import clsx from 'clsx'
import { t } from 'i18next'
import { isNil, maxBy } from 'lodash'
import { useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import { CustomerEntities, RealEstateEntities } from '~/app/api'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import { taxType } from '~/types/enum/enum'
import { calcYearsBuilt, dateFormatOfJc, fullCurrencyFormat } from '~/util/common/common'
import { getSignedUrl } from '~/util/model/FileEntities'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser } from '~/util/store/userSlice'

import { CButton } from '../../../common/cButton/CButton'

type Props = {
    realEstate: RealEstateEntities
    customer: CustomerEntities
}

const CCustomerDetailRecommendListItem = ({ realEstate, customer }: Props) => {
    const navigate = useNavigate()
    const user = useAppSelector(selectLoginUser)
    const detailLink: string = `/realestate/${realEstate.uuid}`

    const [realEstateImage, setRealEstateImage] = useState<string>()
    useEffect(() => {
        const getLogo = async () => {
            const coverImg = maxBy(
                realEstate.documents?.filter((d) => !!d.isCover),
                'createdAt',
            )?.file

            if (coverImg) setRealEstateImage(getSignedUrl(coverImg))
            else setRealEstateImage(`/src/assets/image/noimage.svg`)
        }
        getLogo()
    }, [realEstate])

    return (
        <div className={clsx('m-2')}>
            <div className={clsx('p-2', 'border', 'rounded')}>
                <div className={clsx('flex', 'justify-between')}>
                    <div className={clsx('flex-1', 'overflow-x-hidden')}>
                        <div
                            className={clsx(
                                'text-xl',
                                'text-gray-700',
                                'font-bold',
                                'w-[20em]',
                                'text-ellipsis',
                                'whitespace-nowrap',
                                'overflow-hidden',
                            )}>
                            {realEstate.name}
                        </div>
                        <div className={clsx('flex', 'items-center')}>
                            <div className={clsx('text-xs', 'text-white', 'bg-gray-500', 'py-0.5', 'px-1', 'mr-1')}>
                                {realEstate.useType.name}
                            </div>
                            <div className={clsx('text-sm', 'text-gray-700')}>{realEstate.address}</div>
                        </div>
                    </div>
                    <div className={clsx('flex', 'items-baseline')}>
                        <div className={clsx('flex', 'items-baseline')}>
                            <div className={clsx('text-sm', 'text-kimar-primary')}>
                                {t('CCustomerDetailRecommendListItem.物件価格')}
                            </div>
                            <div className={clsx('text-lg', 'text-kimar-primary', 'font-bold')}>
                                {fullCurrencyFormat(realEstate.price)}
                                {realEstate.taxType
                                    ? ' (' + taxType.find((t) => t.value === realEstate.taxType)?.label + ')'
                                    : ''}
                            </div>
                            <div className={clsx('text-sm', 'text-kimar-primary')}>
                                {t('CCustomerDetailRecommendListItem.円')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('flex', 'my-2')}>
                    <div>
                        <img
                            src={realEstateImage}
                            alt=""
                            loading="lazy"
                            className={clsx('h-20', 'w-20')}
                            crossOrigin="anonymous"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src = '/src/assets/image/notfound.svg'
                            }}
                        />
                    </div>
                    <div className={clsx('flex-1', 'my-2')}>
                        <div className={clsx('mx-2')}>
                            <div className={clsx('text-xs')}>
                                <div className={clsx('flex', 'border-b')}>
                                    <div className={clsx('w-24', 'font-bold')}>
                                        {t('CCustomerDetailRecommendListItem.竣工日')}
                                    </div>
                                    <div className="data">
                                        <span>
                                            {dateFormatOfJc(
                                                realEstate.buildAt,
                                                realEstate.buildAtDateType === 2
                                                    ? 'year'
                                                    : realEstate.buildAtDateType === 1
                                                    ? 'month'
                                                    : 'day',
                                            )}
                                        </span>
                                        <span className={clsx('ml-2')}>{calcYearsBuilt(realEstate.buildAt)}</span>
                                    </div>
                                </div>
                                <div className={clsx('flex', 'border-b')}>
                                    <div className={clsx('w-24', 'font-bold')}>
                                        {t('CCustomerDetailRecommendListItem.利回り')}
                                    </div>
                                    <div className="data">
                                        {!isNil(realEstate.grossRate) && (
                                            <>
                                                <span>{t('CCustomerDetailRecommendListItem.表面')}</span>
                                                <span>{realEstate.grossRate}</span>
                                                <span>%</span>
                                            </>
                                        )}
                                        {!isNil(realEstate.grossRate) && !isNil(realEstate.netRate) && <span>{' / '}</span>}
                                        {!isNil(realEstate.netRate) && (
                                            <>
                                                <span>{t('CCustomerDetailRecommendListItem.実質')}</span>
                                                <span>{realEstate.netRate}</span>
                                                <span>%</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className={clsx('flex', 'border-b')}>
                                    <div className={clsx('w-24', 'font-bold')}>
                                        {t('CCustomerDetailRecommendListItem.担当者')}
                                    </div>
                                    <div className="data">
                                        <span>{}</span>
                                    </div>
                                </div>
                                <div className={clsx('flex', 'border-b')}>
                                    <div className={clsx('w-24', 'font-bold')}>
                                        {t('CCustomerDetailRecommendListItem.登録者/紹介元')}
                                    </div>
                                    <div className="data">
                                        {realEstate.intermediaryFrom?.intermediary?.sentBy?.name ? (
                                            <span>{realEstate.intermediaryFrom?.intermediary?.sentBy?.name}</span>
                                        ) : (
                                            <span>{realEstate.createdBy?.name}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('flex', 'gap-2')}>
                    <CLinkButton className={clsx('c-button-secondary', 'w-full')} to={detailLink} blank>
                        {t('CCustomerDetailRecommendListItem.物件詳細へ')}
                    </CLinkButton>
                    {user?.roles.intermediary === 2 && (
                        <CButton
                            className={clsx('c-button-primary', 'w-full')}
                            onClick={() => {
                                navigate({
                                    pathname: `/realestate/${realEstate.uuid}/intermediary`,
                                    search: createSearchParams({
                                        customers: [customer.uuid],
                                    }).toString(),
                                })
                            }}>
                            {t('CCustomerDetailRecommendListItem.紹介する')}
                        </CButton>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CCustomerDetailRecommendListItem
