import { clsx } from 'clsx'
import { VFC } from 'react'

export type CSelectListViewProps = {} & CSelectListProps

export const CSelectListView: VFC<CSelectListViewProps> = ({
    // disabled,
    items = [],
    dataLabel = 'label',
    // dataValue = null,
    // onChange,
    // className,
}) => {
    const checkBoxList = items.map((data, index) => {
        const label = (dataLabel ? data[dataLabel] : data.label ? data.label : data.value) as string

        return (
            <div key={index} className={clsx('my-1')}>
                <span className={clsx('text-sm', 'ml-2')}>{label}</span>
            </div>
        )
    })

    return <div>{checkBoxList}</div>
}

export type CSelectListProps = {
    className?: string
    // 無効状態か否か
    disabled?: boolean
    // データソース
    items: Array<{ [key: string | number | symbol]: unknown }>
    // ラベルキー
    dataLabel?: string
    // 値キー
    dataValue?: string | null
    onChange: (e: React.ChangeEvent) => void
}

export const CSelectList: VFC<CSelectListProps> = (props) => {
    return <CSelectListView {...props} />
}
