import { t } from 'i18next'
import { useEffect } from 'react'
import toast from 'react-hot-toast/headless'
import { useNavigate } from 'react-router-dom'

import { useClientAuthSignOutMutation } from '~/app/api'
import apiBase from '~/app/apiBase'
import { selectCredential, setCredentials } from '~/util/store/authSlice'
import { useAppDispatch, useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, setLoginUser } from '~/util/store/userSlice'

const SignOut = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const cred = useAppSelector(selectCredential)
    const user = useAppSelector(selectLoginUser)

    const [signOutQuery] = useClientAuthSignOutMutation()
    const signOut = async () => {
        if (!user) {
            // ログアウト済み
            navigate('/')
            return
        }
        try {
            if (!cred) await signOutQuery().unwrap()
        } finally {
            dispatch(apiBase.util.resetApiState()) // RTK Queryのキャッシュクリア
            dispatch(setCredentials({ credential: undefined }))
            dispatch(setLoginUser(undefined))
            navigate('/')
            toast.success(t('SiteHeader.ログアウトしました'))
        }
    }

    useEffect(() => {
        signOut()
    })

    return null
}
export default SignOut
