import { t } from 'i18next'
import { isArray, isNil } from 'lodash'
import { VFC } from 'react'

import CMessage from '../cMessage/CMessage'

export type CErrorViewProps = {} & CErrorProps

export const CErrorView: VFC<CErrorViewProps> = ({ errors }) => {
    const filteredErrors =
        errors?.filter((err): err is { message: string | string[]; statusCode: string | number } => !isNil(err)) ?? []
    const errorListDisplay = filteredErrors?.map((error, index) => {
        const _message = isArray(error.message) ? error.message.join('\n') : error.message
        const domText = _message ? _message.replace(/\n/g, '<br />') : ''
        return (
            <div key={index}>
                {domText ? (
                    <span dangerouslySetInnerHTML={{ __html: domText }} />
                ) : (
                    <span>{t('CError.不明なエラーが発生しました')}</span>
                )}
                {error.statusCode && <em>({error.statusCode})</em>}
            </div>
        )
    })
    return errors?.length ?? 0 > 0 ? <CMessage danger>{errorListDisplay}</CMessage> : null
}

export type CErrorProps = {
    className?: string
    errors?: ({ message: string | string[]; statusCode: string | number } | undefined)[]
}

export const CError: VFC<CErrorProps> = (props) => {
    return <CErrorView {...props} />
}
