import './index.css'
import './i18n/configs'

import * as Sentry from '@sentry/react'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import GoogleMapWrapper from './components/global/googleMaps/GoogleMapsWrapper'
import KimarApp from './KimarApp'
import Error from './pages/error/Error'
import ScrollToTop from './pages/util/ScrollToTop'
import { persistor, store } from './util/store/store'

Sentry.init({
    dsn: process.env.SENTRY_DSN,
})

const onError = (error: Error, componentStack: string) => {
    console.error(error.message, componentStack)
}

const meta = {
    title: 'キマール',
    description:
        '今までになかった不動産売買の業務支援システム 物件管理もラクラク 顧客対応もラクラク いつもの仕事がラクラク KIMAR 不動産売買の業務が、ハヤクなり、ミスが減り、成約増加！',
    ogp: 'https://kimar.jp/public/static/img/OGP.png',
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <CookiesProvider>
                    <HelmetProvider>
                        <Helmet
                            title={meta.title}
                            meta={[
                                { name: 'description', content: meta.description },
                                { property: 'og:image', content: meta.ogp },
                                { property: 'og:description', content: meta.description },
                                { name: 'twitter:card', content: 'summary' },
                            ]}
                        />
                        <BrowserRouter>
                            <ScrollToTop />
                            <GoogleMapWrapper>
                                <Sentry.ErrorBoundary fallback={Error} onError={onError}>
                                    <KimarApp />
                                </Sentry.ErrorBoundary>
                            </GoogleMapWrapper>
                        </BrowserRouter>
                    </HelmetProvider>
                </CookiesProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
)
