import clsx from 'clsx'

import { RealEstateEntities } from '~/app/api'

import CRealestateDetailAssignee from './CRealestateDetailAssignee'
import CRealestateDetailMemo from './CRealestateDetailMemo'

type Props = {
    realEstate: RealEstateEntities
    refetch: () => void
}
const CRealestateDetailManage = ({ realEstate, refetch }: Props) => {
    return (
        <div className={clsx('p-4', 'flex', 'flex-col', 'gap-4')}>
            <CRealestateDetailAssignee realEstate={realEstate} refetch={refetch} />
            <CRealestateDetailMemo realEstate={realEstate} refetch={refetch} />
        </div>
    )
}

export default CRealestateDetailManage
