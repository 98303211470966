import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { round } from 'lodash'
import { useEffect, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import { ClientRealEstateOutputDto, RealEstateEntities } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CDropDown } from '~/components/common/cDropdown/CDropdown'
import { CError } from '~/components/common/cError/CError'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CNumberInput } from '~/components/common/cNumberInput/CNumberInput'
import { MAX_PRICE_INTEGER, taxType } from '~/types/enum/enum'
import { ApplicationError, BadRequest, IApplicationError } from '~/types/error'
import { fullCurrencyFormat } from '~/util/common/common'
import { selectCredential } from '~/util/store/authSlice'
import { useAppSelector } from '~/util/store/hooks'

import { CTextInput } from '../../common/cTextInput/CTextInput'

export type CRealestateOutputViewProps = {
    form: ClientRealEstateOutputDto
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (key: string, value: unknown) => void
    errors: IApplicationError[]
} & CRealestateOutputProps

export const CRealestateOutputView: VFC<CRealestateOutputViewProps> = ({
    visible,
    form,
    onRequestClose,
    onRequestConfirm,
    setProps,
    errors,
}) => {
    const calcGrossRate = () => {
        if (!form?.assumeIncome || !form?.price) return
        const value = round(form.assumeIncome / form.price, 4) * 100
        setProps('grossRate', round(value, 3))
    }

    return (
        <CConfirmModal
            visible={visible}
            content={{ width: '70%' }}
            onRequestClose={() => onRequestClose()}
            onRequestConfirm={() => onRequestConfirm()}
            title={t('CRealestateOutput.物件概要書を出力する')}
            confirmLabel={t('CRealestateOutput.ダウンロード')}>
            <div className={clsx('text-sm')}>
                <p>{t('CRealestateOutput.物件概要書をExcel形式でダウンロードします。')}</p>
                <p>{t('CRealestateOutput.以下の項目は編集してからダウンロードできます。 ※物件の情報は上書きされません。')}</p>
            </div>
            <div>
                <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                    <div
                        className={clsx('col-span-1', 'bg-kimar-primary', 'text-white', 'flex', 'justify-center', 'items-center')}
                    />
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                    'text-sm',
                                )}>
                                {t('CRealestateOutput.物件価格')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                                <CNumberInput
                                    className={clsx('c-text-input-base')}
                                    value={form.price}
                                    decimalScale={0}
                                    onChange={(value) => setProps('price', value)}
                                />
                                <span className={clsx('ml-2')}>{t('Unit.円')}</span>
                                <span className={clsx('ml-2')}>
                                    ({fullCurrencyFormat(form.price)}
                                    {t('Unit.円')})
                                </span>
                                <span>
                                    <CDropDown
                                        items={taxType}
                                        allowUnselected
                                        unselectedLabel={'--'}
                                        nowValue={String(form.taxType)}
                                        onChange={(value) => setProps('taxType', Number(value))}
                                        className={clsx('w-full', 'c-dropdown-base')}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                            'text-sm',
                        )}>
                        {t('CRealestateOutput.収支')}
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                            'text-sm',
                        )}>
                        {t('CRealestateOutput.想定収入／年')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        <CNumberInput
                            className={clsx('w-full', 'c-text-input-base')}
                            value={form.assumeIncome}
                            decimalScale={0}
                            onChange={(value) => setProps('assumeIncome', value)}
                        />
                        <span className={clsx('ml-1')}>{t('Unit.円')}</span>
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                            'text-sm',
                        )}>
                        {t('CRealestateOutput.現況収入／年')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        <CNumberInput
                            className={clsx('w-full', 'c-text-input-base')}
                            value={form.presentIncome}
                            decimalScale={0}
                            onChange={(value) => setProps('presentIncome', value)}
                        />
                        <span className={clsx('ml-1')}>{t('Unit.円')}</span>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                            'text-sm',
                        )}>
                        {t('CRealestateOutput.利回り')}
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                            'text-sm',
                        )}>
                        {t('CRealestateOutput.表面利回り')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        <CNumberInput
                            className={clsx('w-full', 'c-text-input-base')}
                            value={form.grossRate}
                            decimalScale={2}
                            onChange={(value) => setProps('grossRate', value)}
                        />
                        <span className={clsx('ml-1')}>{t('Unit.％')}</span>
                        <CButton
                            className={clsx('c-button-secondary', 'whitespace-nowrap')}
                            disabled={!form?.assumeIncome || !form?.price}
                            onClick={() => calcGrossRate()}>
                            {t('CRealestateEdit.自動計算')}
                        </CButton>
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                            'text-sm',
                        )}>
                        {t('CRealestateOutput.実質利回り')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        <CNumberInput
                            className={clsx('w-full', 'c-text-input-base')}
                            value={form.netRate}
                            decimalScale={2}
                            onChange={(value) => setProps('netRate', value)}
                        />
                        <span className={clsx('ml-1')}>{t('Unit.％')}</span>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                    <div
                        className={clsx('col-span-1', 'bg-kimar-primary', 'text-white', 'flex', 'justify-center', 'items-center')}
                    />
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                    'text-sm',
                                )}>
                                {t('CRealestateOutput.備考')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>
                                <CTextInput
                                    type={'multiline'}
                                    className={clsx('w-full', 'c-text-input-base')}
                                    text={form.other}
                                    onChange={(value) => setProps('other', value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CError errors={errors} />
        </CConfirmModal>
    )
}

export type CRealestateOutputProps = {
    visible: boolean
    realEstate?: RealEstateEntities
    onClose: () => void
    onSubmit: (realEstate: RealEstateEntities) => void
}

export const CRealestateOutput: VFC<CRealestateOutputProps> = ({ ...props }) => {
    const [visible, setVisible] = useState(props.visible)
    const [form, setForm] = useState<ClientRealEstateOutputDto>()
    useEffect(() => {
        setErrors([])
        setForm({
            price: props.realEstate?.price ?? undefined,
            taxType: props.realEstate?.taxType ?? undefined,
            assumeIncome: props.realEstate?.assumeIncome ?? undefined,
            presentIncome: props.realEstate?.presentIncome ?? undefined,
            grossRate: props.realEstate?.grossRate ?? undefined,
            netRate: props.realEstate?.netRate ?? undefined,
            other: props.realEstate?.other ?? undefined,
        })
        setVisible(props.visible)
    }, [props.visible])

    const setProps = (key: string, value: unknown) => {
        if (!form) return
        setForm({
            ...form,
            [key]: value,
        })
    }

    const onRequestClose = () => {
        props.onClose()
    }

    const cred = useAppSelector(selectCredential)
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const onRequestConfirm = async () => {
        if (!form || !props.realEstate) return
        try {
            setErrors([])
            const errorList: Array<ApplicationError> = []
            if (form.price && (form.price >= MAX_PRICE_INTEGER || form.price < 0))
                errorList.push(new BadRequest(t('CRealestateOutput.物件価格に正しい価格を入力してください')))
            if (errorList.length > 0) {
                setErrors(errorList)
                return
            }
            const path = `${process.env.BASE_URL}/api/client/real_estate/${props.realEstate.uuid}/report`
            const header = {
                headers: {
                    'X-Authorization': `Bearer ${cred}`,
                    'Content-Type': 'application/json',
                },
            }
            const response = await fetch(path, {
                method: 'POST',
                headers: header.headers,
                body: JSON.stringify(form),
            })

            if (!response.ok) return
            const blob = response.body

            const streamSaver = (await import('streamsaver')).default
            const fileStream = streamSaver.createWriteStream(props.realEstate.name + '_' + '物件概要書' + '.xlsx') // ファイル名なので埋め込む
            await blob?.pipeTo(fileStream)

            props.onSubmit(props.realEstate)
            toast.success(t('CRealestateOutput.物件概要書を出力しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    if (!form) return null
    return (
        <CRealestateOutputView
            {...props}
            visible={visible}
            form={form}
            onRequestConfirm={onRequestConfirm}
            onRequestClose={onRequestClose}
            setProps={setProps}
            errors={errors}
        />
    )
}
