import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { ReactNode, useState } from 'react'
import toast from 'react-hot-toast/headless'

import { useClientInquiryPostInquirySimpleMutation } from '~/app/api'
import { ApplicationError, IApplicationError } from '~/types/error'

import { CButton } from '../cButton/CButton'
import { CError } from '../cError/CError'
import CMessage from '../cMessage/CMessage'
import { CConfirmModal } from '../cMessageModal/CMessageModal'

type Props = {
    children: ReactNode
}

const CInquiryButton = ({ children }: Props) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    // モーダル表示
    const [visibleSync, setVisibleSync] = useState(false)

    // inquiry api
    const [simpleInquiryQuery, { isSuccess: isSuccessSimpleInquiryQuery }] = useClientInquiryPostInquirySimpleMutation()

    const sendInquiry = async () => {
        setErrors([])
        try {
            await simpleInquiryQuery().unwrap()
            setVisibleSync(false)
            toast.success(t('CInquiryButton.説明依頼を送信しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    return (
        <>
            <div className={clsx('flex', 'flex-col', 'justify-center', 'gap-y-4')}>
                <div className={clsx('flex', 'justify-center')}>
                    <img
                        src="/src/assets/image/page/market/market.svg"
                        className={clsx('sm:max-w-md')}
                        alt="KIMAR MARKETロゴ画像"
                    />
                </div>
                <CError errors={errors} />
                <div>{children}</div>
                <div className={clsx('flex', 'justify-center', 'mx-auto', 'max-w-3xl', 'w-full', 'gap-2', 'flex-wrap')}>
                    <img
                        src="/src/assets/image/page/market/recommend.png"
                        className={clsx('object-contain', 'w-1/2', 'md:w-1/4')}
                        alt="社内情報を活用するならレコメンド機能"
                    />
                    <img
                        src="/src/assets/image/page/market/connection.png"
                        className={clsx('object-contain', 'w-1/2', 'md:w-1/4')}
                        alt="新規人脈と出会うならコネクション機能"
                    />
                    <img
                        src="/src/assets/image/page/market/market.png"
                        className={clsx('object-contain', 'w-1/2', 'md:w-1/4')}
                        alt="物件情報を入手するならマーケット機能"
                    />
                </div>
                {isSuccessSimpleInquiryQuery ? (
                    <CMessage success>
                        <p>{t('CInquiryButton.マーケットの説明依頼をしました。')}</p>
                        <p>{t('CInquiryButton.カスタマーサクセスから追ってご連絡いたします。')}</p>
                    </CMessage>
                ) : (
                    <div className={clsx('flex', 'justify-center')}>
                        <CButton
                            className={clsx('c-button-market', 'text-xl', 'justify-center', 'items-center')}
                            onClick={() => setVisibleSync(true)}>
                            <img
                                src="/src/assets/image/page/market/inquiry-button.png"
                                className={clsx('h-12')}
                                alt="KIMAR MARKET紹介画像"
                            />
                            <div>{t('CInquiryButton.キマールから説明を聞く')}</div>
                        </CButton>
                    </div>
                )}
            </div>

            <CConfirmModal
                visible={visibleSync}
                onRequestClose={() => setVisibleSync(false)}
                onRequestConfirm={sendInquiry}
                confirmLabel={t('Button.送信')}>
                <div className={clsx('m-6')}>
                    {t('CInquiryButton.キマールからマーケットについてご連絡します。よろしいですか？')}
                </div>
            </CConfirmModal>
        </>
    )
}

export default CInquiryButton
