import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { CHeader } from '~/components/common/cHeader/CHeader'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'
import CNotificationTemplate from '~/components/unique/admin/template/CNotificationTemplate'
import CReportTemplate from '~/components/unique/admin/template/CReportTemplate'
import { pageTitleTemplate } from '~/util/common/common'

const AdminTemplate = () => {
    const [selectedTab, setSelectedTab] = useState('notification')
    const tabs = useMemo(
        () => [
            { label: '通知テンプレート', value: 'notification' },
            { label: '帳票テンプレート', value: 'report' },
        ],
        [],
    )

    return (
        <>
            <div className={clsx('flex', 'flex-col', 'gap-y-4')}>
                <Helmet titleTemplate={pageTitleTemplate(true)}>
                    <title>テンプレート管理</title>
                </Helmet>
                <CHeader label="テンプレート管理" />
                <div className={clsx('w-full', 'flex', 'justify-center')}>
                    <div className={clsx('w-full', 'md:w-2/4')}>
                        <CSegmentedControl
                            items={tabs}
                            onClick={(val) => setSelectedTab(val as string)}
                            selectedTabValue={selectedTab}
                        />
                    </div>
                </div>
                <div className={clsx('w-full', 'flex', 'flex-col', 'items-center')}>
                    {selectedTab === 'notification' && <CNotificationTemplate />}
                    {selectedTab === 'report' && <CReportTemplate />}
                </div>
            </div>
        </>
    )
}
export default AdminTemplate
