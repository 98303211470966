import clsx from 'clsx'
import { useEffect, useState, VFC } from 'react'

import { ClientNotificationTemplateUpdateDto } from '~/app/api'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'

type CUserMypageTeamTemplateEditProps = {
    template: ClientNotificationTemplateUpdateDto
    changeHandler: (template: ClientNotificationTemplateUpdateDto) => void
}

const CUserMypageTeamTemplateEdit: VFC<CUserMypageTeamTemplateEditProps> = ({ ...props }) => {
    const [template, setTemplate] = useState(props.template)

    // テンプレートによってテンプレラベルリストの内容を変える
    const templateLabelList = () => {
        const defaultBodyList: Array<{ key: string; text: string }> = [
            {
                key: '__to_company_name__',
                text: '送付先 会社名',
            },
            {
                key: '__to_department_name__',
                text: '送付先 部署名',
            },
            {
                key: '__to_position__',
                text: '送付先 役職名',
            },
            {
                key: '__to_name__',
                text: '送付先 顧客名',
            },
            {
                key: '__from_company_name__',
                text: '送付元 会社名',
            },
            {
                key: '__from_name__',
                text: '送付元 顧客名',
            },
            {
                key: '__real_estate_name__',
                text: '物件名',
            },
            {
                key: '__url__',
                text: '紹介URL',
            },
            {
                key: '__staff_signature__',
                text: '物件担当者名',
            },
            {
                key: '__message__',
                text: '紹介先へのメッセージ（物件の紹介、物件の紹介終了時のみ）',
            },
            {
                key: '__attached_documents__',
                text: '物件資料',
            },
            {
                key: '__real_estate_use_type__',
                text: '種別',
            },
            {
                key: '__real_estate_address__',
                text: '住所',
            },
            {
                key: '__real_estate_station__',
                text: '交通',
            },
            {
                key: '__real_estate_land_right__',
                text: '土地権利',
            },
            {
                key: '__real_estate_land_category__',
                text: '地目',
            },
            {
                key: '__real_estate_registered_land_size__',
                text: '公簿面積（㎡）',
            },
            {
                key: '__real_estate_building_right__',
                text: '建物権利',
            },
            {
                key: '__real_estate_build_at__',
                text: '竣工日',
            },
            {
                key: '__real_estate_structure_type__',
                text: '構造',
            },
            {
                key: '__real_estate_total_floor_size__',
                text: '延床面積（㎡）',
            },
            {
                key: '__real_estate_assume_income__',
                text: '想定年収入（円）',
            },
            {
                key: '__real_estate_gross_rate__',
                text: '表面利回り（％）',
            },
            {
                key: '__real_estate_price__',
                text: '提示金額（円）',
            },
        ]
        // テンプレート名と使えないラベルリストの連想配列
        const targetTemplateLabelPair = {
            // コネクション・マーケット経由物件紹介時は紹介元は役職名等をまだ知らないのでラベル使用も不可
            external_recommend_intermediary: ['__to_department_name__', '__to_position__'],
            real_estate_publish: ['__to_department_name__', '__to_position__'],
        }
        function isTarget(keyName: string) {
            return (
                template.key in targetTemplateLabelPair &&
                (targetTemplateLabelPair as unknown as { [key: string]: string })[template.key].includes(keyName)
            )
        }
        return defaultBodyList.filter((obj) => !isTarget(obj.key))
    }

    const changeHandler = (value: string, target: keyof ClientNotificationTemplateUpdateDto) => {
        setTemplate((old) => ({
            ...old,
            [target]: value,
        }))
    }
    useEffect(() => props.changeHandler(template), [template])

    return (
        <div>
            <div className={clsx('flex', 'gap-x-4')}>
                <div className={clsx('flex', 'flex-col', 'flex-1', 'gap-y-4')}>
                    <CLabeledItem label="テンプレート使用場面">
                        <span className={clsx('text-2xl')}>{template.name}</span>
                    </CLabeledItem>
                    <div>
                        <div className={clsx('flex', 'flex-row', 'justify-between')}>
                            <div>テンプレートラベル</div>
                            <div>
                                <img src="/src/assets/image/icon/info.png" className={clsx('w-5', 'h-5', 'mr-2', 'peer')} />
                                <div
                                    className={clsx(
                                        'absolute',
                                        'bg-white',
                                        'p-2',
                                        'shadow',
                                        'rounded',
                                        'invisible',
                                        'opacity-0',
                                        'peer-hover:opacity-100',
                                        'peer-hover:visible',
                                        'min-w-[200px]',
                                        'max-w-[280px]',
                                        'transition',
                                        'duration-300',
                                    )}>
                                    <div>
                                        テンプレートラベルを使用すると、送付先や紹介したい物件によってメール内容が動的に生成されます。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={clsx('w-full', 'p-2', 'h-auto', 'gap-y-3', 'overflow-auto')}>
                            <table className={clsx('border')}>
                                <tbody>
                                    {templateLabelList().map((label, index) => (
                                        <tr key={index} className={clsx('border')}>
                                            <td className={clsx('border', 'p-1')}>{label.text}</td>
                                            <td className={clsx('border', 'p-1')}>{label.key}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={clsx('flex', 'flex-col', 'flex-1', 'gap-y-6')}>
                    <CLabeledItem label="メール件名">
                        <CTextInput
                            type="text"
                            text={template.mailSubject ?? ''}
                            className={clsx('w-full', 'c-text-input-base')}
                            onChange={(value) => changeHandler(value, 'mailSubject')}
                        />
                    </CLabeledItem>
                    <div className={clsx('border')} />
                    <CLabeledItem label="メール本文">
                        <table className={clsx('w-full')}>
                            <tbody>
                                <tr>
                                    <td className={clsx('p-4')}>
                                        <p>
                                            <CTextInput
                                                type="multiline"
                                                text={template.mailHtmlBody ?? ''}
                                                className={clsx('w-full', 'h-80', 'c-text-input-base')}
                                                onChange={(value) => changeHandler(value, 'mailHtmlBody')}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={clsx('p-4')}>
                                        <p>
                                            <CTextInput
                                                type="text"
                                                text={template.mailHtmlButtonTitle ?? ''}
                                                className={clsx(
                                                    'c-button-primary',
                                                    'text-lg',
                                                    'text-center',
                                                    'w-[280px]',
                                                    'max-w-[80%]',
                                                    'px-[12px]',
                                                    'py-[8px]',
                                                )}
                                                placeholder="ボタン名"
                                                onChange={(value) => changeHandler(value, 'mailHtmlButtonTitle')}
                                            />
                                        </p>
                                        {template.mailHtmlButtonTitle && (
                                            <p className={clsx('text-[10px]')}>
                                                リンク先が表示されない場合は、以下のURLをWEBブラウザに入力することでアクセスできます。
                                                <br />
                                                <a href="__url__" className={clsx('text-kimar-primary')}>
                                                    __url__
                                                </a>
                                            </p>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={clsx('p-4')}>
                                        <p>
                                            <CTextInput
                                                type="multiline"
                                                text={template.mailHtmlAfterMessage ?? ''}
                                                placeholder="追加のメッセージがある場合はここに記載します。"
                                                className={clsx('w-full', 'h-24', 'c-text-input-base')}
                                                onChange={(value) => changeHandler(value, 'mailHtmlAfterMessage')}
                                            />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </CLabeledItem>
                </div>
            </div>
        </div>
    )
}
export default CUserMypageTeamTemplateEdit
