import clsx from 'clsx'
import { t } from 'i18next'
import { VFC } from 'react'

import { ClientMarketRealEstateDto, RealEstateUseTypeEntities } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CTag } from '~/components/common/cTag/CTag'
import { formatDateTime } from '~/util/common/common'
import {
    areaTypeFormat,
    legalComplianceTypeFormat,
    realestateBuildingAgeTypeFormat,
    realestateGrossRateTypeFormat,
    realestateNetRateTypeFormat,
    realestatePriceTypeFormat,
    realestateSalesDestinationTypeFormat,
    realestateSeismicStandardTypeFormat,
    saleTimeTypeFormat,
} from '~/util/filter/format'

export type CMarketRealestateListItemViewProps = {} & CMarketRealestateListItemProps

export const CMarketRealestateListItemView: VFC<CMarketRealestateListItemViewProps> = ({ item, publishRequest, isNew }) => {
    // 種別画像の取得
    const getCoverUrl = (useType: RealEstateUseTypeEntities) => {
        if (useType) return `/src/assets/image/page/market/use_type/use_type_${useType.sort}.svg`
        else return '/src/assets/image/noimage.svg'
    }

    // itemのタイトル表示
    const itemTitle = (item: ClientMarketRealEstateDto) => {
        // 種別が土地か、または築年数の表記ができない時
        if (item.realEstate.useType.name === '土地' || realestateBuildingAgeTypeFormat(item.buildingAgeType) === '-') {
            return t('CMarketRealestateListItem.area／price／usetype', {
                area: item.showAddress ?? areaTypeFormat(item.areaType),
                price: realestatePriceTypeFormat(item.priceType),
                usetype: item.realEstate.useType.name,
            })
        }
        return t('CMarketRealestateListItem.area／age／price／usetype', {
            area: item.showAddress ?? areaTypeFormat(item.areaType),
            age: realestateBuildingAgeTypeFormat(item.buildingAgeType),
            price: realestatePriceTypeFormat(item.priceType),
            usetype: item.realEstate.useType.name,
        })
    }

    const labelDom = () => {
        return (
            <div className={clsx('absolute', 'top-1', 'flex', 'flex-row', 'gap-x-1', 'text-sm')}>
                {isNew && (
                    <CTag className={clsx('rounded-full', 'bg-sky-700', 'px-2', 'text-white')}>
                        {t('CMarketRealestateListItem.NEW')}
                    </CTag>
                )}
                {item.isRegisteredByUserCompany && (
                    <CTag className={clsx('rounded-full', 'bg-kimar-accent', 'px-2', 'text-white')}>
                        {t('CMarketRealestateListItem.自社物件')}
                    </CTag>
                )}
                {item.hasMarketPublishIntermediary && (
                    <CTag className={clsx('rounded-full', 'bg-red-700', 'px-2', 'text-white')}>
                        {t('CMarketRealestateListItem.開示実績あり')}
                    </CTag>
                )}
            </div>
        )
    }

    const requestButtonDom = () => {
        const buttonClass = ['rounded', 'border', 'text-white', 'text-sm', 'py-1', 'px-3', 'w-full', 'sm:w-auto']
        if (item.isRegisteredByUserCompany) {
            return (
                <CButton className={clsx(buttonClass, 'bg-gray-300')} disabled={true}>
                    {t('CMarketRealestateListItem.自社掲載物件')}
                </CButton>
            )
        } else if (item.isRequestedByUser) {
            return (
                <CButton className={clsx(buttonClass, 'bg-gray-300')} disabled={true}>
                    {t('CMarketRealestateListItem.リクエスト済み')}
                </CButton>
            )
        } else if (item.isRequestedByCompany) {
            return (
                <CButton className={clsx(buttonClass, 'bg-gray-300')} disabled={true}>
                    {t('CMarketRealestateListItem.社内でリクエスト済み')}
                </CButton>
            )
        }
        if (item.isReceivingByUserCompany) {
            return (
                <CButton className={clsx(buttonClass, 'bg-gray-300')} disabled={true}>
                    {t('CMarketRealestateListItem.既に紹介を受けています')}
                </CButton>
            )
        } else {
            return (
                <CButton
                    className={clsx(buttonClass, 'bg-kimar-market')}
                    onClick={() => {
                        !!publishRequest && publishRequest()
                    }}>
                    {t('CMarketRealestateListItem.情報開示リクエスト')}
                </CButton>
            )
        }
    }

    return (
        <>
            <div className={clsx('rounded-lg', 'bg-white')}>
                <div className={clsx('sm:flex', 'p-2')}>
                    {/* 通常画面サイズ時の画像・ボタン・ラベル表示 */}
                    <div className={clsx('sm:flex', 'flex-col', 'justify-center', 'w-48', 'relative', 'hidden')}>
                        <div className={clsx('w-full', 'h-full', 'flex', 'flex-col', 'justify-center')}>
                            <img
                                src={getCoverUrl(item.realEstate.useType)}
                                alt=""
                                loading="lazy"
                                className={clsx('max-h-20', 'max-w-20')}
                            />
                        </div>
                        <div className={clsx('bottom-3', 'flex', 'justify-center', 'mb-4')}>{requestButtonDom()}</div>
                        {labelDom()}
                    </div>
                    {/* スマホサイズ時のラベル表示 */}
                    <div className={clsx('absolute', 'whitespace-nowrap', 'sm:hidden')}>{labelDom()}</div>
                    <div className={clsx('flex-1', 'my-2')}>
                        <div className={clsx('flex', 'flex-col', 'gap-y-2', 'mx-2')}>
                            {/* スマホサイズ時の画像・タイトル表示 */}
                            <div className={clsx('flex', 'flex-row', 'gap-y-2', 'sm:hidden', 'items-center', 'pt-4')}>
                                <img
                                    src={getCoverUrl(item.realEstate.useType)}
                                    alt=""
                                    loading="lazy"
                                    className={clsx('max-h-12', 'max-w-12', 'm-3')}
                                />
                                <div className={clsx('text-kimar-market', 'text-xl', 'font-bold')}>{itemTitle(item)}</div>
                            </div>
                            {/* 通常サイズ時のタイトル表示 */}
                            <div className={clsx('text-kimar-market', 'text-xl', 'font-bold', 'hidden', 'sm:block')}>
                                {itemTitle(item)}
                            </div>
                            <div className={clsx('flex')}>
                                <div className={clsx('grid', 'grid-cols-2', 'text-xs', 'flex-1')}>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CMarketRealestateListItem.種別')}</div>
                                        <div className="data">{item.realEstate.useType.name}</div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CMarketRealestateListItem.エリア')}</div>
                                        <div className="data">{item.showAddress ?? areaTypeFormat(item.areaType)}</div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CMarketRealestateListItem.金額')}</div>
                                        <div className="data">{realestatePriceTypeFormat(item.priceType)}</div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CMarketRealestateListItem.築年数')}</div>
                                        <div className="data">
                                            <span>{realestateBuildingAgeTypeFormat(item.buildingAgeType)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>
                                            {t('CMarketRealestateListItem.表面利回り')}
                                        </div>
                                        <div className="data">
                                            <span>{realestateGrossRateTypeFormat(item.grossRateType)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>
                                            {t('CMarketRealestateListItem.実質利回り')}
                                        </div>
                                        <div className="data">
                                            <span>{realestateNetRateTypeFormat(item.netRateType)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CMarketRealestateListItem.遵法性')}</div>
                                        <div className="data">
                                            <span>{legalComplianceTypeFormat(item.realEstate.legalComplianceType)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CMarketRealestateListItem.耐震基準')}</div>
                                        <div className="data">
                                            {realestateSeismicStandardTypeFormat(item.realEstate.seismicStandardType)}
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CMarketRealestateListItem.売却時期')}</div>
                                        <div className="data">
                                            <span>{saleTimeTypeFormat(item.realEstate.saleTimeType)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CMarketRealestateListItem.先数')}</div>
                                        <div className="data">
                                            {realestateSalesDestinationTypeFormat(item.realEstate.salesDestinationType)}
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'col-span-2', 'items-center')}>
                                        {item.realEstate.marketComment ? (
                                            <>
                                                <div className={clsx('w-24', 'font-bold', 'py-1')}>
                                                    {t('CMarketRealestateListItem.掲載者コメント')}
                                                </div>
                                                <div className="data">{item.realEstate.marketComment}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={clsx('w-24', 'font-bold', 'my-1', 'h-4')} />
                                                <div className="data" />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* スマホサイズ時のボタン表示 */}
                            <div className={clsx('sm:hidden')}>{requestButtonDom()}</div>
                            <div className={clsx('flex', 'flex-row', 'justify-between', 'bg-gray-50', 'py-2')}>
                                <div className={clsx('bg-amber-50', 'text-amber-700', 'text-[10px]', 'mx-1')}>
                                    {!!item.recentRequestCount && (
                                        <div className={clsx('px-1')}>
                                            {t('CMarketRealestateListItem.最近count回リクエストされました', {
                                                count: item.recentRequestCount,
                                            })}
                                        </div>
                                    )}
                                </div>
                                <div className={clsx('flex', 'flex-row', 'gap-x-3', 'text-gray-500', 'text-[10px]', 'pr-2')}>
                                    <div>
                                        {t('CMarketRealestateListItem.更新日')}：
                                        {formatDateTime(item.realEstate.updatedAt, 'date')}
                                    </div>
                                    <div>
                                        {t('CMarketRealestateListItem.掲載日')}：{formatDateTime(item.publishedAt, 'date')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export type CMarketRealestateListItemProps = {
    item: ClientMarketRealEstateDto
    isNew?: boolean
    publishRequest?: () => void
}

export const CMarketRealestateListItem: VFC<CMarketRealestateListItemProps> = (props) => {
    return <CMarketRealestateListItemView {...props} />
}
