import clsx from 'clsx'
import { t } from 'i18next'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import { CButton } from '~/components/common/cButton/CButton'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import SiteFooter from '~/components/global/gFooter/SiteFooter'
import SiteHeader from '~/components/global/gHeader/SiteHeader'
import { pageTitleTemplate } from '~/util/common/common'

const Error404 = () => (
    <div className={clsx('min-h-screen', 'flex', 'flex-col', 'text-gray-700', 'bg-gray-50')}>
        <SiteHeader />
        <div className={clsx('p-8', 'h-full')}>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Error.お探しのページが見つかりませんでした')}</title>
            </Helmet>
            <div className={'max-w-xl mx-auto bg-white rounded p-8 flex flex-col space-y-4'}>
                <CHeader label={t('Error.お探しのページが見つかりませんでした')} />
                <CMessage warning>
                    <p>
                        {t(
                            'Error.お探しのページは一時的にアクセスができない状況にあるか、移動もしくは削除された可能性があります。',
                        )}
                    </p>
                </CMessage>
                <Link to="/">
                    <CButton className={clsx('c-button-primary', 'w-full')}>{t('Error.トップページへ')}</CButton>
                </Link>
            </div>
        </div>
        <SiteFooter />
    </div>
)

export default Error404
