import clsx from 'clsx'
import { t } from 'i18next'
import { Helmet } from 'react-helmet-async'

import { CHeader } from '~/components/common/cHeader/CHeader'
import { pageTitleTemplate } from '~/util/common/common'

const SctaNotation = () => (
    <>
        <Helmet titleTemplate={pageTitleTemplate()}>
            <title>{t('SctaNotation.特定商取引法に関する表記')}</title>
        </Helmet>
        <CHeader label={t('SctaNotation.特定商取引法に関する表記')} />
        <div
            className={clsx(
                'bg-white',
                'flex',
                'flex-col',
                'gap-2',
                'p-4',
                'md:p-8',
                'text-sm',
                'w-full',
                'max-w-4xl',
                'mx-auto',
                'rounded',
            )}>
            <h3>販売事業者</h3>
            <p>リマールエステート株式会社</p>

            <h3>運営責任者</h3>
            <p>代表取締役社長 赤木正幸</p>
            <h3>住所</h3>
            <p>
                〒104-0061
                <br />
                東京都中央区銀座8-17-5 銀座OCT5階
                <br />
                TEL: 03-5324-2810
            </p>
            <h3>問い合わせ先</h3>
            <p>下記のお問い合わせフォームよりお問い合わせください。</p>

            <a className={clsx('text-kimar-primary', 'underline')} href="https://kimar.jp/inquiry">
                お問い合わせ
            </a>
            <h3>販売価格</h3>
            <p>下記のお問い合わせフォームよりお問い合わせください。</p>

            <a className={clsx('text-kimar-primary', 'underline')} href="https://kimar.jp/inquiry">
                お問い合わせ
            </a>
            <h3>支払方法</h3>
            <p>
                以下の支払方法のうち、顧客が選択した方法によりお支払いいただきます。
                <br />
                クレジットカード決済：各クレジットカード会社の規約に基づきお支払いいただきます。但し、代行・仲介は決済対象外とさせていただきます。
                <br />
                銀行振込：請求書で指定された支払期限までにお支払いください。なお、振込手数料は顧客のご負担となります。
            </p>
            <h3>申込み有効期限</h3>
            <p>
                なし。ただし、お支払方法が銀行振込の場合、お申込み日より30日以内が有効期限となり、期限内にお振込確認ができない場合、お申込みはキャンセルとなります。
            </p>

            <h3>商品引渡し又はサービス提供の時期</h3>
            <p>登録手続の完了後直ちに提供します。ただし、銀行振込の場合、入金確認後速やかに提供します。</p>

            <h3>返品等の対応</h3>
            <p>商品の特性上、返品には応じられず、購入確定後のキャンセル・返金についてはお受けできません。</p>
        </div>
    </>
)

export default SctaNotation
