/**
 * 物件のマーケット掲載情報
 */

// 用途
export const UseType = {
    InitValue: 0,
    Office: 1,
    Housing: 2,
    CommercialFacility: 3,
    LogisticsFacility: 4,
    Hotel: 5,
    HospitalAndNursingFacility: 6,
    Complex: 7,
    Land: 8,
    Others: 9,
} as const
export type UseType = (typeof UseType)[keyof typeof UseType]

// エリア
export const AreaType = {
    InitValue: null,
    Hokkaido: 1,
    Aomori: 2,
    Iwate: 3,
    Miyagi: 4,
    Akita: 5,
    Yamagata: 6,
    Fukushima: 7,
    Ibaraki: 8,
    Tochigi: 9,
    Gunma: 10,
    Saitama: 11,
    Chiba: 12,
    Tokyo: 13,
    Kanagawa: 14,
    Niigata: 15,
    Toyama: 16,
    Ishikawa: 17,
    Fukui: 18,
    Yamanashi: 19,
    Nagano: 20,
    Gifu: 21,
    Shizuoka: 22,
    Aichi: 23,
    Mie: 24,
    Shiga: 25,
    Kyoto: 26,
    Osaka: 27,
    Hyogo: 28,
    Nara: 29,
    Wakayama: 30,
    Tottori: 31,
    Shimane: 32,
    Okayama: 33,
    Hiroshima: 34,
    Yamaguchi: 35,
    Tokushima: 36,
    Kagawa: 37,
    Ehime: 38,
    Kochi: 39,
    Fukuoka: 40,
    Saga: 41,
    Nagasaki: 42,
    Kumamoto: 43,
    Oita: 44,
    Miyazaki: 45,
    Kagoshima: 46,
    Okinawa: 47,
    Tokyo23Wards: 101,
    Tokyo5Wards: 102,
} as const
export type AreaType = (typeof AreaType)[keyof typeof AreaType]

// 築年数
export const PriceType = {
    InitValue: null,
    Under1Billion: 1,
    Under5Billion: 2,
    Under10Billion: 3,
    Over10Billion: 4,
} as const
export type PriceType = (typeof PriceType)[keyof typeof PriceType]

// 築年数
export const BuildingAgeType = {
    InitValue: null,
    Under5Years: 1,
    Under10Years: 2,
    Under20Years: 3,
    Over20Years: 10,
} as const
export type BuildingAgeType = (typeof BuildingAgeType)[keyof typeof BuildingAgeType]

// 売却時期
export const SaleTimeType = {
    InitValue: 0,
    AsSoonAsPossible: 1,
    Within3Months: 2,
    Within6Months: 3,
    Within1Year: 4,
    DependsOnTheConditions: 10,
} as const
export type SaleTimeType = (typeof SaleTimeType)[keyof typeof SaleTimeType]

// 表面利回り
export const GrossRateType = {
    InitValue: null,
    Over4Percent: 40,
    Over5Percent: 50,
    Over6Percent: 60,
    Over7Percent: 70,
    Under3Percent: 100,
} as const
export type GrossRateType = (typeof GrossRateType)[keyof typeof GrossRateType]

// 実質利回り
export const NetRateType = {
    InitValue: null,
    Over3Percent: 30,
    Over4Percent: 40,
    Over5Percent: 50,
    Over6Percent: 60,
    Under2Percent: 100,
} as const
export type NetRateType = (typeof NetRateType)[keyof typeof NetRateType]

// 所有権の形態
export const OwnershipType = {
    FullOwnership: 1,
    ConditionOwnership: 2,
} as const
export type OwnershipType = (typeof OwnershipType)[keyof typeof OwnershipType]

// 耐震基準
export const SeismicStandardType = {
    InitValue: null,
    New: 1,
    Old: 2,
} as const
export type SeismicStandardType = (typeof SeismicStandardType)[keyof typeof SeismicStandardType]

// 遵法性
export const LegalComplianceType = {
    InitValue: null,
    HaveAProblem: 1,
    NoProblem: 2,
} as const
export type LegalComplianceType = (typeof LegalComplianceType)[keyof typeof LegalComplianceType]

/**
 * マーケット一覧のモーダル表示
 */

export const marketIndexModalType = {
    PreRegistration: 'pre_registration',
    RequestConfirm: `request_confirm`,
    LimitOver: `limit_over`,
} as const
export type marketIndexModalType = (typeof marketIndexModalType)[keyof typeof marketIndexModalType]
