import clsx from 'clsx'
import { t } from 'i18next'
import { CSSProperties, ReactNode, useMemo } from 'react'
import { createSearchParams, useParams } from 'react-router-dom'

import {
    FileEntities,
    RealEstateDocumentEntities,
    useClientIntermediaryDocumentIntermediaryToUuidQuery,
    useClientMediaGetSignedUrlMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { CTagList } from '~/components/common/cTagList/CTagList'
import { formatDateTime } from '~/util/common/common'
import { getSignedUrl, getSignedUrlString, isPdfFile } from '~/util/model/FileEntities'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser } from '~/util/store/userSlice'

const IntermediaryDocument = () => {
    const user = useAppSelector(selectLoginUser)
    const params = useParams()
    const { intermediaryUuid: intermediaryUuid } = params

    const { data: intermediaryTo, isLoading } = useClientIntermediaryDocumentIntermediaryToUuidQuery(
        { uuid: intermediaryUuid! },
        { skip: !intermediaryUuid },
    )

    // 資料一覧
    const filteredDocuments = useMemo(() => {
        return intermediaryTo?.intermediaryTo?.intermediaryToDocuments?.filter((d) => !d.documentFrom?.isMap)
    }, [intermediaryTo])

    /** ドキュメントのラベル */
    const getLabelArray = (item: RealEstateDocumentEntities) => {
        const labels: { className?: string; children: ReactNode; style?: CSSProperties }[] = []
        labels.push(
            ...(item.labels?.map((l) => ({
                className: clsx('text-xs', l.bgColor === '#ffffff' && 'border'),
                style: {
                    backgroundColor: l.bgColor,
                    color: l.letterColor,
                },
                children: <>{l.name}</>,
            })) ?? []),
        )
        return labels
    }

    // プレビュー
    const mutation = useClientMediaGetSignedUrlMutation()
    const previewDocument = async (file: FileEntities) => {
        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        const win = window.open(
            undefined,
            'file_preview',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )

        try {
            const fileUrl = isPdfFile(file) ? await getSignedUrlString(file, mutation) : getSignedUrl(file)
            const url = new URL(window.location.origin + '/file_preview')
            url.search = createSearchParams({
                fileName: file.filename,
                mineType: file.mimeType,
                src: fileUrl,
            }).toString()

            if (win) win.location.href = url.toString()
        } catch (e) {
            // ファイルの取得に失敗
        }
    }

    if (!user || isLoading) return null

    return (
        <div className={clsx('w-full', 'h-full', 'font-body', 'text-gray-700', 'bg-white')}>
            <div
                className={clsx(
                    'fixed',
                    'top-0',
                    'left-0',
                    'w-full',
                    'h-[45px]',
                    'bg-white',
                    'flex',
                    'items-center',
                    'border-t',
                    'border-b',
                )}>
                <div className={clsx('text-gray-700', 'text-lg', 'mx-4', 'font-bold')}>
                    {t('IntermediaryDocument.companyName customerNameに送信した資料', {
                        companyName: intermediaryTo?.intermediaryTo?.destinationTo?.companyName || '',
                        customerName: intermediaryTo?.intermediaryTo?.destinationTo?.name || '',
                    })}
                </div>
            </div>
            <div className={clsx('pt-[54px]', 'pb-[132px]', 'overflow-auto', 'px-2')}>
                {filteredDocuments?.length ?? 0 > 0 ? (
                    <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                        <thead>
                            <tr className={clsx('bg-kimar-primary', 'text-white', 'text-left')}>
                                <th className={clsx('p-1')}>{t('IntermediaryDocument.資料名')}</th>
                                <th className={clsx('p-1')}>{t('IntermediaryDocument.初回送信日時')}</th>
                                <th className={clsx('p-1')}>{t('IntermediaryDocument.閲覧回数')}</th>
                                <th className={clsx('p-1')}>{t('IntermediaryDocument.操作')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDocuments?.map((document) => (
                                <tr key={document.uuid} className={clsx('border', 'hover:bg-kimar-primary-light')}>
                                    <td className={clsx('p-1')}>
                                        <div className={clsx('flex', 'items-center', 'justify-between')}>
                                            <div>{document.file.filename}</div>
                                            <div>
                                                <CTagList horizontal items={getLabelArray(document.documentFrom)} />
                                            </div>
                                        </div>
                                    </td>
                                    <td className={clsx('p-1')}>{formatDateTime(document.createdAt, 'dateTime')}</td>
                                    <td className={clsx('p-1')}>
                                        {t('IntermediaryDocument.documentCount回', {
                                            documentCount: document.previewCount + document.downloadCount,
                                        })}
                                    </td>
                                    <td className={clsx('p-1')}>
                                        <div>
                                            <CButton
                                                className={clsx(
                                                    'bg-white',
                                                    'rounded',
                                                    'text-kimar-primary',
                                                    'border',
                                                    'border-kimar-primary',
                                                    'px-2',
                                                    'py-1',
                                                    'm-1',
                                                )}
                                                onClick={() => previewDocument(document.file)}>
                                                {t('IntermediaryDocument.プレビュー')}
                                            </CButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className={clsx('m-4')}>
                        <CMessage info>{t('IntermediaryDocument.送信した資料はありません')}</CMessage>
                    </div>
                )}
            </div>
        </div>
    )
}
export default IntermediaryDocument
