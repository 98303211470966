/* eslint-disable @typescript-eslint/no-explicit-any */
import { DraggableAttributes } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import ProgressBar from '@ramonak/react-progress-bar'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import { t } from 'i18next'
import { orderBy } from 'lodash'
import React, { CSSProperties, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import toast from 'react-hot-toast/headless'
import { createSearchParams } from 'react-router-dom'

import {
    ClientRealEstateDocumentAttachUpdateDto,
    ClientRealEstateDocumentCoverUpdateDto,
    ClientRealEstateDocumentUpdateDto,
    FileEntities,
    RealEstateDocumentEntities,
    RealEstateEntities,
    useClientIntermediaryPostPreviewMutation,
    useClientMediaGetSignedUrlMutation,
    useClientRealEstateDocumentDeleteUuidMutation,
    useClientRealEstateDocumentPostRealEstateDocumentMutation,
    useClientRealEstateDocumentPutRealEstateDocumentOrderMutation,
    useClientRealEstateDocumentPutUuidMutation,
} from '~/app/api'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CDragAndDropItemSet } from '~/components/common/cDragAndDropItemSet/CDragAndDropItemSet'
import { CDragRowTable } from '~/components/common/cDragRowTable/CDragRowTable'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CFooterModalV2 } from '~/components/common/cModal/CModalV2'
import { CRadioButton } from '~/components/common/cRadioButton/CRadioButton'
import { CTag } from '~/components/common/cTag/CTag'
import { CTagList } from '~/components/common/cTagList/CTagList'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { IApplicationError } from '~/types/error'
import { getSignedUrl, getSignedUrlString, isPdfFile } from '~/util/model/FileEntities'
import { selectCredential } from '~/util/store/authSlice'
import { setDisabledLoading } from '~/util/store/UISlice'
import { selectLoginUser } from '~/util/store/userSlice'

import { useAppDispatch, useAppSelector } from '../../../../util/store/hooks'
import { CButton } from '../../../common/cButton/CButton'
import { CCheckBoxList } from '../../../common/cCheckBoxList/CCheckBoxList'
import { CError } from '../../../common/cError/CError'
import { CFileUpload } from '../../../common/cFileUpload/CFileUpload'

type Props = {
    realEstate: RealEstateEntities
    refetch: () => void
}

const CRealestateDetailFile = ({ realEstate, refetch }: Props) => {
    // ログインしたユーザー
    const user = useAppSelector(selectLoginUser)
    const userRoleRealEstateWatchOnly = () => user?.roles.realestate === 1
    const [errors, setErrors] = useState<IApplicationError[]>([])
    const [isOpenFilter, setIsOpenFilter] = useState(false)
    const [filterLabels, setFilterLabels] = useState<string[]>([])

    const companyRealEstateLabels = useMemo(() => {
        return realEstate.team.company.labels ?? []
    }, [realEstate])

    const listingDocuments = useMemo(() => {
        const documents = realEstate.documents?.filter((d) => !d.isMap)
        return orderBy(documents ?? [], ['sort', 'createdAt'], ['asc', 'desc'])
    }, [realEstate, filterLabels])
    // 資料一覧(フィルタ後)
    const filteredDocuments = useMemo(() => {
        let documents = realEstate.documents?.filter((d) => !d.isMap)
        if (filterLabels.length > 0) documents = documents?.filter((d) => d.labels?.some((l) => filterLabels.includes(l.uuid)))
        return orderBy(documents ?? [], ['sort', 'createdAt'], ['asc', 'desc'])
    }, [realEstate, filterLabels])

    const [postPreview] = useClientIntermediaryPostPreviewMutation()

    // プレビューログ
    const sendPreviewLog = (documentUuid: string, method: 'preview' | 'download') => {
        postPreview({ clientIntermediaryDocumentPreviewDto: { documentUuid, method } }).catch((err) => {
            console.error(err)
        })
    }

    // 一括ダウンロード
    const bulkDownload = async () => {
        await downloadFile(
            `${process.env.BASE_URL}/api/client/real_estate_document/export/${realEstate.uuid}`,
            `${realEstate.name ?? ''}_${dayjs().format('YYYYMMDDHHmmss')}_realestate_documents.zip`,
        )
    }

    // 単体ダウンロード
    const downloadDocument = async (doc: RealEstateDocumentEntities) => {
        if (!doc.file) return
        if (doc.intermediaryDocumentFrom) sendPreviewLog(doc.intermediaryDocumentFrom.uuid, 'download')
        await downloadFile(getSignedUrl(doc.file), `${realEstate.name ?? ''}_${doc.file?.filename ?? ''}`)
    }

    const cred = useAppSelector(selectCredential)
    const downloadFile = async (path: string, name: string) => {
        if (!cred) return
        const header = {
            headers: {
                'X-Authorization': `Bearer ${cred}`,
            },
        }
        try {
            const response = await fetch(path, header)
            const blob = response.body
            const streamSaver = (await import('streamsaver')).default
            const fileStream = streamSaver.createWriteStream(name)
            await blob?.pipeTo(fileStream)
        } catch {
            // ファイルDLがキャンセルor中断された
        }
    }

    // プレビュー
    const mutation = useClientMediaGetSignedUrlMutation()
    const previewDocument = async (doc: RealEstateDocumentEntities) => {
        if (!doc.file) return
        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        const win = window.open(
            undefined,
            'file_preview',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )

        if (doc.intermediaryDocumentFrom) sendPreviewLog(doc.intermediaryDocumentFrom.uuid, 'preview')

        try {
            const fileUrl = isPdfFile(doc.file) ? await getSignedUrlString(doc.file, mutation) : getSignedUrl(doc.file)
            const url = new URL(window.location.origin + '/file_preview')
            url.search = createSearchParams({
                fileName: doc.file?.filename ?? '',
                mineType: doc.file?.mimeType ?? '',
                src: fileUrl,
            }).toString()

            if (win) win.location.href = url.toString()
        } catch (e) {
            // ファイルの取得に失敗
        }
    }

    // 削除
    const [deleteTarget, setDeleteTarget] = useState<RealEstateDocumentEntities>()
    const [deleteQuery] = useClientRealEstateDocumentDeleteUuidMutation()
    const deleteDocument = async () => {
        if (!deleteTarget) return
        await deleteQuery({ uuid: deleteTarget.uuid }).unwrap()
        setDeleteTarget(undefined)
        refetch()
        toast.success(t('CRealestateDetailFile.物件資料を削除しました'))
    }

    /** ドキュメントのラベル */
    const getLabelArray = (item: RealEstateDocumentEntities) => {
        const labels: { className?: string; children: ReactNode; style?: CSSProperties }[] = []
        labels.push(
            ...(item.labels?.map((l) => ({
                className: clsx('text-xs', l.bgColor === '#ffffff' && 'border'),
                style: {
                    backgroundColor: l.bgColor,
                    color: l.letterColor,
                },
                children: <>{l.name}</>,
            })) ?? []),
        )
        return labels
    }

    /** ソート */
    const [setDocumentOrderQuery] = useClientRealEstateDocumentPutRealEstateDocumentOrderMutation()
    const setOrder = async (documentsUuids: string[]) => {
        await setDocumentOrderQuery({
            clientRealEstateDocumentOrderDto: { realEstateUuid: realEstate.uuid, documentUuids: documentsUuids },
        })
        refetch()
    }

    /** ファイルアップロード */
    const dispatch = useAppDispatch()
    const [isUploading, setIsUploading] = useState(0)
    const preFileUpload = () => {
        dispatch(setDisabledLoading(true))
        setIsUploading(0.01)
    }
    const errorFileUpload = () => {
        setIsUploading(0)
        dispatch(setDisabledLoading(false))
    }
    const CUploadLoaderProgress = useMemo(() => {
        return <ProgressBar completed={isUploading * 100} isLabelVisible={false} className={clsx('w-1/2')} bgColor="#166534" />
    }, [isUploading])
    // loading時多重クリック抑制
    const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const CUploadLoader = (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.1,
                ease: 'easeInOut',
            }}
            onClick={onClickHandler}
            className={clsx('fixed', 'z-[9999]', 'inset-0', 'flex', 'items-center', 'justify-center', 'bg-white/50')}>
            {CUploadLoaderProgress}
        </motion.div>
    )

    const [realEstateFileSave] = useClientRealEstateDocumentPostRealEstateDocumentMutation()
    const fileUploaded = async (file?: FileEntities) => {
        setIsUploading(1)
        if (!file || !realEstate) return
        try {
            await realEstateFileSave({
                clientRealEstateDocumentInsertDto: {
                    realEstate,
                    document: file,
                    isCover: false,
                    isMap: false,
                },
            }).unwrap()
            setIsUploading(0)
            dispatch(setDisabledLoading(false))
            refetch()
            toast.success(t('CRealestateDetailFile.物件資料を追加しました'))
        } catch (e) {
            setErrors([
                {
                    ...((e as FetchBaseQueryError).data as IApplicationError),
                    message: t('CRealestateDetailFile.資料の追加に失敗しました'),
                },
            ])
            setIsUploading(0)
            dispatch(setDisabledLoading(false))
        }
    }

    /** ドキュメント編集 */

    const [editDto, setEditDto] = useState<ClientRealEstateDocumentUpdateDto & { uuid: string; isImage: boolean }>()
    const closeEditModal = () => {
        setEditDto(undefined)
    }
    const setProps = (key: string, value: unknown) => {
        if (!editDto) return
        setEditDto({
            ...editDto,
            [key]: value,
        })
    }
    const [editRelEstateDocumentQuery] = useClientRealEstateDocumentPutUuidMutation()
    const editRelEstateDocument = async () => {
        if (!editDto) return
        await editRelEstateDocumentQuery({ uuid: editDto.uuid, body: editDto })
        refetch()
        closeEditModal()
        toast.success(t('CRealestateDetailFile.物件資料を編集しました'))
    }

    // トップ画像設定
    const setTopDocument = async (document: RealEstateDocumentEntities) => {
        const documentDto: ClientRealEstateDocumentCoverUpdateDto = {
            isCover: true,
        }
        await editRelEstateDocumentQuery({ uuid: document.uuid, body: documentDto })
        refetch()
        closeEditModal()
        toast.success(t('CRealestateDetailFile.トップ画像を変更しました'))
    }

    // 送信可否設定
    const setAttachDocument = async (document: RealEstateDocumentEntities) => {
        const documentDto: ClientRealEstateDocumentAttachUpdateDto = {
            isAttachable: !document.isAttachable,
        }
        await editRelEstateDocumentQuery({ uuid: document.uuid, body: documentDto })
        refetch()
        closeEditModal()
        toast.success(t('CRealestateDetailFile.送信可否を変更しました'))
    }

    const closeAll = () => {
        setIsOpenFilter((v) => (v ? false : v))
    }
    const filterRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!filterRef) return
        const handleClickOutside = (e: MouseEvent) => {
            if (!(filterRef.current as Node)?.contains(e.target as Node)) closeAll()
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [filterRef])

    const columns = useMemo(
        () => [
            {
                id: t('CRealestateDetailFile.すべての資料ラベル'),
                title: t('CRealestateDetailFile.すべての資料ラベル'),
                cards: companyRealEstateLabels
                    .filter((l) => !editDto?.labelUuids.includes(l.uuid))
                    .sort((a, b) => a.sort - b.sort)
                    .map((tag) => {
                        return {
                            id: tag.uuid,
                            sort: tag.sort,
                            element: (
                                <CTag
                                    className={clsx('px-2', 'rounded', 'text-sm', tag.bgColor === '#ffffff' && 'border')}
                                    style={{
                                        backgroundColor: tag.bgColor,
                                        color: tag.letterColor,
                                    }}>
                                    {tag.name}
                                </CTag>
                            ),
                        }
                    }),
            },
            {
                id: t('CRealestateDetailFile.この物件資料に設定する資料ラベル'),
                title: t('CRealestateDetailFile.この物件資料に設定する資料ラベル'),
                cards: companyRealEstateLabels
                    .filter((l) => editDto?.labelUuids.includes(l.uuid))
                    .sort((a, b) => a.sort - b.sort)
                    .map((tag) => {
                        return {
                            id: tag.uuid,
                            sort: tag.sort,
                            element: (
                                <CTag
                                    className={clsx('px-2', 'rounded', 'text-sm', tag.bgColor === '#ffffff' && 'border')}
                                    style={{
                                        backgroundColor: tag.bgColor,
                                        color: tag.letterColor,
                                    }}>
                                    {tag.name}
                                </CTag>
                            ),
                        }
                    }),
            },
        ],
        [editDto],
    )

    const documentData = {
        id: 'documents',
        thead: (
            <tr className={clsx('bg-kimar-primary', 'text-white', 'text-left')}>
                <th className={clsx('pl-2', 'w-[24px]')} />
                <th className={clsx('pl-2', 'w-[54px]')}>{t('CRealestateDetailFile.トップ')}</th>
                <th className={clsx('pl-2', 'w-[54px]')}>{t('CRealestateDetailFile.送信可')}</th>
                <th className={clsx('pl-2')}>{t('CRealestateDetailFile.資料名')}</th>
                <th className={clsx('pl-2', 'w-[340px]')}>{t('CRealestateDetailFile.操作')}</th>
            </tr>
        ),
        tbody: filteredDocuments.map((document) => ({
            id: document.uuid,
            tr: (attributes: DraggableAttributes, listeners: SyntheticListenerMap | undefined) => (
                <>
                    <td {...attributes} {...listeners} className={clsx('cursor-grab')}>
                        <div className={clsx('flex', 'justify-center', 'items-center')}>
                            <i className={clsx('material-icons', 'text-gray-300', 'w-5')}>drag_indicator</i>
                        </div>
                    </td>
                    <td data-no-dnd="true">
                        <div className={clsx('flex', 'justify-center', 'items-center')}>
                            {/^image\//.test(document.file?.mimeType ?? '') && (
                                <CRadioButton
                                    data-no-dnd="true"
                                    items={[{ label: '', value: document.uuid }]}
                                    nowValue={filteredDocuments.filter((d) => d.isCover)[0]?.uuid ?? undefined}
                                    onChange={() => setTopDocument(document)}
                                />
                            )}
                        </div>
                    </td>
                    <td data-no-dnd="true">
                        <div className={clsx('flex', 'justify-center', 'items-center')}>
                            <CCheckBox
                                data-no-dnd="true"
                                disabled={document.isCover}
                                checked={document.isAttachable}
                                onChange={() => setAttachDocument(document)}
                            />
                        </div>
                    </td>
                    <td className={clsx('pl-2')} data-no-dnd="true">
                        <div
                            className={clsx(
                                'flex',
                                'flex-col',
                                'md:flex-row',
                                'md:items-center',
                                'md:justify-between',
                                'justify-start',
                            )}>
                            <div>{document.file?.filename}</div>
                            <div>
                                <CTagList horizontal items={getLabelArray(document)} />
                            </div>
                        </div>
                    </td>
                    <td data-no-dnd="true" className={clsx()}>
                        <div className={clsx('flex', 'items-center', 'space-x-1', 'justify-end')}>
                            <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-1', 'whitespace-nowrap')}>
                                <CButton
                                    className={clsx('c-button-secondary', 'text-sm')}
                                    onClick={() => previewDocument(document)}>
                                    {t('CRealestateDetailFile.プレビュー')}
                                </CButton>
                                <CButton
                                    className={clsx('c-button-secondary', 'text-sm')}
                                    onClick={() => downloadDocument(document)}>
                                    <div className={clsx('hidden', 'md:block')}>{t('CRealestateDetailFile.ダウンロード')}</div>
                                </CButton>
                            </div>
                            {user?.roles.realestate === 2 && (
                                <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-1', 'whitespace-nowrap')}>
                                    <CButton
                                        className={clsx('c-button-primary', 'text-sm')}
                                        onClick={() =>
                                            setEditDto({
                                                uuid: document.uuid,
                                                fileName: document.file?.filename ?? '',
                                                isMap: document.isMap,
                                                labelUuids: document.labels?.map((l) => l.uuid) ?? [],
                                                isImage: /^image\//.test(document.file?.mimeType ?? ''),
                                            })
                                        }>
                                        {t('Button.編集')}
                                    </CButton>
                                    <CButton
                                        className={clsx('c-button-danger', 'text-sm')}
                                        disabled={userRoleRealEstateWatchOnly()}
                                        onClick={() => setDeleteTarget(document)}>
                                        {t('Button.削除')}
                                    </CButton>
                                </div>
                            )}
                        </div>
                    </td>
                </>
            ),
        })),
    }

    const sdDocuments = {
        id: 'sdDocuments',
        thead: <></>,
        tbody: filteredDocuments.map((document, index) => ({
            id: document.uuid,
            tr: (
                <React.Fragment key={index}>
                    <div data-no-dnd="true" key={`filteredDocuments-sp-${document.uuid}-1`} className={clsx()}>
                        <div className={clsx('flex', 'gap-1')}>
                            <div className={clsx('flex', 'justify-center', 'items-center')}>
                                {/^image\//.test(document.file?.mimeType ?? '') && (
                                    <CRadioButton
                                        items={[{ label: t('CRealestateDetailFile.トップ'), value: document.uuid }]}
                                        nowValue={filteredDocuments.filter((d) => d.isCover)[0]?.uuid ?? undefined}
                                        onChange={() => setTopDocument(document)}
                                    />
                                )}
                            </div>
                            <div className={clsx('flex', 'justify-center', 'items-center')}>
                                <CCheckBox
                                    label={t('CRealestateDetailFile.送信可')}
                                    disabled={document.isCover}
                                    checked={document.isAttachable}
                                    onChange={() => setAttachDocument(document)}
                                />
                            </div>
                        </div>
                    </div>
                    <div key={`filteredDocuments-sp-${document.uuid}-2`} className={clsx('w-full')}>
                        <div className={clsx('pl-2')}>
                            <div
                                className={clsx(
                                    'flex',
                                    'flex-col',
                                    'md:flex-row',
                                    'md:items-center',
                                    'md:justify-between',
                                    'justify-start',
                                )}>
                                <div className={clsx('text-base')}>{document.file?.filename}</div>
                                <div>
                                    <CTagList horizontal items={getLabelArray(document)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-no-dnd="true" key={`filteredDocuments-sp--${document.uuid}-3`} className={clsx('border-b')}>
                        <div className={clsx('flex', 'flex-col', 'gap-1')}>
                            <div className={clsx('grid', 'grid-cols-2', 'gap-1', 'whitespace-nowrap')}>
                                <CButton
                                    className={clsx('c-button-secondary', 'text-xs')}
                                    onClick={() => previewDocument(document)}>
                                    {t('CRealestateDetailFile.プレビュー')}
                                </CButton>
                                <CButton
                                    className={clsx('c-button-secondary', 'text-xs')}
                                    onClick={() => downloadDocument(document)}>
                                    {t('CRealestateDetailFile.ダウンロード')}
                                </CButton>
                            </div>
                            <div className={clsx('flex', 'items-center', 'justify-end', 'gap-1')}>
                                {user?.roles.realestate === 2 && (
                                    <div className={clsx('flex', 'flex-row', 'gap-1', 'whitespace-nowrap')}>
                                        <CButton
                                            className={clsx('c-button-primary', 'text-xs')}
                                            onClick={() =>
                                                setEditDto({
                                                    uuid: document.uuid,
                                                    fileName: document.file?.filename ?? '',
                                                    isMap: document.isMap,
                                                    labelUuids: document.labels?.map((l) => l.uuid) ?? [],
                                                    isImage: /^image\//.test(document.file?.mimeType ?? ''),
                                                })
                                            }>
                                            {t('Button.編集')}
                                        </CButton>
                                        <CButton
                                            className={clsx('c-button-danger', 'text-xs')}
                                            disabled={userRoleRealEstateWatchOnly()}
                                            onClick={() => setDeleteTarget(document)}>
                                            {t('Button.削除')}
                                        </CButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ),
        })),
    }

    return (
        <div>
            {isUploading ? CUploadLoader : null}
            <div>
                <CError errors={errors} />
                {user?.roles.realestate === 2 && (
                    <CFileUpload
                        multipleMode
                        preFileUpload={preFileUpload}
                        errorFileUpload={errorFileUpload}
                        fileUploaded={(files) => files.forEach((f) => fileUploaded(f))}
                    />
                )}
            </div>

            <div ref={filterRef} className={clsx('my-2')}>
                <div className={clsx('flex', 'justify-between', 'flex-col', 'md:flex-row', 'gap-2')}>
                    <CButton className={clsx('c-button-secondary', 'text-sm')} onClick={() => setIsOpenFilter(!isOpenFilter)}>
                        <i className={clsx('material-icons-outlined', 'text-sm')}>filter_alt</i>
                        <div>{t('CRealestateDetailFile.絞り込み')}</div>
                    </CButton>
                    <div
                        className={clsx(
                            'absolute',
                            'bg-white',
                            'p-2',
                            'mt-7',
                            'shadow',
                            'z-30',
                            'rounded',
                            !isOpenFilter && 'hidden',
                            'min-w-[200px]',
                            'border',
                        )}>
                        <CCheckBoxList
                            items={companyRealEstateLabels}
                            dataLabel={'name'}
                            key={'uuid'}
                            checked={filterLabels}
                            onChange={(value) => setFilterLabels(value as string[])}
                            dataValue="uuid"
                        />
                        <CButton className={clsx('c-button-secondary', 'w-full', 'mb-1')} onClick={() => setFilterLabels([])}>
                            {t('CRealestateDetailFile.絞り込みをリセット')}
                        </CButton>
                        <CButton className={clsx('c-button-primary', 'w-full')} onClick={() => setIsOpenFilter(false)}>
                            {t('CRealestateDetailFile.絞り込みを閉じる')}
                        </CButton>
                    </div>
                    <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-2')}>
                        {(listingDocuments.length ?? 0) > 0 && (
                            <CButton className={clsx('c-button-primary', 'text-sm')} onClick={bulkDownload}>
                                <i className={clsx('material-icons', 'text-sm')}>download</i>
                                <div>{t('CRealestateDetailFile.資料を一括ダウンロード')}</div>
                            </CButton>
                        )}
                    </div>
                </div>
            </div>
            <div className={clsx('hidden', 'md:block')}>
                {(filteredDocuments?.length ?? 0) > 0 ? (
                    <CDragRowTable
                        data={documentData}
                        onChange={(data) => {
                            setOrder(data.tbody.map((card) => card.id))
                        }}
                        fixed
                    />
                ) : (
                    <CMessage info>{t('CRealestateDetailFile.物件資料がありません')}</CMessage>
                )}
            </div>
            <div className={clsx('md:hidden')}>
                {(filteredDocuments?.length ?? 0) > 0 ? (
                    <CDragRowTable
                        data={sdDocuments}
                        onChange={(data) => {
                            setOrder(data.tbody.map((card) => card.id))
                        }}
                    />
                ) : (
                    <CMessage info>{t('CRealestateDetailFile.物件資料がありません')}</CMessage>
                )}
            </div>
            {/* 削除確認モーダル */}
            <CConfirmModal
                visible={!!deleteTarget}
                confirmLabel={t('Button.削除')}
                onRequestClose={() => setDeleteTarget(undefined)}
                onRequestConfirm={() => deleteDocument()}>
                {t('CRealestateDetailFile.物件資料「filename」を削除します。よろしいですか？', {
                    filename: deleteTarget?.file?.filename,
                })}
            </CConfirmModal>
            <CFooterModalV2
                footer={
                    <>
                        <CButton className={clsx('c-button-secondary')} onClick={() => closeEditModal()}>
                            {t('Button.キャンセル')}
                        </CButton>
                        <CButton
                            className={clsx('c-button-primary')}
                            onClick={() => {
                                editRelEstateDocument()
                            }}>
                            {t('CRealestateDetailFile.編集確定')}
                        </CButton>
                    </>
                }
                visible={!!editDto}
                onRequestClose={closeEditModal}
                content={{ width: 1000, height: '70%' }}>
                <div className={clsx('flex', 'flex-col', 'overflow-hidden', 'h-full')}>
                    <div className={clsx('p-2', 'w-full', 'flex', 'justify-center')}>
                        <div className={clsx('text-kimar-primary', 'text-lg', 'font-bold')}>
                            {t('CRealestateDetailFile.物件資料を編集')}
                        </div>
                    </div>

                    <div className={clsx('m-2')}>
                        <CLabeledItem label="物件資料名" className={clsx('mx-2')} required>
                            <CTextInput
                                type={'text'}
                                placeholder={'物件資料.jpg'}
                                text={editDto?.fileName}
                                className={clsx('w-full', 'c-text-input-base')}
                                onChange={(value) => setProps('fileName', value)}
                            />
                        </CLabeledItem>
                    </div>
                    <div className={clsx('p-4', 'flex-1', 'overflow-hidden', 'flex')}>
                        <CDragAndDropItemSet
                            data={columns}
                            autoSorting
                            onChange={(data) => {
                                setProps(
                                    'labelUuids',
                                    data[1].cards.map((c) => c.id),
                                )
                            }}
                        />
                    </div>
                </div>
            </CFooterModalV2>
        </div>
    )
}

export default CRealestateDetailFile
