import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RootState } from '~/util/store/store'

// APIのベースとなるもの
// エンドポイント定義を別ファイルからinjectする
// TODO: 各クエリ呼び出し時に{ skip: !userTeam }を引数に付与
export const apiBase = createApi({
    baseQuery: fetchBaseQuery({
        // baseUrlは.env記載の値を使う
        baseUrl: process.env.BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            // By default, if we have a credential in the store, let's use that for authenticated requests
            const { credential } = (getState() as RootState).persistedReducer.auth
            if (credential) headers.set('x-authorization', `Bearer ${credential}`)
            return headers
        },
    }),
    tagTypes: [
        'Realestate',
        'UnreadChat',
        'UncheckedRecommend',
        'UncheckedExternalRecommend',
        'UncheckedMarketRequest',
        'RealestateIntermediaries',
        'Customer',
        'CustomerReferences',
        'CustomerMemos',
        'MarketRealestate',
        'UncheckedMarketRealestate',
        'Member',
        'RealestateDocuments',
        'Inquiry',
    ],
    endpoints: () => ({}),
})

export default apiBase
