import clsx from 'clsx'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import { useAdminInquiryGetInquiryListQuery } from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CPager } from '~/components/common/cPager/CPager'
import { CTag } from '~/components/common/cTag/CTag'
import { formatDateTime, pageTitleTemplate } from '~/util/common/common'
// import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { inquiryStatusFormat } from '~/util/filter/format'

const AdminInquiry = () => {
    const [page, setPage] = useState(1)
    const onPageChanged = (_page: number) => {
        setPage(_page)
    }

    // 検索フォーム
    // const [form, setForm] = useState<{
    //     email: string
    //     name: string
    //     phone: string
    //     companyName: string
    //     subject: string
    // }>({
    //     email: '',
    //     name: '',
    //     phone: '',
    //     companyName: '',
    //     subject: '',
    // })

    // APIから問い合わせ一覧を取得
    const { data: inquiries, isLoading: isLoadingInquiries } = useAdminInquiryGetInquiryListQuery({ page: page })
    // TODO: API側で検索に対応
    // const { data: inquiries, isLoading: isLoadingInquiries } = useAdminInquiryGetInquiryListQuery({
    //     email: form.email,
    //     name: form.name,
    //     phone: form.phone,
    //     companyName: form.companyName,
    //     subject: form.subject,
    // })
    // 検索フォームイベントハンドラ
    // const formTextChanged = (value: string, target: string) => {
    //     setForm((oldValue) => ({ ...oldValue, [target]: value }))
    // }

    return (
        <>
            <div className="page-layout">
                <Helmet titleTemplate={pageTitleTemplate(true)}>
                    <title>問い合わせ管理</title>
                </Helmet>
                <CHeader label="問い合わせ管理" />
                <div className="page-content">
                    {/* TODO: 未実装なのでコメントアウト */}
                    {/* <div className={clsx('flex')}>
                        <div className={clsx('pl-4', 'whitespace-nowrap', 'self-center')}>絞り込み</div>
                        <div className={clsx('p-4', 'grid', 'grid-cols-6', 'gap-1')}>
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                text={form.email}
                                placeholder={'メールアドレス'}
                                onChange={(value) => formTextChanged(value, 'email')}
                            />
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                text={form.name}
                                placeholder={'氏名'}
                                onChange={(value) => formTextChanged(value, 'name')}
                            />
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                text={form.phone}
                                placeholder={'電話番号'}
                                onChange={(value) => formTextChanged(value, 'phone')}
                            />
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                text={form.companyName}
                                placeholder={'会社名'}
                                onChange={(value) => formTextChanged(value, 'companyName')}
                            />
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                text={form.subject}
                                placeholder={'件名'}
                                onChange={(value) => formTextChanged(value, 'subject')}
                            />
                        </div>
                    </div> */}

                    <div className={clsx('w-full', 'p-2')}>
                        {isLoadingInquiries && <CMessage info>読み込み中です...</CMessage>}
                        <div className={clsx('flex', 'justify-end')}>
                            <CPager page={page} pageSize={50} total={inquiries?.count ?? 0} onPageChanged={onPageChanged} />
                        </div>
                        <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                            <thead>
                                <tr className={clsx('bg-kimar-primary', 'text-white')}>
                                    <th>問い合わせ日</th>
                                    <th>件名</th>
                                    <th>会社名</th>
                                    <th>氏名</th>
                                    <th className={clsx('w-24')}>対応状況</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inquiries?.list.map((inquiry) => (
                                    <tr key={inquiry.uuid} className={clsx('border')}>
                                        <td className={clsx('p-2')}>{formatDateTime(inquiry.createdAt, 'date')}</td>
                                        <td className={clsx('p-2')}>
                                            <Link to={inquiry.uuid} className={clsx('text-kimar-primary')}>
                                                {inquiry.subject}
                                            </Link>
                                        </td>
                                        <td className={clsx('p-2')}>{inquiry.companyName}</td>
                                        <td className={clsx('p-2')}>{inquiry.name}</td>
                                        <td className={clsx('p-2', 'text-center')}>
                                            <CTag
                                                className={clsx(
                                                    'rounded-md',
                                                    !inquiry.status ? 'bg-red-700' : 'bg-kimar-primary',
                                                    'px-2',
                                                    'text-white',
                                                )}>
                                                {inquiryStatusFormat(inquiry.status)}
                                            </CTag>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminInquiry
