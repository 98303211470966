import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useMemo, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import { AdminTeamMemberInsertDto, TeamEntities, TeamMemberEntities, useAdminTeamMemberPostTeamMemberMutation } from '~/app/api'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CDropDown } from '~/components/common/cDropdown/CDropdown'
import { CError } from '~/components/common/cError/CError'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { companyRoles, customerRoles, intermediaryRoles, realestateRoles, teamRoles } from '~/types/enum/enum'
import { ApplicationError, IApplicationError } from '~/types/error'

export type InsertTeamMemberModalViewProps = {
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (key: string, value: unknown) => void
    setRolesProp: (key: string, value: unknown) => void
    dto: AdminTeamMemberInsertDto
    disableSubmit: boolean
    errors: IApplicationError[]
} & InsertTeamMemberModalProps

export const InsertTeamMemberModalView: VFC<InsertTeamMemberModalViewProps> = ({
    isShow,
    team,
    onRequestConfirm,
    onRequestClose,
    setProps,
    setRolesProp,
    dto,
    disableSubmit,
    errors,
}) => {
    return (
        <CConfirmModal
            title={t('InsertTeamMemberModal.チームメンバーを追加')}
            visible={!!isShow}
            content={{ width: '700px' }}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            disableSubmit={disableSubmit}>
            <CError errors={errors} />
            <div className={clsx('flex', 'flex-col', 'space-y-2')}>
                {team && (
                    <CMessage info>
                        {t('InsertTeamMemberModal.チーム「name」にメンバーを追加します', { name: team.name })}
                    </CMessage>
                )}
                <CLabeledItem label={t('InsertTeamMemberModal.氏名')} required className={clsx('w-full')}>
                    <CTextInput
                        className={clsx('w-full', 'c-text-input-base')}
                        type={'text'}
                        placeholder={t('InsertTeamMemberModal.山田 太郎')}
                        text={dto.name}
                        onChange={(val) => setProps('name', val)}
                    />
                </CLabeledItem>
                <div className={clsx('flex', 'space-x-2')}>
                    <CLabeledItem label={t('InsertTeamMemberModal.氏名(かな)')} required className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={t('InsertTeamMemberModal.やまだ たろう')}
                            text={dto.nameSound}
                            onChange={(val) => setProps('nameSound', val)}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('InsertTeamMemberModal.メールアドレス')} required className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={'test@example.com'}
                            text={dto.email}
                            onChange={(val) => setProps('email', val)}
                        />
                    </CLabeledItem>
                </div>
                <div className={clsx('flex', 'space-x-2')}>
                    <CLabeledItem label={t('InsertTeamMemberModal.部署名')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={''}
                            text={dto.departmentName}
                            onChange={(val) => setProps('departmentName', val)}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('InsertTeamMemberModal.役職')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={''}
                            text={dto.position}
                            onChange={(val) => setProps('position', val)}
                        />
                    </CLabeledItem>
                </div>
                <div className={clsx('flex', 'space-x-2')}>
                    <CLabeledItem label={t('InsertTeamMemberModal.電話番号')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={'00-1111-2222'}
                            text={dto.phone}
                            onChange={(val) => setProps('phone', val)}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('InsertTeamMemberModal.内線番号')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={''}
                            text={dto.phoneExtension}
                            onChange={(val) => setProps('phoneExtension', val)}
                        />
                    </CLabeledItem>
                </div>
                <div className={clsx('flex', 'space-x-2')}>
                    <CLabeledItem label={t('InsertTeamMemberModal.携帯電話番号')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={'090-1111-2222'}
                            text={dto.mobile}
                            onChange={(val) => setProps('mobile', val)}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('InsertTeamMemberModal.並び順')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'number'}
                            placeholder={'0'}
                            text={String(dto.sort)}
                            onChange={(val) => setProps('sort', Number(val))}
                        />
                    </CLabeledItem>
                </div>
                <div className={clsx('grid', 'grid-cols-3', 'gap-2')}>
                    <CLabeledItem label={t('InsertTeamMemberModal.会社権限')}>
                        <CDropDown
                            className={clsx('w-full', 'c-text-input-base')}
                            items={companyRoles}
                            nowValue={String(dto.roles.company)}
                            onChange={(val) => setRolesProp('company', Number(val))}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('InsertTeamMemberModal.チーム権限')}>
                        <CDropDown
                            className={clsx('w-full', 'c-text-input-base')}
                            items={teamRoles}
                            nowValue={String(dto.roles.team)}
                            onChange={(val) => setRolesProp('team', Number(val))}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('InsertTeamMemberModal.物件権限')}>
                        <CDropDown
                            className={clsx('w-full', 'c-text-input-base')}
                            items={realestateRoles}
                            nowValue={String(dto.roles.realestate)}
                            onChange={(val) => setRolesProp('realestate', Number(val))}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('InsertTeamMemberModal.顧客権限')}>
                        <CDropDown
                            className={clsx('w-full', 'c-text-input-base')}
                            items={customerRoles}
                            nowValue={String(dto.roles.customer)}
                            onChange={(val) => setRolesProp('customer', Number(val))}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('InsertTeamMemberModal.紹介権限')}>
                        <CDropDown
                            className={clsx('w-full', 'c-text-input-base')}
                            items={intermediaryRoles}
                            nowValue={String(dto.roles.intermediary)}
                            onChange={(val) => setRolesProp('intermediary', Number(val))}
                        />
                    </CLabeledItem>
                    {/* Limarアカウント及びいえらぶアカウントのみ運営管理権限付与可 */}
                    {(dto.email.endsWith('@limar.co.jp') || dto.email.endsWith('@ielove-group.jp')) && (
                        <CLabeledItem label={t('InsertTeamMemberModal.運営管理権限')}>
                            <CCheckBox
                                className={clsx('w-full', 'c-text-input-base')}
                                label={t('InsertTeamMemberModal.運営管理権限')}
                                checked={dto.roles.system}
                                onChange={(val) => setRolesProp('system', val)}
                            />
                        </CLabeledItem>
                    )}
                </div>
            </div>
        </CConfirmModal>
    )
}

export type InsertTeamMemberModalProps = {
    isShow: boolean
    team: TeamEntities
    onClose: () => void
    onSubmit: (teamMember: TeamMemberEntities) => void
}

export const InsertTeamMemberModal: VFC<InsertTeamMemberModalProps> = ({ ...props }) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const onRequestClose = () => {
        setDto(createNewDto())
        props.onClose()
    }

    const [insertTeamMemberQuery] = useAdminTeamMemberPostTeamMemberMutation()
    const onRequestConfirm = async () => {
        try {
            const team = await insertTeamMemberQuery({ adminTeamMemberInsertDto: dto }).unwrap()
            props.onSubmit(team)
            toast.success(t('InsertTeamMemberModal.チームメンバーを追加しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const createNewDto = () =>
        ({
            teamUuid: props.team.uuid,
            name: '',
            nameSound: '',
            email: '',
            sort: 0,
            roles: {
                system: false,
                team: 2,
                realestate: 2,
                customer: 2,
                intermediary: 2,
                company: 2,
                approvalFlow: 1,
            },
            departmentName: '',
            position: '',
            phone: '',
            phoneExtension: '',
            mobile: '',
        } as AdminTeamMemberInsertDto)

    useEffect(() => {
        setDto({
            ...dto,
            teamUuid: props.team.uuid,
        })
    }, [props.team])

    const [dto, setDto] = useState<AdminTeamMemberInsertDto>(createNewDto())

    const setProps = (key: string, value: unknown) => {
        setDto({
            ...dto,
            [key]: value,
        })
    }

    const setRolesProp = (key: string, value: unknown) => {
        const roles = {
            ...dto.roles,
            [key]: value,
        }
        setDto({
            ...dto,
            roles,
        })
    }

    const disableSubmit = useMemo(() => {
        return !dto.name || !dto.nameSound || !dto.email
    }, [dto])

    return (
        <InsertTeamMemberModalView
            {...props}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            dto={dto}
            setProps={setProps}
            setRolesProp={setRolesProp}
            disableSubmit={disableSubmit}
            errors={errors}
        />
    )
}
