import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { useNavigate, useParams } from 'react-router-dom'

import { useClientUserActivateQuery, useClientUserPostActivateMutation } from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { pageTitleTemplate } from '~/util/common/common'

import { CButton } from '../../components/common/cButton/CButton'
import { CError } from '../../components/common/cError/CError'
import { CLabeledItem } from '../../components/common/cLabeledItem/CLabeledItem'
import { CTextInput } from '../../components/common/cTextInput/CTextInput'
import { IApplicationError } from '../../types/error'

const ActivatePassword = () => {
    const navigate = useNavigate()
    const [errors, setErrors] = useState<Array<IApplicationError>>([])

    const params = useParams()
    const { token } = params

    const [isSent, setIsSent] = useState(false)
    useEffect(() => {
        // resetされたのでTOPに遷移
        if (isSent) {
            setTimeout(() => {
                navigate('/')
            }, 3 * 1000)
        }
    }, [isSent])

    const [formState, setFormState] = useState({
        password: '',
    })

    const { data: teamMember, error, isError } = useClientUserActivateQuery({ token: token ?? '' }, { skip: isSent })
    useEffect(() => {
        if (error) setErrors([(error as FetchBaseQueryError).data as IApplicationError])
    }, [isError])
    const [resetPassword] = useClientUserPostActivateMutation()
    const clickHandler = async () => {
        try {
            setIsSent(true)
            await resetPassword({ token: token ?? '', userResetPasswordDto: { password: formState.password } }).unwrap()
            toast.success(t('ActivatePassword.パスワードを登録しました'))
        } catch (e) {
            setIsSent(false)
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const disableSavePassword = useMemo(() => {
        if (!formState.password) return true
        if (formState.password.trim().length === 0) return true
        if (formState.password.trim().length > 0 && formState.password.trim().length < 8)
            return t('ActivatePassword.パスワードは8文字以上の文字列にしてください。')
        if (!formState.password.trim().match(/^(?=.*[-_%$#])(?=.*[0-9])(?=.*[a-zA-Z])/)) {
            return t(
                'ActivatePassword.パスワードは半角の英字と数字と記号（「-」「_」「%」「$」「#」のみ）をすべて含んでください。',
            )
        }
        return false
    }, [formState])

    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('ActivatePassword.アカウント発行')}</title>
            </Helmet>
            <CHeader label={t('ActivatePassword.アカウント発行')} />
            {!isSent ? (
                <div
                    className={clsx('max-w-3xl', 'w-full', 'mx-auto', 'p-4', 'flex', 'flex-col', 'gap-4', 'bg-white', 'rounded')}>
                    <CError errors={errors} />
                    {!isError && !!teamMember ? (
                        <>
                            <CMessage warning>
                                {t('ActivatePassword.member 様のアカウントが発行されました。', { member: teamMember.name })}
                                <br />
                                {t('ActivatePassword.下記の情報をご確認の上、パスワードを設定してキマールをご利用ください。')}
                            </CMessage>
                            <div className={clsx('grid', 'grid-cols-2', 'gap-2', 'my-2')}>
                                <div className={clsx('rounded', 'border', 'border-gray-100')}>
                                    <div className={clsx('bg-gray-100', 'p-2')}>{t('ActivatePassword.組織情報')}</div>
                                    <div className={clsx('p-4')}>
                                        <table className={clsx('text-sm', 'w-full')}>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.組織名')}</td>
                                                <td>{teamMember.team.name}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.会社名')}</td>
                                                <td>{teamMember.team.company.name}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.住所')}</td>
                                                <td>{teamMember.team.company.address}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.電話番号')}</td>
                                                <td>{teamMember.team.company.phone}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.FAX番号')}</td>
                                                <td>{teamMember.team.company.fax}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className={clsx('rounded', 'border', 'border-gray-100')}>
                                    <div className={clsx('bg-gray-100', 'p-2')}>
                                        {t('ActivatePassword.member様の情報', { member: teamMember.name })}
                                    </div>
                                    <div className={clsx('p-4')}>
                                        <table className={clsx('text-sm', 'w-full')}>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.部署名')}</td>
                                                <td>{teamMember.departmentName}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.役職')}</td>
                                                <td>{teamMember.position}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.氏名')}</td>
                                                <td>{teamMember.name}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.氏名（かな）')}</td>
                                                <td>{teamMember.nameSound}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.メールアドレス')}</td>
                                                <td>{teamMember.email}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.電話番号')}</td>
                                                <td>{teamMember.team.company.phone}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.内線番号')}</td>
                                                <td>{teamMember.phoneExtension}</td>
                                            </tr>
                                            <tr className={clsx('border-b')}>
                                                <td>{t('ActivatePassword.携帯番号')}</td>
                                                <td>{teamMember.mobile}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={clsx('w-full')}>
                                <CLabeledItem label={t('ActivatePassword.パスワードを設定してください')} required>
                                    <CTextInput
                                        text={formState.password}
                                        onChange={(value) => setFormState((prev) => ({ ...prev, password: value }))}
                                        type={'password'}
                                        className={clsx('w-full', 'c-text-input-base')}
                                    />
                                </CLabeledItem>
                            </div>
                            {disableSavePassword && disableSavePassword !== true && (
                                <CMessage danger>{disableSavePassword}</CMessage>
                            )}
                            <div className={clsx('w-full', 'flex', 'justify-center')}>
                                <CButton
                                    onClick={clickHandler}
                                    disabled={isSent || !!disableSavePassword}
                                    className={clsx('c-button-primary', 'w-full')}>
                                    {t('Button.送信')}
                                </CButton>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <CMessage info>
                    {t('ActivatePassword.パスワードの設定が完了しました。')}
                    <br />
                    {t('ActivatePassword.自動的にトップページへ遷移します。')}
                </CMessage>
            )}
        </>
    )
}

export default ActivatePassword
