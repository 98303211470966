import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from './store'

const slice = createSlice({
    name: 'auth',
    initialState: { credential: undefined } as {
        credential: undefined | string
    },
    reducers: {
        // ログインPOST結果のuserとcredentialを保存
        setCredentials: (state, { payload: { credential } }: PayloadAction<{ credential: undefined | string }>) => ({
            ...state,
            credential, // credential: credential,
        }),
    },
})

// sliceからactionを切り出す
export const { setCredentials } = slice.actions

// Credential
export const selectCredential = (state: RootState) => state.persistedReducer.auth.credential

// sliceからreducerを切り出す
export default slice.reducer
