import clsx from 'clsx'

import { CustomerEntities } from '~/app/api'

import CCustomerDetailAssignee from './CCustomerDetailAssignee'
import CCustomerDetailMemo from './CCustomerDetailMemo'

type Props = {
    customer: CustomerEntities
    refetch: () => void
}

const CCustomerDetailManage = ({ customer, refetch }: Props) => {
    return (
        <div className={clsx('p-4', 'flex', 'flex-col', 'gap-4')}>
            <CCustomerDetailAssignee customer={customer} refetch={refetch} />
            <CCustomerDetailMemo customer={customer} refetch={refetch} />
        </div>
    )
}

export default CCustomerDetailManage
