import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { isEqual, isNil, round, sortBy, uniq, uniqBy } from 'lodash'
import { CSSProperties, ReactNode, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Trans } from 'react-i18next'
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'

import {
    CustomerEntities,
    RealEstateDocumentEntities,
    RealEstateEntities,
    useClientCustomerCustomerQuery,
    useClientIntermediaryDocumentRealEstateUuidQuery,
    useClientIntermediaryPostIntermediaryMutation,
    useClientIntermediaryPostPreviewMutation,
    useClientMediaGetSignedUrlMutation,
    useClientNotificationTemplateNotificationTemplateQuery,
    useClientRealEstateConnectionRealEstateConnectionQuery,
    useClientRealEstateMarketRequestQuery,
    useClientRealEstateRealEstateQuery,
    useClientRealEstateUuidQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CDropDown } from '~/components/common/cDropdown/CDropdown'
import { CError } from '~/components/common/cError/CError'
import { CHeader } from '~/components/common/cHeader/CHeader'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CNumberInput } from '~/components/common/cNumberInput/CNumberInput'
import { CPager } from '~/components/common/cPager/CPager'
import { CTagList } from '~/components/common/cTagList/CTagList'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import {
    CCustomerSearch,
    CustomerSearchFilterArrayType,
    CustomerSearchFormValue,
} from '~/components/unique/customer/CCustomerSearch'
import { MAX_PRICE_INTEGER, structureTypeItems, taxType } from '~/types/enum/enum'
import { ApplicationError, BadRequest, IApplicationError } from '~/types/error'
import { formatDateTime, fullCurrencyFormat, htmlEntities, pageTitleTemplate } from '~/util/common/common'
import { getSignedUrl, getSignedUrlString, isPdfFile } from '~/util/model/FileEntities'
import { useAppSelector } from '~/util/store/hooks'
import { selectIsPlanTrial, selectLoginUser, selectLoginUserCompany } from '~/util/store/userSlice'

const Intermediary = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { realEstateUuid } = params

    const search = useLocation().search
    const query = new URLSearchParams(search)
    const option = {
        customers: query.getAll('customers'),
        connections: query.getAll('connections'),
        markets: query.getAll('markets'),
    }

    const { data: connections } = useClientRealEstateConnectionRealEstateConnectionQuery(
        {
            uuid: realEstateUuid!,
            connectionUuidList: option.connections!,
        },
        { skip: !realEstateUuid || !option.connections.length },
    )

    const { data: markets } = useClientRealEstateMarketRequestQuery(
        { uuid: realEstateUuid!, publishRequestUuidList: option.markets! },
        { skip: !realEstateUuid || !option.markets.length },
    )

    const { data: realEstate } = useClientRealEstateUuidQuery(
        {
            uuid: realEstateUuid!,
        },
        { skip: !realEstateUuid },
    )

    const { data: intermediaryDocument } = useClientIntermediaryDocumentRealEstateUuidQuery(
        {
            uuid: realEstateUuid!,
        },
        { skip: !realEstateUuid },
    )

    const user = useAppSelector(selectLoginUser)
    const company = useAppSelector(selectLoginUserCompany)
    // 紹介権限なかったらリダイレクト
    if (user?.roles.intermediary === 1) navigate('/realestate')

    // 無料会員
    const isPlanTrial = useAppSelector(selectIsPlanTrial)
    if (isPlanTrial) {
        const { data: sendingRealEstate } = useClientRealEstateRealEstateQuery({
            page: 1,
            pageSize: 1,
            name: '',
            order: 'createdAt',
            filter: ['sending'],
            sort: 'DESC',
            useTypes: [],
            prices: [],
            buildingAges: [],
            saleTimes: [],
            progress: [],
            assignedToType: 'OR',
            assignedToUuidList: [],
            labelType: 'OR',
            labelUuidList: [],
            grossRate: [],
            salesDestination: [],
        })
        // 紹介している物件がヒットする場合はリダイレクト
        if (!!sendingRealEstate?.list?.length && (sendingRealEstate.list as RealEstateEntities[])[0].uuid !== realEstate?.uuid)
            navigate('/realestate?trialIntermediary=true')
    }

    const [errors, setErrors] = useState<Array<IApplicationError>>([])

    const getTagArray = (item: RealEstateEntities) => {
        const tags: { className?: string; children: ReactNode; style?: CSSProperties }[] = []
        const labels =
            item.labels?.map((l) => ({
                className: clsx('text-xs', 'text-white', 'rounded-sm'),
                style: {
                    backgroundColor: l.bgColor,
                    color: l.letterColor,
                },
                children: <>{l.name}</>,
            })) ?? []
        tags.push(...labels)
        return tags
    }

    const [customerPage, setCustomerPage] = useState(1)
    const maxCount = 100

    // 検索フォーム
    const [form, setForm] = useState<{
        name: string
        order: 'name' | 'company'
        filter: CustomerSearchFilterArrayType
        sort: 'ASC' | 'DESC'
        useTypes: string[]
        prices: string[]
        areas: string[]
        buildingAges: string[]
        registeredTypes: string[]
        saleTimes: string[]
        assignedToType: 'AND' | 'OR'
        assignedToUuidList: string[]
        labelType: 'AND' | 'OR'
        labelUuidList: string[]
        customerUuidList: string[]
        realEstate: string
    }>({
        name: '',
        order: 'name',
        filter: [],
        sort: 'ASC',
        useTypes: [],
        prices: [],
        areas: [],
        buildingAges: [],
        registeredTypes: [],
        saleTimes: [],
        assignedToType: 'OR',
        assignedToUuidList: [],
        labelType: 'OR',
        labelUuidList: [],
        customerUuidList: option?.customers ?? [],
        realEstate: '',
    })
    const { data: _customerList, isLoading } = useClientCustomerCustomerQuery({
        page: customerPage,
        maxCount: maxCount,
        ...form,
    })

    const onCustomerPageChanged = (_page: number) => {
        setCustomerPage(_page)
    }

    const customerList = useMemo(():
        | {
              uuid: string
              companyName: string
              name: string
              departmentName?: string
              position?: string
              intermediaryName?: string
          }[]
        | undefined => {
        if (connections?.list) {
            return connections.list
                .map((c) => {
                    return {
                        uuid: c.uuid!,
                        name: c.memberName!,
                        companyName: c.memberCompanyName!,
                    }
                })
                .filter((c): c is Exclude<typeof c, undefined> => !!c)
        }
        if (markets?.list) {
            return markets.list
                .map((c) => {
                    return {
                        uuid: c.uuid!,
                        name: c.requestedName!,
                        companyName: c.requestedCompanyName!,
                    }
                })
                .filter((c): c is Exclude<typeof c, undefined> => !!c)
        }

        return _customerList?.list as
            | { uuid: string; companyName: string; name: string; departmentName?: string; position?: string }[]
            | undefined
    }, [_customerList, connections, markets])

    // すべての顧客に紹介用の全顧客リスト
    const { data: _allCustomerList } = useClientCustomerCustomerQuery(
        {
            name: '',
            order: 'name',
            filter: [],
            sort: 'ASC',
            useTypes: [],
            prices: [],
            areas: [],
            buildingAges: [],
            registeredTypes: [],
            saleTimes: [],
            assignedToType: 'OR',
            assignedToUuidList: [],
            labelType: 'OR',
            labelUuidList: [],
            customerUuidList: option?.customers ?? [],
            realEstate: '',
        },
        { skip: !!option.customers.length || !!option.connections.length || !!option.markets.length },
    )
    const allCustomerList = useMemo(() => {
        return _allCustomerList?.list as CustomerEntities[]
    }, [_allCustomerList])

    // 資料一覧
    const filteredDocuments = useMemo(() => {
        return sortBy(realEstate?.documents?.filter((d) => !d.isMap && !d.isCover && d.isAttachable) ?? [], (s) => s.sort)
    }, [realEstate])

    const [isAllCustomer, setIsAllCustomer] = useState(false)

    const onChangeSearchVal = (val: CustomerSearchFormValue) => {
        setCustomerPage(1)
        setForm({
            ...form,
            ...val,
        })
    }

    type SendDocuments = {
        customerUuid: string
        companyName: string
        customerName: string
        position?: string
        departmentName?: string
        intermediaryName?: string
        documentUuids: string[]
        fromConnectionUuid?: string
        fromPublishRequestUuid?: string
    }[]
    const [sendDocuments, setSendDocuments] = useState<SendDocuments>([])
    const setCustomer = (customerUuid: string) => {
        if (!sendDocuments.find((c) => c.customerUuid === customerUuid)) {
            const target = customerList?.find((c) => c.uuid === customerUuid)
            setSendDocuments([
                {
                    customerUuid,
                    documentUuids: [],
                    companyName: target?.companyName || '',
                    customerName: target?.name || '',
                    intermediaryName: target?.intermediaryName || '',
                    departmentName: target?.departmentName || '',
                    position: target?.position || '',
                    fromConnectionUuid: option.connections.length ? customerUuid : undefined,
                    fromPublishRequestUuid: option.markets.length ? customerUuid : undefined,
                },
                ...sendDocuments,
            ])
        } else setSendDocuments(sendDocuments.filter((sd) => sd.customerUuid !== customerUuid))
    }

    useEffect(() => {
        // 全選択ボタン更新
        if (
            !!sendDocuments.length &&
            customerList?.length &&
            sendDocuments.length >= customerList?.length &&
            customerList.every((c) => sendDocuments?.some((doc) => c.uuid === doc.customerUuid))
        )
            setIsAllCustomer(true)
        else setIsAllCustomer(false)
    }, [sendDocuments, customerList])

    const setAllCustomer = () => {
        if (!customerList) return

        const list = sendDocuments
        const customers = customerList
        if (list.length >= customers.length && customers.every((c) => list.some((doc) => c.uuid === doc.customerUuid))) {
            setSendDocuments(sendDocuments.filter((d) => !customers.some((c) => d.customerUuid === c.uuid)))
            return
        }

        customers.forEach((customer) => {
            if (list.find((c) => c.customerUuid === customer.uuid)) return
            list.push({
                customerUuid: customer.uuid,
                documentUuids: [],
                companyName: customer?.companyName || '',
                customerName: customer?.name || '',
                intermediaryName: customer?.intermediaryName || '',
                departmentName: customer?.departmentName || '',
                position: customer?.position || '',
                fromConnectionUuid: option.connections.length ? customer.uuid : undefined,
                fromPublishRequestUuid: option.markets.length ? customer.uuid : undefined,
            })
        })
        setSendDocuments([...list])
    }

    const setDocumentCustomer = (customerUuid: string, documentUuid: string) => {
        const target = sendDocuments.find((sd) => sd.customerUuid === customerUuid)
        setSendDocuments(
            uniqBy(
                [
                    {
                        customerUuid: customerUuid,
                        documentUuids: target?.documentUuids.length
                            ? target.documentUuids.includes(documentUuid)
                                ? target.documentUuids.filter((d) => d !== documentUuid)
                                : uniq([...target.documentUuids, documentUuid])
                            : [documentUuid],
                        companyName: target?.companyName || '',
                        customerName: target?.customerName || '',
                        intermediaryName: target?.intermediaryName || '',
                        departmentName: target?.departmentName || '',
                        position: target?.position || '',
                        fromConnectionUuid: option.connections.length ? customerUuid : undefined,
                        fromPublishRequestUuid: option.markets.length ? customerUuid : undefined,
                    },
                    ...sendDocuments,
                ],
                'customerUuid',
            ),
        )
    }

    const setAllDocumentCustomer = (customerUuid: string) => {
        const target = sendDocuments.find((sd) => sd.customerUuid === customerUuid)
        if (!target) return

        const documentUuids = filteredDocuments?.map((d) => d.uuid) ?? []
        setSendDocuments([
            ...sendDocuments.filter((d) => d.customerUuid != customerUuid),
            {
                customerUuid: customerUuid,
                documentUuids: target.documentUuids.length === documentUuids.length ? [] : [...documentUuids],
                companyName: target?.companyName || '',
                customerName: target?.customerName || '',
                intermediaryName: target?.intermediaryName || '',
                departmentName: target?.departmentName || '',
                position: target?.position || '',
                fromConnectionUuid: option.connections.length ? customerUuid : undefined,
                fromPublishRequestUuid: option.markets.length ? customerUuid : undefined,
            },
        ])
    }

    const setDocumentAllCustomer = (documentUuid: string) => {
        if (sendDocuments.every((sd) => sd.documentUuids.includes(documentUuid))) {
            setSendDocuments(
                sendDocuments.map((sd) => ({
                    customerUuid: sd.customerUuid,
                    documentUuids: sd.documentUuids.filter((d) => d !== documentUuid),
                    companyName: sd.companyName,
                    customerName: sd.customerName,
                    intermediaryName: sd.intermediaryName,
                    departmentName: sd.departmentName,
                    position: sd.position,
                    fromConnectionUuid: option.connections.length ? sd.customerUuid : undefined,
                    fromPublishRequestUuid: option.markets.length ? sd.customerUuid : undefined,
                })),
            )
        } else {
            setSendDocuments(
                sendDocuments.map((sd) => ({
                    customerUuid: sd.customerUuid,
                    documentUuids: uniq([...sd.documentUuids, documentUuid]),
                    companyName: sd.companyName,
                    customerName: sd.customerName,
                    intermediaryName: sd.intermediaryName,
                    departmentName: sd.departmentName,
                    position: sd.position,
                    fromConnectionUuid: option.connections.length ? sd.customerUuid : undefined,
                    fromPublishRequestUuid: option.markets.length ? sd.customerUuid : undefined,
                })),
            )
        }
    }

    const setAllDocumentAllCustomer = () => {
        const documentUuids = filteredDocuments?.map((d) => d.uuid) ?? []
        if (sendDocuments.every((sd) => isEqual(uniq(sortBy(sd.documentUuids)), uniq(sortBy(documentUuids))))) {
            setSendDocuments(
                sendDocuments.map((sd) => ({
                    customerUuid: sd.customerUuid,
                    documentUuids: [],
                    companyName: sd.companyName,
                    customerName: sd.customerName,
                    intermediaryName: sd.intermediaryName,
                    departmentName: sd.departmentName,
                    position: sd.position,
                    fromConnectionUuid: option.connections.length ? sd.customerUuid : undefined,
                    fromPublishRequestUuid: option.markets.length ? sd.customerUuid : undefined,
                })),
            )
        } else {
            setSendDocuments(
                sendDocuments.map((sd) => ({
                    customerUuid: sd.customerUuid,
                    documentUuids: uniq([...sd.documentUuids, ...documentUuids]),
                    companyName: sd.companyName,
                    customerName: sd.customerName,
                    intermediaryName: sd.intermediaryName,
                    departmentName: sd.departmentName,
                    position: sd.position,
                    fromConnectionUuid: option.connections.length ? sd.customerUuid : undefined,
                    fromPublishRequestUuid: option.markets.length ? sd.customerUuid : undefined,
                })),
            )
        }
    }

    const sendHistory = (documentUuid: string, customerUuid: string) => {
        if (!intermediaryDocument?.length) return undefined
        const target = intermediaryDocument?.find((d) => {
            return d.documentFrom?.uuid === documentUuid && d.intermediaryTo.destinationTo?.uuid === customerUuid
        })
        return target ? target.createdAt : undefined
    }

    const [page, setPage] = useState<1 | 2 | 3>(1)
    const [showNoSetDocumentModal, setShowNoSetDocumentModal] = useState(false)
    const setPrev = (prevPage: 1 | 2 | 3) => {
        setPage(prevPage)
    }
    const setNext = (nextPage: 1 | 2 | 3) => {
        if (nextPage == 2) {
            if (sendDocuments.find((d) => d.documentUuids.length === 0)) {
                // 資料が未選択な顧客がいる場合はアラート
                setShowNoSetDocumentModal(true)
            } else setPage(nextPage)
        } else setPage(nextPage)
    }
    const [selectedMailCustomer, setSelectedMailCustomer] = useState<{
        uuid: string
        companyName: string
        position?: string
        departmentName?: string
        name: string
        intermediaryName?: string
    }>()
    useEffect(() => {
        if (page === 2) {
            setSelectedMailCustomer({
                uuid: sendDocuments[0].customerUuid,
                companyName: sendDocuments[0].companyName,
                name: sendDocuments[0].customerName,
                departmentName: sendDocuments[0].departmentName,
                position: sendDocuments[0].position,
                intermediaryName: sendDocuments[0].intermediaryName,
            })
        }
    }, [page])

    // 全顧客送信モーダル
    const [showAllSendModal, setShowAllSendModal] = useState(false)
    // 全顧客送信のときに送付する資料UUID
    const [allSendDocuments, setAllSendDocuments] = useState<string[]>([])

    // 資料選択
    const setAllSendDocument = (uuid: string) => {
        if (allSendDocuments.includes(uuid)) setAllSendDocuments(allSendDocuments.filter((d) => d !== uuid))
        else setAllSendDocuments([uuid, ...allSendDocuments])
    }

    // 全顧客送信時の顧客・資料選択処理
    const setAllSendCustomer = () => {
        if (!allCustomerList) return
        // リセット
        setSendDocuments([])
        const list: SendDocuments = []
        allCustomerList.forEach((customer) => {
            if (list.find((c) => c.customerUuid === customer.uuid)) return
            list.push({
                customerUuid: customer.uuid,
                documentUuids: allSendDocuments,
                companyName: customer?.companyName || '',
                customerName: customer?.name || '',
                departmentName: customer?.departmentName || '',
                intermediaryName: customer?.intermediaryName || '',
                position: customer?.position || '',
                fromConnectionUuid: option.connections.length ? customer.uuid : undefined,
                fromPublishRequestUuid: option.markets.length ? customer.uuid : undefined,
            })
        })
        setSendDocuments([...list])
    }

    const allSendModalConfirm = async () => {
        await setAllSendCustomer()
        // 資料が未選択の場合はアラート
        if (allSendDocuments.length === 0) setShowNoSetDocumentModal(true)
        else setPage(2)
        setShowAllSendModal(false)
    }

    // ドキュメントのラベル
    const getLabelArray = (item: RealEstateDocumentEntities) => {
        const labels: { className?: string; children: ReactNode; style?: CSSProperties }[] = []
        labels.push(
            ...(item.labels?.map((l) => ({
                className: clsx('text-xs', l.bgColor === '#ffffff' && 'border'),
                style: {
                    backgroundColor: l.bgColor,
                    color: l.letterColor,
                },
                children: <>{l.name}</>,
            })) ?? []),
        )
        return labels
    }

    // プレビュー
    const mutation = useClientMediaGetSignedUrlMutation()
    const previewDocument = async (doc: RealEstateDocumentEntities) => {
        if (!doc.file) return
        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        const win = window.open(
            undefined,
            'file_preview',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )

        if (doc.intermediaryDocumentFrom) sendPreviewLog(doc.intermediaryDocumentFrom.uuid, 'preview')

        try {
            const fileUrl = isPdfFile(doc.file) ? await getSignedUrlString(doc.file, mutation) : getSignedUrl(doc.file)
            const url = new URL(window.location.origin + '/file_preview')
            url.search = createSearchParams({
                fileName: doc.file?.filename ?? '',
                mineType: doc.file?.mimeType ?? '',
                src: fileUrl,
            }).toString()

            if (win) win.location.href = url.toString()
        } catch (e) {
            // ファイルの取得に失敗
        }
    }
    // プレビューログ
    const [postPreview] = useClientIntermediaryPostPreviewMutation()
    const sendPreviewLog = (documentUuid: string, method: 'preview' | 'download') => {
        postPreview({ clientIntermediaryDocumentPreviewDto: { documentUuid, method } }).catch((err) => {
            console.error(err)
        })
    }

    const { data: mailTemplate } = useClientNotificationTemplateNotificationTemplateQuery({
        key: option.connections.length
            ? 'external_recommend_intermediary'
            : option.markets.length
            ? 'real_estate_publish'
            : 'intermediary',
    })

    type SendData = {
        price: number
        title: string
        message: string
        setPassword: boolean
        password: string
        taxType: number
    }
    const [sendData, setSendData] = useState<SendData>({
        price: 0,
        title: '',
        message: '',
        setPassword: false,
        password: '',
        taxType: 0,
    })

    const onChangeSendData = (
        key: 'price' | 'message' | 'setPassword' | 'password' | 'title' | 'taxType',
        val: number | string | boolean,
    ) => {
        setSendData(() => {
            let newSendData: SendData = {
                ...sendData,
                [key]: val,
            }
            // パスワード設定トグルをOFFにした場合は設定中のパスワード文字列を削除
            if (key === 'setPassword' && val === false) newSendData = { ...newSendData, password: '' }

            return newSendData
        })
    }

    const replace = (body: string, regExpStr: string, value: string, isEmptyValue = '', removeBr?: boolean) => {
        let replaced = body
        replaced = replaced.replace(
            new RegExp(`${regExpStr}\n`, 'g'),
            value ? value + '\n' : removeBr ? isEmptyValue : isEmptyValue + '\n',
        )
        replaced = replaced.replace(new RegExp(`${regExpStr}`, 'g'), value || isEmptyValue)
        return replaced
    }

    const previewSubject = useMemo(() => {
        if (!selectedMailCustomer) return ''
        const intermediaryMailTemplate = mailTemplate?.find(() => true)
        if (!intermediaryMailTemplate) return ''
        if (sendData.title) return sendData.title

        let subject = intermediaryMailTemplate.mailSubject
        subject = subject.replace(/__real_estate_name__/g, realEstate?.name ?? '')
        subject = subject.replace(/__from_company_name__/g, company?.name ?? '')
        subject = subject.replace(/__from_name__/g, user?.name ?? '')

        subject = replace(subject, '__real_estate_use_type__', realEstate?.useType?.name ?? '')
        subject = replace(subject, '__real_estate_address__', realEstate?.address ?? '')
        subject = replace(subject, '__real_estate_station__', (realEstate?.station ?? '').replace('\n', ' '))
        subject = replace(subject, '__real_estate_land_right__', realEstate?.landRight ?? '')
        subject = replace(subject, '__real_estate_land_category__', realEstate?.landCategory ?? '')
        subject = replace(
            subject,
            '__real_estate_registered_land_size__',
            realEstate?.registeredLandSize ? String(round(realEstate?.registeredLandSize, 3).toLocaleString()) : '',
        )
        subject = replace(subject, '__real_estate_building_right__', realEstate?.buildingRight ?? '')
        subject = replace(
            subject,
            '__real_estate_build_at__',
            realEstate?.buildAt ? dayjs(realEstate.buildAt).format('YYYY-MM-DD') : '',
        )
        subject = replace(
            subject,
            '__real_estate_structure_type__',
            structureTypeItems.find((v) => v.value === realEstate?.structureType)?.label ?? '',
        )
        subject = replace(
            subject,
            '__real_estate_total_floor_size__',
            realEstate?.totalFloorSize ? String(round(realEstate.totalFloorSize, 3).toLocaleString()) : '',
        )
        subject = replace(
            subject,
            '__real_estate_assume_income__',
            realEstate?.assumeIncome ? String(Number(realEstate.assumeIncome).toLocaleString()) : '',
        )
        subject = replace(
            subject,
            '__real_estate_gross_rate__',
            !!realEstate?.assumeIncome && !!sendData.price
                ? String(round(round(Number(realEstate.assumeIncome) / Number(sendData.price), 4) * 100, 3).toLocaleString())
                : '',
        )
        subject = replace(subject, '__real_estate_price__', sendData.price ? fullCurrencyFormat(sendData.price) : '')

        return subject.replace(/\n/g, '<br />')
    }, [selectedMailCustomer, sendData])
    const previewBody = useMemo(() => {
        if (!selectedMailCustomer) return ''
        const intermediaryMailTemplate = mailTemplate?.find(() => true)
        if (!intermediaryMailTemplate) return ''

        let body = ''
        const toName = selectedMailCustomer.intermediaryName || selectedMailCustomer.name
        const toCompanyName = selectedMailCustomer.companyName
        const toDepartmentName = selectedMailCustomer.departmentName ?? ''
        const toPosition = selectedMailCustomer.position ?? ''

        body +=
            '<table width="100%" style="font-size: 16px; background: white; color: #374151" cellpadding="0" cellspacing="0"><tr><td style="padding: 16px;">'
        if (intermediaryMailTemplate.mailHtmlBody) body += '<p>' + intermediaryMailTemplate.mailHtmlBody + '</p>'

        body += '</td></tr>'
        if (intermediaryMailTemplate.mailHtmlButtonTitle) {
            body +=
                '<tr><td style="padding: 16px;"><p><a href="__url__" alt="' +
                intermediaryMailTemplate.mailHtmlButtonTitle +
                '" style="width: 280px; max-width: 80%; display: inline-block; text-align: center; padding: 8px 12px; background-color: #45A8A0; color: white; border-radius: 4px; font-size: 18px; line-height: 26px; text-decoration: none;">' +
                intermediaryMailTemplate.mailHtmlButtonTitle +
                '</a></p><p style="font-size: 10px;">リンク先が表示されない場合は、以下のURLをWEBブラウザに入力することでアクセスできます。<br /><a href="#" style="color:#45A8A0;">__url__</a></p>'
        }
        if (intermediaryMailTemplate.mailHtmlAfterMessage)
            body += '<tr><td style="padding: 16px;"><p>' + intermediaryMailTemplate.mailHtmlAfterMessage + '</p></td></tr>'

        if (user?.signatures?.length && user.signatures[0].signature !== '')
            body += '<tr><td style="padding: 16px;"><p>' + user.signatures[0].signature + '</p></td></tr>'

        body += '</td></tr></table>'

        const createStaffSignature = () => {
            let staffSignature = ''
            const staffList = realEstate?.assignees
            let charge = ''
            if (staffList?.length) {
                if (staffList.length === 1) {
                    charge = '担当 '
                    staffSignature += charge + staffList[0].assignedTo?.name + '\n'
                } else {
                    staffList
                        .filter((s) => s.isPrimary)
                        .forEach((staff) => {
                            charge = '主担当 '
                            staffSignature += charge + staff.assignedTo?.name + '\n'
                        })
                    staffList
                        .filter((s) => !s.isPrimary)
                        .forEach((staff) => {
                            charge = '副担当 '
                            staffSignature += charge + staff.assignedTo?.name + '\n'
                        })
                }
            } else staffSignature = '[物件の担当者名がここに挿入されます]'

            return staffSignature
        }

        // テンプレ文言置き換え
        body = body.replace(/__from_company_name__/g, company?.name ?? '')
        body = body.replace(/__from_name__/g, user?.name ?? '')
        body = body.replace(/__url__/g, '[紹介URLがここに挿入されます]')
        body = body.replace(/__real_estate_name__/g, realEstate?.name ?? '')
        body = body.replace(/__to_company_name__/g, toCompanyName)
        body = body.replace(/__to_name__/g, toName)
        body = body.replace(/__staff_signature__/g, createStaffSignature())
        body = body.replace(/__to_department_name__/g, toDepartmentName)
        body = body.replace(/__to_position__/g, toPosition)

        /** 物件概要 */
        body = replace(body, '__real_estate_use_type__', realEstate?.useType?.name ?? '')
        body = replace(body, '__real_estate_address__', realEstate?.address ?? '')
        body = replace(body, '__real_estate_station__', (realEstate?.station ?? '').replace('\n', ' '))
        body = replace(body, '__real_estate_land_right__', realEstate?.landRight ?? '')
        body = replace(body, '__real_estate_land_category__', realEstate?.landCategory ?? '')
        body = replace(
            body,
            '__real_estate_registered_land_size__',
            realEstate?.registeredLandSize ? String(round(realEstate?.registeredLandSize, 3).toLocaleString()) : '',
        )
        body = replace(body, '__real_estate_building_right__', realEstate?.buildingRight ?? '')
        body = replace(
            body,
            '__real_estate_build_at__',
            realEstate?.buildAt ? dayjs(realEstate.buildAt).format('YYYY-MM-DD') : '',
        )
        body = replace(
            body,
            '__real_estate_structure_type__',
            structureTypeItems.find((v) => v.value === realEstate?.structureType)?.label ?? '',
        )
        body = replace(
            body,
            '__real_estate_total_floor_size__',
            realEstate?.totalFloorSize ? String(round(realEstate.totalFloorSize, 3).toLocaleString()) : '',
        )
        body = replace(
            body,
            '__real_estate_assume_income__',
            realEstate?.assumeIncome ? String(Number(realEstate.assumeIncome).toLocaleString()) : '',
        )
        body = replace(
            body,
            '__real_estate_gross_rate__',
            !!realEstate?.assumeIncome && !!sendData.price
                ? String(round(round(Number(realEstate.assumeIncome) / Number(sendData.price), 4) * 100, 3).toLocaleString())
                : '',
        )
        body = replace(body, '__real_estate_price__', sendData.price ? fullCurrencyFormat(sendData.price) : '')

        // メッセージ系置き換え
        if (sendData.price > 0) {
            const priceFormat =
                '提示金額\n' +
                fullCurrencyFormat(sendData.price) +
                '円' +
                (sendData.taxType ? ' (' + taxType.find((t) => t.value === sendData.taxType)?.label + ')' : '') +
                '\n'
            body = body.replace(/__message__/g, priceFormat + '\n__message__\n')
        }
        if (sendData.message.length > 0) body = body.replace(/__message__/g, htmlEntities(sendData.message))
        else body = body.replace(/__message__/g, '[紹介先へのメッセージがここに挿入されます]')

        // 物件資料置き換え
        const docs = sendDocuments.find((d) => d.customerUuid === selectedMailCustomer.uuid)
        if (docs) {
            const docStrings: string[] = []
            const createFileHtml = (uuid: string) => {
                const row: string[] = []
                const file = realEstate?.documents?.find((d) => d.uuid === uuid)?.file
                if (file) {
                    row.push(
                        '<tr><td style="padding-left: 16px;">',
                        '<a href="',
                        'https://',
                        process.env.BASE_URL ?? '',
                        '/api/client/media/signed/',
                        uuid,
                        '" style="color:#45A8A0; cursor: pointer; text-decoration: underline;">',
                        file.filename,
                        '</a>',
                        '</td></tr>',
                    )
                }
                return row
            }
            docStrings.push('<tr><td style="padding-left: 16px;"><p>＜物件資料＞</p></td></tr>')
            for (const docUuid of docs.documentUuids) docStrings.push(...createFileHtml(docUuid))

            body = body.replace(/__attached_documents__[\n]?/g, docStrings.join(''))
        } else body = body.replace(/__attached_documents__[\n]?/g, '')

        return body.replace(/\n/g, '<br />')
    }, [selectedMailCustomer, sendData])

    const [postIntermediary] = useClientIntermediaryPostIntermediaryMutation()
    const send = async () => {
        setErrors([])
        if (!realEstate) return
        try {
            const errorList: Array<ApplicationError> = []
            if (sendData.setPassword && sendData.password.length === 0)
                errorList.push(new BadRequest(t('Intermediary.パスワードを入力してください')))
            if (sendData.price && (sendData.price >= MAX_PRICE_INTEGER || sendData.price < 0))
                errorList.push(new BadRequest(t('Intermediary.提示金額に正しい価格を入力してください')))
            if (errorList.length > 0) {
                setErrors(errorList)
                return
            }
            await postIntermediary({
                clientIntermediaryInsertDto: {
                    customers: sendDocuments,
                    realEstateUuid: realEstate.uuid,
                    message: sendData.message,
                    price: sendData.price,
                    taxType: sendData.taxType,
                    title: sendData.title,
                    setPassword: sendData.setPassword,
                    password: sendData.password,
                },
            }).unwrap()
            setNext(3)
            setTimeout(() => {
                navigate('/realEstate/' + realEstate.uuid)
            }, 3000)
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    if (!realEstate || !intermediaryDocument) return <></>

    if (page === 3) {
        return (
            <div
                className={clsx(
                    'w-full',
                    'bg-white',
                    'rounded',
                    'max-w-xl',
                    'mx-auto',
                    'mt-8',
                    'px-4',
                    'py-16',
                    'flex',
                    'flex-col',
                    'items-center',
                    'gap-8',
                )}>
                <img className={clsx('w-96')} src="/src/assets/image/dashboard_img.png" alt="" />
                <p className={clsx('font-bold', 'text-xl')}>{t('Intermediary.物件紹介を送信しました')}</p>
            </div>
        )
    }

    const RealEstateInfo = () => {
        return (
            <div className={clsx('flex', 'justify-between', 'flex-col', 'md:flex-row')}>
                <div className={clsx('flex-1')}>
                    <div className={clsx('text-2xl', 'text-gray-700')}>
                        {t('Intermediary.realestateを紹介', { realestate: realEstate.name })}
                    </div>
                    <div className={clsx('flex', 'items-center')}>
                        <div className={clsx('text-xs', 'text-white', 'bg-gray-500', 'py-0.5', 'px-1', 'mr-1')}>
                            {realEstate.useType.name}
                        </div>
                        <div className={clsx('text-sm', 'text-gray-700')}>{realEstate.address}</div>
                    </div>
                </div>
                <div className={clsx('flex', 'flex-col', 'items-end')}>
                    <div className={clsx('flex', 'items-baseline')}>
                        <div className={clsx('text-sm', 'text-kimar-primary')}>{t('Intermediary.物件価格')}</div>
                        <div className={clsx('text-lg', 'text-kimar-primary', 'font-bold')}>
                            {fullCurrencyFormat(realEstate.price)}
                        </div>
                        <div className={clsx('text-sm', 'text-kimar-primary')}>{t('Unit.円')}</div>
                        {!isNil(realEstate.taxType) && (
                            <div className={clsx('text-sm', 'text-kimar-primary')}>{`(${
                                taxType.find((t) => t.value === realEstate.taxType)?.label
                            })`}</div>
                        )}
                    </div>
                    <div>
                        <CTagList horizontal items={getTagArray(realEstate)} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            className={clsx('w-full', 'h-[calc(100%-128px)]', 'flex', 'flex-col', 'gap-4', 'pt-4', 'md:pt-8', 'px-4', 'md:px-8')}>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Intermediary.物件を紹介')}</title>
            </Helmet>
            <RealEstateInfo />
            <CError errors={errors} />
            {page === 1 && (
                <>
                    {/* いずれかの制限（レコメンド、コネクション、マーケット）あるなら非表示 */}
                    {!option.customers.length && !option.connections.length && !option.markets.length && (
                        <div className={clsx('w-full', 'z-50', 'relative')}>
                            <CCustomerSearch realEstate={realEstate} onChange={onChangeSearchVal} />
                        </div>
                    )}
                    {isLoading || !_customerList ? (
                        <CMessage info>{t('Message.読み込み中です…')}</CMessage>
                    ) : (
                        <>
                            <CMessage info>
                                <p>{t('Intermediary.紹介先の顧客と送信する資料を選択してください。')}</p>
                                <p>{t('Intermediary.物件トップ画像は必ず紹介先に送信されるため、こちらには表示されません。')}</p>
                            </CMessage>
                            <div className={clsx('flex', 'flex-col', 'md:flex-row', 'items-center', 'justify-between')}>
                                <div className={clsx('text-sm')}>
                                    {/* いずれかの制限（レコメンド、コネクション、マーケット）があるなら非表示 */}
                                    {!option.customers.length && !option.connections.length && !option.markets.length && (
                                        <CButton className={clsx('c-button-primary')} onClick={() => setShowAllSendModal(true)}>
                                            {t('Intermediary.すべての顧客に紹介')}
                                        </CButton>
                                    )}
                                </div>
                                <CPager
                                    page={customerPage}
                                    pageSize={maxCount}
                                    total={_customerList.count}
                                    onPageChanged={onCustomerPageChanged}
                                />
                            </div>
                            <div className={clsx('overflow-x-auto')}>
                                <table
                                    className={clsx('text-sm', 'table-fixed')}
                                    style={{ width: `${384 + 128 * (filteredDocuments?.length ?? 0)}px` }}>
                                    <thead>
                                        <tr
                                            className={clsx(
                                                'bg-kimar-primary',
                                                'text-white',
                                                'text-left',
                                                'sticky',
                                                'z-20',
                                                'top-0',
                                            )}>
                                            <th className={clsx('bg-kimar-primary', 'w-8', 'sticky', 'top-0', 'left-0', 'z-20')}>
                                                <div className={clsx('flex')}>
                                                    {!!customerList && (customerList?.length ?? 0) > 0 && (
                                                        <CCheckBox
                                                            className={clsx('rounded', 'border', 'border-white')}
                                                            checked={isAllCustomer}
                                                            onChange={() => {
                                                                setAllCustomer()
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </th>
                                            <th
                                                className={clsx(
                                                    'hidden',
                                                    'md:table-cell',
                                                    'pl-2',
                                                    'w-40',
                                                    'sticky',
                                                    'top-0',
                                                    'left-8',
                                                    'z-20',
                                                    'bg-kimar-primary',
                                                )}>
                                                {t('Intermediary.会社名')}
                                            </th>
                                            <th
                                                className={clsx(
                                                    'pl-2',
                                                    'w-40',
                                                    'sticky',
                                                    'top-0',
                                                    'left-8',
                                                    'z-20',
                                                    'md:left-48',
                                                    'bg-kimar-primary',
                                                )}>
                                                <span className={clsx('md:hidden')}>{t('Intermediary.会社名')}/</span>
                                                {t('Intermediary.氏名')}
                                            </th>
                                            <th className={clsx('bg-kimar-primary', 'px-2', 'w-8')}>
                                                <div className={clsx('flex')}>
                                                    {!!customerList && (customerList?.length ?? 0) > 0 && (
                                                        <CCheckBox
                                                            disabled={!sendDocuments.length}
                                                            className={clsx('rounded', 'border', 'border-white')}
                                                            checked={
                                                                !!sendDocuments.length &&
                                                                sendDocuments.every(
                                                                    (sd) =>
                                                                        sd.documentUuids.length ===
                                                                        (filteredDocuments?.length ?? 0),
                                                                )
                                                            }
                                                            label={''}
                                                            onChange={() => {
                                                                setAllDocumentAllCustomer()
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </th>
                                            {filteredDocuments?.map((d) => (
                                                <th key={d.uuid} className={clsx('pl-2')}>
                                                    <div className={clsx('h-full')}>
                                                        <div className={clsx('peer')}>
                                                            <div className={clsx('flex', 'items-center')}>
                                                                <CCheckBox
                                                                    disabled={!sendDocuments.length}
                                                                    className={clsx('rounded', 'border', 'border-white', 'mr-1')}
                                                                    checked={
                                                                        !!sendDocuments.length &&
                                                                        sendDocuments.every((sd) =>
                                                                            sd.documentUuids.includes(d.uuid),
                                                                        )
                                                                    }
                                                                    label={''}
                                                                    onChange={() => setDocumentAllCustomer(d.uuid)}
                                                                />
                                                                <div
                                                                    className={clsx(
                                                                        'cursor-pointer',
                                                                        'text-ellipsis',
                                                                        'whitespace-nowrap',
                                                                        'overflow-hidden',
                                                                    )}>
                                                                    {d.file?.filename}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={clsx('hidden', 'peer-hover:block', 'absolute', 'z-30')}>
                                                            <div
                                                                className={clsx(
                                                                    'text-gray-700',
                                                                    'bg-white',
                                                                    'rounded',
                                                                    'text-sm',
                                                                    'p-2',
                                                                )}>
                                                                {d.file?.filename}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customerList?.map((customer) => (
                                            <tr
                                                key={customer.uuid}
                                                className={clsx(
                                                    'align-top',
                                                    'border-b',
                                                    sendDocuments.find((sd) => sd.customerUuid === customer.uuid)
                                                        ? 'bg-kimar-primary-light'
                                                        : 'bg-white',
                                                    'divide-x',
                                                    'cursor-pointer',
                                                )}>
                                                <td
                                                    className={clsx(
                                                        'w-8',
                                                        'sticky',
                                                        'top-0',
                                                        'left-0',
                                                        'z-10',
                                                        sendDocuments.find((sd) => sd.customerUuid === customer.uuid)
                                                            ? 'bg-kimar-primary-light'
                                                            : 'bg-white',
                                                    )}
                                                    onClick={() => setCustomer(customer.uuid)}>
                                                    <CCheckBox
                                                        className={clsx('rounded', 'flex-1')}
                                                        checked={!!sendDocuments.find((sd) => sd.customerUuid === customer.uuid)}
                                                    />
                                                </td>
                                                <td
                                                    className={clsx(
                                                        'hidden',
                                                        'md:table-cell',
                                                        'px-2',
                                                        'w-40',
                                                        'sticky',
                                                        'top-0',
                                                        'left-8',
                                                        'z-10',
                                                        sendDocuments.find((sd) => sd.customerUuid === customer.uuid)
                                                            ? 'bg-kimar-primary-light'
                                                            : 'bg-white',
                                                    )}
                                                    onClick={() => setCustomer(customer.uuid)}>
                                                    <div>{customer.companyName}</div>
                                                </td>
                                                <td
                                                    className={clsx(
                                                        'pl-2',
                                                        'w-40',
                                                        'sticky',
                                                        'overflow-hidden',
                                                        'top-0',
                                                        'left-8',
                                                        'md:left-48',
                                                        'z-10',
                                                        sendDocuments.find((sd) => sd.customerUuid === customer.uuid)
                                                            ? 'bg-kimar-primary-light'
                                                            : 'bg-white',
                                                    )}
                                                    onClick={() => setCustomer(customer.uuid)}>
                                                    <div>
                                                        <div className={clsx('md:hidden')}>
                                                            {customer.companyName}/<br />
                                                        </div>

                                                        {customer.name}
                                                    </div>
                                                </td>
                                                <td
                                                    className={clsx(
                                                        'px-2',
                                                        'w-8',
                                                        sendDocuments.find((sd) => sd.customerUuid === customer.uuid)
                                                            ? 'bg-kimar-primary-light'
                                                            : 'bg-white',
                                                    )}
                                                    onClick={() => setCustomer(customer.uuid)}>
                                                    <CCheckBox
                                                        className={clsx('rounded', 'h-5')}
                                                        disabled={!sendDocuments.find((sd) => sd.customerUuid === customer.uuid)}
                                                        checked={
                                                            !!sendDocuments.find(
                                                                (sd) =>
                                                                    sd.customerUuid === customer.uuid &&
                                                                    isEqual(
                                                                        uniq(sortBy(sd.documentUuids)),
                                                                        uniq(sortBy(filteredDocuments?.map((d) => d.uuid) ?? [])),
                                                                    ),
                                                            )
                                                        }
                                                        label={''}
                                                        onChange={() => setAllDocumentCustomer(customer.uuid)}
                                                    />
                                                </td>
                                                {filteredDocuments?.map((d) => (
                                                    <td key={d.uuid} className={clsx('px-2')}>
                                                        <CCheckBox
                                                            className={clsx('rounded')}
                                                            disabled={
                                                                !sendDocuments.find((sd) => sd.customerUuid === customer.uuid)
                                                            }
                                                            checked={
                                                                !!sendDocuments
                                                                    .find((sd) => sd.customerUuid === customer.uuid)
                                                                    ?.documentUuids.includes(d.uuid)
                                                            }
                                                            onChange={() => setDocumentCustomer(customer.uuid, d.uuid)}
                                                            label={t('Intermediary.添付する')}
                                                        />
                                                        {sendHistory(d.uuid, customer.uuid) && (
                                                            <div className={clsx('text-gray-500', 'text-xs', 'flex')}>
                                                                <i className={clsx('material-icons', 'text-xs')}>check</i>
                                                                {formatDateTime(sendHistory(d.uuid, customer.uuid), 'date')}
                                                            </div>
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                    <div
                        className={clsx(
                            'fixed',
                            'left-0',
                            'p-2',
                            'bottom-0',
                            'flex',
                            'justify-between',
                            'z-10',
                            'w-full',
                            'bg-white',
                        )}>
                        <CLinkButton className={clsx('c-button-secondary')} to={`/realestate/${realEstate.uuid}`}>
                            {t('Button.戻る')}
                        </CLinkButton>
                        <div className={clsx('flex', 'gap-4')}>
                            {sendDocuments.length !== 0 && (
                                <p className={clsx('flex', 'items-center', 'gap-1')}>
                                    <Trans i18nKey={'Intermediary.count名を選択中'} count={sendDocuments.length}>
                                        <span className={clsx('text-xl', 'text-kimar-primary')}>
                                            <>{{ count: sendDocuments.length }}</>
                                        </span>
                                        名を選択中
                                    </Trans>
                                </p>
                            )}
                            <CButton
                                className={clsx('c-button-primary')}
                                disabled={!sendDocuments.length}
                                onClick={() => setNext(2)}>
                                <i className={clsx('material-symbols-outlined')}>Mail</i>
                                <div>{t('Intermediary.メール内容編集へ')}</div>
                            </CButton>
                        </div>
                    </div>
                </>
            )}
            {page === 2 && (
                <>
                    <CMessage info>
                        <p>{t('Intermediary.物件紹介メールの設定とプレビューを確認し、物件紹介メールを送信してください')}</p>
                    </CMessage>
                    <div className={clsx('grid', 'grid-cols-1', 'md:grid-cols-2', 'gap-4')}>
                        <div className={clsx('w-full')}>
                            <div className={clsx('mb-4')}>
                                <CHeader label={t('Intermediary.紹介内容設定')} bgClass="bg-kimar-accent" />
                            </div>
                            <div className={clsx('rounded', 'border', 'bg-white', 'p-4')}>
                                <div className={clsx('flex')}>
                                    <div>{t('Intermediary.非会員向けの物件紹介ページにパスワードを設定する')}</div>
                                    <div className={clsx('ml-2')}>
                                        <CToggleSwitch
                                            value={sendData.setPassword}
                                            trueLabel={t('Intermediary.YES')}
                                            falseLabel={t('Intermediary.NO')}
                                            toggleChanged={(value) => {
                                                onChangeSendData('setPassword', value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={clsx('my-2')}>
                                    <CTextInput
                                        disabled={!sendData.setPassword}
                                        text={sendData.password}
                                        onChange={(value) => onChangeSendData('password', value)}
                                        type={'text'}
                                        className={clsx('w-full', 'c-text-input-base')}
                                    />
                                </div>
                                <CMessage info>
                                    <p className={clsx('text-sm')}>
                                        {t(
                                            'Intermediary.YESにすると、物件紹介メールとは別にパスワードが記載されたメールが紹介先に送信されます。',
                                        )}
                                        <br />
                                        {t(
                                            'Intermediary.非会員がメールのURLにアクセスしたときにパスワードの入力が必要になります。',
                                        )}
                                        <br />
                                        {t('Intermediary.相手がキマール会員の場合はパスワードなしでアクセスできます。')}
                                    </p>
                                </CMessage>
                                <div className={clsx('mt-4', 'mb-2')}>
                                    <CLabeledItem
                                        labelNode={
                                            <div>
                                                <div>{t('Intermediary.メール件名')}</div>
                                            </div>
                                        }>
                                        <div>
                                            <CTextInput
                                                text={sendData.title}
                                                onChange={(value) => onChangeSendData('title', value)}
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                            />
                                        </div>
                                    </CLabeledItem>
                                </div>
                                <CMessage info>
                                    <p className={clsx('text-sm')}>
                                        {t(
                                            'Intermediary.未入力の場合はマイページ＞メール設定で設定したテンプレートの件名が適用されます',
                                        )}
                                    </p>
                                </CMessage>
                                <div className={clsx('mt-4', 'mb-2')}>
                                    <CLabeledItem labelNode={<div>{t('Intermediary.提示金額')}</div>}>
                                        <div className={clsx('flex', 'items-center')}>
                                            <div className={clsx('flex', 'items-end', 'items-center')}>
                                                <CNumberInput
                                                    className={clsx('w-full', 'c-text-input-base')}
                                                    value={sendData.price}
                                                    onChange={(value) => onChangeSendData('price', value ?? 0)}
                                                />
                                                <span className={clsx('ml-1')}>{t('Unit.円')}</span>
                                            </div>
                                            <div className={clsx('px-1')}>
                                                ({fullCurrencyFormat(sendData.price)}
                                                {t('Unit.円')})
                                            </div>
                                            <div className={clsx('px-1')}>
                                                <CDropDown
                                                    items={taxType}
                                                    allowUnselected
                                                    unselectedLabel={'--'}
                                                    nowValue={String(sendData.taxType)}
                                                    onChange={(value) => onChangeSendData('taxType', Number(value))}
                                                    className={clsx('w-full', 'c-dropdown-base')}
                                                />
                                            </div>
                                        </div>
                                    </CLabeledItem>
                                </div>
                                <CMessage info>
                                    <p className={clsx('text-sm')}>
                                        {t(
                                            'Intermediary.全員に同じ金額が提示されます。紹介先ごとに金額を変えたい場合は、金額ごとに新たな紹介をしてください',
                                        )}
                                    </p>
                                </CMessage>
                                <div className={clsx('mt-4', 'mb-2')}>
                                    <CLabeledItem labelNode={<div>{t('Intermediary.紹介先への追加メッセージ')}</div>}>
                                        <div>
                                            <CTextInput
                                                type={'multiline'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={sendData.message}
                                                onChange={(value) => onChangeSendData('message', value)}
                                            />
                                        </div>
                                    </CLabeledItem>
                                </div>
                                <CMessage info>
                                    <p className={clsx('text-sm')}>
                                        {t(
                                            'Intermediary.全員に同じメッセージが提示されます。紹介先ごとにメッセージを変えたい場合は、メッセージごとに新たな紹介をしてください',
                                        )}
                                    </p>
                                </CMessage>
                            </div>
                            <div className={clsx('my-4')}>
                                <CHeader label={t('Intermediary.送信先')} bgClass="bg-kimar-accent" />
                            </div>
                            <div className={clsx('rounded', 'border', 'bg-white', 'p-4', 'md:mb-16')}>
                                <div className={clsx('mb-2')}>
                                    <p>{t('Intermediary.以下の顧客全員に物件紹介メールが送信されます。')}</p>
                                    <p>{t('Intermediary.送信先を選択することで送信先ごとのメール文面を確認できます。')}</p>
                                </div>
                                <div className={clsx('max-h-[300px]', 'w-full', 'overflow-x-auto')}>
                                    <table className={clsx('text-sm', 'table-fixed')}>
                                        <thead>
                                            <tr
                                                className={clsx(
                                                    'bg-kimar-primary',
                                                    'text-white',
                                                    'text-left',
                                                    'sticky',
                                                    'z-20',
                                                    'top-0',
                                                )}>
                                                <td className={clsx('px-2')}>{t('Intermediary.会社名')}</td>
                                                <td className={clsx('px-2')}>{t('Intermediary.氏名')}</td>
                                                <td className={clsx('px-2')}>{t('Intermediary.選択資料')}</td>
                                            </tr>
                                        </thead>
                                        <tbody className={clsx('w-full')}>
                                            {sendDocuments.map((sd) => (
                                                <tr
                                                    key={sd.customerUuid}
                                                    className={clsx(
                                                        'p-1',
                                                        'text-sm',
                                                        'text-gray-700',
                                                        'cursor-pointer',
                                                        'w-full',
                                                        'border-b',
                                                        selectedMailCustomer?.uuid == sd.customerUuid && 'bg-kimar-primary-light',
                                                    )}
                                                    onClick={() =>
                                                        setSelectedMailCustomer({
                                                            uuid: sd.customerUuid,
                                                            companyName: sd.companyName,
                                                            intermediaryName: sd.intermediaryName,
                                                            name: sd.customerName,
                                                            departmentName: sd.departmentName,
                                                            position: sd.position,
                                                        })
                                                    }>
                                                    <td className={clsx('p-2')}>{sd.companyName}</td>
                                                    <td className={clsx('p-2')}>{sd.customerName}</td>
                                                    <td className={clsx('p-2', 'flex', 'flex-nowrap', 'gap-2')}>
                                                        {sd.documentUuids.map((uuid) => {
                                                            return (
                                                                <div className={clsx('whitespace-nowrap', 'underline')}>
                                                                    {
                                                                        realEstate?.documents?.find((d) => d.uuid === uuid)?.file
                                                                            ?.filename
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                        {!sd.documentUuids.length && (
                                                            <div className={clsx('whitespace-nowrap')}>
                                                                {t('Intermediary.(物件画像／地図のみ)')}
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={clsx('w-full')}>
                            <div className={clsx('sticky', 'top-20', 'mb-16')}>
                                <div className={clsx('mb-4')}>
                                    <CHeader label="物件紹介メールプレビュー" bgClass="bg-kimar-accent" />
                                </div>
                                <div className={clsx('rounded', 'border', 'bg-white', 'p-8')}>
                                    <div className={clsx('text-2xl', 'border-b')}>{previewSubject}</div>
                                    <div
                                        className={clsx('my-4', 'pointer-events-none')}
                                        dangerouslySetInnerHTML={{
                                            __html: previewBody,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={clsx('h-16')} />
                    <div
                        className={clsx(
                            'fixed',
                            'left-0',
                            'bottom-0',
                            'flex',
                            'p-2',
                            'justify-between',
                            'space-x-2',
                            'z-10',
                            'w-full',
                            'bg-white',
                        )}>
                        <CButton className={clsx('c-button-secondary')} onClick={() => setPrev(1)}>
                            {t('Button.戻る')}
                        </CButton>
                        <CButton
                            className={clsx('c-button-primary')}
                            disabled={!sendDocuments.length}
                            onClick={() => {
                                send()
                            }}>
                            <i className={clsx('material-symbols-outlined')}>Mail</i>
                            <div>{t('Intermediary.物件紹介メールを送信')}</div>
                        </CButton>
                    </div>
                </>
            )}
            <CConfirmModal
                visible={showAllSendModal}
                onRequestClose={() => setShowAllSendModal(false)}
                onRequestConfirm={() => {
                    allSendModalConfirm()
                }}
                title={t('Intermediary.すべての顧客に紹介')}
                confirmLabel={t('Intermediary.メール内容編集へ')}
                cancelLabel={t('Button.戻る')}>
                <div className={clsx('flex', 'flex-col', 'gap-4')}>
                    <RealEstateInfo />
                    <CMessage info>
                        <p>{t('Intermediary.送付する資料を選択して、顧客リストの全員に一括紹介します。')}</p>
                    </CMessage>
                    {(filteredDocuments?.length ?? 0) > 0 ? (
                        <table className={clsx('w-full')}>
                            <tbody>
                                {filteredDocuments.map((document) => (
                                    <tr
                                        key={document.uuid}
                                        className={clsx(
                                            'border-b',
                                            allSendDocuments.includes(document.uuid) ? 'bg-kimar-primary-light' : 'bg-white',
                                        )}>
                                        <td className={clsx('w-6')}>
                                            <CCheckBox
                                                className={clsx('rounded', 'border', 'border-white')}
                                                checked={!!allSendDocuments.length && allSendDocuments.includes(document.uuid)}
                                                onChange={() => {
                                                    setAllSendDocument(document.uuid)
                                                }}
                                            />
                                        </td>
                                        <td onClick={() => setAllSendDocument(document.uuid)} className={clsx('cursor-pointer')}>
                                            <div className={clsx('flex', 'items-center', 'justify-between')}>
                                                <div>{document.file?.filename}</div>
                                                <div>
                                                    <CTagList horizontal items={getLabelArray(document)} />
                                                </div>
                                            </div>
                                        </td>
                                        <td className={clsx('w-28', 'pl-2')}>
                                            <CButton
                                                className={clsx('c-button-secondary', 'text-sm')}
                                                onClick={() => previewDocument(document)}>
                                                {t('CRealestateDetailFile.プレビュー')}
                                            </CButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <CMessage info>{t('CRealestateDetailFile.物件資料がありません')}</CMessage>
                    )}
                </div>
            </CConfirmModal>
            <CConfirmModal
                visible={showNoSetDocumentModal}
                onRequestClose={() => setShowNoSetDocumentModal(false)}
                onRequestConfirm={() => {
                    setPage(2)
                    setShowNoSetDocumentModal(false)
                }}
                confirmLabel={t('Button.確定')}>
                <div className={clsx('m-2')}>
                    {t('Intermediary.送付する資料が未選択な顧客が存在します。このまま進んでもよろしいですか？')}
                </div>
            </CConfirmModal>
        </div>
    )
}

export default Intermediary
