import clsx from 'clsx'
import toast, { useToaster } from 'react-hot-toast/headless'

const CNotification = () => {
    const { toasts, handlers } = useToaster()
    const { startPause, endPause, calculateOffset, updateHeight } = handlers

    return (
        <div
            id="notification"
            onMouseEnter={startPause}
            onMouseLeave={endPause}
            className={clsx('fixed', 'top-24', 'md:top-20', 'right-80', 'z-[150]')}>
            {toasts.map((t) => {
                const offset = calculateOffset(t, {
                    reverseOrder: false,
                    gutter: 8,
                })

                const ref = (el: HTMLDivElement) => {
                    if (el && typeof t.height !== 'number') {
                        const height = el.getBoundingClientRect().height
                        updateHeight(t.id, height)
                    }
                }
                return (
                    <div
                        key={t.id}
                        ref={ref}
                        className={clsx(
                            'absolute',
                            'w-[300px]',
                            'p-4',
                            'rounded',
                            'border',
                            t.type === 'blank' && ['bg-gray-50', 'border-gray-700', 'text-gray-700'],
                            t.type === 'success' && ['bg-green-50', 'border-green-700', 'text-green-700'],
                            t.type === 'error' && ['bg-red-50', 'border-red-700', 'text-red-700'],
                            t.type === 'loading' && ['bg-sky-50', 'border-sky-700', 'text-sky-700'],
                            'bg-opacity-90',
                        )}
                        style={{
                            transition: 'all 0.5s ease-out',
                            opacity: t.visible ? 1 : 0,
                            transform: `translateY(${offset}px)`,
                        }}
                        {...t.ariaProps}>
                        <>
                            {/* {t.icon} */}
                            {t.message}
                            {t.type !== 'loading' && (
                                <button className={clsx('absolute', 'top-0', 'right-0')} onClick={() => toast.dismiss(t.id)}>
                                    <i className={clsx('material-symbols-outlined')}>close</i>
                                </button>
                            )}
                        </>
                    </div>
                )
            })}
        </div>
    )
}

export default CNotification
