import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast/headless'
import { createSearchParams, useNavigate } from 'react-router-dom'

import {
    ClientRealEstateRecommendListDto,
    CustomerEntities,
    RealEstateEntities,
    useClientCustomerUuidQuery,
    useClientRealEstateRecommendPutCheckMutation,
    useClientRealEstateRecommendPutExclusionMutation,
    useClientRealEstateRecommendRealEstateRecommendQuery,
} from '~/app/api'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import CInquiryButton from '~/components/common/cInquiryButton/CInquiryButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { taxType } from '~/types/enum/enum'
import { formatDateTime } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectIsPlanStandardOrOver, selectLoginUser, selectLoginUserHaveRecommendContract } from '~/util/store/userSlice'

import { ApplicationError, IApplicationError } from '../../../../types/error'
import { priceFormat, voteInterestFormat, voteRouteFormat } from '../../../../util/filter/format'
import { CButton } from '../../../common/cButton/CButton'
import { CError } from '../../../common/cError/CError'
import { CSortButton } from '../../../common/cSortButton/CSortButton'
import { CTag } from '../../../common/cTag/CTag'
import CCustomerDetailInfo from '../../customer/detail/CCustomerDetailInfo'

type Props = {
    realEstate: RealEstateEntities
    setOpenEditModal: (value: boolean) => void
}

const CRealestateDetailRecommend = ({ realEstate, setOpenEditModal }: Props) => {
    const user = useAppSelector(selectLoginUser)
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const navigate = useNavigate()
    const isRecommendContract = useAppSelector(selectLoginUserHaveRecommendContract)
    const isPlanStandardOrOver = useAppSelector(selectIsPlanStandardOrOver)
    const { data: recommendsData, isLoading: isLoadingRecommends } = useClientRealEstateRecommendRealEstateRecommendQuery({
        uuid: realEstate.uuid,
    })

    // レコメンド対象から外すAPI
    const [recommendExclusionAPI] = useClientRealEstateRecommendPutExclusionMutation()
    // レコメンド閲覧API
    const [recommendCheckAPI] = useClientRealEstateRecommendPutCheckMutation()
    // レコメンドを見たことを送信
    useEffect(() => {
        recommendCheckAPI({ uuid: realEstate.uuid })
    }, [realEstate.uuid])

    // 選択中のレコメンドリスト
    const [checkedRecommendList, setCheckedRecommendList] = useState<Array<ClientRealEstateRecommendListDto>>([])
    // レコメンドリストからの除外確認モーダル表示
    const [visibleSync, setVisibleSync] = useState(false)
    // 顧客詳細モーダル
    const [visibleCustomerDetailSync, setVisibleCustomerDetailSync] = useState(false)
    // 顧客詳細モーダルで表示する顧客
    const [clickedCustomer, setClickedCustomer] = useState<CustomerEntities | undefined>(undefined)

    const { data: selectedCustomer } = useClientCustomerUuidQuery(
        {
            uuid: clickedCustomer?.uuid ?? '',
        },
        { skip: !clickedCustomer },
    )

    // 検索フィルタ
    const [filter, setFilter] = useState({
        order: 'createdAt' as 'createdAt' | 'orderScore',
        sort: 'DESC' as 'ASC' | 'DESC',
    })

    const onChange = (v: 'createdAt' | 'orderScore') => {
        setFilter((oldValue) => ({
            ...oldValue,
            order: v,
        }))
    }
    const onChangeSort = () => {
        setFilter((oldValue) => ({
            ...oldValue,
            sort: oldValue.sort == 'ASC' ? 'DESC' : 'ASC',
        }))
    }

    // 検索反映後のレコメンド一覧
    const sortedRecommends = recommendsData?.list
        ? [...recommendsData.list].sort((a, b) => {
              // レコメンド日
              if (filter.order === 'createdAt') return dayjs(a.executionAt).diff(dayjs(b.executionAt), 'second')
              else if (filter.order === 'orderScore') return a.orderScore - b.orderScore
              return 0
          })
        : undefined
    if (filter.sort === 'DESC') sortedRecommends?.reverse()

    // 資料の合計閲覧数を返す
    const getDocumentViewsCount = (item: ClientRealEstateRecommendListDto) => {
        let count: number = 0
        if (!item.intermediaryTo?.intermediaryToDocuments?.length) return 0
        item.intermediaryTo.intermediaryToDocuments.forEach((document) => {
            count += document.previewCount + document.downloadCount
        })

        return count
    }

    const exclusion = async () => {
        try {
            setErrors([])
            if (!checkedRecommendList.length) {
                throw new ApplicationError(
                    t('CRealestateDetailRecommend.リストから削除するレコメンドデータが選択されていません。'),
                )
            }
            await recommendExclusionAPI({ uuid: realEstate.uuid, body: checkedRecommendList }).unwrap()
            toast.success(t('CRealestateDetailRecommend.レコメンド対象を解除しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleSync(false)
            setCheckedRecommendList([])
        }
    }

    const description = () => {
        if (isRecommendContract && realEstate.isRecommendTarget && !isLoadingRecommends) {
            if (recommendsData?.count) {
                return (
                    <div className={clsx('text-center')}>
                        <div>
                            {t('CRealestateDetailRecommend.あなたの物件に興味を持ちそうな顧客がcount人います。', {
                                count: recommendsData?.count,
                            })}
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={clsx('text-center')}>
                        <div>{t('CRealestateDetailRecommend.あなたの物件に興味を持ちそうな顧客はまだ見つかっていません。')}</div>
                        <div>
                            {t(
                                'CRealestateDetailRecommend.物件概要で未入力の項目を入力することでレコメンドが増えることがあります。',
                            )}
                        </div>
                    </div>
                )
            }
        }
    }

    const onChangeCheckBox = (stateChangeRecommend: ClientRealEstateRecommendListDto) => {
        if (checkedRecommendList.find((recommend) => recommend.uuid === stateChangeRecommend.uuid))
            setCheckedRecommendList((oldList) => oldList.filter((recommend) => recommend.uuid !== stateChangeRecommend.uuid))
        else setCheckedRecommendList((oldList) => [...oldList, stateChangeRecommend])
    }

    // 顧客名を押下したときのハンドラ
    const onClickCustomerName = (item: ClientRealEstateRecommendListDto) => {
        setClickedCustomer(item.customer)
        setVisibleCustomerDetailSync(true)
    }

    return (
        <div>
            {/* 冒頭文言 */}
            {description()}
            <CError errors={errors} />
            {!isRecommendContract ? (
                // レコメンド契約なし
                <div className={clsx('text-center', 'bg-white', 'm-4')}>
                    <CInquiryButton>
                        <p>{t('CRealestateDetailRecommend.ご契約のプランではレコメンドはお使いいただけません')}</p>
                    </CInquiryButton>
                </div>
            ) : !realEstate.isRecommendTarget ? (
                // レコメンド未設定
                <div className={clsx('mt-4')}>
                    <CMessage warning>
                        <div className={clsx('flex', 'justify-between', 'items-center')}>
                            <div>
                                {t('CRealestateDetailRecommend.この物件はレコメンド／コネクション対象物件に設定されていません')}
                            </div>
                            <div>
                                <CButton
                                    disabled={user?.roles.realestate !== 2}
                                    className={clsx('text-sm', 'c-button-primary')}
                                    onClick={() => {
                                        setOpenEditModal(true)
                                    }}>
                                    {t('CRealestateDetailRecommend.レコメンド利用の設定')}
                                </CButton>
                            </div>
                        </div>
                    </CMessage>
                </div>
            ) : isLoadingRecommends ? (
                // 読み込み中
                <div className={clsx('mt-4')}>
                    <CMessage info>{t('CRealestateDetailRecommend.レコメンドを読込中です')}</CMessage>
                </div>
            ) : recommendsData?.count === 0 ? (
                // レコメンド0件
                <div className={clsx('mt-4')}>
                    <CMessage info>{t('CRealestateDetailRecommend.レコメンドはありません')}</CMessage>
                </div>
            ) : (
                // レコメンドあり
                <div>
                    <div className={clsx('flex', 'flex-col', 'md:flex-row', 'justify-between', 'items-center', 'gap-2', 'my-2')}>
                        {/* ソートボタン */}
                        <div className={clsx('flex', 'items-center')}>
                            <CSortButton
                                className={clsx('border-gray-300')}
                                items={[
                                    {
                                        label: t('CRealestateDetailRecommend.レコメンド日'),
                                        value: 'createdAt',
                                    },
                                    {
                                        label: t('CRealestateDetailRecommend.マッチ度'),
                                        value: 'orderScore',
                                    },
                                ]}
                                sort={filter.sort}
                                onChange={(v) => {
                                    onChange(v as 'createdAt' | 'orderScore')
                                }}
                                onClickSort={onChangeSort}
                            />
                        </div>
                        {/* 通常ボタン */}
                        <div className={clsx('flex', 'gap-2')}>
                            {user?.roles.intermediary === 2 && (
                                <CButton
                                    className={clsx('c-button-primary', 'whitespace-nowrap')}
                                    disabled={checkedRecommendList.length === 0}
                                    onClick={() => {
                                        navigate({
                                            pathname: `/realestate/${realEstate.uuid}/intermediary`,
                                            search: createSearchParams({
                                                customers: checkedRecommendList.map((item) => item.customer.uuid),
                                            }).toString(),
                                        })
                                    }}>
                                    {t('CRealestateDetailRecommend.物件を紹介')}
                                </CButton>
                            )}
                            {user?.roles.realestate === 2 && (
                                <CButton
                                    className={clsx('c-button-danger', 'whitespace-nowrap')}
                                    disabled={checkedRecommendList.length === 0}
                                    onClick={() => setVisibleSync(true)}>
                                    {t('CRealestateDetailRecommend.リストから削除')}
                                </CButton>
                            )}
                        </div>
                    </div>

                    <ul>
                        {sortedRecommends?.map((item, index) => (
                            <li key={index} className={clsx('border-t', 'hover:bg-kimar-primary-light')}>
                                {item.intermediaryTo ? (
                                    // 紹介データがある場合
                                    <div className={clsx('flex', 'flex-row', 'gap-x-2')}>
                                        {user?.roles.intermediary === 2 && (
                                            <div className={clsx('flex', 'items-center')}>
                                                <CCheckBox
                                                    className={clsx('p-1', 'rounded', 'flex-1')}
                                                    value={
                                                        checkedRecommendList.find((recommend) => recommend.uuid === item.uuid)
                                                            ? 'checked'
                                                            : undefined
                                                    }
                                                    onChange={() => onChangeCheckBox(item)}
                                                />
                                            </div>
                                        )}
                                        <div className={clsx('w-full')}>
                                            {/* 顧客名・タグ */}
                                            <div className={clsx('flex')}>
                                                <div
                                                    className={clsx(
                                                        'text-lg',
                                                        'text-kimar-primary',
                                                        'underline',
                                                        'cursor-pointer',
                                                    )}
                                                    onClick={() => onClickCustomerName(item)}>
                                                    {item.customer?.name} ({item.customer?.companyName})
                                                </div>
                                                {!item.checkedAt && (
                                                    <div
                                                        className={clsx(
                                                            'flex',
                                                            'justify-center',
                                                            'items-center',
                                                            'text-sm',
                                                            'ml-2',
                                                        )}>
                                                        <CTag
                                                            className={clsx(
                                                                'rounded-full',
                                                                'bg-green-500',
                                                                'px-2',
                                                                'text-white',
                                                            )}>
                                                            {t('CRealestateDetailRecommend.NEW')}
                                                        </CTag>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className={clsx(
                                                    'grid',
                                                    'grid-cols-2',
                                                    'sm:grid-cols-4',
                                                    'gap-y-1',
                                                    'text-sm',
                                                    'p-1',
                                                )}>
                                                <div className={clsx('font-bold', 'border-b', 'px-1')}>
                                                    {t('CRealestateDetailRecommend.物件紹介日時')}
                                                </div>
                                                <div className={clsx('px-1', 'border-b')}>
                                                    {formatDateTime(item.intermediaryTo.createdAt, 'dateTime')}
                                                </div>
                                                <div className={clsx('font-bold', 'border-b', 'px-1')}>
                                                    {t('CRealestateDetailRecommend.最終アクセス日時')}
                                                </div>
                                                {item.intermediaryTo.lastAccessAt ? (
                                                    <div className={clsx('border-b', 'px-1')}>
                                                        {formatDateTime(item.intermediaryTo.lastAccessAt, 'dateTime')}
                                                    </div>
                                                ) : (
                                                    <div className={clsx('border-b', 'px-1')}>
                                                        {t('CRealestateDetailRecommend.未開封')}
                                                    </div>
                                                )}
                                                <div className={clsx('font-bold', 'border-b', 'px-1')}>
                                                    {t('CRealestateDetailRecommend.提示金額')}
                                                </div>
                                                <div className={clsx('px-1', 'border-b')}>
                                                    {priceFormat(item.intermediaryTo.intermediary.price)}
                                                    {item.intermediaryTo.intermediary.taxType
                                                        ? ' (' +
                                                          taxType.find(
                                                              (t) => t.value === item.intermediaryTo?.intermediary.taxType,
                                                          )?.label +
                                                          ')'
                                                        : ''}
                                                </div>
                                                <div className={clsx('font-bold', 'border-b', 'px-1')}>
                                                    {t('CRealestateDetailRecommend.資料の閲覧回数')}
                                                </div>
                                                <div className={clsx('px-1', 'border-b')}>
                                                    {getDocumentViewsCount(item)}
                                                    {t('Unit.回')}
                                                </div>
                                                {isPlanStandardOrOver && (
                                                    <>
                                                        <div className={clsx('font-bold', 'border-b', 'px-1')}>
                                                            {t('CRealestateDetailRecommend.取得状況')}
                                                        </div>
                                                        {item.intermediaryTo.intermediaryToVote?.length &&
                                                        item.intermediaryTo.intermediaryToVote[0].isAcquiredOtherRoute ? (
                                                            // 初取得の場合は文字が赤色
                                                            <div className={clsx('border-b', 'px-1', 'text-red-700')}>
                                                                {voteRouteFormat(
                                                                    item.intermediaryTo.intermediaryToVote[0]
                                                                        .isAcquiredOtherRoute,
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className={clsx('border-b', 'px-1')}>
                                                                {t('CRealestateDetailRecommend.未回答')}
                                                            </div>
                                                        )}
                                                        <div className={clsx('border-b', 'font-bold', 'px-1')}>
                                                            {t('CRealestateDetailRecommend.興味度合い')}
                                                        </div>
                                                        {item.intermediaryTo.intermediaryToVote?.length &&
                                                        item.intermediaryTo.intermediaryToVote[0].isInterested ? (
                                                            // 興味ありの場合は文字が赤色
                                                            <div className={clsx('border-b', 'px-1', 'text-red-700')}>
                                                                {voteInterestFormat(
                                                                    item.intermediaryTo.intermediaryToVote[0].isInterested,
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div className={clsx('border-b', 'px-1')}>
                                                                {t('CRealestateDetailRecommend.未回答')}
                                                            </div>
                                                        )}
                                                        <div className={clsx('border-b', 'font-bold', 'px-1')}>
                                                            {t('CRealestateDetailRecommend.紹介元')}
                                                        </div>
                                                        <div className={clsx('border-b', 'sm:col-span-3', 'px-1')}>
                                                            {item.intermediaryTo.intermediary.sentBy?.name}(
                                                            {item.intermediaryTo.intermediary.sentBy?.team.company.name})
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    // 紹介データがない場合
                                    <div className={clsx('flex', 'flex-row', 'gap-x-2')}>
                                        {user?.roles.intermediary === 2 && (
                                            <div className={clsx('flex', 'items-center')}>
                                                <CCheckBox
                                                    className={clsx('p-1', 'rounded', 'flex-1')}
                                                    value={
                                                        checkedRecommendList.find((recommend) => recommend.uuid === item.uuid)
                                                            ? 'checked'
                                                            : undefined
                                                    }
                                                    onChange={() => onChangeCheckBox(item)}
                                                />
                                            </div>
                                        )}
                                        <div className={clsx('flex', 'items-center', 'gap-2', 'flex-wrap')}>
                                            <div
                                                className={clsx('text-lg', 'text-kimar-primary', 'underline', 'cursor-pointer')}
                                                onClick={() => onClickCustomerName(item)}>
                                                {item.customer?.name} ({item.customer?.companyName})
                                            </div>
                                            {!item.checkedAt && (
                                                <div
                                                    className={clsx('flex', 'justify-center', 'items-center', 'text-sm', 'ml-2')}>
                                                    <CTag className={clsx('rounded-full', 'bg-green-500', 'px-2', 'text-white')}>
                                                        {t('CRealestateDetailRecommend.NEW')}
                                                    </CTag>
                                                </div>
                                            )}
                                            <CTag className={clsx('px-1', 'rounded', 'text-sm', 'bg-gray-500', 'text-white')}>
                                                {t('CRealestateDetailRecommend.未紹介')}
                                            </CTag>
                                        </div>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {/* 顧客名を押下した時の顧客詳細モーダル */}
            <CFooterModal
                footer={
                    <>
                        <CButton
                            className={clsx('c-button-secondary')}
                            onClick={() => {
                                setClickedCustomer(undefined)
                                setVisibleCustomerDetailSync(false)
                            }}>
                            {t('Button.閉じる')}
                        </CButton>
                    </>
                }
                visible={visibleCustomerDetailSync}
                onRequestClose={() => {
                    setClickedCustomer(undefined)
                    setVisibleCustomerDetailSync(false)
                }}>
                <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>
                    {t('CRealestateDetailRecommend.顧客情報')}
                </div>
                <div className={clsx('border', 'rounded', 'm-6', 'pt-2')}>
                    {selectedCustomer && <CCustomerDetailInfo customer={selectedCustomer} refetch={() => {}} />}
                </div>
            </CFooterModal>

            {/* レコメンドから外すときの確認モーダル */}
            <CConfirmModal
                visible={visibleSync}
                onRequestClose={() => setVisibleSync(false)}
                onRequestConfirm={() => exclusion()}
                confirmLabel={t('CRealestateDetailRecommend.削除確定')}>
                <p>{t('CRealestateDetailRecommend.レコメンド対象から外します。よろしいですか？')}</p>
            </CConfirmModal>
        </div>
    )
}

export default CRealestateDetailRecommend
