import clsx from 'clsx'
import { t } from 'i18next'
import { useState } from 'react'
import toast from 'react-hot-toast/headless'

import {
    FileEntities,
    RealEstateEntities,
    RealEstateMemoEntities,
    useClientRealEstateMemoDeleteUuidMutation,
    useClientRealEstateMemoPostRealEstateMemoMutation,
} from '~/app/api'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { CIconTips } from '~/components/common/cGuideTips/CGuideTips'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { formatDateTime } from '~/util/common/common'
import { getSignedUrl } from '~/util/model/FileEntities'
import { selectLoginUser } from '~/util/store/userSlice'

import { useAppSelector } from '../../../../util/store/hooks'
import { CButton } from '../../../common/cButton/CButton'

type Props = {
    realEstate: RealEstateEntities
    refetch: () => void
}

const CRealestateDetailMemo = ({ realEstate, refetch }: Props) => {
    // ログインしたユーザー
    const user = useAppSelector(selectLoginUser)

    // 送信ボタン
    const [content, setContent] = useState('')
    const [attachedFile, setAttachedFile] = useState<FileEntities>()
    const [insertMemoQuery] = useClientRealEstateMemoPostRealEstateMemoMutation()
    const insertButtonHandler = async () => {
        if (content.length === 0 && !attachedFile) return
        await insertMemoQuery({
            clientRealEstateMemoInsertDto: {
                content,
                attachmentUuid: attachedFile?.uuid,
                realEstate,
            },
        })
        setContent('')
        setAttachedFile(undefined)
        refetch()
        toast.success(t('CRealestateDetailMemo.物件の社内メモを追加しました'))
    }
    const fileUploaded = async (file?: FileEntities) => {
        setAttachedFile(file)
    }
    const isImageFile = (file?: FileEntities) => {
        if (file?.mimeType.match(/^image\//)) return true
        return false
    }
    const [deleteTarget, setDeleteTarget] = useState<RealEstateMemoEntities>()
    const [deleteMemoQuery] = useClientRealEstateMemoDeleteUuidMutation()
    const closeCancelModal = () => {
        setDeleteTarget(undefined)
    }
    const confirmCancelModal = async () => {
        if (!deleteTarget) return
        await deleteMemoQuery({ uuid: deleteTarget.uuid }).unwrap()
        closeCancelModal()
        refetch()
        toast.success(t('CRealestateDetailMemo.物件の社内メモを削除しました'))
    }

    if (!user) return null

    return (
        <>
            <CHeader label={t('CRealestateDetailMemo.社内メモ')} bgClass="bg-kimar-accent" />
            <div className={clsx('w-full', 'p-2', 'my-2', 'flex', 'flex-col', 'gap-2', 'bg-gray-50', 'rounded')}>
                {(realEstate.memoList?.length ?? 0) > 0 ? (
                    <div className={clsx('py-2')}>
                        {realEstate.memoList?.map((memo) => {
                            if (memo.createdBy?.uuid === user.uuid) {
                                return (
                                    <div key={memo.uuid} className={clsx('flex', 'gap-0.5', 'm-2', 'justify-end')}>
                                        <div
                                            className={clsx(
                                                'w-20',
                                                'flex-shrink-0',
                                                'h-auto',
                                                'flex',
                                                'flex-col',
                                                'items-end',
                                                'justify-end',
                                            )}>
                                            <div className={clsx('text-xs', 'text-gray-500', 'text-right')}>
                                                {formatDateTime(memo.createdAt, 'date')}
                                                <br />
                                                {formatDateTime(memo.createdAt, 'time')}
                                            </div>
                                            <CButton
                                                className={clsx('rounded-md', 'text-red-700', 'text-xs')}
                                                onClick={() => setDeleteTarget(memo)}>
                                                {t('Button.削除')}
                                            </CButton>
                                        </div>
                                        <div className={clsx('flex-shrink')}>
                                            <div
                                                className={clsx(
                                                    'bg-kimar-primary',
                                                    'rounded',
                                                    'text-white',
                                                    'px-3',
                                                    'py-1',
                                                    'whitespace-pre-wrap',
                                                )}>
                                                {memo.content}
                                                {memo.attachment && (
                                                    <a
                                                        className={clsx(
                                                            'underline',
                                                            'cursor-pointer',
                                                            'flex',
                                                            'flex-col',
                                                            'items-end',
                                                        )}
                                                        href={getSignedUrl(memo.attachment)}
                                                        target="_blank">
                                                        {isImageFile(memo.attachment) && (
                                                            <img
                                                                className={clsx('max-w-[200px]')}
                                                                src={getSignedUrl(memo.attachment)}
                                                            />
                                                        )}
                                                        <span>{memo.attachment.filename}</span>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={clsx(
                                                'w-3',
                                                'h-3',
                                                'bg-kimar-primary',
                                                '-ml-3',
                                                'mr-3',
                                                'mt-2.5',
                                                'rotate-45',
                                                'flex-shrink-0',
                                            )}
                                        />
                                        <CIconTips
                                            icon={
                                                <div
                                                    className={clsx(
                                                        'w-12',
                                                        'h-12',
                                                        'rounded-full',
                                                        'bg-gray-200',
                                                        'flex',
                                                        'peer',
                                                        'justify-center',
                                                        'items-center',
                                                    )}>
                                                    {memo.createdBy?.name.charAt(0)}
                                                </div>
                                            }
                                            tooltipDirection="left">
                                            {memo.createdBy?.name}
                                        </CIconTips>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={memo.uuid} className={clsx('flex', 'gap-0.5', 'm-2')}>
                                        <CIconTips
                                            icon={
                                                <i
                                                    className={clsx(
                                                        'w-12',
                                                        'h-12',
                                                        'rounded-full',
                                                        'bg-gray-200',
                                                        'flex',
                                                        'peer',
                                                        'justify-center',
                                                        'items-center',
                                                    )}>
                                                    {memo.createdBy ? memo.createdBy.name.charAt(0) : '―'}
                                                </i>
                                            }
                                            tooltipDirection="right">
                                            {memo.createdBy ? memo.createdBy.name : t('CCustomerDetailMemo.削除されたユーザー')}
                                        </CIconTips>
                                        <div
                                            className={clsx(
                                                'w-3',
                                                'h-3',
                                                'bg-gray-200',
                                                '-mr-3',
                                                'ml-3',
                                                'mt-2.5',
                                                'rotate-45',
                                                'flex-shrink-0',
                                            )}
                                        />
                                        <div className={clsx('flex-shrink')}>
                                            <div
                                                className={clsx('bg-gray-200', 'rounded', 'px-3', 'py-1', 'whitespace-pre-wrap')}>
                                                {memo.content}
                                                {memo.attachment && (
                                                    <a
                                                        className={clsx(
                                                            'underline',
                                                            'cursor-pointer',
                                                            'flex',
                                                            'flex-col',
                                                            'items-start',
                                                        )}
                                                        href={getSignedUrl(memo.attachment)}
                                                        target="_blank">
                                                        {isImageFile(memo.attachment) && (
                                                            <img
                                                                className={clsx('max-w-[200px]')}
                                                                src={getSignedUrl(memo.attachment)}
                                                            />
                                                        )}
                                                        <span>{memo.attachment.filename}</span>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={clsx(
                                                'w-20',
                                                'flex-shrink-0',
                                                'h-auto',
                                                'flex',
                                                'flex-col',
                                                'items-start',
                                                'justify-end',
                                            )}>
                                            <div className={clsx('text-xs', 'text-gray-500', 'text-left')}>
                                                {formatDateTime(memo.createdAt, 'date')}
                                                <br />
                                                {formatDateTime(memo.createdAt, 'time')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                ) : (
                    <CMessage info>{t('CRealestateDetailMemo.社内メモがありません')}</CMessage>
                )}
                {user?.roles.realestate === 2 && (
                    <div className={clsx()}>
                        <div className={clsx('grid', 'grid-cols-2', 'gap-1', 'mb-1')}>
                            <CTextInput
                                placeholder={t('CRealestateDetailMemo.社内メモは自社ユーザーにしか表示されません')}
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'multiline'}
                                text={content}
                                onChange={(val) => setContent(val)}
                            />
                            {attachedFile ? (
                                <div>
                                    <div className={clsx('rounded', 'bg-gray-100', 'p-1', 'mb-1')}>{attachedFile.filename}</div>
                                    <CButton
                                        className={clsx('c-button-danger', 'w-full')}
                                        onClick={() => setAttachedFile(undefined)}>
                                        {t('Chat.添付ファイルを削除')}
                                    </CButton>
                                </div>
                            ) : (
                                <CFileUpload fileUploaded={(files) => fileUploaded(files.length ? files[0] : undefined)} />
                            )}
                        </div>
                        <CButton
                            className={clsx('c-button-primary', 'w-full')}
                            disabled={content.length === 0 && !attachedFile}
                            onClick={insertButtonHandler}>
                            {t('CRealestateDetailMemo.社内メモを送信')}
                        </CButton>
                    </div>
                )}
            </div>
            <CConfirmModal
                visible={!!deleteTarget}
                onRequestClose={closeCancelModal}
                onRequestConfirm={confirmCancelModal}
                confirmLabel={'削除'}>
                <CMessage info>{deleteTarget?.content}</CMessage>
                <div>{t('CRealestateDetailMemo.上記の社内メモを削除します。よろしいですか？')}</div>
            </CConfirmModal>
        </>
    )
}

export default CRealestateDetailMemo
