import clsx from 'clsx'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router'

import { CompanyEntities, useAdminCompanyCompanyQuery } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CHeader } from '~/components/common/cHeader/CHeader'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import { CPager } from '~/components/common/cPager/CPager'
import { CSortButton } from '~/components/common/cSortButton/CSortButton'
import { CTag } from '~/components/common/cTag/CTag'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { InsertCompanyModal } from '~/components/unique/company/InsertCompanyModal'
import { pageTitleTemplate } from '~/util/common/common'

const AdminCompany = () => {
    const navigate = useNavigate()

    // 検索フォーム
    const [form, setForm] = useState<{
        name: string
        includeCanceledCompany: boolean
        sort: 'ASC' | 'DESC'
    }>({
        name: '',
        includeCanceledCompany: false,
        sort: 'ASC',
    })
    const [name, setName] = useState('')
    const [page, setPage] = useState(1)
    const [timer, setTimer] = useState<NodeJS.Timeout>()

    // 会社契約一覧を取得
    const { data: companyList, isLoading: isLoadingCompanyList } = useAdminCompanyCompanyQuery({
        page,
        name: form.name,
        includeCanceledCompany: form.includeCanceledCompany,
        order: 'name',
        sort: form.sort,
    })

    const tableRef = useRef<HTMLDivElement>(null)
    const callbackTableRef = useCallback(() => {
        if (tableRef.current !== null) tableRef.current.scrollTop = 0
    }, [companyList])
    useEffect(() => callbackTableRef(), [callbackTableRef])

    const onChangeText = (val: string) => {
        setName(val)
        if (timer) clearTimeout(timer)
        const newTimer = setTimeout(() => {
            setForm({
                ...form,
                name: val,
            })
        }, 500)
        setTimer(newTimer)
    }

    const onChangeSort = () => {
        setPage(1)
        setForm({
            ...form,
            sort: form.sort == 'ASC' ? 'DESC' : 'ASC',
        })
    }

    const toggleIncludeCanceledCompany = (value: boolean) => {
        setPage(1)
        setForm({
            ...form,
            includeCanceledCompany: value,
        })
    }

    const onPageChanged = (_page: number) => {
        setPage(_page)
    }

    const [showInsertModal, setShowInsertModal] = useState(false)
    const closeInsertCompanyModal = () => {
        setShowInsertModal(false)
    }

    const submitInsertCompany = (company: CompanyEntities) => {
        navigate(`/admin/company/${company.uuid}`)
    }

    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate(true)}>
                <title>会社管理</title>
            </Helmet>
            <CHeader label="会社管理" />
            <div className={clsx('flex', 'mx-2', 'justify-between', 'items-center')}>
                <div className={clsx('flex', 'space-x-2')}>
                    <CLabeledItem label="並び順" horizontal>
                        <CSortButton
                            className={clsx('border-gray-300')}
                            items={[
                                {
                                    label: '会社名',
                                    value: '会社名',
                                },
                            ]}
                            sort={form.sort}
                            onChange={() => {}}
                            onClickSort={onChangeSort}
                        />
                    </CLabeledItem>
                    <CLabeledItem label="絞り込み" horizontal>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={'会社名'}
                            text={name}
                            onChange={(val) => onChangeText(val)}
                        />
                    </CLabeledItem>
                    <CLabeledItem label="解約済みの会社" horizontal>
                        <CToggleSwitch
                            width="lg"
                            value={form.includeCanceledCompany}
                            trueLabel={'表示'}
                            falseLabel={'非表示'}
                            toggleChanged={(value) => {
                                toggleIncludeCanceledCompany(value)
                            }}
                        />
                    </CLabeledItem>
                </div>
                <div>
                    <CButton className={clsx('c-button-primary')} onClick={() => setShowInsertModal(true)}>
                        <i className={clsx('material-icons')}>add</i>
                        <div>新規会社登録</div>
                    </CButton>
                </div>
            </div>
            {isLoadingCompanyList ? (
                'loading'
            ) : (
                <>
                    <div ref={tableRef} className={clsx('w-full', 'px-2', 'my-2', 'h-[calc(100vh-340px)]', 'overflow-y-auto')}>
                        <table className={clsx('w-full', 'text-sm')}>
                            <thead className={clsx('sticky', 'top-[0px]', 'text-xs')}>
                                <tr className={clsx('bg-kimar-primary', 'text-white', 'h-8')}>
                                    <th>会社名</th>
                                    <th className={clsx('w-36')}>プラン</th>
                                    <th className={clsx('w-16')}>メンバー数</th>
                                    <th className={clsx('w-20', 'hidden', 'lg:table-cell')}>レコメンド</th>
                                    <th className={clsx('w-20', 'hidden', 'lg:table-cell')}>コネクション</th>
                                    <th className={clsx('w-20', 'hidden', 'lg:table-cell')}>マーケット</th>
                                    <th className={clsx('w-20', 'hidden', 'lg:table-cell')}>KPI集計対象</th>
                                </tr>
                            </thead>
                            <tbody className={clsx('bg-white', 'divide-y')}>
                                {companyList?.list.map((_company) => {
                                    const company = _company as CompanyEntities
                                    const nowContract = company.contracts.find((c) => !c.EndAt)
                                    return (
                                        <tr key={company.uuid} className={clsx('hover:bg-kimar-primary-light')}>
                                            <td>
                                                <CLinkButton to={`/admin/company/${company.uuid}`}>
                                                    <div className={clsx('flex', 'justify-between', 'px-2')}>
                                                        <div>{company.name}</div>
                                                        <div>
                                                            {!company.contracts.length && (
                                                                <CTag
                                                                    className={clsx(
                                                                        'rounded',
                                                                        'bg-gray-300',
                                                                        'px-2',
                                                                        'text-gray-700',
                                                                        'ml-2',
                                                                    )}>
                                                                    契約なし
                                                                </CTag>
                                                            )}
                                                            {!!company.contracts.length && !nowContract && (
                                                                <CTag
                                                                    className={clsx(
                                                                        'rounded',
                                                                        'bg-gray-300',
                                                                        'px-2',
                                                                        'text-gray-700',
                                                                        'ml-2',
                                                                    )}>
                                                                    解約済み
                                                                </CTag>
                                                            )}
                                                        </div>
                                                    </div>
                                                </CLinkButton>
                                            </td>
                                            <td>{nowContract?.plan.name || '(契約なし)'}</td>
                                            <td>
                                                {company.teams.length
                                                    ? company.teams.reduce((prev, team) => prev + team.teamMembers.length, 0)
                                                    : 0}
                                                人
                                            </td>
                                            <td className={clsx('hidden', 'lg:table-cell')}>
                                                <div className={clsx('flex')}>
                                                    <CCheckBox
                                                        className={clsx('p-1', 'rounded', 'flex-1')}
                                                        readonly
                                                        checked={!!nowContract?.recommendFeatureCondition}
                                                        label={nowContract?.recommendFeatureCondition ? '有効' : '無効'}
                                                    />
                                                </div>
                                            </td>
                                            <td className={clsx('hidden', 'lg:table-cell')}>
                                                <div className={clsx('flex')}>
                                                    <CCheckBox
                                                        className={clsx('p-1', 'rounded', 'flex-1')}
                                                        readonly
                                                        checked={!!nowContract?.connectionFeatureCondition}
                                                        label={nowContract?.connectionFeatureCondition ? '有効' : '無効'}
                                                    />
                                                </div>
                                            </td>
                                            <td className={clsx('hidden', 'lg:table-cell')}>
                                                <div className={clsx('flex')}>
                                                    <CCheckBox
                                                        className={clsx('p-1', 'rounded', 'flex-1')}
                                                        readonly
                                                        checked={!!nowContract?.marketFeatureCondition?.requestLimit}
                                                        label={
                                                            nowContract?.marketFeatureCondition?.requestLimit ? '有効' : '無効'
                                                        }
                                                    />
                                                </div>
                                            </td>
                                            <td className={clsx('hidden', 'lg:table-cell')}>
                                                <div className={clsx('flex', 'justify-center')}>
                                                    <CCheckBox
                                                        className={clsx('p-1', 'rounded', 'flex-1')}
                                                        readonly
                                                        checked={company.isKpiTarget}
                                                        label={company.isKpiTarget ? '対象' : '対象外'}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <CPager page={page} pageSize={50} total={companyList?.count ?? 0} onPageChanged={onPageChanged} />
                </>
            )}
            <InsertCompanyModal isShow={showInsertModal} onSubmit={submitInsertCompany} onClose={closeInsertCompanyModal} />
        </>
    )
}
export default AdminCompany
