import clsx from 'clsx'
import { t } from 'i18next'
import { Helmet } from 'react-helmet-async'

import { useClientFaqGetFaqListQuery } from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CFaqListItem } from '~/components/unique/faq/CFaqListItem'
import { pageTitleTemplate } from '~/util/common/common'

const Faq = () => {
    // FAQ一覧を取得
    const { data: faqList, isLoading: isLoadingFaqList } = useClientFaqGetFaqListQuery({})

    return (
        <div className={clsx('py-10', 'flex', 'flex-col', 'gap-y-3')}>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Faq.よくあるご質問')}</title>
            </Helmet>
            <CHeader label={t('Faq.よくあるご質問')} />
            <div className={clsx('m-2')}>
                {isLoadingFaqList ? (
                    <CMessage info>{t('Message.読み込み中です…')}</CMessage>
                ) : (
                    <ul className={clsx('w-full', 'max-w-[768px]', 'mx-auto', 'table', 'border-collapse')}>
                        {faqList?.list.map((content) => (
                            <li key={content.uuid} className={clsx('border-y-2', 'table-row', 'w-full')}>
                                <CFaqListItem faq={content} />
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default Faq
