import clsx from 'clsx'
import { ReactNode } from 'react'

type Props = {
    tableWidth?: string
    border?: boolean
    thead?: ReactNode
    tbody?: ReactNode
}

const CTable = ({ tableWidth = '100%', thead, tbody }: Props) => (
    <table className={clsx('border', 'border-gray-200', 'text-sm', 'overflow-auto')} style={{ width: tableWidth }}>
        {thead && (
            <thead className={clsx('bg-kimar-primary', 'text-white', 'text-left', 'leading-7', 'whitespace-nowrap')}>
                {thead}
            </thead>
        )}
        {tbody && <tbody className={clsx('bg-white', 'divide-y', 'leading-7')}>{tbody}</tbody>}
    </table>
)

export default CTable
