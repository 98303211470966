import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { orderBy } from 'lodash'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast/headless'

import {
    CustomerAssigneeEntities,
    CustomerEntities,
    TeamMemberEntities,
    useClientCustomerAssigneeDeleteUuidMutation,
    useClientCustomerAssigneePostCustomerAssigneeMutation,
    useClientCustomerAssigneePutUuidMutation,
    useClientTeamMemberTeamUuidQuery,
} from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CTag } from '~/components/common/cTag/CTag'
import { selectLoginUser, selectLoginUserTeam } from '~/util/store/userSlice'

import { ApplicationError, IApplicationError } from '../../../../types/error'
import { useAppSelector } from '../../../../util/store/hooks'
import { CButton } from '../../../common/cButton/CButton'
import { CDropDown } from '../../../common/cDropdown/CDropdown'
import { CError } from '../../../common/cError/CError'

type Props = {
    customer: CustomerEntities
    refetch: () => void
}

const CCustomerDetailAssignee = ({ customer, refetch }: Props) => {
    const user = useAppSelector(selectLoginUser)
    const userTeam = useAppSelector(selectLoginUserTeam)
    const { data: teamMemberListCount } = useClientTeamMemberTeamUuidQuery(
        { teamUuid: userTeam?.uuid ?? '' },
        { skip: !userTeam },
    )
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const userRoleCusromerWatchOnly = () => user?.roles.customer === 1

    // 追加
    const [insertUuid, setInsertUuid] = useState<string>()
    useEffect(() => {
        if (teamMemberListCount && filterdMembers().length > 0) setInsertUuid(filterdMembers()[0].uuid)
    }, [teamMemberListCount])
    const [insertQuery] = useClientCustomerAssigneePostCustomerAssigneeMutation()
    const insertAssignee = async () => {
        try {
            setErrors([])
            if (!teamMemberListCount) return
            const insertMember = (teamMemberListCount.list as TeamMemberEntities[]).find((m) => m.uuid === insertUuid)
            if (!insertMember) return
            await insertQuery({
                clientCustomerAssigneeInsertDto: {
                    isPrimary: !customer.assignees?.length,
                    member: insertMember,
                    customer,
                },
            }).unwrap()
            if (teamMemberListCount && filterdMembers().length > 0) setInsertUuid(filterdMembers()[0].uuid)
            refetch()
            toast.success(t('CCustomerDetailAssignee.顧客担当者を追加しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const filterdMembers = () => {
        const members = teamMemberListCount?.list as TeamMemberEntities[]
        if (!customer.assignees) return members
        // 担当者がすでにいる場合はリストから除外
        const assignees = customer.assignees.map((assignee) => assignee.assignedTo)
        const result = members.filter((member) => !assignees.some((assignee) => assignee?.email === member.email))
        return result
    }

    // 編集(担当入れ替え)
    const [updateQuery] = useClientCustomerAssigneePutUuidMutation()
    const updateAssignee = async (assignee: CustomerAssigneeEntities, isPrimary: boolean) => {
        try {
            setErrors([])
            await updateQuery({
                uuid: assignee.uuid,
                clientCustomerAssigneeUpdateDto: {
                    isPrimary,
                },
            }).unwrap()
            if (teamMemberListCount && filterdMembers().length > 0) setInsertUuid(filterdMembers()[0].uuid)
            refetch()
            toast.success(t('CCustomerDetailAssignee.顧客担当者を編集しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    // 削除
    const [deleteTarget, setDeleteTarget] = useState<CustomerAssigneeEntities>()
    const [deleteQuery] = useClientCustomerAssigneeDeleteUuidMutation()
    const deleteAssignee = async () => {
        if (!deleteTarget) return
        await deleteQuery({ uuid: deleteTarget.uuid }).unwrap()
        setDeleteTarget(undefined)
        if (teamMemberListCount && filterdMembers().length > 0) setInsertUuid(filterdMembers()[0].uuid)
        refetch()
        toast.success(t('CCustomerDetailAssignee.顧客担当者を削除しました'))
    }

    return (
        <>
            <CHeader label={t('CCustomerDetailAssignee.顧客担当者')} bgClass="bg-kimar-accent" />
            <div>
                {(customer.assignees?.length ?? 0) > 0 ? (
                    <table className={clsx('w-full', 'text-sm')}>
                        <thead>
                            <tr className={clsx('bg-kimar-primary', 'text-white', 'text-left')}>
                                <th className={clsx('pl-2')}>{t('CCustomerDetailAssignee.担当者名')}</th>
                                <th className={clsx('pl-2', 'w-[200px]')}>{t('CCustomerDetailAssignee.操作')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderBy(customer.assignees ?? [], ['isPrimary', 'createdAt'], ['desc', 'asc']).map((assignee) => (
                                <tr key={assignee.uuid} className={clsx('border-b')}>
                                    <td className={clsx('pl-2')}>
                                        <div className={clsx('flex', 'items-center', 'justify-start')}>
                                            <div>
                                                <CTag
                                                    className={clsx(
                                                        'rounded',
                                                        'px-2',
                                                        'mr-2',
                                                        'text-white',
                                                        assignee.isPrimary ? 'bg-kimar-primary' : 'bg-sky-700',
                                                    )}
                                                    children={
                                                        <>
                                                            {assignee.isPrimary
                                                                ? t('CCustomerDetailAssignee.主担当者')
                                                                : t('CCustomerDetailAssignee.副担当者')}
                                                        </>
                                                    }
                                                />
                                            </div>
                                            <div>{assignee.assignedTo?.name}</div>
                                        </div>
                                    </td>
                                    <td className={clsx('pl-2')}>
                                        <div className={clsx('flex', 'items-center', 'gap-1')}>
                                            {customer.assignees?.length !== 1 && (
                                                <CButton
                                                    className={clsx(
                                                        assignee.isPrimary ? 'c-button-secondary' : 'c-button-primary',
                                                        'text-sm',
                                                    )}
                                                    disabled={userRoleCusromerWatchOnly()}
                                                    onClick={() => updateAssignee(assignee, !assignee.isPrimary)}>
                                                    {assignee.isPrimary
                                                        ? t('CCustomerDetailAssignee.副担当者に変更')
                                                        : t('CCustomerDetailAssignee.主担当者に変更')}
                                                </CButton>
                                            )}
                                            <CButton
                                                className={clsx('c-button-danger', 'text-sm')}
                                                disabled={userRoleCusromerWatchOnly()}
                                                onClick={() => setDeleteTarget(assignee)}>
                                                {t('Button.削除')}
                                            </CButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <CMessage info>{t('CCustomerDetailAssignee.担当者は設定されていません')}</CMessage>
                )}
            </div>
            {teamMemberListCount && user?.roles.customer === 2 && (
                <div className={clsx('mb-8')}>
                    <CError errors={errors} />
                    <div className={clsx('flex', 'flex-col', 'lg:flex-row', 'justify-between', 'space-x-2', 'lg:items-center')}>
                        <div className={clsx('text-sm')}>
                            {t('CCustomerDetailAssignee.顧客の担当者にするメンバーを選択してください。')}
                        </div>
                        <div className={clsx('flex', 'space-x-2')}>
                            <div className={clsx('flex-1')}>
                                <CDropDown
                                    className={clsx('w-full', 'min-w-[180px]', 'rounded', 'border-gray-300')}
                                    items={filterdMembers()}
                                    dataLabel="name"
                                    dataValue="uuid"
                                    nowValue={insertUuid}
                                    onChange={(value) => setInsertUuid(value)}
                                />
                            </div>
                            <CButton
                                className={clsx('c-button-primary', 'whitespace-nowrap')}
                                disabled={filterdMembers().length === 0}
                                onClick={insertAssignee}>
                                {t('CCustomerDetailAssignee.担当者に追加')}
                            </CButton>
                        </div>
                    </div>
                </div>
            )}
            {/* 削除確認モーダル */}
            <CConfirmModal
                visible={!!deleteTarget}
                onRequestClose={() => setDeleteTarget(undefined)}
                confirmLabel={t('Button.削除')}
                onRequestConfirm={() => deleteAssignee()}>
                {t('CCustomerDetailAssignee.担当者「assignedName」を削除します。よろしいですか？', {
                    assignedName: deleteTarget?.assignedTo?.name,
                })}
            </CConfirmModal>
        </>
    )
}

export default CCustomerDetailAssignee
