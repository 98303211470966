import clsx from 'clsx'
import { Trans } from 'react-i18next'

import { CNoticeWindow } from '~/components/common/cNoticeWindow/CNoticeWindow'

const CNoticeMarketRequest = () => {
    return (
        <CNoticeWindow>
            <div className={clsx('text-center', 'px-8')}>
                <div className={clsx('text-kimar-market', 'text-2xl', 'font-bold', 'mb-3')}>
                    <Trans i18nKey="CNoticeMarketRequest.情報開示リクエストで新規物件を獲得しましょう！">
                        情報開示リクエストで
                        <br />
                        新規物件を獲得しましょう！
                    </Trans>
                </div>
                <div>
                    <p>
                        <Trans i18nKey="CNoticeMarketRequest.リクエストが承諾されると物件紹介が受けられます。">
                            リクエストが承諾されると
                            <br />
                            物件紹介が受けられます。
                        </Trans>
                    </p>
                </div>
                <div className={clsx('w-full')}>
                    <img className={clsx('mx-auto')} src="/src/assets/image/page/market/notice_market_request.svg" alt="image" />
                </div>
            </div>
        </CNoticeWindow>
    )
}

export default CNoticeMarketRequest
