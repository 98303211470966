import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Trans } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useClientCustomerIntermediaryPostReviewFinishGuestMutation, useClientIntermediaryToUuidQuery } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import { CHeader } from '~/components/common/cHeader/CHeader'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import CMessage from '~/components/common/cMessage/CMessage'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { RouteClientAuthGuard } from '~/components/global/guard/RouteClientAuthGuard'
import { CompanyLogo } from '~/components/unique/company/CompanyLogo'
import CIntermediaryVote from '~/components/unique/intermediary/CIntermediaryVote'
import CRealestateDetailIntermediaryFile from '~/components/unique/realestate/detail/CRealestateDetailIntermediaryFile'
import CRealestateDetailIntermediaryInfo from '~/components/unique/realestate/detail/CRealestateDetailIntermediaryInfo'
import { IApplicationError } from '~/types/error'
import { pageTitleTemplate, staticMapImageUrl } from '~/util/common/common'
import { useAppDispatch, useAppSelector } from '~/util/store/hooks'
import { selectIntermediaryTo, setIntermediaryTo } from '~/util/store/intermediarySlice'

const RealestateDetailIntermediary = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { intermediaryUuid: intermediaryUuid } = params

    const [queryPassword, setQueryPassword] = useState<string>()

    const {
        data: dto,
        isLoading,
        isError,
        refetch,
    } = useClientIntermediaryToUuidQuery(
        {
            uuid: intermediaryUuid!,
            password: queryPassword,
        },
        { skip: !intermediaryUuid },
    )
    const _intermediaryTo = useAppSelector(selectIntermediaryTo)

    const [password, setPassword] = useState('')
    const clickSetPassword = () => {
        setQueryPassword(password)
    }

    useEffect(() => {
        if (dto && dto.intermediaryTo && dto.intermediaryTo.uuid !== _intermediaryTo?.uuid)
            dispatch(setIntermediaryTo(dto.intermediaryTo))
        if (dto?.realEstate) navigate(`/realestate/${dto.realEstate.uuid}`)
    }, [dto])

    const realEstateImage = useMemo(() => {
        const coverImg = dto?.intermediaryTo?.intermediaryToDocuments?.find((d) => !!d.documentFrom?.isCover)
        if (coverImg) return `${process.env.BASE_URL}/api/client/media/${coverImg.file.uuid}`
        return `/src/assets/image/noimage.svg`
    }, [dto])

    const isExpired = useMemo(() => {
        return (
            isError ||
            !!dto?.intermediaryTo?.reviewFinishedAt ||
            !!dto?.intermediaryTo?.intermediaryFinishedAt ||
            dayjs(dto?.intermediaryTo?.expiresAt).isBefore(dayjs(), 'day')
        )
    }, [dto, isError])

    const mapImage = useMemo(() => {
        if (
            !dto?.intermediaryTo?.intermediary.sourceRealEstateRevision?.latitude ||
            !dto?.intermediaryTo?.intermediary.sourceRealEstateRevision.longitude
        )
            return undefined
        const coverImg = dto?.intermediaryTo?.intermediaryToDocuments?.find((d) => !!d.documentFrom?.isMap)?.file
        if (coverImg) return `${process.env.BASE_URL}/api/client/media/${coverImg.uuid}`
        else {
            return staticMapImageUrl(
                dto?.intermediaryTo?.intermediary.sourceRealEstateRevision?.latitude,
                dto?.intermediaryTo?.intermediary.sourceRealEstateRevision.longitude,
                300,
                300,
            )
        }
    }, [dto])

    const errors: IApplicationError[] = []
    // タブ表示
    const [selectedTab, setSelectedTab] = useState('file')
    const tabs: Array<{ label: string; value: string }> = [
        { label: t('Intermediary.物件資料'), value: 'file' },
        { label: t('Intermediary.物件情報'), value: 'detail' },
    ]

    const linkToForm = () => {
        window.open('https://forms.gle/Pr19tpJjcxdZTc7r6', '_blank', 'noopener,noreferrer')
    }

    const [showFinishIntermediaryModal, setShowFinishIntermediaryModal] = useState(false)
    const [finishIntermediaryQuery] = useClientCustomerIntermediaryPostReviewFinishGuestMutation()
    const finishIntermediary = async (sendNotification: boolean) => {
        if (!dto) return
        await finishIntermediaryQuery({
            clientIntermediaryReviewFinishDto: { intermediaryToUuid: dto.intermediaryTo.uuid, sendNotification },
        }).unwrap()
        setShowFinishIntermediaryModal(false)
        refetch()
    }

    if (isLoading) return <CMessage info>{t('Message.読み込み中です…')}</CMessage>
    if (dto && dto.requiredLogin) return <RouteClientAuthGuard />

    if (dto && dto.requiredPassword) {
        return (
            <>
                <Helmet titleTemplate={pageTitleTemplate()}>
                    <title>{t('Intermediary.パスワード入力')}</title>
                </Helmet>
                <header
                    className={clsx(
                        'w-full',
                        'px-3',
                        'bg-white',
                        'border-1',
                        'border-gray-300',
                        'shadow-md',
                        'h-16',
                        'text-sm',
                        'sticky',
                        'z-100',
                        'top-0',
                        'flex',
                    )}>
                    <div className={clsx('w-full', 'flex', 'items-center')}>
                        <div>
                            <CompanyLogo />
                        </div>
                    </div>
                </header>
                <div className={clsx('max-w-lg', 'mx-auto', 'p-4', 'flex', 'flex-col', 'gap-4', 'bg-white', 'rounded')}>
                    {dto.incorrectPassword && (
                        <CError
                            errors={[
                                {
                                    message: t('Intermediary.パスワードが一致しません。'),
                                    statusCode: '',
                                },
                            ]}
                        />
                    )}
                    <CMessage warning>{t('Intermediary.物件を閲覧するにはパスワードを入力してください。')}</CMessage>
                    <div className={clsx('w-full')}>
                        <CLabeledItem label={t('Intermediary.パスワード')} required>
                            <CTextInput
                                text={password}
                                onChange={(value) => setPassword(value)}
                                type={'text'}
                                className={clsx('w-full', 'c-text-input-base')}
                            />
                        </CLabeledItem>
                    </div>
                    <div className={clsx('w-full', 'flex', 'justify-center')}>
                        <CButton onClick={clickSetPassword} disabled={!password} className={clsx('c-button-primary', 'w-full')}>
                            {t('Button.確定')}
                        </CButton>
                    </div>
                </div>
                <footer
                    className={clsx(
                        'font-body',
                        'w-full',
                        'bg-gray-700',
                        'text-white',
                        'text-xs',
                        'py-6',
                        'px-4',
                        'sticky',
                        'text-center',
                        'top-[100vh]',
                    )}>
                    <CButton className={clsx('bg-gray-700', 'w-full', 'max-w-xl', 'py-1')} onClick={() => linkToForm()}>
                        <img src="/src/assets/image/banner/user_register.png" />
                    </CButton>
                    <ul className={clsx('flex', 'justify-center', 'flex-wrap', 'space-x-2', 'leading-loose')}>
                        <Link className={clsx('underline')} to="eura">
                            {t('SiteFooter.利用規約')}
                        </Link>
                        <Link className={clsx('underline')} to="privacy">
                            {t('SiteFooter.プライバシーポリシー')}
                        </Link>
                        <Link className={clsx('underline')} to="scta_notation">
                            {t('SiteFooter.特定商取引法に基づく表記')}
                        </Link>
                        <Link className={clsx('underline')} to="inquiry">
                            {t('SiteFooter.お問い合わせ')}
                        </Link>
                        <a href="https://limar.co.jp" target="_blank" rel="noopener noreferrer" className={clsx('underline')}>
                            {t('SiteFooter.運営会社')}
                        </a>
                    </ul>
                    <div className={clsx('text-center', 'mt-6', 'tracking-widest')}>COPYRIGHT &copy; LimarEstate LIMITED</div>
                </footer>
            </>
        )
    }

    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Intermediary.ご紹介物件詳細')}</title>
            </Helmet>
            <header
                className={clsx(
                    'w-full',
                    'px-3',
                    'bg-white',
                    'border-1',
                    'border-gray-300',
                    'shadow-md',
                    'h-16',
                    'text-sm',
                    'sticky',
                    'z-100',
                    'top-0',
                    'flex',
                )}>
                <div className={clsx('w-full', 'flex', 'items-center')}>
                    {/* 紹介元がロゴを設定している場合のみロゴ表示 */}
                    {dto?.intermediaryTo?.intermediary?.sentBy?.team?.company?.logoFile && (
                        <div>
                            <CompanyLogo company={dto?.intermediaryTo?.intermediary?.sentBy?.team?.company} />
                        </div>
                    )}
                    <div className={clsx('font-body', 'text-gray-700', 'w-full')}>
                        <div className={clsx('flex', 'justify-between', 'items-center')}>
                            <div className={clsx('flex', 'justify-start', 'items-center', 'mx-4')}>
                                {dto?.intermediaryTo?.intermediary?.sentBy?.team?.company?.name}
                            </div>
                            <div className={clsx('flex', 'justify-end', 'items-center')}>
                                <div>powered by</div>
                                <div className={clsx('mx-2')}>
                                    <Link to="/">
                                        <img src="/src/assets/image/logo.svg" className={clsx('h-4')} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className={clsx('p-4', 'md:p-8', 'flex-1', 'bg-gray-50')}>
                {(!dto || isExpired) && (
                    <div
                        className={clsx(
                            'flex',
                            'flex-col',
                            'gap-4',
                            'max-w-xl',
                            'min-h-[calc(100vh-300px)]',
                            'mx-auto',
                            'p-8',
                            'rounded',
                            'bg-white',
                        )}>
                        <div className={clsx('flex', 'justify-center')}>
                            <img src="/src/assets/image/logo.svg" className={clsx('h-9')} />
                        </div>
                        <div>
                            <CMessage info>
                                {t(
                                    'Intermediary.本物件ページは、閲覧期限が過ぎているか、ご紹介が終了しているため表示できません。物件に関するお問い合わせは紹介元にご連絡ください。',
                                )}
                            </CMessage>
                        </div>
                        <div>
                            <Trans i18nKey="Intermediary.非公開物件情報を安全かつ効率的に配信する方法や、物件管理・顧客管理・情報配信・情報管理を1システムで完結する方法にご関心がある方はお問い合わせより取得できます。">
                                <Link className={clsx('underline')} to="/inquiry">
                                    {t('Intermediary.お問い合わせ')}
                                </Link>
                            </Trans>
                        </div>
                        <div>
                            {t('Intermediary.キマールでは、無料の会員登録をお薦めしております。')}
                            <br />
                            {t('Intermediary.会員の皆さまは会員限定機能をご利用いただけます。')}
                        </div>
                        <CButton className={clsx('c-button-primary', 'w-full')} onClick={() => linkToForm()}>
                            {t('Intermediary.無料会員登録する')}
                        </CButton>
                    </div>
                )}
                <CError errors={errors} />
                {dto && !isExpired && (
                    <div className={clsx('max-w-7xl', 'w-full', 'mx-auto', 'flex', 'flex-col', 'gap-4')}>
                        <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-2')}>
                            <div>
                                <div className={clsx('text-2xl', 'text-gray-700', 'font-bold')}>
                                    {dto?.intermediaryTo?.intermediary.sourceRealEstateRevision?.name}
                                </div>
                                <div className={clsx('flex', 'gap-1', 'items-center')}>
                                    <span
                                        className={clsx(
                                            'text-xs',
                                            'text-white',
                                            'bg-gray-500',
                                            'py-0.5',
                                            'px-1',
                                            'mr-1',
                                            'whitespace-nowrap',
                                        )}>
                                        {dto?.intermediaryTo?.intermediary.sourceRealEstateRevision?.useType.name}
                                    </span>
                                    <span className={clsx('text-gray-500', 'text-xs')}>
                                        {dto?.intermediaryTo?.intermediary.sourceRealEstateRevision?.address}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={clsx(
                                    'flex-1',
                                    'flex',
                                    'items-center',
                                    'content-start',
                                    'justify-end',
                                    'gap-1',
                                    'flex-wrap',
                                )}>
                                <div
                                    className={clsx(
                                        'flex',
                                        'items-center',
                                        'border',
                                        'bg-white',
                                        'border-kimar-primary',
                                        'p-1',
                                        'gap-1',
                                        'rounded',
                                        'bg-white',
                                    )}>
                                    <div className={clsx('flex', 'items-center')}>
                                        <i className={clsx('material-icons', 'text-kimar-primary')}>mail_outline</i>
                                    </div>
                                    <div className={clsx('flex', 'flex-col', 'justify-center')}>
                                        <div className={clsx('flex', 'items-center')}>
                                            <span className={clsx('text-xs')}>
                                                {t('Intermediary.fromからto様へのご紹介です', {
                                                    from: dto?.intermediaryTo?.intermediary.sentBy?.name,
                                                    to: dto?.intermediaryTo?.destinationTo?.name,
                                                })}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <CButton
                                            className={clsx(
                                                'border',
                                                'border-red-700',
                                                'text-red-700',
                                                'rounded',
                                                'text-xs',
                                                'p-0.5',
                                                'mx-1',
                                            )}
                                            onClick={() => {
                                                setShowFinishIntermediaryModal(true)
                                            }}>
                                            {t('Intermediary.検討終了')}
                                        </CButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-4')}>
                            {/* 物件画像 */}
                            <div className={clsx('flex', 'flex-col', 'space-y-2', 'items-center')}>
                                <div className={clsx('h-[320px]', 'w-[320px]', 'flex')}>
                                    {/* トップ画像 */}
                                    <img
                                        src={realEstateImage}
                                        alt=""
                                        loading="lazy"
                                        className={clsx(
                                            'h-[320px]',
                                            'w-[320px]',
                                            'rounded',
                                            'border',
                                            'border-gray-200',
                                            'object-cover',
                                        )}
                                        crossOrigin="anonymous"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null
                                            currentTarget.src = '/src/assets/image/notfound.svg'
                                        }}
                                    />
                                </div>
                                {/* 物件地図 */}
                                <div className={clsx('h-[320px]', 'w-[320px]', 'flex')}>
                                    <img
                                        src={mapImage}
                                        alt=""
                                        loading="lazy"
                                        className={clsx(
                                            'h-[320px]',
                                            'w-[320px]',
                                            'rounded',
                                            'border',
                                            'border-gray-200',
                                            'object-cover',
                                        )}
                                        crossOrigin="anonymous"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null
                                            currentTarget.src = '/src/assets/image/notfound.svg'
                                        }}
                                    />
                                </div>
                            </div>
                            {/* 物件詳細 */}
                            <div className={clsx('max-w-full', 'flex-grow', 'bg-white', 'p-4')}>
                                <div className={clsx('max-w-md', 'mx-auto', 'mb-4')}>
                                    <CSegmentedControl
                                        items={tabs}
                                        onClick={(val) => setSelectedTab(val as string)}
                                        selectedTabValue={selectedTab}
                                    />
                                </div>
                                {selectedTab === 'detail' && (
                                    <CRealestateDetailIntermediaryInfo intermediaryTo={dto.intermediaryTo} />
                                )}
                                {selectedTab === 'file' && (
                                    <div id="file-upload">
                                        <CRealestateDetailIntermediaryFile intermediaryTo={dto.intermediaryTo} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* アンケート */}
            <CIntermediaryVote intermediaryTo={dto?.intermediaryTo} isVoteAvailable={dto?.voteAvailable} />
            {/* 検討終了 */}
            <CFooterModal
                visible={showFinishIntermediaryModal}
                onRequestClose={() => setShowFinishIntermediaryModal(false)}
                footer={
                    <>
                        <CButton className={clsx('c-button-secondary')} onClick={() => setShowFinishIntermediaryModal(false)}>
                            {t('Button.キャンセル')}
                        </CButton>
                        <CButton className={clsx('c-button-primary')} onClick={() => finishIntermediary(false)}>
                            {t('Intermediary.通知を送らず検討終了')}
                        </CButton>
                        <CButton className={clsx('c-button-primary')} onClick={() => finishIntermediary(true)}>
                            {t('Intermediary.通知を送って検討終了')}
                        </CButton>
                    </>
                }>
                <div className={clsx('p-2')}>
                    <CHeader label={t('Intermediary.検討を終了する')} />
                    <div>
                        <p>{t('Intermediary.検討終了すると本ページは閲覧できなくなります。')}</p>
                        <p>{t('Intermediary.資料の保管が必要な場合はダウンロードを事前に行ってください。')}</p>
                        <br />
                        <p>{t('Intermediary.本物件の検討を終了します。よろしいですか？')}</p>
                    </div>
                </div>
            </CFooterModal>

            <footer
                className={clsx(
                    'font-body',
                    'w-full',
                    'bg-gray-700',
                    'text-white',
                    'text-xs',
                    'py-6',
                    'px-4',
                    'sticky',
                    'text-center',
                    'top-[100vh]',
                )}>
                <CButton className={clsx('bg-gray-700', 'w-full', 'max-w-xl', 'py-1')} onClick={() => linkToForm()}>
                    <img src="/src/assets/image/banner/user_register.png" />
                </CButton>
                <ul className={clsx('flex', 'justify-center', 'flex-wrap', 'space-x-2', 'leading-loose')}>
                    <Link className={clsx('underline')} to="eura">
                        {t('SiteFooter.利用規約')}
                    </Link>
                    <Link className={clsx('underline')} to="privacy">
                        {t('SiteFooter.プライバシーポリシー')}
                    </Link>
                    <Link className={clsx('underline')} to="scta_notation">
                        {t('SiteFooter.特定商取引法に基づく表記')}
                    </Link>
                    <Link className={clsx('underline')} to="inquiry">
                        {t('SiteFooter.お問い合わせ')}
                    </Link>
                    <a href="https://limar.co.jp" target="_blank" rel="noopener noreferrer" className={clsx('underline')}>
                        {t('SiteFooter.運営会社')}
                    </a>
                </ul>
                <div className={clsx('text-center', 'mt-6', 'tracking-widest')}>COPYRIGHT &copy; LimarEstate LIMITED</div>
            </footer>
        </>
    )
}

export default RealestateDetailIntermediary
