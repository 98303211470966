import clsx from 'clsx'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { CHeader } from '~/components/common/cHeader/CHeader'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'
import CUserMypageLabel from '~/components/unique/user/CUserMypageLabel'
import { pageTitleTemplate } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser } from '~/util/store/userSlice'

import CUserMypageProfile from '../../components/unique/user/CUserMypageProfile'
import CUserMypageTeam from '../../components/unique/user/CUserMypageTeam'
import CUserMypageTeamTemplate from '../../components/unique/user/CUserMypageTeamTemplate'

const Mypage = () => {
    const user = useAppSelector(selectLoginUser)
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const param = {
        type: query.get('type') || 'profile',
        content: query.get('content') || '',
    }

    const [selectedTab, setSelectedTab] = useState(param.type)

    const tabs = useMemo(
        () => [
            { label: t('Mypage.プロフィール'), value: 'profile' },
            { label: t('Mypage.会社'), value: 'team' },
            { label: t('Mypage.メール'), value: 'mail_template' },
            { label: t('Mypage.ラベル'), value: 'label' },
        ],
        [],
    )

    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Mypage.マイページ')}</title>
            </Helmet>
            <CHeader label={t('Mypage.マイページ')} />
            <div
                className={clsx(
                    'w-full',
                    'bg-white',
                    'border',
                    'border-gray-200',
                    'rounded',
                    'p-4',
                    'md:p-8',
                    'flex',
                    'flex-col',
                    'items-center',
                    'gap-4',
                )}>
                {user?.roles.team !== 0 && (
                    <div className={clsx('w-full', 'md:w-3/4')}>
                        <CSegmentedControl
                            items={tabs}
                            onClick={(val) => setSelectedTab(val as string)}
                            selectedTabValue={selectedTab}
                        />
                    </div>
                )}
                <div className={clsx('w-full', 'flex', 'flex-col', 'space-y-8', 'items-center')}>
                    {selectedTab === 'profile' && <CUserMypageProfile content={param.content} />}
                    {selectedTab === 'team' && user?.roles.team !== 0 && <CUserMypageTeam />}
                    {selectedTab === 'mail_template' && user?.roles.team !== 0 && <CUserMypageTeamTemplate />}
                    {selectedTab === 'label' && user?.roles.team !== 0 && <CUserMypageLabel />}
                </div>
            </div>
        </>
    )
}

export default Mypage
