import 'rc-tree/assets/index.css'

import { DraggableAttributes } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import clsx from 'clsx'
import { t } from 'i18next'
import Tree, { TreeNode } from 'rc-tree'
import { Key } from 'rc-tree/lib/interface'
import { useEffect, useMemo, useState, VFC } from 'react'

import {
    TeamEntities,
    TeamMemberEntities,
    useClientTeamMemberPutTeamMemberOrderMutation,
    useClientTeamMemberTeamUuidQuery,
} from '~/app/api'
import { CDragRowTable } from '~/components/common/cDragRowTable/CDragRowTable'
import { companyRoles, customerRoles, intermediaryRoles, realestateRoles, teamRoles } from '~/types/enum/enum'
import { createTeamTreeWithChildren, TeamTreeNode } from '~/util/model/TeamEntities'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser } from '~/util/store/userSlice'

import { CButton } from '../../common/cButton/CButton'
import { UpdateTeamMemberClientModal } from '../team/UpdateTeamMemberModal'

export type CUserMyPageTeamMemberProps = {
    team: TeamEntities
}

export const CUserMyPageTeamMember: VFC<CUserMyPageTeamMemberProps> = ({ ...props }) => {
    const user = useAppSelector(selectLoginUser)
    const [team, setTeam] = useState<TeamEntities>()
    const [teamMemberUpdateTarget, setTeamMemberUpdateTarget] = useState<TeamMemberEntities>()
    const { data: teamMemberListCount, refetch } = useClientTeamMemberTeamUuidQuery(
        { teamUuid: team?.uuid ?? '' },
        { skip: !team },
    )

    useEffect(() => {
        setTeam(props.team)
    }, [props.team])

    /** Inner Component */
    const teamTreeComponent = () => {
        const rootTeam = props.team
        if (!rootTeam) return <></>

        /** Event */
        const onSelectNode = (
            selectedKeys: Key[],
            info: {
                event: 'select'
                selected: boolean
                nativeEvent: MouseEvent
            },
        ) => {
            if (!info.selected) {
                info.nativeEvent.preventDefault()
                return
            }
            if (selectedKeys.length != 1) return

            const getTeam = (teams: TeamEntities[], uuid: string): TeamEntities | undefined => {
                let target = teams.find((t) => t.uuid === uuid)
                if (target) return target
                for (let i = 0; i < teams.length; i++) {
                    target = getTeam(teams[i].childTeams ?? [], uuid)
                    if (target) return target
                }

                return undefined
            }

            setTeam(getTeam([props.team], selectedKeys[0] as string))
        }

        /** Inner Component */
        const treeNode = (teamTreeNode: TeamTreeNode) => {
            return (
                <TreeNode title={teamTreeNode.model.name} key={teamTreeNode.model.uuid}>
                    {teamTreeNode.children.map((t) => treeNode(t))}
                </TreeNode>
            )
        }

        return (
            <>
                <Tree defaultExpandAll showIcon={false} selectedKeys={team ? [team.uuid] : undefined} onSelect={onSelectNode}>
                    {treeNode(createTeamTreeWithChildren(props.team))}
                </Tree>
            </>
        )
    }

    const teamMemberDetail = () => {
        if (!team) return

        return (
            <>
                <div className={clsx()}>
                    {(teamMemberListCount?.list?.length ?? 0) > 0 && (
                        <CDragRowTable
                            data={teamMemberData}
                            onChange={(data) => {
                                setOrder(data.tbody.map((card) => card.id))
                            }}
                            fixed
                        />
                    )}
                </div>
            </>
        )
    }

    /** ソート */
    const [setTeamMemberOrderQuery] = useClientTeamMemberPutTeamMemberOrderMutation()
    const setOrder = async (teamMemberUuids: string[]) => {
        await setTeamMemberOrderQuery({
            teamUuid: props.team.uuid,
            clientTeamMemberOrderDto: { teamMemberUuids: teamMemberUuids },
        })
        refetch()
    }

    const teamMemberData = {
        id: 'teamMembers',
        thead: (
            <tr className={clsx('bg-kimar-primary', 'text-white')}>
                <th className={clsx('pl-2', 'w-[24px]')} />
                <th className={clsx('px-2')}>{t('CUserMypageTeamMember.氏名')}</th>
                <th className={clsx('px-2', 'w-20')}>{t('CUserMypageTeamMember.役職')}</th>
                <th className={clsx('px-2', 'hidden', 'lg:table-cell')}>{t('CUserMypageTeamMember.メールアドレス')}</th>
                <th className={clsx('px-2', 'w-12', 'hidden', 'lg:table-cell')}>
                    {t('CUserMypageTeamMember.会社')}
                    <br />
                    {t('CUserMypageTeamMember.権限')}
                </th>
                <th className={clsx('px-2', 'w-12', 'hidden', 'lg:table-cell')}>
                    {t('CUserMypageTeamMember.チーム')}
                    <br />
                    {t('CUserMypageTeamMember.権限')}
                </th>
                <th className={clsx('px-2', 'w-12', 'hidden', 'lg:table-cell')}>
                    {t('CUserMypageTeamMember.物件')}
                    <br />
                    {t('CUserMypageTeamMember.権限')}
                </th>
                <th className={clsx('px-2', 'w-12', 'hidden', 'lg:table-cell')}>
                    {t('CUserMypageTeamMember.顧客')}
                    <br />
                    {t('CUserMypageTeamMember.権限')}
                </th>
                <th className={clsx('px-2', 'w-12', 'hidden', 'lg:table-cell')}>
                    {t('CUserMypageTeamMember.紹介')}
                    <br />
                    {t('CUserMypageTeamMember.権限')}
                </th>
                {user?.roles.team === 2 && (
                    <>
                        <th className={clsx('px-2', 'w-16')}>{t('CUserMypageTeamMember.操作')}</th>
                    </>
                )}
            </tr>
        ),
        tbody: ((teamMemberListCount?.list as TeamMemberEntities[] | undefined) ?? [])?.map((teamMember) => ({
            id: teamMember.uuid,
            tr: (attributes: DraggableAttributes, listeners: SyntheticListenerMap | undefined) => (
                <>
                    <td {...attributes} {...listeners} className={clsx('cursor-grab')}>
                        <div className={clsx('flex', 'justify-center', 'items-center')}>
                            <i className={clsx('material-icons', 'text-gray-300', 'w-5')}>drag_indicator</i>
                        </div>
                    </td>
                    <td className={clsx('break-all', 'max-w-[96px]', 'text-sm')}>{teamMember.name}</td>
                    <td className={clsx('text-sm')}>{teamMember.position}</td>
                    <td className={clsx('hidden', 'lg:table-cell', 'text-sm', 'break-all')}>{teamMember.email}</td>
                    <td className={clsx('hidden', 'lg:table-cell', 'text-xs')}>
                        {companyRoles.find((role) => role.value === teamMember.roles.company)?.label}
                    </td>
                    <td className={clsx('hidden', 'lg:table-cell', 'text-xs')}>
                        {teamRoles.find((role) => role.value === teamMember.roles.team)?.label}
                    </td>
                    <td className={clsx('hidden', 'lg:table-cell', 'text-xs')}>
                        {realestateRoles.find((role) => role.value === teamMember.roles.realestate)?.label}
                    </td>
                    <td className={clsx('hidden', 'lg:table-cell', 'text-xs')}>
                        {customerRoles.find((role) => role.value === teamMember.roles.customer)?.label}
                    </td>
                    <td className={clsx('hidden', 'lg:table-cell', 'text-xs')}>
                        {intermediaryRoles.find((role) => role.value === teamMember.roles.intermediary)?.label}
                    </td>
                    {user?.roles.team === 2 && (
                        <>
                            <td className={clsx('text-center')}>
                                <CButton
                                    className={clsx('c-button-primary', 'text-sm')}
                                    onClick={() => setTeamMemberUpdateTarget(teamMember)}>
                                    {t('Button.編集')}
                                </CButton>
                            </td>
                        </>
                    )}
                </>
            ),
        })),
    }

    const teamMemberUpdateComponent = useMemo(() => {
        if (!teamMemberUpdateTarget) return <></>

        const closeUpdateTeamMemberModal = () => {
            setTeamMemberUpdateTarget(undefined)
        }

        const submitUpdateTeamMember = () => {
            setTeamMemberUpdateTarget(undefined)
        }
        return (
            <UpdateTeamMemberClientModal
                teamMember={teamMemberUpdateTarget}
                isShow={!!teamMemberUpdateTarget}
                onSubmit={submitUpdateTeamMember}
                onClose={closeUpdateTeamMemberModal}
            />
        )
    }, [team, teamMemberUpdateTarget])

    return (
        <>
            {!!props.team && (
                <>
                    {props.team.childTeams?.length && (
                        <div className={clsx('max-w-md', 'm-2')}>
                            <div className={clsx('text-white', 'bg-kimar-primary', 'text-sm', 'p-1')}>
                                {t('CUserMypageTeamMember.チーム選択')}
                            </div>
                            <div className={clsx('border-x', 'border-b', 'rounded-b', 'px-2')}>{teamTreeComponent()}</div>
                        </div>
                    )}
                    {teamMemberDetail()}
                </>
            )}
            {teamMemberUpdateComponent}
        </>
    )
}
