import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { FC, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast/headless'

import {
    ClientRealEstateLabelUpdateDto,
    RealEstateEntities,
    RealEstateLabelEntities,
    useClientRealEstatePutUuidLabelsMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CDragAndDropItemSet } from '~/components/common/cDragAndDropItemSet/CDragAndDropItemSet'
import { CFooterModalV2 } from '~/components/common/cModal/CModalV2'
import { CTag } from '~/components/common/cTag/CTag'
import { ApplicationError, IApplicationError } from '~/types/error'

export type CRealEstateDetailLabelEditViewProps = {
    form: ClientRealEstateLabelUpdateDto
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (key: string, value: unknown) => void
    errors: IApplicationError[]
} & CRealEstateDetailLabelEditProps

export const CRealEstateDetailLabelEditView: FC<CRealEstateDetailLabelEditViewProps> = ({
    visible,
    form,
    onRequestClose,
    onRequestConfirm,
    setProps,
    realEstate,
    teamRealEstateLabels: teamRealEstateLabels,
}) => {
    const footer = (
        <>
            <CButton className={clsx('c-button-secondary')} onClick={() => onRequestClose()}>
                {t('Button.キャンセル')}
            </CButton>
            <CButton
                className={clsx('c-button-primary')}
                onClick={() => {
                    onRequestConfirm()
                }}>
                {t('CRealEstateDetailLabelEdit.ラベル確定')}
            </CButton>
        </>
    )

    const columns = useMemo(
        () => [
            {
                id: t('CRealEstateDetailLabelEdit.すべての物件ラベル'),
                title: t('CRealEstateDetailLabelEdit.すべての物件ラベル'),
                cards: teamRealEstateLabels
                    .filter((l) => !form.labelUuids.includes(l.uuid))
                    .sort((a, b) => a.sort - b.sort)
                    .map((tag) => {
                        return {
                            id: tag.uuid,
                            sort: tag.sort,
                            element: (
                                <CTag
                                    className={clsx('px-2', 'rounded', 'text-sm', tag.bgColor === '#ffffff' && 'border')}
                                    style={{
                                        backgroundColor: tag.bgColor,
                                        color: tag.letterColor,
                                    }}>
                                    {tag.name}
                                </CTag>
                            ),
                        }
                    }),
            },
            {
                id: t('CRealEstateDetailLabelEdit.この物件に設定するラベル'),
                title: t('CRealEstateDetailLabelEdit.この物件に設定するラベル'),
                cards: teamRealEstateLabels
                    .filter((l) => form.labelUuids.includes(l.uuid))
                    .sort((a, b) => a.sort - b.sort)
                    .map((tag) => {
                        return {
                            id: tag.uuid,
                            sort: tag.sort,
                            element: (
                                <CTag
                                    className={clsx('px-2', 'rounded', 'text-sm', tag.bgColor === '#ffffff' && 'border')}
                                    style={{
                                        backgroundColor: tag.bgColor,
                                        color: tag.letterColor,
                                    }}>
                                    {tag.name}
                                </CTag>
                            ),
                        }
                    }),
            },
        ],
        [form],
    )

    return (
        <CFooterModalV2
            footer={footer}
            visible={visible}
            content={{ width: 600, height: '50%' }}
            onRequestClose={() => onRequestClose()}>
            <div className={clsx('flex', 'flex-col', 'overflow-hidden', 'h-full')}>
                <div className={clsx('p-2', 'w-full', 'flex', 'justify-center')}>
                    <div className={clsx('text-kimar-primary', 'text-lg', 'font-bold')}>
                        {t('CRealEstateDetailLabelEdit.物件「name」のラベル編集', { name: realEstate?.name })}
                    </div>
                </div>
                <div className={clsx('p-4', 'flex-1', 'overflow-hidden', 'flex')}>
                    <CDragAndDropItemSet
                        data={columns}
                        autoSorting
                        onChange={(data) => {
                            setProps(
                                'labelUuids',
                                data[1].cards.map((c) => c.id),
                            )
                        }}
                    />
                </div>
            </div>
        </CFooterModalV2>
    )
}

export type CRealEstateDetailLabelEditProps = {
    visible: boolean
    realEstate?: RealEstateEntities
    teamRealEstateLabels: RealEstateLabelEntities[]
    onClose: () => void
    onSubmit: (realEstate: RealEstateEntities) => void
}

export const CRealEstateDetailLabelEdit: FC<CRealEstateDetailLabelEditProps> = ({ ...props }) => {
    const [visible, setVisible] = useState(props.visible)
    const [form, setForm] = useState<ClientRealEstateLabelUpdateDto>()
    useEffect(() => {
        setErrors([])
        setForm({
            labelUuids: props.realEstate?.labels?.map((l) => l.uuid) ?? [],
        })
        setVisible(props.visible)
    }, [props.visible])

    const setProps = (key: string, value: unknown) => {
        if (!form) return
        setForm({
            ...form,
            [key]: value,
        })
    }

    const onRequestClose = () => {
        props.onClose()
    }

    const [updateRealEstateLabelQuery] = useClientRealEstatePutUuidLabelsMutation()
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const onRequestConfirm = async () => {
        if (!form || !props.realEstate) return
        try {
            setErrors([])
            const customer = await updateRealEstateLabelQuery({
                uuid: props.realEstate.uuid,
                clientRealEstateLabelUpdateDto: form,
            }).unwrap()
            props.onSubmit(customer)
            toast.success(t('CRealEstateDetailLabelEdit.物件のラベルを編集しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    if (!form) return null
    return (
        <CRealEstateDetailLabelEditView
            {...props}
            visible={visible}
            form={form}
            onRequestConfirm={onRequestConfirm}
            onRequestClose={onRequestClose}
            setProps={setProps}
            errors={errors}
        />
    )
}
