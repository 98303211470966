import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { useNavigate, useParams } from 'react-router-dom'

import { AdminFaqUpdateDto, useAdminFaqDeleteUuidMutation, useAdminFaqPutUuidMutation, useAdminFaqUuidQuery } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CModal } from '~/components/common/cModal/CModal'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { CFaqListItem } from '~/components/unique/faq/CFaqListItem'
import { ApplicationError, IApplicationError } from '~/types/error'
import { pageTitleTemplate } from '~/util/common/common'

const AdminFaqContentDetail = () => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const navigate = useNavigate()
    const params = useParams()
    const { faqContentUuid } = params
    // FAQ初期状態
    const emptyFaq: AdminFaqUpdateDto = {
        uuid: '',
        question: '',
        answer: '',
        isDisplayHome: false,
        sort: 0,
    }
    const [faq, setFaq] = useState<AdminFaqUpdateDto>(emptyFaq)
    // 編集API
    const [putAPI] = useAdminFaqPutUuidMutation()
    // 編集成功時に立つフラグ
    const [successFlag, setSuccessFlag] = useState(false)
    // 削除確認モーダルvisible
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
    // 削除API
    const [deleteAPI] = useAdminFaqDeleteUuidMutation()

    // よくある質問の内容のGET
    if (!faqContentUuid) return <CMessage>FAQを特定する情報が見つかりませんでした</CMessage>
    const { data: originalFaq, isLoading: isLoadingOriginalFaq } = useAdminFaqUuidQuery(
        { uuid: faqContentUuid },
        { skip: visibleDeleteModal },
    )
    useMemo(() => {
        if (originalFaq) {
            const faqUpdateDto: AdminFaqUpdateDto = {
                uuid: originalFaq.uuid,
                question: originalFaq.question,
                answer: originalFaq.answer,
                isDisplayHome: originalFaq.isDisplayHome,
                sort: originalFaq.sort,
            }
            setFaq(faqUpdateDto)
        }
    }, [originalFaq])

    const changeHandler = (value: string | boolean | number, target: keyof AdminFaqUpdateDto) => {
        setFaq((old) => ({ ...old, [target]: value }))
    }

    const confirmButtonHandler = async () => {
        try {
            if (!faq) throw new ApplicationError('FAQ情報の取得に失敗しました')
            await putAPI({ uuid: faq.uuid, adminFaqUpdateDto: faq }).unwrap()
            setSuccessFlag(true)
            toast.success('よくある質問を編集しました')
            setTimeout(() => {
                setSuccessFlag(false)
            }, 3000)
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    // 削除実行
    const deleteFaq = async () => {
        try {
            await deleteAPI({ uuid: faq.uuid }).unwrap()
            toast.success('よくある質問を削除しました')
            // FAQ一覧に遷移
            navigate('/admin/faq')
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleDeleteModal(false)
        }
    }

    return (
        <>
            <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3')}>
                <Helmet titleTemplate={pageTitleTemplate(true)}>
                    <title>よくある質問詳細</title>
                </Helmet>
                <CError errors={errors} />
                {successFlag && <CMessage info>保存に成功しました。</CMessage>}
                <CHeader label="よくある質問詳細" />
                <div>
                    <div className={clsx('flex', 'justify-end', 'my-2')}>
                        <CButton className={clsx('c-button-danger')} onClick={() => setVisibleDeleteModal(true)}>
                            質問を削除
                        </CButton>
                    </div>
                    {isLoadingOriginalFaq ? (
                        <CMessage info>読み込み中です...</CMessage>
                    ) : (
                        <div className={clsx('w-full', 'p-2', 'flex', 'gap-x-2', 'bg-white')}>
                            <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-4', 'overflow-auto')}>
                                <CHeader label="内容詳細" bgClass="bg-kimar-accent" />
                                <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                                    <tbody>
                                        <tr className={clsx('border')}>
                                            <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                                質問
                                            </th>
                                            <td className={clsx('p-1')}>
                                                <CTextInput
                                                    type={'text'}
                                                    placeholder={'入力してください'}
                                                    text={faq?.question}
                                                    className={clsx('w-full', 'c-text-input-base')}
                                                    onChange={(value) => changeHandler(value, 'question')}
                                                />
                                            </td>
                                        </tr>
                                        <tr className={clsx('border')}>
                                            <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                                回答
                                            </th>
                                            <td className={clsx('p-1')}>
                                                <CTextInput
                                                    type={'multiline'}
                                                    placeholder={'入力してください'}
                                                    text={faq?.answer}
                                                    className={clsx('w-full', 'c-text-input-base')}
                                                    onChange={(value) => changeHandler(value, 'answer')}
                                                />
                                            </td>
                                        </tr>
                                        <tr className={clsx('border')}>
                                            <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                                LPに表示する
                                            </th>
                                            <td className={clsx('p-1')}>
                                                <div className={clsx('w-fit')}>
                                                    <CToggleSwitch
                                                        width="lg"
                                                        value={faq?.isDisplayHome}
                                                        trueLabel={'はい'}
                                                        falseLabel={'いいえ'}
                                                        toggleChanged={(value) => changeHandler(value, 'isDisplayHome')}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className={clsx('border')}>
                                            <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                                LPでの表示順
                                            </th>
                                            <td className={clsx('p-1')}>
                                                <CTextInput
                                                    type={'number'}
                                                    className={clsx('w-full', 'c-text-input-base')}
                                                    text={faq?.sort.toString()}
                                                    onChange={(value) => changeHandler(value, 'sort')}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className={clsx('w-full', 'p-4', 'flex', 'justify-center')}>
                                    <CButton className={clsx('c-button-primary')} onClick={confirmButtonHandler}>
                                        保存
                                    </CButton>
                                </div>
                            </div>
                            <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-4')}>
                                <CHeader label="プレビュー" bgClass="bg-kimar-accent" />
                                <div>
                                    <CFaqListItem faq={faq} defaultOpen />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* 削除確認モーダル */}
            <CModal visible={visibleDeleteModal} onRequestClose={() => setVisibleDeleteModal(false)}>
                <div className={clsx('p-2')}>
                    <div>よくある質問を削除します。よろしいですか？</div>
                    <div className={clsx('flex', 'm-4', 'justify-center')}>
                        <CButton className={clsx('c-button-secondary')} onClick={() => setVisibleDeleteModal(false)}>
                            キャンセル
                        </CButton>
                        <CButton className={clsx('c-button-danger')} onClick={deleteFaq}>
                            削除
                        </CButton>
                    </div>
                </div>
            </CModal>
        </>
    )
}

export default AdminFaqContentDetail
