import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { isArray, sortBy, uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { animateScroll as scroll } from 'react-scroll'

import {
    CustomerEntities,
    CustomerLabelEntities,
    TeamMemberEntities,
    useClientCustomerCustomerQuery,
    useClientCustomerLabelTeamUuidQuery,
    useClientCustomerPutMultipleMutation,
    useClientTeamMemberTeamUuidQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CError } from '~/components/common/cError/CError'
import { CGuideTips } from '~/components/common/cGuideTips/CGuideTips'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CPager } from '~/components/common/cPager/CPager'
import { CSortButton } from '~/components/common/cSortButton/CSortButton'
import CTable from '~/components/common/cTable/CTable'
import { CTag } from '~/components/common/cTag/CTag'
import { CTagList } from '~/components/common/cTagList/CTagList'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { CCustomerInfoCsvEdit } from '~/components/unique/customer/CCustomerInfoCsvEdit'
import { CCustomerDetail } from '~/components/unique/customer/detail/CCustomerDetail'
import { CCustomerEdit } from '~/components/unique/customer/detail/CCustomerEdit'
import { ApplicationError, IApplicationError } from '~/types/error'
import { pageTitleTemplate } from '~/util/common/common'
import { customerRegisteredTypeFormat } from '~/util/filter/format'
import { selectCredential } from '~/util/store/authSlice'
import { useAppSelector } from '~/util/store/hooks'
import { selectIsPlanStandardOrOver, selectLoginUser, selectLoginUserTeam } from '~/util/store/userSlice'

import {
    CCustomerSearch,
    CustomerSearchFilterArrayType,
    CustomerSearchFormValue,
} from '../../components/unique/customer/CCustomerSearch'

const Customer = () => {
    const user = useAppSelector(selectLoginUser)
    const userTeam = useAppSelector(selectLoginUserTeam)
    const cred = useAppSelector(selectCredential)

    // 検索フォーム
    const [form, setForm] = useState<{
        name: string
        order: 'name' | 'company'
        filter: CustomerSearchFilterArrayType
        sort: 'ASC' | 'DESC'
        useTypes: string[]
        prices: string[]
        areas: string[]
        buildingAges: string[]
        registeredTypes: string[]
        saleTimes: string[]
        assignedToType: 'AND' | 'OR'
        assignedToUuidList: string[]
        labelType: 'AND' | 'OR'
        labelUuidList: string[]
        customerUuidList: string[]
    }>({
        name: '',
        order: 'name',
        filter: [],
        sort: 'ASC',
        useTypes: [],
        prices: [],
        areas: [],
        buildingAges: [],
        registeredTypes: [],
        saleTimes: [],
        assignedToType: 'OR',
        assignedToUuidList: [],
        labelType: 'OR',
        labelUuidList: [],
        customerUuidList: [],
    })

    const [page, setPage] = useState(1)
    const maxCount = 100

    const [isShowInsertCustomer, setIsShowInsertCustomer] = useState(false)
    isShowInsertCustomer
    const [selectedCustomer, setSelectedCustomer] = useState<CustomerEntities>()
    // 最後に選択した顧客(範囲選択の起点用)
    const [lastSelectedCustomer, setLastSelectedCustomer] = useState<CustomerEntities>()
    // スタンダード以上
    const isPlanStandardOrOver = useAppSelector(selectIsPlanStandardOrOver)

    const {
        data: customerList,
        refetch: refetchCustomer,
        isSuccess,
        isLoading: isLoadingCustomerList,
    } = useClientCustomerCustomerQuery({
        page,
        maxCount,
        ...form,
    })

    const { data: teamMemberListCount } = useClientTeamMemberTeamUuidQuery(
        { teamUuid: userTeam?.uuid ?? '' },
        { skip: !userTeam },
    )
    const { data: labelListCount } = useClientCustomerLabelTeamUuidQuery({ uuid: userTeam?.uuid ?? '' }, { skip: !userTeam })

    useEffect(() => {
        if (isSuccess && selectedCustomer) {
            setSelectedCustomer(
                (customerList?.list as CustomerEntities[] | undefined)?.find((c) => c.uuid === selectedCustomer.uuid),
            )
        }
    }, [customerList, isSuccess])

    const onChangeSort = (order: 'name' | 'company', sort: 'ASC' | 'DESC') => {
        setPage(1)
        setForm({
            ...form,
            order,
            sort,
        })
    }

    const onChangeSearchVal = (val: CustomerSearchFormValue) => {
        setPage(1)
        setForm({
            ...form,
            ...val,
        })
    }

    const onPageChanged = (_page: number) => {
        setPage(_page)
    }

    useEffect(() => {
        scroll.scrollToTop()
    }, [page])

    const [selectedMultipleCustomer, setSelectedMultipleCustomer] = useState<CustomerEntities[]>([])
    const [selectedMultipleTeamMember, setSelectedMultipleTeamMember] = useState<TeamMemberEntities[]>([])
    const [selectedMultipleCustomerLabel, setSelectedMultipleCustomerLabel] = useState<CustomerLabelEntities[]>([])
    const [multipleEdit, setMultipleEdit] = useState(false)
    useEffect(() => {
        if (multipleEdit) setSelectedCustomer(undefined)
        else setSelectedMultipleCustomer([])
    }, [multipleEdit])

    // 一括編集モード下での顧客選択ハンドラ
    const onClickCustomerInMultipleEdit = (
        customers: CustomerEntities[],
        selectedCustomer: CustomerEntities,
        clickEvent: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    ) => {
        if (clickEvent.shiftKey && lastSelectedCustomer) {
            // shiftキー押下しながら顧客選択した場合は範囲選択
            const startIndex = customers.findIndex((it) => lastSelectedCustomer.uuid === it.uuid)
            const lastIndex = customers.findIndex((it) => selectedCustomer.uuid === it.uuid)
            // リストで、最後に選択した顧客〜今選択した顧客の間に入っている顧客
            let filteredCustomers = customers.filter((_, index) => {
                return (startIndex <= index && index <= lastIndex) || (lastIndex <= index && index <= startIndex)
            })
            if (startIndex > lastIndex) filteredCustomers.reverse()

            // 間の顧客群の中で、すでに選択済みの顧客は再度入れないので取り除いておく
            filteredCustomers = filteredCustomers.filter((c) => !selectedMultipleCustomer.includes(c))
            setSelectedMultipleCustomer([...selectedMultipleCustomer, ...filteredCustomers])
        } else {
            if (selectedMultipleCustomer.includes(selectedCustomer))
                setSelectedMultipleCustomer(selectedMultipleCustomer.filter((c) => c.uuid != selectedCustomer.uuid))
            else setSelectedMultipleCustomer([...selectedMultipleCustomer, selectedCustomer])
        }
        // 最終選択顧客の更新
        setLastSelectedCustomer(selectedCustomer)
    }

    const [sendMultipleQuery] = useClientCustomerPutMultipleMutation()
    const sendMultipleApi = async (
        command: 'assign' | 'unassign' | 'label' | 'unlabelled' | 'request' | 'delete',
        value: typeof selectedMultipleTeamMember | typeof selectedMultipleCustomerLabel | boolean,
    ) => {
        if (isArray(value) && value.length === 0) return
        const body = {
            customer: selectedMultipleCustomer,
            [command]: value,
        }
        await sendMultipleQuery({
            clientCustomerMultipleDto: body,
        })

        setSelectedMultipleCustomer([])
        setSelectedMultipleCustomerLabel([])
        setSelectedMultipleTeamMember([])
        toast.success(t('Customer.顧客を一括編集しました'))
    }

    const [deleteMultipleCustomer, setDeleteMultipleCustomer] = useState(false)
    const closeMultipleDeleteModal = async () => {
        setDeleteMultipleCustomer(false)
    }
    const confirmMultipleDeleteModal = async () => {
        await sendMultipleApi('delete', true)
        setDeleteMultipleCustomer(false)
        toast.success(t('Customer.選択した顧客を削除しました'))
    }
    const [requestMultipleCustomer, setRequestMultipleCustomer] = useState(false)
    const closeRequestMultipleCustomerModal = async () => {
        setRequestMultipleCustomer(false)
    }
    const confirmRequestMultipleCustomerModal = async () => {
        await sendMultipleApi('request', true)
        setRequestMultipleCustomer(false)
        toast.success(t('Customer.購入条件リクエストを送信しました'))
    }

    const getInvestmentConditionStatus = (customer: CustomerEntities) => {
        if (customer.investmentConditionRequests && customer.investmentConditionRequests.some((c) => c.answeredAt !== null))
            return { text: t('Customer.回答済'), bgClass: 'bg-kimar-primary', textClass: 'text-white' }
        else if (customer.investmentConditionRequests && customer.investmentConditionRequests.some((c) => c.answeredAt === null))
            return { text: t('Customer.リクエスト済'), bgClass: 'bg-gray-200', textClass: 'text-gray-700' }
        else return { text: t('Customer.未リクエスト'), bgClass: 'bg-red-700', textClass: 'text-white' }
    }

    const managementRefetch = () => {
        refetchCustomer()
    }

    const [showCsvImport, setShowCsvImport] = useState(false)
    useEffect(() => {
        if (!showCsvImport) refetchCustomer()
    }, [showCsvImport])

    const [exportErrors, setExportErrors] = useState<Array<IApplicationError>>([])
    const [showCsvExport, setShowCsvExport] = useState(false)
    const csvExport = async () => {
        setExportErrors([])
        try {
            const header = {
                headers: {
                    'X-Authorization': `Bearer ${cred}`,
                },
            }
            const response = await fetch(`${process.env.BASE_URL}/api/client/customer/export`, header)
            const blob = await response.blob()
            if (!response.ok || !blob) throw new ApplicationError(response.statusText, response.status)
            const bom = new Uint8Array([0xef, 0xbb, 0xbf])
            const blobWithBOM = new Blob([bom, blob], { type: 'text/csv;charset=utf-8' })

            const streamSaver = (await import('streamsaver')).default
            const fileStream = streamSaver.createWriteStream(`顧客リスト_${dayjs().format('YYYY-MM-DD_HH:mm:ss')}.csv`)
            await blobWithBOM?.stream().pipeTo(fileStream)
            toast.success(t('Customer.顧客リストを出力しました'))
            setShowCsvExport(false)
        } catch (e) {
            if (e instanceof ApplicationError) setExportErrors([e])
            else setExportErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    if (isLoadingCustomerList) return <CMessage info>{t('Message.読み込み中です…')}</CMessage>
    if (!customerList) return <></>
    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Customer.顧客')}</title>
            </Helmet>
            <div className={clsx('sticky', 'top-16', 'w-full', 'z-10')}>
                <CCustomerSearch onChange={onChangeSearchVal} />
            </div>
            {user?.roles.customer === 2 && (
                <div
                    className={clsx(
                        'flex',
                        multipleEdit ? 'justify-between' : 'justify-end',
                        'flex-col',
                        'md:flex-row',
                        'gap-2',
                    )}>
                    {multipleEdit ? (
                        <div className={clsx('py-2', 'text-gray-500')}>
                            {t('Customer.編集したい顧客を左のリストから選択し、右のリストから操作を選択してください')}
                        </div>
                    ) : (
                        <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-1')}>
                            <CButton
                                className={clsx('c-button-primary', 'text-sm')}
                                onClick={() => setIsShowInsertCustomer(true)}>
                                <i className={clsx('material-icons')}>add</i>
                                <div>{t('Button.新規追加')}</div>
                            </CButton>
                            <CButton
                                className={clsx('hidden', 'md:flex', 'c-button-secondary', 'text-sm')}
                                onClick={() => setShowCsvImport(true)}>
                                <i className={clsx('material-symbols-outlined')}>Download</i>
                                <div>{t('Customer.CSV一括登録')}</div>
                            </CButton>
                            {isPlanStandardOrOver && (
                                <CButton
                                    className={clsx('hidden', 'md:flex', 'c-button-secondary', 'text-sm')}
                                    onClick={() => setShowCsvExport(true)}>
                                    <i className={clsx('material-symbols-outlined')}>Upload</i>
                                    <div>{t('Customer.CSV一括出力')}</div>
                                </CButton>
                            )}
                        </div>
                    )}
                    <div className={clsx('hidden', 'md:flex', 'items-center', 'gap-1')}>
                        <CLabeledItem label={t('Customer.一括編集モード')} horizontal>
                            <CToggleSwitch
                                value={multipleEdit}
                                trueLabel={t('Customer.ON')}
                                falseLabel={t('Customer.OFF')}
                                toggleChanged={(value) => {
                                    setMultipleEdit(value)
                                    setLastSelectedCustomer(undefined)
                                }}
                            />
                        </CLabeledItem>
                        <CGuideTips tooltipDirection="left">
                            {t('Customer.複数の顧客に対して顧客担当者や顧客ラベルの追加・解除ができます')}
                        </CGuideTips>
                    </div>
                </div>
            )}
            {multipleEdit && user?.roles.customer === 2 ? (
                <div className={clsx('flex', 'gap-2')}>
                    <div className={clsx('w-full')}>
                        <div className={clsx('flex', 'justify-between')}>
                            <CLabeledItem label={t('Customer.並び順')} horizontal className={clsx('mx-2')}>
                                <CSortButton
                                    className={clsx('border-gray-300')}
                                    items={[
                                        {
                                            label: t('Customer.名前'),
                                            value: 'name',
                                        },
                                        {
                                            label: t('Customer.会社名'),
                                            value: 'company',
                                        },
                                    ]}
                                    nowValue={form.order}
                                    sort={form.sort}
                                    onChange={(val) => onChangeSort(val as 'name' | 'company', form.sort)}
                                    onClickSort={() => onChangeSort(form.order, form.sort == 'ASC' ? 'DESC' : 'ASC')}
                                />
                            </CLabeledItem>
                            <CPager page={page} pageSize={maxCount} total={customerList.count} onPageChanged={onPageChanged} />
                        </div>
                        <div className={clsx('w-full')}>
                            {customerList.list.length === 0 && (
                                <CMessage info>{t('Customer.顧客が見つかりませんでした')}</CMessage>
                            )}
                            {customerList.list.length > 0 && (
                                <CTable
                                    thead={
                                        <>
                                            <tr>
                                                <th>
                                                    <div className={clsx('flex')}>
                                                        <CCheckBox
                                                            className={clsx('rounded', 'border', 'border-white')}
                                                            checked={
                                                                !!selectedMultipleCustomer.length &&
                                                                (customerList.list as CustomerEntities[]).every((c) =>
                                                                    selectedMultipleCustomer.includes(c),
                                                                )
                                                            }
                                                            onChange={() => {
                                                                !!selectedMultipleCustomer.length &&
                                                                (customerList.list as CustomerEntities[]).every((c) =>
                                                                    selectedMultipleCustomer.includes(c),
                                                                )
                                                                    ? setSelectedMultipleCustomer(
                                                                          selectedMultipleCustomer.filter(
                                                                              (c) => !customerList.list.includes(c),
                                                                          ),
                                                                      )
                                                                    : setSelectedMultipleCustomer(
                                                                          uniqBy(
                                                                              [
                                                                                  ...selectedMultipleCustomer,
                                                                                  ...(customerList.list as CustomerEntities[]),
                                                                              ],
                                                                              'uuid',
                                                                          ),
                                                                      )
                                                            }}
                                                        />
                                                    </div>
                                                </th>
                                                <th>{t('Customer.顧客ラベル')}</th>
                                                <th>{t('Customer.会社名')}</th>
                                                <th>{t('Customer.氏名')}</th>
                                                <th>{t('Customer.顧客担当者')}</th>
                                            </tr>
                                        </>
                                    }
                                    tbody={
                                        <>
                                            {/* 顧客リストの単体 */}
                                            {((customerList?.list ?? []) as CustomerEntities[]).map((customer) => (
                                                <tr
                                                    key={customer.uuid}
                                                    className={clsx(
                                                        'align-top',
                                                        'border-b',
                                                        selectedMultipleCustomer.includes(customer) && 'bg-kimar-primary-light',
                                                        'cursor-pointer',
                                                        'select-none',
                                                    )}
                                                    onClick={(event) => {
                                                        onClickCustomerInMultipleEdit(
                                                            customerList?.list as CustomerEntities[],
                                                            customer,
                                                            event,
                                                        )
                                                    }}>
                                                    <td>
                                                        <CCheckBox
                                                            className={clsx('rounded', 'flex-1')}
                                                            checked={selectedMultipleCustomer.includes(customer)}
                                                        />
                                                    </td>
                                                    <td className={clsx('px-2')}>
                                                        <CTagList
                                                            horizontal
                                                            items={
                                                                customer.labels?.map((label) => ({
                                                                    className: clsx('text-xs'),
                                                                    style: {
                                                                        backgroundColor: label.bgColor,
                                                                        color: label.letterColor,
                                                                    },
                                                                    children: <>{label.name}</>,
                                                                })) ?? []
                                                            }
                                                        />
                                                    </td>
                                                    <td className={clsx('px-2')}>{customer.companyName}</td>
                                                    <td className={clsx('px-2')}>{customer.name}</td>
                                                    <td className={clsx('px-2')}>
                                                        {customer.assignees
                                                            ?.map((assignee) => assignee.assignedTo?.name)
                                                            .join(', ')}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    }
                                />
                            )}
                        </div>
                        <CPager page={page} pageSize={maxCount} total={customerList.count} onPageChanged={onPageChanged} />
                    </div>
                    <div className={clsx('bg-white', 'w-1/3', 'p-4')}>
                        <div className={clsx('text-gray-700', 'text-lg')}>{t('Customer.顧客担当者')}</div>
                        <div>
                            <CTable
                                thead={
                                    <>
                                        <tr>
                                            <th className={clsx('w-6')}>
                                                <div className={clsx('flex')}>
                                                    <CCheckBox
                                                        className={clsx('rounded', 'border', 'border-white')}
                                                        checked={
                                                            !!selectedMultipleTeamMember.length &&
                                                            ((teamMemberListCount?.list ?? []) as TeamMemberEntities[]).every(
                                                                (c) => selectedMultipleTeamMember.includes(c),
                                                            )
                                                        }
                                                        onChange={() => {
                                                            !!selectedMultipleTeamMember.length &&
                                                            ((teamMemberListCount?.list ?? []) as TeamMemberEntities[]).every(
                                                                (c) => selectedMultipleTeamMember.includes(c),
                                                            )
                                                                ? setSelectedMultipleTeamMember(
                                                                      selectedMultipleTeamMember.filter(
                                                                          (c) => !teamMemberListCount?.list.includes(c),
                                                                      ),
                                                                  )
                                                                : setSelectedMultipleTeamMember(
                                                                      uniqBy(
                                                                          [
                                                                              ...selectedMultipleTeamMember,
                                                                              ...(teamMemberListCount?.list as TeamMemberEntities[]),
                                                                          ],
                                                                          'uuid',
                                                                      ),
                                                                  )
                                                        }}
                                                    />
                                                </div>
                                            </th>
                                            <th>{t('Customer.担当者名')}</th>
                                        </tr>
                                    </>
                                }
                                tbody={
                                    <>
                                        {((teamMemberListCount?.list ?? []) as TeamMemberEntities[]).map((member) => (
                                            <tr
                                                key={member.uuid}
                                                className={clsx(
                                                    'align-top',
                                                    'border-b',
                                                    selectedMultipleTeamMember.includes(member) && 'bg-kimar-primary-light',
                                                    'cursor-pointer',
                                                )}
                                                onClick={() => {
                                                    if (selectedMultipleTeamMember.includes(member)) {
                                                        setSelectedMultipleTeamMember(
                                                            selectedMultipleTeamMember.filter((c) => c.uuid != member.uuid),
                                                        )
                                                    } else setSelectedMultipleTeamMember([...selectedMultipleTeamMember, member])
                                                }}>
                                                <td className={clsx('w-6')}>
                                                    <CCheckBox
                                                        className={clsx('rounded', 'flex-1')}
                                                        checked={selectedMultipleTeamMember.includes(member)}
                                                    />
                                                </td>
                                                <td className={clsx('px-2')}>
                                                    <div>{member.name}</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                }
                            />
                        </div>
                        <div className={clsx('grid', 'grid-cols-2', 'gap-2', 'my-2')}>
                            <div className={clsx('flex', 'justify-end')}>
                                <CButton
                                    className={clsx('c-button-primary', 'text-sm')}
                                    disabled={!selectedMultipleCustomer.length || !selectedMultipleTeamMember.length}
                                    onClick={() => sendMultipleApi('assign', selectedMultipleTeamMember)}>
                                    {t('Customer.選択した担当者を追加')}
                                </CButton>
                            </div>
                            <div className={clsx('flex', 'justify-start')}>
                                <CButton
                                    className={clsx('c-button-secondary', 'text-sm')}
                                    disabled={!selectedMultipleCustomer.length || !selectedMultipleTeamMember.length}
                                    onClick={() => sendMultipleApi('unassign', selectedMultipleTeamMember)}>
                                    {t('Customer.選択した担当者を解除')}
                                </CButton>
                            </div>
                        </div>
                        <div className={clsx('h-[1px]', 'bg-gray-300', 'my-4')} />
                        <div className={clsx('text-gray-700', 'text-lg')}>{t('Customer.顧客ラベル')}</div>
                        <CTable
                            thead={
                                <tr>
                                    <th className={clsx('w-6')}>
                                        <div className={clsx('flex')}>
                                            <CCheckBox
                                                className={clsx('rounded', 'border', 'border-white')}
                                                checked={
                                                    !!selectedMultipleCustomerLabel.length &&
                                                    ((labelListCount?.list ?? []) as CustomerLabelEntities[]).every((c) =>
                                                        selectedMultipleCustomerLabel.includes(c),
                                                    )
                                                }
                                                onChange={() => {
                                                    !!selectedMultipleCustomerLabel.length &&
                                                    ((labelListCount?.list ?? []) as CustomerLabelEntities[]).every((c) =>
                                                        selectedMultipleCustomerLabel.includes(c),
                                                    )
                                                        ? setSelectedMultipleCustomerLabel(
                                                              selectedMultipleCustomerLabel.filter(
                                                                  (c) => !labelListCount?.list.includes(c),
                                                              ),
                                                          )
                                                        : setSelectedMultipleCustomerLabel(
                                                              uniqBy(
                                                                  [
                                                                      ...selectedMultipleCustomerLabel,
                                                                      ...(labelListCount?.list as CustomerLabelEntities[]),
                                                                  ],
                                                                  'uuid',
                                                              ),
                                                          )
                                                }}
                                            />
                                        </div>
                                    </th>
                                    <th>{t('Customer.顧客ラベル名')}</th>
                                </tr>
                            }
                            tbody={(
                                sortBy(
                                    labelListCount?.list ?? [],
                                    (s: CustomerLabelEntities) => s.sort,
                                ) as CustomerLabelEntities[]
                            ).map((label) => (
                                <tr
                                    key={label.uuid}
                                    className={clsx(
                                        'align-top',
                                        'border-b',
                                        selectedCustomer?.uuid == label.uuid && 'bg-kimar-primary-light',
                                        'cursor-pointer',
                                    )}
                                    onClick={() => {
                                        if (selectedMultipleCustomerLabel.includes(label)) {
                                            setSelectedMultipleCustomerLabel(
                                                selectedMultipleCustomerLabel.filter((c) => c.uuid != label.uuid),
                                            )
                                        } else setSelectedMultipleCustomerLabel([...selectedMultipleCustomerLabel, label])
                                    }}>
                                    <td className={clsx('w-6')}>
                                        <CCheckBox
                                            className={clsx('p-1', 'rounded', 'flex-1')}
                                            checked={selectedMultipleCustomerLabel.includes(label)}
                                        />
                                    </td>
                                    <td className={clsx('px-2')}>
                                        <CTag
                                            className={clsx('text-sm', 'px-1', 'rounded')}
                                            style={{
                                                backgroundColor: label.bgColor,
                                                color: label.letterColor,
                                            }}>
                                            {label.name}
                                        </CTag>
                                    </td>
                                </tr>
                            ))}
                        />
                        <div className={clsx('grid', 'grid-cols-2', 'gap-2', 'my-2')}>
                            <div className={clsx('flex', 'justify-end')}>
                                <CButton
                                    className={clsx('c-button-primary', 'text-sm')}
                                    disabled={!selectedMultipleCustomer.length || !selectedMultipleCustomerLabel.length}
                                    onClick={() => sendMultipleApi('label', selectedMultipleCustomerLabel)}>
                                    {t('Customer.選択したラベルを追加')}
                                </CButton>
                            </div>
                            <div className={clsx('flex', 'justify-start')}>
                                <CButton
                                    className={clsx('c-button-secondary', 'text-sm')}
                                    disabled={!selectedMultipleCustomer.length || !selectedMultipleCustomerLabel.length}
                                    onClick={() => sendMultipleApi('unlabelled', selectedMultipleCustomerLabel)}>
                                    {t('Customer.選択したラベルを解除')}
                                </CButton>
                            </div>
                        </div>
                        <div className={clsx('h-[1px]', 'bg-gray-300', 'my-4')} />
                        <div className={clsx('text-gray-700', 'text-lg')}>{t('Customer.その他の操作')}</div>
                        <div className={clsx('grid', 'grid-cols-2', 'gap-2')}>
                            <div className={clsx('flex', 'justify-end')}>
                                <CButton
                                    className={clsx('c-button-primary', 'text-sm')}
                                    disabled={!selectedMultipleCustomer.length}
                                    onClick={() => setRequestMultipleCustomer(true)}>
                                    {t('Customer.購入条件の入力をリクエスト')}
                                </CButton>
                            </div>
                            <div className={clsx('flex', 'justify-start')}>
                                <CButton
                                    className={clsx('c-button-danger', 'text-sm')}
                                    disabled={!selectedMultipleCustomer.length}
                                    onClick={() => setDeleteMultipleCustomer(true)}>
                                    {t('Customer.選択した顧客を削除')}
                                </CButton>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className={clsx('flex', 'flex-col', 'md:flex-row', 'justify-between', 'whitespace-nowrap', 'gap-2')}>
                        <CLabeledItem label={t('Customer.並び順')} horizontal>
                            <CSortButton
                                className={clsx('border-gray-300')}
                                items={[
                                    {
                                        label: t('Customer.名前'),
                                        value: 'name',
                                    },
                                    {
                                        label: t('Customer.会社名'),
                                        value: 'company',
                                    },
                                ]}
                                nowValue={form.order}
                                sort={form.sort}
                                onChange={(val) => onChangeSort(val as 'name' | 'company', form.sort)}
                                onClickSort={() => onChangeSort(form.order, form.sort == 'ASC' ? 'DESC' : 'ASC')}
                            />
                        </CLabeledItem>
                        <CPager page={page} pageSize={maxCount} total={customerList.count} onPageChanged={onPageChanged} />
                    </div>
                    <div className={clsx('flex', selectedCustomer ? ['flex-col', 'md:flex-row', 'gap-2'] : 'flex-col')}>
                        <div
                            className={clsx(
                                'flex',
                                'flex-col',
                                selectedCustomer ? 'md:w-1/2' : 'w-full',
                                'gap-2',
                                'max-h-[50vh]',
                                'md:max-h-none',
                            )}>
                            <div className={clsx('overflow-auto')}>
                                {customerList.list.length === 0 && (
                                    <CMessage info>{t('Customer.顧客が見つかりませんでした')}</CMessage>
                                )}
                                {customerList.list.length > 0 && (
                                    <div className={clsx(!selectedCustomer ? 'min-w-[1000px]' : 'w-auto')}>
                                        {/* 顧客リスト */}
                                        <CTable
                                            thead={
                                                <tr>
                                                    <th className={clsx('px-2')}>{t('Customer.顧客ラベル')}</th>
                                                    <th className={clsx('px-2')}>{t('Customer.会社名')}</th>
                                                    <th className={clsx('px-2')}>{t('Customer.氏名')}</th>
                                                    <th className={clsx('px-2')}>{t('Customer.役職')}</th>
                                                    {!selectedCustomer && (
                                                        <>
                                                            <th className={clsx('px-2')}>{t('Customer.顧客担当者')}</th>
                                                            <th className={clsx('px-2')}>{t('Customer.獲得経路')}</th>
                                                            <th className={clsx('px-2')}>{t('Customer.購入条件')}</th>
                                                            <th className={clsx('px-2')}>{t('Customer.レコメンド')}</th>
                                                            {/* TODO: 紹介件数どう取るか考える */}
                                                            {/* <th className={clsx('px-2')}>{t('Customer.紹介した')}</th>
                                                        <th className={clsx('px-2')}>{t('Customer.紹介された')}</th> */}
                                                        </>
                                                    )}
                                                </tr>
                                            }
                                            tbody={
                                                <>
                                                    {/* 顧客リストの単体 */}
                                                    {((customerList?.list ?? []) as CustomerEntities[]).map((customer) => (
                                                        <tr
                                                            key={customer.uuid}
                                                            className={clsx(
                                                                'align-top',
                                                                'border-b',
                                                                selectedCustomer?.uuid == customer.uuid &&
                                                                    'bg-kimar-primary-light',
                                                                'cursor-pointer',
                                                            )}
                                                            onClick={() => {
                                                                if (selectedCustomer && selectedCustomer.uuid == customer.uuid)
                                                                    setSelectedCustomer(undefined)
                                                                else setSelectedCustomer(customer)
                                                            }}>
                                                            <td className={clsx('px-2')}>
                                                                <CTagList
                                                                    horizontal
                                                                    items={
                                                                        sortBy(customer.labels, (s) => s.sort).map((label) => ({
                                                                            className: clsx('text-xs'),
                                                                            style: {
                                                                                backgroundColor: label.bgColor,
                                                                                color: label.letterColor,
                                                                            },
                                                                            children: <>{label.name}</>,
                                                                        })) ?? []
                                                                    }
                                                                />
                                                            </td>
                                                            <td className={clsx('px-2')}>{customer.companyName}</td>
                                                            <td className={clsx('px-2')}>{customer.name}</td>
                                                            <td className={clsx('px-2')}>{customer.position}</td>
                                                            {!selectedCustomer && (
                                                                <>
                                                                    <td className={clsx('px-2')}>
                                                                        {customer.assignees
                                                                            ?.map((assignee) => assignee.assignedTo?.name)
                                                                            .join(', ')}
                                                                    </td>
                                                                    <td className={clsx('px-2')}>
                                                                        {customerRegisteredTypeFormat(customer.registeredType)}
                                                                    </td>
                                                                    <td className={clsx('px-2', 'whitespace-nowrap')}>
                                                                        {(() => {
                                                                            const status = getInvestmentConditionStatus(customer)
                                                                            return (
                                                                                <CTag
                                                                                    className={clsx(
                                                                                        'rounded',
                                                                                        'text-xs',
                                                                                        status.bgClass,
                                                                                        status.textClass,
                                                                                        'px-1',
                                                                                    )}>
                                                                                    {status.text}
                                                                                </CTag>
                                                                            )
                                                                        })()}
                                                                    </td>
                                                                    <td className={clsx('px-2', 'whitespace-nowrap')}>
                                                                        <div className={clsx('flex')}>
                                                                            <CCheckBox
                                                                                className={clsx('p-1', 'rounded', 'flex-1')}
                                                                                readonly
                                                                                checked={customer.isRecommendTarget}
                                                                                label={
                                                                                    customer.isRecommendTarget
                                                                                        ? t('Customer.対象')
                                                                                        : t('Customer.非対象')
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    {/* <td className={clsx('px-2', 'whitespace-nowrap')}>
                                                                    <div className={clsx('flex')}>
                                                                        <CCheckBox
                                                                            className={clsx('p-1', 'rounded', 'flex-1')}
                                                                            readonly
                                                                            checked={!!customer.intermediaryToList?.length}
                                                                            label={`${
                                                                                customer.intermediaryToList?.length ?? 0
                                                                            }${t('Unit.件')}`}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className={clsx('px-2', 'whitespace-nowrap')}>
                                                                    <div className={clsx('flex')}>
                                                                        <CCheckBox
                                                                            className={clsx('p-1', 'rounded', 'flex-1')}
                                                                            readonly
                                                                            checked={
                                                                                !!customer.relatedMember?.intermediaries?.length
                                                                            }
                                                                            label={`${
                                                                                customer.relatedMember?.intermediaries?.length ??
                                                                                0
                                                                            }${t('Unit.件')}`}
                                                                        />
                                                                    </div>
                                                                </td> */}
                                                                </>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </>
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                            <CPager page={page} pageSize={maxCount} total={customerList.count} onPageChanged={onPageChanged} />
                        </div>
                        {selectedCustomer && (
                            <div className={clsx('h-full', 'sticky', 'top-32', 'md:w-1/2')}>
                                <CCustomerDetail customer={selectedCustomer} refetch={managementRefetch} />
                            </div>
                        )}
                        <CCustomerEdit
                            visible={!!isShowInsertCustomer}
                            onSubmit={() => {
                                setIsShowInsertCustomer(false)
                                refetchCustomer()
                            }}
                            onClose={() => {
                                setIsShowInsertCustomer(false)
                                refetchCustomer()
                            }}
                        />
                    </div>
                </>
            )}
            <CConfirmModal
                visible={deleteMultipleCustomer}
                onRequestClose={closeMultipleDeleteModal}
                onRequestConfirm={confirmMultipleDeleteModal}
                confirmLabel={'削除'}>
                {t('Customer.選択した顧客を削除します。よろしいですか？')}
            </CConfirmModal>
            <CConfirmModal
                visible={requestMultipleCustomer}
                onRequestClose={closeRequestMultipleCustomerModal}
                onRequestConfirm={confirmRequestMultipleCustomerModal}
                confirmLabel={'送信'}>
                {t('Customer.選択した顧客へ購入条件リクエストを送信します。よろしいですか？')}
            </CConfirmModal>
            <CConfirmModal
                content={{ maxWidth: '50%' }}
                visible={showCsvExport}
                onRequestClose={() => setShowCsvExport(false)}
                onRequestConfirm={() => csvExport()}
                confirmLabel={'確定'}>
                <CError errors={exportErrors} />
                {t(
                    'Customer.全顧客をCSV出力します。顧客リストの情報に特殊文字と常用外漢字が含まれる場合は正しく表示されませんのでご注意ください。',
                )}
            </CConfirmModal>
            <CCustomerInfoCsvEdit isShow={showCsvImport} onRequestClose={() => setShowCsvImport(false)} />
        </>
    )
}
export default Customer
