import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { isNil } from 'lodash'
import { useEffect, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import {
    ClientRealEstateEndedRealEstateInsertDto,
    RealEstateEntities,
    useClientRealEstateEndedRealEstatePostRealEstateAssigneeMutation,
} from '~/app/api'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CError } from '~/components/common/cError/CError'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CNumberInput } from '~/components/common/cNumberInput/CNumberInput'
import { CRadioButton } from '~/components/common/cRadioButton/CRadioButton'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { CYearMonthDay } from '~/components/common/cYearMonthDay/CYearMonthDay'
import { ApplicationError, IApplicationError } from '~/types/error'
import { fullCurrencyFormat } from '~/util/common/common'

const EndedType = [
    { value: 1, label: '自決' },
    { value: 2, label: '他決' },
]

const Involvement = [
    { value: 1, label: '自社購入' },
    { value: 2, label: '自社売却' },
    { value: 3, label: '売り仲介' },
    { value: 4, label: '買い仲介' },
    { value: 5, label: '両手仲介' },
]

const ViaType = [
    { value: 1, label: 'Kimarで紹介' },
    { value: 2, label: 'その他の方法で紹介' },
]

export const getEndedTypeLabel = (type?: number) => {
    return EndedType.find((i) => i.value === type)?.label ?? ''
}

export const getInvolvementLabel = (type?: number) => {
    return Involvement.find((i) => i.value === type)?.label ?? ''
}

export type CEndedRealestateViewProps = {
    form: ClientRealEstateEndedRealEstateInsertDto
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (
        key: keyof ClientRealEstateEndedRealEstateInsertDto,
        value: ClientRealEstateEndedRealEstateInsertDto[keyof ClientRealEstateEndedRealEstateInsertDto],
    ) => void
    errors: IApplicationError[]
} & CEndedRealestateProps

export const CEndedRealestateView: VFC<CEndedRealestateViewProps> = ({
    realEstate,
    visible,
    form,
    onRequestClose,
    onRequestConfirm,
    setProps,
    errors,
}) => {
    const viaTypeItems = ViaType.map((v) => ({
        value: v.value,
        label:
            v.value === 1 ? (
                <div className={clsx('whitespace-nowrap', 'flex', 'items-center')}>
                    <div>{v.label}</div>
                    <div className={clsx('mr-1')}>{'（'}</div>
                    <CCheckBox
                        label={t('CEndedRealestate.マーケット経由で情報取得')}
                        disabled={!form.isKimar}
                        checked={!!form.isMarket}
                        onChange={(e) => setProps('isMarket', e.target.checked)}
                    />
                    <div className={clsx('ml-1')}>{'）'}</div>
                </div>
            ) : (
                <div className={clsx('my-1')}>{v.label}</div>
            ),
    }))

    return (
        <CConfirmModal
            visible={visible}
            content={{ width: '100%', maxWidth: '768px' }}
            title={t('CEndedRealestate.物件終了')}
            onRequestClose={() => onRequestClose()}
            onRequestConfirm={() => onRequestConfirm()}
            confirmLabel={t('CEndedRealestate.終了確定')}>
            <div>
                <div className={clsx('px-8')}>
                    <CError errors={errors} />
                    <CMessage warning>
                        <div className={clsx('whitespace-pre-wrap')}>
                            {t(
                                'CEndedRealestate.物件終了（成約）を登録します。終了した物件はレコメンド／コネクションの対象になりません。また、マーケットへの掲載及び紹介は全て終了されます。この操作を戻すことはできません。',
                            )}
                        </div>
                    </CMessage>
                    <div className={clsx('py-2')}>
                        {realEstate?.sendStatus === 1 && (
                            <CMessage info>
                                <div>
                                    <CLabeledItem
                                        label={t('CEndedRealestate.通知を送って紹介終了')}
                                        horizontal
                                        className={clsx('space-y-0')}>
                                        <CToggleSwitch
                                            width="lg"
                                            value={form.isSendNotification ?? false}
                                            trueLabel={t('CEndedRealestate.はい')}
                                            falseLabel={t('CEndedRealestate.いいえ')}
                                            toggleChanged={(value) => setProps('isSendNotification', value)}
                                        />
                                    </CLabeledItem>
                                    {form.isSendNotification && (
                                        <div className={clsx('py-2')}>
                                            <CLabeledItem label={t('CEndedRealestate.紹介先へのメッセージ')}>
                                                <CTextInput
                                                    text={form.finishMessage}
                                                    onChange={(value) => setProps('finishMessage', value)}
                                                    className={clsx('w-full', 'c-text-input-base')}
                                                    placeholder={t(
                                                        'CEndedRealestate.物件紹介の終了にあたり紹介先に対してメッセージを送ることが出来ます。記入がない場合は紹介終了のお知らせのみ紹介先に送信されます。',
                                                    )}
                                                    type="multiline"
                                                />
                                            </CLabeledItem>
                                        </div>
                                    )}
                                </div>
                            </CMessage>
                        )}
                    </div>
                    <div className={clsx('py-2')}>
                        <div className={clsx('flex', 'gap-2')}>
                            <CLabeledItem label={t('CEndedRealestate.契約時期')}>
                                <CYearMonthDay
                                    value={dayjs(form.endedAt).toDate()}
                                    jc
                                    type={'month'}
                                    hideNonUsedDropdown
                                    forceReturnValue
                                    disabled={isNil(form.endedAt)}
                                    onChange={(val) => setProps('endedAt', val ? dayjs(val).toISOString() : undefined)}
                                />
                                <CCheckBox
                                    className={clsx('rounded', 'border', 'border-white')}
                                    onChange={() => {
                                        setProps('endedAt', form.endedAt ? undefined : dayjs().toDate().toISOString())
                                    }}
                                    label={t('CEndedRealestate.契約時期不明')}
                                    checked={isNil(form.endedAt)}
                                />
                            </CLabeledItem>
                            <CLabeledItem label={t('CEndedRealestate.決済時期')}>
                                <CYearMonthDay
                                    value={dayjs(form.paymentAt).toDate()}
                                    jc
                                    type={'month'}
                                    hideNonUsedDropdown
                                    forceReturnValue
                                    disabled={isNil(form.paymentAt)}
                                    onChange={(val) => setProps('paymentAt', val ? dayjs(val).toISOString() : undefined)}
                                />
                                <CCheckBox
                                    className={clsx('rounded', 'border', 'border-white')}
                                    onChange={() => {
                                        setProps('paymentAt', form.paymentAt ? undefined : dayjs().toDate().toISOString())
                                    }}
                                    label={t('CEndedRealestate.決済時期不明')}
                                    checked={isNil(form.paymentAt)}
                                />
                            </CLabeledItem>
                        </div>
                        <div>
                            <CLabeledItem label={t('CEndedRealestate.売買金額')}>
                                <div className={clsx('mx-1')}>
                                    <CNumberInput
                                        className={clsx('c-text-input-base')}
                                        value={form.price}
                                        decimalScale={0}
                                        disabled={isNil(form.price)}
                                        onChange={(value) => setProps('price', value)}
                                    />
                                    <span className={clsx('ml-2')}>{t('Unit.円')}</span>
                                    <span className={clsx('ml-2')}>
                                        ({fullCurrencyFormat(form.price)}
                                        {t('Unit.円')})
                                    </span>
                                </div>
                                <CCheckBox
                                    className={clsx('rounded', 'border', 'border-white')}
                                    onChange={() => {
                                        setProps('price', !isNil(form.price) ? undefined : 0)
                                    }}
                                    label={t('CEndedRealestate.売買金額不明')}
                                    checked={isNil(form.price)}
                                />
                            </CLabeledItem>
                        </div>
                        <div>
                            <CLabeledItem label={t('CEndedRealestate.売買への関与')}>
                                <div className={clsx('mx-1')}>
                                    <CRadioButton
                                        horizontal
                                        items={EndedType}
                                        nowValue={form.endedType}
                                        onChange={(val) => setProps('endedType', val as number)}
                                    />
                                </div>
                            </CLabeledItem>
                        </div>
                        <div>
                            <CLabeledItem label={t('CEndedRealestate.関与内容')}>
                                <div className={clsx('mx-1')}>
                                    <CRadioButton
                                        disabled={form.endedType !== 1}
                                        horizontal
                                        items={Involvement}
                                        nowValue={form.involvement}
                                        onChange={(val) => setProps('involvement', val as number)}
                                    />
                                </div>
                            </CLabeledItem>
                        </div>
                        <div className={clsx('flex', 'gap-2')}>
                            <CLabeledItem label={t('CEndedRealestate.紹介方法')}>
                                <div className={clsx('mx-1', 'flex')}>
                                    <CRadioButton
                                        horizontal
                                        items={viaTypeItems}
                                        nowValue={form.isKimar ? 1 : 2}
                                        onChange={(val) => setProps('isKimar', val === 1)}
                                    />
                                </div>
                            </CLabeledItem>
                        </div>
                    </div>
                </div>
            </div>
        </CConfirmModal>
    )
}

export type CEndedRealestateProps = {
    visible: boolean
    realEstate?: RealEstateEntities
    onSubmit: () => void
    onClose: () => void
}

export const CEndedRealestate: VFC<CEndedRealestateProps> = ({ ...props }) => {
    const [visible, setVisible] = useState(props.visible)
    const [form, setForm] = useState<ClientRealEstateEndedRealEstateInsertDto>()
    useEffect(() => {
        setErrors([])
        setForm({
            realEstateUuid: props.realEstate?.uuid ?? '',
            endedAt: dayjs().toDate().toISOString(),
            paymentAt: dayjs().toDate().toISOString(),
            price: props.realEstate?.price ?? 0,
            endedType: 1,
            involvement: 1,
            isKimar: true,
            isMarket: false,
        })
        setVisible(props.visible)
    }, [props.visible])

    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const [endedQuery] = useClientRealEstateEndedRealEstatePostRealEstateAssigneeMutation()
    const setProps = (
        key: keyof ClientRealEstateEndedRealEstateInsertDto,
        value: ClientRealEstateEndedRealEstateInsertDto[keyof ClientRealEstateEndedRealEstateInsertDto],
    ) => {
        if (!form) return
        setForm({
            ...form,
            [key]: value,
        })
    }
    const onRequestClose = () => {
        props.onClose()
    }
    const onRequestConfirm = async () => {
        if (!props.realEstate) return
        if (!form) return
        setErrors([])
        try {
            const sendData = {
                ...form,
                involvement: form.endedType !== 1 ? undefined : form.involvement,
            }
            await endedQuery({
                clientRealEstateEndedRealEstateInsertDto: sendData,
            }).unwrap()
            toast.success(t('CEndedRealestate.物件終了しました'))
            props.onSubmit()
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    if (!form) return null
    return (
        <CEndedRealestateView
            {...props}
            visible={visible}
            form={form}
            onRequestConfirm={onRequestConfirm}
            onRequestClose={onRequestClose}
            setProps={setProps}
            errors={errors}
        />
    )
}
