import clsx from 'clsx'
import { ReactNode } from 'react'

type Props = {
    id?: string
    messages?: Array<string>
    // 中央寄せ
    centered?: boolean
    // カラーリング
    info?: boolean
    success?: boolean
    warning?: boolean
    danger?: boolean
    color?: string | null

    children: ReactNode
}

const CMessage = ({ id, messages = [], children, ...options }: Props) => {
    // const additionalClass = {}
    const additionalStyle: React.CSSProperties = options.color ? { backgroundColor: options.color } : {}

    const messageListDisplay = messages.map((message, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>{message}</li>
    ))
    return (
        <section
            id={id}
            className={clsx(
                'rounded',
                'border',
                'p-4',
                options.centered && 'text-center',
                options.info && ['border-sky-700', 'bg-sky-50', 'text-sky-700'],
                options.success && ['border-green-900', 'bg-green-50', 'text-green-900'],
                options.warning && ['border-amber-700', 'bg-amber-50', 'text-amber-700'],
                options.danger && ['border-red-700', 'bg-red-50', 'text-red-700'],
            )}
            style={additionalStyle}>
            {messages && <ul>{messageListDisplay}</ul>}
            {children}
        </section>
    )
}

export default CMessage
