import clsx from 'clsx'
import { t } from 'i18next'

import pastReceivePin from '~/assets/image/page/map/past_receive.svg'
import pastSendPin from '~/assets/image/page/map/past_send.svg'
import receivePin from '~/assets/image/page/map/receive.svg'
import registPin from '~/assets/image/page/map/regist.svg'
import sendPin from '~/assets/image/page/map/send.svg'

type Props = {
    isLegendDisplayFull?: boolean
}

const CMapIconLegend = ({ isLegendDisplayFull = true }: Props) => {
    return (
        <div className={clsx('absolute', 'top-3', 'right-14', 'p-2', 'z-10', 'rounded', 'shadow', 'bg-white', 'cursor-pointer')}>
            {!isLegendDisplayFull ? (
                <div className={clsx('flex', 'items-center', 'font-bold')}>
                    <img className={clsx('h-8')} src={sendPin} alt={t('CMapIconLegend.凡例アイコン')} />
                    <p>{t('CMapIconLegend.ピン凡例')}</p>
                </div>
            ) : (
                <div className={clsx('flex', 'flex-col')}>
                    <p className={clsx('text-center', 'font-bold')}>{t('CMapIconLegend.ピン凡例')}</p>
                    <ul className={clsx('flex', 'flex-col', 'space-y-1', 'text-sm')}>
                        <li className={clsx('flex', 'items-center', 'space-x-1')}>
                            <img className={clsx('h-9')} src={registPin} />
                            <span>{t('CMapIconLegend.登録した')}</span>
                        </li>
                        <li className={clsx('w-full', 'h-px', 'bg-gray-300')} />
                        <li className={clsx('flex', 'items-center', 'space-x-1')}>
                            <img className={clsx('h-9')} src={sendPin} />
                            <span>{t('CMapIconLegend.紹介している')}</span>
                        </li>
                        <li className={clsx('flex', 'items-center', 'space-x-1')}>
                            <img className={clsx('h-9')} src={pastSendPin} />
                            <span>{t('CMapIconLegend.紹介していた')}</span>
                        </li>
                        <li className={clsx('w-full', 'h-px', 'bg-gray-300')} />
                        <li className={clsx('flex', 'items-center', 'space-x-1')}>
                            <img className={clsx('h-9')} src={receivePin} />
                            <span>{t('CMapIconLegend.紹介されている')}</span>
                        </li>
                        <li className={clsx('flex', 'items-center', 'space-x-1')}>
                            <img className={clsx('h-9')} src={pastReceivePin} />
                            <span>{t('CMapIconLegend.紹介されていた')}</span>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    )
}

export default CMapIconLegend
