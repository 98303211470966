import clsx from 'clsx'
import { t } from 'i18next'
import { VFC } from 'react'

import { CButton } from '~/components/common/cButton/CButton'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import { CFooterModal } from '~/components/common/cModal/CModal'

export type CRealestateTrialModalViewProps = {
    visible: boolean
    onRequestClose: () => void
} & CRealestateTrialModalProps

export const CRealestateTrialModalView: VFC<CRealestateTrialModalViewProps> = ({ visible, onRequestClose }) => {
    return (
        <CFooterModal
            footer={
                <CButton className={clsx('c-button-secondary')} onClick={onRequestClose}>
                    {t('Button.閉じる')}
                </CButton>
            }
            visible={!!visible}
            content={{ width: '450px' }}
            onRequestClose={onRequestClose}>
            <div className={clsx('px-8', 'py-4', 'flex', 'flex-col', 'gap-4')}>
                <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>
                    {t('CRealestateTrialModal.無料会員様へのお知らせ')}
                </div>
                <p>{t('CRealestateTrialModal.無料会員は5件まで物件情報の閲覧が可能です。')}</p>
                <p>
                    {t('CRealestateTrialModal.6件目以降の物件情報を閲覧するには、不要な物件情報をキマール上で削除してください。')}
                </p>
                <p>
                    ■{t('CRealestateTrialModal.物件の削除方法')}
                    <br />
                    {t('CRealestateTrialModal.閲覧可能な物件の詳細ページ')}
                    <br />→
                    <span className={clsx('border', 'border-red-700', 'text-red-700', 'rounded', 'text-xs', 'p-0.5', 'mx-1')}>
                        {t('CRealestateTrialModal.検討終了')}
                    </span>
                    {t('CRealestateTrialModal.（紹介された物件の場合）')}
                    <br />→
                    <span className={clsx('border', 'border-red-700', 'text-red-700', 'rounded', 'text-xs', 'p-0.5', 'mx-1')}>
                        {t('CRealestateTrialModal.物件削除')}
                    </span>
                </p>
                <p>
                    {t(
                        'CRealestateTrialModal.削除方法に関する質問や、閲覧可能な物件数を無制限とするには、以下のボタンからお問い合わせください。',
                    )}
                </p>
                <CLinkButton className={clsx('c-button-primary', 'text-sm', 'md:row-start-4')} to={`/inquiry`}>
                    {t('CRealestateTrialModal.お問い合わせ')}
                </CLinkButton>
            </div>
        </CFooterModal>
    )
}

export type CRealestateTrialModalProps = {
    visible: boolean
    onClose: () => void
}

export const CRealestateTrialModal: VFC<CRealestateTrialModalProps> = ({ ...props }) => {
    const onRequestClose = () => {
        props.onClose()
    }

    return <CRealestateTrialModalView {...props} onRequestClose={onRequestClose} />
}
