import clsx from 'clsx'
import { VFC } from 'react'

export type CHeaderViewProps = {} & CHeaderProps

export const CHeaderView: VFC<CHeaderViewProps> = ({ label, bgClass }) => {
    return (
        <div className={clsx('flex', 'flex-col', 'gap-1', 'items-center', 'justify-center', 'text-2xl')}>
            <h2>{label}</h2>
            <div className={clsx('w-12', 'h-[3px]', 'mx-auto', 'rounded-sm', bgClass || 'bg-kimar-primary')} />
        </div>
    )
}

export type CHeaderProps = {
    label?: string
    bgClass?: string
}

export const CHeader: VFC<CHeaderProps> = (props) => {
    return <CHeaderView {...props} />
}
