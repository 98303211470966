import Big from 'big.js'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { ceil, floor, isEmpty, isNil, isNumber } from 'lodash'

export const pageTitleTemplate = (isAdmin: boolean = false) => {
    const preffixStages = ['local', 'dev', 'stage']
    const stage = preffixStages.includes(process.env.STAGE ?? '') ? `[${process.env.STAGE}] ` : ''
    return `${stage}%s | ${isAdmin ? 'キマール運営管理' : t('App.キマール')}`
}

export const toArrayBuffer = (buf: Buffer) => {
    const ab = new ArrayBuffer(buf.length)
    const view = new Uint8Array(ab)
    for (let i = 0; i < buf.length; ++i) view[i] = buf[i]
    return ab
}

export const formatDateTime = (
    date: Date | string | number | undefined | null,
    type: 'date' | 'time' | 'dateTime' | 'dateTimeWithoutSecond',
) => {
    if (isNil(date)) return ''
    let format = 'YYYY/MM/DD'
    switch (type) {
        case 'date':
            format = 'YYYY/MM/DD'
            break
        case 'time':
            format = 'HH:mm'
            break
        case 'dateTime':
            format = 'YYYY/MM/DD HH:mm:ss'
            break
        case 'dateTimeWithoutSecond':
            format = 'YYYY/MM/DD HH:mm'
            break
    }
    const dateString = dayjs(date).format(format)
    return dateString
}

export const deltaDateTime = (date: Date | string, baseDate?: Date | string) => {
    const dateDelta = Math.round(dayjs(date).diff(baseDate, 'day', true))
    const dateDiff = Math.abs(dateDelta < 0 ? floor(dateDelta) : ceil(dateDelta))
    const dateString = dateDelta <= 1 ? (dateDelta === 0 ? deltaTime(date, baseDate) : `${dateDiff}日前`) : `${dateDiff}日後`
    return dateString
}

export const deltaTime = (date: Date | string, baseDate?: Date | string) => {
    const dateDelta = Math.round(dayjs(date).diff(baseDate, 'hour', true))
    const dateDiff = Math.abs(dateDelta < 0 ? floor(dateDelta) : ceil(dateDelta))
    const dateString = dateDelta <= 1 ? (dateDelta === 0 ? 'たった今' : `${dateDiff}時間前`) : `${dateDiff}時間後`
    return dateString
}

export const dateFormatOfJc = (date?: Date | string | number, type: 'year' | 'month' | 'day' = 'day') => {
    if (isNil(date)) return ''
    const d = dayjs(date).toDate()
    const year = d.getFullYear()
    const month = d.getMonth() + 1
    const day = d.getDate()
    // 年月日があるときの和暦
    const jc = getJc(year, month - 1, day)
    // 月の最初の日の和暦
    const jcFirstDayOfMonth = getJc(year, month - 1, 1)
    // 月の最後の日の和暦
    const jcLastDayOfMonth = getJc(year, month, 0)
    // 年の最初の日の和暦
    const jcFirstDayOfYear = getJc(year, 0, 1)
    // 年の最後の日の和暦
    const jcLastDayOfYear = getJc(year, 11, 31)
    if (type === 'day') return `${year}（${jc}）年${month}月${day}日`
    else if (type === 'month') {
        if (jcFirstDayOfMonth === jcLastDayOfMonth) return `${year}（${jcFirstDayOfMonth}）年${month}月`
        else return `${year}（${jcFirstDayOfMonth} / ${jcLastDayOfMonth}）年${month}月`
    } else if (type === 'year') {
        if (jcFirstDayOfYear === jcLastDayOfYear) return `${year}（${jcFirstDayOfYear}）年`
        else return `${year}（${jcFirstDayOfYear} / ${jcLastDayOfYear}）年`
    }
    // 和暦を取得
    function getJc(year: number, month: number, day: number) {
        const jc = new Date(year, month, day).toLocaleString('ja-JP-u-ca-japanese').split('/').shift()
        return jc
    }
}

export const fullCurrencyFormat = (value?: number | string) => {
    if (isNil(value)) return ''
    if (isNumber(value) && value === 0) return '0'
    const _value = isNumber(value) ? String(value) : value
    if (value === '0') return '0'

    const formatter = new Intl.NumberFormat('ja-JP', {
        notation: 'compact',
        useGrouping: false,
        maximumSignificantDigits: 21,
        maximumFractionDigits: 21,
    })
    const fmt = (target: number | string, result: string[] = []): string => {
        const bigIntNum = BigInt(target)
        const _result = formatter.formatToParts(bigIntNum)
        const num = _result.find((v) => v.type === 'integer')!
        const notation = _result.find((v) => v.type === 'compact')
        const numStr = bigIntNum.toString()
        if (notation === undefined) return numStr != '0' ? [...result, numStr].join('') : [...result].join('')
        const dig = num.value.length
        const value = numStr.slice(0, dig)
        const next = numStr.slice(dig)

        return fmt(next, [...result, `${value}${notation.value}`])
    }

    try {
        return fmt(_value)
    } catch {
        // 主にBigInt変換エラー
        return ''
    }
}

// 100掛けて小数点以下切り捨てて100で割る
// ただし掛けるときに誤差が生まれるケースがあるのでライブラリ経由で計算
export const roundDownToTwoDecimalPlaces = (val: number) => {
    const bigval = new Big(val)
    return Math.floor(bigval.times(100).toNumber()) / 100
}

// m^2 to 坪
export const sizeToTsubo = (val: number) => {
    return roundDownToTwoDecimalPlaces(val * 0.3025)
}

// 坪 to m^2
export const tsuboToSize = (val: number) => {
    return roundDownToTwoDecimalPlaces(val * 3.30579)
}

export const isValidDate = (date: string, format: string = 'YYYY-MM-DD') => {
    return dayjs(date, format).format(format) === date
}

export const staticMapImageUrl = (latitude: number, longitude: number, width: number, height: number) => {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&scale=1&size=${width}x${height}&maptype=roadmap&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C${latitude},${longitude}&key=${
        import.meta.env.VITE_GOOGLE_MAPS_API_KEY
    }`
}

// インジェクション対策
export const htmlEntities = (str: string) => {
    return String(str).replace(/&/g, '＆').replace(/"/g, '”').replace(/</g, '＜').replace(/>/g, '＞')
}

export const getUnitString = (unit: 'day' | 'week' | 'twoWeek' | 'month' | 'year' | undefined) => {
    if (unit === 'week') return t('Unit.回／週')
    if (unit === 'twoWeek') return t('Unit.回／2週')
    return ''
}

// 築年数計算
export const calcYearsBuilt = (dateString: string | undefined) => {
    if (!dateString) return ''
    const currentDate = dayjs()
    const targetDate = dayjs(dateString)
    // 未来
    if (currentDate.isBefore(targetDate)) return ''
    const yearsPassed = currentDate.diff(targetDate, 'year')
    const monthPassed = currentDate.diff(targetDate, 'month') - yearsPassed * 12

    if (yearsPassed === 0 && monthPassed === 0) return `(${t('Unit.築1ヶ月以内')})`
    else if (yearsPassed === 0 && monthPassed > 0) return `(${t('Unit.築monthヶ月', { month: monthPassed })})`
    else if (yearsPassed > 0 && monthPassed === 0) return `(${t('Unit.築years年', { years: yearsPassed, month: monthPassed })})`
    else if (yearsPassed > 0 && monthPassed > 0)
        return `(${t('Unit.築years年monthヶ月', { years: yearsPassed, month: monthPassed })})`
}

export const addPrefix = (prefix: string, value?: string) => {
    if (isEmpty(value)) return ''
    return `${prefix}${value}`
}

export const addSuffix = (suffix: string, value?: string) => {
    if (isEmpty(value)) return ''
    return `${value}${suffix}`
}
