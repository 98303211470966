import 'rc-tree/assets/index.css'

import clsx from 'clsx'
import { t } from 'i18next'
import { FC, useMemo, useState } from 'react'
import toast from 'react-hot-toast/headless'

import { RealEstateEntities, useClientCompanyPutUuidRealEstateListKeysMutation } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CDragAndDropItemSet } from '~/components/common/cDragAndDropItemSet/CDragAndDropItemSet'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CTag } from '~/components/common/cTag/CTag'
import { DefaultRealEstateKeys, RealEstateKeyLabels } from '~/util/model/RealEstateEntities'
import { useAppDispatch, useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, selectLoginUserCompany, setLoginUserCompany } from '~/util/store/userSlice'

import { CRealestateListItem } from '../realestate/CRealestateListItem'

export type CUserMyPageTeamMemberProps = {}

export const CUserMyPageListInformation: FC<CUserMyPageTeamMemberProps> = () => {
    const user = useAppSelector(selectLoginUser)
    const company = useAppSelector(selectLoginUserCompany)
    const realEstateKeys =
        (company?.realEstateListKeys as (typeof RealEstateKeyLabels)[number]['key'][] | undefined) ?? DefaultRealEstateKeys
    const [form, setForm] = useState<{ keys: (typeof RealEstateKeyLabels)[number]['key'][] }>({ keys: realEstateKeys })

    const columns = useMemo(() => {
        return [
            {
                id: t('CUserMyPageListInformation.すべての物件項目'),
                title: t('CUserMyPageListInformation.すべての物件項目'),
                cards: RealEstateKeyLabels.filter((kv) => !form.keys.includes(kv.key)).map((kv) => {
                    return {
                        id: kv.key,
                        element: (
                            <CTag
                                className={clsx('px-2', 'rounded', 'text-sm', 'border')}
                                style={{
                                    backgroundColor: '#ffffff',
                                    color: '#000000',
                                }}>
                                {kv.label}
                            </CTag>
                        ),
                    }
                }),
            },
            {
                id: t('CUserMyPageListInformation.表示する物件項目'),
                title: t('CUserMyPageListInformation.表示する物件項目'),
                cards: form.keys
                    .map((k) => {
                        const kv = RealEstateKeyLabels.find((kv) => kv.key === k)
                        if (!kv) return undefined
                        return {
                            id: kv.key,
                            element: (
                                <CTag
                                    className={clsx('px-2', 'rounded', 'text-sm', 'border')}
                                    style={{
                                        backgroundColor: '#ffffff',
                                        color: '#000000',
                                    }}>
                                    {kv.label}
                                </CTag>
                            ),
                        }
                    })
                    .filter((i): i is Exclude<typeof i, undefined> => i !== undefined),
            },
        ]
    }, [form])

    const previewItem = useMemo(() => {
        const item: RealEstateEntities = {
            uuid: '',
            deletedAt: null,
            createdAt: '2022-10-17T07:14:22.000Z',
            updatedAt: '2023-11-15T06:32:23.000Z',
            name: 'テスト物件',
            address: '東京都渋谷区1丁目1-1',
            latitude: 35.6509135,
            longitude: 139.7128408,
            station: '駅徒歩2分',
            informationSource: 'テスト太郎',
            acquiredAt: '2022-11-27T15:00:00.000Z',
            seller: 'テスト売主',
            builder: 'テスト建築主',
            buildingName: 'テスト建物',
            totalFloorSize: 77.65,
            totalFloorSizeTsubo: 23.48,
            rentableAreaSize: 66.1,
            rentableAreaSizeTsubo: 19.99,
            buildAt: '2015-03-01T15:00:00.000Z',
            buildAtDateType: 0,
            structureType: 2,
            floor: 3,
            undergroundFloor: 1,
            zoningType: 5,
            zoningType2: 14,
            floorAreaRatio: 41,
            floorAreaRatio2: 38,
            buildingRate: 54,
            buildingRate2: 53,
            grossRate: 0.72,
            netRate: 3.9,
            price: 139000000,
            taxType: 1,
            saleTimeType: 2,
            seismicStandardType: 1,
            legalComplianceType: 1,
            other: '物件メモ\n床面積 77.52㎡ 23.45坪 ',
            isRecommendTarget: false,
            sendStatus: 0,
            receiveStatus: 0,
            assumeIncome: 1000001,
            presentIncome: 1000005,
            buildingNumber: '家屋番号',
            buildingRight: '所有権',
            certificateOfCompletionOfConfirmation: 1,
            certificateOfFinalInspection: 2,
            cityPlanningArea: 1,
            deliveryAt: '2026-10-04T15:00:00.000Z',
            deliveryAtDateType: 0,
            facility: '駐車場',
            firePreventionDistricts: 1,
            floorSize: 5,
            floorSizeTsubo: 10,
            frontalRoad: '公道',
            heightControl: 'テスト高度規制',
            landCategory: '地目',
            landRight: '完全所有権',
            landShape: 'テスト敷地形状',
            modeOfTransaction: 5,
            salesDestinationType: 3,
            otherControl: 'その他規制なし',
            parcelNumber: '123-4',
            surveyDrawing: 2,
            surveyedLandSize: 115.8,
            surveyedLandSizeTsubo: 35.02,
            totalUnits: 14,
            registeredLandSize: 121,
            registeredLandSizeTsubo: 36.6,
            marketComment: '',
            option: 0,
            useType: {
                uuid: 'debe0e57-615a-48e3-9ae0-ed3003bc6661',
                deletedAt: null,
                createdAt: '2023-05-22T08:06:59.000Z',
                updatedAt: '2023-11-02T08:37:26.000Z',
                name: '商業',
                overview: '',
                sort: 3,
            },
            labels: [
                {
                    uuid: '2b7c1456-7e8d-4aaf-a48d-f504e246c4e6',
                    deletedAt: null,
                    createdAt: '2023-05-22T08:13:02.000Z',
                    updatedAt: '2023-11-30T11:03:38.000Z',
                    name: 'テストラベル',
                    bgColor: '#000000',
                    letterColor: '#ffffff',
                    sort: 1,
                    team: user!.team,
                },
            ],
            memoList: [],
            documents: [],
            markets: [],
            assignees: [
                {
                    uuid: '48f85faf-7116-4848-b1ce-2da1432e0194',
                    deletedAt: null,
                    createdAt: '2023-05-22T08:12:26.000Z',
                    updatedAt: '2023-05-22T08:12:26.000Z',
                    isPrimary: true,
                    assignedTo: user!,
                    realEstate: {} as RealEstateEntities,
                },
            ],
            team: user!.team,
            updatedBy: user,
            createdBy: user,
        }
        return (
            <CRealestateListItem
                item={item}
                details={form.keys}
                progress={{ uuid: '', progress: 0 }}
                isReceiveNewChat={false}
                isSendNewChat={false}
                isNewRecommend={false}
            />
        )
    }, [form])

    const setProps = (key: string, value: unknown) => {
        if (!form) return
        setForm({
            ...form,
            [key]: value,
        })
    }

    const dispatch = useAppDispatch()
    const [saveRealEstateListKeys] = useClientCompanyPutUuidRealEstateListKeysMutation()
    const saveRealEstateListKeysHandler = async () => {
        if (!user || !company) return
        try {
            const _company = await saveRealEstateListKeys({
                uuid: company.uuid,
                companyUpdateRealEstateListKeysDto: {
                    keys: form.keys,
                },
            }).unwrap()
            dispatch(setLoginUserCompany(_company))
            toast.success(t('CUserMyPageListInformation.物件一覧項目を編集しました'))
        } catch (e) {
            console.error(e)
        }
    }

    if (!user) return null
    return (
        <div>
            <CHeader label={t('CUserMyPageListInformation.物件一覧表示項目')} />
            <div className={clsx('py-2')}>
                <CMessage info>
                    {t('CUserMyPageListInformation.物件一覧の表示項目を変更できます。')}
                    <br />
                    {t(
                        'CUserMyPageListInformation.「表示する物件項目」に項目を追加することで、物件一覧にて物件の項目を表示できるようになります。',
                    )}
                </CMessage>
            </div>
            <div className={clsx('pointer-events-none', 'rounded', 'border')}>{previewItem}</div>
            <div className={clsx('p-4', 'max-h-[300px]', 'overflow-hidden', 'flex')}>
                <CDragAndDropItemSet
                    data={columns}
                    onChange={(data) => {
                        setProps(
                            'keys',
                            data[1].cards.map((c) => c.id),
                        )
                    }}
                />
            </div>
            <div className={clsx('w-full', 'flex', 'justify-center')}>
                <CButton
                    className={clsx('c-button-primary')}
                    disabled={!form.keys.length}
                    onClick={saveRealEstateListKeysHandler}>
                    {t('CUserMyPageListInformation.設定確定')}
                </CButton>
            </div>
        </div>
    )
}
