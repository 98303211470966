import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast/headless'
import { Trans } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'

import {
    ClientRealEstateConnectionListDto,
    RealEstateEntities,
    RealEstateUseTypeEntities,
    useClientRealEstateConnectionPutCheckMutation,
    useClientRealEstateConnectionPutOpenMutation,
    useClientRealEstateConnectionRealEstateConnectionQuery,
    useClientRealEstateUseTypeRealEstateUseTypeQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import CInquiryButton from '~/components/common/cInquiryButton/CInquiryButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { ApplicationError, IApplicationError } from '~/types/error'
import { formatDateTime } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, selectLoginUserHaveConnectionContract } from '~/util/store/userSlice'

import {
    areaTypeFormat,
    customerBuildingAgeTypeFormat,
    customerGrossRateTypeFormat,
    customerLegalComplianceTypeFormat,
    customerNetRateTypeFormat,
    customerPriceTypeFormat,
    customerSeismicStandardTypeFormat,
    saleTimeTypeFormat,
} from '../../../../util/filter/format'
import { CSortButton } from '../../../common/cSortButton/CSortButton'
import { CTag } from '../../../common/cTag/CTag'

type Props = {
    realEstate: RealEstateEntities
    setOpenEditModal: (value: boolean) => void
}

const CRealestateDetailConnection = ({ realEstate, setOpenEditModal }: Props) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const user = useAppSelector(selectLoginUser)
    const navigate = useNavigate()
    const isConnectionContract = useAppSelector(selectLoginUserHaveConnectionContract)
    const { data: connectionsData, isLoading: isLoadingConnections } = useClientRealEstateConnectionRealEstateConnectionQuery({
        uuid: realEstate.uuid,
    })
    const { data: useTypeListCount } = useClientRealEstateUseTypeRealEstateUseTypeQuery({})
    // モーダル表示
    const [visibleSync, setVisibleSync] = useState(false)
    // 会社名/氏名確認候補のコネクション
    const [openNameConfirmConnection, setOpenNameConfirmConnection] = useState<ClientRealEstateConnectionListDto | undefined>(
        undefined,
    )
    // 会社名/氏名確認API
    const [connectionOpenAPI] = useClientRealEstateConnectionPutOpenMutation()
    // コネクション閲覧API
    const [connectionCheckAPI] = useClientRealEstateConnectionPutCheckMutation()
    // コネクションを見たことを送信
    useEffect(() => {
        connectionCheckAPI({ uuid: realEstate.uuid })
    }, [realEstate.uuid])

    // 検索フィルタ
    const [filter, setFilter] = useState({
        order: 'createdAt' as 'createdAt' | 'orderScore',
        sort: 'DESC' as 'ASC' | 'DESC',
    })

    const onChange = (v: typeof filter.order) => {
        setFilter((oldValue) => ({
            ...oldValue,
            order: v,
        }))
    }
    const onChangeSort = () => {
        setFilter((oldValue) => ({
            ...oldValue,
            sort: oldValue.sort == 'ASC' ? 'DESC' : 'ASC',
        }))
    }

    // 検索反映後の表示一覧
    const sortedConnections = connectionsData?.list
        ? [...connectionsData.list].sort((a, b) => {
              // コネクション提案日
              if (filter.order === 'createdAt') return dayjs(a.executionAt).diff(dayjs(b.executionAt), 'second')
              else if (filter.order === 'orderScore') return a.orderScore - b.orderScore
              else return 0
          })
        : undefined
    if (filter.sort === 'DESC') sortedConnections?.reverse()

    // 「会社名/氏名を確認」ボタンを表示するか
    const canDisplayOpenButton = (connection: ClientRealEstateConnectionListDto) => {
        // 会社名/氏名確認済みの場合は会社名/氏名確認ボタンを押せない
        if (connection.memberName || connection.memberCompanyName) return false

        return true
    }

    // 「会社名/氏名を確認」ボタンを押下可能状態にするか
    const canClickOpenButton = () => {
        // 紹介権限がない場合はボタンを押下できない
        if (!user?.roles.intermediary || user?.roles.intermediary <= 1) return false

        // オープン件数上限に達している場合は押下できない
        if (!connectionsData?.availableOpenCount || connectionsData.availableOpenCount <= 0) return false

        return true
    }

    // 会社名/氏名を確認ボタンハンドラ
    const openNameButtonHandler = (connection: ClientRealEstateConnectionListDto) => {
        setVisibleSync(true)
        setOpenNameConfirmConnection(connection)
    }

    // 会社名/氏名確認処理
    const openName = async () => {
        try {
            setErrors([])
            if (!openNameConfirmConnection || !openNameConfirmConnection.uuid) {
                throw new ApplicationError(
                    t('CRealestateDetailConnection.会社名/氏名を確認するコネクションデータが選択されていません。'),
                )
            }
            await connectionOpenAPI({ uuid: openNameConfirmConnection.uuid! }).unwrap()
            toast.success(t('CRealestateDetailConnection.コネクション先の会社名/氏名を表示しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleSync(false)
            setOpenNameConfirmConnection(undefined)
        }
    }

    // 物件紹介ボタンを押せるか
    const canClickPublishButton = (connection: ClientRealEstateConnectionListDto) => {
        // 契約なし
        if (!isConnectionContract) return false

        // 会社名/氏名未確認の場合は物件紹介ボタンを押せない
        if (!(connection.memberName || connection.memberCompanyName)) return false

        // レコメンドメンバーが削除済みメンバーの場合、物件紹介ボタンではなく退会済みボタンとなるので、押せなくする
        if (connection.isCancelledMember) return false

        // 紹介権限がない場合は物件紹介ボタンを押せない
        if (!user?.roles.intermediary || user?.roles.intermediary <= 1) return false

        return true
    }

    const buttonDom = (connection: ClientRealEstateConnectionListDto) => {
        if (canDisplayOpenButton(connection)) {
            return (
                <CButton
                    className={clsx('c-button-primary', 'whitespace-nowrap')}
                    disabled={!isConnectionContract || !canClickOpenButton()}
                    onClick={() => {
                        openNameButtonHandler(connection)
                    }}>
                    {t('CRealestateDetailConnection.会社名/氏名を確認')}
                </CButton>
            )
        } else if (!connection.intermediaryAt) {
            // 会社名/氏名はopen状態だが情報開示をまだしていない時のみボタンを出す
            return (
                <CButton
                    className={clsx('c-button-primary', 'whitespace-nowrap')}
                    disabled={!isConnectionContract || !canClickPublishButton(connection)}
                    onClick={() => {
                        navigate({
                            pathname: `/realestate/${realEstate.uuid}/intermediary`,
                            search: createSearchParams({
                                connections: [connection.uuid ?? ''],
                            }).toString(),
                        })
                    }}>
                    {connection.isCancelledMember
                        ? t('CRealestateDetailConnection.退会済み')
                        : t('CRealestateDetailConnection.物件紹介')}
                </CButton>
            )
        }
    }

    const description = () => {
        if (realEstate.markets?.length && !isLoadingConnections) {
            if (connectionsData?.count) {
                if (isConnectionContract) {
                    // 現在もレコメンド契約がある場合
                    return (
                        <div className={clsx('text-center')}>
                            <div>
                                {t('CRealestateDetailConnection.あなたの物件に興味を持ちそうな新規のお客様がcount人います。', {
                                    count: connectionsData?.count,
                                })}
                            </div>
                            <div>{t('CRealestateDetailConnection.会社名/氏名を確認して物件を紹介してみましょう。')}</div>
                        </div>
                    )
                } else {
                    // 現在はレコメンド契約がなくなっている場合
                    return (
                        <div className={clsx('text-center')}>
                            <div>
                                {t('CRealestateDetailConnection.あなたの物件に興味を持ちそうな新規のお客様がcount人います。', {
                                    count: connectionsData?.count,
                                })}
                            </div>
                        </div>
                    )
                }
            } else {
                // レコメンドオプトインONだがコネクション件数0件の場合
                if (realEstate.isRecommendTarget) {
                    return (
                        <div className={clsx('text-center')}>
                            <div>
                                {t(
                                    'CRealestateDetailConnection.あなたの物件に興味を持ちそうな新規のお客様はまだ見つかっていません。',
                                )}
                            </div>
                            <div>
                                {t(
                                    'CRealestateDetailConnection.物件概要で未入力の項目を入力することでコネクションが増えることがあります。',
                                )}
                            </div>
                        </div>
                    )
                }
            }
        }
    }
    const openCount: number = connectionsData?.availableOpenCount ?? 0

    return (
        <div>
            {/* 冒頭文言 */}
            {description()}
            <CError errors={errors} />
            {!isConnectionContract ? (
                // コネクション契約なし
                <div className={clsx('text-center', 'bg-white', 'm-4')}>
                    <CInquiryButton>
                        <p>{t('CRealestateDetailConnection.ご契約のプランではコネクションはお使いいただけません')}</p>
                    </CInquiryButton>
                </div>
            ) : !realEstate.isRecommendTarget ? (
                // コネクション未設定
                <div className={clsx('mt-4')}>
                    <CMessage warning>
                        <div className={clsx('flex', 'justify-between', 'items-center')}>
                            <div>
                                {t('CRealestateDetailConnection.この物件はレコメンド／コネクション対象物件に設定されていません')}
                            </div>
                            <div>
                                <CButton
                                    disabled={user?.roles.realestate !== 2}
                                    className={clsx('text-sm', 'c-button-primary')}
                                    onClick={() => {
                                        setOpenEditModal(true)
                                    }}>
                                    {t('CRealestateDetailConnection.コネクション利用の設定')}
                                </CButton>
                            </div>
                        </div>
                    </CMessage>
                </div>
            ) : isLoadingConnections ? (
                // 読み込み中
                <div className={clsx('mt-4')}>
                    <CMessage info>{t('CRealestateDetailConnection.コネクションを読込中です')}</CMessage>
                </div>
            ) : connectionsData?.count === 0 ? (
                // コネクション0件
                <div className={clsx('mt-4')}>
                    <CMessage info>{t('CRealestateDetailConnection.コネクションはありません')}</CMessage>
                </div>
            ) : (
                // コネクションあり
                <div>
                    {/* 残件数表示 */}
                    <div className={clsx('text-center', 'border', 'border-kimar-primary', 'rounded', 'p-2', 'bg-white', 'm-2')}>
                        <div className={clsx('text-sm')}>
                            {connectionsData?.availableOpenCount ? (
                                <Trans
                                    i18nKey="CRealestateDetailConnection.あとopenCount件会社名/氏名を表示できます"
                                    openCount={openCount}>
                                    あと
                                    <span className={clsx('text-2xl')}>
                                        <>{{ openCount }}</>
                                    </span>
                                    件<span className={clsx('text-kimar-primary', 'font-bold')}>会社名/氏名を表示</span>
                                    できます
                                </Trans>
                            ) : (
                                t('CRealestateDetailConnection.会社名/氏名の確認可能件数が上限に達しました')
                            )}
                        </div>
                        <div className={clsx('text-xs', 'text-gray-700')}>
                            {t(
                                'CRealestateDetailConnection.会社名/氏名の確認可能件数は会社全体で共有され、毎週月曜日に更新されます。',
                            )}
                        </div>
                    </div>
                    {/* ソートボタン */}
                    <div className={clsx('flex', 'items-center')}>
                        <div className={clsx('flex')}>
                            <div className={clsx('p-2')}>
                                <CSortButton
                                    className={clsx('border-gray-300')}
                                    items={[
                                        {
                                            label: t('CRealestateDetailConnection.コネクション提案日'),
                                            value: 'createdAt',
                                        },
                                        {
                                            label: t('CRealestateDetailConnection.マッチ度'),
                                            value: 'orderScore',
                                        },
                                    ]}
                                    sort={filter.sort}
                                    onChange={(v) => {
                                        onChange(v as typeof filter.order)
                                    }}
                                    onClickSort={onChangeSort}
                                />
                            </div>
                        </div>
                    </div>
                    <ul>
                        {sortedConnections?.map((connection, index) => (
                            <li key={index} className={clsx('m-2', 'border-t')}>
                                {/* 顧客名・タグ・ボタン */}
                                <div className={clsx('flex', 'justify-between', 'items-center', 'pt-2')}>
                                    {/* 顧客名・タグ */}
                                    <div className={clsx('flex')}>
                                        {connection.memberName && connection.memberCompanyName ? (
                                            // 開示済み、または顧客登録済の場合の表示
                                            <div className={clsx('leading-5', 'break-all')}>
                                                {connection.memberName} ({connection.memberCompanyName})
                                                {connection.isRegisteredCustomer && (
                                                    <CTag
                                                        className={clsx(
                                                            'rounded-md',
                                                            'bg-[#EA580C]',
                                                            'mx-1',
                                                            'px-2',
                                                            'text-white',
                                                            'text-xs',
                                                        )}>
                                                        {t('CRealestateDetailConnection.顧客登録済')}
                                                    </CTag>
                                                )}
                                                {!connection.checkedAt && (
                                                    <CTag
                                                        className={clsx(
                                                            'rounded-full',
                                                            'bg-green-500',
                                                            'px-2',
                                                            'text-white',
                                                            'text-xs',
                                                        )}>
                                                        {t('CRealestateDetailConnection.NEW')}
                                                    </CTag>
                                                )}
                                            </div>
                                        ) : (
                                            // 未開示の場合の表示
                                            <div className={clsx('leading-5', 'break-all')}>
                                                <div className={clsx('inline-flex', 'whitespace-nowrap')}>
                                                    <img
                                                        src="/src/assets/image/page/market/hide_member_name.png"
                                                        alt="ダミー会社名/氏名"
                                                        className={clsx('w-1/2')}
                                                    />
                                                    <div className={clsx('align-middle')}>
                                                        <i
                                                            className={clsx(
                                                                'material-icons',
                                                                'inline-flex',
                                                                'align-middle',
                                                                'text-kimar-accent',
                                                            )}>
                                                            lock
                                                        </i>
                                                        {t('CRealestateDetailConnection.会社名/氏名 非表示中')}
                                                    </div>
                                                </div>
                                                {!connection.checkedAt && (
                                                    <CTag
                                                        className={clsx(
                                                            'rounded-full',
                                                            'bg-green-500',
                                                            'px-2',
                                                            'text-white',
                                                            'text-xs',
                                                        )}>
                                                        {t('CRealestateDetailConnection.NEW')}
                                                    </CTag>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {/* ボタン */}
                                    <div>{buttonDom(connection)}</div>
                                </div>
                                {/* 日時表示 */}
                                <div className={clsx('pb-2')}>
                                    {t('CRealestateDetailConnection.物件紹介日時')}:{' '}
                                    {connection.intermediaryAt
                                        ? formatDateTime(connection.intermediaryAt, 'dateTime')
                                        : t('CRealestateDetailConnection.未紹介')}
                                </div>
                                {/* 購入条件表 */}
                                <div className={clsx('grid', 'grid-cols-2', 'sm:grid-cols-4', 'gap-y-1', 'text-sm', 'p-1')}>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailConnection.種別')}</div>
                                    <div className={clsx('sm:col-span-3', 'border-b')}>
                                        <span>
                                            {connection.purchaseCondition?.useType
                                                .map(
                                                    (u) =>
                                                        (useTypeListCount?.list as RealEstateUseTypeEntities[] | undefined)?.find(
                                                            (t) => t.uuid === u,
                                                        )?.name,
                                                )
                                                .sort()
                                                .join('、')}
                                        </span>
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailConnection.エリア')}</div>
                                    <div className={clsx('sm:col-span-3', 'border-b')}>
                                        <span>
                                            {connection.purchaseCondition?.area
                                                .map((typeNumber) => areaTypeFormat(typeNumber))
                                                .join('、')}
                                        </span>
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailConnection.金額')}</div>
                                    <div className={clsx('border-b')}>
                                        {connection.purchaseCondition?.prices
                                            ?.map((typeNumber) => customerPriceTypeFormat(typeNumber))
                                            .join('、')}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailConnection.築年数')}</div>
                                    <div className={clsx('border-b')}>
                                        {customerBuildingAgeTypeFormat(connection.purchaseCondition?.buildingAgeType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>
                                        {t('CRealestateDetailConnection.表面利回り')}
                                    </div>
                                    <div className={clsx('border-b')}>
                                        {customerGrossRateTypeFormat(connection.purchaseCondition?.grossRateType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>
                                        {t('CRealestateDetailConnection.実質利回り')}
                                    </div>
                                    <div className={clsx('border-b')}>
                                        {customerNetRateTypeFormat(connection.purchaseCondition?.netRateType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>
                                        {t('CRealestateDetailConnection.購入時期')}
                                    </div>
                                    <div className={clsx('border-b')}>
                                        {saleTimeTypeFormat(connection.purchaseCondition?.purchaseTimeType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>
                                        {t('CRealestateDetailConnection.耐震基準')}
                                    </div>
                                    <div className={clsx('border-b')}>
                                        {customerSeismicStandardTypeFormat(connection.purchaseCondition?.seismicStandardType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailConnection.遵法性')}</div>
                                    <div className={clsx('border-b')}>
                                        {customerLegalComplianceTypeFormat(connection.purchaseCondition?.legalComplianceType)}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <CConfirmModal
                visible={!!visibleSync}
                confirmLabel={t('Button.送信')}
                onRequestClose={() => {
                    setVisibleSync(false)
                    setOpenNameConfirmConnection(undefined)
                }}
                onRequestConfirm={() => openName()}>
                <p>{t('CRealestateDetailConnection.会社名/氏名を表示します。表示には残り回数を使用します。よろしいですか？')}</p>
                <p>
                    {t('CRealestateDetailConnection.(会社名/氏名 確認可能件数 残りcount件)', {
                        count: connectionsData?.availableOpenCount,
                    })}
                </p>
            </CConfirmModal>
        </div>
    )
}

export default CRealestateDetailConnection
