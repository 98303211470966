import clsx from 'clsx'
import { t } from 'i18next'

import { CustomerEntities, RealEstateEntities, useClientCustomerRecommendQuery } from '~/app/api'
import CInquiryButton from '~/components/common/cInquiryButton/CInquiryButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { selectLoginUserHaveRecommendContract } from '~/util/store/userSlice'

import { useAppSelector } from '../../../../util/store/hooks'
import CCustomerDetailRecommendListItem from './CCustomerDetailRecommendListItem'

type Props = {
    customer: CustomerEntities
}

const CCustomerDetailRecommend = ({ customer }: Props) => {
    const isRecommendFeature = useAppSelector(selectLoginUserHaveRecommendContract)

    const { data: realEstateList, isSuccess } = useClientCustomerRecommendQuery({ customer: customer.uuid })

    if (!isSuccess) return <div>Loading...</div>
    return (
        <div className={clsx('border', 'rounded', 'mt-2')}>
            <div className={clsx('p-4', 'text-gray-700', 'font-body', 'text-sm')}>
                {isRecommendFeature ? (
                    <div>
                        {customer.isRecommendTarget ? (
                            <>
                                {!realEstateList?.list?.length ? (
                                    <CMessage info>
                                        {t('CCustomerDetailRecommend.この顧客が興味を持ちそうな物件はありませんでした')}
                                    </CMessage>
                                ) : (
                                    <>
                                        <div className={clsx('w-full', 'text-center')}>
                                            {t('CCustomerDetailRecommend.この顧客が興味を持ちそうな物件がcount件あります。', {
                                                count: realEstateList.list.length,
                                            })}
                                        </div>
                                        {(realEstateList.list as RealEstateEntities[]).map((item, i) => (
                                            <div key={i} className={clsx('w-full')}>
                                                <CCustomerDetailRecommendListItem realEstate={item} customer={customer} />
                                            </div>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <CMessage info>{t('CCustomerDetailRecommend.この顧客はレコメンド対象外です')}</CMessage>
                        )}
                    </div>
                ) : (
                    <div className={clsx('text-center')}>
                        <CInquiryButton>
                            <p>{t('CCustomerDetailRecommend.現在のプランではキマールレコメンド機能を使うことはできません。')}</p>
                            <p>
                                {t(
                                    'CCustomerDetailRecommend.レコメンド機能を使うためにはプランのアップグレードが必要となります。',
                                )}
                            </p>
                            <p>
                                {t(
                                    'CCustomerDetailRecommend.レコメンド機能を使うと、AIマッチングにより現在登録している物件と顧客の最適な組み合わせを知ることができます。',
                                )}
                            </p>
                        </CInquiryButton>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CCustomerDetailRecommend
