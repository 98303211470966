import { FallbackRender } from '@sentry/react'
import clsx from 'clsx'
import { t } from 'i18next'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import { CButton } from '~/components/common/cButton/CButton'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import SiteFooter from '~/components/global/gFooter/SiteFooter'
import SiteHeader from '~/components/global/gHeader/SiteHeader'
import { pageTitleTemplate } from '~/util/common/common'

const Error: FallbackRender = ({ error, resetError }) => (
    <div className={clsx('min-h-screen', 'flex', 'flex-col', 'text-gray-700', 'bg-gray-50')}>
        <SiteHeader />
        <div className={clsx('p-8', 'h-full')}>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Error.エラーが発生しました')}</title>
            </Helmet>
            <div className={'max-w-xl mx-auto bg-white rounded p-8 flex flex-col space-y-4'}>
                <CHeader label={t('Error.エラーが発生しました')} />
                <CMessage danger>
                    {error.message ? (
                        <p>{error.message}</p>
                    ) : (
                        <p>{t('Error.しばらくしてからもう一度お試しください。繰り返し発生する場合はお問い合わせください。')}</p>
                    )}
                </CMessage>
                <CButton className={clsx('c-button-primary')} onClick={resetError}>
                    {t('Error.再試行')}
                </CButton>
                <Link to="/">
                    <CButton className={clsx('c-button-secondary', 'w-full')}>{t('Error.トップページへ')}</CButton>
                </Link>
            </div>
        </div>
        <SiteFooter />
    </div>
)

export default Error
