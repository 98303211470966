import { apiBase as api } from './apiBase'
export const addTagTypes = [
    'ClientCompanyController',
    'ClientConditionRequestController',
    'ClientCustomerAssigneeController',
    'ClientCustomerController',
    'ClientCustomerIntermediaryController',
    'ClientCustomerLabelController',
    'ClientCustomerMemoController',
    'ClientDashboardAnalyticsController',
    'ClientDownloadController',
    'ClientFaqController',
    'ClientFileController',
    'ClientInquiryController',
    'ClientIntermediaryController',
    'ClientIntermediaryVoteController',
    'ClientMediaController',
    'ClientNotificationTemplateController',
    'ClientPageViewController',
    'ClientRealEstateAssigneeController',
    'ClientRealEstateConnectionController',
    'ClientRealEstateController',
    'ClientRealEstateDocumentController',
    'ClientRealEstateDocumentLabelController',
    'ClientRealEstateLabelController',
    'ClientRealEstateMemoController',
    'ClientRealEstateRecommendController',
    'ClientTeamController',
    'ClientTeamMemberConditionController',
    'ClientTeamMemberController',
    'ClientUserController',
    'ClientTeamMemberSignatureController',
    'ClientRealEstateUseTypeController',
    'ClientIntermediaryToController',
    'ClientTeamActivityController',
    'ClientIntermediaryDocumentController',
    'ClientRealEstateEndedRealEstateController',
    'ClientKpiController',
    'ClientCustomerFilterController',
    'ClientAuthController',
    'AdminCompanyContractController',
    'AdminCompanyController',
    'AdminDownloadController',
    'AdminFaqController',
    'AdminFileController',
    'AdminInquiryController',
    'AdminMediaController',
    'AdminNotificationTemplateController',
    'AdminTeamController',
    'AdminTeamMemberController',
    'AdminPlanController',
    'AdminReportTemplateController',
] as const
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            appGetHc: build.query<AppGetHcApiResponse, AppGetHcApiArg>({
                query: () => ({ url: `/api/hc` }),
            }),
            clientCompanyPutUuid: build.mutation<ClientCompanyPutUuidApiResponse, ClientCompanyPutUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/company/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.companyUpdateDto,
                }),
                invalidatesTags: ['ClientCompanyController'],
            }),
            clientCompanyPutUuidLogo: build.mutation<ClientCompanyPutUuidLogoApiResponse, ClientCompanyPutUuidLogoApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/company/${queryArg.uuid}/logo`,
                    method: 'PUT',
                    body: queryArg.companyUpdateLogoDto,
                }),
                invalidatesTags: ['ClientCompanyController'],
            }),
            clientCompanyDeleteUuidLogo: build.mutation<
                ClientCompanyDeleteUuidLogoApiResponse,
                ClientCompanyDeleteUuidLogoApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/company/${queryArg.uuid}/logo`, method: 'DELETE' }),
                invalidatesTags: ['ClientCompanyController'],
            }),
            clientCompanyPutUuidBand: build.mutation<ClientCompanyPutUuidBandApiResponse, ClientCompanyPutUuidBandApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/company/${queryArg.uuid}/band`,
                    method: 'PUT',
                    body: queryArg.companyUpdateRealEstateDetailBandDto,
                }),
                invalidatesTags: ['ClientCompanyController'],
            }),
            clientCompanyDeleteUuidBand: build.mutation<
                ClientCompanyDeleteUuidBandApiResponse,
                ClientCompanyDeleteUuidBandApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/company/${queryArg.uuid}/band`, method: 'DELETE' }),
                invalidatesTags: ['ClientCompanyController'],
            }),
            clientCompanyPutUuidRealEstateListKeys: build.mutation<
                ClientCompanyPutUuidRealEstateListKeysApiResponse,
                ClientCompanyPutUuidRealEstateListKeysApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/company/${queryArg.uuid}/real_estate_list_keys`,
                    method: 'PUT',
                    body: queryArg.companyUpdateRealEstateListKeysDto,
                }),
                invalidatesTags: ['ClientCompanyController'],
            }),
            clientConditionRequestGetConditionRequest: build.query<
                ClientConditionRequestGetConditionRequestApiResponse,
                ClientConditionRequestGetConditionRequestApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/condition_request/${queryArg.uuid}` }),
                providesTags: ['ClientConditionRequestController'],
            }),
            clientConditionRequestPostConditionRequest: build.mutation<
                ClientConditionRequestPostConditionRequestApiResponse,
                ClientConditionRequestPostConditionRequestApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/condition_request/${queryArg.cuuid}/condition_request`,
                    method: 'POST',
                }),
                invalidatesTags: ['ClientConditionRequestController'],
            }),
            clientConditionRequestPutUuid: build.mutation<
                ClientConditionRequestPutUuidApiResponse,
                ClientConditionRequestPutUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/condition_request/${queryArg.cuuid}/condition_request/${queryArg.ruuid}`,
                    method: 'PUT',
                    body: queryArg.conditionRequestUpdateDto,
                }),
                invalidatesTags: ['ClientConditionRequestController'],
            }),
            clientCustomerAssigneePostCustomerAssignee: build.mutation<
                ClientCustomerAssigneePostCustomerAssigneeApiResponse,
                ClientCustomerAssigneePostCustomerAssigneeApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_assignee`,
                    method: 'POST',
                    body: queryArg.clientCustomerAssigneeInsertDto,
                }),
                invalidatesTags: ['ClientCustomerAssigneeController'],
            }),
            clientCustomerAssigneePutMultiEdit: build.mutation<
                ClientCustomerAssigneePutMultiEditApiResponse,
                ClientCustomerAssigneePutMultiEditApiArg
            >({
                query: () => ({ url: `/api/client/customer_assignee/multi_edit`, method: 'PUT' }),
                invalidatesTags: ['ClientCustomerAssigneeController'],
            }),
            clientCustomerAssigneePutUuid: build.mutation<
                ClientCustomerAssigneePutUuidApiResponse,
                ClientCustomerAssigneePutUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_assignee/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.clientCustomerAssigneeUpdateDto,
                }),
                invalidatesTags: ['ClientCustomerAssigneeController'],
            }),
            clientCustomerAssigneeDeleteUuid: build.mutation<
                ClientCustomerAssigneeDeleteUuidApiResponse,
                ClientCustomerAssigneeDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/customer_assignee/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientCustomerAssigneeController'],
            }),
            clientCustomerCustomer: build.query<ClientCustomerCustomerApiResponse, ClientCustomerCustomerApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/customer`,
                    params: {
                        page: queryArg.page,
                        maxCount: queryArg.maxCount,
                        name: queryArg.name,
                        order: queryArg.order,
                        filter: queryArg.filter,
                        useTypes: queryArg.useTypes,
                        prices: queryArg.prices,
                        areas: queryArg.areas,
                        buildingAges: queryArg.buildingAges,
                        saleTimes: queryArg.saleTimes,
                        registeredTypes: queryArg.registeredTypes,
                        assignedToType: queryArg.assignedToType,
                        assignedToUuidList: queryArg.assignedToUuidList,
                        labelType: queryArg.labelType,
                        customerUuidList: queryArg.customerUuidList,
                        labelUuidList: queryArg.labelUuidList,
                        realEstate: queryArg.realEstate,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['ClientCustomerController'],
            }),
            clientCustomerPostCustomer: build.mutation<ClientCustomerPostCustomerApiResponse, ClientCustomerPostCustomerApiArg>({
                query: (queryArg) => ({ url: `/api/client/customer`, method: 'POST', body: queryArg.clientCustomerInsertDto }),
                invalidatesTags: ['ClientCustomerController'],
            }),
            clientCustomerPutMultiple: build.mutation<ClientCustomerPutMultipleApiResponse, ClientCustomerPutMultipleApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/customer/multiple`,
                    method: 'PUT',
                    body: queryArg.clientCustomerMultipleDto,
                }),
                invalidatesTags: ['ClientCustomerController'],
            }),
            clientCustomerRecommend: build.query<ClientCustomerRecommendApiResponse, ClientCustomerRecommendApiArg>({
                query: (queryArg) => ({ url: `/api/client/customer/recommend`, params: { customer: queryArg.customer } }),
                providesTags: ['ClientCustomerController'],
            }),
            clientCustomerExport: build.query<ClientCustomerExportApiResponse, ClientCustomerExportApiArg>({
                query: () => ({ url: `/api/client/customer/export` }),
                providesTags: ['ClientCustomerController'],
            }),
            clientCustomerPreImport: build.mutation<ClientCustomerPreImportApiResponse, ClientCustomerPreImportApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/customer/pre-import`,
                    method: 'POST',
                    body: queryArg.clientCustomerImportRequestDto,
                }),
                invalidatesTags: ['ClientCustomerController'],
            }),
            clientCustomerImport: build.mutation<ClientCustomerImportApiResponse, ClientCustomerImportApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/customer/import`,
                    method: 'POST',
                    body: queryArg.clientCustomerImportRequestDto,
                    params: { importType: queryArg.importType },
                }),
                invalidatesTags: ['ClientCustomerController'],
            }),
            clientCustomerUuid: build.query<ClientCustomerUuidApiResponse, ClientCustomerUuidApiArg>({
                query: (queryArg) => ({ url: `/api/client/customer/${queryArg.uuid}` }),
                providesTags: ['ClientCustomerController'],
            }),
            clientCustomerPutUuid: build.mutation<ClientCustomerPutUuidApiResponse, ClientCustomerPutUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/customer/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.clientCustomerUpdateDto,
                }),
                invalidatesTags: ['ClientCustomerController'],
            }),
            clientCustomerDeleteUuid: build.mutation<ClientCustomerDeleteUuidApiResponse, ClientCustomerDeleteUuidApiArg>({
                query: (queryArg) => ({ url: `/api/client/customer/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientCustomerController'],
            }),
            clientCustomerPutUuidLabels: build.mutation<
                ClientCustomerPutUuidLabelsApiResponse,
                ClientCustomerPutUuidLabelsApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer/${queryArg.uuid}/labels`,
                    method: 'PUT',
                    body: queryArg.clientCustomerLabelUpdateDto,
                }),
                invalidatesTags: ['ClientCustomerController'],
            }),
            clientCustomerIntermediaryCustomerUuid: build.query<
                ClientCustomerIntermediaryCustomerUuidApiResponse,
                ClientCustomerIntermediaryCustomerUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/customer_intermediary/customer/${queryArg.uuid}` }),
                providesTags: ['ClientCustomerIntermediaryController'],
            }),
            clientCustomerIntermediaryPostFinish: build.mutation<
                ClientCustomerIntermediaryPostFinishApiResponse,
                ClientCustomerIntermediaryPostFinishApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_intermediary/finish`,
                    method: 'POST',
                    body: queryArg.clientIntermediaryFinishDto,
                }),
                invalidatesTags: ['ClientCustomerIntermediaryController'],
            }),
            clientCustomerIntermediaryPostReviewFinish: build.mutation<
                ClientCustomerIntermediaryPostReviewFinishApiResponse,
                ClientCustomerIntermediaryPostReviewFinishApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_intermediary/review_finish`,
                    method: 'POST',
                    body: queryArg.clientIntermediaryReviewFinishDto,
                }),
                invalidatesTags: ['ClientCustomerIntermediaryController'],
            }),
            clientCustomerIntermediaryPostReviewFinishGuest: build.mutation<
                ClientCustomerIntermediaryPostReviewFinishGuestApiResponse,
                ClientCustomerIntermediaryPostReviewFinishGuestApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_intermediary/review_finish_guest`,
                    method: 'POST',
                    body: queryArg.clientIntermediaryReviewFinishDto,
                }),
                invalidatesTags: ['ClientCustomerIntermediaryController'],
            }),
            clientCustomerLabelPostCustomerLabel: build.mutation<
                ClientCustomerLabelPostCustomerLabelApiResponse,
                ClientCustomerLabelPostCustomerLabelApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_label`,
                    method: 'POST',
                    body: queryArg.clientCustomerLabelEditDto,
                }),
                invalidatesTags: ['ClientCustomerLabelController'],
            }),
            clientCustomerLabelPutCustomerLabelOrder: build.mutation<
                ClientCustomerLabelPutCustomerLabelOrderApiResponse,
                ClientCustomerLabelPutCustomerLabelOrderApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_label/order`,
                    method: 'PUT',
                    body: queryArg.clientCustomerLabelOrderDto,
                }),
                invalidatesTags: ['ClientCustomerLabelController'],
            }),
            clientCustomerLabelPutUuid: build.mutation<ClientCustomerLabelPutUuidApiResponse, ClientCustomerLabelPutUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/customer_label/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.clientCustomerLabelEditDto,
                }),
                invalidatesTags: ['ClientCustomerLabelController'],
            }),
            clientCustomerLabelDeleteUuid: build.mutation<
                ClientCustomerLabelDeleteUuidApiResponse,
                ClientCustomerLabelDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/customer_label/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientCustomerLabelController'],
            }),
            clientCustomerLabelIsUsing: build.query<ClientCustomerLabelIsUsingApiResponse, ClientCustomerLabelIsUsingApiArg>({
                query: (queryArg) => ({ url: `/api/client/customer_label/is_using/${queryArg.uuid}` }),
                providesTags: ['ClientCustomerLabelController'],
            }),
            clientCustomerLabelTeamUuid: build.query<ClientCustomerLabelTeamUuidApiResponse, ClientCustomerLabelTeamUuidApiArg>({
                query: (queryArg) => ({ url: `/api/client/customer_label/team/${queryArg.uuid}` }),
                providesTags: ['ClientCustomerLabelController'],
            }),
            clientCustomerLabelPutLabelUuidSortUuid: build.mutation<
                ClientCustomerLabelPutLabelUuidSortUuidApiResponse,
                ClientCustomerLabelPutLabelUuidSortUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_label/sort/${queryArg.labelUuid}`,
                    method: 'PUT',
                    params: { action: queryArg.action },
                }),
                invalidatesTags: ['ClientCustomerLabelController'],
            }),
            clientCustomerMemoPostCustomerMemo: build.mutation<
                ClientCustomerMemoPostCustomerMemoApiResponse,
                ClientCustomerMemoPostCustomerMemoApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_memo`,
                    method: 'POST',
                    body: queryArg.clientCustomerMemoInsertDto,
                }),
                invalidatesTags: ['ClientCustomerMemoController'],
            }),
            clientCustomerMemoDeleteUuid: build.mutation<
                ClientCustomerMemoDeleteUuidApiResponse,
                ClientCustomerMemoDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/customer_memo/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientCustomerMemoController'],
            }),
            clientDashboardAnalyticsDashboardAnalytics: build.query<
                ClientDashboardAnalyticsDashboardAnalyticsApiResponse,
                ClientDashboardAnalyticsDashboardAnalyticsApiArg
            >({
                query: () => ({ url: `/api/client/dashboard_analytics` }),
                providesTags: ['ClientDashboardAnalyticsController'],
            }),
            clientDownloadPostDownload: build.mutation<ClientDownloadPostDownloadApiResponse, ClientDownloadPostDownloadApiArg>({
                query: (queryArg) => ({ url: `/api/client/download`, method: 'POST', body: queryArg.downloadExecuteDto }),
                invalidatesTags: ['ClientDownloadController'],
            }),
            clientFaqGetFaqList: build.query<ClientFaqGetFaqListApiResponse, ClientFaqGetFaqListApiArg>({
                query: (queryArg) => ({ url: `/api/client/faq`, params: { home: queryArg.home, page: queryArg.page } }),
                providesTags: ['ClientFaqController'],
            }),
            clientFileGetOneFileDetail: build.query<ClientFileGetOneFileDetailApiResponse, ClientFileGetOneFileDetailApiArg>({
                query: (queryArg) => ({ url: `/api/client/file/${queryArg.uuid}` }),
                providesTags: ['ClientFileController'],
            }),
            clientFileGetPreSignedUrl: build.mutation<ClientFileGetPreSignedUrlApiResponse, ClientFileGetPreSignedUrlApiArg>({
                query: (queryArg) => ({ url: `/api/client/file`, method: 'POST', body: queryArg.mediaFileUploadDto }),
                invalidatesTags: ['ClientFileController'],
            }),
            clientFileSetFileSize: build.mutation<ClientFileSetFileSizeApiResponse, ClientFileSetFileSizeApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/file/set_file_size`,
                    method: 'POST',
                    body: queryArg.mediaFileUploadSetSizeDto,
                }),
                invalidatesTags: ['ClientFileController'],
            }),
            clientInquiryPostInquiry: build.mutation<ClientInquiryPostInquiryApiResponse, ClientInquiryPostInquiryApiArg>({
                query: (queryArg) => ({ url: `/api/client/inquiry`, method: 'POST', body: queryArg.inquiryInsertDto }),
                invalidatesTags: ['ClientInquiryController'],
            }),
            clientInquiryPostInquirySimple: build.mutation<
                ClientInquiryPostInquirySimpleApiResponse,
                ClientInquiryPostInquirySimpleApiArg
            >({
                query: () => ({ url: `/api/client/inquiry/simple`, method: 'POST' }),
                invalidatesTags: ['ClientInquiryController'],
            }),
            clientIntermediaryPostIntermediary: build.mutation<
                ClientIntermediaryPostIntermediaryApiResponse,
                ClientIntermediaryPostIntermediaryApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/intermediary`,
                    method: 'POST',
                    body: queryArg.clientIntermediaryInsertDto,
                }),
                invalidatesTags: ['ClientIntermediaryController'],
            }),
            clientIntermediaryPostPreview: build.mutation<
                ClientIntermediaryPostPreviewApiResponse,
                ClientIntermediaryPostPreviewApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/intermediary/preview`,
                    method: 'POST',
                    body: queryArg.clientIntermediaryDocumentPreviewDto,
                }),
                invalidatesTags: ['ClientIntermediaryController'],
            }),
            clientIntermediaryRealEstateUuid: build.query<
                ClientIntermediaryRealEstateUuidApiResponse,
                ClientIntermediaryRealEstateUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/intermediary/real-estate/${queryArg.uuid}` }),
                providesTags: ['ClientIntermediaryController'],
            }),
            clientIntermediaryVotePostIntermediaryVote: build.mutation<
                ClientIntermediaryVotePostIntermediaryVoteApiResponse,
                ClientIntermediaryVotePostIntermediaryVoteApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/intermediary_vote`,
                    method: 'POST',
                    body: queryArg.clientIntermediaryToVoteInsertDto,
                }),
                invalidatesTags: ['ClientIntermediaryVoteController'],
            }),
            clientMediaGetSignedUrl: build.mutation<ClientMediaGetSignedUrlApiResponse, ClientMediaGetSignedUrlApiArg>({
                query: (queryArg) => ({ url: `/api/client/media/signed`, method: 'POST', body: queryArg.valueDto }),
                invalidatesTags: ['ClientMediaController'],
            }),
            clientMediaGetFile: build.query<ClientMediaGetFileApiResponse, ClientMediaGetFileApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/media/${queryArg.uuid}`,
                    params: { width: queryArg.width, height: queryArg.height },
                }),
                providesTags: ['ClientMediaController'],
            }),
            clientMediaGetFileSignedUrl: build.query<ClientMediaGetFileSignedUrlApiResponse, ClientMediaGetFileSignedUrlApiArg>({
                query: (queryArg) => ({ url: `/api/client/media/signed/${queryArg.uuid}` }),
                providesTags: ['ClientMediaController'],
            }),
            clientNotificationTemplateNotificationTemplate: build.query<
                ClientNotificationTemplateNotificationTemplateApiResponse,
                ClientNotificationTemplateNotificationTemplateApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/notification_template`, params: { key: queryArg.key } }),
                providesTags: ['ClientNotificationTemplateController'],
            }),
            clientNotificationTemplateNotificationTemplateMypage: build.query<
                ClientNotificationTemplateNotificationTemplateMypageApiResponse,
                ClientNotificationTemplateNotificationTemplateMypageApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/notification_template/mypage`, params: { type: queryArg['type'] } }),
                providesTags: ['ClientNotificationTemplateController'],
            }),
            clientNotificationTemplatePutUuid: build.mutation<
                ClientNotificationTemplatePutUuidApiResponse,
                ClientNotificationTemplatePutUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/notification_template/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.clientNotificationTemplateUpdateDto,
                }),
                invalidatesTags: ['ClientNotificationTemplateController'],
            }),
            clientNotificationTemplateDeleteUuid: build.mutation<
                ClientNotificationTemplateDeleteUuidApiResponse,
                ClientNotificationTemplateDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/notification_template/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientNotificationTemplateController'],
            }),
            clientPageViewPageView: build.mutation<ClientPageViewPageViewApiResponse, ClientPageViewPageViewApiArg>({
                query: (queryArg) => ({ url: `/api/client/page_view`, method: 'POST', body: queryArg.clientPageViewInsertDto }),
                invalidatesTags: ['ClientPageViewController'],
            }),
            clientRealEstateAssigneePostRealEstateAssignee: build.mutation<
                ClientRealEstateAssigneePostRealEstateAssigneeApiResponse,
                ClientRealEstateAssigneePostRealEstateAssigneeApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_assignee`,
                    method: 'POST',
                    body: queryArg.clientRealEstateAssigneeInsertDto,
                }),
                invalidatesTags: ['ClientRealEstateAssigneeController'],
            }),
            clientRealEstateAssigneePutUuid: build.mutation<
                ClientRealEstateAssigneePutUuidApiResponse,
                ClientRealEstateAssigneePutUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_assignee/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.clientRealEstateAssigneeUpdateDto,
                }),
                invalidatesTags: ['ClientRealEstateAssigneeController'],
            }),
            clientRealEstateAssigneeDeleteUuid: build.mutation<
                ClientRealEstateAssigneeDeleteUuidApiResponse,
                ClientRealEstateAssigneeDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_assignee/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientRealEstateAssigneeController'],
            }),
            clientRealEstateConnectionRealEstateConnection: build.query<
                ClientRealEstateConnectionRealEstateConnectionApiResponse,
                ClientRealEstateConnectionRealEstateConnectionApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_connection/${queryArg.uuid}/connection`,
                    params: { connectionUuidList: queryArg.connectionUuidList },
                }),
                providesTags: ['ClientRealEstateConnectionController'],
            }),
            clientRealEstateConnectionPutCheck: build.mutation<
                ClientRealEstateConnectionPutCheckApiResponse,
                ClientRealEstateConnectionPutCheckApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_connection/${queryArg.uuid}/connection_check`,
                    method: 'PUT',
                }),
                invalidatesTags: ['ClientRealEstateConnectionController'],
            }),
            clientRealEstateConnectionPutOpen: build.mutation<
                ClientRealEstateConnectionPutOpenApiResponse,
                ClientRealEstateConnectionPutOpenApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_connection/${queryArg.uuid}/connection_open`,
                    method: 'PUT',
                }),
                invalidatesTags: ['ClientRealEstateConnectionController'],
            }),
            clientRealEstateRealEstate: build.query<ClientRealEstateRealEstateApiResponse, ClientRealEstateRealEstateApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/real_estate`,
                    params: {
                        page: queryArg.page,
                        name: queryArg.name,
                        pageSize: queryArg.pageSize,
                        order: queryArg.order,
                        filter: queryArg.filter,
                        useTypes: queryArg.useTypes,
                        prices: queryArg.prices,
                        buildingAges: queryArg.buildingAges,
                        saleTimes: queryArg.saleTimes,
                        grossRate: queryArg.grossRate,
                        salesDestination: queryArg.salesDestination,
                        assignedToType: queryArg.assignedToType,
                        assignedToUuidList: queryArg.assignedToUuidList,
                        labelType: queryArg.labelType,
                        labelUuidList: queryArg.labelUuidList,
                        progress: queryArg.progress,
                        topRightLat: queryArg.topRightLat,
                        topRightLng: queryArg.topRightLng,
                        bottomLeftLat: queryArg.bottomLeftLat,
                        bottomLeftLng: queryArg.bottomLeftLng,
                        acquiredAtFrom: queryArg.acquiredAtFrom,
                        acquiredAtTo: queryArg.acquiredAtTo,
                        sort: queryArg.sort,
                        isMap: queryArg.isMap,
                    },
                }),
                providesTags: ['ClientRealEstateController'],
            }),
            clientRealEstatePostRealEstate: build.mutation<
                ClientRealEstatePostRealEstateApiResponse,
                ClientRealEstatePostRealEstateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate`,
                    method: 'POST',
                    body: queryArg.clientRealEstateInsertDto,
                }),
                invalidatesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateGetMaxProgress: build.query<
                ClientRealEstateGetMaxProgressApiResponse,
                ClientRealEstateGetMaxProgressApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate/progress`,
                    params: { realEstateUuids: queryArg.realEstateUuids },
                }),
                providesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateHasNoReadTeamActivity: build.query<
                ClientRealEstateHasNoReadTeamActivityApiResponse,
                ClientRealEstateHasNoReadTeamActivityApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate/activity_status`,
                    params: { realEstateUuids: queryArg.realEstateUuids },
                }),
                providesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateGetNoReadChatActivity: build.query<
                ClientRealEstateGetNoReadChatActivityApiResponse,
                ClientRealEstateGetNoReadChatActivityApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate/activity_status/no_read/chat`,
                    params: { realEstateUuids: queryArg.realEstateUuids },
                }),
                providesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateUuid: build.query<ClientRealEstateUuidApiResponse, ClientRealEstateUuidApiArg>({
                query: (queryArg) => ({ url: `/api/client/real_estate/${queryArg.uuid}` }),
                providesTags: ['ClientRealEstateController'],
            }),
            clientRealEstatePutUuid: build.mutation<ClientRealEstatePutUuidApiResponse, ClientRealEstatePutUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/real_estate/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.clientRealEstateUpdateDto,
                }),
                invalidatesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateDeleteUuid: build.mutation<ClientRealEstateDeleteUuidApiResponse, ClientRealEstateDeleteUuidApiArg>({
                query: (queryArg) => ({ url: `/api/client/real_estate/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientRealEstateController'],
            }),
            clientRealEstatePutUuidLabels: build.mutation<
                ClientRealEstatePutUuidLabelsApiResponse,
                ClientRealEstatePutUuidLabelsApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate/${queryArg.uuid}/labels`,
                    method: 'PUT',
                    body: queryArg.clientRealEstateLabelUpdateDto,
                }),
                invalidatesTags: ['ClientRealEstateController'],
            }),
            clientRealEstatePostReport: build.mutation<ClientRealEstatePostReportApiResponse, ClientRealEstatePostReportApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/real_estate/${queryArg.uuid}/report`,
                    method: 'POST',
                    body: queryArg.clientRealEstateOutputDto,
                }),
                invalidatesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateMarketRequest: build.query<
                ClientRealEstateMarketRequestApiResponse,
                ClientRealEstateMarketRequestApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate/${queryArg.uuid}/market_request`,
                    params: { publishRequestUuidList: queryArg.publishRequestUuidList },
                }),
                providesTags: ['ClientRealEstateController'],
            }),
            clientRealEstatePostMarketRequest: build.mutation<
                ClientRealEstatePostMarketRequestApiResponse,
                ClientRealEstatePostMarketRequestApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate/${queryArg.uuid}/market_request`,
                    method: 'POST',
                    body: queryArg.clientRealEstatePublishRequestInsertDto,
                }),
                invalidatesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateRemovePublishMarket: build.mutation<
                ClientRealEstateRemovePublishMarketApiResponse,
                ClientRealEstateRemovePublishMarketApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate/${queryArg.uuid}/publish_market`, method: 'DELETE' }),
                invalidatesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateDeleteUuidOption: build.mutation<
                ClientRealEstateDeleteUuidOptionApiResponse,
                ClientRealEstateDeleteUuidOptionApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate/${queryArg.uuid}/option`,
                    method: 'DELETE',
                    params: { option: queryArg.option },
                }),
                invalidatesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateDocumentPostRealEstateDocument: build.mutation<
                ClientRealEstateDocumentPostRealEstateDocumentApiResponse,
                ClientRealEstateDocumentPostRealEstateDocumentApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_document`,
                    method: 'POST',
                    body: queryArg.clientRealEstateDocumentInsertDto,
                }),
                invalidatesTags: ['ClientRealEstateDocumentController'],
            }),
            clientRealEstateDocumentPutRealEstateDocumentOrder: build.mutation<
                ClientRealEstateDocumentPutRealEstateDocumentOrderApiResponse,
                ClientRealEstateDocumentPutRealEstateDocumentOrderApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_document/order`,
                    method: 'PUT',
                    body: queryArg.clientRealEstateDocumentOrderDto,
                }),
                invalidatesTags: ['ClientRealEstateDocumentController'],
            }),
            clientRealEstateDocumentPutUuid: build.mutation<
                ClientRealEstateDocumentPutUuidApiResponse,
                ClientRealEstateDocumentPutUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_document/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.body,
                }),
                invalidatesTags: ['ClientRealEstateDocumentController'],
            }),
            clientRealEstateDocumentDeleteUuid: build.mutation<
                ClientRealEstateDocumentDeleteUuidApiResponse,
                ClientRealEstateDocumentDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_document/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientRealEstateDocumentController'],
            }),
            clientRealEstateDocumentPutDocumentUuidSortUuid: build.mutation<
                ClientRealEstateDocumentPutDocumentUuidSortUuidApiResponse,
                ClientRealEstateDocumentPutDocumentUuidSortUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_document/sort/${queryArg.documentUuid}`,
                    method: 'PUT',
                    params: { action: queryArg.action },
                }),
                invalidatesTags: ['ClientRealEstateDocumentController'],
            }),
            clientRealEstateDocumentExport: build.query<
                ClientRealEstateDocumentExportApiResponse,
                ClientRealEstateDocumentExportApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_document/export/${queryArg.realEstateUuid}` }),
                providesTags: ['ClientRealEstateDocumentController'],
            }),
            clientRealEstateDocumentLabelPostRealEstateDocumentLabel: build.mutation<
                ClientRealEstateDocumentLabelPostRealEstateDocumentLabelApiResponse,
                ClientRealEstateDocumentLabelPostRealEstateDocumentLabelApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_document_label`,
                    method: 'POST',
                    body: queryArg.clientRealEstateDocumentLabelEditDto,
                }),
                invalidatesTags: ['ClientRealEstateDocumentLabelController'],
            }),
            clientRealEstateDocumentLabelPutRealEstateDocumentOrder: build.mutation<
                ClientRealEstateDocumentLabelPutRealEstateDocumentOrderApiResponse,
                ClientRealEstateDocumentLabelPutRealEstateDocumentOrderApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_document_label/order`,
                    method: 'PUT',
                    body: queryArg.clientRealEstateDocumentLabelOrderDto,
                }),
                invalidatesTags: ['ClientRealEstateDocumentLabelController'],
            }),
            clientRealEstateDocumentLabelPutUuid: build.mutation<
                ClientRealEstateDocumentLabelPutUuidApiResponse,
                ClientRealEstateDocumentLabelPutUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_document_label/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.clientRealEstateDocumentLabelEditDto,
                }),
                invalidatesTags: ['ClientRealEstateDocumentLabelController'],
            }),
            clientRealEstateDocumentLabelDeleteUuid: build.mutation<
                ClientRealEstateDocumentLabelDeleteUuidApiResponse,
                ClientRealEstateDocumentLabelDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_document_label/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientRealEstateDocumentLabelController'],
            }),
            clientRealEstateDocumentLabelIsUsing: build.query<
                ClientRealEstateDocumentLabelIsUsingApiResponse,
                ClientRealEstateDocumentLabelIsUsingApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_document_label/is_using/${queryArg.uuid}` }),
                providesTags: ['ClientRealEstateDocumentLabelController'],
            }),
            clientRealEstateDocumentLabelTeamUuid: build.query<
                ClientRealEstateDocumentLabelTeamUuidApiResponse,
                ClientRealEstateDocumentLabelTeamUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_document_label/team/${queryArg.uuid}` }),
                providesTags: ['ClientRealEstateDocumentLabelController'],
            }),
            clientRealEstateDocumentLabelPutLabelUuidSortUuid: build.mutation<
                ClientRealEstateDocumentLabelPutLabelUuidSortUuidApiResponse,
                ClientRealEstateDocumentLabelPutLabelUuidSortUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_document_label/sort/${queryArg.labelUuid}`,
                    method: 'PUT',
                    params: { action: queryArg.action },
                }),
                invalidatesTags: ['ClientRealEstateDocumentLabelController'],
            }),
            clientRealEstateLabelPostRealEstateLabel: build.mutation<
                ClientRealEstateLabelPostRealEstateLabelApiResponse,
                ClientRealEstateLabelPostRealEstateLabelApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_label`,
                    method: 'POST',
                    body: queryArg.clientRealEstateLabelEditDto,
                }),
                invalidatesTags: ['ClientRealEstateLabelController'],
            }),
            clientRealEstateLabelPutRealEstateLabelOrder: build.mutation<
                ClientRealEstateLabelPutRealEstateLabelOrderApiResponse,
                ClientRealEstateLabelPutRealEstateLabelOrderApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_label/order`,
                    method: 'PUT',
                    body: queryArg.clientRealEstateLabelOrderDto,
                }),
                invalidatesTags: ['ClientRealEstateLabelController'],
            }),
            clientRealEstateLabelPutUuid: build.mutation<
                ClientRealEstateLabelPutUuidApiResponse,
                ClientRealEstateLabelPutUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_label/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.clientRealEstateLabelEditDto,
                }),
                invalidatesTags: ['ClientRealEstateLabelController'],
            }),
            clientRealEstateLabelDeleteUuid: build.mutation<
                ClientRealEstateLabelDeleteUuidApiResponse,
                ClientRealEstateLabelDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_label/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientRealEstateLabelController'],
            }),
            clientRealEstateLabelIsUsing: build.query<
                ClientRealEstateLabelIsUsingApiResponse,
                ClientRealEstateLabelIsUsingApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_label/is_using/${queryArg.uuid}` }),
                providesTags: ['ClientRealEstateLabelController'],
            }),
            clientRealEstateLabelTeamUuid: build.query<
                ClientRealEstateLabelTeamUuidApiResponse,
                ClientRealEstateLabelTeamUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_label/team/${queryArg.uuid}` }),
                providesTags: ['ClientRealEstateLabelController'],
            }),
            clientRealEstateLabelPutLabelUuidSortUuid: build.mutation<
                ClientRealEstateLabelPutLabelUuidSortUuidApiResponse,
                ClientRealEstateLabelPutLabelUuidSortUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_label/sort/${queryArg.labelUuid}`,
                    method: 'PUT',
                    params: { action: queryArg.action },
                }),
                invalidatesTags: ['ClientRealEstateLabelController'],
            }),
            clientRealEstateMarketRealEstateMarket: build.query<
                ClientRealEstateMarketRealEstateMarketApiResponse,
                ClientRealEstateMarketRealEstateMarketApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_market`,
                    params: {
                        page: queryArg.page,
                        use: queryArg.use,
                        area: queryArg.area,
                        price: queryArg.price,
                        age: queryArg.age,
                        saleTime: queryArg.saleTime,
                        grossRate: queryArg.grossRate,
                        netRate: queryArg.netRate,
                        seismicStandard: queryArg.seismicStandard,
                        legalCompliance: queryArg.legalCompliance,
                        salesDestination: queryArg.salesDestination,
                        filter: queryArg.filter,
                        order: queryArg.order,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateMarketHasNewMarketEntity: build.query<
                ClientRealEstateMarketHasNewMarketEntityApiResponse,
                ClientRealEstateMarketHasNewMarketEntityApiArg
            >({
                query: () => ({ url: `/api/client/real_estate_market/has_new_market_entity` }),
                providesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateMarketPutRequestCheck: build.mutation<
                ClientRealEstateMarketPutRequestCheckApiResponse,
                ClientRealEstateMarketPutRequestCheckApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_market/${queryArg.uuid}/request_check`, method: 'PUT' }),
                invalidatesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateMarketPutMarketOpen: build.mutation<
                ClientRealEstateMarketPutMarketOpenApiResponse,
                ClientRealEstateMarketPutMarketOpenApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_market/${queryArg.uuid}/market_open`, method: 'PUT' }),
                invalidatesTags: ['ClientRealEstateController'],
            }),
            clientRealEstateMemoPostRealEstateMemo: build.mutation<
                ClientRealEstateMemoPostRealEstateMemoApiResponse,
                ClientRealEstateMemoPostRealEstateMemoApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_memo`,
                    method: 'POST',
                    body: queryArg.clientRealEstateMemoInsertDto,
                }),
                invalidatesTags: ['ClientRealEstateMemoController'],
            }),
            clientRealEstateMemoDeleteUuid: build.mutation<
                ClientRealEstateMemoDeleteUuidApiResponse,
                ClientRealEstateMemoDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_memo/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientRealEstateMemoController'],
            }),
            clientRealEstateRecommendRealEstateRecommend: build.query<
                ClientRealEstateRecommendRealEstateRecommendApiResponse,
                ClientRealEstateRecommendRealEstateRecommendApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/real_estate_recommend/${queryArg.uuid}/recommend` }),
                providesTags: ['ClientRealEstateRecommendController'],
            }),
            clientRealEstateRecommendPutCheck: build.mutation<
                ClientRealEstateRecommendPutCheckApiResponse,
                ClientRealEstateRecommendPutCheckApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_recommend/${queryArg.uuid}/recommend_check`,
                    method: 'PUT',
                }),
                invalidatesTags: ['ClientRealEstateRecommendController'],
            }),
            clientRealEstateRecommendPutExclusion: build.mutation<
                ClientRealEstateRecommendPutExclusionApiResponse,
                ClientRealEstateRecommendPutExclusionApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_recommend/${queryArg.uuid}/recommend_exclusion`,
                    method: 'PUT',
                    body: queryArg.body,
                }),
                invalidatesTags: ['ClientRealEstateRecommendController'],
            }),
            clientTeamPostTeam: build.mutation<ClientTeamPostTeamApiResponse, ClientTeamPostTeamApiArg>({
                query: (queryArg) => ({ url: `/api/client/team`, method: 'POST', body: queryArg.clientTeamInsertDto }),
                invalidatesTags: ['ClientTeamController'],
            }),
            clientTeamUuid: build.query<ClientTeamUuidApiResponse, ClientTeamUuidApiArg>({
                query: (queryArg) => ({ url: `/api/client/team/${queryArg.uuid}`, params: { withChild: queryArg.withChild } }),
                providesTags: ['ClientTeamController'],
            }),
            clientTeamPutUuid: build.mutation<ClientTeamPutUuidApiResponse, ClientTeamPutUuidApiArg>({
                query: (queryArg) => ({ url: `/api/client/team/${queryArg.uuid}`, method: 'PUT', body: queryArg.teamUpdateDto }),
                invalidatesTags: ['ClientTeamController'],
            }),
            clientTeamMemberConditionGetMe: build.query<
                ClientTeamMemberConditionGetMeApiResponse,
                ClientTeamMemberConditionGetMeApiArg
            >({
                query: () => ({ url: `/api/client/team_member_condition/me` }),
                providesTags: ['ClientTeamMemberConditionController'],
            }),
            clientTeamMemberPostTeamMember: build.mutation<
                ClientTeamMemberPostTeamMemberApiResponse,
                ClientTeamMemberPostTeamMemberApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/team_member`,
                    method: 'POST',
                    body: queryArg.clientTeamMemberInsertDto,
                }),
                invalidatesTags: ['ClientTeamMemberController'],
            }),
            clientTeamMemberHasNoReadTeamActivity: build.query<
                ClientTeamMemberHasNoReadTeamActivityApiResponse,
                ClientTeamMemberHasNoReadTeamActivityApiArg
            >({
                query: () => ({ url: `/api/client/team_member/activity_status` }),
                providesTags: ['ClientTeamMemberController'],
            }),
            clientTeamMemberTeamUuid: build.query<ClientTeamMemberTeamUuidApiResponse, ClientTeamMemberTeamUuidApiArg>({
                query: (queryArg) => ({ url: `/api/client/team_member/team/${queryArg.teamUuid}` }),
                providesTags: ['ClientTeamMemberController'],
            }),
            clientTeamMemberPutTeamMemberOrder: build.mutation<
                ClientTeamMemberPutTeamMemberOrderApiResponse,
                ClientTeamMemberPutTeamMemberOrderApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/team_member/team/${queryArg.teamUuid}/order`,
                    method: 'PUT',
                    body: queryArg.clientTeamMemberOrderDto,
                }),
                invalidatesTags: ['ClientTeamMemberController'],
            }),
            clientTeamMemberPutTeamUuidSortUuid: build.mutation<
                ClientTeamMemberPutTeamUuidSortUuidApiResponse,
                ClientTeamMemberPutTeamUuidSortUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/team_member/team/${queryArg.teamUuid}/sort/${queryArg.teamMemberUuid}`,
                    method: 'PUT',
                    params: { action: queryArg.action },
                }),
                invalidatesTags: ['ClientTeamMemberController'],
            }),
            clientTeamMemberPutUuid: build.mutation<ClientTeamMemberPutUuidApiResponse, ClientTeamMemberPutUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/team_member/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.clientTeamMemberUpdateDto,
                }),
                invalidatesTags: ['ClientTeamMemberController'],
            }),
            clientTeamMemberDeleteUuid: build.mutation<ClientTeamMemberDeleteUuidApiResponse, ClientTeamMemberDeleteUuidApiArg>({
                query: (queryArg) => ({ url: `/api/client/team_member/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientTeamMemberController'],
            }),
            clientUserPostReminder: build.mutation<ClientUserPostReminderApiResponse, ClientUserPostReminderApiArg>({
                query: (queryArg) => ({ url: `/api/client/user/reminder`, method: 'POST', body: queryArg.userReminderDto }),
                invalidatesTags: ['ClientUserController'],
            }),
            clientUserPutPassword: build.mutation<ClientUserPutPasswordApiResponse, ClientUserPutPasswordApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/user/password`,
                    method: 'PUT',
                    body: queryArg.clientTeamMemberUpdatePasswordDto,
                }),
                invalidatesTags: ['ClientUserController'],
            }),
            clientUserSignature: build.query<ClientUserSignatureApiResponse, ClientUserSignatureApiArg>({
                query: () => ({ url: `/api/client/user/signature` }),
                providesTags: ['ClientUserController'],
            }),
            clientUserCondition: build.query<ClientUserConditionApiResponse, ClientUserConditionApiArg>({
                query: () => ({ url: `/api/client/user/condition` }),
                providesTags: ['ClientUserController'],
            }),
            clientUserPutCondition: build.mutation<ClientUserPutConditionApiResponse, ClientUserPutConditionApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/user/condition`,
                    method: 'PUT',
                    body: queryArg.clientTeamMemberConditionUpdateDto,
                }),
                invalidatesTags: ['ClientUserController'],
            }),
            clientUserPutProfile: build.mutation<ClientUserPutProfileApiResponse, ClientUserPutProfileApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/user/profile`,
                    method: 'PUT',
                    body: queryArg.clientTeamMemberUpdateMeDto,
                }),
                invalidatesTags: ['ClientUserController'],
            }),
            clientUserMarketInfo: build.query<ClientUserMarketInfoApiResponse, ClientUserMarketInfoApiArg>({
                query: () => ({ url: `/api/client/user/market_info` }),
                providesTags: ['ClientUserController'],
            }),
            clientUserReset: build.query<ClientUserResetApiResponse, ClientUserResetApiArg>({
                query: (queryArg) => ({ url: `/api/client/user/reset/${queryArg.token}` }),
                providesTags: ['ClientUserController'],
            }),
            clientUserPostReset: build.mutation<ClientUserPostResetApiResponse, ClientUserPostResetApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/user/reset/${queryArg.token}`,
                    method: 'POST',
                    body: queryArg.userResetPasswordDto,
                }),
                invalidatesTags: ['ClientUserController'],
            }),
            clientUserActivate: build.query<ClientUserActivateApiResponse, ClientUserActivateApiArg>({
                query: (queryArg) => ({ url: `/api/client/user/activate/${queryArg.token}` }),
                providesTags: ['ClientUserController'],
            }),
            clientUserPostActivate: build.mutation<ClientUserPostActivateApiResponse, ClientUserPostActivateApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/user/activate/${queryArg.token}`,
                    method: 'POST',
                    body: queryArg.userResetPasswordDto,
                }),
                invalidatesTags: ['ClientUserController'],
            }),
            clientTeamMemberSignatureMemberUuid: build.query<
                ClientTeamMemberSignatureMemberUuidApiResponse,
                ClientTeamMemberSignatureMemberUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/team_member_signature/member/${queryArg.uuid}` }),
                providesTags: ['ClientTeamMemberSignatureController'],
            }),
            clientRealEstateUseTypeRealEstateUseType: build.query<
                ClientRealEstateUseTypeRealEstateUseTypeApiResponse,
                ClientRealEstateUseTypeRealEstateUseTypeApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/real_estate_use_type`,
                    params: { isNotSetOption: queryArg.isNotSetOption },
                }),
                providesTags: ['ClientRealEstateUseTypeController'],
            }),
            clientIntermediaryToUpdateProgress: build.mutation<
                ClientIntermediaryToUpdateProgressApiResponse,
                ClientIntermediaryToUpdateProgressApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/intermediary_to/progress`,
                    method: 'PUT',
                    body: queryArg.clientIntermediaryToProgressUpdateDto,
                }),
                invalidatesTags: ['ClientIntermediaryToController'],
            }),
            clientIntermediaryToUuid: build.query<ClientIntermediaryToUuidApiResponse, ClientIntermediaryToUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/client/intermediary_to/${queryArg.uuid}`,
                    params: { password: queryArg.password },
                }),
                providesTags: ['ClientIntermediaryToController'],
            }),
            clientIntermediaryToExport: build.query<ClientIntermediaryToExportApiResponse, ClientIntermediaryToExportApiArg>({
                query: (queryArg) => ({ url: `/api/client/intermediary_to/export/${queryArg.intermediaryToUuid}` }),
                providesTags: ['ClientIntermediaryToController'],
            }),
            clientIntermediaryToUuidChat: build.query<
                ClientIntermediaryToUuidChatApiResponse,
                ClientIntermediaryToUuidChatApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/intermediary_to/${queryArg.uuid}/chat` }),
                providesTags: ['ClientIntermediaryToController'],
            }),
            clientIntermediaryToPostChat: build.mutation<
                ClientIntermediaryToPostChatApiResponse,
                ClientIntermediaryToPostChatApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/intermediary_to/${queryArg.uuid}/chat`,
                    method: 'POST',
                    body: queryArg.clientIntermediaryToChatInsertDto,
                }),
                invalidatesTags: ['ClientIntermediaryToController'],
            }),
            clientIntermediaryToViewChat: build.mutation<
                ClientIntermediaryToViewChatApiResponse,
                ClientIntermediaryToViewChatApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/intermediary_to/${queryArg.uuid}/chat/view`, method: 'POST' }),
                invalidatesTags: ['ClientIntermediaryToController'],
            }),
            clientIntermediaryToDeleteChat: build.mutation<
                ClientIntermediaryToDeleteChatApiResponse,
                ClientIntermediaryToDeleteChatApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/intermediary_to/${queryArg.uuid}/chat/${queryArg.chatUuid}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['ClientIntermediaryToController'],
            }),
            clientTeamActivityTeamActivity: build.query<
                ClientTeamActivityTeamActivityApiResponse,
                ClientTeamActivityTeamActivityApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/team_activity`, params: { page: queryArg.page } }),
                providesTags: ['ClientTeamActivityController'],
            }),
            clientTeamActivityPutKeyStatus: build.mutation<
                ClientTeamActivityPutKeyStatusApiResponse,
                ClientTeamActivityPutKeyStatusApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/team_activity/${queryArg.key}/status/read`, method: 'PUT' }),
                invalidatesTags: ['ClientTeamActivityController'],
            }),
            clientTeamActivityPutTeamMemberUuidStatus: build.mutation<
                ClientTeamActivityPutTeamMemberUuidStatusApiResponse,
                ClientTeamActivityPutTeamMemberUuidStatusApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/team_activity/team_member/${queryArg.uuid}/status/read`,
                    method: 'PUT',
                }),
                invalidatesTags: ['ClientTeamActivityController'],
            }),
            clientIntermediaryDocumentRealEstateUuid: build.query<
                ClientIntermediaryDocumentRealEstateUuidApiResponse,
                ClientIntermediaryDocumentRealEstateUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/intermediary_document/real_estate/${queryArg.uuid}` }),
                providesTags: ['ClientIntermediaryDocumentController'],
            }),
            clientIntermediaryDocumentIntermediaryToUuid: build.query<
                ClientIntermediaryDocumentIntermediaryToUuidApiResponse,
                ClientIntermediaryDocumentIntermediaryToUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/intermediary_document/intermediary_to/${queryArg.uuid}` }),
                providesTags: ['ClientIntermediaryDocumentController'],
            }),
            clientIntermediaryDocumentPostPreview: build.query<
                ClientIntermediaryDocumentPostPreviewApiResponse,
                ClientIntermediaryDocumentPostPreviewApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/intermediary_document/preview/${queryArg.uuid}` }),
                providesTags: ['ClientIntermediaryDocumentController'],
            }),
            clientRealEstateEndedRealEstatePostRealEstateAssignee: build.mutation<
                ClientRealEstateEndedRealEstatePostRealEstateAssigneeApiResponse,
                ClientRealEstateEndedRealEstatePostRealEstateAssigneeApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/ended_real_estate`,
                    method: 'POST',
                    body: queryArg.clientRealEstateEndedRealEstateInsertDto,
                }),
                invalidatesTags: ['ClientRealEstateEndedRealEstateController'],
            }),
            clientRealEstateEndedRealEstateRevertEndedRealEstate: build.mutation<
                ClientRealEstateEndedRealEstateRevertEndedRealEstateApiResponse,
                ClientRealEstateEndedRealEstateRevertEndedRealEstateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/ended_real_estate/revert/${queryArg.realEstateUuid}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['ClientRealEstateEndedRealEstateController'],
            }),
            clientKpiGetBeacon: build.query<ClientKpiGetBeaconApiResponse, ClientKpiGetBeaconApiArg>({
                query: (queryArg) => ({ url: `/api/client/kpi/${queryArg.uuid}/${queryArg.name}` }),
                providesTags: ['ClientKpiController'],
            }),
            clientCustomerFilterPostCustomerFilter: build.mutation<
                ClientCustomerFilterPostCustomerFilterApiResponse,
                ClientCustomerFilterPostCustomerFilterApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_filter`,
                    method: 'POST',
                    body: queryArg.clientCustomerFilterInsertDto,
                }),
                invalidatesTags: ['ClientCustomerFilterController'],
            }),
            clientCustomerFilterPutUuid: build.mutation<
                ClientCustomerFilterPutUuidApiResponse,
                ClientCustomerFilterPutUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/client/customer_filter/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.clientCustomerFilterUpdateDto,
                }),
                invalidatesTags: ['ClientCustomerFilterController'],
            }),
            clientCustomerFilterDeleteUuid: build.mutation<
                ClientCustomerFilterDeleteUuidApiResponse,
                ClientCustomerFilterDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/customer_filter/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['ClientCustomerFilterController'],
            }),
            clientCustomerFilterFindFiltersByCustomer: build.query<
                ClientCustomerFilterFindFiltersByCustomerApiResponse,
                ClientCustomerFilterFindFiltersByCustomerApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/customer_filter/customer/${queryArg.uuid}` }),
                providesTags: ['ClientCustomerFilterController'],
            }),
            clientCustomerFilterFindCustomersByFiltersAndRealEstate: build.query<
                ClientCustomerFilterFindCustomersByFiltersAndRealEstateApiResponse,
                ClientCustomerFilterFindCustomersByFiltersAndRealEstateApiArg
            >({
                query: (queryArg) => ({ url: `/api/client/customer_filter/real_estate/${queryArg.uuid}/customer` }),
                providesTags: ['ClientCustomerFilterController'],
            }),
            clientAuthSignIn: build.mutation<ClientAuthSignInApiResponse, ClientAuthSignInApiArg>({
                query: (queryArg) => ({ url: `/api/auth/signin`, method: 'POST', body: queryArg.userSignInDto }),
                invalidatesTags: ['ClientAuthController'],
            }),
            clientAuthClientMe: build.query<ClientAuthClientMeApiResponse, ClientAuthClientMeApiArg>({
                query: () => ({ url: `/api/auth/me` }),
                providesTags: ['ClientAuthController'],
            }),
            clientAuthSignOut: build.mutation<ClientAuthSignOutApiResponse, ClientAuthSignOutApiArg>({
                query: () => ({ url: `/api/auth/signout`, method: 'POST' }),
                invalidatesTags: ['ClientAuthController'],
            }),
            adminCompanyContractPostContract: build.mutation<
                AdminCompanyContractPostContractApiResponse,
                AdminCompanyContractPostContractApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/admin/company_contract`,
                    method: 'POST',
                    body: queryArg.adminCompanyContractInsertDto,
                }),
                invalidatesTags: ['AdminCompanyContractController'],
            }),
            adminCompanyContractPutUuid: build.mutation<
                AdminCompanyContractPutUuidApiResponse,
                AdminCompanyContractPutUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/admin/company_contract/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.adminCompanyContractUpdateDto,
                }),
                invalidatesTags: ['AdminCompanyContractController'],
            }),
            adminCompanyContractDeleteUuid: build.mutation<
                AdminCompanyContractDeleteUuidApiResponse,
                AdminCompanyContractDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/admin/company_contract/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['AdminCompanyContractController'],
            }),
            adminCompanyContractPutUuidCancel: build.mutation<
                AdminCompanyContractPutUuidCancelApiResponse,
                AdminCompanyContractPutUuidCancelApiArg
            >({
                query: (queryArg) => ({ url: `/api/admin/company_contract/${queryArg.uuid}/cancel`, method: 'PUT' }),
                invalidatesTags: ['AdminCompanyContractController'],
            }),
            adminCompanyCompany: build.query<AdminCompanyCompanyApiResponse, AdminCompanyCompanyApiArg>({
                query: (queryArg) => ({
                    url: `/api/admin/company`,
                    params: {
                        page: queryArg.page,
                        name: queryArg.name,
                        includeCanceledCompany: queryArg.includeCanceledCompany,
                        order: queryArg.order,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['AdminCompanyController'],
            }),
            adminCompanyPostCompany: build.mutation<AdminCompanyPostCompanyApiResponse, AdminCompanyPostCompanyApiArg>({
                query: (queryArg) => ({ url: `/api/admin/company`, method: 'POST', body: queryArg.adminCompanyInsertDto }),
                invalidatesTags: ['AdminCompanyController'],
            }),
            adminCompanyUuid: build.query<AdminCompanyUuidApiResponse, AdminCompanyUuidApiArg>({
                query: (queryArg) => ({ url: `/api/admin/company/${queryArg.uuid}` }),
                providesTags: ['AdminCompanyController'],
            }),
            adminCompanyPutUuid: build.mutation<AdminCompanyPutUuidApiResponse, AdminCompanyPutUuidApiArg>({
                query: (queryArg) => ({ url: `/api/admin/company/${queryArg.uuid}`, method: 'PUT', body: queryArg.body }),
                invalidatesTags: ['AdminCompanyController'],
            }),
            adminCompanyDeleteUuid: build.mutation<AdminCompanyDeleteUuidApiResponse, AdminCompanyDeleteUuidApiArg>({
                query: (queryArg) => ({ url: `/api/admin/company/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['AdminCompanyController'],
            }),
            adminCompanyPutUuidLogo: build.mutation<AdminCompanyPutUuidLogoApiResponse, AdminCompanyPutUuidLogoApiArg>({
                query: (queryArg) => ({
                    url: `/api/admin/company/${queryArg.uuid}/logo`,
                    method: 'PUT',
                    body: queryArg.adminCompanyLogoUpdateDto,
                }),
                invalidatesTags: ['AdminCompanyController'],
            }),
            adminDownloadGetDownloadList: build.query<
                AdminDownloadGetDownloadListApiResponse,
                AdminDownloadGetDownloadListApiArg
            >({
                query: (queryArg) => ({ url: `/api/admin/download`, params: { page: queryArg.page } }),
                providesTags: ['AdminDownloadController'],
            }),
            adminDownloadUuid: build.query<AdminDownloadUuidApiResponse, AdminDownloadUuidApiArg>({
                query: (queryArg) => ({ url: `/api/admin/download/${queryArg.uuid}` }),
                providesTags: ['AdminDownloadController'],
            }),
            adminFaqGetFaqList: build.query<AdminFaqGetFaqListApiResponse, AdminFaqGetFaqListApiArg>({
                query: (queryArg) => ({ url: `/api/admin/faq`, params: { cauuid: queryArg.cauuid, page: queryArg.page } }),
                providesTags: ['AdminFaqController'],
            }),
            adminFaqPostFaq: build.mutation<AdminFaqPostFaqApiResponse, AdminFaqPostFaqApiArg>({
                query: (queryArg) => ({ url: `/api/admin/faq`, method: 'POST', body: queryArg.adminFaqUpdateDto }),
                invalidatesTags: ['AdminFaqController'],
            }),
            adminFaqUuid: build.query<AdminFaqUuidApiResponse, AdminFaqUuidApiArg>({
                query: (queryArg) => ({ url: `/api/admin/faq/${queryArg.uuid}` }),
                providesTags: ['AdminFaqController'],
            }),
            adminFaqPutUuid: build.mutation<AdminFaqPutUuidApiResponse, AdminFaqPutUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/admin/faq/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.adminFaqUpdateDto,
                }),
                invalidatesTags: ['AdminFaqController'],
            }),
            adminFaqDeleteUuid: build.mutation<AdminFaqDeleteUuidApiResponse, AdminFaqDeleteUuidApiArg>({
                query: (queryArg) => ({ url: `/api/admin/faq/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['AdminFaqController'],
            }),
            adminFileGetPreSignedUrl: build.mutation<AdminFileGetPreSignedUrlApiResponse, AdminFileGetPreSignedUrlApiArg>({
                query: (queryArg) => ({ url: `/api/admin/file`, method: 'POST', body: queryArg.mediaFileUploadDto }),
                invalidatesTags: ['AdminFileController'],
            }),
            adminFileSetFileSize: build.mutation<AdminFileSetFileSizeApiResponse, AdminFileSetFileSizeApiArg>({
                query: (queryArg) => ({ url: `/api/admin/file/set_file_size`, method: 'POST', body: queryArg.body }),
                invalidatesTags: ['AdminFileController'],
            }),
            adminFileGetOneFileDetail: build.query<AdminFileGetOneFileDetailApiResponse, AdminFileGetOneFileDetailApiArg>({
                query: (queryArg) => ({ url: `/api/admin/file/${queryArg.uuid}` }),
                providesTags: ['AdminFileController'],
            }),
            adminInquiryGetInquiryList: build.query<AdminInquiryGetInquiryListApiResponse, AdminInquiryGetInquiryListApiArg>({
                query: (queryArg) => ({ url: `/api/admin/inquiry`, params: { page: queryArg.page } }),
                providesTags: ['AdminInquiryController'],
            }),
            adminInquiryUuid: build.query<AdminInquiryUuidApiResponse, AdminInquiryUuidApiArg>({
                query: (queryArg) => ({ url: `/api/admin/inquiry/${queryArg.uuid}` }),
                providesTags: ['AdminInquiryController'],
            }),
            adminInquiryPutUuid: build.mutation<AdminInquiryPutUuidApiResponse, AdminInquiryPutUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/admin/inquiry/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.inquiryEntities,
                }),
                invalidatesTags: ['AdminInquiryController'],
            }),
            adminInquiryDeleteUuid: build.mutation<AdminInquiryDeleteUuidApiResponse, AdminInquiryDeleteUuidApiArg>({
                query: (queryArg) => ({ url: `/api/admin/inquiry/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['AdminInquiryController'],
            }),
            adminMediaGetFile: build.query<AdminMediaGetFileApiResponse, AdminMediaGetFileApiArg>({
                query: (queryArg) => ({
                    url: `/api/admin/media/${queryArg.uuid}`,
                    params: { filename: queryArg.filename, width: queryArg.width, height: queryArg.height },
                }),
                providesTags: ['AdminMediaController'],
            }),
            adminNotificationTemplateGetNotificationTemplate: build.query<
                AdminNotificationTemplateGetNotificationTemplateApiResponse,
                AdminNotificationTemplateGetNotificationTemplateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/admin/notification_template`,
                    params: {
                        page: queryArg.page,
                        key: queryArg.key,
                        name: queryArg.name,
                        mailSubject: queryArg.mailSubject,
                        order: queryArg.order,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['AdminNotificationTemplateController'],
            }),
            adminNotificationTemplatePostNotificationTemplate: build.mutation<
                AdminNotificationTemplatePostNotificationTemplateApiResponse,
                AdminNotificationTemplatePostNotificationTemplateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/admin/notification_template`,
                    method: 'POST',
                    body: queryArg.adminNotificationTemplateInsertDto,
                }),
                invalidatesTags: ['AdminNotificationTemplateController'],
            }),
            adminNotificationTemplateGetNotificationTemplateByUuid: build.query<
                AdminNotificationTemplateGetNotificationTemplateByUuidApiResponse,
                AdminNotificationTemplateGetNotificationTemplateByUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/admin/notification_template/${queryArg.uuid}` }),
                providesTags: ['AdminNotificationTemplateController'],
            }),
            adminNotificationTemplatePutUuid: build.mutation<
                AdminNotificationTemplatePutUuidApiResponse,
                AdminNotificationTemplatePutUuidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/admin/notification_template/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.adminNotificationTemplateUpdateDto,
                }),
                invalidatesTags: ['AdminNotificationTemplateController'],
            }),
            adminNotificationTemplateDeleteUuid: build.mutation<
                AdminNotificationTemplateDeleteUuidApiResponse,
                AdminNotificationTemplateDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/admin/notification_template/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['AdminNotificationTemplateController'],
            }),
            adminTeamPostTeam: build.mutation<AdminTeamPostTeamApiResponse, AdminTeamPostTeamApiArg>({
                query: (queryArg) => ({ url: `/api/admin/team`, method: 'POST', body: queryArg.adminTeamInsertDto }),
                invalidatesTags: ['AdminTeamController'],
            }),
            adminTeamPutUuid: build.mutation<AdminTeamPutUuidApiResponse, AdminTeamPutUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/admin/team/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.adminTeamUpdateDto,
                }),
                invalidatesTags: ['AdminTeamController'],
            }),
            adminTeamMemberPostTeamMember: build.mutation<
                AdminTeamMemberPostTeamMemberApiResponse,
                AdminTeamMemberPostTeamMemberApiArg
            >({
                query: (queryArg) => ({ url: `/api/admin/team_member`, method: 'POST', body: queryArg.adminTeamMemberInsertDto }),
                invalidatesTags: ['AdminTeamMemberController'],
            }),
            adminTeamMemberReplaceUuid: build.mutation<AdminTeamMemberReplaceUuidApiResponse, AdminTeamMemberReplaceUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/admin/team_member/replace`,
                    method: 'POST',
                    body: queryArg.adminTeamMemberReplaceDto,
                }),
                invalidatesTags: ['AdminTeamMemberController'],
            }),
            adminTeamMemberPutUuid: build.mutation<AdminTeamMemberPutUuidApiResponse, AdminTeamMemberPutUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/admin/team_member/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.adminTeamMemberUpdateDto,
                }),
                invalidatesTags: ['AdminTeamMemberController'],
            }),
            adminTeamMemberDeleteUuid: build.mutation<AdminTeamMemberDeleteUuidApiResponse, AdminTeamMemberDeleteUuidApiArg>({
                query: (queryArg) => ({ url: `/api/admin/team_member/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['AdminTeamMemberController'],
            }),
            adminPlanPlan: build.query<AdminPlanPlanApiResponse, AdminPlanPlanApiArg>({
                query: () => ({ url: `/api/admin/plan` }),
                providesTags: ['AdminPlanController'],
            }),
            adminReportTemplateGetReportTemplate: build.query<
                AdminReportTemplateGetReportTemplateApiResponse,
                AdminReportTemplateGetReportTemplateApiArg
            >({
                query: (queryArg) => ({ url: `/api/admin/report_template`, params: { page: queryArg.page } }),
                providesTags: ['AdminReportTemplateController'],
            }),
            adminReportTemplatePostReportTemplate: build.mutation<
                AdminReportTemplatePostReportTemplateApiResponse,
                AdminReportTemplatePostReportTemplateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/admin/report_template`,
                    method: 'POST',
                    body: queryArg.adminReportTemplateInsertDto,
                }),
                invalidatesTags: ['AdminReportTemplateController'],
            }),
            adminReportTemplateGetReportTemplateByUuid: build.query<
                AdminReportTemplateGetReportTemplateByUuidApiResponse,
                AdminReportTemplateGetReportTemplateByUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/admin/report_template/${queryArg.uuid}` }),
                providesTags: ['AdminReportTemplateController'],
            }),
            adminReportTemplatePutUuid: build.mutation<AdminReportTemplatePutUuidApiResponse, AdminReportTemplatePutUuidApiArg>({
                query: (queryArg) => ({
                    url: `/api/admin/report_template/${queryArg.uuid}`,
                    method: 'PUT',
                    body: queryArg.adminReportTemplateUpdateDto,
                }),
                invalidatesTags: ['AdminReportTemplateController'],
            }),
            adminReportTemplateDeleteUuid: build.mutation<
                AdminReportTemplateDeleteUuidApiResponse,
                AdminReportTemplateDeleteUuidApiArg
            >({
                query: (queryArg) => ({ url: `/api/admin/report_template/${queryArg.uuid}`, method: 'DELETE' }),
                invalidatesTags: ['AdminReportTemplateController'],
            }),
        }),
        overrideExisting: false,
    })
export { injectedRtkApi as api }
export type AppGetHcApiResponse = unknown
export type AppGetHcApiArg = void
export type ClientCompanyPutUuidApiResponse = /** status 200  */ CompanyEntities
export type ClientCompanyPutUuidApiArg = {
    uuid: string
    companyUpdateDto: CompanyUpdateDto
}
export type ClientCompanyPutUuidLogoApiResponse = /** status 200  */ CompanyEntities
export type ClientCompanyPutUuidLogoApiArg = {
    uuid: string
    companyUpdateLogoDto: CompanyUpdateLogoDto
}
export type ClientCompanyDeleteUuidLogoApiResponse = /** status 200  */ CompanyEntities
export type ClientCompanyDeleteUuidLogoApiArg = {
    uuid: string
}
export type ClientCompanyPutUuidBandApiResponse = /** status 200  */ CompanyEntities
export type ClientCompanyPutUuidBandApiArg = {
    uuid: string
    companyUpdateRealEstateDetailBandDto: CompanyUpdateRealEstateDetailBandDto
}
export type ClientCompanyDeleteUuidBandApiResponse = /** status 200  */ CompanyEntities
export type ClientCompanyDeleteUuidBandApiArg = {
    uuid: string
}
export type ClientCompanyPutUuidRealEstateListKeysApiResponse = /** status 200  */ CompanyEntities
export type ClientCompanyPutUuidRealEstateListKeysApiArg = {
    uuid: string
    companyUpdateRealEstateListKeysDto: CompanyUpdateRealEstateListKeysDto
}
export type ClientConditionRequestGetConditionRequestApiResponse = /** status 200  */ CustomerInvestmentConditionRequestEntities
export type ClientConditionRequestGetConditionRequestApiArg = {
    uuid: string
}
export type ClientConditionRequestPostConditionRequestApiResponse = /** status 200  */ KeyValueDto
export type ClientConditionRequestPostConditionRequestApiArg = {
    cuuid: string
}
export type ClientConditionRequestPutUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientConditionRequestPutUuidApiArg = {
    cuuid: string
    ruuid: string
    conditionRequestUpdateDto: ConditionRequestUpdateDto
}
export type ClientCustomerAssigneePostCustomerAssigneeApiResponse = /** status 200  */ CompanyEntities
export type ClientCustomerAssigneePostCustomerAssigneeApiArg = {
    clientCustomerAssigneeInsertDto: ClientCustomerAssigneeInsertDto
}
export type ClientCustomerAssigneePutMultiEditApiResponse = /** status 200  */ KeyValueDto
export type ClientCustomerAssigneePutMultiEditApiArg = void
export type ClientCustomerAssigneePutUuidApiResponse = /** status 200  */ CustomerAssigneeEntities
export type ClientCustomerAssigneePutUuidApiArg = {
    uuid: string
    clientCustomerAssigneeUpdateDto: ClientCustomerAssigneeUpdateDto
}
export type ClientCustomerAssigneeDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientCustomerAssigneeDeleteUuidApiArg = {
    uuid: string
}
export type ClientCustomerCustomerApiResponse = /** status 200  */ ListCountDto
export type ClientCustomerCustomerApiArg = {
    page?: number
    maxCount?: number
    name?: string
    order?: 'name' | 'company'
    filter?: string[]
    useTypes: string[]
    prices: string[]
    areas: string[]
    buildingAges: string[]
    saleTimes: string[]
    registeredTypes: string[]
    assignedToType: string
    assignedToUuidList: string[]
    labelType: string
    customerUuidList: string[]
    labelUuidList: string[]
    realEstate?: string
    sort?: 'ASC' | 'DESC'
}
export type ClientCustomerPostCustomerApiResponse = /** status 200  */ CustomerEntities
export type ClientCustomerPostCustomerApiArg = {
    clientCustomerInsertDto: ClientCustomerInsertDto
}
export type ClientCustomerPutMultipleApiResponse = /** status 200  */ CustomerEntities[]
export type ClientCustomerPutMultipleApiArg = {
    clientCustomerMultipleDto: ClientCustomerMultipleDto
}
export type ClientCustomerRecommendApiResponse = /** status 200  */ ListCountDto
export type ClientCustomerRecommendApiArg = {
    customer: string
}
export type ClientCustomerExportApiResponse = /** status 200  */ Blob
export type ClientCustomerExportApiArg = void
export type ClientCustomerPreImportApiResponse = /** status 200  */ ClientCustomerImportDto[]
export type ClientCustomerPreImportApiArg = {
    clientCustomerImportRequestDto: ClientCustomerImportRequestDto
}
export type ClientCustomerImportApiResponse = /** status 200  */ number
export type ClientCustomerImportApiArg = {
    importType: 'insert' | 'update'
    clientCustomerImportRequestDto: ClientCustomerImportRequestDto
}
export type ClientCustomerUuidApiResponse = /** status 200  */ CustomerEntities
export type ClientCustomerUuidApiArg = {
    uuid: string
}
export type ClientCustomerPutUuidApiResponse = /** status 200  */ CustomerEntities
export type ClientCustomerPutUuidApiArg = {
    uuid: string
    clientCustomerUpdateDto: ClientCustomerUpdateDto
}
export type ClientCustomerDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientCustomerDeleteUuidApiArg = {
    uuid: string
}
export type ClientCustomerPutUuidLabelsApiResponse = /** status 200  */ CustomerEntities
export type ClientCustomerPutUuidLabelsApiArg = {
    uuid: string
    clientCustomerLabelUpdateDto: ClientCustomerLabelUpdateDto
}
export type ClientCustomerIntermediaryCustomerUuidApiResponse = /** status 200  */ ClientCustomerIntermediaryResponseDto
export type ClientCustomerIntermediaryCustomerUuidApiArg = {
    uuid: string
}
export type ClientCustomerIntermediaryPostFinishApiResponse = /** status 200  */ KeyValueDto
export type ClientCustomerIntermediaryPostFinishApiArg = {
    clientIntermediaryFinishDto: ClientIntermediaryFinishDto
}
export type ClientCustomerIntermediaryPostReviewFinishApiResponse = /** status 200  */ KeyValueDto
export type ClientCustomerIntermediaryPostReviewFinishApiArg = {
    clientIntermediaryReviewFinishDto: ClientIntermediaryReviewFinishDto
}
export type ClientCustomerIntermediaryPostReviewFinishGuestApiResponse = /** status 200  */ KeyValueDto
export type ClientCustomerIntermediaryPostReviewFinishGuestApiArg = {
    clientIntermediaryReviewFinishDto: ClientIntermediaryReviewFinishDto
}
export type ClientCustomerLabelPostCustomerLabelApiResponse = /** status 200  */ InsertResult
export type ClientCustomerLabelPostCustomerLabelApiArg = {
    clientCustomerLabelEditDto: ClientCustomerLabelEditDto
}
export type ClientCustomerLabelPutCustomerLabelOrderApiResponse = /** status 200  */ KeyValueDto
export type ClientCustomerLabelPutCustomerLabelOrderApiArg = {
    clientCustomerLabelOrderDto: ClientCustomerLabelOrderDto
}
export type ClientCustomerLabelPutUuidApiResponse = /** status 200  */ UpdateResult
export type ClientCustomerLabelPutUuidApiArg = {
    uuid: string
    clientCustomerLabelEditDto: ClientCustomerLabelEditDto
}
export type ClientCustomerLabelDeleteUuidApiResponse = /** status 200  */ UpdateResult
export type ClientCustomerLabelDeleteUuidApiArg = {
    uuid: string
}
export type ClientCustomerLabelIsUsingApiResponse = /** status 200  */ boolean
export type ClientCustomerLabelIsUsingApiArg = {
    uuid: string
}
export type ClientCustomerLabelTeamUuidApiResponse = /** status 200  */ ListCountDto
export type ClientCustomerLabelTeamUuidApiArg = {
    uuid: string
}
export type ClientCustomerLabelPutLabelUuidSortUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientCustomerLabelPutLabelUuidSortUuidApiArg = {
    labelUuid: string
    action: 'PREV' | 'NEXT'
}
export type ClientCustomerMemoPostCustomerMemoApiResponse = /** status 200  */ CustomerMemoEntities
export type ClientCustomerMemoPostCustomerMemoApiArg = {
    clientCustomerMemoInsertDto: ClientCustomerMemoInsertDto
}
export type ClientCustomerMemoDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientCustomerMemoDeleteUuidApiArg = {
    uuid: string
}
export type ClientDashboardAnalyticsDashboardAnalyticsApiResponse = /** status 200  */ ClientDashboardStatusDto
export type ClientDashboardAnalyticsDashboardAnalyticsApiArg = void
export type ClientDownloadPostDownloadApiResponse = /** status 200  */ KeyValueDto
export type ClientDownloadPostDownloadApiArg = {
    downloadExecuteDto: DownloadExecuteDto
}
export type ClientFaqGetFaqListApiResponse = /** status 200  */ FaqListResponseDto
export type ClientFaqGetFaqListApiArg = {
    home?: boolean
    page?: number
}
export type ClientFileGetOneFileDetailApiResponse = /** status 200  */ FileEntities
export type ClientFileGetOneFileDetailApiArg = {
    uuid: string
}
export type ClientFileGetPreSignedUrlApiResponse = /** status 200  */ MediaFileUploadResponseDto
export type ClientFileGetPreSignedUrlApiArg = {
    mediaFileUploadDto: MediaFileUploadDto
}
export type ClientFileSetFileSizeApiResponse = /** status 200  */ KeyValueDto
export type ClientFileSetFileSizeApiArg = {
    mediaFileUploadSetSizeDto: MediaFileUploadSetSizeDto
}
export type ClientInquiryPostInquiryApiResponse = /** status 200  */ KeyValueDto
export type ClientInquiryPostInquiryApiArg = {
    inquiryInsertDto: InquiryInsertDto
}
export type ClientInquiryPostInquirySimpleApiResponse = /** status 200  */ KeyValueDto
export type ClientInquiryPostInquirySimpleApiArg = void
export type ClientIntermediaryPostIntermediaryApiResponse = /** status 200  */ KeyValueDto
export type ClientIntermediaryPostIntermediaryApiArg = {
    clientIntermediaryInsertDto: ClientIntermediaryInsertDto
}
export type ClientIntermediaryPostPreviewApiResponse = /** status 200  */ HealthDto
export type ClientIntermediaryPostPreviewApiArg = {
    clientIntermediaryDocumentPreviewDto: ClientIntermediaryDocumentPreviewDto
}
export type ClientIntermediaryRealEstateUuidApiResponse = /** status 200  */ IntermediaryEntities[]
export type ClientIntermediaryRealEstateUuidApiArg = {
    uuid: string
}
export type ClientIntermediaryVotePostIntermediaryVoteApiResponse = /** status 200  */ IntermediaryToVoteEntities
export type ClientIntermediaryVotePostIntermediaryVoteApiArg = {
    clientIntermediaryToVoteInsertDto: ClientIntermediaryToVoteInsertDto
}
export type ClientMediaGetSignedUrlApiResponse = /** status 200  */ ValueDto
export type ClientMediaGetSignedUrlApiArg = {
    valueDto: ValueDto
}
export type ClientMediaGetFileApiResponse = /** status 200  */ Blob
export type ClientMediaGetFileApiArg = {
    uuid: string
    width?: number
    height?: number
}
export type ClientMediaGetFileSignedUrlApiResponse = unknown
export type ClientMediaGetFileSignedUrlApiArg = {
    uuid: string
}
export type ClientNotificationTemplateNotificationTemplateApiResponse = /** status 200  */ NotificationTemplateEntities[]
export type ClientNotificationTemplateNotificationTemplateApiArg = {
    key: string
}
export type ClientNotificationTemplateNotificationTemplateMypageApiResponse = /** status 200  */ NotificationTemplateEntities[]
export type ClientNotificationTemplateNotificationTemplateMypageApiArg = {
    type: 1 | 2
}
export type ClientNotificationTemplatePutUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientNotificationTemplatePutUuidApiArg = {
    uuid: string
    clientNotificationTemplateUpdateDto: ClientNotificationTemplateUpdateDto
}
export type ClientNotificationTemplateDeleteUuidApiResponse = unknown
export type ClientNotificationTemplateDeleteUuidApiArg = {
    uuid: string
}
export type ClientPageViewPageViewApiResponse = /** status 200  */ KeyValueDto
export type ClientPageViewPageViewApiArg = {
    clientPageViewInsertDto: ClientPageViewInsertDto
}
export type ClientRealEstateAssigneePostRealEstateAssigneeApiResponse = /** status 200  */ CompanyEntities
export type ClientRealEstateAssigneePostRealEstateAssigneeApiArg = {
    clientRealEstateAssigneeInsertDto: ClientRealEstateAssigneeInsertDto
}
export type ClientRealEstateAssigneePutUuidApiResponse = /** status 200  */ RealEstateAssigneeEntities
export type ClientRealEstateAssigneePutUuidApiArg = {
    uuid: string
    clientRealEstateAssigneeUpdateDto: ClientRealEstateAssigneeUpdateDto
}
export type ClientRealEstateAssigneeDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateAssigneeDeleteUuidApiArg = {
    uuid: string
}
export type ClientRealEstateConnectionRealEstateConnectionApiResponse = /** status 200  */ ClientRealEstateConnectionResponseDto
export type ClientRealEstateConnectionRealEstateConnectionApiArg = {
    /** 物件のuuid */
    uuid: string
    connectionUuidList?: string[]
}
export type ClientRealEstateConnectionPutCheckApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateConnectionPutCheckApiArg = {
    /** 物件のuuid */
    uuid: string
}
export type ClientRealEstateConnectionPutOpenApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateConnectionPutOpenApiArg = {
    /** コネクションのuuid */
    uuid: string
}
export type ClientRealEstateRealEstateApiResponse = /** status 200  */ ListCountDto
export type ClientRealEstateRealEstateApiArg = {
    page: number
    name: string
    pageSize: number
    order: string
    filter: string[]
    useTypes: string[]
    prices: string[]
    buildingAges: string[]
    saleTimes: string[]
    grossRate: string[]
    salesDestination: string[]
    assignedToType: string
    assignedToUuidList: string[]
    labelType: string
    labelUuidList: string[]
    progress: string[]
    topRightLat?: number
    topRightLng?: number
    bottomLeftLat?: number
    bottomLeftLng?: number
    acquiredAtFrom?: string
    acquiredAtTo?: string
    sort: string
    isMap?: boolean
}
export type ClientRealEstatePostRealEstateApiResponse = /** status 200  */ RealEstateEntities
export type ClientRealEstatePostRealEstateApiArg = {
    clientRealEstateInsertDto: ClientRealEstateInsertDto
}
export type ClientRealEstateGetMaxProgressApiResponse = /** status 200  */ ClientRealEstateProgressDto
export type ClientRealEstateGetMaxProgressApiArg = {
    realEstateUuids: string[]
}
export type ClientRealEstateHasNoReadTeamActivityApiResponse = /** status 200  */ ClientRealEstateActivityDto
export type ClientRealEstateHasNoReadTeamActivityApiArg = {
    realEstateUuids: string[]
}
export type ClientRealEstateGetNoReadChatActivityApiResponse = /** status 200  */ ClientRealEstateChatActivityDto
export type ClientRealEstateGetNoReadChatActivityApiArg = {
    realEstateUuids: string[]
}
export type ClientRealEstateUuidApiResponse = /** status 200  */ ClientRealEstateListDto
export type ClientRealEstateUuidApiArg = {
    uuid: string
}
export type ClientRealEstatePutUuidApiResponse = /** status 200  */ RealEstateEntities
export type ClientRealEstatePutUuidApiArg = {
    uuid: string
    clientRealEstateUpdateDto: ClientRealEstateUpdateDto
}
export type ClientRealEstateDeleteUuidApiResponse = unknown
export type ClientRealEstateDeleteUuidApiArg = {
    uuid: string
}
export type ClientRealEstatePutUuidLabelsApiResponse = /** status 200  */ RealEstateEntities
export type ClientRealEstatePutUuidLabelsApiArg = {
    uuid: string
    clientRealEstateLabelUpdateDto: ClientRealEstateLabelUpdateDto
}
export type ClientRealEstatePostReportApiResponse = /** status 200  */ Blob
export type ClientRealEstatePostReportApiArg = {
    uuid: string
    clientRealEstateOutputDto: ClientRealEstateOutputDto
}
export type ClientRealEstateMarketRequestApiResponse = /** status 200  */ ClientRealEstateMarketResponseDto
export type ClientRealEstateMarketRequestApiArg = {
    /** 物件のuuid */
    uuid: string
    publishRequestUuidList?: string[]
}
export type ClientRealEstatePostMarketRequestApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstatePostMarketRequestApiArg = {
    /** マーケット物件のuuid */
    uuid: string
    clientRealEstatePublishRequestInsertDto: ClientRealEstatePublishRequestInsertDto
}
export type ClientRealEstateRemovePublishMarketApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateRemovePublishMarketApiArg = {
    /** 物件のuuid */
    uuid: string
}
export type ClientRealEstateDeleteUuidOptionApiResponse = unknown
export type ClientRealEstateDeleteUuidOptionApiArg = {
    uuid: string
    option: number
}
export type ClientRealEstateDocumentPostRealEstateDocumentApiResponse = /** status 200  */ CompanyEntities
export type ClientRealEstateDocumentPostRealEstateDocumentApiArg = {
    clientRealEstateDocumentInsertDto: ClientRealEstateDocumentInsertDto
}
export type ClientRealEstateDocumentPutRealEstateDocumentOrderApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateDocumentPutRealEstateDocumentOrderApiArg = {
    clientRealEstateDocumentOrderDto: ClientRealEstateDocumentOrderDto
}
export type ClientRealEstateDocumentPutUuidApiResponse = /** status 200  */ RealEstateDocumentEntities
export type ClientRealEstateDocumentPutUuidApiArg = {
    uuid: string
    body: ClientRealEstateDocumentUpdateDto | ClientRealEstateDocumentCoverUpdateDto | ClientRealEstateDocumentAttachUpdateDto
}
export type ClientRealEstateDocumentDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateDocumentDeleteUuidApiArg = {
    uuid: string
}
export type ClientRealEstateDocumentPutDocumentUuidSortUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateDocumentPutDocumentUuidSortUuidApiArg = {
    documentUuid: string
    action: 'PREV' | 'NEXT'
}
export type ClientRealEstateDocumentExportApiResponse = unknown
export type ClientRealEstateDocumentExportApiArg = {
    realEstateUuid: string
}
export type ClientRealEstateDocumentLabelPostRealEstateDocumentLabelApiResponse = /** status 200  */ InsertResult
export type ClientRealEstateDocumentLabelPostRealEstateDocumentLabelApiArg = {
    clientRealEstateDocumentLabelEditDto: ClientRealEstateDocumentLabelEditDto
}
export type ClientRealEstateDocumentLabelPutRealEstateDocumentOrderApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateDocumentLabelPutRealEstateDocumentOrderApiArg = {
    clientRealEstateDocumentLabelOrderDto: ClientRealEstateDocumentLabelOrderDto
}
export type ClientRealEstateDocumentLabelPutUuidApiResponse = /** status 200  */ UpdateResult
export type ClientRealEstateDocumentLabelPutUuidApiArg = {
    uuid: string
    clientRealEstateDocumentLabelEditDto: ClientRealEstateDocumentLabelEditDto
}
export type ClientRealEstateDocumentLabelDeleteUuidApiResponse = /** status 200  */ UpdateResult
export type ClientRealEstateDocumentLabelDeleteUuidApiArg = {
    uuid: string
}
export type ClientRealEstateDocumentLabelIsUsingApiResponse = /** status 200  */ boolean
export type ClientRealEstateDocumentLabelIsUsingApiArg = {
    uuid: string
}
export type ClientRealEstateDocumentLabelTeamUuidApiResponse = /** status 200  */ ListCountDto
export type ClientRealEstateDocumentLabelTeamUuidApiArg = {
    uuid: string
}
export type ClientRealEstateDocumentLabelPutLabelUuidSortUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateDocumentLabelPutLabelUuidSortUuidApiArg = {
    labelUuid: string
    action: 'PREV' | 'NEXT'
}
export type ClientRealEstateLabelPostRealEstateLabelApiResponse = /** status 200  */ InsertResult
export type ClientRealEstateLabelPostRealEstateLabelApiArg = {
    clientRealEstateLabelEditDto: ClientRealEstateLabelEditDto
}
export type ClientRealEstateLabelPutRealEstateLabelOrderApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateLabelPutRealEstateLabelOrderApiArg = {
    clientRealEstateLabelOrderDto: ClientRealEstateLabelOrderDto
}
export type ClientRealEstateLabelPutUuidApiResponse = /** status 200  */ UpdateResult
export type ClientRealEstateLabelPutUuidApiArg = {
    uuid: string
    clientRealEstateLabelEditDto: ClientRealEstateLabelEditDto
}
export type ClientRealEstateLabelDeleteUuidApiResponse = /** status 200  */ UpdateResult
export type ClientRealEstateLabelDeleteUuidApiArg = {
    uuid: string
}
export type ClientRealEstateLabelIsUsingApiResponse = /** status 200  */ boolean
export type ClientRealEstateLabelIsUsingApiArg = {
    uuid: string
}
export type ClientRealEstateLabelTeamUuidApiResponse = /** status 200  */ ListCountDto
export type ClientRealEstateLabelTeamUuidApiArg = {
    uuid: string
}
export type ClientRealEstateLabelPutLabelUuidSortUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateLabelPutLabelUuidSortUuidApiArg = {
    labelUuid: string
    action: 'PREV' | 'NEXT'
}
export type ClientRealEstateMarketRealEstateMarketApiResponse = /** status 200  */ ClientRealEstateMarketListResponseDto
export type ClientRealEstateMarketRealEstateMarketApiArg = {
    page?: number
    use?: string[]
    area?: string[]
    price?: string[]
    age?: string[]
    saleTime?: string[]
    grossRate?: string[]
    netRate?: string[]
    seismicStandard?: string[]
    legalCompliance?: string[]
    salesDestination?: string[]
    filter?: string[]
    order?: 'publishedAt' | 'updatedAt' | 'publishRequestCount'
    sort?: 'ASC' | 'DESC'
}
export type ClientRealEstateMarketHasNewMarketEntityApiResponse = /** status 200  */ MarketRealEstateEntities[]
export type ClientRealEstateMarketHasNewMarketEntityApiArg = void
export type ClientRealEstateMarketPutRequestCheckApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateMarketPutRequestCheckApiArg = {
    /** 物件のuuid */
    uuid: string
}
export type ClientRealEstateMarketPutMarketOpenApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateMarketPutMarketOpenApiArg = {
    /** 情報開示リクエストのuuid */
    uuid: string
}
export type ClientRealEstateMemoPostRealEstateMemoApiResponse = /** status 200  */ RealEstateMemoEntities
export type ClientRealEstateMemoPostRealEstateMemoApiArg = {
    clientRealEstateMemoInsertDto: ClientRealEstateMemoInsertDto
}
export type ClientRealEstateMemoDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateMemoDeleteUuidApiArg = {
    uuid: string
}
export type ClientRealEstateRecommendRealEstateRecommendApiResponse = /** status 200  */ ClientRealEstateRecommendResponseDto
export type ClientRealEstateRecommendRealEstateRecommendApiArg = {
    /** 物件のuuid */
    uuid: string
}
export type ClientRealEstateRecommendPutCheckApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateRecommendPutCheckApiArg = {
    /** 物件のuuid */
    uuid: string
}
export type ClientRealEstateRecommendPutExclusionApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateRecommendPutExclusionApiArg = {
    /** 物件のuuid */
    uuid: string
    body: ClientRealEstateRecommendListDto[]
}
export type ClientTeamPostTeamApiResponse = /** status 200  */ TeamEntities
export type ClientTeamPostTeamApiArg = {
    clientTeamInsertDto: ClientTeamInsertDto
}
export type ClientTeamUuidApiResponse = /** status 200  */ TeamEntities
export type ClientTeamUuidApiArg = {
    uuid: string
    withChild?: boolean
}
export type ClientTeamPutUuidApiResponse = /** status 200  */ TeamEntities
export type ClientTeamPutUuidApiArg = {
    uuid: string
    teamUpdateDto: TeamUpdateDto
}
export type ClientTeamMemberConditionGetMeApiResponse = /** status 200  */ TeamMemberInvestmentConditionEntities
export type ClientTeamMemberConditionGetMeApiArg = void
export type ClientTeamMemberPostTeamMemberApiResponse = /** status 200  */ TeamMemberEntities
export type ClientTeamMemberPostTeamMemberApiArg = {
    clientTeamMemberInsertDto: ClientTeamMemberInsertDto
}
export type ClientTeamMemberHasNoReadTeamActivityApiResponse = /** status 200  */ ClientRealEstateActivityBooleanDto
export type ClientTeamMemberHasNoReadTeamActivityApiArg = void
export type ClientTeamMemberTeamUuidApiResponse = /** status 200  */ ListCountDto
export type ClientTeamMemberTeamUuidApiArg = {
    teamUuid: string
}
export type ClientTeamMemberPutTeamMemberOrderApiResponse = /** status 200  */ KeyValueDto
export type ClientTeamMemberPutTeamMemberOrderApiArg = {
    teamUuid: string
    clientTeamMemberOrderDto: ClientTeamMemberOrderDto
}
export type ClientTeamMemberPutTeamUuidSortUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientTeamMemberPutTeamUuidSortUuidApiArg = {
    teamUuid: string
    teamMemberUuid: string
    action: 'PREV' | 'NEXT'
}
export type ClientTeamMemberPutUuidApiResponse = /** status 200  */ TeamMemberEntities
export type ClientTeamMemberPutUuidApiArg = {
    uuid: string
    clientTeamMemberUpdateDto: ClientTeamMemberUpdateDto
}
export type ClientTeamMemberDeleteUuidApiResponse = unknown
export type ClientTeamMemberDeleteUuidApiArg = {
    uuid: string
}
export type ClientUserPostReminderApiResponse = /** status 200  */ KeyValueDto
export type ClientUserPostReminderApiArg = {
    userReminderDto: UserReminderDto
}
export type ClientUserPutPasswordApiResponse = /** status 200  */ TeamMemberEntities
export type ClientUserPutPasswordApiArg = {
    clientTeamMemberUpdatePasswordDto: ClientTeamMemberUpdatePasswordDto
}
export type ClientUserSignatureApiResponse = /** status 200  */ TeamMemberSignatureEntities[]
export type ClientUserSignatureApiArg = void
export type ClientUserConditionApiResponse = /** status 200  */ TeamMemberInvestmentConditionEntities
export type ClientUserConditionApiArg = void
export type ClientUserPutConditionApiResponse = /** status 200  */ TeamMemberInvestmentConditionEntities
export type ClientUserPutConditionApiArg = {
    clientTeamMemberConditionUpdateDto: ClientTeamMemberConditionUpdateDto
}
export type ClientUserPutProfileApiResponse = /** status 200  */ TeamMemberEntities
export type ClientUserPutProfileApiArg = {
    clientTeamMemberUpdateMeDto: ClientTeamMemberUpdateMeDto
}
export type ClientUserMarketInfoApiResponse = /** status 200  */ ClientUserMarketResponseDto
export type ClientUserMarketInfoApiArg = void
export type ClientUserResetApiResponse = /** status 200  */ KeyValueDto
export type ClientUserResetApiArg = {
    token: string
}
export type ClientUserPostResetApiResponse = /** status 200  */ KeyValueDto
export type ClientUserPostResetApiArg = {
    token: string
    userResetPasswordDto: UserResetPasswordDto
}
export type ClientUserActivateApiResponse = /** status 200  */ TeamMemberEntities
export type ClientUserActivateApiArg = {
    token: string
}
export type ClientUserPostActivateApiResponse = /** status 200  */ KeyValueDto
export type ClientUserPostActivateApiArg = {
    token: string
    userResetPasswordDto: UserResetPasswordDto
}
export type ClientTeamMemberSignatureMemberUuidApiResponse = /** status 200  */ TeamMemberSignatureEntities[]
export type ClientTeamMemberSignatureMemberUuidApiArg = {
    uuid: string
}
export type ClientRealEstateUseTypeRealEstateUseTypeApiResponse = /** status 200  */ ListCountDto
export type ClientRealEstateUseTypeRealEstateUseTypeApiArg = {
    isNotSetOption?: boolean
}
export type ClientIntermediaryToUpdateProgressApiResponse = /** status 200  */ IntermediaryToEntities
export type ClientIntermediaryToUpdateProgressApiArg = {
    clientIntermediaryToProgressUpdateDto: ClientIntermediaryToProgressUpdateDto
}
export type ClientIntermediaryToUuidApiResponse = /** status 200  */ ClientIntermediaryToResponseDto
export type ClientIntermediaryToUuidApiArg = {
    uuid: string
    password?: string
}
export type ClientIntermediaryToExportApiResponse = unknown
export type ClientIntermediaryToExportApiArg = {
    intermediaryToUuid: string
}
export type ClientIntermediaryToUuidChatApiResponse = /** status 200  */ ClientIntermediaryToChatResponseDto
export type ClientIntermediaryToUuidChatApiArg = {
    uuid: string
}
export type ClientIntermediaryToPostChatApiResponse = /** status 200  */ IntermediaryToChatEntities
export type ClientIntermediaryToPostChatApiArg = {
    /** intermediaryTo uuid */
    uuid: string
    clientIntermediaryToChatInsertDto: ClientIntermediaryToChatInsertDto
}
export type ClientIntermediaryToViewChatApiResponse = /** status 200  */ KeyValueDto
export type ClientIntermediaryToViewChatApiArg = {
    /** intermediaryTo uuid */
    uuid: string
}
export type ClientIntermediaryToDeleteChatApiResponse = /** status 200  */ KeyValueDto
export type ClientIntermediaryToDeleteChatApiArg = {
    /** intermediaryToChat uuid */
    chatUuid: string
    /** intermediaryTo uuid */
    uuid: any
}
export type ClientTeamActivityTeamActivityApiResponse = /** status 200  */ ClientTeamActivityResponseDto
export type ClientTeamActivityTeamActivityApiArg = {
    page?: number
}
export type ClientTeamActivityPutKeyStatusApiResponse = /** status 200  */ TeamActivityClass
export type ClientTeamActivityPutKeyStatusApiArg = {
    key: string
}
export type ClientTeamActivityPutTeamMemberUuidStatusApiResponse = /** status 200  */ TeamActivityClass[]
export type ClientTeamActivityPutTeamMemberUuidStatusApiArg = {
    uuid: string
}
export type ClientIntermediaryDocumentRealEstateUuidApiResponse = /** status 200  */ IntermediaryToDocumentEntities[]
export type ClientIntermediaryDocumentRealEstateUuidApiArg = {
    /** realEstate uuid */
    uuid: string
}
export type ClientIntermediaryDocumentIntermediaryToUuidApiResponse = /** status 200  */ ClientIntermediaryDocumentResponseDto
export type ClientIntermediaryDocumentIntermediaryToUuidApiArg = {
    /** intermediary to uuid */
    uuid: string
}
export type ClientIntermediaryDocumentPostPreviewApiResponse = unknown
export type ClientIntermediaryDocumentPostPreviewApiArg = {
    uuid: string
}
export type ClientRealEstateEndedRealEstatePostRealEstateAssigneeApiResponse = /** status 200  */ EndedRealEstateEntities
export type ClientRealEstateEndedRealEstatePostRealEstateAssigneeApiArg = {
    clientRealEstateEndedRealEstateInsertDto: ClientRealEstateEndedRealEstateInsertDto
}
export type ClientRealEstateEndedRealEstateRevertEndedRealEstateApiResponse = /** status 200  */ KeyValueDto
export type ClientRealEstateEndedRealEstateRevertEndedRealEstateApiArg = {
    realEstateUuid: string
}
export type ClientKpiGetBeaconApiResponse = /** status 200  */ Blob
export type ClientKpiGetBeaconApiArg = {
    uuid: string
    name:
        | 'market_publish_request'
        | 'real_estate_count'
        | 'send_intermediary'
        | 'receive_intermediary'
        | 'open_name_count'
        | 'external_recommend_count'
        | 'recommend_count'
        | 'company_count'
        | 'member_count'
        | 'customer_count'
        | 'market_real_estate_count'
        | 'add_real_estate'
        | 'delete_real_estate'
        | 'customer_company_count'
        | 'open_intermediary_mail'
}
export type ClientCustomerFilterPostCustomerFilterApiResponse = /** status 200  */ CustomerFilterEntities
export type ClientCustomerFilterPostCustomerFilterApiArg = {
    clientCustomerFilterInsertDto: ClientCustomerFilterInsertDto
}
export type ClientCustomerFilterPutUuidApiResponse = /** status 200  */ CustomerFilterEntities
export type ClientCustomerFilterPutUuidApiArg = {
    uuid: string
    clientCustomerFilterUpdateDto: ClientCustomerFilterUpdateDto
}
export type ClientCustomerFilterDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type ClientCustomerFilterDeleteUuidApiArg = {
    uuid: string
}
export type ClientCustomerFilterFindFiltersByCustomerApiResponse = /** status 200  */ CustomerFilterEntities[]
export type ClientCustomerFilterFindFiltersByCustomerApiArg = {
    uuid: string
}
export type ClientCustomerFilterFindCustomersByFiltersAndRealEstateApiResponse = /** status 200  */ CustomerEntities[]
export type ClientCustomerFilterFindCustomersByFiltersAndRealEstateApiArg = {
    uuid: string
}
export type ClientAuthSignInApiResponse = /** status 200  */ UserSignInResultDto
export type ClientAuthSignInApiArg = {
    userSignInDto: UserSignInDto
}
export type ClientAuthClientMeApiResponse = /** status 200  */ TeamMemberEntities
export type ClientAuthClientMeApiArg = void
export type ClientAuthSignOutApiResponse = unknown
export type ClientAuthSignOutApiArg = void
export type AdminCompanyContractPostContractApiResponse = /** status 200  */ CompanyContractEntities
export type AdminCompanyContractPostContractApiArg = {
    adminCompanyContractInsertDto: AdminCompanyContractInsertDto
}
export type AdminCompanyContractPutUuidApiResponse = /** status 200  */ CompanyContractEntities
export type AdminCompanyContractPutUuidApiArg = {
    uuid: string
    adminCompanyContractUpdateDto: AdminCompanyContractUpdateDto
}
export type AdminCompanyContractDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type AdminCompanyContractDeleteUuidApiArg = {
    uuid: string
}
export type AdminCompanyContractPutUuidCancelApiResponse = /** status 200  */ KeyValueDto
export type AdminCompanyContractPutUuidCancelApiArg = {
    uuid: string
}
export type AdminCompanyCompanyApiResponse = /** status 200  */ ListCountDto
export type AdminCompanyCompanyApiArg = {
    page?: number
    name?: string
    includeCanceledCompany?: boolean
    order?: 'name'
    sort?: 'ASC' | 'DESC'
}
export type AdminCompanyPostCompanyApiResponse = /** status 200  */ CompanyEntities
export type AdminCompanyPostCompanyApiArg = {
    adminCompanyInsertDto: AdminCompanyInsertDto
}
export type AdminCompanyUuidApiResponse = /** status 200  */ CompanyEntities
export type AdminCompanyUuidApiArg = {
    uuid: string
}
export type AdminCompanyPutUuidApiResponse = /** status 200  */ CompanyEntities
export type AdminCompanyPutUuidApiArg = {
    uuid: string
    body: AdminCompanyUpdateDto | AdminCompanyDetailUpdateDto
}
export type AdminCompanyDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type AdminCompanyDeleteUuidApiArg = {
    uuid: string
}
export type AdminCompanyPutUuidLogoApiResponse = /** status 200  */ CompanyEntities
export type AdminCompanyPutUuidLogoApiArg = {
    uuid: string
    adminCompanyLogoUpdateDto: AdminCompanyLogoUpdateDto
}
export type AdminDownloadGetDownloadListApiResponse = /** status 200  */ AdminDownloadListResponseDto
export type AdminDownloadGetDownloadListApiArg = {
    page?: number
}
export type AdminDownloadUuidApiResponse = /** status 200  */ DownloadEntities
export type AdminDownloadUuidApiArg = {
    uuid: string
}
export type AdminFaqGetFaqListApiResponse = /** status 200  */ AdminFaqListResponseDto
export type AdminFaqGetFaqListApiArg = {
    cauuid?: string
    page?: number
}
export type AdminFaqPostFaqApiResponse = /** status 200  */ KeyValueDto
export type AdminFaqPostFaqApiArg = {
    adminFaqUpdateDto: AdminFaqUpdateDto
}
export type AdminFaqUuidApiResponse = /** status 200  */ FaqContentEntities
export type AdminFaqUuidApiArg = {
    uuid: string
}
export type AdminFaqPutUuidApiResponse = /** status 200  */ KeyValueDto
export type AdminFaqPutUuidApiArg = {
    uuid: string
    adminFaqUpdateDto: AdminFaqUpdateDto
}
export type AdminFaqDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type AdminFaqDeleteUuidApiArg = {
    uuid: string
}
export type AdminFileGetPreSignedUrlApiResponse = /** status 200  */ MediaFileUploadResponseDto
export type AdminFileGetPreSignedUrlApiArg = {
    mediaFileUploadDto: MediaFileUploadDto
}
export type AdminFileSetFileSizeApiResponse = /** status 200  */ KeyValueDto
export type AdminFileSetFileSizeApiArg = {
    body: string
}
export type AdminFileGetOneFileDetailApiResponse = /** status 200  */ FileEntities
export type AdminFileGetOneFileDetailApiArg = {
    uuid: string
}
export type AdminInquiryGetInquiryListApiResponse = /** status 200  */ AdminInquiryListResponseDto
export type AdminInquiryGetInquiryListApiArg = {
    page?: number
}
export type AdminInquiryUuidApiResponse = /** status 200  */ InquiryEntities
export type AdminInquiryUuidApiArg = {
    uuid: string
}
export type AdminInquiryPutUuidApiResponse = /** status 200  */ KeyValueDto
export type AdminInquiryPutUuidApiArg = {
    uuid: string
    inquiryEntities: InquiryEntities
}
export type AdminInquiryDeleteUuidApiResponse = /** status 200  */ KeyValueDto
export type AdminInquiryDeleteUuidApiArg = {
    uuid: string
}
export type AdminMediaGetFileApiResponse = /** status 200  */ Blob
export type AdminMediaGetFileApiArg = {
    uuid: string
    filename?: string
    width?: number
    height?: number
}
export type AdminNotificationTemplateGetNotificationTemplateApiResponse =
    /** status 200  */ AdminNotificationTemplateListResponseDto
export type AdminNotificationTemplateGetNotificationTemplateApiArg = {
    page?: number
    key?: string
    name?: string
    mailSubject?: string
    order?: 'key' | 'name' | 'mailSubject'
    sort?: 'ASC' | 'DESC'
}
export type AdminNotificationTemplatePostNotificationTemplateApiResponse = /** status 200  */ KeyValueDto
export type AdminNotificationTemplatePostNotificationTemplateApiArg = {
    adminNotificationTemplateInsertDto: AdminNotificationTemplateInsertDto
}
export type AdminNotificationTemplateGetNotificationTemplateByUuidApiResponse = /** status 200  */ NotificationTemplateEntities
export type AdminNotificationTemplateGetNotificationTemplateByUuidApiArg = {
    uuid: string
}
export type AdminNotificationTemplatePutUuidApiResponse = /** status 200  */ KeyValueDto
export type AdminNotificationTemplatePutUuidApiArg = {
    uuid: string
    adminNotificationTemplateUpdateDto: AdminNotificationTemplateUpdateDto
}
export type AdminNotificationTemplateDeleteUuidApiResponse = unknown
export type AdminNotificationTemplateDeleteUuidApiArg = {
    uuid: string
}
export type AdminTeamPostTeamApiResponse = /** status 200  */ TeamEntities
export type AdminTeamPostTeamApiArg = {
    adminTeamInsertDto: AdminTeamInsertDto
}
export type AdminTeamPutUuidApiResponse = /** status 200  */ TeamEntities
export type AdminTeamPutUuidApiArg = {
    uuid: string
    adminTeamUpdateDto: AdminTeamUpdateDto
}
export type AdminTeamMemberPostTeamMemberApiResponse = /** status 200  */ TeamMemberEntities
export type AdminTeamMemberPostTeamMemberApiArg = {
    adminTeamMemberInsertDto: AdminTeamMemberInsertDto
}
export type AdminTeamMemberReplaceUuidApiResponse = /** status 200  */ KeyValueDto
export type AdminTeamMemberReplaceUuidApiArg = {
    adminTeamMemberReplaceDto: AdminTeamMemberReplaceDto
}
export type AdminTeamMemberPutUuidApiResponse = /** status 200  */ TeamMemberEntities
export type AdminTeamMemberPutUuidApiArg = {
    uuid: string
    adminTeamMemberUpdateDto: AdminTeamMemberUpdateDto
}
export type AdminTeamMemberDeleteUuidApiResponse = unknown
export type AdminTeamMemberDeleteUuidApiArg = {
    uuid: string
}
export type AdminPlanPlanApiResponse = /** status 200  */ ListCountDto
export type AdminPlanPlanApiArg = void
export type AdminReportTemplateGetReportTemplateApiResponse = /** status 200  */ AdminReportTemplateListResponseDto
export type AdminReportTemplateGetReportTemplateApiArg = {
    page?: number
}
export type AdminReportTemplatePostReportTemplateApiResponse = /** status 200  */ KeyValueDto
export type AdminReportTemplatePostReportTemplateApiArg = {
    adminReportTemplateInsertDto: AdminReportTemplateInsertDto
}
export type AdminReportTemplateGetReportTemplateByUuidApiResponse = /** status 200  */ ReportTemplateEntities
export type AdminReportTemplateGetReportTemplateByUuidApiArg = {
    uuid: string
}
export type AdminReportTemplatePutUuidApiResponse = /** status 200  */ KeyValueDto
export type AdminReportTemplatePutUuidApiArg = {
    uuid: string
    adminReportTemplateUpdateDto: AdminReportTemplateUpdateDto
}
export type AdminReportTemplateDeleteUuidApiResponse = unknown
export type AdminReportTemplateDeleteUuidApiArg = {
    uuid: string
}
export type FileMeta = {}
export type FileEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    filename: string
    mimeType: string
    path: string
    filesize: number
    uploadType: 0 | 1
    meta?: FileMeta | null
}
export type PlanEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    name: string
    price: number
}
export type CompanyContractTeamFeatureCondition = {
    limit: number
    nestedLimit: number
    memberLimit: number
    enableLimitIpAddress: boolean
    enableTrackingUser: boolean
}
export type CompanyContractRealEstateFeatureCondition = {
    limit: number
    assignedMemberLimit: number
    enableMapPinColor: boolean
}
export type CompanyContractCustomerFeatureCondition = {
    limit: number
    assignedMemberLimit: number
    enableExportCsv: boolean
}
export type CompanyContractIntermediaryFeatureCondition = {
    limit: number
    enableVote: boolean
}
export type CompanyContractUnitSet = {
    value: number
    unit: 'day' | 'week' | 'twoWeek' | 'month' | 'year'
}
export type CompanyContractMarketFeatureCondition = {
    openLimit?: CompanyContractUnitSet
    requestLimit?: CompanyContractUnitSet
}
export type CompanyContractRecommendFeatureCondition = {
    matchingLimit?: CompanyContractUnitSet
}
export type CompanyContractConnectionFeatureCondition = {
    openLimit?: CompanyContractUnitSet
}
export type CompanyContractEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    company: object
    plan: PlanEntities
    teamFeatureCondition: CompanyContractTeamFeatureCondition
    realEstateFeatureCondition: CompanyContractRealEstateFeatureCondition
    customerFeatureCondition: CompanyContractCustomerFeatureCondition
    intermediaryFeatureCondition: CompanyContractIntermediaryFeatureCondition
    marketFeatureCondition?: CompanyContractMarketFeatureCondition
    recommendFeatureCondition?: CompanyContractRecommendFeatureCondition
    connectionFeatureCondition?: CompanyContractConnectionFeatureCondition
    startAt: string
    EndAt: string
}
export type MemberRole = {
    system: boolean
    team: 0 | 1 | 2
    realestate: 1 | 2
    customer: 1 | 2
    intermediary: 1 | 2
    company: 0 | 1 | 2
    approvalFlow: number
}
export type MemberSetting = {
    mail: 1 | 2 | 3 | 4
    sendToString: 1 | 2
}
export type TeamMemberInvestmentConditionEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    teamMember: TeamMemberEntities
    isShareCondition: boolean
    useType: string[]
    area: number[]
    price: number
    prices: number[]
    buildingAgeType: number
    purchaseTimeType: number
    grossRateType: number
    netRateType: number
    ownershipType: number
    seismicStandardType: number
    legalComplianceType: number
}
export type RealEstateUseTypeEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    name: string
    overview: string
    sort: number
}
export type CustomerInvestmentConditionEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    customer: CustomerEntities
    useType: string[]
    area: number[]
    price: number
    prices: number[]
    buildingAgeType: number
    purchaseTimeType: number
    grossRateType: number
    netRateType: number
    ownershipType: number
    seismicStandardType: number
    legalComplianceType: number
    createdBy: TeamMemberEntities
    updatedBy: TeamMemberEntities
    deletedBy: TeamMemberEntities
}
export type CustomerInvestmentConditionRequestEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    customer: CustomerEntities
    sentBy?: TeamMemberEntities
    answeredAt: string
    lastRemindAt: string
    useType: string[]
    area: number[]
    price: number
    prices: number[]
    buildingAgeType: number
    purchaseTimeType: number
    grossRateType?: number
    netRateType?: number
    ownershipType: number
    seismicStandardType: number
    legalComplianceType: number
}
export type CustomerLabelEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    team: TeamEntities
    name: string
    bgColor: string
    letterColor: string
    sort: number
    customers?: CustomerEntities[]
}
export type CustomerAssigneeEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    customer: CustomerEntities
    assignedTo: TeamMemberEntities
    isPrimary: boolean
    createdBy?: TeamMemberEntities
    updatedBy?: TeamMemberEntities
    deletedBy?: TeamMemberEntities
}
export type RealEstateLabelEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    team: TeamEntities
    name: string
    bgColor: string
    letterColor: string
    sort: number
    realEstates?: RealEstateEntities[]
    realEstateRevisions?: RealEstateRevisionEntities[]
}
export type IntermediaryToDocumentEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    intermediaryTo: IntermediaryToEntities
    documentFrom: RealEstateDocumentEntities
    file: FileEntities
    downloadCount: number
    previewCount: number
}
export type RealEstateDocumentLabelEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    company: CompanyEntities
    name: string
    bgColor: string
    letterColor: string
    sort: number
    documents?: RealEstateDocumentEntities[]
}
export type RealEstateDocumentEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    realEstate: RealEstateEntities
    category?: string
    file?: FileEntities
    sort: number
    isCover: boolean
    coverFile?: FileEntities
    isMap: boolean
    isAttachable: boolean
    downloadCount: number
    previewCount: number
    intermediaryDocumentFrom?: IntermediaryToDocumentEntities
    createdBy?: TeamMemberEntities
    updatedBy?: TeamMemberEntities
    deletedBy?: TeamMemberEntities
    labels?: RealEstateDocumentLabelEntities[]
}
export type RecommendResultExternalMemberEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    realEstate: RealEstateEntities
    teamMember?: TeamMemberEntities
    matchUseType: boolean
    matchArea: boolean
    matchPrice: boolean
    matchBuildingAge: boolean
    matchPurchaseTime: boolean
    matchGrossRate: boolean
    matchNetRate: boolean
    matchOwnership: boolean
    matchSeismicStandard: boolean
    matchLegalCompliance: boolean
    score: number
    isRecommendTarget: boolean
    isDisplay: boolean
    isDisabled: boolean
    openNameAt: string
    openNameBy?: TeamMemberEntities
    intermediaryAt: string
    intermediaryBy?: TeamMemberEntities
    intermediaryFrom: IntermediaryToEntities
    checkedAt: string
}
export type MarketRealEstateEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    realEstate: RealEstateEntities
    publishedBy?: TeamMemberEntities
    publishedAt: string
    showAddress?: string
}
export type MarketPublishRequestEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    requestedMember?: TeamMemberEntities
    realEstate: RealEstateEntities
    openedNameAt: string
    openedNameBy?: TeamMemberEntities
    publishedAt: string
    publishedBy?: TeamMemberEntities
    intermediaryFrom: IntermediaryToEntities
    checkedAt: string
    comment?: string
}
export type RealEstateAssigneeEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    realEstate: RealEstateEntities
    assignedTo: TeamMemberEntities
    isPrimary: boolean
    createdBy?: TeamMemberEntities
    updatedBy?: TeamMemberEntities
    deletedBy?: TeamMemberEntities
}
export type RealEstateMemoEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    realEstate: RealEstateEntities
    content: string
    attachment: FileEntities
    createdBy?: TeamMemberEntities
    updatedBy?: TeamMemberEntities
    deletedBy?: TeamMemberEntities
}
export type EndedRealEstateEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    realEstate: RealEstateEntities
    endedAt?: string
    paymentAt?: string
    price?: number
    endedType?: 1 | 2
    involvement?: 1 | 2 | 3 | 4 | 5
    isKimar?: boolean
    isMarket?: boolean
    createdBy?: TeamMemberEntities
}
export type RealEstateEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    team: TeamEntities
    name: string
    useType: RealEstateUseTypeEntities
    address?: string
    latitude?: number
    longitude?: number
    station?: string
    informationSource?: string
    acquiredAt?: string
    seller?: string
    builder?: string
    buildingName?: string
    totalFloorSize?: number
    totalFloorSizeTsubo?: number
    rentableAreaSize?: number
    rentableAreaSizeTsubo?: number
    buildAt?: string
    buildAtDateType?: number
    structureType?: number
    floor?: number
    undergroundFloor?: number
    zoningType?: number
    zoningType2?: number
    floorAreaRatio?: number
    floorAreaRatio2?: number
    buildingRate?: number
    buildingRate2?: number
    grossRate?: number
    netRate?: number
    price?: number
    taxType: number
    saleTimeType?: number
    seismicStandardType?: number
    legalComplianceType?: number
    other?: string
    isRecommendTarget: boolean
    intermediaryFrom?: IntermediaryToEntities
    sendStatus: number
    receiveStatus: number
    assumeIncome: number
    presentIncome: number
    buildingNumber: string
    buildingRight: string
    certificateOfCompletionOfConfirmation: number
    certificateOfFinalInspection: number
    cityPlanningArea: number
    deliveryAt: string
    deliveryAtDateType: number
    facility: string
    firePreventionDistricts: number
    floorSize: number
    floorSizeTsubo: number
    frontalRoad: string
    heightControl: string
    landCategory: string
    landRight: string
    landShape: string
    modeOfTransaction: number
    salesDestinationType: number
    otherControl: string
    parcelNumber: string
    surveyDrawing: number
    surveyedLandSize: number
    surveyedLandSizeTsubo: number
    totalUnits: number
    registeredLandSize: number
    registeredLandSizeTsubo: number
    marketComment?: string
    createdBy?: TeamMemberEntities
    updatedBy?: TeamMemberEntities
    deletedBy?: TeamMemberEntities
    labels?: RealEstateLabelEntities[]
    documents?: RealEstateDocumentEntities[]
    revisions?: RealEstateRevisionEntities[]
    recommends?: RecommendResultCustomerEntities[]
    connections?: RecommendResultExternalMemberEntities[]
    markets?: MarketRealEstateEntities[]
    publishRequests?: MarketPublishRequestEntities[]
    assignees?: RealEstateAssigneeEntities[]
    memoList?: RealEstateMemoEntities[]
    ended?: EndedRealEstateEntities
    option: number
}
export type RecommendResultCustomerEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    realEstate: RealEstateEntities
    customer?: CustomerEntities
    matchUseType: boolean
    matchArea: boolean
    matchPrice: boolean
    matchBuildingAge: boolean
    matchPurchaseTime: boolean
    matchGrossRate: boolean
    matchNetRate: boolean
    matchOwnership: boolean
    matchSeismicStandard: boolean
    matchLegalCompliance: boolean
    score: number
    isRecommendTarget: boolean
    isDisplay: boolean
    isDisabled: boolean
    checkedAt: string
}
export type CustomerMemoEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    customer: CustomerEntities
    content: string
    attachment: FileEntities
    createdBy?: TeamMemberEntities
    updatedBy?: TeamMemberEntities
    deletedBy?: TeamMemberEntities
}
export type CustomerReferenceEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    customer: CustomerEntities
    referenceTo: TeamEntities
    createdBy?: TeamMemberEntities
    updatedBy?: TeamMemberEntities
    deletedBy?: TeamMemberEntities
}
export type CustomerFilterEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    customer: CustomerEntities
    team: TeamEntities
    useTypes?: RealEstateUseTypeEntities[]
    prices?: number[]
    buildingAgeTypes?: number[]
    grossRateTypes?: number[]
}
export type CustomerEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    team: TeamEntities
    name: string
    nameSound: string
    intermediaryName?: string
    email: string
    position: string
    departmentName: string
    phone: string
    phoneExtension: string
    mobile: string
    companyName: string
    companyNameSound: string
    companyAddress: string
    companyPhone: string
    companyFax: string
    relatedMember?: TeamMemberEntities
    isRecommendTarget: boolean
    sendStatus: object
    receiveStatus: object
    investmentConditions?: CustomerInvestmentConditionEntities[]
    investmentConditionRequests?: CustomerInvestmentConditionRequestEntities[]
    registeredType: number
    labels?: CustomerLabelEntities[]
    intermediaryToList?: IntermediaryToEntities[]
    assignees?: CustomerAssigneeEntities[]
    recommends?: RecommendResultCustomerEntities[]
    memoList?: CustomerMemoEntities[]
    customerReferences?: CustomerReferenceEntities[]
    customerFilters?: CustomerFilterEntities[]
    createdBy?: TeamMemberEntities
    updatedBy?: TeamMemberEntities
    deletedBy?: TeamMemberEntities
}
export type IntermediaryToVoteEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    intermediaryTo: IntermediaryToEntities
    isAcquiredOtherRoute: boolean
    isInterested: boolean
}
export type IntermediaryToChatEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    intermediaryTo: IntermediaryToEntities
    sentBy?: TeamMemberEntities
    isSentTeam: boolean
    content: string
    attachment: FileEntities
}
export type IntermediaryToEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    intermediary: IntermediaryEntities
    destinationTo?: CustomerEntities
    referenceTo?: IntermediaryToEntities
    intermediaryToDocuments?: IntermediaryToDocumentEntities[]
    intermediaryToVote?: IntermediaryToVoteEntities[]
    intermediaryToChat?: IntermediaryToChatEntities[]
    relatedRealEstate: RealEstateEntities[]
    openedAt: string
    lastAccessAt: string
    expiresAt: string
    reviewFinishedAt: string
    intermediaryFinishedAt: string
    intermediaryFinishMessage: string
    mailOpenedAt: string
    progress: number
    fromConnection: RecommendResultExternalMemberEntities
    fromPublishRequest: MarketPublishRequestEntities
}
export type RealEstateRevisionEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    team: TeamEntities
    name: string
    useType: RealEstateUseTypeEntities
    address?: string
    latitude?: number
    longitude?: number
    station?: string
    informationSource?: string
    acquiredAt?: string
    seller?: string
    builder?: string
    buildingName?: string
    totalFloorSize?: number
    totalFloorSizeTsubo?: number
    rentableAreaSize?: number
    rentableAreaSizeTsubo?: number
    buildAt?: string
    buildAtDateType?: number
    structureType?: number
    floor?: number
    undergroundFloor?: number
    zoningType?: number
    zoningType2?: number
    floorAreaRatio?: number
    floorAreaRatio2?: number
    buildingRate?: number
    buildingRate2?: number
    grossRate?: number
    netRate?: number
    price?: number
    taxType: number
    saleTimeType?: number
    seismicStandardType?: number
    legalComplianceType?: number
    other?: string
    isRecommendTarget: boolean
    intermediaryFrom?: IntermediaryToEntities
    sendStatus: number
    receiveStatus: number
    assumeIncome: number
    presentIncome: number
    buildingNumber: string
    buildingRight: string
    certificateOfCompletionOfConfirmation: number
    certificateOfFinalInspection: number
    cityPlanningArea: number
    deliveryAt: string
    deliveryAtDateType: number
    facility: string
    firePreventionDistricts: number
    floorSize: number
    floorSizeTsubo: number
    frontalRoad: string
    heightControl: string
    landCategory: string
    landRight: string
    landShape: string
    modeOfTransaction: number
    salesDestinationType: number
    otherControl: string
    parcelNumber: string
    surveyDrawing: number
    surveyedLandSize: number
    surveyedLandSizeTsubo: number
    totalUnits: number
    registeredLandSize: number
    registeredLandSizeTsubo: number
    marketComment?: string
    createdBy?: TeamMemberEntities
    updatedBy?: TeamMemberEntities
    deletedBy?: TeamMemberEntities
    realEstate: RealEstateEntities
    labels?: RealEstateLabelEntities[]
    intermediaries?: IntermediaryEntities[]
}
export type IntermediaryEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    sourceRealEstateRevision: RealEstateRevisionEntities
    sentBy?: TeamMemberEntities
    price: number
    taxType: number
    message: string
    authType: object
    authPassword: string
    canReIntermediary: boolean
    intermediaryToList?: IntermediaryToEntities[]
}
export type TeamMemberSignatureEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    teamMember: TeamMemberEntities
    signature: string
}
export type ClientInfo = {
    userAgent: string
    ipAddress: string
}
export type TeamMemberSessionEntities = {
    createdAt: string
    updatedAt: string
    credential: string
    expire: string
    clientInfo: ClientInfo
}
export type TeamMemberEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    team: TeamEntities
    departmentName: string
    position: string
    name: string
    nameSound: string
    email: string
    emailConfirmedAt: string
    password: string
    lastChangePasswordAt: string
    phone: string
    phoneExtension: string
    mobile: string
    sort: number
    roles: MemberRole
    settings: MemberSetting
    investmentConditions?: TeamMemberInvestmentConditionEntities[]
    intermediaries?: IntermediaryEntities[]
    isRecommendTarget: boolean
    remainedAt: string
    sentRealEstateUuid: string
    customers?: CustomerEntities[]
    signatures?: TeamMemberSignatureEntities[]
    sessions?: TeamMemberSessionEntities[]
}
export type TeamEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    company: CompanyEntities
    name: string
    overview: string
    address: string
    phone: string
    fax: string
    latitude: number
    longitude: number
    parentTeam?: TeamEntities
    childTeams?: TeamEntities[]
    teamMembers: TeamMemberEntities[]
}
export type CompanyEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    name: string
    sound: string
    address: string
    phone: string
    fax: string
    latitude: number
    longitude: number
    logoFile: FileEntities
    bandImage: FileEntities
    ipRestriction: string[]
    isKpiTarget: boolean
    realEstateListKeys: string[]
    contracts: CompanyContractEntities[]
    teams: TeamEntities[]
    labels?: RealEstateDocumentLabelEntities[]
}
export type CompanyUpdateDto = {
    name: string
    sound: string
    address: string
    phone: string
    fax: string
}
export type CompanyUpdateLogoDto = {
    logo: FileEntities
}
export type CompanyUpdateRealEstateDetailBandDto = {
    bandImage: FileEntities
}
export type CompanyUpdateRealEstateListKeysDto = {
    keys: string[]
}
export type KeyValueDto = {
    key: string
    value: object
}
export type ConditionRequestUpdateDto = {
    useType: string[]
    area: number[]
    prices: number[]
    buildingAgeType: number
    purchaseTimeType: number
    grossRateType?: number
    netRateType?: number
    ownershipType?: number
    seismicStandardType?: number
    legalComplianceType?: number
}
export type ClientCustomerAssigneeInsertDto = {
    customer: CustomerEntities
    member: TeamMemberEntities
    isPrimary: boolean
}
export type ClientCustomerAssigneeUpdateDto = {
    isPrimary: boolean
}
export type ListCountDto = {
    list: object[]
    count: number
}
export type ClientCustomerInsertCustomerDto = {
    name: string
    nameSound: string
    intermediaryName: string
    email: string
    position: string
    departmentName: string
    phone: string
    phoneExtension: string
    mobile: string
    companyName: string
    companyNameSound: string
    companyAddress: string
    companyPhone: string
    companyFax: string
    isRecommendTarget: boolean
}
export type ClientCustomerInsertDto = {
    customer: ClientCustomerInsertCustomerDto
    investmentCondition: ConditionRequestUpdateDto
}
export type ClientCustomerMultipleDto = {
    customer: CustomerEntities[]
    assign?: TeamMemberEntities[]
    unassign?: TeamMemberEntities[]
    label?: CustomerLabelEntities[]
    unlabelled?: CustomerLabelEntities[]
    request?: boolean
    delete?: boolean
}
export type ClientCustomerImportDto = {
    companyName: string
    companyNameSound: string
    departmentName: string
    position: string
    name: string
    nameSound: string
    intermediaryName: string
    companyAddress: string
    phone: string
    phoneExtension: string
    companyPhone: string
    companyFax: string
    email: string
    mobile: string
    labels: string
    assignees: string
    conditionUseType: string
    conditionArea: string
    conditionPrices: string
    conditionBuildingAgeType: string
    conditionPurchaseTimeType: string
    conditionGrossRateType: string
    conditionNetRateType: string
    conditionSeismicStandardType: string
    conditionLegalComplianceType: string
    isDuplicate: boolean
}
export type ClientCustomerImportRequestDto = {
    fileUuid: string
}
export type ClientCustomerUpdateCustomerDto = {
    name: string
    nameSound: string
    intermediaryName: string
    position: string
    departmentName: string
    phone: string
    phoneExtension: string
    mobile: string
    companyName: string
    companyNameSound: string
    companyAddress: string
    companyPhone: string
    companyFax: string
    isRecommendTarget: boolean
}
export type ClientCustomerUpdateDto = {
    customer: ClientCustomerUpdateCustomerDto
    investmentCondition: ConditionRequestUpdateDto
}
export type ClientCustomerLabelUpdateDto = {
    labelUuids: string[]
}
export type ClientCustomerIntermediaryResponseDto = {
    sending: IntermediaryToEntities[]
    sended: IntermediaryToEntities[]
    receiving: IntermediaryToEntities[]
    received: IntermediaryToEntities[]
}
export type ClientIntermediaryFinishDto = {
    intermediaryUuidList: string[]
    message?: string
    isSendNotification?: boolean
}
export type ClientIntermediaryReviewFinishDto = {
    intermediaryToUuid: string
    sendNotification: boolean
}
export type InsertResult = {}
export type ClientCustomerLabelEditDto = {
    uuid?: string
    name: string
    bgColor: string
    letterColor: string
}
export type ClientCustomerLabelOrderDto = {
    labelUuids: string[]
}
export type UpdateResult = {}
export type ClientCustomerMemoInsertDto = {
    customer: CustomerEntities
    content?: string
    attachmentUuid?: string
}
export type ClientDashboardStatusDto = {
    intermediaryCount: number
    customerCount: number
    realEstateCount: number
    newestPublishRequest: MarketPublishRequestEntities
}
export type DownloadExecuteDto = {
    email: string
    name: string
    phone: string
    companyName: string
    departmentName?: string
    position?: string
    trigger: number
    triggerContent?: string
    issue: string
    freeContent?: string
    intermediaryFrom?: IntermediaryToEntities
}
export type FaqContentEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    question: string
    answer: string
    isDisplayHome: boolean
    sort: number
}
export type FaqListResponseDto = {
    list: FaqContentEntities[]
    count: number
}
export type MediaFileUploadResponseDto = {
    url: string
    fileEntity: FileEntities
}
export type MediaFileUploadDto = {
    meta?: object
    tag?: string
    sort?: number
    originalFilename: string
    mimetype: string
}
export type MediaFileUploadSetSizeDto = {
    uuid: string
}
export type InquiryInsertDto = {
    email: string
    name: string
    phone: string
    companyName: string
    subject: string[]
    content?: string
}
export type ClientIntermediaryInsertDocumentsDto = {
    customerUuid: string
    documentUuids: string[]
    fromConnectionUuid?: string
    fromPublishRequestUuid?: string
}
export type ClientIntermediaryInsertDto = {
    customers: ClientIntermediaryInsertDocumentsDto[]
    realEstateUuid: string
    price: number
    taxType: number
    title: string
    message: string
    setPassword: boolean
    password: string
}
export type HealthDto = {
    key: string
    value: object
}
export type ClientIntermediaryDocumentPreviewDto = {
    documentUuid: string
    method: string
}
export type ClientIntermediaryToVoteInsertDto = {
    intermediaryUuid: string
    isAcquiredOtherRoute: boolean
    isInterested: boolean
}
export type ValueDto = {
    value: string
}
export type NotificationTemplateEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    team: TeamEntities
    member: TeamEntities
    key: string
    name: string
    mailSubject: string
    mailHtmlBody: string
    mailHtmlButtonTitle: string
    mailHtmlAfterMessage: string
    body: string
}
export type ClientNotificationTemplateUpdateDto = {
    uuid: string
    key: string
    templateType: number
    name: string
    mailSubject: string
    mailHtmlBody: string
    mailHtmlButtonTitle: string
    mailHtmlAfterMessage: string
}
export type ClientPageViewInsertDto = {
    path: string
}
export type ClientRealEstateAssigneeInsertDto = {
    realEstate: RealEstateEntities
    member: TeamMemberEntities
    isPrimary: boolean
}
export type ClientRealEstateAssigneeUpdateDto = {
    isPrimary: boolean
}
export type ClientRealEstateConnectionListDto = {
    uuid?: string
    executionAt: string
    orderScore: number
    memberName?: string
    memberCompanyName?: string
    intermediaryAt?: string
    purchaseCondition?: TeamMemberInvestmentConditionEntities
    isRegisteredCustomer?: boolean
    isCancelledMember?: boolean
    checkedAt?: string
}
export type ClientRealEstateConnectionResponseDto = {
    availableOpenCount: number
    count?: number
    openedSum?: number
    list?: ClientRealEstateConnectionListDto[]
    uncheckedExternalRecommendCount?: number
}
export type ClientRealEstateInsertDto = {
    isRecommendTarget: boolean
    isMarketTarget: boolean
    name: string
    address: string
    latitude?: number
    longitude?: number
    parcelNumber?: string
    useType: RealEstateUseTypeEntities
    saleTimeType?: number
    price?: number
    taxType?: number
    buildAt?: string
    buildAtDateType?: number
    station?: string
    landCategory?: string
    landShape?: string
    landRight?: string
    surveyDrawing?: number
    registeredLandSize?: number
    registeredLandSizeTsubo?: number
    surveyedLandSize?: number
    surveyedLandSizeTsubo?: number
    frontalRoad?: string
    buildingRight?: string
    buildingNumber?: string
    seismicStandardType?: number
    legalComplianceType?: number
    buildingName?: string
    builder?: string
    totalUnits?: number
    structureType?: number
    floorSize?: number
    floorSizeTsubo?: number
    totalFloorSize?: number
    totalFloorSizeTsubo?: number
    rentableAreaSize?: number
    rentableAreaSizeTsubo?: number
    floor?: number
    undergroundFloor?: number
    facility?: string
    certificateOfCompletionOfConfirmation?: number
    certificateOfFinalInspection?: number
    cityPlanningArea?: number
    zoningType?: number
    zoningType2?: number
    buildingRate?: number
    buildingRate2?: number
    floorAreaRatio?: number
    floorAreaRatio2?: number
    firePreventionDistricts?: number
    heightControl?: string
    otherControl?: string
    assumeIncome?: number
    presentIncome?: number
    grossRate?: number
    netRate?: number
    deliveryAt?: string
    deliveryAtDateType?: number
    modeOfTransaction?: number
    salesDestinationType?: number
    informationSource?: string
    acquiredAt?: string
    seller?: string
    marketComment?: string
    other?: string
}
export type ClientRealEstateProgressItemDto = {
    uuid: string
    progress: number
}
export type ClientRealEstateProgressDto = {
    items: ClientRealEstateProgressItemDto[]
}
export type ClientRealEstateActivityDto = {
    receiveNewChat: string[]
    sendNewChat: string[]
    newRecommend: string[]
    newConnection: string[]
    newPublishRequest: string[]
}
export type TeamActivityClass = {
    team: string
    teamMember: string
    name: string
    content: string
    option: string
    params: object
    id: string
    status: 'read'
    createdAt: number
    updatedAt: number
}
export type ClientRealEstateChatActivityDto = {
    receiveNewChat: TeamActivityClass[]
    sendNewChat: TeamActivityClass[]
}
export type ClientRealEstateListDto = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    team: TeamEntities
    name: string
    useType: RealEstateUseTypeEntities
    address?: string
    latitude?: number
    longitude?: number
    station?: string
    informationSource?: string
    acquiredAt?: string
    seller?: string
    builder?: string
    buildingName?: string
    totalFloorSize?: number
    totalFloorSizeTsubo?: number
    rentableAreaSize?: number
    rentableAreaSizeTsubo?: number
    buildAt?: string
    buildAtDateType?: number
    structureType?: number
    floor?: number
    undergroundFloor?: number
    zoningType?: number
    zoningType2?: number
    floorAreaRatio?: number
    floorAreaRatio2?: number
    buildingRate?: number
    buildingRate2?: number
    grossRate?: number
    netRate?: number
    price?: number
    taxType: number
    saleTimeType?: number
    seismicStandardType?: number
    legalComplianceType?: number
    other?: string
    isRecommendTarget: boolean
    intermediaryFrom?: IntermediaryToEntities
    sendStatus: number
    receiveStatus: number
    assumeIncome: number
    presentIncome: number
    buildingNumber: string
    buildingRight: string
    certificateOfCompletionOfConfirmation: number
    certificateOfFinalInspection: number
    cityPlanningArea: number
    deliveryAt: string
    deliveryAtDateType: number
    facility: string
    firePreventionDistricts: number
    floorSize: number
    floorSizeTsubo: number
    frontalRoad: string
    heightControl: string
    landCategory: string
    landRight: string
    landShape: string
    modeOfTransaction: number
    salesDestinationType: number
    otherControl: string
    parcelNumber: string
    surveyDrawing: number
    surveyedLandSize: number
    surveyedLandSizeTsubo: number
    totalUnits: number
    registeredLandSize: number
    registeredLandSizeTsubo: number
    marketComment?: string
    createdBy?: TeamMemberEntities
    updatedBy?: TeamMemberEntities
    deletedBy?: TeamMemberEntities
    labels?: RealEstateLabelEntities[]
    documents?: RealEstateDocumentEntities[]
    revisions?: RealEstateRevisionEntities[]
    recommends?: RecommendResultCustomerEntities[]
    connections?: RecommendResultExternalMemberEntities[]
    markets?: MarketRealEstateEntities[]
    publishRequests?: MarketPublishRequestEntities[]
    assignees?: RealEstateAssigneeEntities[]
    memoList?: RealEstateMemoEntities[]
    ended?: EndedRealEstateEntities
    option: number
    isSecret?: boolean
    voteAvailable?: boolean
}
export type ClientRealEstateUpdateDto = {
    isRecommendTarget: boolean
    isMarketTarget: boolean
    name: string
    address: string
    latitude?: number
    longitude?: number
    parcelNumber?: string
    useType: RealEstateUseTypeEntities
    saleTimeType?: number
    price?: number
    taxType?: number
    buildAt?: string
    buildAtDateType?: number
    station?: string
    landCategory?: string
    landShape?: string
    landRight?: string
    surveyDrawing?: number
    registeredLandSize?: number
    registeredLandSizeTsubo?: number
    surveyedLandSize?: number
    surveyedLandSizeTsubo?: number
    frontalRoad?: string
    buildingRight?: string
    buildingNumber?: string
    seismicStandardType?: number
    legalComplianceType?: number
    buildingName?: string
    builder?: string
    totalUnits?: number
    structureType?: number
    floorSize?: number
    floorSizeTsubo?: number
    totalFloorSize?: number
    totalFloorSizeTsubo?: number
    rentableAreaSize?: number
    rentableAreaSizeTsubo?: number
    floor?: number
    undergroundFloor?: number
    facility?: string
    certificateOfCompletionOfConfirmation?: number
    certificateOfFinalInspection?: number
    cityPlanningArea?: number
    zoningType?: number
    zoningType2?: number
    buildingRate?: number
    buildingRate2?: number
    floorAreaRatio?: number
    floorAreaRatio2?: number
    firePreventionDistricts?: number
    heightControl?: string
    otherControl?: string
    assumeIncome?: number
    presentIncome?: number
    grossRate?: number
    netRate?: number
    deliveryAt?: string
    deliveryAtDateType?: number
    modeOfTransaction?: number
    salesDestinationType?: number
    informationSource?: string
    acquiredAt?: string
    seller?: string
    marketComment?: string
    other?: string
}
export type ClientRealEstateLabelUpdateDto = {
    labelUuids: string[]
}
export type ClientRealEstateOutputDto = {
    price?: number
    taxType?: number
    assumeIncome?: number
    presentIncome?: number
    grossRate?: number
    netRate?: number
    other?: string
}
export type ClientRealEstatePublishRequestResponseDto = {
    uuid?: string
    requestedName?: string
    requestedCompanyName?: string
    comment?: string
    requestedAt: string
    publishedAt?: string
    purchaseCondition?: TeamMemberInvestmentConditionEntities
    isRegisteredCustomer?: boolean
    isCancelledMember?: boolean
    checkedAt?: string
}
export type ClientRealEstateMarketResponseDto = {
    openLimit: number
    openedSum: number
    remainingRequestCount: number
    count: number
    list?: ClientRealEstatePublishRequestResponseDto[]
    uncheckedRequestCount: number
}
export type ClientRealEstatePublishRequestInsertDto = {
    comment?: string
}
export type ClientRealEstateDocumentInsertDto = {
    document: FileEntities
    realEstate: RealEstateEntities
    isMap: boolean
    isCover: boolean
}
export type ClientRealEstateDocumentOrderDto = {
    realEstateUuid: string
    documentUuids: string[]
}
export type ClientRealEstateDocumentUpdateDto = {
    fileName: string
    labelUuids: string[]
    isMap: boolean
}
export type ClientRealEstateDocumentCoverUpdateDto = {
    isCover: boolean
}
export type ClientRealEstateDocumentAttachUpdateDto = {
    isAttachable: boolean
}
export type ClientRealEstateDocumentLabelEditDto = {
    uuid?: string
    name: string
    bgColor: string
    letterColor: string
}
export type ClientRealEstateDocumentLabelOrderDto = {
    labelUuids: string[]
}
export type ClientRealEstateLabelEditDto = {
    uuid?: string
    name: string
    bgColor: string
    letterColor: string
}
export type ClientRealEstateLabelOrderDto = {
    labelUuids: string[]
}
export type ClientMarketRealEstateDto = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    realEstate: RealEstateEntities
    publishedBy?: TeamMemberEntities
    publishedAt: string
    showAddress?: string
    areaType: number
    priceType: number
    buildingAgeType: number
    grossRateType: number
    netRateType: number
    isRequestedByUser: boolean
    isRequestedByCompany: boolean
    marketComment?: boolean
    isRegisteredByUserCompany: boolean
    isReceivingByUserCompany: boolean
    hasMarketPublishIntermediary: boolean
    recentRequestCount?: number
}
export type ClientRealEstateMarketListResponseDto = {
    list: ClientMarketRealEstateDto[]
    count: number
    availablePublishRequestCount: number
}
export type ClientRealEstateMemoInsertDto = {
    realEstate: RealEstateEntities
    content?: string
    attachmentUuid?: string
}
export type ClientRealEstateRecommendListDto = {
    uuid: string
    executionAt: string
    orderScore: number
    customer: CustomerEntities
    intermediaryTo?: IntermediaryToEntities
    checkedAt?: string
}
export type ClientRealEstateRecommendResponseDto = {
    count?: number
    list?: ClientRealEstateRecommendListDto[]
    uncheckedRecommendCount?: number
}
export type ClientTeamInsertDto = {
    name: string
    company: CompanyEntities
    parentTeam?: TeamEntities
}
export type TeamUpdateDto = {
    name: string
    overview?: string
}
export type ClientTeamMemberInsertDto = {
    name: string
    nameSound: string
    email: string
    sort: number
    roles: MemberRole
    team: TeamEntities
    departmentName?: string
    position?: string
    phone?: string
    phoneExtension?: string
    mobile?: string
}
export type ClientRealEstateActivityBooleanDto = {
    newChat: boolean
    newRecommend: boolean
    newConnection: boolean
    newPublishRequest: boolean
}
export type ClientTeamMemberOrderDto = {
    teamMemberUuids: string[]
}
export type ClientTeamMemberUpdateDto = {
    name: string
    nameSound: string
    email: string
    sort: number
    departmentName?: string
    position?: string
    phone?: string
    phoneExtension?: string
    mobile?: string
    roles: MemberRole
}
export type UserReminderDto = {
    email: string
}
export type ClientTeamMemberUpdatePasswordDto = {
    password: string
}
export type ClientTeamMemberConditionUpdateDto = {
    isShareCondition: boolean
    isRecommendTarget: boolean
    area: number[]
    buildingAgeType: number
    grossRateType: number
    legalComplianceType: number
    netRateType: number
    prices: number[]
    purchaseTimeType: number
    seismicStandardType: number
    useType: string[]
}
export type ClientTeamMemberUpdateMeDto = {
    name: string
    nameSound: string
    departmentName?: string
    position?: string
    phone?: string
    phoneExtension?: string
    mobile?: string
    signature?: string
    setting: MemberSetting
}
export type ClientUserMarketResponseDto = {
    publishRequestCount: number
}
export type UserResetPasswordDto = {
    password: string
}
export type ClientIntermediaryToProgressUpdateDto = {
    uuid: string
    progress: number
}
export type ClientIntermediaryToResponseDto = {
    intermediaryTo: IntermediaryToEntities
    realEstate?: RealEstateEntities
    requiredPassword?: boolean
    incorrectPassword?: boolean
    requiredLogin?: boolean
    voteAvailable?: boolean
}
export type ClientIntermediaryToChatResponseDto = {
    chatList: IntermediaryToChatEntities[]
    count: number
    intermediaryTo: IntermediaryToEntities
}
export type ClientIntermediaryToChatInsertDto = {
    content?: string
    attachmentUuid?: string
}
export type ClientTeamActivityResponseDto = {
    result: TeamActivityClass[]
}
export type ClientIntermediaryDocumentResponseDto = {
    documents: IntermediaryToDocumentEntities[]
    intermediaryTo: IntermediaryToEntities
}
export type ClientRealEstateEndedRealEstateInsertDto = {
    realEstateUuid: string
    endedAt?: string
    paymentAt?: string
    price?: number
    endedType?: 1 | 2
    involvement?: 1 | 2 | 3 | 4 | 5
    isKimar?: boolean
    isMarket?: boolean
    isSendNotification?: boolean
    finishMessage?: string
}
export type ClientCustomerFilterInsertDto = {
    customerUuid: string
    useTypes?: string[]
    prices?: number[]
    buildingAgeTypes?: number[]
    grossRateTypes?: number[]
}
export type ClientCustomerFilterUpdateDto = {
    customerUuid: string
    useTypes?: string[]
    prices?: number[]
    buildingAgeTypes?: number[]
    grossRateTypes?: number[]
}
export type UserSignInResultDto = {
    accessToken: string
    expire: string
    isMFA?: boolean
}
export type UserSignInDto = {
    email: string
    password: string
}
export type AdminCompanyContractInsertDto = {
    company: CompanyEntities
    plan: PlanEntities
    startAt: string
    customerFeatureCondition: CompanyContractCustomerFeatureCondition
    teamFeatureCondition: CompanyContractTeamFeatureCondition
    realEstateFeatureCondition: CompanyContractRealEstateFeatureCondition
    intermediaryFeatureCondition: CompanyContractIntermediaryFeatureCondition
    marketFeatureCondition?: CompanyContractMarketFeatureCondition
    recommendFeatureCondition?: CompanyContractRecommendFeatureCondition
    connectionFeatureCondition?: CompanyContractConnectionFeatureCondition
}
export type AdminCompanyContractUpdateDto = {
    plan: PlanEntities
    startAt: string
    endAt?: string
    customerFeatureCondition: CompanyContractCustomerFeatureCondition
    teamFeatureCondition: CompanyContractTeamFeatureCondition
    realEstateFeatureCondition: CompanyContractRealEstateFeatureCondition
    intermediaryFeatureCondition: CompanyContractIntermediaryFeatureCondition
    marketFeatureCondition?: CompanyContractMarketFeatureCondition
    recommendFeatureCondition?: CompanyContractRecommendFeatureCondition
    connectionFeatureCondition?: CompanyContractConnectionFeatureCondition
}
export type AdminCompanyInsertDto = {
    name: string
    sound: string
    isKpiTarget: boolean
}
export type AdminCompanyUpdateDto = {
    isKpiTarget: boolean
}
export type AdminCompanyDetailUpdateDto = {
    name: string
    sound: string
    address?: string
    phone?: string
    fax?: string
}
export type AdminCompanyLogoUpdateDto = {
    logo: FileEntities
}
export type DownloadEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    email: string
    name: string
    phone: string
    companyName: string
    departmentName: string
    position: string
    trigger: number
    trigger_content: string
    issue: string
    freeContent: string
    intermediaryFrom: IntermediaryToEntities
}
export type AdminDownloadListResponseDto = {
    list: DownloadEntities[]
    count: number
}
export type AdminFaqListResponseDto = {
    list: FaqContentEntities[]
    count: number
}
export type AdminFaqUpdateDto = {
    uuid: string
    question: string
    answer: string
    isDisplayHome: boolean
    sort: number
}
export type InquiryEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    email: string
    name: string
    phone: string
    companyName: string
    subject: string
    content: string
    teamMember?: TeamMemberEntities
    status: number
}
export type AdminInquiryListResponseDto = {
    list: InquiryEntities[]
    count: number
}
export type AdminNotificationTemplateListResponseDto = {
    list: NotificationTemplateEntities[]
    count: number
}
export type AdminNotificationTemplateInsertDto = {
    key: string
    name: string
}
export type AdminNotificationTemplateUpdateDto = {
    uuid: string
    key: string
    name: string
    mailSubject?: string
    mailHtmlBody?: string
    mailHtmlButtonTitle?: string
    mailHtmlAfterMessage?: string
    body?: string
}
export type AdminTeamInsertDto = {
    name: string
    company: CompanyEntities
    parentTeam?: TeamEntities
}
export type AdminTeamUpdateDto = {
    name: string
    overview?: string
    address?: string
    phone?: string
    fax?: string
}
export type AdminTeamMemberInsertDto = {
    name: string
    nameSound: string
    email: string
    sort: number
    roles: MemberRole
    teamUuid: string
    departmentName?: string
    position?: string
    phone?: string
    phoneExtension?: string
    mobile?: string
}
export type AdminTeamMemberReplaceDto = {
    baseMemberUuid: string
    targetMemberUuid: string
}
export type AdminTeamMemberUpdateDto = {
    name: string
    nameSound: string
    email: string
    sort: number
    roles: MemberRole
    departmentName?: string
    position?: string
    phone?: string
    phoneExtension?: string
    mobile?: string
}
export type ReportTemplateEntities = {
    uuid: string
    deletedAt: string | null
    createdAt: string
    updatedAt: string
    category: string
    displayName: string
    file: FileEntities
    registeredTeam: TeamEntities
    registeredMember?: TeamMemberEntities
}
export type AdminReportTemplateListResponseDto = {
    list: ReportTemplateEntities[]
    count: number
}
export type AdminReportTemplateInsertDto = {
    category: string
    displayName: string
    file?: FileEntities
}
export type AdminReportTemplateUpdateDto = {
    uuid: string
    category: string
    displayName: string
    file?: FileEntities
}
export const {
    useAppGetHcQuery,
    useClientCompanyPutUuidMutation,
    useClientCompanyPutUuidLogoMutation,
    useClientCompanyDeleteUuidLogoMutation,
    useClientCompanyPutUuidBandMutation,
    useClientCompanyDeleteUuidBandMutation,
    useClientCompanyPutUuidRealEstateListKeysMutation,
    useClientConditionRequestGetConditionRequestQuery,
    useClientConditionRequestPostConditionRequestMutation,
    useClientConditionRequestPutUuidMutation,
    useClientCustomerAssigneePostCustomerAssigneeMutation,
    useClientCustomerAssigneePutMultiEditMutation,
    useClientCustomerAssigneePutUuidMutation,
    useClientCustomerAssigneeDeleteUuidMutation,
    useClientCustomerCustomerQuery,
    useClientCustomerPostCustomerMutation,
    useClientCustomerPutMultipleMutation,
    useClientCustomerRecommendQuery,
    useClientCustomerExportQuery,
    useClientCustomerPreImportMutation,
    useClientCustomerImportMutation,
    useClientCustomerUuidQuery,
    useClientCustomerPutUuidMutation,
    useClientCustomerDeleteUuidMutation,
    useClientCustomerPutUuidLabelsMutation,
    useClientCustomerIntermediaryCustomerUuidQuery,
    useClientCustomerIntermediaryPostFinishMutation,
    useClientCustomerIntermediaryPostReviewFinishMutation,
    useClientCustomerIntermediaryPostReviewFinishGuestMutation,
    useClientCustomerLabelPostCustomerLabelMutation,
    useClientCustomerLabelPutCustomerLabelOrderMutation,
    useClientCustomerLabelPutUuidMutation,
    useClientCustomerLabelDeleteUuidMutation,
    useClientCustomerLabelIsUsingQuery,
    useClientCustomerLabelTeamUuidQuery,
    useClientCustomerLabelPutLabelUuidSortUuidMutation,
    useClientCustomerMemoPostCustomerMemoMutation,
    useClientCustomerMemoDeleteUuidMutation,
    useClientDashboardAnalyticsDashboardAnalyticsQuery,
    useClientDownloadPostDownloadMutation,
    useClientFaqGetFaqListQuery,
    useClientFileGetOneFileDetailQuery,
    useClientFileGetPreSignedUrlMutation,
    useClientFileSetFileSizeMutation,
    useClientInquiryPostInquiryMutation,
    useClientInquiryPostInquirySimpleMutation,
    useClientIntermediaryPostIntermediaryMutation,
    useClientIntermediaryPostPreviewMutation,
    useClientIntermediaryRealEstateUuidQuery,
    useClientIntermediaryVotePostIntermediaryVoteMutation,
    useClientMediaGetSignedUrlMutation,
    useClientMediaGetFileQuery,
    useClientMediaGetFileSignedUrlQuery,
    useClientNotificationTemplateNotificationTemplateQuery,
    useClientNotificationTemplateNotificationTemplateMypageQuery,
    useClientNotificationTemplatePutUuidMutation,
    useClientNotificationTemplateDeleteUuidMutation,
    useClientPageViewPageViewMutation,
    useClientRealEstateAssigneePostRealEstateAssigneeMutation,
    useClientRealEstateAssigneePutUuidMutation,
    useClientRealEstateAssigneeDeleteUuidMutation,
    useClientRealEstateConnectionRealEstateConnectionQuery,
    useClientRealEstateConnectionPutCheckMutation,
    useClientRealEstateConnectionPutOpenMutation,
    useClientRealEstateRealEstateQuery,
    useClientRealEstatePostRealEstateMutation,
    useClientRealEstateGetMaxProgressQuery,
    useClientRealEstateHasNoReadTeamActivityQuery,
    useClientRealEstateGetNoReadChatActivityQuery,
    useClientRealEstateUuidQuery,
    useClientRealEstatePutUuidMutation,
    useClientRealEstateDeleteUuidMutation,
    useClientRealEstatePutUuidLabelsMutation,
    useClientRealEstatePostReportMutation,
    useClientRealEstateMarketRequestQuery,
    useClientRealEstatePostMarketRequestMutation,
    useClientRealEstateRemovePublishMarketMutation,
    useClientRealEstateDeleteUuidOptionMutation,
    useClientRealEstateDocumentPostRealEstateDocumentMutation,
    useClientRealEstateDocumentPutRealEstateDocumentOrderMutation,
    useClientRealEstateDocumentPutUuidMutation,
    useClientRealEstateDocumentDeleteUuidMutation,
    useClientRealEstateDocumentPutDocumentUuidSortUuidMutation,
    useClientRealEstateDocumentExportQuery,
    useClientRealEstateDocumentLabelPostRealEstateDocumentLabelMutation,
    useClientRealEstateDocumentLabelPutRealEstateDocumentOrderMutation,
    useClientRealEstateDocumentLabelPutUuidMutation,
    useClientRealEstateDocumentLabelDeleteUuidMutation,
    useClientRealEstateDocumentLabelIsUsingQuery,
    useClientRealEstateDocumentLabelTeamUuidQuery,
    useClientRealEstateDocumentLabelPutLabelUuidSortUuidMutation,
    useClientRealEstateLabelPostRealEstateLabelMutation,
    useClientRealEstateLabelPutRealEstateLabelOrderMutation,
    useClientRealEstateLabelPutUuidMutation,
    useClientRealEstateLabelDeleteUuidMutation,
    useClientRealEstateLabelIsUsingQuery,
    useClientRealEstateLabelTeamUuidQuery,
    useClientRealEstateLabelPutLabelUuidSortUuidMutation,
    useClientRealEstateMarketRealEstateMarketQuery,
    useClientRealEstateMarketHasNewMarketEntityQuery,
    useClientRealEstateMarketPutRequestCheckMutation,
    useClientRealEstateMarketPutMarketOpenMutation,
    useClientRealEstateMemoPostRealEstateMemoMutation,
    useClientRealEstateMemoDeleteUuidMutation,
    useClientRealEstateRecommendRealEstateRecommendQuery,
    useClientRealEstateRecommendPutCheckMutation,
    useClientRealEstateRecommendPutExclusionMutation,
    useClientTeamPostTeamMutation,
    useClientTeamUuidQuery,
    useClientTeamPutUuidMutation,
    useClientTeamMemberConditionGetMeQuery,
    useClientTeamMemberPostTeamMemberMutation,
    useClientTeamMemberHasNoReadTeamActivityQuery,
    useClientTeamMemberTeamUuidQuery,
    useClientTeamMemberPutTeamMemberOrderMutation,
    useClientTeamMemberPutTeamUuidSortUuidMutation,
    useClientTeamMemberPutUuidMutation,
    useClientTeamMemberDeleteUuidMutation,
    useClientUserPostReminderMutation,
    useClientUserPutPasswordMutation,
    useClientUserSignatureQuery,
    useClientUserConditionQuery,
    useClientUserPutConditionMutation,
    useClientUserPutProfileMutation,
    useClientUserMarketInfoQuery,
    useClientUserResetQuery,
    useClientUserPostResetMutation,
    useClientUserActivateQuery,
    useClientUserPostActivateMutation,
    useClientTeamMemberSignatureMemberUuidQuery,
    useClientRealEstateUseTypeRealEstateUseTypeQuery,
    useClientIntermediaryToUpdateProgressMutation,
    useClientIntermediaryToUuidQuery,
    useClientIntermediaryToExportQuery,
    useClientIntermediaryToUuidChatQuery,
    useClientIntermediaryToPostChatMutation,
    useClientIntermediaryToViewChatMutation,
    useClientIntermediaryToDeleteChatMutation,
    useClientTeamActivityTeamActivityQuery,
    useClientTeamActivityPutKeyStatusMutation,
    useClientTeamActivityPutTeamMemberUuidStatusMutation,
    useClientIntermediaryDocumentRealEstateUuidQuery,
    useClientIntermediaryDocumentIntermediaryToUuidQuery,
    useClientIntermediaryDocumentPostPreviewQuery,
    useClientRealEstateEndedRealEstatePostRealEstateAssigneeMutation,
    useClientRealEstateEndedRealEstateRevertEndedRealEstateMutation,
    useClientKpiGetBeaconQuery,
    useClientCustomerFilterPostCustomerFilterMutation,
    useClientCustomerFilterPutUuidMutation,
    useClientCustomerFilterDeleteUuidMutation,
    useClientCustomerFilterFindFiltersByCustomerQuery,
    useClientCustomerFilterFindCustomersByFiltersAndRealEstateQuery,
    useClientAuthSignInMutation,
    useClientAuthClientMeQuery,
    useClientAuthSignOutMutation,
    useAdminCompanyContractPostContractMutation,
    useAdminCompanyContractPutUuidMutation,
    useAdminCompanyContractDeleteUuidMutation,
    useAdminCompanyContractPutUuidCancelMutation,
    useAdminCompanyCompanyQuery,
    useAdminCompanyPostCompanyMutation,
    useAdminCompanyUuidQuery,
    useAdminCompanyPutUuidMutation,
    useAdminCompanyDeleteUuidMutation,
    useAdminCompanyPutUuidLogoMutation,
    useAdminDownloadGetDownloadListQuery,
    useAdminDownloadUuidQuery,
    useAdminFaqGetFaqListQuery,
    useAdminFaqPostFaqMutation,
    useAdminFaqUuidQuery,
    useAdminFaqPutUuidMutation,
    useAdminFaqDeleteUuidMutation,
    useAdminFileGetPreSignedUrlMutation,
    useAdminFileSetFileSizeMutation,
    useAdminFileGetOneFileDetailQuery,
    useAdminInquiryGetInquiryListQuery,
    useAdminInquiryUuidQuery,
    useAdminInquiryPutUuidMutation,
    useAdminInquiryDeleteUuidMutation,
    useAdminMediaGetFileQuery,
    useAdminNotificationTemplateGetNotificationTemplateQuery,
    useAdminNotificationTemplatePostNotificationTemplateMutation,
    useAdminNotificationTemplateGetNotificationTemplateByUuidQuery,
    useAdminNotificationTemplatePutUuidMutation,
    useAdminNotificationTemplateDeleteUuidMutation,
    useAdminTeamPostTeamMutation,
    useAdminTeamPutUuidMutation,
    useAdminTeamMemberPostTeamMemberMutation,
    useAdminTeamMemberReplaceUuidMutation,
    useAdminTeamMemberPutUuidMutation,
    useAdminTeamMemberDeleteUuidMutation,
    useAdminPlanPlanQuery,
    useAdminReportTemplateGetReportTemplateQuery,
    useAdminReportTemplatePostReportTemplateMutation,
    useAdminReportTemplateGetReportTemplateByUuidQuery,
    useAdminReportTemplatePutUuidMutation,
    useAdminReportTemplateDeleteUuidMutation,
} = injectedRtkApi
