import clsx from 'clsx'
import { motion } from 'framer-motion'
import { Outlet } from 'react-router-dom'

import CLoader from '~/components/common/cLoader/CLoader'

import CNotification from '../components/common/cNotification/CNotification'
import SiteFooter from '../components/global/gFooter/SiteFooter'
import SiteHeader from '../components/global/gHeader/SiteHeader'

// ヘッダー・中身・フッターの基本構造
const Default = () => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
            duration: 0.2,
            ease: 'easeInOut',
        }}
        className={clsx('min-h-screen', 'flex', 'flex-col', 'text-gray-700', 'bg-gray-50')}>
        <SiteHeader />
        <div className={clsx('relative', 'flex', 'flex-col', 'space-y-4', 'p-4', 'md:p-8')}>
            <Outlet />
        </div>
        <SiteFooter />
        {/* 通知吹き出し(トースト) */}
        <CNotification />
        {/* Loading */}
        <CLoader />
    </motion.div>
)

export default Default
