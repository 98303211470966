import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { Trans } from 'react-i18next'
import { animateScroll as scroll } from 'react-scroll'

import {
    ClientRealEstatePublishRequestInsertDto,
    ClientTeamMemberConditionUpdateDto,
    useClientPageViewPageViewMutation,
    useClientRealEstateMarketHasNewMarketEntityQuery,
    useClientRealEstateMarketRealEstateMarketQuery,
    useClientRealEstatePostMarketRequestMutation,
    useClientUserMarketInfoQuery,
    useClientUserPutConditionMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { CSortButton } from '~/components/common/cSortButton/CSortButton'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { pageTitleTemplate } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, selectLoginUserCompanyContract, selectLoginUserHaveMarketContract } from '~/util/store/userSlice'

import { CError } from '../../components/common/cError/CError'
import CInquiryButton from '../../components/common/cInquiryButton/CInquiryButton'
import CMessage from '../../components/common/cMessage/CMessage'
import { CPager } from '../../components/common/cPager/CPager'
import { CMarketRealestateListItem } from '../../components/unique/market/CMarketRealestateListItem'
import CMarketRealestateSearch from '../../components/unique/market/CMarketRealestateSearch'
import CNoticeMarketRequest from '../../components/unique/market/CNoticeMarketRequest'
import CUserPurchaseConditionContent from '../../components/unique/user/CUserPurchaseConditionContent'
import { ApplicationError, IApplicationError } from '../../types/error'

const Market = () => {
    const user = useAppSelector(selectLoginUser)
    const [modalErrors, setModalErrors] = useState<Array<IApplicationError>>([])
    const [page, setPage] = useState(1)
    const [requestTargetMarketUuid, setRequestTargetMarketUuid] = useState<string>()
    // 開示リクエストPOSTデータ
    const [publishRequestInsertDto, setPublishRequestInsertDto] = useState<ClientRealEstatePublishRequestInsertDto>({})
    const pageSize = 100
    const commentMaxLength = 25
    const contract = useAppSelector(selectLoginUserCompanyContract)
    // マーケット使用権限
    const isMarketFeature = useAppSelector(selectLoginUserHaveMarketContract)

    // 新規マーケット物件リスト
    const { data: hasNewMarketEntity } = useClientRealEstateMarketHasNewMarketEntityQuery(void {}, { skip: !user })

    const { data: marketInfo } = useClientUserMarketInfoQuery()
    const isNotMarketRequest = marketInfo?.publishRequestCount === 0

    const [postPageViewQuery] = useClientPageViewPageViewMutation()
    useEffect(() => {
        postPageViewQuery({ clientPageViewInsertDto: { path: '/market' } })
    }, [])

    // モーダル表示
    const [visibleSync, setVisibleSync] = useState(false)
    // 検索フィルタ
    const [filter, setFilter] = useState({
        use: [] as Array<string>,
        area: [] as Array<string>,
        price: [] as Array<string>,
        age: [] as Array<string>,
        saleTime: [] as Array<string>,
        grossRate: [] as Array<string>,
        netRate: [] as Array<string>,
        seismicStandard: [] as Array<string>,
        legalCompliance: [] as Array<string>,
        salesDestination: [] as Array<string>,
        filter: [] as Array<string>,
        order: 'publishedAt' as 'updatedAt' | 'publishedAt' | 'publishRequestCount' | undefined,
        sort: 'DESC' as 'ASC' | 'DESC',
    })

    // マーケット掲載物件リスト
    const { data: realestates, isLoading: isLoadingRealestates } = useClientRealEstateMarketRealEstateMarketQuery(
        {
            page,
            ...filter,
        },
        { skip: !isMarketFeature },
    )

    const saveFilter = (_filter: typeof filter) => {
        setPage(1)
        setFilter(_filter)
    }

    const onChange = (v: 'updatedAt' | 'publishedAt' | undefined) => {
        setPage(1)
        setFilter((oldValue) => ({
            ...oldValue,
            order: v,
        }))
    }
    const onChangeSort = () => {
        setPage(1)
        setFilter((oldValue) => ({
            ...oldValue,
            sort: oldValue.sort == 'ASC' ? 'DESC' : 'ASC',
        }))
    }

    const onPageChanged = (_page: number) => {
        setPage(_page)
    }

    useEffect(() => {
        scroll.scrollToTop()
    }, [page])

    const formTextChanged = (value: string, target: keyof ClientRealEstatePublishRequestInsertDto) => {
        setPublishRequestInsertDto((oldValue) => ({ ...oldValue, [target]: value }))
    }

    // 残回数0件時の文言
    const revivalRequestCountWord = () => {
        let revivalRequestCountWord = ''
        if (contract?.marketFeatureCondition?.requestLimit?.unit === 'twoWeek') {
            const today = new Date()
            if (today.getDate() < 15) revivalRequestCountWord = t('Market.今月15日に再びリクエストを送ることができます')
            else revivalRequestCountWord = t('Market.来月1日に再びリクエストを送ることができます')
        } else revivalRequestCountWord = t('Market.来週以降再びリクエストを送ることができます')

        return revivalRequestCountWord
    }

    // リミット表示
    const limitDom = () => {
        return (
            <>
                {isLoadingRealestates ? (
                    <CMessage info>{t('Message.読み込み中です…')}</CMessage>
                ) : (
                    <div className={clsx('text-center', 'border', 'border-kimar-primary', 'rounded', 'p-2', 'bg-white')}>
                        {realestates?.availablePublishRequestCount ? (
                            <div className={clsx('text-sm')}>
                                {contract?.marketFeatureCondition?.requestLimit?.unit === 'twoWeek' ? (
                                    <Trans
                                        i18nKey="Market.2週間であとcount回情報開示リクエストできます"
                                        count={realestates?.availablePublishRequestCount}>
                                        2週間であと
                                        <span className={clsx('text-2xl', 'mx-2')}>
                                            <>{{ count: realestates?.availablePublishRequestCount }}</>
                                        </span>
                                        回<span className={clsx('text-kimar-primary', 'font-bold')}>情報開示リクエスト</span>
                                        できます
                                    </Trans>
                                ) : (
                                    <Trans
                                        i18nKey="Market.今週はあとcount回情報開示リクエストできます"
                                        count={realestates?.availablePublishRequestCount}>
                                        今週はあと
                                        <span className={clsx('text-2xl', 'mx-2')}>
                                            <>{{ count: realestates?.availablePublishRequestCount }}</>
                                        </span>
                                        回<span className={clsx('text-kimar-primary', 'font-bold')}>情報開示リクエスト</span>
                                        できます
                                    </Trans>
                                )}
                            </div>
                        ) : (
                            <div>
                                <div>{t('Market.情報開示リクエスト回数の上限に達しました')}</div>
                                <div>{revivalRequestCountWord()}</div>
                            </div>
                        )}
                        <div className={clsx('text-xs', 'text-gray-700')}>
                            {contract?.marketFeatureCondition?.requestLimit?.unit === 'twoWeek' ? (
                                <>{t('Market.ご契約プランでの上限は1回/半月')}</>
                            ) : (
                                <>
                                    {t('Market.ご契約プランでの上限はcount回/週', {
                                        count: contract?.marketFeatureCondition?.requestLimit?.value,
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </>
        )
    }

    // 購入条件
    const [updateConditionDto, setUpdateConditionDto] = useState<ClientTeamMemberConditionUpdateDto>({
        isShareCondition: false,
        isRecommendTarget: false,
        area: [],
        buildingAgeType: 1,
        grossRateType: 40,
        legalComplianceType: 1,
        netRateType: 30,
        prices: [],
        purchaseTimeType: 1,
        seismicStandardType: 1,
        useType: [],
    })
    const [isConditionNull, setIsConditionNull] = useState(true)
    // TODO: 多言語化
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState<
        boolean | '種別が選択されていません' | 'エリアが選択されていません'
    >(false)
    const conditionChangeHandler = (updateConditionDto: ClientTeamMemberConditionUpdateDto) => {
        if (!updateConditionDto?.useType.length) setIsDisabledSaveButton('種別が選択されていません')
        else if (!updateConditionDto?.area.length) setIsDisabledSaveButton('エリアが選択されていません')
        else setIsDisabledSaveButton(false)
        setUpdateConditionDto(updateConditionDto)
    }
    const [updateConditionQuery] = useClientUserPutConditionMutation()
    const [postMarketRequestQuery] = useClientRealEstatePostMarketRequestMutation()
    // 購入情報入力フォーム付き情報開示リクエストモーダルの確定ボタンが押された時の処理
    const saveConditionHandler = async () => {
        if (!updateConditionDto) return
        setModalErrors([])
        try {
            if (!requestTargetMarketUuid) throw new ApplicationError(t('Market.リクエストを送る物件が選択されていません。'))
            if (publishRequestInsertDto.comment && publishRequestInsertDto.comment.length > commentMaxLength)
                throw new ApplicationError(t('Market.コメントがlength文字を超えています。', { length: commentMaxLength }))

            await updateConditionQuery({
                clientTeamMemberConditionUpdateDto: updateConditionDto,
            }).unwrap()
            await postMarketRequestQuery({
                uuid: requestTargetMarketUuid,
                clientRealEstatePublishRequestInsertDto: publishRequestInsertDto,
            }).unwrap()
            setVisibleSync(false)
            setPublishRequestInsertDto({})
            toast.success(t('Market.情報開示リクエストを送信しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setModalErrors([e])
            else setModalErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }
    // 情報開示リクエストモーダルの確定ボタンが押された時の処理
    const sendRequest = async () => {
        setModalErrors([])
        try {
            if (!requestTargetMarketUuid) throw new ApplicationError(t('Market.リクエストを送る物件が選択されていません。'))
            if (publishRequestInsertDto.comment && publishRequestInsertDto.comment.length > commentMaxLength)
                throw new ApplicationError(t('Market.コメントがlength文字を超えています。', { length: commentMaxLength }))

            await postMarketRequestQuery({
                uuid: requestTargetMarketUuid,
                clientRealEstatePublishRequestInsertDto: publishRequestInsertDto,
            }).unwrap()
            setVisibleSync(false)
            setPublishRequestInsertDto({})
            toast.success(t('Market.情報開示リクエストを送信しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setModalErrors([e])
            else setModalErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const marketRealestateItems = () => {
        if (realestates?.count === 0) return <CMessage warning>{t('Market.マーケット掲載物件が見つかりませんでした。')}</CMessage>

        return (
            <>
                {realestates?.list.map((realestate) => (
                    <CMarketRealestateListItem
                        key={realestate.uuid}
                        item={realestate}
                        isNew={!!hasNewMarketEntity?.find((i) => i.uuid === realestate.uuid)}
                        publishRequest={() => {
                            setRequestTargetMarketUuid(realestate.uuid)
                            setModalErrors([])
                            setVisibleSync(true)
                        }}
                    />
                ))}
            </>
        )
    }

    if (!isMarketFeature) {
        return (
            <>
                <div className={clsx('text-center', 'bg-white', 'border', 'border-gray-200', 'rounded', 'p-4', 'md:p-8')}>
                    <CInquiryButton>
                        <p className={clsx('text-sm', 'mb-1')}>
                            {t('Market.ご契約のプランではマーケット機能はお使いいただけません。')}
                        </p>
                        <p className={clsx('text-sm')}>
                            {t('Market.マーケットを有効にして、新規物件と新規人脈を手に入れましょう。')}
                        </p>
                    </CInquiryButton>
                </div>
            </>
        )
    }

    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Market.マーケット')}</title>
            </Helmet>
            {/* 情報開示リクエスト促進メッセージ */}
            {isNotMarketRequest && <CNoticeMarketRequest />}
            {limitDom()}
            <CMarketRealestateSearch
                filterProp={filter}
                filterUpdate={(filter) => saveFilter(filter)}
                marginBottom={realestates?.count === 0}
            />

            <div className={clsx('flex', 'flex-col', 'md:flex-row', 'justify-between')}>
                <CLabeledItem label={t('Market.並び順')} horizontal className={clsx('whitespace-nowrap')}>
                    <CSortButton
                        className={clsx('border-gray-300')}
                        items={[
                            {
                                label: t('Market.掲載日'),
                                value: 'publishedAt',
                            },
                            {
                                label: t('Market.更新日'),
                                value: 'updatedAt',
                            },
                            {
                                label: t('Market.直近リクエスト受信数'),
                                value: 'publishRequestCount',
                            },
                        ]}
                        sort={filter.sort}
                        onChange={(v) => {
                            onChange(v as 'updatedAt' | 'publishedAt' | undefined)
                        }}
                        onClickSort={onChangeSort}
                    />
                </CLabeledItem>
                {realestates?.count ? (
                    <CPager page={page} pageSize={pageSize} total={realestates?.count} onPageChanged={onPageChanged} />
                ) : (
                    <></>
                )}
            </div>

            {/* メインコンテンツ */}
            {realestates?.count ? (
                <>
                    <div className={clsx('grid', 'md:grid-cols-2', 'gap-4')}>{marketRealestateItems()}</div>
                    <CPager page={page} pageSize={pageSize} total={realestates?.count} onPageChanged={onPageChanged} />
                </>
            ) : (
                <CMessage info>{t('Market.物件が見つかりませんでした')}</CMessage>
            )}
            <CFooterModal
                footer={
                    <>
                        <CButton
                            className={clsx('c-button-secondary')}
                            onClick={() => {
                                setVisibleSync(false)
                            }}>
                            {t('Button.キャンセル')}
                        </CButton>
                        {isConditionNull ? (
                            <CButton
                                className={clsx('c-button-primary')}
                                disabled={!!isDisabledSaveButton}
                                onClick={saveConditionHandler}>
                                {t('Market.購入条件を保存して情報開示リクエスト')}
                            </CButton>
                        ) : (
                            <CButton className={clsx('c-button-primary')} disabled={!!isDisabledSaveButton} onClick={sendRequest}>
                                {t('Button.送信')}
                            </CButton>
                        )}
                    </>
                }
                visible={visibleSync}
                content={{ width: '80%' }}
                onRequestClose={() => setVisibleSync(false)}>
                <div className={clsx('p-6')}>
                    <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>
                        {t('Market.情報開示リクエストを送信')}
                    </div>
                    <CError errors={modalErrors} />

                    {/* 購入条件がない場合にのみ表示 */}
                    {isConditionNull && (
                        <>
                            <p className={clsx('py-2')}>
                                {t('Market.情報開示リクエストには購入条件の登録が必要です。以下のフォームから入力してください。')}
                            </p>
                            <div className={clsx('rounded-t', 'border', 'border-b-0', 'py-2', 'px-4')}>
                                {t('Market.購入条件')}
                            </div>
                            <div className={clsx('rounded-b', 'border', 'border-t-0', 'p-4')}>
                                <CUserPurchaseConditionContent
                                    isModal
                                    changeHandler={(updateConditionDto) => conditionChangeHandler(updateConditionDto)}
                                    isConditionNull={(isNull) => setIsConditionNull(isNull)}
                                />
                            </div>
                        </>
                    )}

                    {limitDom()}
                    <p className={clsx('py-4')}>
                        {t('Market.物件情報の開示リクエストを掲載者に送信します。')}
                        <br />
                        {t('Market.掲載者にはあなたの購入条件、会社名/氏名、コメントが送信されます。')}
                        <br />
                        {t('Market.掲載者があなたに物件紹介すると、物件情報を確認できます。')}
                    </p>
                    <CLabeledItem
                        label={t('Market.掲載元にコメントを送る（任意・length文字以内）', { length: commentMaxLength })}>
                        <CTextInput
                            type="text"
                            className={clsx('w-full', 'c-text-input-base')}
                            placeholder={t('Market.都心で築浅の事務所を探しております。')}
                            text={publishRequestInsertDto.comment ?? ''}
                            maxLength={commentMaxLength}
                            onChange={(value) => formTextChanged(value, 'comment')}
                        />
                    </CLabeledItem>
                    <div className={clsx('py-4')}>
                        <CMessage info>{t('Market.コメントには会社名や氏名は記入しないでください')}</CMessage>
                    </div>
                    {!!isDisabledSaveButton && (
                        <div className={clsx('text-red-500', 'w-full', 'text-center')}>{isDisabledSaveButton}</div>
                    )}
                </div>
            </CFooterModal>
        </>
    )
}

export default Market
