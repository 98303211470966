import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { Trans } from 'react-i18next'
import { Link, Location, useLocation, useNavigate } from 'react-router-dom'

import { useClientAuthSignInMutation } from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import { pageTitleTemplate } from '~/util/common/common'
import { selectCredential, setCredentials } from '~/util/store/authSlice'
import { useAppDispatch, useAppSelector } from '~/util/store/hooks'

import { CButton } from '../../components/common/cButton/CButton'
import { CError } from '../../components/common/cError/CError'
import { CLabeledItem } from '../../components/common/cLabeledItem/CLabeledItem'
import { CTextInput } from '../../components/common/cTextInput/CTextInput'
import { IApplicationError } from '../../types/error'

const SignIn = () => {
    const navigate = useNavigate()
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const dispatch = useAppDispatch()
    const [cookies, , removeCookie] = useCookies()

    if (cookies.__cred__) removeCookie('__cred__', { path: '/' })

    // 他ページからサインインにリダイレクトしてきた場合の「他ページ」(=アクセスしようとしていた先)情報
    const location = useLocation().state as { from: Location }

    // ログインフォームの情報をstateとして持つ
    const [formState, setFormState] = React.useState({
        email: '',
        password: '',
    })

    // ログインAPI
    const [signIn, { isLoading }] = useClientAuthSignInMutation()
    const cred = useAppSelector(selectCredential)
    // ログインボタン押下処理
    const clickHandler = async () => {
        try {
            // sign in
            const data = await signIn({ userSignInDto: formState }).unwrap()
            dispatch(setCredentials({ credential: data.accessToken }))
            toast.success(t('SignIn.ログインしました'))
        } catch (e) {
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    useEffect(() => {
        // credが存在する場合、すなわち認証が成功した場合に画面遷移を行う
        if (cred) navigate(location?.from?.pathname ?? '/dashboard')
    }, [cred, navigate, location])

    return (
        <div className={clsx('flex', 'flex-col', 'space-y-4', 'items-center')}>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('SignIn.ログイン')}</title>
            </Helmet>
            <CHeader label={t('SignIn.ログイン')} />
            <div
                className={clsx(
                    'w-full',
                    'max-w-md',
                    'bg-white',
                    'rounded-md',
                    'flex',
                    'flex-col',
                    'space-y-4',
                    'p-4',
                    'md:p-8',
                )}>
                <CError errors={errors} />
                <div className={clsx('w-full')}>
                    <CLabeledItem label={t('SignIn.メールアドレス')} required>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            text={formState.email}
                            onChange={(value) => setFormState((prev) => ({ ...prev, email: value }))}
                            type={'text'}
                        />
                    </CLabeledItem>
                </div>
                <div className={clsx('w-full')}>
                    <CLabeledItem label={t('SignIn.パスワード')} required>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            text={formState.password}
                            onChange={(value) => setFormState((prev) => ({ ...prev, password: value }))}
                            type={'password'}
                        />
                    </CLabeledItem>
                </div>
                <CButton onClick={clickHandler} disabled={isLoading} className={clsx('c-button-primary', 'w-full')}>
                    {t('Button.ログイン')}
                </CButton>
                <CLinkButton to="/user/reminder" className={clsx('p-2', 'mx-auto', 'text-kimar-primary', 'underline')}>
                    {t('SignIn.パスワードを忘れた場合')}
                </CLinkButton>
                <hr />
                <p>
                    <Trans i18nKey="SignIn.新規登録をご希望の方はこちらからお問い合わせください。">
                        新規登録をご希望の方は
                        <Link to="/inquiry" className={clsx('text-kimar-primary', 'underline')}>
                            こちら
                        </Link>
                        からお問い合わせください。
                    </Trans>
                </p>
            </div>
        </div>
    )
}
export default SignIn
