import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useMemo, useState, VFC } from 'react'

import { isValidDate } from '~/util/common/common'

import { CDropDown } from '../cDropdown/CDropdown'

export type CYearMonthDayViewProps = {
    dropdownList: {
        year: { label: string; value: number }[]
        month: { label: string; value: number }[]
        date: { label: string; value: number }[]
    }
    date: {
        year: number
        month: number
        date: number
    }
    onChangeDropDown: (type: 'year' | 'month' | 'date', value: number) => void
} & CYearMonthDayProps

export const CYearMonthDayView: VFC<CYearMonthDayViewProps> = ({
    dropdownList,
    onChangeDropDown,
    date,
    hideNonUsedDropdown,
    type,
    disabled,
    tiny,
}) => {
    return (
        <div className={clsx('flex', !tiny && 'p-1', 'space-x-1', 'items-center')}>
            <CDropDown
                disabled={disabled}
                items={dropdownList.year}
                nowValue={date.year}
                onChange={(value) => {
                    onChangeDropDown('year', Number(value))
                }}
                className={clsx(tiny ? 'c-dropdown-sm' : 'c-dropdown-base')}
            />
            <span>{t('CYearMonthDay.年')}</span>
            {!(hideNonUsedDropdown && type === 'year') && (
                <>
                    <CDropDown
                        disabled={disabled}
                        items={dropdownList.month}
                        nowValue={date.month}
                        onChange={(value) => {
                            onChangeDropDown('month', Number(value))
                        }}
                        className={clsx(tiny ? 'c-dropdown-sm' : 'c-dropdown-base')}
                    />
                    <span>{t('CYearMonthDay.月')}</span>
                </>
            )}

            {!(hideNonUsedDropdown && (type === 'year' || type === 'month')) && (
                <>
                    <CDropDown
                        disabled={disabled}
                        items={dropdownList.date}
                        nowValue={date.date}
                        onChange={(value) => {
                            onChangeDropDown('date', Number(value))
                        }}
                        className={clsx(tiny ? 'c-dropdown-sm' : 'c-dropdown-base')}
                    />
                    <span>{t('CYearMonthDay.日')}</span>
                </>
            )}
        </div>
    )
}

export type CYearMonthDayProps = {
    value?: Date
    jc?: boolean // 年号表示
    forceReturnValue?: boolean
    type?: 'year' | 'month' | 'date'
    yearMargin?: number
    hideNonUsedDropdown?: boolean
    disabled?: boolean
    tiny?: boolean
    onChange: (val: Date | undefined, type: 'year' | 'month' | 'date') => void
}

export const CYearMonthDay: VFC<CYearMonthDayProps> = ({ ...props }) => {
    const [date, setDate] = useState<{ year: number; month: number; date: number }>({ year: 0, month: 0, date: 0 })
    useEffect(() => {
        if (props.value && !Number.isNaN(props.value.getTime())) {
            setDate({
                year: props.value.getFullYear(),
                month: !props.type || props.type !== 'year' ? props.value.getMonth() + 1 : 0,
                date: !props.type || props.type === 'date' ? props.value.getDate() : 0,
            })

            const days = dayjs(new Date(props.value.getFullYear(), props.value.getMonth(), 1)).daysInMonth()
            setDropdownList({
                ...dropdownList,
                date: [
                    { label: '--', value: 0 },
                    ...(!isNaN(days)
                        ? Array(days)
                              .fill('')
                              .map((v, i) => ({ label: String(i + 1), value: i + 1 }))
                        : []),
                ],
            })
        } else setDate({ year: 0, month: 0, date: 0 })
    }, [props.value, props.type])
    const yearMargin = useMemo(() => {
        return props.yearMargin ?? 10
    }, [props.yearMargin])

    const now = dayjs()
    const [dropdownList, setDropdownList] = useState<{
        year: { label: string; value: number }[]
        month: { label: string; value: number }[]
        date: { label: string; value: number }[]
    }>({
        year: [
            { label: '----', value: 0 },
            ...Array(110)
                .fill('')
                .map((v, i) => {
                    if (props.jc) {
                        const jc = new Date(now.year() + yearMargin - i, 1, 1)
                            .toLocaleString('ja-JP-u-ca-japanese')
                            .split('/')
                            .shift()
                        return { label: String(now.year() + yearMargin - i) + '(' + jc + ')', value: now.year() + yearMargin - i }
                    } else return { label: String(now.year() + yearMargin - i), value: now.year() + yearMargin - i }
                }),
        ],
        month: [
            { label: '--', value: 0 },
            ...Array(12)
                .fill('')
                .map((v, i) => ({ label: String(i + 1), value: i + 1 })),
        ],
        date: [{ label: '--', value: 0 }],
    })

    const onChangeDropDown = (type: 'year' | 'month' | 'date', value: number) => {
        const newValue = {
            ...date,
        }
        if (type === 'year') {
            if (value !== 0) {
                newValue.year = value
                if (newValue.month) {
                    const days = dayjs(new Date(newValue.year, newValue.month - 1, 1)).daysInMonth()
                    setDropdownList({
                        ...dropdownList,
                        date: [
                            { label: '--', value: 0 },
                            ...(!isNaN(days)
                                ? Array(days)
                                      .fill('')
                                      .map((v, i) => ({ label: String(i + 1), value: i + 1 }))
                                : []),
                        ],
                    })
                    if (
                        newValue.date &&
                        !isValidDate(
                            `${newValue.year}-${String(newValue.month).padStart(2, '0')}-${String(newValue.date).padStart(
                                2,
                                '0',
                            )}`,
                        )
                    )
                        newValue.date = 1
                }
            } else {
                newValue.year = 0
                newValue.month = 0
                newValue.date = 0
            }
        }
        if (type === 'month') {
            if (value !== 0) {
                newValue.month = value
                const days = dayjs(new Date(newValue.year, newValue.month - 1, 1)).daysInMonth()
                setDropdownList({
                    ...dropdownList,
                    date: [
                        { label: '--', value: 0 },
                        ...Array(days)
                            .fill('')
                            .map((v, i) => ({ label: String(i + 1), value: i + 1 })),
                    ],
                })
                if (
                    newValue.date &&
                    !isValidDate(
                        `${newValue.year}-${String(newValue.month).padStart(2, '0')}-${String(newValue.date).padStart(2, '0')}`,
                    )
                )
                    newValue.date = 1
            } else {
                newValue.month = 0
                newValue.date = 0
            }
        }
        if (type === 'date') newValue.date = value

        setDate(newValue)

        if (newValue.year === 0 || newValue.month === 0 || newValue.date === 0) {
            if (props.forceReturnValue) props.onChange(forceDate(newValue), getDateType(newValue))
            else props.onChange(undefined, getDateType(newValue))
        } else {
            const newDateDayJs = dayjs()
                .year(newValue.year)
                .month(newValue.month - 1)
                .date(newValue.date)
            if (
                isValidDate(
                    `${newValue.year}-${String(newValue.month).padStart(2, '0')}-${String(newValue.date).padStart(2, '0')}`,
                )
            )
                props.onChange(newDateDayJs.toDate(), getDateType(newValue))
            else props.onChange(undefined, getDateType(newValue))
        }
    }

    const forceDate = (newValue: typeof date) => {
        const newDateDayJs = dayjs()
            .year(newValue.year || 2000)
            .month(newValue.month - 1 > -1 ? newValue.month - 1 : 0)
            .date(newValue.date || 1)
        return newDateDayJs.toDate()
    }

    const getDateType = (newValue: typeof date) => {
        if (newValue.date !== 0) return 'date'
        if (newValue.month !== 0) return 'month'
        if (newValue.year !== 0) return 'year'
        return 'date'
    }

    return <CYearMonthDayView {...props} date={date} dropdownList={dropdownList} onChangeDropDown={onChangeDropDown} />
}
