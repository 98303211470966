import { DraggableAttributes } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import clsx from 'clsx'
import { t } from 'i18next'
import { isNil, orderBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import toast from 'react-hot-toast/headless'

import {
    ClientCustomerLabelEditDto,
    CustomerLabelEntities,
    useClientCustomerLabelDeleteUuidMutation,
    useClientCustomerLabelIsUsingQuery,
    useClientCustomerLabelPostCustomerLabelMutation,
    useClientCustomerLabelPutCustomerLabelOrderMutation,
    useClientCustomerLabelPutUuidMutation,
    useClientCustomerLabelTeamUuidQuery,
} from '~/app/api'
import { CDragRowTable } from '~/components/common/cDragRowTable/CDragRowTable'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser } from '~/util/store/userSlice'

import { ApplicationError } from '../../../types/error'
import { CButton } from '../../common/cButton/CButton'
import { CLabeledItem } from '../../common/cLabeledItem/CLabeledItem'
import { CFooterModal } from '../../common/cModal/CModal'
import { CRadioButton } from '../../common/cRadioButton/CRadioButton'
import { CTextInput } from '../../common/cTextInput/CTextInput'

const CUserMypageCustomerLabel = () => {
    // ログインしたユーザー
    const user = useAppSelector(selectLoginUser)
    // ラベルリスト取得
    const { data: labels, refetch: refetchLabel } = useClientCustomerLabelTeamUuidQuery(
        { uuid: user?.team.uuid ?? '' },
        { skip: !user },
    )
    // 表示用に並び替えたラベルリスト
    const sortedLabels = useMemo(() => {
        return orderBy(labels?.list ?? [], ['sort', 'name'], ['asc', 'asc'])
    }, [labels]) as CustomerLabelEntities[]
    // ラベル作成API
    const [labelCreateAPI] = useClientCustomerLabelPostCustomerLabelMutation()
    // ラベル更新API
    const [labelUpdateAPI] = useClientCustomerLabelPutUuidMutation()
    // ラベル削除API
    const [labelDeleteAPI] = useClientCustomerLabelDeleteUuidMutation()

    const emptyLabel: ClientCustomerLabelEditDto = {
        name: '',
        bgColor: '#333333',
        letterColor: '#ffffff',
    }

    // 新規作成中、あるいは編集・削除対象ラベル
    const [labelEditModel, setLabelEditModel] = useState<ClientCustomerLabelEditDto>(emptyLabel)
    // ラベル新規作成・編集ダイアログ表示flag
    const [labelEditVisible, setLabelEditVisible] = useState(false)
    // ラベル削除確認モーダル表示flag
    const [labelDeleteVisible, setLabelDeleteVisible] = useState(false)

    // 文字色
    const letterColorList = [
        {
            label: t('CUserMypageCustomerLabel.黒'),
            value: '#333333',
        },
        {
            label: t('CUserMypageCustomerLabel.白'),
            value: '#ffffff',
        },
    ]

    // ラベルを作成するボタン
    const createLabelHandler = () => {
        setLabelEditVisible(true)
        setLabelEditModel(emptyLabel)
    }

    // ラベル修正ボタン
    const editLabelHandler = (data: CustomerLabelEntities) => {
        setLabelEditVisible(true)
        setLabelEditModel({
            uuid: data.uuid,
            name: data.name,
            bgColor: data.bgColor,
            letterColor: data.letterColor,
        })
    }

    // ラベルの作成・編集確定ボタン
    const saveLabelHandler = async () => {
        try {
            if (labelEditModel.name.length <= 0) throw new ApplicationError('ラベル名を空欄で登録することはできません')
            if (labelEditModel.uuid) {
                await labelUpdateAPI({ uuid: labelEditModel.uuid, clientCustomerLabelEditDto: labelEditModel }).unwrap()
                toast.success(t('CUserMypageCustomerLabel.顧客ラベルを編集しました'))
            } else {
                await labelCreateAPI({ clientCustomerLabelEditDto: labelEditModel }).unwrap()
                toast.success(t('CUserMypageCustomerLabel.顧客ラベルを追加しました'))
            }
            setLabelEditVisible(false)
            setLabelEditModel(emptyLabel)
        } catch (e) {
            console.error(e)
        }
    }

    const [labelDeleteTarget, setLabelDeleteTarget] = useState<CustomerLabelEntities>()
    const { data: isUsingCustomerLabel, isFetching } = useClientCustomerLabelIsUsingQuery(
        {
            uuid: labelDeleteTarget?.uuid ?? '',
        },
        { skip: !labelDeleteTarget },
    )

    useEffect(() => {
        if (isFetching) return
        if (!!labelDeleteTarget && !isNil(isUsingCustomerLabel)) showDeleteLabelModal()
    }, [isUsingCustomerLabel, labelDeleteTarget, isFetching])

    const showDeleteLabelModal = () => {
        if (!labelDeleteTarget) return
        setLabelDeleteVisible(true)
    }

    // ラベル削除ボタン
    const deleteLabelHandler = (data: CustomerLabelEntities) => {
        setLabelDeleteTarget(data)
    }

    // ラベル削除リクエスト送信
    const sendDeleteRequest = async () => {
        try {
            if (!labelDeleteTarget?.uuid) throw new ApplicationError('削除するラベルのデータを取得できませんでした')
            await labelDeleteAPI({ uuid: labelDeleteTarget.uuid }).unwrap()
            toast.success(t('CUserMypageCustomerLabel.顧客ラベルを削除しました'))
            setLabelDeleteVisible(false)
            setLabelDeleteTarget(undefined)
        } catch (e) {
            console.error(e)
        }
    }

    const colorPickedHandler = (color: ColorResult) => {
        setLabelEditModel((oldValue) => {
            if (!oldValue) return oldValue
            return { ...oldValue, bgColor: color.hex }
        })
    }

    /** ソート */
    const [setLabelOrderQuery] = useClientCustomerLabelPutCustomerLabelOrderMutation()
    const setOrder = async (labelUuids: string[]) => {
        await setLabelOrderQuery({
            clientCustomerLabelOrderDto: { labelUuids: labelUuids },
        })
        refetchLabel()
    }

    const customerLabelData = {
        id: 'customerLabels',
        thead: (
            <tr className={clsx('bg-kimar-primary', 'text-white', 'text-left')}>
                <th className={clsx('pl-2', 'w-[24px]')} />
                <th className={clsx('p-1')}>{t('CUserMypageCustomerLabel.顧客ラベル')}</th>
                {user?.roles.realestate === 2 && (
                    <th className={clsx('p-1', 'w-[130px]')}>{t('CUserMypageCustomerLabel.操作')}</th>
                )}
            </tr>
        ),
        tbody: sortedLabels.map((data) => ({
            id: data.uuid,
            tr: (attributes: DraggableAttributes, listeners: SyntheticListenerMap | undefined) => (
                <>
                    <td {...attributes} {...listeners} className={clsx('cursor-grab')}>
                        <div className={clsx('flex', 'justify-center', 'items-center')}>
                            <i className={clsx('material-icons', 'text-gray-300', 'w-5')}>drag_indicator</i>
                        </div>
                    </td>
                    <td data-no-dnd="true" className={clsx('p-1')}>
                        <div
                            className={clsx('rounded', 'px-2', 'text-center', 'w-fit', 'h-fit')}
                            style={{ background: data.bgColor, color: data.letterColor }}>
                            {data.name ?? ''}
                        </div>
                    </td>
                    {user?.roles.realestate === 2 && (
                        <td data-no-dnd="true" className={clsx('p-1', 'text-xs', 'flex', 'space-x-1')}>
                            <CButton className={clsx('c-button-primary')} onClick={() => editLabelHandler(data)}>
                                {t('Button.修正')}
                            </CButton>
                            <CButton className={clsx('c-button-danger')} onClick={() => deleteLabelHandler(data)}>
                                {t('Button.削除')}
                            </CButton>
                        </td>
                    )}
                </>
            ),
        })),
    }

    return (
        <div className={clsx('w-full', 'flex', 'flex-col', 'gap-y-3')}>
            <CHeader label={t('CUserMypageCustomerLabel.顧客ラベル')} />
            {user?.roles.customer === 2 && (
                <div className={clsx('flex', 'justify-end')}>
                    <CButton className={clsx('c-button-primary', 'text-sm')} onClick={createLabelHandler}>
                        <i className={clsx('material-icons')}>add</i>
                        <div>{t('CUserMypageCustomerLabel.新規顧客ラベル追加')}</div>
                    </CButton>
                </div>
            )}
            <div className={clsx()}>
                {(sortedLabels?.length ?? 0) > 0 ? (
                    <CDragRowTable
                        data={customerLabelData}
                        onChange={(data) => {
                            setOrder(data.tbody.map((card) => card.id))
                        }}
                        fixed
                    />
                ) : (
                    <CMessage info>{t('CUserMypageCustomerLabel.顧客ラベルがありません')}</CMessage>
                )}
            </div>
            {/* ラベル新規作成・編集 */}
            <CFooterModal
                footer={
                    <>
                        <CButton
                            className={clsx('c-button-secondary')}
                            onClick={() => {
                                setLabelEditModel(emptyLabel)
                                setLabelEditVisible(false)
                            }}>
                            {t('Button.キャンセル')}
                        </CButton>
                        <CButton className={clsx('c-button-primary')} disabled={!labelEditModel?.name} onClick={saveLabelHandler}>
                            {labelEditModel.uuid
                                ? t('CUserMypageCustomerLabel.編集確定')
                                : t('CUserMypageCustomerLabel.ラベル作成')}
                        </CButton>
                    </>
                }
                content={{ width: '100%', maxWidth: '768px' }}
                visible={labelEditVisible}
                onRequestClose={() => {
                    setLabelEditVisible(false)
                    setLabelEditModel(emptyLabel)
                }}>
                <div className={clsx('w-full', 'flex', 'flex-col')}>
                    <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-4')}>
                        {labelEditModel.uuid
                            ? t('CUserMypageCustomerLabel.顧客ラベルを編集')
                            : t('CUserMypageCustomerLabel.顧客ラベルを作成')}
                    </div>
                    <div className={clsx('grid', 'grid-cols-2', 'gap-2', 'p-4')}>
                        {/* ラベル名・背景色・文字色 */}
                        <div className={clsx('p-4', 'flex', 'flex-col', 'gap-y-4')}>
                            <CLabeledItem label={t('CUserMypageCustomerLabel.ラベル名')} required>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    text={labelEditModel?.name}
                                    onChange={(value) =>
                                        setLabelEditModel((oldValue) => {
                                            if (!oldValue) return oldValue
                                            return { ...oldValue, name: value }
                                        })
                                    }
                                    type={'text'}
                                />
                            </CLabeledItem>
                            <div>
                                <CLabeledItem label={t('CUserMypageCustomerLabel.背景色')} />
                                <ChromePicker
                                    color={labelEditModel?.bgColor}
                                    onChangeComplete={(color) => colorPickedHandler(color)}
                                    disableAlpha
                                />
                            </div>
                            <div>
                                <CLabeledItem label={t('CUserMypageCustomerLabel.文字色')}>
                                    <CRadioButton
                                        horizontal
                                        items={letterColorList}
                                        nowValue={labelEditModel?.letterColor}
                                        dataValue="value"
                                        onChange={(value) =>
                                            setLabelEditModel((oldValue) => {
                                                if (!oldValue) return oldValue
                                                return { ...oldValue, letterColor: value as string }
                                            })
                                        }
                                    />
                                </CLabeledItem>
                            </div>
                        </div>
                        {/* 表示プレビュー */}
                        <div className={clsx('p-4')}>
                            <CLabeledItem label={t('CUserMypageCustomerLabel.表示プレビュー')}>
                                {labelEditModel?.name !== '' && (
                                    <div
                                        className={clsx('rounded', 'px-2', 'text-center', 'w-fit')}
                                        style={{ background: labelEditModel?.bgColor, color: labelEditModel?.letterColor }}>
                                        {labelEditModel?.name ?? ''}
                                    </div>
                                )}
                            </CLabeledItem>
                        </div>
                    </div>
                </div>
            </CFooterModal>
            {/* ラベル削除 */}
            <CConfirmModal
                visible={labelDeleteVisible}
                confirmLabel={t('CUserMypageCustomerLabel.削除確定')}
                onRequestConfirm={() => sendDeleteRequest()}
                onRequestClose={() => {
                    setLabelDeleteTarget(undefined)
                    setLabelDeleteVisible(false)
                }}>
                {isUsingCustomerLabel ? (
                    <>
                        <span>
                            {t('CUserMypageCustomerLabel.ラベル「name」は顧客で利用されています。', {
                                name: labelDeleteTarget?.name ?? '',
                            })}
                        </span>
                        <br />
                        <span>{t('CUserMypageCustomerLabel.削除してもよろしいですか？')}</span>
                    </>
                ) : (
                    <>
                        <span>
                            {t('CUserMypageCustomerLabel.ラベル「name」を削除します。', {
                                name: labelDeleteTarget?.name ?? '',
                            })}
                        </span>
                        <br />
                        <span>{t('CUserMypageCustomerLabel.よろしいですか？')}</span>
                    </>
                )}
            </CConfirmModal>
        </div>
    )
}

export default CUserMypageCustomerLabel
