import { clsx } from 'clsx'
import { t } from 'i18next'
import { first, last, sortBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast/headless'

import {
    CustomerEntities,
    CustomerFilterEntities,
    CustomerLabelEntities,
    RealEstateUseTypeEntities,
    useClientConditionRequestPostConditionRequestMutation,
    useClientCustomerDeleteUuidMutation,
    useClientCustomerFilterDeleteUuidMutation,
    useClientCustomerFilterFindFiltersByCustomerQuery,
    useClientCustomerLabelTeamUuidQuery,
    useClientRealEstateUseTypeRealEstateUseTypeQuery,
} from '~/app/api'
import { CDropDown } from '~/components/common/cDropdown/CDropdown'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import CTable from '~/components/common/cTable/CTable'
import { CTagList } from '~/components/common/cTagList/CTagList'
import { formatDateTime } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, selectLoginUserHaveRecommendContract, selectLoginUserTeam } from '~/util/store/userSlice'

import {
    ageRangesFormat,
    areaTypeFormat,
    customerBuildingAgeTypeFormat,
    customerGrossRateTypeFormat,
    customerLegalComplianceTypeFormat,
    customerNetRateTypeFormat,
    customerPriceTypeFormat,
    customerRegisteredTypeFormat,
    customerSeismicStandardTypeFormat,
    realestateGrossRateTypeFormat,
    realEstatePriceTypeFormat,
    saleTimeTypeFormat,
} from '../../../../util/filter/format'
import { CButton } from '../../../common/cButton/CButton'
import { CCustomerEdit } from './CCustomerEdit'
import { CCustomerFilterEdit } from './CCustomerFilterEdit'
import { CCustomerLabelEdit } from './CCustomerLabelEdit'

type Props = {
    customer: CustomerEntities
    refetch: () => void
}
const CCustomerDetailInfo = ({
    customer,
    refetch,
}: Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
    const user = useAppSelector(selectLoginUser)
    const { data: useTypeListCount } = useClientRealEstateUseTypeRealEstateUseTypeQuery({})
    const isRecommendContract = useAppSelector(selectLoginUserHaveRecommendContract)

    const [selectedCustomer, setSelectedCustomer] = useState<CustomerEntities>()
    const [selectedCustomerForLabel, setSelectedCustomerForLabel] = useState<CustomerEntities>()
    const userTeam = useAppSelector(selectLoginUserTeam)
    const { data: labelListCount } = useClientCustomerLabelTeamUuidQuery({ uuid: userTeam?.uuid ?? '' }, { skip: !userTeam })

    const [selectedCustomerForRequest, setSelectedCustomerForRequest] = useState<CustomerEntities>()
    const [requestInvestmentConditionQuery] = useClientConditionRequestPostConditionRequestMutation()
    const closeRequestModal = () => {
        setSelectedCustomerForRequest(undefined)
    }
    const confirmRequestModal = async () => {
        if (!selectedCustomerForRequest) return
        await requestInvestmentConditionQuery({ cuuid: selectedCustomerForRequest.uuid }).unwrap()
        closeRequestModal()
        refetch()
        toast.success(t('CCustomerDetailInfo.購入条件リクエストを送信しました'))
    }

    const [deleteCustomer, setDeleteCustomer] = useState<CustomerEntities>()
    const [deleteCustomerQuery] = useClientCustomerDeleteUuidMutation()
    const closeDeleteModal = () => {
        setDeleteCustomer(undefined)
    }
    const confirmDeleteModal = async () => {
        if (!deleteCustomer) return
        await deleteCustomerQuery({ uuid: deleteCustomer.uuid }).unwrap()
        closeDeleteModal()
        refetch()
        toast.success(t('CCustomerDetailInfo.顧客を削除しました'))
    }

    const lastInvestmentConditionRequest = useMemo(() => {
        return last(customer.investmentConditionRequests)
    }, [customer])

    const lastInvestmentCondition = useMemo(() => {
        return last(customer.investmentConditions)
    }, [customer])

    const lastCustomerCondition = useMemo(() => {
        return last(customer.relatedMember?.investmentConditions)
    }, [customer])

    const { data: filterEntities } = useClientCustomerFilterFindFiltersByCustomerQuery({
        uuid: customer.uuid,
    })
    const [selectedCustomerFilter, setSelectedCustomerFilter] = useState<CustomerFilterEntities>()
    const [editCustomerFilter, setEditCustomerFilter] = useState<{
        customerUuid: string
        uuid?: string
        useTypes?: string[]
        prices?: number[]
        buildingAgeTypes?: number[]
        grossRateTypes?: number[]
    }>()
    const [deleteCustomerFilter, setDeleteCustomerFilter] = useState<string>()
    useEffect(() => {
        setSelectedCustomerFilter(first(filterEntities ?? []))
    }, [filterEntities])
    const [deleteCustomerFilterQuery] = useClientCustomerFilterDeleteUuidMutation()
    const deleteFilter = async () => {
        if (!deleteCustomerFilter) return
        await deleteCustomerFilterQuery({ uuid: deleteCustomerFilter }).unwrap()
        setDeleteCustomerFilter(undefined)
        refetch()
        toast.success(t('CCustomerDetailInfo.絞り込み条件を削除しました'))
    }

    return (
        <div className={clsx('border', 'rounded', 'mt-2', 'max-h-[63vh]', 'overflow-auto', 'p-4', 'flex', 'flex-col', 'gap-2')}>
            {
                <>
                    {user?.roles.customer === 2 && (
                        <div className={clsx('flex', 'text-sm', 'justify-end', 'space-x-2')}>
                            <CButton
                                className={clsx('c-button-secondary', 'text-sm')}
                                onClick={() => setSelectedCustomerForLabel(customer)}>
                                {t('CCustomerDetailInfo.ラベル編集')}
                            </CButton>
                            <CButton
                                className={clsx('c-button-primary', 'text-sm')}
                                onClick={() => setSelectedCustomer(customer)}>
                                {t('Button.編集')}
                            </CButton>
                            <CButton className={clsx('c-button-danger', 'text-sm')} onClick={() => setDeleteCustomer(customer)}>
                                {t('Button.削除')}
                            </CButton>
                        </div>
                    )}
                    <CTable
                        tbody={
                            <>
                                <tr className={clsx('border-b')}>
                                    <th rowSpan={10} className={clsx('bg-kimar-primary', 'text-white', 'w-16')}>
                                        {t('CCustomerDetailInfo.顧客情報')}
                                    </th>
                                    <th className={clsx('bg-kimar-primary', 'text-white', 'w-32')}>
                                        {t('CCustomerDetailInfo.顧客ラベル')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        <CTagList
                                            horizontal
                                            items={
                                                sortBy(customer.labels, (s) => s.sort).map((label) => ({
                                                    className: clsx('text-xs'),
                                                    style: {
                                                        backgroundColor: label.bgColor,
                                                        color: label.letterColor,
                                                    },
                                                    children: <>{label.name}</>,
                                                })) ?? []
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.名前')}</th>
                                    <td className={clsx('px-2')}>{customer.name}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.名前（かな）')}
                                    </th>
                                    <td className={clsx('px-2')}>{customer.nameSound}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.紹介時宛名')}
                                    </th>
                                    <td className={clsx('px-2')}>{customer.intermediaryName}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.メールアドレス')}
                                    </th>
                                    <td className={clsx('px-2')}>{customer.email}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.役職')}</th>
                                    <td className={clsx('px-2')}>{customer.position}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.電話番号')}
                                    </th>
                                    <td className={clsx('px-2')}>{customer.phone}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.内線番号')}
                                    </th>
                                    <td className={clsx('px-2')}>{customer.phoneExtension}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.携帯電話')}
                                    </th>
                                    <td className={clsx('px-2')}>{customer.mobile}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.獲得経路')}
                                    </th>
                                    <td className={clsx('px-2')}>{customerRegisteredTypeFormat(customer.registeredType)}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th rowSpan={5} className={clsx('bg-kimar-primary', 'text-white', 'w-16')}>
                                        {t('CCustomerDetailInfo.会社情報')}
                                    </th>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.会社名')}</th>
                                    <td className={clsx('px-2')}>{customer.companyName}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.会社名（かな）')}
                                    </th>
                                    <td className={clsx('px-2')}>{customer.companyNameSound}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.住所')}</th>
                                    <td className={clsx('px-2')}>{customer.companyAddress}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.代表電話番号')}
                                    </th>
                                    <td className={clsx('px-2')}>{customer.companyPhone}</td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.代表FAX番号')}
                                    </th>
                                    <td className={clsx('px-2')}>{customer.companyFax}</td>
                                </tr>
                            </>
                        }
                    />
                    <CHeader label="購入条件" bgClass="bg-kimar-accent" />
                    {isRecommendContract && user?.roles.customer === 2 && (
                        <>
                            {!lastInvestmentConditionRequest && (
                                <CMessage warning>
                                    {t('CCustomerDetailInfo.この顧客は自身の購入条件を登録していません。')}
                                    <br />
                                    {t(
                                        'CCustomerDetailInfo.購入条件をリクエストすると相手に購入条件の入力を促すことができます。',
                                    )}
                                </CMessage>
                            )}
                            {lastInvestmentConditionRequest ? (
                                lastInvestmentConditionRequest.answeredAt ? (
                                    <div className={clsx('flex', 'justify-end')}>
                                        {/* リクエスト済み＆回答済み */}
                                        <CButton
                                            className={clsx('c-button-secondary', 'text-sm')}
                                            onClick={() => setSelectedCustomerForRequest(customer)}>
                                            {t('CCustomerDetailInfo.購入条件の入力を再リクエスト')}
                                        </CButton>
                                    </div>
                                ) : (
                                    <div className={clsx('flex', 'justify-end')}>
                                        {/* リクエスト済み＆未回答 */}
                                        <CButton className={clsx('c-button-secondary', 'text-sm')} disabled>
                                            {t('CCustomerDetailInfo.リクエストの入力待ち')}
                                        </CButton>
                                    </div>
                                )
                            ) : (
                                <div className={clsx('flex', 'justify-end')}>
                                    {/* 未リクエスト */}
                                    <CButton
                                        className={clsx('c-button-secondary', 'text-sm')}
                                        onClick={() => setSelectedCustomerForRequest(customer)}>
                                        {t('CCustomerDetailInfo.購入条件の入力をリクエスト')}
                                    </CButton>
                                </div>
                            )}
                        </>
                    )}
                    <CTable
                        tbody={
                            <>
                                {/* 自社登録 */}
                                <tr className={clsx('border-b')}>
                                    <th rowSpan={10} className={clsx('bg-kimar-primary', 'text-white', 'w-16')}>
                                        {t('CCustomerDetailInfo.自社登録')}
                                        <br />
                                        {t('CCustomerDetailInfo.購入条件')}
                                    </th>
                                    <th className={clsx('bg-kimar-primary', 'text-white', 'w-32')}>
                                        {t('CCustomerDetailInfo.種別')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {lastInvestmentCondition?.useType
                                            ?.map(
                                                (u) =>
                                                    (useTypeListCount?.list as RealEstateUseTypeEntities[] | undefined)?.find(
                                                        (t) => t.uuid === u,
                                                    )?.name,
                                            )
                                            .join()}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.エリア')}</th>
                                    <td className={clsx('px-2')}>
                                        {lastInvestmentCondition?.area?.map((a) => areaTypeFormat(a)).join()}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.金額')}</th>
                                    <td className={clsx('px-2')}>
                                        {lastInvestmentCondition?.prices
                                            ?.map((typeNumber) => customerPriceTypeFormat(typeNumber))
                                            .join('、')}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.築年数')}</th>
                                    <td className={clsx('px-2')}>
                                        {customerBuildingAgeTypeFormat(lastInvestmentCondition?.buildingAgeType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.購入時期')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {saleTimeTypeFormat(lastInvestmentCondition?.purchaseTimeType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.表面利回り')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerGrossRateTypeFormat(lastInvestmentCondition?.grossRateType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.実質利回り')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerNetRateTypeFormat(lastInvestmentCondition?.netRateType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.耐震基準')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerSeismicStandardTypeFormat(lastInvestmentCondition?.seismicStandardType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.遵法性違反')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerLegalComplianceTypeFormat(lastInvestmentCondition?.legalComplianceType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.最終更新日')}
                                    </th>
                                    <td className={clsx('px-2')}>{formatDateTime(lastInvestmentCondition?.updatedAt, 'date')}</td>
                                </tr>
                                {/* リクエスト登録 */}
                                <tr className={clsx('border-b')}>
                                    <th rowSpan={10} className={clsx('bg-kimar-primary', 'text-white', 'w-16')}>
                                        {t('CCustomerDetailInfo.顧客回答')}
                                        <br />
                                        {t('CCustomerDetailInfo.購入条件')}
                                    </th>
                                    <th className={clsx('bg-kimar-primary', 'text-white', 'w-32')}>
                                        {t('CCustomerDetailInfo.種別')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {lastInvestmentConditionRequest?.useType
                                            ?.map(
                                                (u) =>
                                                    (useTypeListCount?.list as RealEstateUseTypeEntities[] | undefined)?.find(
                                                        (t) => t.uuid === u,
                                                    )?.name,
                                            )
                                            .join()}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.エリア')}</th>
                                    <td className={clsx('px-2')}>
                                        {lastInvestmentConditionRequest?.area?.map((a) => areaTypeFormat(a)).join()}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.金額')}</th>
                                    <td className={clsx('px-2')}>
                                        {lastInvestmentConditionRequest?.prices
                                            ?.map((typeNumber) => customerPriceTypeFormat(typeNumber))
                                            .join('、')}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.築年数')}</th>
                                    <td className={clsx('px-2')}>
                                        {customerBuildingAgeTypeFormat(lastInvestmentConditionRequest?.buildingAgeType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.購入時期')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {saleTimeTypeFormat(lastInvestmentConditionRequest?.purchaseTimeType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.表面利回り')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerGrossRateTypeFormat(lastInvestmentConditionRequest?.grossRateType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.実質利回り')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerNetRateTypeFormat(lastInvestmentConditionRequest?.netRateType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.耐震基準')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerSeismicStandardTypeFormat(lastInvestmentConditionRequest?.seismicStandardType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.遵法性違反')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerLegalComplianceTypeFormat(lastInvestmentConditionRequest?.legalComplianceType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.最終更新日')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {formatDateTime(lastInvestmentConditionRequest?.updatedAt, 'date')}
                                    </td>
                                </tr>
                                {/* 顧客登録 */}
                                <tr className={clsx('border-b')}>
                                    <th rowSpan={10} className={clsx('bg-kimar-primary', 'text-white', 'w-16')}>
                                        {t('CCustomerDetailInfo.顧客登録')}
                                        <br />
                                        {t('CCustomerDetailInfo.購入条件')}
                                    </th>
                                    <th className={clsx('bg-kimar-primary', 'text-white', 'w-32')}>
                                        {t('CCustomerDetailInfo.種別')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {lastCustomerCondition?.useType
                                            ?.map(
                                                (u) =>
                                                    (useTypeListCount?.list as RealEstateUseTypeEntities[] | undefined)?.find(
                                                        (t) => t.uuid === u,
                                                    )?.name,
                                            )
                                            .join()}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.エリア')}</th>
                                    <td className={clsx('px-2')}>
                                        {lastCustomerCondition?.area?.map((a) => areaTypeFormat(a)).join()}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.金額')}</th>
                                    <td className={clsx('px-2')}>
                                        {lastCustomerCondition?.prices
                                            ?.map((typeNumber) => customerPriceTypeFormat(typeNumber))
                                            .join('、')}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>{t('CCustomerDetailInfo.築年数')}</th>
                                    <td className={clsx('px-2')}>
                                        {customerBuildingAgeTypeFormat(lastCustomerCondition?.buildingAgeType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.購入時期')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {saleTimeTypeFormat(lastCustomerCondition?.purchaseTimeType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.表面利回り')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerGrossRateTypeFormat(lastCustomerCondition?.grossRateType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.実質利回り')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerNetRateTypeFormat(lastCustomerCondition?.netRateType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.耐震基準')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerSeismicStandardTypeFormat(lastCustomerCondition?.seismicStandardType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.遵法性違反')}
                                    </th>
                                    <td className={clsx('px-2')}>
                                        {customerLegalComplianceTypeFormat(lastCustomerCondition?.legalComplianceType)}
                                    </td>
                                </tr>
                                <tr className={clsx('border-b')}>
                                    <th className={clsx('bg-kimar-primary', 'text-white')}>
                                        {t('CCustomerDetailInfo.最終更新日')}
                                    </th>
                                    <td className={clsx('px-2')}>{formatDateTime(lastCustomerCondition?.updatedAt, 'date')}</td>
                                </tr>
                            </>
                        }
                    />

                    <CHeader label="絞り込み条件" bgClass="bg-kimar-accent" />
                    <div className={clsx('flex', 'justify-end', 'gap-2')}>
                        <CButton
                            className={clsx('c-button-primary', 'text-sm')}
                            disabled={(filterEntities?.length ?? 0) >= 10}
                            disabledText={t('CCustomerDetailInfo.絞り込み条件は10件までです。')}
                            onClick={() => {
                                setEditCustomerFilter({
                                    customerUuid: customer.uuid,
                                    uuid: undefined,
                                    useTypes: [],
                                    prices: [],
                                    buildingAgeTypes: [],
                                    grossRateTypes: [],
                                })
                            }}>
                            {t('Button.追加')}
                        </CButton>
                        <CButton
                            className={clsx('c-button-primary', 'text-sm')}
                            disabled={!selectedCustomerFilter}
                            onClick={() => {
                                if (!selectedCustomerFilter) return
                                setEditCustomerFilter({
                                    customerUuid: customer.uuid,
                                    uuid: selectedCustomerFilter.uuid,
                                    useTypes: selectedCustomerFilter.useTypes?.map((u) => u.uuid) ?? [],
                                    prices: selectedCustomerFilter.prices ?? [],
                                    buildingAgeTypes: selectedCustomerFilter.buildingAgeTypes ?? [],
                                    grossRateTypes: selectedCustomerFilter.grossRateTypes ?? [],
                                })
                            }}>
                            {t('Button.編集')}
                        </CButton>
                        <CButton
                            className={clsx('c-button-danger', 'text-sm')}
                            disabled={!selectedCustomerFilter}
                            onClick={() => {
                                if (!selectedCustomerFilter) return
                                setDeleteCustomerFilter(selectedCustomerFilter.uuid)
                            }}>
                            {t('Button.削除')}
                        </CButton>
                    </div>
                    {selectedCustomerFilter && (
                        <>
                            <CDropDown
                                className={clsx('c-dropdown-base')}
                                items={
                                    sortBy(filterEntities, 'createdAt').map((f, index) => ({
                                        label: `条件${index + 1}`,
                                        value: f.uuid,
                                    })) ?? []
                                }
                                nowValue={selectedCustomerFilter?.uuid ?? ''}
                                onChange={(val) => {
                                    setSelectedCustomerFilter(filterEntities?.find((f) => f.uuid === val))
                                }}
                            />
                            <CTable
                                tbody={
                                    <>
                                        <tr className={clsx('border-b')}>
                                            <th className={clsx('bg-kimar-primary', 'text-white', 'w-32')}>
                                                {t('CCustomerDetailInfo.種別')}
                                            </th>
                                            <td className={clsx('px-2')}>
                                                {selectedCustomerFilter?.useTypes
                                                    ?.map(
                                                        (u) =>
                                                            (
                                                                useTypeListCount?.list as RealEstateUseTypeEntities[] | undefined
                                                            )?.find((t) => t.uuid === u.uuid)?.name,
                                                    )
                                                    .join()}
                                            </td>
                                        </tr>
                                        <tr className={clsx('border-b')}>
                                            <th className={clsx('bg-kimar-primary', 'text-white')}>
                                                {t('CCustomerDetailInfo.金額')}
                                            </th>
                                            <td className={clsx('px-2')}>
                                                {selectedCustomerFilter?.prices
                                                    ?.map((typeNumber) => realEstatePriceTypeFormat(typeNumber))
                                                    .join('、')}
                                            </td>
                                        </tr>
                                        <tr className={clsx('border-b')}>
                                            <th className={clsx('bg-kimar-primary', 'text-white')}>
                                                {t('CCustomerDetailInfo.築年数')}
                                            </th>
                                            <td className={clsx('px-2')}>
                                                {(
                                                    selectedCustomerFilter?.buildingAgeTypes?.map((v) => ageRangesFormat(v)) ?? []
                                                ).join(', ')}
                                            </td>
                                        </tr>
                                        <tr className={clsx('border-b')}>
                                            <th className={clsx('bg-kimar-primary', 'text-white')}>
                                                {t('CCustomerDetailInfo.表面利回り')}
                                            </th>
                                            <td className={clsx('px-2')}>
                                                {(
                                                    selectedCustomerFilter?.grossRateTypes?.map((v) =>
                                                        realestateGrossRateTypeFormat(v),
                                                    ) ?? []
                                                ).join(', ')}
                                            </td>
                                        </tr>
                                    </>
                                }
                            />
                        </>
                    )}
                    {!selectedCustomerFilter && (
                        <CMessage warning>{t('CCustomerDetailInfo.絞り込み条件はありません。')}</CMessage>
                    )}
                </>
            }
            <CCustomerEdit
                visible={!!selectedCustomer}
                customer={selectedCustomer}
                onSubmit={() => {
                    setSelectedCustomer(undefined)
                    refetch()
                }}
                onClose={() => {
                    setSelectedCustomer(undefined)
                    refetch()
                }}
            />
            <CCustomerLabelEdit
                visible={!!selectedCustomerForLabel}
                customer={selectedCustomerForLabel}
                teamCustomerLabels={(labelListCount?.list ?? []) as CustomerLabelEntities[]}
                onSubmit={() => {
                    setSelectedCustomerForLabel(undefined)
                    refetch()
                }}
                onClose={() => {
                    setSelectedCustomerForLabel(undefined)
                    refetch()
                }}
            />
            <CConfirmModal
                visible={!!deleteCustomer}
                onRequestClose={closeDeleteModal}
                onRequestConfirm={confirmDeleteModal}
                confirmLabel={t('CCustomerDetailInfo.削除確定')}>
                {t('CCustomerDetailInfo.顧客「customer」を削除します。よろしいですか？', {
                    customer: deleteCustomer?.name,
                })}
            </CConfirmModal>
            <CConfirmModal
                visible={!!selectedCustomerForRequest}
                onRequestClose={closeRequestModal}
                onRequestConfirm={confirmRequestModal}
                confirmLabel={t('Button.送信')}>
                {t('CCustomerDetailInfo.顧客「customer」へ購入条件リクエストを送信します。よろしいですか？', {
                    customer: selectedCustomerForRequest?.name,
                })}
            </CConfirmModal>
            <CCustomerFilterEdit
                visible={!!editCustomerFilter}
                data={editCustomerFilter}
                onSubmit={() => {
                    setEditCustomerFilter(undefined)
                    refetch()
                }}
                onClose={() => {
                    setEditCustomerFilter(undefined)
                    refetch()
                }}
            />
            <CConfirmModal
                visible={!!deleteCustomerFilter}
                onRequestClose={() => setDeleteCustomerFilter(undefined)}
                onRequestConfirm={() => deleteFilter()}
                confirmLabel={t('CCustomerDetailInfo.削除確定')}>
                <div>{t('CCustomerDetailInfo.絞り込み条件を削除します。よろしいですか？')}</div>
            </CConfirmModal>
        </div>
    )
}

export default CCustomerDetailInfo
