import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useState, VFC } from 'react'

import { RealEstateEntities, useClientRealEstateRemovePublishMarketMutation } from '~/app/api'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'

export type CRealEstateDetailFinishMarketViewProps = {
    visible: boolean
    onRequestClose: () => void
    onRequestConfirm: () => void
} & CRealEstateDetailFinishMarketProps

export const CRealEstateDetailFinishMarketView: VFC<CRealEstateDetailFinishMarketViewProps> = ({
    visible,
    onRequestClose,
    onRequestConfirm,
}) => {
    const title = t('CRealEstateDetailFinishMarket.マーケット掲載物件の紹介を終了しました')?.toString()

    return (
        <CConfirmModal
            title={title}
            visible={visible}
            content={{ width: '60%' }}
            onRequestClose={() => onRequestClose()}
            onRequestConfirm={() => onRequestConfirm()}>
            <div>
                <div className={clsx('p-2', 'text-center')}>
                    {t('CRealEstateDetailFinishMarket.マーケット掲載物件の紹介を終了しました。マーケット掲載も終了しますか？')}
                </div>
            </div>
        </CConfirmModal>
    )
}

export type CRealEstateDetailFinishMarketProps = {
    visible: boolean
    realEstate?: RealEstateEntities
    onSubmit: () => void
    onClose: () => void
}

export const CRealEstateDetailFinishMarket: VFC<CRealEstateDetailFinishMarketProps> = ({ ...props }) => {
    const [visible, setVisible] = useState(props.visible)

    useEffect(() => {
        setVisible(props.visible)
    }, [props.visible])

    const onRequestClose = () => {
        props.onClose()
    }

    const [removePublishMarketQuery] = useClientRealEstateRemovePublishMarketMutation()
    const onRequestConfirm = async () => {
        if (!props.realEstate) return
        await removePublishMarketQuery({ uuid: props.realEstate.uuid }).unwrap()

        onRequestClose()
    }

    return (
        <CRealEstateDetailFinishMarketView
            {...props}
            visible={visible}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
        />
    )
}
