import clsx from 'clsx'
import { KeyboardEvent, VFC } from 'react'

export type CTextInputViewProps = {} & CTextInputProps

export const CTextInputView: VFC<CTextInputViewProps> = ({ ...props }) => {
    switch (props.type) {
        case 'password':
            return (
                <input
                    name="password"
                    type="password"
                    value={props.text}
                    placeholder={props.placeholder}
                    readOnly={props.readonly}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    onChange={(event) => {
                        !!props.onChange && props.onChange(event.target.value)
                    }}
                    onKeyUp={() => !!props.onKeyUp && props.onKeyUp()}
                    onBlur={() => !!props.onBlur && props.onBlur()}
                    className={props.className}
                />
            )
        case 'number':
            return (
                <input
                    type="number"
                    value={props.text}
                    placeholder={props.placeholder}
                    readOnly={props.readonly}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    onChange={(event) => {
                        !!props.onChange && props.onChange(event.target.value)
                    }}
                    onKeyUp={() => !!props.onKeyUp && props.onKeyUp()}
                    onBlur={() => !!props.onBlur && props.onBlur()}
                    className={props.className}
                />
            )
        case 'multiline':
            return (
                <textarea
                    placeholder={props.placeholder}
                    readOnly={props.readonly}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    value={props.text}
                    onChange={(event) => {
                        !!props.onChange && props.onChange(event.target.value)
                    }}
                    onBlur={() => !!props.onBlur && props.onBlur()}
                    className={props.className}
                    rows={props.rows}
                />
            )
        case 'text':
            return (
                <input
                    type="text"
                    value={props.text}
                    placeholder={props.placeholder}
                    readOnly={props.readonly}
                    disabled={props.disabled}
                    maxLength={props.maxLength}
                    onChange={(event) => {
                        !!props.onChange && props.onChange(event.target.value)
                    }}
                    onKeyUp={() => !!props.onKeyUp && props.onKeyUp()}
                    onKeyDown={(e) => !!props.onKeyDown && props.onKeyDown(e)}
                    onBlur={() => !!props.onBlur && props.onBlur()}
                    className={clsx(props.disabled && 'bg-gray-100', props.disabled && 'text-gray-500', props.className)}
                />
            )
        default:
            return <CTextInputView {...props} />
    }
}

export type CTextInputProps = {
    type: 'password' | 'number' | 'multiline' | 'text'
    text?: string
    className?: string
    placeholder?: string
    readonly?: boolean
    disabled?: boolean
    maxLength?: number
    rows?: number
    onChange?: (value: string) => void
    onKeyUp?: () => void
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
    onClickReset?: () => void
    onFocus?: () => void
    onInput?: () => void
    onBlur?: () => void
}

export const CTextInput: VFC<CTextInputProps> = (props) => {
    return <CTextInputView {...props} />
}
