import {
    Active,
    ClientRect,
    closestCorners,
    CollisionDetection,
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DroppableContainer,
    KeyboardSensor,
    MouseSensor,
    pointerWithin,
    TouchSensor,
    useDroppable,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { RectMap } from '@dnd-kit/core/dist/store'
import { Coordinates } from '@dnd-kit/core/dist/types'
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { clsx } from 'clsx'
import { t } from 'i18next'
import { CSSProperties, FC, ReactNode, useEffect, useState } from 'react'

export type CDragAndDropItemSetProps = {
    data: CDragAndDropItemSetColumnType[]
    autoSorting?: boolean
    onChange?: (data: CDragAndDropItemSetColumnType[]) => void
}

export type CDragAndDropItemSetCardType = {
    id: string
    element: ReactNode
    sort?: number
}

export type CDragAndDropItemSetColumnType = {
    id: string
    title: string
    cards: CDragAndDropItemSetCardType[]
}

export const CDragAndDropItemSet: FC<CDragAndDropItemSetProps> = ({ data, onChange, autoSorting }) => {
    const Card: FC<CDragAndDropItemSetCardType> = ({ id, element }) => {
        const { attributes, listeners, setNodeRef, transform } = useSortable({
            id: id,
        })

        const style: CSSProperties = {
            transform: CSS.Transform.toString(transform),
        }

        return (
            <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
                <div id={id} className={clsx('cursor-grab')}>
                    {element}
                </div>
            </div>
        )
    }

    const Column: FC<CDragAndDropItemSetColumnType> = ({ id, title, cards }) => {
        const { setNodeRef } = useDroppable({ id: id })
        return (
            <SortableContext id={id} items={cards} strategy={rectSortingStrategy}>
                <div ref={setNodeRef} className={clsx('flex', 'flex-col', 'h-full')}>
                    <div className={clsx('bg-kimar-primary', 'text-white', 'p-2')}>{title}</div>
                    <div className={clsx('p-1', 'overflow-y-auto', 'flex-1', 'flex', 'flex-col')}>
                        {cards.map((card) => (
                            <Card key={card.id} id={card.id} element={card.element} />
                        ))}
                    </div>
                </div>
            </SortableContext>
        )
    }

    const findColumn = (unique: string | null) => {
        if (!unique) return null
        if (columns.some((c) => c.id === unique)) return columns.find((c) => c.id === unique) ?? null

        const id = String(unique)
        const itemWithColumnId = columns.flatMap((c) => {
            const columnId = c.id
            return c.cards.map((i) => ({ itemId: i.id, columnId: columnId }))
        })
        const columnId = itemWithColumnId.find((i) => i.itemId === id)?.columnId
        return columns.find((c) => c.id === columnId) ?? null
    }

    const handleDragOver = (event: DragOverEvent) => {
        const { active, over, delta } = event
        const activeId = String(active.id)
        const overId = over ? String(over.id) : null
        const activeColumn = findColumn(activeId)
        const overColumn = findColumn(overId)
        if (!activeColumn || !overColumn || activeColumn === overColumn) return null

        setColumns((prevState) => {
            const activeItems = activeColumn.cards
            const overItems = overColumn.cards
            const activeIndex = activeItems.findIndex((i) => i.id === activeId)
            const overIndex = overItems.findIndex((i) => i.id === overId)
            const newIndex = () => {
                const putOnBelowLastItem = overIndex === overItems.length - 1 && delta.y > 0
                const modifier = putOnBelowLastItem ? 1 : 0
                return overIndex >= 0 ? overIndex + modifier : overItems.length + 1
            }
            return prevState.map((c) => {
                if (c.id === activeColumn.id) {
                    c.cards = activeItems.filter((i) => i.id !== activeId)
                    return c
                } else if (c.id === overColumn.id) {
                    c.cards = [
                        ...overItems.slice(0, newIndex()),
                        activeItems[activeIndex],
                        ...overItems.slice(newIndex(), overItems.length),
                    ]
                    return c
                } else return c
            })
        })
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event
        const activeId = String(active.id)
        const overId = over ? String(over.id) : null
        const activeColumn = findColumn(activeId)
        const overColumn = findColumn(overId)
        if (!activeColumn || !overColumn || activeColumn !== overColumn) return null

        const activeIndex = activeColumn.cards.findIndex((i) => i.id === activeId)
        const overIndex = autoSorting
            ? overColumn.cards.find((i) => i.id === activeId)?.sort ?? 0
            : overColumn.cards.findIndex((i) => i.id === overId)
        if (activeIndex !== overIndex) {
            setColumns((prevState) => {
                return prevState.map((column) => {
                    if (column.id === activeColumn.id) {
                        column.cards = arrayMove(overColumn.cards, activeIndex, overIndex)
                        return column
                    } else return column
                })
            })
        }
    }

    const pointerAndClosestCorners: CollisionDetection = (collisionArgs: {
        active: Active
        collisionRect: ClientRect
        droppableRects: RectMap
        droppableContainers: DroppableContainer[]
        pointerCoordinates: Coordinates | null
    }) => {
        const pointerCollisions = pointerWithin(collisionArgs)
        if (pointerCollisions.length > 0) return pointerCollisions
        return closestCorners(collisionArgs)
    }

    const [columns, setColumns] = useState<CDragAndDropItemSetColumnType[]>(data)
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )

    useEffect(() => {
        onChange && onChange(columns)
    }, [columns])

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={pointerAndClosestCorners}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}>
            <div className={clsx('flex', 'flex-row', 'p-5', 'gap-2', 'overflow-hidden', 'w-full')}>
                {columns.map((column, index) => (
                    <>
                        <div key={column.id} className={clsx('flex-1', 'border', 'border-gray-200', 'overflow-y-hidden')}>
                            <Column key={column.id} id={column.id} title={column.title} cards={column.cards} />
                        </div>
                        {index != columns.length - 1 && (
                            <div
                                key={`${column.id}-arrow`}
                                className={clsx('text-xs', 'flex', 'flex-col', 'justify-center', 'items-center')}>
                                <div>{t('CDragAndDropItemSet.ドラッグして')}</div>
                                <i className={clsx('border-2', 'rounded-full', 'border-white', 'material-icons', 'text-[16px]')}>
                                    sync_alt
                                </i>
                                <div>{t('CDragAndDropItemSet.移動')}</div>
                            </div>
                        )}
                    </>
                ))}
            </div>
        </DndContext>
    )
}
