import clsx from 'clsx'
import purify from 'dompurify'
import { useState, VFC } from 'react'

import { AdminFaqUpdateDto, FaqContentEntities } from '~/app/api'

export type CFaqListItemViewProps = {} & CFaqListItemProps

export const CFaqListItemView: VFC<CFaqListItemViewProps> = ({ faq, defaultOpen = false }) => {
    // 選択状態か否か
    const [isActive, setIsActive] = useState(defaultOpen)

    return (
        <>
            <div
                className={clsx('flex', 'items-center', 'cursor-pointer', 'py-2', isActive && 'bg-kimar-primary')}
                onClick={() => setIsActive((old) => !old)}>
                <img
                    className={clsx('h-8', 'm-2')}
                    src={isActive ? '/src/assets/image/Q-white.png' : '/src/assets/image/Q.png'}
                    alt="question"
                />
                <div className={clsx(isActive ? 'text-white' : 'text-black')}>{faq.question}</div>
                <img
                    className={clsx('ml-auto', 'mr-4')}
                    src={isActive ? '/src/assets/image/page/lp/arrow-close.svg' : '/src/assets/image/page/lp/arrow-open.svg'}
                    alt="arrow"
                    width="30px"
                />
            </div>
            <p
                className={clsx(
                    !isActive && 'hidden',
                    'pl-8',
                    'pr-4',
                    'py-4',
                    'bg-white',
                    'border',
                    'border-kimar-primary',
                    'text-sm',
                )}
                dangerouslySetInnerHTML={{ __html: `A: ${purify.sanitize(faq.answer)}` }}
            />
        </>
    )
}

export type CFaqListItemProps = {
    faq: FaqContentEntities | AdminFaqUpdateDto
    defaultOpen?: boolean
}

export const CFaqListItem: VFC<CFaqListItemProps> = (props) => {
    return <CFaqListItemView {...props} />
}
