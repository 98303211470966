import clsx from 'clsx'
import { ReactNode, VFC } from 'react'

export type CButtonViewProps = {} & CButtonProps

export const CButtonView: VFC<CButtonViewProps> = ({
    onClick,
    children,
    className,
    disabled,
    preventDefault,
    stopPropagation,
    disabledText,
}) => {
    const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!onClick) return
        if (preventDefault) e.preventDefault()
        if (stopPropagation) e.stopPropagation()
        onClick()
    }

    return (
        <>
            {(!disabled || (disabled && !disabledText)) && (
                <button
                    type="button"
                    onClick={(e) => onClickHandler(e)}
                    disabled={disabled}
                    className={clsx(!disabled && 'cursor-pointer', className)}>
                    {children}
                </button>
            )}
            {disabled && !!disabledText && (
                <div className={clsx('relative')}>
                    <button
                        type="button"
                        onClick={(e) => onClickHandler(e)}
                        disabled={disabled}
                        className={clsx(!disabled && 'cursor-pointer', className, 'peer')}>
                        {children}
                    </button>
                    <div
                        className={clsx(
                            'opacity-0',
                            'peer-hover:opacity-100',
                            'pointer-events-none',
                            'absolute',
                            'text-gray-700',
                            'z-10',
                            'p-2',
                            'rounded',
                            'bg-white',
                            'bg-opacity-90',
                            'transition',
                            'text-sm',
                            'w-[200px]',
                            ['top-6', 'right-0'],
                        )}>
                        {disabledText}
                    </div>
                </div>
            )}
        </>
    )
}

export type CButtonProps = {
    disabled?: boolean
    disabledText?: string
    children?: ReactNode
    className?: string
    onClick?: () => void
    preventDefault?: boolean // デフォルト動作を抑える
    stopPropagation?: boolean // クリックイベント伝播を抑える
}

export const CButton: VFC<CButtonProps> = (props) => {
    return <CButtonView {...props} />
}
