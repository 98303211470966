import { CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js'
import clsx from 'clsx'
import { Helmet } from 'react-helmet-async'

import { CHeader } from '~/components/common/cHeader/CHeader'
import { pageTitleTemplate } from '~/util/common/common'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const AdminDashboard = () => {
    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate(true)}>
                <title>KPI</title>
            </Helmet>
            <div className={clsx('flex', 'flex-col', 'gap-4', 'justify-center')}>
                {(process.env.STAGE === 'local' || process.env.STAGE === 'dev') && (
                    <>
                        <CHeader label="【local/develop環境】Googleアナリティクス統計情報" />
                        <div className={clsx('p-4', 'bg-white', 'overflow-auto')}>
                            <iframe
                                width="800"
                                height="600"
                                className={clsx('mx-auto')}
                                src="https://lookerstudio.google.com/embed/reporting/990eed66-94e2-4239-bd9d-d06028613c4b/page/h00ND"
                                allowFullScreen
                            />
                        </div>
                    </>
                )}
                {process.env.STAGE === 'stage' && (
                    <>
                        <CHeader label="【Staging環境】Googleアナリティクス統計情報" />
                        <div className={clsx('p-4', 'bg-white', 'overflow-auto')}>
                            <iframe
                                width="800"
                                height="600"
                                className={clsx('mx-auto')}
                                src="https://lookerstudio.google.com/embed/reporting/742317ca-8870-456d-a727-345b3e28a88d/page/h00ND"
                                allowFullScreen
                            />
                        </div>
                    </>
                )}
                {process.env.STAGE === 'prod' && (
                    <>
                        <CHeader label="Googleアナリティクス統計情報" />
                        <div className={clsx('p-4', 'bg-white', 'overflow-auto')}>
                            <iframe
                                width="800"
                                height="600"
                                className={clsx('mx-auto')}
                                src="https://lookerstudio.google.com/embed/reporting/dc7b3c74-e06c-4455-8f25-4fec34d65e9d/page/h00ND"
                                allowFullScreen
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
export default AdminDashboard
