import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { Ref, useEffect, useMemo, useRef, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'
import { useMediaQuery } from 'react-responsive'

import {
    CustomerFilterEntities,
    RealEstateUseTypeEntities,
    useClientCustomerFilterPostCustomerFilterMutation,
    useClientCustomerFilterPutUuidMutation,
    useClientRealEstateUseTypeRealEstateUseTypeQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBoxList } from '~/components/common/cCheckBoxList/CCheckBoxList'
import { CError } from '~/components/common/cError/CError'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { ageRanges, realestateGrossRateTypeItems, realEstatePriceRanges } from '~/types/enum/enum'
import { ApplicationError, IApplicationError } from '~/types/error'

export type CCustomerFilterEditViewProps = {
    form: {
        customerUuid: string
        uuid?: string | undefined
        useTypes?: string[] | undefined
        prices?: number[] | undefined
        buildingAgeTypes?: number[] | undefined
        grossRateTypes?: number[] | undefined
    }
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (key: string, value: unknown) => void
    scrollRef: Ref<HTMLDivElement>
    disableSubmit: boolean
    errors: IApplicationError[]
} & CCustomerFilterEditProps

export const CCustomerFilterEditView: VFC<CCustomerFilterEditViewProps> = ({
    visible,
    form,
    onRequestClose,
    onRequestConfirm,
    setProps,
    scrollRef,
    disableSubmit,
    errors,
}) => {
    const grid = 5
    const isMobile = useMediaQuery({ maxWidth: '768px' })
    const { data: useTypeListCount } = useClientRealEstateUseTypeRealEstateUseTypeQuery({ isNotSetOption: true })
    const footer = (
        <>
            <CButton className={clsx('c-button-secondary')} onClick={() => onRequestClose()}>
                {t('Button.キャンセル')}
            </CButton>
            <CButton disabled={disableSubmit} className={clsx('c-button-primary')} onClick={() => onRequestConfirm()}>
                {form.uuid ? t('CCustomerFilterEdit.編集確定') : t('CCustomerFilterEdit.登録確定')}
            </CButton>
        </>
    )
    return (
        <CFooterModal
            footer={footer}
            visible={visible}
            content={{ width: '800px' }}
            onRequestClose={() => onRequestClose()}
            closeOnOverlayClick={false}>
            <div>
                <div className={clsx('px-8', 'py-2')}>
                    <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>
                        {form.uuid ? t('CCustomerFilterEdit.絞り込み条件を編集') : t('CCustomerFilterEdit.絞り込み条件を登録')}
                    </div>
                    <div ref={scrollRef} />
                    <CError errors={errors} />
                    <div className={clsx('text-gray-700', 'font-bold', 'my-4')}>{t('CCustomerFilterEdit.絞り込み条件')}</div>
                    <div>
                        <CLabeledItem label={t('CCustomerFilterEdit.種別')}>
                            <div className={clsx('border', 'rounded', 'p-2')}>
                                <CCheckBoxList
                                    grid={isMobile ? 3 : grid}
                                    items={useTypeListCount?.list as RealEstateUseTypeEntities[]}
                                    dataLabel={'name'}
                                    dataValue={'uuid'}
                                    checked={form.useTypes}
                                    onChange={(value) => setProps('useTypes', value)}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CCustomerFilterEdit.金額')}>
                            <div className={clsx('border', 'rounded', 'p-2')}>
                                <CCheckBoxList
                                    grid={isMobile ? 3 : grid}
                                    items={realEstatePriceRanges}
                                    checked={form.prices}
                                    onChange={(value) => setProps('prices', value)}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CCustomerFilterEdit.築年数')}>
                            <div className={clsx('border', 'rounded', 'p-2')}>
                                <CCheckBoxList
                                    grid={isMobile ? 3 : grid}
                                    items={ageRanges}
                                    checked={form.buildingAgeTypes}
                                    onChange={(value) => setProps('buildingAgeTypes', value)}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CCustomerFilterEdit.表面利回り')}>
                            <div className={clsx('border', 'rounded', 'p-2')}>
                                <CCheckBoxList
                                    grid={isMobile ? 3 : grid}
                                    items={realestateGrossRateTypeItems}
                                    checked={form.grossRateTypes}
                                    onChange={(value) => setProps('grossRateTypes', value)}
                                />
                            </div>
                        </CLabeledItem>
                    </div>
                </div>
            </div>
        </CFooterModal>
    )
}

export type CCustomerFilterEditProps = {
    visible: boolean
    data?: {
        customerUuid: string
        uuid?: string
        useTypes?: string[]
        prices?: number[]
        buildingAgeTypes?: number[]
        grossRateTypes?: number[]
    }
    onClose: () => void
    onSubmit: (customerFilter: CustomerFilterEntities) => void
}

export const CCustomerFilterEdit: VFC<CCustomerFilterEditProps> = ({ ...props }) => {
    const [visible, setVisible] = useState(props.visible)
    const [form, setForm] = useState<{
        customerUuid: string
        uuid?: string
        useTypes?: string[]
        prices?: number[]
        buildingAgeTypes?: number[]
        grossRateTypes?: number[]
    }>()
    useEffect(() => {
        setErrors([])
        setForm(props.data)
        setVisible(props.visible)
    }, [props.visible, props.data])

    const setProps = (key: string, value: unknown) => {
        if (!form) return
        setForm({
            ...form,
            [key]: value,
        })
    }

    const onRequestClose = () => {
        props.onClose()
    }

    const [insertCustomerFilterQuery] = useClientCustomerFilterPostCustomerFilterMutation()
    const [updateCustomerFilterQuery] = useClientCustomerFilterPutUuidMutation()
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const onRequestConfirm = async () => {
        if (!form) return
        try {
            setErrors([])
            if (!form.uuid) {
                const filter = await insertCustomerFilterQuery({
                    clientCustomerFilterInsertDto: {
                        customerUuid: form.customerUuid,
                        useTypes: form.useTypes,
                        prices: form.prices,
                        buildingAgeTypes: form.buildingAgeTypes,
                        grossRateTypes: form.grossRateTypes,
                    },
                }).unwrap()
                props.onSubmit(filter)
                toast.success(t('CCustomerFilterEdit.絞り込み条件を登録しました'))
            } else {
                const filter = await updateCustomerFilterQuery({
                    uuid: form.uuid,
                    clientCustomerFilterUpdateDto: {
                        customerUuid: form.customerUuid,
                        useTypes: form.useTypes,
                        prices: form.prices,
                        buildingAgeTypes: form.buildingAgeTypes,
                        grossRateTypes: form.grossRateTypes,
                    },
                }).unwrap()
                props.onSubmit(filter)
                toast.success(t('CCustomerFilterEdit.絞り込み条件を編集しました'))
            }
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            scrollTop()
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const scrollRef = useRef<HTMLDivElement>(null)
    const scrollTop = () => {
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const disableSubmit = useMemo(() => {
        return (
            !form ||
            (form.useTypes?.length ?? 0) +
                (form.prices?.length ?? 0) +
                (form.buildingAgeTypes?.length ?? 0) +
                (form.grossRateTypes?.length ?? 0) ===
                0
        )
    }, [form])

    if (!props.data || !form) return null
    return (
        <CCustomerFilterEditView
            {...props}
            visible={visible}
            form={form}
            scrollRef={scrollRef}
            onRequestConfirm={onRequestConfirm}
            onRequestClose={onRequestClose}
            setProps={setProps}
            disableSubmit={disableSubmit}
            errors={errors}
        />
    )
}
