import clsx from 'clsx'
import { t } from 'i18next'
import { isNil, maxBy } from 'lodash'
import { useEffect, useState } from 'react'
import { createSearchParams } from 'react-router-dom'

import { CustomerEntities, IntermediaryToEntities, useClientRealEstateGetNoReadChatActivityQuery } from '~/app/api'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import { taxType } from '~/types/enum/enum'
import { calcYearsBuilt, dateFormatOfJc, fullCurrencyFormat } from '~/util/common/common'
import { getSignedUrl } from '~/util/model/FileEntities'

import { CButton } from '../../../common/cButton/CButton'

type Props = {
    customer: CustomerEntities
    intermediaryTo: IntermediaryToEntities
    showChatButton: boolean
    showDocumentButton: boolean
    // activityDto?: ClientRealEstateActivityDto
}

const CCustomerDetailRealEstateListItem = ({ intermediaryTo, showChatButton, showDocumentButton }: Props) => {
    const detailLink = `/realestate/${intermediaryTo.intermediary.sourceRealEstateRevision.realEstate?.uuid}`
    const sourceRealEstateRevision = intermediaryTo.intermediary.sourceRealEstateRevision

    const [realEstateImage, setRealEstateImage] = useState<string>()
    useEffect(() => {
        const getLogo = async () => {
            const coverImg = maxBy(
                intermediaryTo.intermediary.sourceRealEstateRevision.realEstate?.documents?.filter((d) => !!d.isCover),
                'createdAt',
            )?.file

            if (coverImg) setRealEstateImage(getSignedUrl(coverImg))
            else setRealEstateImage(`/src/assets/image/noimage.svg`)
        }
        getLogo()
    }, [intermediaryTo])

    const openChat = (intermediaryTo: IntermediaryToEntities) => {
        const url = new URL(window.location.origin + '/chat')
        url.search = createSearchParams({
            intermediaryTo: intermediaryTo.uuid,
            isSentTeam: 'true',
        }).toString()

        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        window.open(
            url,
            '_blank',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )
    }

    const openDocument = (intermediaryTo: IntermediaryToEntities) => {
        const url = new URL(window.location.origin + '/intermediary/' + intermediaryTo.uuid + '/document')

        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        window.open(
            url,
            '_blank',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )
    }

    const { data: activityDto } = useClientRealEstateGetNoReadChatActivityQuery(
        {
            realEstateUuids: [intermediaryTo.intermediary.sourceRealEstateRevision.realEstate.uuid],
        },
        { skip: !intermediaryTo?.intermediary?.sourceRealEstateRevision?.realEstate },
    )
    const hasNewChat = (intermediaryTo: IntermediaryToEntities) => {
        if (!activityDto) return false
        return activityDto.sendNewChat.find((ta) =>
            (ta.params as { from_name: string })['from_name'].includes(intermediaryTo.destinationTo?.email ?? ''),
        )
    }

    return (
        <div className={clsx('m-2')}>
            <div className={clsx('p-2', 'border', 'rounded')}>
                <div className={clsx('flex', 'justify-between')}>
                    <div className={clsx('flex-1', 'overflow-x-hidden')}>
                        <div
                            className={clsx(
                                'text-xl',
                                'text-gray-700',
                                'font-bold',
                                'w-[20em]',
                                'text-ellipsis',
                                'whitespace-nowrap',
                                'overflow-hidden',
                            )}>
                            {sourceRealEstateRevision.name}
                        </div>
                        <div className={clsx('flex', 'items-center')}>
                            <div className={clsx('text-xs', 'text-white', 'bg-gray-500', 'py-0.5', 'px-1', 'mr-1')}>
                                {sourceRealEstateRevision.useType.name}
                            </div>
                            <div className={clsx('text-sm', 'text-gray-700')}>{sourceRealEstateRevision.address}</div>
                        </div>
                    </div>
                    <div className={clsx('flex', 'items-baseline')}>
                        <div className={clsx('flex', 'items-baseline')}>
                            <div className={clsx('text-sm', 'text-kimar-primary')}>
                                {t('CCustomerDetailRealEstateListItem.物件価格')}
                            </div>
                            <div className={clsx('text-lg', 'text-kimar-primary', 'font-bold')}>
                                {fullCurrencyFormat(sourceRealEstateRevision.realEstate?.price)}
                            </div>
                            <div className={clsx('text-sm', 'text-kimar-primary')}>{t('Unit.円')}</div>
                        </div>

                        <div className={clsx('flex', 'items-baseline')}>
                            <div className={clsx('text-sm', 'text-kimar-primary')}>
                                ({t('CCustomerDetailRealEstateListItem.提示価格')}
                            </div>
                            <div className={clsx('text-sm', 'text-kimar-primary', 'font-bold')}>
                                {fullCurrencyFormat(intermediaryTo.intermediary.price)}
                                {intermediaryTo.intermediary.taxType
                                    ? ' (' + taxType.find((t) => t.value === intermediaryTo.intermediary.taxType)?.label + ')'
                                    : ''}
                            </div>
                            <div className={clsx('text-sm', 'text-kimar-primary')}>{t('Unit.円')})</div>
                        </div>
                    </div>
                </div>
                <div className={clsx('flex', 'my-2')}>
                    <div className={clsx('h-[80px]', 'w-[80px]', 'flex')}>
                        <img
                            src={realEstateImage}
                            alt=""
                            loading="lazy"
                            className={clsx('h-[80px]', 'w-[80px]', 'rounded', 'border', 'border-gray-200', 'object-cover')}
                            crossOrigin="anonymous"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null
                                currentTarget.src = '/src/assets/image/notfound.svg'
                            }}
                        />
                    </div>
                    <div className={clsx('flex-1', 'my-2')}>
                        <div className={clsx('mx-2')}>
                            <div className={clsx('text-xs')}>
                                <div className={clsx('flex', 'border-b')}>
                                    <div className={clsx('w-24', 'font-bold')}>
                                        {t('CCustomerDetailRealEstateListItem.竣工日')}
                                    </div>
                                    <div className="data">
                                        <span>{dateFormatOfJc(sourceRealEstateRevision.buildAt, 'day')}</span>
                                        <span className={clsx('ml-2')}>{calcYearsBuilt(sourceRealEstateRevision.buildAt)}</span>
                                    </div>
                                </div>
                                <div className={clsx('flex', 'border-b')}>
                                    <div className={clsx('w-24', 'font-bold')}>
                                        {t('CCustomerDetailRealEstateListItem.利回り')}
                                    </div>
                                    <div className="data">
                                        {!isNil(sourceRealEstateRevision.grossRate) && (
                                            <>
                                                <span>{t('CCustomerDetailRealEstateListItem.表面')}</span>
                                                <span>{sourceRealEstateRevision.grossRate}</span>
                                                <span>%</span>
                                            </>
                                        )}
                                        {!isNil(sourceRealEstateRevision.grossRate) &&
                                            !isNil(sourceRealEstateRevision.netRate) && <span>{' / '}</span>}
                                        {!isNil(sourceRealEstateRevision.netRate) && (
                                            <>
                                                <span>{t('CCustomerDetailRealEstateListItem.実質')}</span>
                                                <span>{sourceRealEstateRevision.netRate}</span>
                                                <span>%</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className={clsx('flex', 'border-b')}>
                                    <div className={clsx('w-24', 'font-bold')}>
                                        {t('CCustomerDetailRealEstateListItem.担当者')}
                                    </div>
                                    <div className="data">
                                        <span>
                                            {sourceRealEstateRevision.realEstate?.assignees
                                                ?.map((assignee) => {
                                                    if (assignee.isPrimary) return assignee.assignedTo?.name + '(主)'
                                                    return assignee.assignedTo?.name
                                                })
                                                .join(', ')}
                                        </span>
                                    </div>
                                </div>
                                <div className={clsx('flex', 'border-b')}>
                                    <div className={clsx('w-24', 'font-bold')}>
                                        {t('CCustomerDetailRealEstateListItem.登録者/紹介元')}
                                    </div>
                                    <div className="data">
                                        <span>{intermediaryTo.intermediary?.sentBy?.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('flex', 'gap-2')}>
                    {showChatButton && (
                        <CButton
                            className={clsx(
                                'border',
                                'rounded',
                                'border-kimar-primary',
                                'w-8',
                                'h-8',
                                'flex',
                                'justify-center',
                                'items-center',
                                'relative',
                            )}
                            onClick={() => openChat(intermediaryTo)}>
                            <i className={clsx('material-icons', 'text-kimar-primary')}>chat</i>
                            {hasNewChat(intermediaryTo) && (
                                <div
                                    className={clsx('absolute', 'top-0', 'right-0', 'w-2', 'h-2', 'rounded-full', 'bg-red-700')}
                                />
                            )}
                        </CButton>
                    )}
                    {showDocumentButton && (
                        <CButton
                            className={clsx(
                                'border',
                                'rounded',
                                'border-kimar-primary',
                                'w-8',
                                'h-8',
                                'flex',
                                'justify-center',
                                'items-center',
                            )}
                            onClick={() => openDocument(intermediaryTo)}>
                            <i className={clsx('material-icons', 'text-kimar-primary')}>description</i>
                        </CButton>
                    )}
                    <CLinkButton className={clsx('c-button-primary', 'flex-grow')} to={detailLink} blank>
                        {t('CCustomerDetailRealEstateListItem.物件詳細へ')}
                    </CLinkButton>
                </div>
            </div>
        </div>
    )
}

export default CCustomerDetailRealEstateListItem
