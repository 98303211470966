import clsx from 'clsx'
import { t } from 'i18next'
import { sortBy } from 'lodash'
import { FC, useEffect, useRef, useState } from 'react'

import {
    ClientCustomerCustomerApiArg,
    CustomerLabelEntities,
    RealEstateEntities,
    RealEstateUseTypeEntities,
    TeamMemberEntities,
    useClientCustomerLabelTeamUuidQuery,
    useClientRealEstateUseTypeRealEstateUseTypeQuery,
    useClientTeamMemberTeamUuidQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBoxItemInterface, CCheckBoxList } from '~/components/common/cCheckBoxList/CCheckBoxList'
import { CGuideTips } from '~/components/common/cGuideTips/CGuideTips'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CTag } from '~/components/common/cTag/CTag'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import {
    areaTypeItems,
    customerBuildingAgeTypeItems,
    customerPriceRanges,
    customerRegisteredTypeItems,
    saleTimeTypeItems,
} from '~/types/enum/enum'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUserTeam } from '~/util/store/userSlice'

export type CCustomerSearchViewProps = {
    isOpenDetail: boolean
    onClickDetail: () => void
    isOpenFilter: boolean
    onClickFilter: () => void
    onChangeText: (val: string) => void
    form: CustomerSearchFormValue
    name: string
    onChangeFilterCheckBox: (val: CustomerSearchFilterArrayType, target: CustomerSearchFilterArrayType) => void
    resetFilterCheckBox: () => void
    onChangeDetailCheckBox: (key: keyof CustomerSearchFormValue, val: string[] | number[]) => void
    onChangeDetailAndOrToggle: (key: keyof CustomerSearchFormValue, val: 'AND' | 'OR') => void
    resetDetail: () => void
    teamMembers: TeamMemberEntities[]
    useTypes: RealEstateUseTypeEntities[]
    labels: CustomerLabelEntities[]
    closeAll: () => void
} & CCustomerSearchProps

export const CCustomerSearchView: FC<CCustomerSearchViewProps> = ({
    isOpenDetail,
    onClickDetail,
    isOpenFilter,
    onClickFilter,
    onChangeText,
    form,
    name,
    onChangeFilterCheckBox,
    resetFilterCheckBox,
    onChangeDetailCheckBox,
    onChangeDetailAndOrToggle,
    resetDetail,
    teamMembers,
    useTypes,
    labels,
    closeAll,
}) => {
    // 選択中の絞り込みと詳細検索を表示する
    const filterTags = () => {
        let elements: JSX.Element[] = []
        elements = elements.concat(
            form.filter.map((v) => {
                return (
                    <CTag className={clsx('rounded', 'px-2', 'text-gray-700', 'bg-gray-300', 'text-sm', 'items-center')}>
                        <div className={clsx('flex', 'justify-center')}>
                            <div className={clsx('whitespace-nowrap')}>
                                {CustomerSearchFilterLangString.find((s) => s.value === v)?.label}
                            </div>
                            <div>
                                <CButton
                                    onClick={() => {
                                        onChangeFilterCheckBox(
                                            form.filter.filter((f) => f != v) as CustomerSearchFilterArrayType,
                                            Array.from(CustomerSearchFilterStrings),
                                        )
                                    }}>
                                    <i className={clsx('material-symbols-outlined', 'text-sm')}>close</i>
                                </CButton>
                            </div>
                        </div>
                    </CTag>
                )
            }),
        )
        elements = elements.concat(
            [
                {
                    prop: 'prices' as const,
                    label: (v: unknown) =>
                        `${t('CCustomerSearch.購入条件（金額）')}:${customerPriceRanges.find((s) => s.value === v)?.label}`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox('prices', form.prices.filter((f) => f != v) as unknown as number[]),
                },
                {
                    prop: 'useTypes' as const,
                    label: (v: unknown) => `${t('CCustomerSearch.購入条件（種別）')}:${useTypes.find((s) => s.uuid === v)?.name}`,
                    onClick: (v: unknown) => onChangeDetailCheckBox('useTypes', form.useTypes.filter((f) => f != v) as string[]),
                },
                {
                    prop: 'areas' as const,
                    label: (v: unknown) =>
                        `${t('CCustomerSearch.購入条件（エリア）')}:${areaTypeItems.find((s) => s.value === v)?.label}`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox('areas', form.prices.filter((f) => f != v) as unknown as number[]),
                },
                {
                    prop: 'buildingAges' as const,
                    label: (v: unknown) =>
                        `${t('CCustomerSearch.購入条件（築年数）')}:${
                            customerBuildingAgeTypeItems.find((s) => s.value === v)?.label
                        }`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox('buildingAges', form.buildingAges.filter((f) => f !== v) as unknown as number[]),
                },
                {
                    prop: 'saleTimes' as const,
                    label: (v: unknown) =>
                        `${t('CCustomerSearch.購入条件（購入時期）')}:${saleTimeTypeItems.find((s) => s.value === v)?.label}`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox('saleTimes', form.saleTimes.filter((f) => f !== v) as unknown as number[]),
                },
                {
                    prop: 'assignedToUuidList' as const,
                    label: (v: unknown) =>
                        `${t('CCustomerSearch.担当者')}:${
                            teamMembers.map((m) => ({ label: m.name, value: m.uuid })).find((s) => s.value === v)?.label
                        }`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox(
                            'assignedToUuidList',
                            form.assignedToUuidList.filter((f) => f !== v) as unknown as number[],
                        ),
                },
                {
                    prop: 'labelUuidList' as const,
                    label: (v: unknown) =>
                        `${t('CCustomerSearch.顧客ラベル')}:${
                            sortBy(labels, (s) => s.sort)
                                .map((m) => ({
                                    label: m.name,
                                    value: m.uuid,
                                }))
                                .find((s) => s.value === v)?.label
                        }`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox('labelUuidList', form.labelUuidList.filter((f) => f !== v) as unknown as number[]),
                },
                {
                    prop: 'registeredTypes' as const,
                    label: (v: unknown) =>
                        `${t('CCustomerSearch.獲得経路')}:${customerRegisteredTypeItems.find((s) => s.value === v)?.label}`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox(
                            'registeredTypes',
                            form.registeredTypes.filter((f) => f !== v) as unknown as number[],
                        ),
                },
            ].flatMap((p) => {
                return form[p.prop].map((v) => {
                    return (
                        <CTag className={clsx('rounded', 'px-2', 'text-gray-700', 'bg-gray-300', 'text-sm', 'items-center')}>
                            <div className={clsx('flex', 'justify-center')}>
                                <div className={clsx('whitespace-nowrap')}>{p.label(v)}</div>
                                <div>
                                    <CButton
                                        onClick={() => {
                                            p.onClick(v)
                                        }}>
                                        <i className={clsx('material-symbols-outlined', 'text-sm')}>close</i>
                                    </CButton>
                                </div>
                            </div>
                        </CTag>
                    )
                })
            }),
        )

        if (!elements.length) return null

        return (
            <div
                className={clsx(
                    'flex',
                    'gap-1',
                    'flex-wrap',
                    'py-2',
                    'px-1',
                    'border-x',
                    'border-kimar-primary',
                    'md:border-none',
                    'bg-white',
                    'md:bg-inherit',
                )}>
                {elements}
            </div>
        )
    }

    const cCustomerSearchRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!cCustomerSearchRef) return
        const handleClickOutside = (e: MouseEvent) => {
            if (!(cCustomerSearchRef.current as Node)?.contains(e.target as Node)) closeAll()
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [cCustomerSearchRef])

    return (
        <div ref={cCustomerSearchRef} className={clsx('w-full')}>
            <div className={clsx('w-full', 'flex', 'flex-col', 'md:flex-row', 'font-body')}>
                <div
                    className={clsx(
                        'flex-1',
                        isOpenDetail ? ['border', 'rounded-tl-xl'] : ['border', 'rounded-tl-xl', 'md:rounded-l-xl'],
                        'rounded-tr-xl',
                        'md:rounded-tr-none',
                        'border-kimar-primary',
                        'bg-white',
                    )}>
                    <div className={clsx('rounded', 'flex')}>
                        <div className={clsx('flex-1', 'flex', 'items-center', 'pl-2', 'relative')}>
                            <i className={clsx('material-icons', 'text-gray-300')}>search</i>
                            <div className={clsx('hidden', 'md:block', 'max-w-[33%]')}>{filterTags()}</div>
                            <CTextInput
                                className={clsx(
                                    'w-full',
                                    'bg-inherit',
                                    'border-none',
                                    'focus:ring-0',
                                    'pl-0',
                                    'placeholder:text-gray-300',
                                )}
                                type={'text'}
                                placeholder={t('CCustomerSearch.検索キーワードを入力')}
                                text={name}
                                onChange={(val) => onChangeText(val)}
                            />
                            <div className={clsx('mr-2')}>
                                <CGuideTips tooltipDirection="bottom-left">
                                    <b>{t('CCustomerSearch.＜検索キーワード＞')}</b>
                                    <br />
                                    {t('CCustomerSearch.・会社名')}
                                    <br />
                                    {t('CCustomerSearch.・会社名ふりがな')}
                                    <br />
                                    {t('CCustomerSearch.・顧客名')}
                                    <br />
                                    {t('CCustomerSearch.・顧客名ふりがな')}
                                    <br />
                                    {t('CCustomerSearch.・顧客メールアドレス')}
                                </CGuideTips>
                            </div>
                        </div>
                        <div>
                            <CButton
                                className={clsx(
                                    'c-button-secondary',
                                    'w-full',
                                    'min-w-[120px]',
                                    'h-full',
                                    'border-r-0',
                                    'border-t-0',
                                    'border-b-0',
                                    'border-kimar-primary',
                                    'rounded-none',
                                    'rounded-tr-xl',
                                    'md:rounded-tr-none',
                                )}
                                onClick={() => onClickFilter()}>
                                <i className={clsx('material-icons-outlined', 'text-kimar-primary')}>filter_alt</i>
                                <div>{t('CCustomerSearch.絞り込み')}</div>
                            </CButton>
                            <div
                                className={clsx(
                                    'absolute',
                                    'bg-white',
                                    'p-2',
                                    'top-[42px]',
                                    'right-0',
                                    'md:right-20',
                                    'shadow',
                                    'rounded',
                                    !isOpenFilter && 'hidden',
                                    'min-w-[200px]',
                                    'z-50',
                                )}>
                                <CCheckBoxList
                                    items={[
                                        { label: t('CCustomerSearch.紹介されたことがある'), value: 'introduced' },
                                        { label: t('CCustomerSearch.紹介したことがある'), value: 'sending' },
                                    ]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as CustomerSearchFilterArrayType, ['introduced', 'sending'])
                                    }}
                                />
                                <hr />
                                <CCheckBoxList
                                    items={[{ label: t('CCustomerSearch.自分が担当の顧客'), value: 'assigned' }]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as CustomerSearchFilterArrayType, ['assigned'])
                                    }}
                                />
                                <hr />
                                <CCheckBoxList
                                    items={(() => {
                                        const commonOptions = [
                                            { label: t('CCustomerSearch.レコメンド対象外'), value: 'withoutRecommend' },
                                            { label: t('CCustomerSearch.レコメンド対象'), value: 'recommend' },
                                        ]
                                        return form.realEstate
                                            ? [
                                                  ...commonOptions,
                                                  { label: t('CCustomerSearch.この物件にマッチする'), value: 'matchRealEstate' },
                                                  { label: t('CCustomerSearch.この物件を紹介している'), value: 'nowSending' },
                                              ]
                                            : commonOptions
                                    })()}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as CustomerSearchFilterArrayType, [
                                            'withoutRecommend',
                                            'recommend',
                                            'matchRealEstate',
                                            'nowSending',
                                        ])
                                    }}
                                />
                                <hr />
                                <CCheckBoxList
                                    items={[
                                        { label: t('CCustomerSearch.購入条件未入力'), value: 'notPurchaseCondition' },
                                        { label: t('CCustomerSearch.購入条件入力済'), value: 'hasPurchaseCondition' },
                                    ]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as CustomerSearchFilterArrayType, [
                                            'notPurchaseCondition',
                                            'hasPurchaseCondition',
                                        ])
                                    }}
                                />
                                {!!form.realEstate && (
                                    <>
                                        <hr />
                                        <CCheckBoxList
                                            items={[
                                                { label: t('CCustomerSearch.資料開封済み'), value: 'openedDocument' },
                                                { label: t('CCustomerSearch.資料未開封'), value: 'notOpenDocument' },
                                            ]}
                                            checked={form.filter}
                                            onChange={(val) => {
                                                onChangeFilterCheckBox(val as CustomerSearchFilterArrayType, [
                                                    'openedDocument',
                                                    'notOpenDocument',
                                                ])
                                            }}
                                        />
                                    </>
                                )}
                                <CButton
                                    className={clsx('c-button-secondary', 'w-full', 'mb-1')}
                                    onClick={() => resetFilterCheckBox()}>
                                    {t('CCustomerSearch.絞り込みをリセット')}
                                </CButton>
                                <CButton className={clsx('c-button-primary', 'w-full')} onClick={() => onClickFilter()}>
                                    {t('CCustomerSearch.絞り込みを閉じる')}
                                </CButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('md:hidden', 'block', 'max-w-full')}>{filterTags()}</div>
                <CButton
                    className={clsx(
                        'c-button-primary',
                        'rounded-none',
                        isOpenDetail ? 'md:rounded-tr-xl' : ['rounded-b-xl', 'md:rounded-bl-none', 'md:rounded-r-xl'],
                    )}
                    onClick={() => onClickDetail()}>
                    <div
                        className={clsx(
                            'w-3',
                            'h-3',
                            isOpenDetail
                                ? ['border-r-2', 'border-t-2', 'translate-y-1']
                                : ['border-l-2', 'border-b-2', '-translate-y-1'],
                            'border-white',
                            '-rotate-45',
                            'm-2',
                            'transition',
                        )}
                    />
                    <div>{t('CCustomerSearch.詳細検索')}</div>
                </CButton>
            </div>
            <div
                className={clsx(
                    'w-full',
                    'p-2',
                    'font-body',
                    'border-b',
                    'border-l',
                    'border-r',
                    'border-kimar-primary',
                    'rounded-b-2xl',
                    'bg-white',
                    'bg-opacity-70',
                    'backdrop-blur',
                    !isOpenDetail && 'hidden',
                )}>
                <div className={clsx('')}>
                    <div className={clsx('grid', 'grid-cols-2', 'md:grid-cols-5', 'gap-4')}>
                        <CLabeledItem label={t('CCustomerSearch.購入条件（金額）')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={customerPriceRanges}
                                    checked={form.prices}
                                    onChange={(val) => onChangeDetailCheckBox('prices', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CCustomerSearch.購入条件（種別）')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={useTypes}
                                    dataLabel={'name'}
                                    dataValue={'uuid'}
                                    checked={form.useTypes}
                                    onChange={(val) => onChangeDetailCheckBox('useTypes', val as string[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CCustomerSearch.購入条件（エリア）')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={areaTypeItems as unknown as CCheckBoxItemInterface[]}
                                    checked={form.areas}
                                    onChange={(val) => onChangeDetailCheckBox('areas', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CCustomerSearch.購入条件（築年数）')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={customerBuildingAgeTypeItems as unknown as CCheckBoxItemInterface[]}
                                    checked={form.buildingAges}
                                    onChange={(val) => onChangeDetailCheckBox('buildingAges', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CCustomerSearch.購入条件（購入時期）')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={saleTimeTypeItems}
                                    checked={form.saleTimes}
                                    onChange={(val) => onChangeDetailCheckBox('saleTimes', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem
                            labelNode={
                                <div className={clsx('w-full', 'flex', 'justify-between')}>
                                    <div>{t('CCustomerSearch.担当者')}</div>
                                    <div className={clsx('flex', 'gap-1')}>
                                        <CToggleSwitch
                                            trueLabel={t('CCustomerSearch.AND')}
                                            falseLabel={t('CCustomerSearch.OR')}
                                            toggleChanged={(val) =>
                                                onChangeDetailAndOrToggle('assignedToType', val ? 'AND' : 'OR')
                                            }
                                            value={form.assignedToType === 'AND'}
                                        />
                                        <CGuideTips tooltipDirection="bottom-right">
                                            <b>{t('CCustomerSearch.AND')}</b>
                                            <br />
                                            {t('CCustomerSearch.すべての条件に一致')}
                                            <br />
                                            <b>{t('CCustomerSearch.OR')}</b>
                                            <br />
                                            {t('CCustomerSearch.いずれかの条件に一致')}
                                        </CGuideTips>
                                    </div>
                                </div>
                            }>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={[{ label: t('CCustomerSearch.担当者なし'), value: 'noAssigned' }]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as CustomerSearchFilterArrayType, ['noAssigned'])
                                    }}
                                />
                                <hr />
                                <CCheckBoxList
                                    items={teamMembers.map((m) => ({ label: m.name, value: m.uuid }))}
                                    checked={form.assignedToUuidList}
                                    onChange={(val) => onChangeDetailCheckBox('assignedToUuidList', val as string[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem
                            labelNode={
                                <div className={clsx('w-full', 'flex', 'justify-between')}>
                                    <div>{t('CCustomerSearch.顧客ラベル')}</div>
                                    <div className={clsx('flex', 'gap-1')}>
                                        <CToggleSwitch
                                            trueLabel={t('CCustomerSearch.AND')}
                                            falseLabel={t('CCustomerSearch.OR')}
                                            toggleChanged={(val) => onChangeDetailAndOrToggle('labelType', val ? 'AND' : 'OR')}
                                            value={form.labelType === 'AND'}
                                        />
                                        <CGuideTips tooltipDirection="bottom-left">
                                            <b>{t('CCustomerSearch.AND')}</b>
                                            <br />
                                            {t('CCustomerSearch.すべての条件に一致')}
                                            <br />
                                            <b>{t('CCustomerSearch.OR')}</b>
                                            <br />
                                            {t('CCustomerSearch.いずれかの条件に一致')}
                                        </CGuideTips>
                                    </div>
                                </div>
                            }>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={[{ label: t('CCustomerSearch.ラベルなし'), value: 'noLabels' }]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as CustomerSearchFilterArrayType, ['noLabels'])
                                    }}
                                />
                                <hr />
                                <CCheckBoxList
                                    items={sortBy(labels, (s) => s.sort).map((m) => ({
                                        label: m.name,
                                        value: m.uuid,
                                    }))}
                                    checked={form.labelUuidList}
                                    onChange={(val) => onChangeDetailCheckBox('labelUuidList', val as string[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CCustomerSearch.獲得経路')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={customerRegisteredTypeItems as unknown as CCheckBoxItemInterface[]}
                                    checked={form.registeredTypes}
                                    onChange={(val) => onChangeDetailCheckBox('registeredTypes', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-1', 'sm:grid-cols-2', 'gap-2', 'my-2')}>
                    <CButton className={clsx('c-button-secondary', 'w-full')} onClick={() => resetDetail()}>
                        {t('CCustomerSearch.詳細検索をリセット')}
                    </CButton>
                    <CButton className={clsx('c-button-primary', 'w-full')} onClick={() => onClickDetail()}>
                        {t('CCustomerSearch.詳細検索を閉じる')}
                    </CButton>
                </div>
            </div>
        </div>
    )
}

export type CustomerSearchFormValue = {
    name: string
    order: 'name' | 'company'
    filter: CustomerSearchFilterArrayType
    useTypes: string[]
    areas: string[]
    prices: string[]
    buildingAges: string[]
    saleTimes: string[]
    registeredTypes: string[]
    assignedToType: 'AND' | 'OR'
    assignedToUuidList: string[]
    labelType: 'AND' | 'OR'
    labelUuidList: string[]
    realEstate: string
}
const CustomerSearchFilterLangString = [
    { label: t('CCustomerSearch.紹介されたことがある'), value: 'introduced' },
    { label: t('CCustomerSearch.紹介したことがある'), value: 'sending' },
    { label: t('CCustomerSearch.自分が担当の顧客'), value: 'assigned' },
    { label: t('CCustomerSearch.レコメンド対象外'), value: 'withoutRecommend' },
    { label: t('CCustomerSearch.レコメンド対象'), value: 'recommend' },
    { label: t('CCustomerSearch.この物件にマッチする'), value: 'matchRealEstate' },
    { label: t('CCustomerSearch.この物件を紹介している'), value: 'nowSending' },
    { label: t('CCustomerSearch.資料開封済み'), value: 'openedDocument' },
    { label: t('CCustomerSearch.資料未開封'), value: 'notOpenDocument' },
    { label: t('CCustomerSearch.担当者なし'), value: 'noAssigned' },
    { label: t('CCustomerSearch.ラベルなし'), value: 'noLabels' },
    { label: t('CCustomerSearch.購入条件未入力'), value: 'notPurchaseCondition' },
    { label: t('CCustomerSearch.購入条件入力済'), value: 'hasPurchaseCondition' },
] as const
const CustomerSearchFilterStrings = CustomerSearchFilterLangString.map((v) => v.value)

export type CustomerSearchFilterArrayType = NonNullable<ClientCustomerCustomerApiArg['filter']>

export type CCustomerSearchProps = {
    realEstate?: RealEstateEntities
    onChange: (value: CustomerSearchFormValue) => void
}

export const CCustomerSearch: FC<CCustomerSearchProps> = ({ ...props }) => {
    const userTeam = useAppSelector(selectLoginUserTeam)
    const { data: teamMemberListCount } = useClientTeamMemberTeamUuidQuery(
        { teamUuid: userTeam?.uuid ?? '' },
        { skip: !userTeam },
    )
    const { data: useTypeListCount } = useClientRealEstateUseTypeRealEstateUseTypeQuery({})
    const { data: labelListCount } = useClientCustomerLabelTeamUuidQuery({ uuid: userTeam?.uuid ?? '' }, { skip: !userTeam })

    const [isOpenDetail, setIsOpenDetail] = useState(false)
    const [isOpenFilter, setIsOpenFilter] = useState(false)

    const onClickDetail = () => {
        setIsOpenDetail(!isOpenDetail)
        setIsOpenFilter(false)
    }
    const onClickFilter = () => {
        setIsOpenFilter(!isOpenFilter)
        setIsOpenDetail(false)
    }

    const closeAll = () => {
        setIsOpenDetail((v) => (v ? false : v))
        setIsOpenFilter((v) => (v ? false : v))
    }

    // 検索フォーム
    const [form, setForm] = useState<CustomerSearchFormValue>({
        name: '',
        order: 'name',
        filter: [],
        useTypes: [],
        areas: [],
        prices: [],
        buildingAges: [],
        registeredTypes: [],
        saleTimes: [],
        assignedToType: 'OR',
        assignedToUuidList: [],
        labelType: 'OR',
        labelUuidList: [],
        realEstate: props.realEstate ? props.realEstate.uuid : '',
    })

    const [name, setName] = useState('')
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const onChangeText = (val: string) => {
        setName(val)
        if (timer) clearTimeout(timer)
        const newTimer = setTimeout(() => {
            const newValue = {
                ...form,
                name: val,
            }
            setForm(newValue)
            props.onChange(newValue)
        }, 500)
        setTimer(newTimer)
    }

    const onChangeFilterCheckBox = (val: CustomerSearchFilterArrayType, target: CustomerSearchFilterArrayType) => {
        const newFilter = form.filter.filter((f) => !target.includes(f)).concat(val.filter((f) => target.includes(f)))
        const newValue = {
            ...form,
            filter: newFilter,
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    const resetFilterCheckBox = () => {
        const newValue = {
            ...form,
            filter: [],
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    const onChangeDetailCheckBox = (key: keyof CustomerSearchFormValue, val: string[] | number[]) => {
        const newValue = {
            ...form,
            [key]: val,
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    const onChangeDetailAndOrToggle = (key: keyof CustomerSearchFormValue, val: 'AND' | 'OR') => {
        const newValue = {
            ...form,
            [key]: val,
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    const resetDetail = () => {
        const newValue = {
            name: '',
            order: 'name' as const,
            useTypes: [],
            areas: [],
            prices: [],
            buildingAges: [],
            registeredTypes: [],
            saleTimes: [],
            assignedToType: 'OR' as const,
            assignedToUuidList: [],
            labelType: 'OR' as const,
            labelUuidList: [],
            realEstate: props.realEstate ? props.realEstate.uuid : '',
            filter: form.filter.filter((v) => v !== 'noAssigned' && v !== 'noLabels'),
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    if (!teamMemberListCount || !useTypeListCount || !labelListCount) return null

    return (
        <CCustomerSearchView
            {...props}
            isOpenDetail={isOpenDetail}
            onClickDetail={onClickDetail}
            isOpenFilter={isOpenFilter}
            onClickFilter={onClickFilter}
            onChangeText={onChangeText}
            form={form}
            name={name}
            onChangeFilterCheckBox={onChangeFilterCheckBox}
            resetFilterCheckBox={resetFilterCheckBox}
            onChangeDetailCheckBox={onChangeDetailCheckBox}
            onChangeDetailAndOrToggle={onChangeDetailAndOrToggle}
            resetDetail={resetDetail}
            useTypes={useTypeListCount.list as RealEstateUseTypeEntities[]}
            labels={labelListCount.list as CustomerLabelEntities[]}
            teamMembers={teamMemberListCount.list as TeamMemberEntities[]}
            closeAll={closeAll}
        />
    )
}
