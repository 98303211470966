import clsx from 'clsx'
import { CSSProperties, ReactNode, VFC } from 'react'

import { CTag } from '../cTag/CTag'

export type CTagListViewProps = {} & CTagListProps

export const CTagListView: VFC<CTagListViewProps> = ({ items, horizontal, noWrap }) => {
    return (
        <div className={clsx('flex', 'gap-1', horizontal ? 'flex-wrap' : 'flex-col', noWrap && 'flex-none')}>
            {items.map((item, index) => {
                return (
                    <div key={index} className={clsx('flex', 'items-center')}>
                        <CTag className={clsx('px-1', 'rounded', item.className)} style={item.style}>
                            {item.children}
                        </CTag>
                    </div>
                )
            })}
        </div>
    )
}

export type CTagListProps = {
    items: { children: ReactNode; className?: string; style?: CSSProperties }[]
    children?: ReactNode
    horizontal?: boolean
    noWrap?: boolean
}

export const CTagList: VFC<CTagListProps> = (props) => {
    return <CTagListView {...props} />
}
