import {
    createSlice,
    isFulfilled,
    isPending,
    isRejectedWithValue,
    Middleware,
    MiddlewareAPI,
    PayloadAction,
} from '@reduxjs/toolkit'
import { isNil } from 'lodash'

import type { RootState } from './store'

const slice = createSlice({
    name: 'UI',
    initialState: { disabledLoading: false, loadingRequestIds: [] } as {
        disabledLoading: boolean
        loadingRequestIds: string[]
    },
    reducers: {
        setDisabledLoading: (state, { payload: isDisabled }: PayloadAction<boolean>) => ({
            ...state,
            disabledLoading: isDisabled,
        }),
        setLoadingRequestId: (state, { payload: requestId }: PayloadAction<string>) => ({
            ...state,
            loadingRequestIds: [...state.loadingRequestIds, requestId],
        }),
        unSetLoadingRequestId: (state, { payload: requestId }: PayloadAction<string>) => ({
            ...state,
            loadingRequestIds: state.loadingRequestIds.filter((id) => id !== requestId),
        }),
    },
})

// sliceからactionを切り出す
export const { setDisabledLoading, setLoadingRequestId, unSetLoadingRequestId } = slice.actions

// ログインユーザー
export const selectLoadingRequestIds = (state: RootState) => state.UIReducer.loadingRequestIds
export const isLoading = (state: RootState) => !!state.UIReducer.loadingRequestIds.length

// sliceからreducerを切り出す
export default slice.reducer

/**
 * [Redux] Handling Request - Show loading
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const rtkQueryLoading: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isNil(action?.meta?.requestId)) return next(action)

    const unShowLoadingName = ['clientRealEstateHasNoReadTeamActivity']
    if (unShowLoadingName.includes(action?.meta?.arg.endpointName)) return next(action)

    if (!(api.getState() as RootState).UIReducer.disabledLoading && isPending(action))
        api.dispatch(slice.actions.setLoadingRequestId(action.meta.requestId))

    if (isFulfilled(action) || isRejectedWithValue(action))
        api.dispatch(slice.actions.unSetLoadingRequestId(action.meta.requestId))

    return next(action)
}
