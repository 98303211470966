import 'cropperjs/dist/cropper.css'

import clsx from 'clsx'
import { t } from 'i18next'
import React, { ChangeEvent, useRef, useState, VFC } from 'react'
import Cropper from 'react-cropper'
import { toast } from 'react-hot-toast/headless'

import { FileEntities, useClientFileGetPreSignedUrlMutation, useClientFileSetFileSizeMutation } from '~/app/api'

import { CButton } from '../cButton/CButton'
import { CFooterModal } from '../cModal/CModal'

export type CFileUploadViewProps = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    onDrop: (event: React.DragEvent) => void
    cropVisible: boolean
    closeCrop: () => void
    cropSource?: string
    cropConfirm: (dataUrl?: string) => void
} & CFileUploadProps

export const CFileUploadView: VFC<CFileUploadViewProps> = ({
    uploadLabel = t('CFileUploadView.ファイル'),
    accept,
    multipleMode,
    cropVisible,
    cropSource,
    aspectRatio = 1,
    onChange,
    onDrop,
    closeCrop,
    cropConfirm,
}) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [isHovering, setIsHovering] = useState(false)
    const dragoverHandler = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
        setIsHovering(true)
    }
    const dragleaveHandler = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
        setIsHovering(false)
    }
    const dropHandler = (e: React.DragEvent) => {
        e.stopPropagation()
        e.preventDefault()
        setIsHovering(false)
        onDrop(e)
    }

    const onClickFileUploadButton = () => {
        if (inputRef.current instanceof HTMLInputElement) inputRef.current.click()
    }
    const [cropper, setCropper] = useState<Cropper>()

    return (
        <>
            <div
                className={clsx(
                    'w-full',
                    'p-2',
                    'flex',
                    'space-y-2',
                    'justify-center',
                    'items-center',
                    'flex-col',
                    'rounded',
                    'border',
                    'border-dashed',
                    isHovering ? 'bg-gray-100' : 'bg-gray-50',
                    'font-body',
                )}
                onDragOver={dragoverHandler}
                onDragLeave={dragleaveHandler}
                onDrop={dropHandler}>
                <p className={clsx('text-center', 'text-sm')}>
                    {t('CFileUpload.アップロードするファイルをドラッグ')}
                    <br />
                    {t('CFileUpload.または')}
                </p>
                <input hidden ref={inputRef} type="file" accept={accept} multiple={multipleMode} onChange={onChange} />
                <CButton className={clsx('c-button-primary', 'text-sm')} onClick={() => onClickFileUploadButton()}>
                    <i className={clsx('material-icons-outlined')}>cloud_upload</i>
                    <span>{t('CFileUpload.uploadLabelをアップロード', { uploadLabel })}</span>
                </CButton>
            </div>
            <CFooterModal
                footer={
                    <>
                        <CButton className={clsx('c-button-secondary')} onClick={() => closeCrop()}>
                            {t('Button.キャンセル')}
                        </CButton>
                    </>
                }
                visible={cropVisible}
                content={{ width: '95%' }}
                onRequestClose={closeCrop}>
                <div className={clsx('flex', 'flex-col', 'gap-4', 'p-4')}>
                    <div className={clsx('text-kimar-primary', 'font-bold', 'text-center')}>{t('CFileUpload.トリミング')}</div>
                    <div className={clsx('text-center', 'm-2')}>
                        {t('CFileUpload.アップロードする範囲を選択してください。（スクロールでズームイン・アウト）')}
                    </div>
                    <div
                        className={clsx(
                            'flex',
                            'justify-center',
                            'w-full',
                            'md:flex-row',
                            'flex-col',
                            'items-center',
                            'md:items-start',
                        )}>
                        <Cropper
                            zoomTo={0.2}
                            initialAspectRatio={1}
                            preview={'#previewFileUploadImage'}
                            src={cropSource}
                            viewMode={0}
                            minCropBoxHeight={300}
                            minCropBoxWidth={300}
                            background={true}
                            autoCropArea={1}
                            guides={true}
                            dragMode={'crop'}
                            rotatable={false}
                            aspectRatio={aspectRatio}
                            alt={'image'}
                            onInitialized={(instance) => {
                                setCropper(instance)
                            }}
                        />
                        <div className={clsx('flex', 'flex-col', 'gap-2', 'inline-block', 'md:pl-4', 'box-border')}>
                            <div>{t('CFileUpload.プレビュー')}</div>
                            <div
                                id="previewFileUploadImage"
                                className={clsx(
                                    'overflow-hidden',
                                    'w-[360px]',
                                    'h-[120px]',
                                    'border',
                                    'border-dashed',
                                    'border-black',
                                )}
                            />
                            <CButton
                                className={clsx('c-button-primary', 'w-full')}
                                onClick={() => cropConfirm(cropper?.getCroppedCanvas().toDataURL('image/png'))}>
                                {t('CFileUpload.切り抜き')}
                            </CButton>
                        </div>
                    </div>
                </div>
            </CFooterModal>
        </>
    )
}

type CFileUploadBaseProps = {
    uploadLabel?: string
    accept?: string
    preFileUpload?: () => void
    finishFileUpload?: () => void
    errorFileUpload?: () => void
    fileUploaded: (files: FileEntities[]) => void
}
type CFileUploadCropProps = {
    multipleMode?: undefined
    aspectRatio?: number
    isCrop?: boolean
}
type CFileUploadMultipleModeProps = {
    multipleMode?: boolean
    aspectRatio?: undefined
    isCrop?: undefined
}
export type CFileUploadProps = CFileUploadBaseProps & (CFileUploadCropProps | CFileUploadMultipleModeProps)

export const CFileUpload: VFC<CFileUploadProps> = ({ ...props }) => {
    const [cropVisible, setCropVisible] = useState(false)
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files ?? [])
        uploadFileInitializer(files)
        e.target.value = ''
    }
    const onDrop = (e: React.DragEvent) => {
        const files = Array.from(e.dataTransfer.files)
        uploadFileInitializer(files)
    }
    const uploadFileInitializer = (files: File[]) => {
        if (!files?.length) return
        if (!props.multipleMode && files.length > 1) {
            toast.error(
                t('CFileUpload.複数のファイルを同時にアップロードすることはできません。ファイルを1つだけ選択してください。'),
            )
            return
        }
        if (files.find((f) => f.size === 0)) toast.error(t('CFileUpload.選択したファイルにはデータがありません'))
        if (props.isCrop) uploadFileToCropper(files)
        else uploadFile(files)
    }

    const [cropSource, setCropSource] = useState<string | undefined>()
    const uploadFileToCropper = (files: File[]) => {
        const file = files[0]
        if (!file.type.includes('image/')) {
            // 選択されたものが画像ではなかった場合の処理
            toast.error(t('CFileUpload.画像ファイルを選択してください'))
            return
        }
        if (typeof FileReader === 'function') {
            const reader = new FileReader()
            reader.onload = (event) => {
                if (event.target && event.target.result) setCropSource(event.target.result.toString())
            }
            reader.readAsDataURL(file)
        } else toast.error(t('CFileUpload.お使いのブラウザでは FileReader API をサポートしていません。'))
        // トリミングモーダル表示
        setCropVisible(true)
    }

    const closeCrop = () => {
        setCropVisible(false)
    }

    const cropConfirm = async (dataUrl?: string) => {
        if (!dataUrl) return
        // base64のデコード
        const bin = window.atob(dataUrl.replace(/^.*,/, ''))
        // バイナリデータ化
        const buffer = new Uint8Array(bin.length)
        for (let i = 0; i < bin.length; i++) buffer[i] = bin.charCodeAt(i)
        // バイナリデータ化
        const file = new File([buffer.buffer], 'logo.png', { type: 'image/png' })
        await uploadFile([file])
    }

    const [fileUploadUrlQuery] = useClientFileGetPreSignedUrlMutation()
    const [setFileSizeQuery] = useClientFileSetFileSizeMutation()
    const uploadFile = async (files: File[]) => {
        if (props.preFileUpload) props.preFileUpload()
        try {
            const entities = [] as FileEntities[]
            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                const response = await fileUploadUrlQuery({
                    mediaFileUploadDto: {
                        originalFilename: file.name,
                        mimetype: files[i].type,
                    },
                }).unwrap()

                const requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': files[i].type },
                    body: new Blob([await files[i].arrayBuffer()]),
                }
                //Perform the upload
                await fetch(response.url, requestOptions)

                await setFileSizeQuery({
                    mediaFileUploadSetSizeDto: {
                        uuid: response.fileEntity.uuid,
                    },
                }).unwrap()

                entities.push(response.fileEntity)
            }

            props.fileUploaded(entities)
            setCropVisible(false)
        } catch {
            toast.error(t('CFileUpload.ファイルアップロードに失敗しました'))
            if (props.errorFileUpload) props.errorFileUpload()
        } finally {
            if (props.finishFileUpload) props.finishFileUpload()
        }
    }

    return (
        <CFileUploadView
            {...props}
            onChange={onChange}
            onDrop={onDrop}
            closeCrop={closeCrop}
            cropVisible={cropVisible}
            cropSource={cropSource}
            cropConfirm={cropConfirm}
        />
    )
}
