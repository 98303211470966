import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useMemo, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import { AdminTeamInsertDto, CompanyEntities, TeamEntities, useAdminTeamPostTeamMutation } from '~/app/api'
import { CError } from '~/components/common/cError/CError'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { ApplicationError, IApplicationError } from '~/types/error'

export type InsertTeamModalViewProps = {
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (key: string, value: unknown) => void
    dto: AdminTeamInsertDto
    disableSubmit: boolean
    errors: IApplicationError[]
} & InsertTeamModalProps

export const InsertTeamModalView: VFC<InsertTeamModalViewProps> = ({
    isShow,
    parentTeam,
    onRequestConfirm,
    onRequestClose,
    setProps,
    dto,
    disableSubmit,
    errors,
}) => {
    return (
        <CConfirmModal
            title={t('InsertTeamModal.チームを追加')}
            visible={!!isShow}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            disableSubmit={disableSubmit}>
            <div>
                <CError errors={errors} />
                <div className={clsx('flex', 'flex-col', 'space-y-2')}>
                    {parentTeam && (
                        <CMessage info>
                            {t('InsertTeamModal.親チーム')}: {parentTeam.name}
                        </CMessage>
                    )}
                    <CLabeledItem label={t('InsertTeamModal.チーム名')} required>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={t('InsertTeamModal.チーム名')}
                            text={dto.name}
                            onChange={(val) => setProps('name', val)}
                        />
                    </CLabeledItem>
                </div>
            </div>
        </CConfirmModal>
    )
}

export type InsertTeamModalProps = {
    isShow: boolean
    company: CompanyEntities
    parentTeam?: TeamEntities
    onClose: () => void
    onSubmit: (team: TeamEntities) => void
}

export const InsertTeamModal: VFC<InsertTeamModalProps> = ({ ...props }) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const onRequestClose = () => {
        setDto(createNewDto())
        props.onClose()
    }

    const [insertTeamQuery] = useAdminTeamPostTeamMutation()
    const onRequestConfirm = async () => {
        try {
            const team = await insertTeamQuery({ adminTeamInsertDto: dto }).unwrap()
            props.onSubmit(team)
            toast.success(t('InsertTeamModal.チームを追加しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const createNewDto = () => ({
        name: '',
        company: props.company,
        parentTeam: props.parentTeam,
    })

    useEffect(() => {
        setDto({
            ...dto,
            parentTeam: props.parentTeam,
        })
    }, [props.parentTeam])

    const [dto, setDto] = useState<AdminTeamInsertDto>(createNewDto())

    const setProps = (key: string, value: unknown) => {
        setDto({
            ...dto,
            [key]: value,
        })
    }

    const disableSubmit = useMemo(() => {
        return !dto.name
    }, [dto])

    return (
        <InsertTeamModalView
            {...props}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            dto={dto}
            setProps={setProps}
            disableSubmit={disableSubmit}
            errors={errors}
        />
    )
}
