import clsx from 'clsx'
import React, { VFC } from 'react'

export type CCheckBoxViewProps = {} & CCheckBoxProps

export const CCheckBoxView: VFC<CCheckBoxViewProps> = ({
    disabled,
    label,
    value,
    checked,
    onChange,
    className,
    color,
    attention,
}) => {
    return (
        <div className={clsx('my-0.5', 'py-0.5', 'rounded', checked && label && !disabled && 'bg-kimar-primary-light')}>
            <label className={clsx(className, 'flex', 'items-center')}>
                <input
                    className={clsx(
                        'rounded-sm',
                        disabled
                            ? ['text-gray-300', 'border-gray-300']
                            : color === 'market'
                            ? ['text-kimar-market', 'focus:ring-kimar-market', 'border-kimar-market', 'cursor-pointer']
                            : ['text-kimar-primary', 'focus:ring-kimar-primary', 'border-kimar-primary', 'cursor-pointer'],
                    )}
                    type="checkbox"
                    value={value}
                    disabled={disabled}
                    checked={checked}
                    onChange={(e) => onChange && onChange(e)}
                />
                <span className={clsx('text-sm', label && 'ml-2', !disabled && 'cursor-pointer')}>{label}</span>
                {attention && <div className={clsx('w-2', 'h-2', 'rounded-full', 'bg-red-700', '-translate-y-1')} />}
            </label>
        </div>
    )
}

export type CCheckBoxReadOnlyViewProps = {} & CCheckBoxProps

export const CCheckBoxReadOnlyView: VFC<CCheckBoxReadOnlyViewProps> = ({ className, label, checked, size, color }) => {
    const textClass =
        size === 'xs'
            ? {
                  icon: 'text-[14px]',
                  label: 'text-xs',
                  margin: 'ml-1',
              }
            : {
                  icon: 'text-[18px]',
                  label: 'text-sm',
                  margin: 'ml-1',
              }

    const iconColor = {
        market: 'bg-kimar-market',
        primary: 'bg-kimar-primary',
        accent: 'bg-kimar-accent',
    }

    const textColor = {
        market: 'text-kimar-market',
        primary: 'text-kimar-primary',
        accent: 'text-kimar-accent',
    }

    const borderColor = {
        market: 'border-kimar-market',
        primary: 'border-kimar-primary',
        accent: 'border-kimar-accent',
    }

    return (
        <div className={clsx(className, 'rounded')}>
            <label className={clsx('flex', 'items-center')}>
                {checked ? (
                    <i
                        className={clsx(
                            'material-icons',
                            'text-white',
                            'rounded-full',
                            'border',
                            iconColor[color ?? 'primary'] ?? 'bg-kimar-primary',
                            borderColor[color ?? 'primary'] ?? 'border-kimar-primary',
                            textClass.icon,
                        )}>
                        check
                    </i>
                ) : (
                    <i
                        className={clsx(
                            'material-icons',
                            'rounded-full',
                            'border',
                            textColor[color ?? 'primary'] ?? 'text-kimar-primary',
                            borderColor[color ?? 'primary'] ?? 'border-kimar-primary',
                            textClass.icon,
                        )}>
                        horizontal_rule
                    </i>
                )}
                {label && <span className={clsx(textClass.label, textClass.margin)}>{label}</span>}
            </label>
        </div>
    )
}

export type CCheckBoxProps = {
    size?: 'xs'
    color?: 'market' | 'primary' | 'accent'
    className?: string
    // 無効状態か否か（編集できるようになる場合はこちら）
    disabled?: boolean
    // 読み取り専用かどうか（編集を想定しない場合はこちら）
    readonly?: boolean
    label?: string
    value?: string | number | readonly string[]
    // 選択有無の初期状態
    checked?: boolean
    // 通知赤丸つけるかどうか
    attention?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const CCheckBox: VFC<CCheckBoxProps> = ({ ...props }) => {
    if (props.readonly) return <CCheckBoxReadOnlyView {...props} />
    return <CCheckBoxView {...props} />
}
