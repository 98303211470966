import { TeamEntities } from '~/app/api'

export class TeamTreeNode {
    public model: TeamEntities
    public children: TeamTreeNode[] = []

    constructor(model: TeamEntities) {
        this.model = model
    }
}
export const createTeamTree = (teams: TeamEntities[]) => {
    const buildTree = (models: TeamEntities[], parentId?: string) => {
        const children = models.filter((model) => model.parentTeam?.uuid === parentId)
        return children.map((child) => {
            const node = new TeamTreeNode(child)
            node.children = buildTree(models, child.uuid)
            return node
        })
    }

    return buildTree(teams)
}

export const createTeamTreeWithChildren = (team: TeamEntities) => {
    const buildTree = (model: TeamEntities) => {
        const children = model.childTeams
        return (
            children?.map((child) => {
                const node = new TeamTreeNode(child)
                node.children = buildTree(child)
                return node
            }) ?? []
        )
    }

    const node = new TeamTreeNode(team)
    node.children = buildTree(team)
    return node
}
