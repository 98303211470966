import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { clsx } from 'clsx'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast/headless'

import {
    CompanyEntities,
    FileEntities,
    TeamEntities,
    useClientCompanyDeleteUuidBandMutation,
    useClientCompanyDeleteUuidLogoMutation,
    useClientCompanyPutUuidBandMutation,
    useClientCompanyPutUuidLogoMutation,
    useClientCompanyPutUuidMutation,
    useClientTeamPutUuidMutation,
    useClientTeamUuidQuery,
} from '~/app/api'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { usePrevious } from '~/util/customHooks/common'
import { getSignedUrl } from '~/util/model/FileEntities'
import {
    selectLoginUser,
    selectLoginUserCompany,
    selectLoginUserTeam,
    setLoginUserCompany,
    setLoginUserTeam,
} from '~/util/store/userSlice'

import { IApplicationError } from '../../../types/error'
import { useAppDispatch, useAppSelector } from '../../../util/store/hooks'
import { CButton } from '../../common/cButton/CButton'
import { CError } from '../../common/cError/CError'
import { CLabeledItem } from '../../common/cLabeledItem/CLabeledItem'
import { CTextInput } from '../../common/cTextInput/CTextInput'
import { CompanyLogo } from '../company/CompanyLogo'
import { CUserMyPageListInformation } from './CUserMypageTeamListInformation'
import { CUserMyPageTeamMember } from './CUserMypageTeamMember'

const CUserMypageTeam = () => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectLoginUser)
    const _userTeam = useAppSelector(selectLoginUserTeam)
    const userCompany = useAppSelector(selectLoginUserCompany)

    const { data: userTeam } = useClientTeamUuidQuery({ uuid: _userTeam!.uuid, withChild: true }, { skip: !_userTeam })

    const [teamErrors, setTeamErrors] = useState<IApplicationError[]>([])
    const [companyErrors, setCompanyErrors] = useState<IApplicationError[]>([])
    const [logoErrors, setLogoErrors] = useState<IApplicationError[]>([])
    const [bandErrors, setBandErrors] = useState<IApplicationError[]>([])

    // チーム基本情報用モデル
    type TeamModelType = 'name' | 'overview'
    const [teamModel, setTeamModel] = useState<Pick<TeamEntities, TeamModelType> | undefined>(_userTeam)
    type CompanyModelType = 'name' | 'sound' | 'address' | 'phone' | 'fax'
    const [companyModel, setCompanyModel] = useState<Pick<CompanyEntities, CompanyModelType> | undefined>(userCompany)

    // ロゴを設定するかどうか
    const [isSetLogo, setIsSetLogo] = useState(false)
    // ロゴ設定トグルの1つ前の状態
    const prevSetLogo = usePrevious(isSetLogo)
    // ロゴ設定をOFFにした時にロゴが設定されていた場合の確認ログ
    const [isShowResetLogo, setIsShowResetLogo] = useState(false)
    useEffect(() => {
        setIsSetLogo(!!userCompany?.logoFile)
    }, [userCompany])
    useEffect(() => {
        if (prevSetLogo && !isSetLogo && !!userCompany?.logoFile) setIsShowResetLogo(true)
    }, [isSetLogo])
    const [companyLogoDelete, { isSuccess: isCompanyLogoDeleteSuccess }] = useClientCompanyDeleteUuidLogoMutation()
    const removeCompanyLogo = async () => {
        if (!userCompany || !companyModel) return
        try {
            const company = await companyLogoDelete({ uuid: userCompany.uuid }).unwrap()
            dispatch(setLoginUserCompany(company))
            setIsShowResetLogo(false)
            toast.success(t('CUserMypageTeam.ロゴを削除しました'))
        } catch (e) {
            setLogoErrors([
                {
                    ...((e as FetchBaseQueryError).data as IApplicationError),
                    message: t('CUserMypageTeam.ロゴの削除に失敗しました'),
                },
            ])
        }
    }

    // チーム情報の更新
    const [teamSave, { isSuccess: isTeamSaveSuccess }] = useClientTeamPutUuidMutation()
    const teamSaveHandler = async () => {
        if (!userTeam || !teamModel) return
        try {
            const team = await teamSave({ uuid: userTeam.uuid, teamUpdateDto: { ...teamModel } }).unwrap()
            dispatch(setLoginUserTeam(team))
            toast.success(t('CUserMypageTeam.チーム情報を編集しました'))
        } catch (e) {
            setTeamErrors([
                {
                    ...((e as FetchBaseQueryError).data as IApplicationError),
                    message: t('CUserMypageTeam.チーム基本情報の更新に失敗しました'),
                },
            ])
        }
    }

    // 会社情報の更新
    const [companySave, { isSuccess: isCompanySaveSuccess }] = useClientCompanyPutUuidMutation()
    const companySaveHandler = async () => {
        if (!userCompany || !companyModel) return
        try {
            const company = await companySave({ uuid: userCompany.uuid, companyUpdateDto: { ...companyModel } }).unwrap()
            dispatch(setLoginUserCompany(company))
            toast.success(t('CUserMypageTeam.会社情報を編集しました'))
        } catch (e) {
            setCompanyErrors([
                {
                    ...((e as FetchBaseQueryError).data as IApplicationError),
                    message: t('CUserMypageTeam.会社基本情報の更新に失敗しました'),
                },
            ])
        }
    }

    const inputTeamChangeHandler = (target: TeamModelType, value: string) => {
        setTeamModel((oldProfile) => (oldProfile ? { ...oldProfile, [target]: value } : undefined))
    }

    const inputCompanyChangeHandler = (target: CompanyModelType, value: string) => {
        setCompanyModel((oldProfile) => (oldProfile ? { ...oldProfile, [target]: value } : undefined))
    }

    const [companyLogoSave, { isSuccess: isCompanyLogoSaveSuccess }] = useClientCompanyPutUuidLogoMutation()
    const logoFileUploaded = async (file?: FileEntities) => {
        if (!file || !userCompany || !companyModel) return
        try {
            const company = await companyLogoSave({ uuid: userCompany.uuid, companyUpdateLogoDto: { logo: file } }).unwrap()
            dispatch(setLoginUserCompany(company))
            toast.success(t('CUserMypageTeam.自社ロゴを登録しました'))
        } catch (e) {
            setLogoErrors([
                {
                    ...((e as FetchBaseQueryError).data as IApplicationError),
                    message: t('CUserMypageTeam.自社ロゴの登録に失敗しました'),
                },
            ])
        }
    }

    // 帯を設定するかどうか
    const [isSetBand, setIsSetBand] = useState(false)
    // 帯設定トグルの1つ前の状態
    const prevSetBand = usePrevious(isSetBand)
    // 帯設定をOFFにした時にロゴが設定されていた場合の確認ログ
    const [isShowResetBand, setIsShowResetBand] = useState(false)
    useEffect(() => {
        setIsSetBand(!!userCompany?.bandImage)
    }, [userCompany])
    useEffect(() => {
        if (prevSetBand && !isSetBand && !!userCompany?.bandImage) setIsShowResetBand(true)
    }, [isSetBand])
    const [companyBandDelete, { isSuccess: isCompanyBandDeleteSuccess }] = useClientCompanyDeleteUuidBandMutation()
    const removeCompanyBand = async () => {
        if (!userCompany || !companyModel) return
        try {
            const company = await companyBandDelete({ uuid: userCompany.uuid }).unwrap()
            dispatch(setLoginUserCompany(company))
            setIsShowResetBand(false)
            toast.success(t('CUserMypageTeam.帯を削除しました'))
        } catch (e) {
            setBandErrors([
                {
                    ...((e as FetchBaseQueryError).data as IApplicationError),
                    message: t('CUserMypageTeam.帯の削除に失敗しました'),
                },
            ])
        }
    }
    const [companyBandSave, { isSuccess: isCompanyBandSaveSuccess }] = useClientCompanyPutUuidBandMutation()
    const bandFileUploaded = async (file?: FileEntities) => {
        if (!file || !userCompany || !companyModel) return
        try {
            const company = await companyBandSave({
                uuid: userCompany.uuid,
                companyUpdateRealEstateDetailBandDto: {
                    bandImage: file,
                },
            }).unwrap()
            dispatch(setLoginUserCompany(company))
            toast.success(t('CUserMypageTeam.自社帯を登録しました'))
        } catch (e) {
            setBandErrors([
                {
                    ...((e as FetchBaseQueryError).data as IApplicationError),
                    message: t('CUserMypageTeam.自社帯の登録に失敗しました'),
                },
            ])
        }
    }

    const [selectedTab, setSelectedTab] = useState('company')
    const tabs = useMemo(
        () =>
            user?.roles.company === 0
                ? [
                      { label: t('CUserMypageTeam.チーム情報'), value: 'team' },
                      { label: t('CUserMypageTeam.メンバー'), value: 'member' },
                  ]
                : [
                      { label: t('CUserMypageTeam.会社情報'), value: 'company' },
                      { label: t('CUserMypageTeam.チーム情報'), value: 'team' },
                      { label: t('CUserMypageTeam.メンバー'), value: 'member' },
                      { label: t('CUserMypageTeam.一覧項目'), value: 'listInformation' },
                  ],
        [],
    )

    return (
        <>
            <div className={clsx('w-full', 'md:max-w-lg')}>
                <CSegmentedControl items={tabs} onClick={(val) => setSelectedTab(val as string)} selectedTabValue={selectedTab} />
            </div>
            {selectedTab === 'listInformation' && <CUserMyPageListInformation />}
            {selectedTab === 'company' && companyModel && (
                <>
                    <div className={clsx('flex', 'flex-col', 'space-y-4', 'max-w-xl', 'w-full')}>
                        {isCompanySaveSuccess && (
                            <div className={clsx('bg-green-50', 'text-green-900', 'p-4', 'rounded')}>
                                {t('CUserMypageTeam.会社基本情報を変更しました')}
                            </div>
                        )}
                        <CError errors={companyErrors} />
                        <div className={clsx('grid', 'grid-cols-2', 'gap-2')}>
                            <CLabeledItem label={t('CUserMypageTeam.会社名')} required={user?.roles.company === 2}>
                                {user?.roles.company === 1 && (
                                    <div className={clsx('m-2', 'font-semibold')}>{companyModel.name}</div>
                                )}
                                {user?.roles.company === 2 && (
                                    <CTextInput
                                        text={companyModel.name}
                                        onChange={(value) => inputCompanyChangeHandler('name', value)}
                                        type={'text'}
                                        className={clsx('w-full', 'c-text-input-base')}
                                    />
                                )}
                            </CLabeledItem>
                            <CLabeledItem label={t('CUserMypageTeam.会社名(かな)')} required={user?.roles.company === 2}>
                                {user?.roles.company === 1 && (
                                    <div className={clsx('m-2', 'font-semibold')}>{companyModel.sound}</div>
                                )}
                                {user?.roles.company === 2 && (
                                    <CTextInput
                                        type={'text'}
                                        text={companyModel.sound}
                                        onChange={(value) => inputCompanyChangeHandler('sound', value)}
                                        className={clsx('w-full', 'c-text-input-base')}
                                    />
                                )}
                            </CLabeledItem>
                        </div>
                        <div className={clsx('grid', 'grid-cols-2', 'gap-2')}>
                            <CLabeledItem label={t('CUserMypageTeam.電話番号')}>
                                {user?.roles.company === 1 && (
                                    <div className={clsx('m-2', 'font-semibold')}>{companyModel.phone}</div>
                                )}
                                {user?.roles.company === 2 && (
                                    <CTextInput
                                        type={'text'}
                                        text={companyModel.phone ?? ''}
                                        onChange={(value) => inputCompanyChangeHandler('phone', value)}
                                        className={clsx('w-full', 'c-text-input-base')}
                                    />
                                )}
                            </CLabeledItem>
                            <CLabeledItem label={t('CUserMypageTeam.FAX番号')}>
                                {user?.roles.company === 1 && (
                                    <div className={clsx('m-2', 'font-semibold')}>{companyModel.fax}</div>
                                )}
                                {user?.roles.company === 2 && (
                                    <CTextInput
                                        type={'text'}
                                        text={companyModel.fax ?? ''}
                                        onChange={(value) => inputCompanyChangeHandler('fax', value)}
                                        className={clsx('w-full', 'c-text-input-base')}
                                    />
                                )}
                            </CLabeledItem>
                        </div>
                        <CLabeledItem label={t('CUserMypageTeam.住所')}>
                            {user?.roles.company === 1 && (
                                <div className={clsx('m-2', 'font-semibold')}>{companyModel.address}</div>
                            )}
                            {user?.roles.company === 2 && (
                                <CTextInput
                                    type={'text'}
                                    text={companyModel.address ?? ''}
                                    onChange={(value) => inputCompanyChangeHandler('address', value)}
                                    className={clsx('w-full', 'c-text-input-base')}
                                />
                            )}
                        </CLabeledItem>

                        {user?.roles.company === 2 && (
                            <CButton
                                className={clsx('c-button-primary', 'w-full')}
                                disabled={!companyModel.name || !companyModel.sound}
                                onClick={companySaveHandler}>
                                {t('CUserMypageTeam.設定確定')}
                            </CButton>
                        )}
                        {user?.roles.company === 2 && (
                            <>
                                <CHeader label={t('CUserMypageTeam.自社ロゴ')} />
                                <CMessage info>
                                    {t('CUserMypageTeam.自社ロゴを設定すると、物件を紹介したときに相手に表示されます。')}
                                    <br />
                                    {t(
                                        'CUserMypageTeam.また、社内のメンバー全員のヘッダーロゴ（ページ上部のキマールの画像）が自社ロゴに変更されます。',
                                    )}
                                </CMessage>
                                <CLabeledItem label={t('CUserMypageTeam.自社ロゴを設定する')} horizontal>
                                    <CToggleSwitch
                                        width="base"
                                        value={isSetLogo}
                                        trueLabel={'YES'}
                                        falseLabel={'NO'}
                                        toggleChanged={(value) => {
                                            setIsSetLogo(value)
                                        }}
                                    />
                                </CLabeledItem>
                                <CError errors={logoErrors} />
                                {(isCompanyLogoSaveSuccess || isCompanyLogoDeleteSuccess) && (
                                    <div className={clsx('bg-green-50', 'text-green-900', 'p-4', 'rounded')}>
                                        {t('CUserMypageTeam.自社ロゴを変更しました')}
                                    </div>
                                )}
                                {isSetLogo && (
                                    <div className={clsx('flex', 'flex-col', 'space-y-2')}>
                                        <p>
                                            {userCompany?.logoFile ? (
                                                <CompanyLogo company={userCompany} />
                                            ) : (
                                                <span>{t('CUserMypageTeam.ロゴは未設定です')}</span>
                                            )}
                                        </p>
                                        <CFileUpload
                                            isCrop
                                            accept="image/*"
                                            uploadLabel={t('CUserMypageTeam.画像')}
                                            aspectRatio={3 / 1}
                                            fileUploaded={(files) => logoFileUploaded(files.length ? files[0] : undefined)}
                                        />
                                        <p>{t('CUserMypageTeam.推奨サイズ：横幅 150px、縦幅 50px（比率 3：1）')}</p>
                                    </div>
                                )}
                            </>
                        )}
                        {user?.roles.company === 2 && (
                            <>
                                <CHeader label={t('CUserMypageTeam.自社帯')} />
                                <CMessage info>
                                    {t('CUserMypageTeam.自社帯を設定すると、物件概要書を出力したときに帯が追加されます。')}
                                </CMessage>
                                <CLabeledItem label={t('CUserMypageTeam.自社帯を設定する')} horizontal>
                                    <CToggleSwitch
                                        width="base"
                                        value={isSetBand}
                                        trueLabel={'YES'}
                                        falseLabel={'NO'}
                                        toggleChanged={(value) => {
                                            setIsSetBand(value)
                                        }}
                                    />
                                </CLabeledItem>
                                <CError errors={bandErrors} />
                                {(isCompanyBandSaveSuccess || isCompanyBandDeleteSuccess) && (
                                    <div className={clsx('bg-green-50', 'text-green-900', 'p-4', 'rounded')}>
                                        {t('CUserMypageTeam.自社帯を変更しました')}
                                    </div>
                                )}
                                {isSetBand && (
                                    <div className={clsx('flex', 'flex-col', 'space-y-2')}>
                                        <p>
                                            {userCompany?.bandImage ? (
                                                <img
                                                    src={getSignedUrl(userCompany.bandImage)}
                                                    alt=""
                                                    loading="lazy"
                                                    crossOrigin="anonymous"
                                                />
                                            ) : (
                                                <span>{t('CUserMypageTeam.帯は未設定です')}</span>
                                            )}
                                        </p>
                                        <CFileUpload
                                            accept="image/jpeg,image/png,image/gif"
                                            uploadLabel={t('CUserMypageTeam.画像')}
                                            fileUploaded={(files) => bandFileUploaded(files.length ? files[0] : undefined)}
                                        />
                                        <p>{t('CUserMypageTeam.推奨サイズ：横幅 670px、縦幅 60px')}</p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
            {selectedTab === 'team' && (
                <>
                    {teamModel && (
                        <div className={clsx('flex', 'flex-col', 'space-y-4', 'max-w-xl', 'w-full')}>
                            {isTeamSaveSuccess && (
                                <div className={clsx('bg-green-50', 'text-green-900', 'p-4', 'rounded')}>
                                    {t('CUserMypageTeam.チーム基本情報を変更しました')}
                                </div>
                            )}
                            <CError errors={teamErrors} />
                            <CLabeledItem label={t('CUserMypageTeam.チーム名')} required={user?.roles.team === 2}>
                                {user?.roles.team === 1 && <div className={clsx('m-2', 'font-semibold')}>{teamModel.name}</div>}
                                {user?.roles.team === 2 && (
                                    <CTextInput
                                        type={'text'}
                                        text={teamModel.name}
                                        onChange={(value) => inputTeamChangeHandler('name', value)}
                                        className={clsx('w-full', 'c-text-input-base')}
                                    />
                                )}
                            </CLabeledItem>
                            <CLabeledItem label={t('CUserMypageTeam.概要')}>
                                {user?.roles.team === 1 && (
                                    <div className={clsx('m-2', 'font-semibold')}>{teamModel.overview}</div>
                                )}
                                {user?.roles.team === 2 && (
                                    <CTextInput
                                        type={'text'}
                                        text={teamModel.overview}
                                        onChange={(value) => inputTeamChangeHandler('overview', value)}
                                        className={clsx('w-full', 'c-text-input-base')}
                                    />
                                )}
                            </CLabeledItem>
                            {user?.roles.team === 2 && (
                                <CButton
                                    className={clsx('c-button-primary', 'w-full')}
                                    disabled={!teamModel.name}
                                    onClick={teamSaveHandler}>
                                    {t('CUserMypageTeam.設定確定')}
                                </CButton>
                            )}
                        </div>
                    )}
                </>
            )}
            {selectedTab === 'member' && !!userTeam && <CUserMyPageTeamMember team={userTeam} />}
            <CConfirmModal
                visible={isShowResetLogo}
                confirmLabel={t('Button.削除')}
                onRequestConfirm={() => removeCompanyLogo()}
                onRequestClose={() => {
                    setIsShowResetLogo(false)
                    setIsSetLogo(true)
                }}>
                <div>
                    <div className={clsx('m-4')}>{t('CUserMypageTeam.自社ロゴを削除します。よろしいですか？')}</div>
                    <div className={clsx('flex', 'justify-center', 'my-4')}>
                        <CompanyLogo />
                    </div>
                </div>
            </CConfirmModal>
            <CConfirmModal
                visible={isShowResetBand}
                confirmLabel={t('Button.削除')}
                onRequestConfirm={() => removeCompanyBand()}
                onRequestClose={() => {
                    setIsShowResetBand(false)
                    setIsSetBand(true)
                }}>
                <div>
                    <div className={clsx('m-4')}>{t('CUserMypageTeam.自社帯を削除します。よろしいですか？')}</div>
                </div>
            </CConfirmModal>
        </>
    )
}

export default CUserMypageTeam
