import clsx from 'clsx'
import { t } from 'i18next'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import { CButton } from '~/components/common/cButton/CButton'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { pageTitleTemplate } from '~/util/common/common'

const Download = () => (
    <div className={clsx('p-2', 'font-body', 'bg-gray-50', 'h-full')}>
        <Helmet titleTemplate={pageTitleTemplate()}>
            <title>{t('Download.資料ダウンロード')}</title>
        </Helmet>
        <div className={clsx('max-w-xl', 'mx-auto', 'bg-white', 'rounded', 'p-8', 'flex', 'flex-col', 'gap-4')}>
            <CHeader label={t('Download.資料ダウンロード')} />
            <CMessage info>
                <p>{t('Download.キマールの資料ダウンロードにお申し込みいただきありがとうございます。')}</p>
                <p>{t('Download.ご入力いただいたメールアドレスに、資料ダウンロードのURLが記載されたメールをお送りしました。')}</p>
            </CMessage>
            <Link to="/">
                <CButton className={clsx('c-button-primary', 'w-full')}>{t('Download.トップページへ')}</CButton>
            </Link>
        </div>
    </div>
)

export default Download
