import { GoogleMap, Marker, OverlayView } from '@react-google-maps/api'
import clsx from 'clsx'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation } from 'react-router-dom'

import { RealEstateEntities, useClientRealEstateRealEstateQuery } from '~/app/api'
import defaultPin from '~/assets/image/page/map/default.svg'
import defaultActivePin from '~/assets/image/page/map/default_active.svg'
import pastReceivePin from '~/assets/image/page/map/past_receive.svg'
import pastReceiveActivePin from '~/assets/image/page/map/past_receive_active.svg'
import pastSendPin from '~/assets/image/page/map/past_send.svg'
import pastSendActivePin from '~/assets/image/page/map/past_send_active.svg'
import receivePin from '~/assets/image/page/map/receive.svg'
import receiveActivePin from '~/assets/image/page/map/receive_active.svg'
import registPin from '~/assets/image/page/map/regist.svg'
import registActivePin from '~/assets/image/page/map/regist_active.svg'
import sendPin from '~/assets/image/page/map/send.svg'
import sendActivePin from '~/assets/image/page/map/send_active.svg'
import { CGuideTips } from '~/components/common/cGuideTips/CGuideTips'
import CMessage from '~/components/common/cMessage/CMessage'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import CMapIconLegend from '~/components/unique/map/CMapIconLegend'
import { RealEstateReceiveStatus, RealEstateSendStatus } from '~/types/enum/enum'
import { dateFormatOfJc, formatDateTime, fullCurrencyFormat, pageTitleTemplate } from '~/util/common/common'
import { saleTimeTypeFormat } from '~/util/filter/format'
import { selectLatLng, selectZoom, setLatLng as _setLatLng, setZoom as _setZoom } from '~/util/store/mapSlice'
import { selectIsPlanStandardOrOver } from '~/util/store/userSlice'

import { CButton } from '../../components/common/cButton/CButton'
import {
    CRealestateSearch,
    RealEstateSearchFilterArrayType,
    RealEstateSearchFormValue,
} from '../../components/unique/realestate/CRealestateSearch'
import { useAppDispatch, useAppSelector } from '../../util/store/hooks'

const Map = () => {
    const dispatch = useAppDispatch()
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const option = {
        lat: query.get('lat'),
        lng: query.get('lng'),
    }
    const [map, setMap] = useState<google.maps.Map>()
    // 最初に吹き出しを開いておきたい物件
    const initOpenRealEstateUuid = useMemo(() => query.get('uuid'), [])

    // 中心座標
    const latLng = useAppSelector(selectLatLng)
    const center = useMemo(() => (option.lat && option.lng ? { lat: Number(option.lat), lng: Number(option.lng) } : latLng), [])
    const [showLatLngSet, setShowLatLngSet] = useState<{
        topRight?: { lat?: number; lng?: number }
        bottomLeft?: { lat?: number; lng?: number }
    }>()

    // zoom値
    const _zoom = useAppSelector(selectZoom)
    const zoom = useMemo(() => _zoom, [])

    // ピンをカラフルに表示する契約か
    const canColoringPin = useAppSelector(selectIsPlanStandardOrOver)
    // ピン凡例表示を縮小サイズかフルサイズか
    const [isLegendDisplayFull, setIsLegendDisplayFull] = useState(true)

    // 検索フォーム
    const [form, setForm] = useState<{
        name: string
        order: string
        filter: RealEstateSearchFilterArrayType
        sort: 'ASC' | 'DESC'
        useTypes: string[]
        prices: string[]
        buildingAges: string[]
        saleTimes: string[]
        assignedToType: 'AND' | 'OR'
        assignedToUuidList: string[]
        labelType: 'AND' | 'OR'
        labelUuidList: string[]
        progress: string[]
        grossRate: string[]
        salesDestination: string[]
    }>({
        name: '',
        order: 'createdAt',
        filter: [],
        sort: 'ASC',
        useTypes: [],
        prices: [],
        buildingAges: [],
        saleTimes: [],
        assignedToType: 'OR',
        assignedToUuidList: [],
        labelType: 'OR',
        labelUuidList: [],
        progress: [],
        grossRate: [],
        salesDestination: [],
    })
    // 複数物件選択トグル値
    const [canSelectMultiple, setCanSelectMultiple] = useState(false)

    const onChangeSearchVal = (val: RealEstateSearchFormValue) => {
        setForm({
            ...form,
            ...val,
        })
    }

    const { data: realEstateList, isLoading: isLoadingRealEstateList } = useClientRealEstateRealEstateQuery(
        {
            ...form,
            pageSize: Number.MAX_SAFE_INTEGER,
            page: 0,
            topRightLat: showLatLngSet?.topRight?.lat,
            topRightLng: showLatLngSet?.topRight?.lng,
            bottomLeftLat: showLatLngSet?.bottomLeft?.lat,
            bottomLeftLng: showLatLngSet?.bottomLeft?.lng,
            isMap: true,
        },
        { skip: !showLatLngSet },
    )

    // 物件モデルにプラスしてマーカー押下ウィンドウが表示されているかの状態を持たせたもの
    const [marker, setMarker] = useState<Array<RealEstateEntities & { isOpen: boolean }>>()
    useEffect(() => {
        setMarker(
            (realEstateList?.list as [RealEstateEntities])?.map((r) => ({
                ...r,
                isOpen: r.uuid === initOpenRealEstateUuid,
            })),
        )
    }, [realEstateList])

    const onMarkerClick = (realEstate: RealEstateEntities & { isOpen: boolean }) => {
        // クリックしたマーカーに対応する物件吹き出しオープン・クローズ
        if (!canSelectMultiple) {
            // 開閉反転は自分だけ、他は全部クローズ
            setMarker((old) => {
                return old?.map((o) => ({ ...o, isOpen: o.uuid === realEstate.uuid ? !o.isOpen : false }))
            })
        } else {
            setMarker((old) => {
                return old?.map((o) => ({ ...o, isOpen: o.uuid === realEstate.uuid ? !o.isOpen : o.isOpen }))
            })
        }
        // 凡例縮小表示
        setIsLegendDisplayFull(false)
    }

    const onCenterChanged = () => {
        const changedCenter = map?.getCenter()
        if (!changedCenter) return
        dispatch(_setLatLng({ lat: changedCenter.lat(), lng: changedCenter.lng() }))
    }

    const onZoomChanged = () => {
        const changedZoom = map?.getZoom()
        if (!changedZoom) return
        dispatch(_setZoom(changedZoom))
    }

    const onBoundsChanged = () => {
        if (showLatLngSet) return
        const topRight = map?.getBounds()?.getNorthEast()
        const bottomLeft = map?.getBounds()?.getSouthWest()
        if (topRight && bottomLeft) {
            setShowLatLngSet({
                topRight: { lat: topRight?.lat(), lng: topRight?.lng() },
                bottomLeft: { lat: bottomLeft?.lat(), lng: bottomLeft?.lng() },
            })
        }
    }

    const onClickReload = () => {
        const topRight = map?.getBounds()?.getNorthEast()
        const bottomLeft = map?.getBounds()?.getSouthWest()
        if (topRight && bottomLeft) {
            setShowLatLngSet({
                topRight: { lat: topRight?.lat(), lng: topRight?.lng() },
                bottomLeft: { lat: bottomLeft?.lat(), lng: bottomLeft?.lng() },
            })
        }
    }

    const onToggleChaged = (value: boolean) => {
        // ON -> OFFの場合はinfoWindow全クローズ
        if (!value) {
            setMarker((old) => {
                return old?.map((o) => ({ ...o, isOpen: false }))
            })
        }
        // OFF -> ONの場合は今infoWindow開いているものはそのままとするのでsetMarker()なし
        setCanSelectMultiple(value)
    }

    const legendDom = () => {
        if (!canColoringPin) return
        return (
            <div onClick={() => setIsLegendDisplayFull(!isLegendDisplayFull)}>
                <CMapIconLegend isLegendDisplayFull={isLegendDisplayFull} />
            </div>
        )
    }

    const makeIcon = (realEstate: RealEstateEntities & { isOpen: boolean }): google.maps.Icon => {
        let icon = realEstate.isOpen ? defaultActivePin : defaultPin
        if (canColoringPin) {
            if (realEstate.sendStatus === RealEstateSendStatus.find((status) => status.label === '紹介している')?.value)
                icon = realEstate.isOpen ? sendActivePin : sendPin
            else if (
                realEstate.receiveStatus === RealEstateReceiveStatus.find((status) => status.label === '紹介されている')?.value
            )
                icon = realEstate.isOpen ? receiveActivePin : receivePin
            else if (realEstate.sendStatus === RealEstateSendStatus.find((status) => status.label === '紹介していた')?.value)
                icon = realEstate.isOpen ? pastSendActivePin : pastSendPin
            else if (
                realEstate.receiveStatus === RealEstateReceiveStatus.find((status) => status.label === '紹介されていた')?.value
            )
                icon = realEstate.isOpen ? pastReceiveActivePin : pastReceivePin
            else if (
                realEstate.sendStatus === RealEstateSendStatus.find((status) => status.label === '紹介したことがない')?.value
            )
                icon = realEstate.isOpen ? registActivePin : registPin
        }
        return {
            url: icon,
            size: new google.maps.Size(48, 48, 'px', 'px'),
            scaledSize: new google.maps.Size(48, 48, 'px', 'px'),
        }
    }

    const infoWindowContent = (realEstate: RealEstateEntities & { isOpen: boolean }) => (
        <div>
            <section className={clsx('p-2', 'text-xs')}>
                <h3 className={clsx('text-base')}>{realEstate.name}</h3>
                <table className={clsx('m-1', 'w-full', 'text-left')}>
                    <tbody>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.住所')}</th>
                            <td>{realEstate.address}</td>
                        </tr>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.最寄り駅')}</th>
                            <td>
                                <p>{realEstate.station}</p>
                            </td>
                        </tr>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.種別')}</th>
                            <td>{realEstate.useType.name}</td>
                        </tr>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.竣工日')}</th>
                            <td>
                                {realEstate.buildAt &&
                                    dateFormatOfJc(
                                        realEstate.buildAt,
                                        realEstate.buildAtDateType === 2
                                            ? 'year'
                                            : realEstate.buildAtDateType === 1
                                            ? 'month'
                                            : 'day',
                                    )}
                            </td>
                        </tr>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.売却時期')}</th>
                            <td>{saleTimeTypeFormat(realEstate.saleTimeType)}</td>
                        </tr>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.物件価格')}</th>
                            <td>{fullCurrencyFormat(realEstate.price)}</td>
                        </tr>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.情報取得元')}</th>
                            <td>
                                <p>{realEstate.informationSource}</p>
                            </td>
                        </tr>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.情報取得日')}</th>
                            <td>{realEstate.acquiredAt && formatDateTime(realEstate.acquiredAt, 'dateTime')}</td>
                        </tr>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.売主')}</th>
                            <td>{realEstate.seller}</td>
                        </tr>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.登録日')}</th>
                            <td>{formatDateTime(realEstate.createdAt, 'dateTime')}</td>
                        </tr>
                        <tr className={clsx('border-b')}>
                            <th>{t('Map.更新日')}</th>
                            <td>{formatDateTime(realEstate.updatedAt, 'dateTime')}</td>
                        </tr>
                    </tbody>
                </table>
                <div className={clsx('flex', 'flex-col', 'm-1', 'gap-y-1')}>
                    <Link to={`/realestate/${realEstate.uuid}`} rel="noopener noreferrer" target="_blank">
                        <CButton className={clsx('c-button-primary', 'w-full')}>{t('Map.物件詳細を見る')}</CButton>
                    </Link>
                    <CButton className={clsx('c-button-secondary', 'w-full')} onClick={() => onMarkerClick(realEstate)}>
                        {t('Button.閉じる')}
                    </CButton>
                </div>
            </section>
        </div>
    )

    /** 物件情報ドラッグ移動対応 */
    const [isOverlayDragging, setIsOverlayDragging] = useState<string>()
    const [overlayPos, setOverlayPos] = useState<{ [key: string]: google.maps.LatLng }>({})
    const [overlayOffset, setOverlayOffset] = useState<{ [key: string]: { x: number; y: number } }>({})
    const isOpenRealEstate = useMemo(() => {
        const keys = Object.keys(overlayPos)
        return marker?.filter((m) => keys.includes(m.uuid))
    }, [overlayPos])
    useEffect(() => {
        setOverlayPos((old) => {
            const newVal = { ...old }
            const open = marker?.filter((m) => m.isOpen)
            for (const o of open ?? [])
                if (!newVal[o.uuid]) newVal[o.uuid] = new google.maps.LatLng(o.latitude || 0, o.longitude || 0)
            const close = marker?.filter((m) => !m.isOpen)
            for (const o of close ?? []) delete newVal[o.uuid]
            return newVal
        })
        setOverlayOffset((old) => {
            const newVal = { ...old }
            const close = marker?.filter((m) => !m.isOpen)
            for (const o of close ?? []) delete newVal[o.uuid]
            return newVal
        })
    }, [marker])

    return (
        <div className={clsx('w-full', 'h-full', 'flex', 'flex-col', 'items-center', 'relative', 'space-y-4')}>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Map.地図')}</title>
            </Helmet>

            <div className={clsx('w-full', 'flex', 'flex-col', 'gap-2', 'pt-4', 'md:pt-8', 'px-4', 'md:px-8')}>
                <CRealestateSearch onChange={onChangeSearchVal} />
                <div className={clsx('flex', 'items-center', 'justify-between')}>
                    <div className={clsx('flex', 'flex-row', 'gap-x-2', 'items-center', 'self-start')}>
                        <CButton
                            className={clsx('c-button-primary')}
                            disabled={isLoadingRealEstateList}
                            onClick={() => onClickReload()}>
                            <div>{t('Map.表示範囲で再度検索する')}</div>
                        </CButton>
                    </div>
                    <div className={clsx('flex', 'flex-row', 'gap-x-2', 'items-center', 'self-end')}>
                        {t('Map.複数物件選択モード')}
                        <CToggleSwitch
                            value={canSelectMultiple}
                            trueLabel={'ON'}
                            falseLabel={'OFF'}
                            toggleChanged={(value) => onToggleChaged(value)}
                        />
                        <CGuideTips tooltipDirection="left">{t('Map.ONにすると、複数の物件情報が同時に閲覧できます')}</CGuideTips>
                    </div>
                </div>

                {isLoadingRealEstateList ? (
                    <div className={clsx('mx-2', 'mb-2')}>
                        <CMessage info>{t('Message.読み込み中です…')}</CMessage>
                    </div>
                ) : (
                    (!marker?.length || marker.length <= 0) && (
                        <div className={clsx('mx-2', 'mb-2')}>
                            <CMessage info>{t('Map.物件はありません')}</CMessage>
                        </div>
                    )
                )}
            </div>

            <div className={clsx('mt-4', 'w-full', 'flex-grow', 'min-h-[500px]', 'relative')}>
                {legendDom()}

                <GoogleMap
                    options={{ draggable: !isOverlayDragging }}
                    mapContainerStyle={{
                        height: '100%',
                        width: '100%',
                    }}
                    center={center}
                    zoom={zoom}
                    onLoad={(map) => setMap(map)}
                    onCenterChanged={onCenterChanged}
                    onZoomChanged={onZoomChanged}
                    onBoundsChanged={onBoundsChanged}
                    onMouseMove={(e) => {
                        if (isOverlayDragging) {
                            const { latLng } = e
                            setOverlayPos((old) => {
                                return {
                                    ...old,
                                    [isOverlayDragging]: new google.maps.LatLng(latLng?.lat() || 0, latLng?.lng() || 0),
                                }
                            })
                        }
                    }}>
                    {marker?.map((realEstate) => {
                        if (realEstate.latitude && realEstate.longitude) {
                            return (
                                <Marker
                                    key={realEstate.uuid}
                                    position={new google.maps.LatLng(realEstate.latitude, realEstate.longitude)}
                                    title={realEstate.name}
                                    icon={makeIcon(realEstate)}
                                    onClick={() => onMarkerClick(realEstate)}
                                />
                            )
                        }
                    })}
                    {isOpenRealEstate?.map((realEstate) => {
                        if (realEstate.latitude && realEstate.longitude) {
                            return (
                                <OverlayView
                                    key={`${realEstate.uuid}_window`}
                                    getPixelPositionOffset={() => {
                                        if (overlayOffset[realEstate.uuid]) return overlayOffset[realEstate.uuid]
                                        else return { x: 0, y: 0 }
                                    }}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    position={overlayPos[realEstate.uuid]}>
                                    <div
                                        className={clsx('bg-white')}
                                        onMouseDown={(e) => {
                                            setIsOverlayDragging(realEstate.uuid)
                                            const z = e.currentTarget.getBoundingClientRect()
                                            setOverlayOffset((old) => ({
                                                ...old,
                                                [realEstate.uuid]: {
                                                    x: z.left - e.clientX,
                                                    y: z.top - e.clientY,
                                                },
                                            }))
                                        }}
                                        onMouseUp={() => {
                                            setIsOverlayDragging(undefined)
                                        }}>
                                        {infoWindowContent(realEstate)}
                                    </div>
                                </OverlayView>
                            )
                        }
                    })}
                </GoogleMap>
            </div>
        </div>
    )
}
export default Map
