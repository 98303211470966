import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast/headless'

import {
    RealEstateAssigneeEntities,
    RealEstateEntities,
    TeamMemberEntities,
    useClientRealEstateAssigneeDeleteUuidMutation,
    useClientRealEstateAssigneePostRealEstateAssigneeMutation,
    useClientRealEstateAssigneePutUuidMutation,
    useClientTeamMemberTeamUuidQuery,
} from '~/app/api'
import { CDropDown } from '~/components/common/cDropdown/CDropdown'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CTag } from '~/components/common/cTag/CTag'
import { selectIsPlanStandardOrOver, selectLoginUser, selectLoginUserTeam } from '~/util/store/userSlice'

import { ApplicationError, IApplicationError } from '../../../../types/error'
import { useAppSelector } from '../../../../util/store/hooks'
import { CButton } from '../../../common/cButton/CButton'
import { CError } from '../../../common/cError/CError'

type Props = {
    realEstate: RealEstateEntities
    refetch: () => void
}

const CRealestateDetailAssignee = ({ realEstate, refetch }: Props) => {
    const user = useAppSelector(selectLoginUser)
    const userTeam = useAppSelector(selectLoginUserTeam)
    const { data: teamMemberListCount } = useClientTeamMemberTeamUuidQuery(
        { teamUuid: userTeam?.uuid ?? '' },
        { skip: !userTeam },
    )
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const userRoleRealEstateWatchOnly = () => user?.roles.realestate === 1
    // スタンダード以上
    const isPlanStandardOrOver = useAppSelector(selectIsPlanStandardOrOver)

    // 追加
    const [insertUuid, setInsertUuid] = useState<string>()
    useEffect(() => {
        if (teamMemberListCount && filterdMembers().length > 0) setInsertUuid(filterdMembers()[0].uuid)
    }, [teamMemberListCount])
    const [insertQuery] = useClientRealEstateAssigneePostRealEstateAssigneeMutation()
    const insertAssignee = async () => {
        try {
            setErrors([])
            if (!teamMemberListCount) return
            const insertMember = (teamMemberListCount.list as TeamMemberEntities[]).find((m) => m.uuid === insertUuid)
            if (!insertMember) return
            // スタンダード未満で担当者設定済みの場合、削除してから追加
            if (!isPlanStandardOrOver && realEstate.assignees && realEstate.assignees.length !== 0)
                await deleteQuery({ uuid: realEstate.assignees[0].uuid }).unwrap()
            await insertQuery({
                clientRealEstateAssigneeInsertDto: {
                    isPrimary: !realEstate.assignees?.length,
                    member: insertMember,
                    realEstate,
                },
            }).unwrap()
            if (filterdMembers().length > 0) setInsertUuid(filterdMembers()[0].uuid)
            refetch()
            toast.success(t('CRealestateDetailAssignee.物件担当者を追加しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const filterdMembers = () => {
        const members = teamMemberListCount?.list as TeamMemberEntities[]
        if (!realEstate.assignees) return members
        // 担当者がすでにいる場合はリストから除外
        const assignees = realEstate.assignees.map((assignee) => assignee.assignedTo)
        const result = members.filter((member) => !assignees.some((assignee) => assignee?.email === member.email))
        return result
    }

    // 編集(担当入れ替え)
    const [updateQuery] = useClientRealEstateAssigneePutUuidMutation()
    const updateAssignee = async (assignee: RealEstateAssigneeEntities, isPrimary: boolean) => {
        try {
            setErrors([])
            await updateQuery({
                uuid: assignee.uuid,
                clientRealEstateAssigneeUpdateDto: {
                    isPrimary,
                },
            }).unwrap()
            if (teamMemberListCount && filterdMembers().length > 0) setInsertUuid(filterdMembers()[0].uuid)
            refetch()
            toast.success(t('CRealestateDetailAssignee.物件担当者を編集しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    // 削除
    const [deleteTarget, setDeleteTarget] = useState<RealEstateAssigneeEntities>()
    const [deleteQuery] = useClientRealEstateAssigneeDeleteUuidMutation()
    const deleteAssignee = async () => {
        if (!deleteTarget) return
        await deleteQuery({ uuid: deleteTarget.uuid }).unwrap()
        setDeleteTarget(undefined)
        if (teamMemberListCount && filterdMembers().length > 0) setInsertUuid(filterdMembers()[0].uuid)
        refetch()
        toast.success(t('CRealestateDetailAssignee.物件担当者を削除しました'))
    }

    return (
        <>
            <CHeader label={t('CRealestateDetailAssignee.物件担当者')} bgClass="bg-kimar-accent" />
            <div>
                {(realEstate.assignees?.length ?? 0) > 0 ? (
                    <table className={clsx('w-full', 'text-sm')}>
                        <thead>
                            <tr className={clsx('bg-kimar-primary', 'text-white', 'text-left')}>
                                <th className={clsx('pl-2')}>{t('CRealestateDetailAssignee.担当者名')}</th>
                                <th className={clsx('pl-2', 'w-[200px]')}>{t('CRealestateDetailAssignee.操作')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {realEstate.assignees?.map((assignee) => (
                                <tr key={assignee.uuid} className={clsx('border-b')}>
                                    <td className={clsx('pl-2')}>
                                        <div className={clsx('flex', 'items-center', 'justify-start')}>
                                            {isPlanStandardOrOver && (
                                                <CTag
                                                    className={clsx(
                                                        'rounded',
                                                        'px-2',
                                                        'mr-2',
                                                        'text-white',
                                                        assignee.isPrimary ? ['bg-kimar-primary'] : ['bg-sky-700'],
                                                    )}
                                                    children={
                                                        <>
                                                            {assignee.isPrimary
                                                                ? t('CRealestateDetailAssignee.主担当者')
                                                                : t('CRealestateDetailAssignee.副担当者')}
                                                        </>
                                                    }
                                                />
                                            )}
                                            <div>{assignee.assignedTo?.name}</div>
                                        </div>
                                    </td>
                                    <td className={clsx('pl-2')}>
                                        <div className={clsx('flex', 'items-center', 'gap-1')}>
                                            {isPlanStandardOrOver && realEstate.assignees?.length !== 1 && (
                                                <CButton
                                                    className={clsx(
                                                        assignee.isPrimary ? 'c-button-secondary' : 'c-button-primary',
                                                        'text-sm',
                                                    )}
                                                    disabled={userRoleRealEstateWatchOnly()}
                                                    onClick={() => updateAssignee(assignee, !assignee.isPrimary)}>
                                                    {assignee.isPrimary
                                                        ? t('CRealestateDetailAssignee.副担当者に変更')
                                                        : t('CRealestateDetailAssignee.主担当者に変更')}
                                                </CButton>
                                            )}
                                            <CButton
                                                className={clsx('c-button-danger', 'text-sm')}
                                                disabled={userRoleRealEstateWatchOnly()}
                                                onClick={() => setDeleteTarget(assignee)}>
                                                {t('Button.削除')}
                                            </CButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <CMessage info>{t('CRealestateDetailAssignee.担当者は設定されていません')}</CMessage>
                )}
            </div>
            {teamMemberListCount && user?.roles.realestate === 2 && (
                <div className={clsx('mb-8')}>
                    <CError errors={errors} />
                    <div className={clsx('flex', 'flex-col', 'lg:flex-row', 'justify-between', 'space-x-2', 'lg:items-center')}>
                        <div className={clsx('text-sm')}>
                            {t('CRealestateDetailAssignee.物件の担当者にするメンバーを選択してください。')}
                        </div>
                        <div className={clsx('flex', 'space-x-2')}>
                            <div className={clsx('flex-1')}>
                                <CDropDown
                                    className={clsx('w-full', 'min-w-[180px]', 'rounded', 'border-gray-300')}
                                    items={filterdMembers()}
                                    dataLabel="name"
                                    dataValue="uuid"
                                    nowValue={insertUuid}
                                    onChange={(value) => setInsertUuid(value)}
                                />
                            </div>
                            <CButton
                                className={clsx('c-button-primary', 'whitespace-nowrap')}
                                disabled={filterdMembers().length === 0}
                                onClick={insertAssignee}>
                                {t('CRealestateDetailAssignee.担当者に追加')}
                            </CButton>
                        </div>
                    </div>
                </div>
            )}
            {/* 削除確認モーダル */}
            <CConfirmModal
                visible={!!deleteTarget}
                confirmLabel={t('Button.削除')}
                onRequestClose={() => setDeleteTarget(undefined)}
                onRequestConfirm={() => deleteAssignee()}>
                {t('CRealestateDetailAssignee.担当者「assignee」を削除します。よろしいですか？', {
                    assignee: deleteTarget?.assignedTo?.name,
                })}
            </CConfirmModal>
        </>
    )
}

export default CRealestateDetailAssignee
