import { normalize } from '@geolonia/normalize-japanese-addresses'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useState, VFC } from 'react'

import { ClientRealEstateInsertDto, RealEstateUseTypeEntities } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CTag } from '~/components/common/cTag/CTag'
import { areaTypeWithOutWordsItems, tokyo5WardsAreaTypeItems, tokyo23WithOut5WardsAreaTypeItems } from '~/types/enum/enum'
import { AreaType, BuildingAgeType, GrossRateType, NetRateType, PriceType } from '~/types/enum/market'
import { formatDateTime } from '~/util/common/common'
import {
    areaTypeFormat,
    legalComplianceTypeFormat,
    realestateBuildingAgeTypeFormat,
    realestateGrossRateTypeFormat,
    realestateNetRateTypeFormat,
    realestatePriceTypeFormat,
    realestateSalesDestinationTypeFormat,
    realestateSeismicStandardTypeFormat,
    saleTimeTypeFormat,
} from '~/util/filter/format'

export type CRealestateMarketPreviewViewProps = {} & CRealestateMarketPreviewProps

export const CRealestateMarketPreviewView: VFC<CRealestateMarketPreviewViewProps> = ({ item }) => {
    // 住所からareaTypeを取得
    const getAreaType = (address: string) => {
        if (!address) return ''
        const regexPrefecture = address.match(/東京都|北海道|(?:京都|大阪)府|.{2,3}県/)
        if (regexPrefecture) {
            // 住所が東京都の場合は区まで確認する
            if (regexPrefecture[0] === '東京都') {
                const regexWard = address.replace('東京都', '').match(/.{1,3}区/)
                if (regexWard) {
                    // 都心5区と一致するかを確認
                    const tokyo5 = tokyo5WardsAreaTypeItems.find((item) => item.label === regexWard[0])
                    if (tokyo5) return areaTypeFormat(AreaType.Tokyo5Wards)

                    // 都心5区を除いた都心23区と一致するかを確認
                    const tokyo23WithOut5 = tokyo23WithOut5WardsAreaTypeItems.find((item) => item.label === regexWard[0])
                    if (tokyo23WithOut5) return areaTypeFormat(AreaType.Tokyo5Wards)
                }
            }
            const areaType = areaTypeWithOutWordsItems.find((item) => item.label === regexPrefecture[0])
            return areaType ? areaTypeFormat(areaType.value) : ''
        }
        return areaTypeFormat(AreaType.InitValue)
    }

    // 金額からpriceTypeを取得
    const getPriceType = (price: number | undefined) => {
        if (!price) return realestatePriceTypeFormat(PriceType.InitValue)
        else if (price < 100000000) return realestatePriceTypeFormat(PriceType.Under1Billion)
        else if (price < 500000000) return realestatePriceTypeFormat(PriceType.Under5Billion)
        else if (price < 1000000000) return realestatePriceTypeFormat(PriceType.Under10Billion)
        return realestatePriceTypeFormat(PriceType.Over10Billion)
    }

    // 竣工日からbuildingAgeTypeを取得
    const getBuildingAgeType = (buildAt: string | undefined) => {
        if (!buildAt) return realestateBuildingAgeTypeFormat(BuildingAgeType.InitValue)
        // 現時刻
        const now = dayjs()
        // 竣工日
        const dayjsBuildAt = dayjs(buildAt)
        // api側の築年数の算出処理と合わせるため、daysを365で割って切り捨てた値を取得
        const buildingAge = Math.floor(now.diff(dayjsBuildAt, 'days') / 365)
        if (buildingAge <= 5) return realestateBuildingAgeTypeFormat(BuildingAgeType.Under5Years)
        else if (buildingAge <= 10) return realestateBuildingAgeTypeFormat(BuildingAgeType.Under10Years)
        else if (buildingAge <= 20) return realestateBuildingAgeTypeFormat(BuildingAgeType.Under20Years)
        return realestateBuildingAgeTypeFormat(BuildingAgeType.Over20Years)
    }

    // 表面利回りからgrossRateTypeを取得
    const getGrossRateType = (grossRate: number | undefined) => {
        if (!grossRate) return realestateGrossRateTypeFormat(GrossRateType.InitValue)
        else if (grossRate >= 7) return realestateGrossRateTypeFormat(GrossRateType.Over7Percent)
        else if (grossRate >= 6) return realestateGrossRateTypeFormat(GrossRateType.Over6Percent)
        else if (grossRate >= 5) return realestateGrossRateTypeFormat(GrossRateType.Over5Percent)
        else if (grossRate >= 4) return realestateGrossRateTypeFormat(GrossRateType.Over4Percent)
        return realestateGrossRateTypeFormat(GrossRateType.Under3Percent)
    }

    // 実質利回りからnetRateTypeを取得
    const getNetRateType = (netRate: number | undefined) => {
        if (!netRate) return realestateNetRateTypeFormat(NetRateType.InitValue)
        else if (netRate >= 6) return realestateNetRateTypeFormat(NetRateType.Over6Percent)
        else if (netRate >= 5) return realestateNetRateTypeFormat(NetRateType.Over5Percent)
        else if (netRate >= 4) return realestateNetRateTypeFormat(NetRateType.Over4Percent)
        else if (netRate >= 3) return realestateNetRateTypeFormat(NetRateType.Over3Percent)
        return realestateNetRateTypeFormat(NetRateType.Under2Percent)
    }

    // 種別画像の取得
    const getCoverUrl = (useType: RealEstateUseTypeEntities) => {
        if (useType) return `/src/assets/image/page/market/use_type/use_type_${useType.sort}.svg`
        else return '/src/assets/image/noimage.svg'
    }

    // itemのタイトル表示
    const [itemTitle, setItemTitle] = useState('')
    useEffect(() => {
        const f = async () => {
            let showAddress: string | undefined = undefined
            try {
                const getAddressResult = item.address ? await normalize(item.address, { level: 2 }) : undefined
                showAddress = getAddressResult ? getAddressResult.pref + getAddressResult.city : undefined
            } catch {
                // なんらかの理由で住所が取得できなかった場合スキップ
            }

            if (item.useType.name === '土地' || getBuildingAgeType(item.buildAt) === '-') {
                setItemTitle(
                    t('CRealestateMarketPreview.area／price／usetype', {
                        area: showAddress ?? getAreaType(item.address),
                        price: getPriceType(item.price),
                        usetype: item.useType.name,
                    }),
                )
                return
            }
            setItemTitle(
                t('CRealestateMarketPreview.area／age／price／usetype', {
                    area: showAddress ?? getAreaType(item.address),
                    age: getBuildingAgeType(item.buildAt),
                    price: getPriceType(item.price),
                    usetype: item.useType.name,
                }),
            )
        }
        f()
    }, [item])

    const labelDom = () => {
        return (
            <div className={clsx('absolute', 'top-1', 'flex', 'flex-row', 'gap-x-1', 'text-sm')}>
                <CTag className={clsx('rounded-full', 'bg-sky-700', 'px-2', 'text-white')}>
                    {t('CRealestateMarketPreview.NEW')}
                </CTag>
            </div>
        )
    }

    const requestButtonDom = () => {
        return (
            <CButton className={clsx('c-button-market', 'w-full')} disabled>
                {t('CRealestateMarketPreview.情報開示リクエスト')}
            </CButton>
        )
    }

    return (
        <>
            <div className={clsx('border', 'm-2', 'bg-white')}>
                <div className={clsx('sm:flex', 'p-2', 'relative')}>
                    {/* 通常画面サイズ時の画像・ボタン・ラベル表示 */}
                    <div className={clsx('sm:flex', 'flex-col', 'justify-center', 'w-48', 'relative', 'hidden')}>
                        <div className={clsx('w-full', 'h-full', 'flex', 'flex-col', 'justify-center')}>
                            <img src={getCoverUrl(item.useType)} alt="" loading="lazy" className={clsx('max-h-20', 'max-w-20')} />
                        </div>
                        <div className={clsx('bottom-3', 'flex', 'justify-center', 'mb-4')}>{requestButtonDom()}</div>
                        {labelDom()}
                    </div>
                    {/* スマホサイズ時のラベル表示 */}
                    <div className={clsx('absolute', 'whitespace-nowrap', 'sm:hidden')}>{labelDom()}</div>
                    <div className={clsx('flex-1', 'my-2')}>
                        <div className={clsx('flex', 'flex-col', 'gap-y-2', 'mx-2')}>
                            {/* スマホサイズ時の画像・タイトル表示 */}
                            <div className={clsx('flex', 'flex-row', 'gap-y-2', 'sm:hidden', 'items-center', 'pt-4')}>
                                <img
                                    src={getCoverUrl(item.useType)}
                                    alt=""
                                    loading="lazy"
                                    className={clsx('max-h-12', 'max-w-12', 'm-3')}
                                />
                                <div className={clsx('text-kimar-market', 'text-xl', 'font-bold')}>{itemTitle}</div>
                            </div>
                            {/* 通常サイズ時のタイトル表示 */}
                            <div className={clsx('text-kimar-market', 'text-xl', 'font-bold', 'hidden', 'sm:block')}>
                                {itemTitle}
                            </div>
                            <div className={clsx('flex')}>
                                <div className={clsx('grid', 'grid-cols-2', 'text-xs', 'flex-1')}>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CRealestateMarketPreview.種別')}</div>
                                        <div className="data">{item.useType.name}</div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CRealestateMarketPreview.エリア')}</div>
                                        <div className="data">{getAreaType(item.address)}</div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CRealestateMarketPreview.金額')}</div>
                                        <div className="data">{getPriceType(item.price)}</div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CRealestateMarketPreview.築年数')}</div>
                                        <div className="data">
                                            <span>{getBuildingAgeType(item.buildAt)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>
                                            {t('CRealestateMarketPreview.表面利回り')}
                                        </div>
                                        <div className="data">
                                            <span>{getGrossRateType(item.grossRate)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>
                                            {t('CMarketRealestateListItem.実質利回り')}
                                        </div>
                                        <div className="data">
                                            <span>{getNetRateType(item.netRate)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CRealestateMarketPreview.遵法性')}</div>
                                        <div className="data">
                                            <span>{legalComplianceTypeFormat(item.legalComplianceType)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CRealestateMarketPreview.耐震基準')}</div>
                                        <div className="data">
                                            {realestateSeismicStandardTypeFormat(item.seismicStandardType)}
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CRealestateMarketPreview.売却時期')}</div>
                                        <div className="data">
                                            <span>{saleTimeTypeFormat(item.saleTimeType)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b', 'py-1')}>
                                        <div className={clsx('w-24', 'font-bold')}>{t('CRealestateMarketPreview.先数')}</div>
                                        <div className="data">
                                            {realestateSalesDestinationTypeFormat(item.salesDestinationType)}
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'col-span-2', 'items-center')}>
                                        {item.marketComment ? (
                                            <>
                                                <div className={clsx('w-24', 'font-bold', 'py-1')}>
                                                    {t('CRealestateMarketPreview.掲載者コメント')}
                                                </div>
                                                <div className="data">{item.marketComment}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={clsx('w-24', 'font-bold', 'my-1', 'h-4')} />
                                                <div className="data" />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* スマホサイズ時のボタン表示 */}
                            <div className={clsx('sm:hidden')}>{requestButtonDom()}</div>
                            <div className={clsx('flex', 'flex-row', 'justify-between', 'bg-gray-50', 'py-2')}>
                                <div className={clsx('flex', 'flex-row', 'gap-x-3', 'text-gray-500', 'text-[10px]', 'pr-2')}>
                                    <div>
                                        {t('CRealestateMarketPreview.更新日')}：{formatDateTime(dayjs().toString(), 'date')}
                                    </div>
                                    <div>
                                        {t('CRealestateMarketPreview.掲載日')}：{formatDateTime(dayjs().toString(), 'date')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export type CRealestateMarketPreviewProps = {
    item: ClientRealEstateInsertDto
}

export const CRealestateMarketPreview: VFC<CRealestateMarketPreviewProps> = (props) => {
    return <CRealestateMarketPreviewView {...props} />
}
