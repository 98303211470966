import clsx from 'clsx'
import { ReactNode, useState, VFC } from 'react'

export type CNoticeWindowViewProps = {} & CNoticeWindowProps

export const CNoticeWindowView: VFC<CNoticeWindowViewProps> = ({ children }) => {
    const [isScrollDisplay, setIsScrollDisplay] = useState(true)
    const [isClose, setIsClose] = useState(false)

    const scrollHandler = () => {
        const body = window.document.body
        const _html = window.document.documentElement
        const scrollTop = body.scrollTop || _html.scrollTop
        const sc = _html.scrollHeight - _html.clientHeight - scrollTop

        // ページ下部 20% になったらバナーエリア非表示
        if (sc / (sc + window.scrollY) <= 0.2) setIsScrollDisplay(false)
        else setIsScrollDisplay(true)
    }

    window.addEventListener('scroll', scrollHandler)

    return (
        <div>
            {isScrollDisplay && !isClose && (
                <div className={clsx('fixed', 'bottom-4', 'right-4', 'bg-white', 'p-2', 'rounded', 'shadow-lg', 'z-10')}>
                    <div className={clsx('flex', 'justify-end')}>
                        <span className={clsx('align-top')} onClick={() => setIsClose(true)}>
                            <i className={clsx('material-icons text-sm align-top cursor-pointer')}>close</i>
                        </span>
                    </div>
                    {children}
                </div>
            )}
        </div>
    )
}

export type CNoticeWindowProps = {
    children: ReactNode
}

export const CNoticeWindow: VFC<CNoticeWindowProps> = (props) => {
    return <CNoticeWindowView {...props} />
}
