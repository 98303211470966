import clsx from 'clsx'
import { t } from 'i18next'
import { useMemo, useState, VFC } from 'react'

import { CustomerEntities } from '~/app/api'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'

import CCustomerDetailInfo from './CCustomerDetailInfo'
import CCustomerDetailManage from './CCustomerDetailManage'
import CCustomerDetailRealEstate from './CCustomerDetailRealEstate'
import CCustomerDetailRecommend from './CCustomerDetailRecommend'

export type CCustomerDetailViewProps = {} & CCustomerDetailProps

export const CCustomerDetailView: VFC<CCustomerDetailViewProps> = ({ customer, refetch }) => {
    const [selectedTab, setSelectedTab] = useState('profile')
    const tabs = useMemo(
        () => [
            { label: t('CCustomerDetail.顧客情報'), value: 'profile' },
            { label: t('CCustomerDetail.おすすめ物件'), value: 'recommend' },
            { label: t('CCustomerDetail.社内管理'), value: 'management' },
            { label: t('CCustomerDetail.関連物件'), value: 'realEstate' },
        ],
        [],
    )

    return (
        <div className={clsx('w-full', 'flex', 'flex-col', 'gap-4', 'p-4', 'bg-white', 'rounded')}>
            <CSegmentedControl items={tabs} onClick={(val) => setSelectedTab(val as string)} selectedTabValue={selectedTab} />
            {selectedTab === 'profile' && <CCustomerDetailInfo customer={customer} refetch={refetch} />}
            {selectedTab === 'recommend' && <CCustomerDetailRecommend customer={customer} />}
            {selectedTab === 'management' && <CCustomerDetailManage customer={customer} refetch={refetch} />}
            {selectedTab === 'realEstate' && <CCustomerDetailRealEstate customer={customer} />}
        </div>
    )
}

export type CCustomerDetailProps = {
    customer: CustomerEntities
    refetch: () => void
}

export const CCustomerDetail: VFC<CCustomerDetailProps> = (props) => {
    return <CCustomerDetailView {...props} />
}
