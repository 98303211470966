import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import React, { VFC } from 'react'

import { CCheckBox } from '../cCheckBox/CCheckBox'

export interface CCheckBoxItemInterface {
    [key: string]: unknown
}

const gridClass = {
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
    8: 'grid-cols-8',
    10: 'grid-cols-10',
    12: 'grid-cols-12',
}

export type CCheckBoxListViewProps = {
    onChangeCheckBox: (value: string | number | readonly string[] | undefined, checked: boolean) => void
} & CCheckBoxListProps

export const CCheckBoxListView: VFC<CCheckBoxListViewProps> = ({
    disabled,
    horizontal,
    items = [],
    dataLabel = 'label',
    dataValue = null,
    onChangeCheckBox,
    className,
    checked,
    grid,
}) => {
    const checkBoxList = items.map((data, index) => {
        const label = (dataLabel ? data[dataLabel] : data.label ? data.label : data.value) as string
        const value = (dataValue ? data[dataValue] : data.value) as string | number | readonly string[] | undefined
        const attention = data['attention'] ? (data['attention'] as boolean) : undefined
        return (
            <CCheckBox
                key={index}
                label={label}
                value={value}
                checked={checked ? checked.includes(value) : false}
                disabled={disabled}
                attention={attention}
                className={clsx(className, horizontal && ['mr-2'])}
                onChange={(e) => onChangeCheckBox(value, e.target.checked)}
            />
        )
    })

    return grid ? (
        <div className={clsx(['grid', gridClass[grid], 'gap-x-3'])}>{checkBoxList}</div>
    ) : (
        <div className={clsx(horizontal && ['flex', 'flex-wrap'])}>{checkBoxList}</div>
    )
}

export type CCheckBoxListProps = {
    className?: string
    // 無効状態か否か
    disabled?: boolean
    // 横配置
    horizontal?: boolean
    // 整列配置
    grid?: keyof typeof gridClass
    // データソース
    items: Array<CCheckBoxItemInterface>
    // ラベルキー
    dataLabel?: string
    // 値キー
    dataValue?: string | null
    checked?: Array<string | number | readonly string[] | undefined>
    onChange: (value: Array<unknown>) => void
}

export const CCheckBoxList: VFC<CCheckBoxListProps> = ({ ...props }) => {
    const onChangeCheckBox = (value: string | number | readonly string[] | undefined, checked: boolean) => {
        if (!props.checked) return

        let newCheckedItems = cloneDeep(props.checked)
        if (checked) newCheckedItems.push(value)
        else newCheckedItems = newCheckedItems.filter((i) => i !== value)
        props.onChange(newCheckedItems)
    }
    return <CCheckBoxListView {...props} onChangeCheckBox={onChangeCheckBox} />
}
