import { LoadScript } from '@react-google-maps/api'
import { ReactNode } from 'react'

type Props = {
    children: ReactNode
}

// const ErrorComponent = () => <div>google maps error!</div>
// const Spinner = () => <div>Loading google maps...</div>

const GoogleMapWrapper = ({ children }: Props) => {
    return (
        <LoadScript googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY} libraries={['places']}>
            {children}
        </LoadScript>
    )
}

export default GoogleMapWrapper
