import { ReactNode, useEffect, VFC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useClientAuthClientMeQuery } from '~/app/api'
import apiBase from '~/app/apiBase'
import { selectCredential, setCredentials } from '~/util/store/authSlice'
import { useAppDispatch, useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, setLoginUser } from '~/util/store/userSlice'

export type RouteClientAuthGuardProps = {
    children?: ReactNode
}

export const RouteClientAuthGuard: VFC<RouteClientAuthGuardProps> = ({ ...props }: RouteClientAuthGuardProps) => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const user = useAppSelector(selectLoginUser)
    const cred = useAppSelector(selectCredential)

    const { data: teamMember, isLoading, isError } = useClientAuthClientMeQuery(undefined, { skip: !!user && !!cred })
    useEffect(() => {
        if (!isLoading && teamMember) dispatch(setLoginUser(teamMember))
    }, [isLoading, teamMember])

    useEffect(() => {
        if (isError) {
            dispatch(apiBase.util.resetApiState()) // RTK Queryのキャッシュクリア
            if (user || cred) {
                dispatch(setCredentials({ credential: undefined }))
                dispatch(setLoginUser(undefined))
            }
            navigate('/user/signin', { state: { from: location } })
        }
    }, [isError])

    /** render */
    const loginJsx = <>{props.children}</>
    if (user && cred) return loginJsx
    if (isLoading) return <></>
    return loginJsx
}
