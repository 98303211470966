import {
    Action,
    combineReducers,
    configureStore,
    isRejectedWithValue,
    type Middleware,
    type MiddlewareAPI,
    ThunkAction,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import toast from 'react-hot-toast/headless'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session'

import apiBase from '~/app/apiBase'

import authReducer, { setCredentials } from './authSlice'
import intermediaryReducer from './intermediarySlice'
import mapReducer from './mapSlice'
import UIReducer, { rtkQueryLoading } from './UISlice'
import userReducer, { setLoginUser } from './userSlice'

// 永続化の設定
const persistConfig = {
    key: 'kimar',
    storage: storage,
    blacklist: ['user', 'auth', 'intermediary', 'map'],
}

// Credは永続化
const authConfig = {
    key: 'auth',
    storage: storage,
}

// ユーザー情報はSession管理
const userConfig = {
    key: 'user',
    storage: storageSession,
}

// ユーザー情報はSession管理
const intermediaryConfig = {
    key: 'intermediary',
    storage: storageSession,
}

const mapConfig = {
    key: 'map',
    storage: storageSession,
}

const reducers = combineReducers({
    auth: persistReducer(authConfig, authReducer),
    user: persistReducer(userConfig, userReducer),
    intermediary: persistReducer(intermediaryConfig, intermediaryReducer),
    map: persistReducer(mapConfig, mapReducer),
})

const persistedReducer = persistReducer(persistConfig, reducers)

/**
 * [RTK Query] Handling errors at a macro level
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 401) {
            const excludeUrls = ['/api/auth/signin', '/api/client/intermediary_to/']
            // ログインAPI＆紹介ページでのエラーはアラート出さない
            if (excludeUrls.find((e) => !!(action.payload.data?.path as string | undefined)?.match(e))) return next(action)

            // TODO: ログイン画面にリダイレクト
            if (action.payload?.data?.errorCode === 100040) {
                // セッション（トークン）切れ
                toast.error('セッションがタイムアウトしました。再度ログインしてください。', {
                    id: String(action.payload?.data?.errorCode),
                })
                api.dispatch(setCredentials({ credential: undefined }))
                api.dispatch(setLoginUser(undefined))
            } else {
                const message = action.payload?.data?.message
                    ? action.payload.data.message
                    : action.error?.message && 'エラーが発生しました'
                toast.error(message, { id: message })
            }
        } else {
            // 未ログイン時はリダイレクトなので別処理
            const excludeUrls = ['/api/auth/me']
            // 除外パスならトースト出さない
            if (excludeUrls.find((e) => !!(action.payload.data?.path as string | undefined)?.match(e))) return next(action)

            const message = action.payload?.data?.message
                ? action.payload.data.message
                : action.error?.message && 'エラーが発生しました'
            toast.error(message, { id: message })
        }
    }
    return next(action)
}

export const store = configureStore({
    reducer: {
        // 永続化store
        persistedReducer,
        // API
        [apiBase.reducerPath]: apiBase.reducer,
        // ローディング表示
        UIReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat([rtkQueryErrorLogger, rtkQueryLoading])
            .concat(apiBase.middleware),
})

setupListeners(store.dispatch)
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
