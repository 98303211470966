import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { last } from 'lodash'
import { Ref, useEffect, useMemo, useRef, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import {
    ClientCustomerInsertDto,
    ClientCustomerUpdateDto,
    CustomerEntities,
    useClientCustomerPostCustomerMutation,
    useClientCustomerPutUuidMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { ApplicationError, IApplicationError } from '~/types/error'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUserHaveRecommendContract } from '~/util/store/userSlice'

import CConditionForm from '../../form/CConditionForm'

export type CCustomerEditViewProps = {
    form: ClientCustomerInsertDto | ClientCustomerUpdateDto
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (entity: string, key: string, value: unknown) => void
    scrollRef: Ref<HTMLDivElement>
    disableSubmit: boolean
    errors: IApplicationError[]
} & CCustomerEditProps

export const CCustomerEditView: VFC<CCustomerEditViewProps> = ({
    visible,
    form,
    onRequestClose,
    onRequestConfirm,
    setProps,
    scrollRef,
    disableSubmit,
    errors,
    customer,
}) => {
    const isRecommendContract = useAppSelector(selectLoginUserHaveRecommendContract)
    const footer = (
        <>
            <CButton className={clsx('c-button-secondary')} onClick={() => onRequestClose()}>
                {t('Button.キャンセル')}
            </CButton>
            <CButton disabled={disableSubmit} className={clsx('c-button-primary')} onClick={() => onRequestConfirm()}>
                {customer ? t('CCustomerEdit.編集確定') : t('CCustomerEdit.登録確定')}
            </CButton>
        </>
    )
    return (
        <CFooterModal
            footer={footer}
            visible={visible}
            content={{ width: '800px' }}
            onRequestClose={() => onRequestClose()}
            closeOnOverlayClick={false}>
            <div>
                <div className={clsx('px-8', 'py-2')}>
                    <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>
                        {customer ? t('CCustomerEdit.顧客を編集') : t('CCustomerEdit.顧客を登録')}
                    </div>
                    <div ref={scrollRef} />
                    <CError errors={errors} />
                    <div className={clsx('text-gray-700', 'font-bold', 'my-4')}>{t('CCustomerEdit.顧客情報')}</div>
                    <div>
                        {!customer ? (
                            <CLabeledItem label={t('CCustomerEdit.メールアドレス')} required>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={(form as ClientCustomerInsertDto).customer.email}
                                    onChange={(value) => setProps('customer', 'email', value)}
                                    placeholder="mail@example.com"
                                />
                            </CLabeledItem>
                        ) : (
                            <CLabeledItem label={t('CCustomerEdit.メールアドレス')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base', 'bg-gray-100', 'text-gray-700')}
                                    type="text"
                                    text={customer.email}
                                    readonly
                                />
                            </CLabeledItem>
                        )}
                    </div>
                    <div className={clsx('grid', 'grid-cols-2', 'gap-1')}>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.名前')} required>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.name}
                                    onChange={(value) => setProps('customer', 'name', value)}
                                />
                            </CLabeledItem>
                        </div>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.名前（かな）')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.nameSound}
                                    onChange={(value) => setProps('customer', 'nameSound', value)}
                                />
                            </CLabeledItem>
                        </div>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.紹介時宛名')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.intermediaryName}
                                    onChange={(value) => setProps('customer', 'intermediaryName', value)}
                                />
                            </CLabeledItem>
                        </div>
                    </div>
                    <div className={clsx('grid', 'grid-cols-2', 'gap-1')}>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.役職')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.position}
                                    onChange={(value) => setProps('customer', 'position', value)}
                                />
                            </CLabeledItem>
                        </div>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.部署')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.departmentName}
                                    onChange={(value) => setProps('customer', 'departmentName', value)}
                                />
                            </CLabeledItem>
                        </div>
                    </div>
                    <div className={clsx('grid', 'grid-cols-2', 'gap-1')}>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.電話番号')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.phone}
                                    onChange={(value) => setProps('customer', 'phone', value)}
                                />
                            </CLabeledItem>
                        </div>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.内線番号')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.phoneExtension}
                                    onChange={(value) => setProps('customer', 'phoneExtension', value)}
                                />
                            </CLabeledItem>
                        </div>
                    </div>
                    <div className={clsx('grid', 'grid-cols-1')}>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.携帯電話')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.mobile}
                                    onChange={(value) => setProps('customer', 'mobile', value)}
                                />
                            </CLabeledItem>
                        </div>
                    </div>
                    <div className={clsx('text-gray-700', 'font-bold', 'my-4')}>{t('CCustomerEdit.会社情報')}</div>
                    <div className={clsx('grid', 'grid-cols-2', 'gap-1')}>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.会社名')} required>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.companyName}
                                    onChange={(value) => setProps('customer', 'companyName', value)}
                                />
                            </CLabeledItem>
                        </div>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.会社名（かな）')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.companyNameSound}
                                    onChange={(value) => setProps('customer', 'companyNameSound', value)}
                                />
                            </CLabeledItem>
                        </div>
                    </div>
                    <div className={clsx('grid', 'grid-cols-1')}>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.住所')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.companyAddress}
                                    onChange={(value) => setProps('customer', 'companyAddress', value)}
                                />
                            </CLabeledItem>
                        </div>
                    </div>
                    <div className={clsx('grid', 'grid-cols-2', 'gap-1')}>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.代表電話番号')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.companyPhone}
                                    onChange={(value) => setProps('customer', 'companyPhone', value)}
                                />
                            </CLabeledItem>
                        </div>
                        <div>
                            <CLabeledItem label={t('CCustomerEdit.代表FAX番号')}>
                                <CTextInput
                                    className={clsx('w-full', 'c-text-input-base')}
                                    type="text"
                                    text={form.customer.companyFax}
                                    onChange={(value) => setProps('customer', 'companyFax', value)}
                                />
                            </CLabeledItem>
                        </div>
                    </div>
                    {/* レコメンド契約がある場合 */}
                    {isRecommendContract && (
                        <>
                            <div className={clsx('text-gray-700', 'font-bold', 'my-4')}>{t('CCustomerEdit.マーケット設定')}</div>
                            <div className={clsx('border-l', 'flex', 'items-end', 'justify-between', 'mb-4', 'pl-4', 'w-full')}>
                                <div className={clsx('mr-4')}>{t('CCustomerEdit.この顧客をレコメンド対象にしますか？')}</div>
                                <CToggleSwitch
                                    width="base"
                                    value={form.customer.isRecommendTarget}
                                    trueLabel={t('CCustomerEdit.YES')}
                                    falseLabel={t('CCustomerEdit.NO')}
                                    toggleChanged={(value) => {
                                        setProps('customer', 'isRecommendTarget', value)
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <div className={clsx('text-gray-700', 'font-bold', 'my-4')}>{t('CCustomerEdit.購入条件')}</div>
                    <CConditionForm
                        formDto={form.investmentCondition}
                        requiredContent={
                            customer?.investmentConditionRequests?.find((i) => i.answeredAt)
                                ? []
                                : form.customer.isRecommendTarget
                                ? ['useType', 'area', 'prices', 'buildingAgeType', 'purchaseTimeType']
                                : []
                        }
                        changeHandler={(key, value) => setProps('investmentCondition', key, value)}
                    />
                </div>
            </div>
        </CFooterModal>
    )
}

export type CCustomerEditProps = {
    visible: boolean
    customer?: CustomerEntities
    onClose: () => void
    onSubmit: (customer: CustomerEntities) => void
}

export const CCustomerEdit: VFC<CCustomerEditProps> = ({ ...props }) => {
    const [visible, setVisible] = useState(props.visible)
    const [form, setForm] = useState<ClientCustomerInsertDto | (ClientCustomerUpdateDto & { customer: { email?: string } })>()
    useEffect(() => {
        setErrors([])
        setForm({
            customer: {
                name: props.customer?.name ?? '',
                nameSound: props.customer?.nameSound ?? '',
                intermediaryName: props.customer?.intermediaryName ?? '',
                email: props.customer?.email ?? '',
                position: props.customer?.position ?? '',
                departmentName: props.customer?.departmentName ?? '',
                phone: props.customer?.phone ?? '',
                phoneExtension: props.customer?.phoneExtension ?? '',
                mobile: props.customer?.mobile ?? '',
                companyName: props.customer?.companyName ?? '',
                companyNameSound: props.customer?.companyNameSound ?? '',
                companyAddress: props.customer?.companyAddress ?? '',
                companyPhone: props.customer?.companyPhone ?? '',
                companyFax: props.customer?.companyFax ?? '',
                isRecommendTarget: props.customer?.isRecommendTarget ?? false,
            },
            investmentCondition: {
                useType: last(props.customer?.investmentConditions)?.useType ?? [],
                area: last(props.customer?.investmentConditions)?.area ?? [],
                prices: last(props.customer?.investmentConditions)?.prices ?? [],
                buildingAgeType: last(props.customer?.investmentConditions)?.buildingAgeType ?? 0,
                purchaseTimeType: last(props.customer?.investmentConditions)?.purchaseTimeType ?? 0,
                grossRateType: last(props.customer?.investmentConditions)?.grossRateType ?? 0,
                netRateType: last(props.customer?.investmentConditions)?.netRateType ?? 0,
                ownershipType: last(props.customer?.investmentConditions)?.ownershipType ?? 0,
                seismicStandardType: last(props.customer?.investmentConditions)?.seismicStandardType ?? 0,
                legalComplianceType: last(props.customer?.investmentConditions)?.legalComplianceType ?? 0,
            },
        })
        setVisible(props.visible)
    }, [props.visible])

    const setProps = (entity: string, key: string, value: unknown) => {
        if (!form) return
        setForm({
            ...form,
            [entity]: {
                ...form[entity as 'customer' | 'investmentCondition'],
                [key]: value,
            },
        })
    }

    const onRequestClose = () => {
        props.onClose()
    }

    const [insertCustomerQuery] = useClientCustomerPostCustomerMutation()
    const [updateCustomerQuery] = useClientCustomerPutUuidMutation()
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const onRequestConfirm = async () => {
        if (!form) return
        try {
            setErrors([])
            if (!props.customer) {
                const customer = await insertCustomerQuery({ clientCustomerInsertDto: form as ClientCustomerInsertDto }).unwrap()
                props.onSubmit(customer)
                toast.success(t('CCustomerEdit.顧客を登録しました'))
            } else {
                const customer = await updateCustomerQuery({
                    uuid: props.customer.uuid,
                    clientCustomerUpdateDto: form as ClientCustomerUpdateDto,
                }).unwrap()
                props.onSubmit(customer)
                toast.success(t('CCustomerEdit.顧客を編集しました'))
            }
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            scrollTop()
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const scrollRef = useRef<HTMLDivElement>(null)
    const scrollTop = () => {
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const disableSubmit = useMemo(() => {
        let noValidCondition = false
        if (!props.customer?.investmentConditionRequests?.find((i) => i.answeredAt)) {
            noValidCondition =
                !form ||
                !form.investmentCondition.useType.length ||
                !form.investmentCondition.area.length ||
                !form.investmentCondition.prices.length ||
                !form.investmentCondition.buildingAgeType ||
                !form.investmentCondition.purchaseTimeType
        }

        return !form || !form.customer.name || !form.customer.companyName || (form.customer.isRecommendTarget && noValidCondition)
    }, [form])

    if (!form) return null
    return (
        <CCustomerEditView
            {...props}
            visible={visible}
            form={form}
            scrollRef={scrollRef}
            onRequestConfirm={onRequestConfirm}
            onRequestClose={onRequestClose}
            setProps={setProps}
            disableSubmit={disableSubmit}
            errors={errors}
        />
    )
}
