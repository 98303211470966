import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast/headless'
import { Trans } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'

import {
    ClientRealEstatePublishRequestResponseDto,
    RealEstateEntities,
    RealEstateUseTypeEntities,
    useClientRealEstateMarketPutMarketOpenMutation,
    useClientRealEstateMarketPutRequestCheckMutation,
    useClientRealEstateMarketRequestQuery,
    useClientRealEstateUseTypeRealEstateUseTypeQuery,
} from '~/app/api'
import { CError } from '~/components/common/cError/CError'
import CInquiryButton from '~/components/common/cInquiryButton/CInquiryButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { ApplicationError, IApplicationError } from '~/types/error'
import { formatDateTime } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, selectLoginUserHaveMarketContract } from '~/util/store/userSlice'

import {
    areaTypeFormat,
    customerBuildingAgeTypeFormat,
    customerGrossRateTypeFormat,
    customerLegalComplianceTypeFormat,
    customerNetRateTypeFormat,
    customerPriceTypeFormat,
    customerSeismicStandardTypeFormat,
    saleTimeTypeFormat,
} from '../../../../util/filter/format'
import { CButton } from '../../../common/cButton/CButton'
import { CSortButton } from '../../../common/cSortButton/CSortButton'
import { CTag } from '../../../common/cTag/CTag'

type Props = {
    realEstate: RealEstateEntities
    setOpenEditModal: (value: boolean) => void
}

const CRealestateDetailMarket = ({ realEstate, setOpenEditModal }: Props) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const user = useAppSelector(selectLoginUser)
    const navigate = useNavigate()
    const isMarketContract = useAppSelector(selectLoginUserHaveMarketContract)
    const { data: requests, isLoading: isLoadingRequests } = useClientRealEstateMarketRequestQuery({ uuid: realEstate.uuid })
    const { data: useTypeListCount } = useClientRealEstateUseTypeRealEstateUseTypeQuery({})
    // モーダル表示
    const [visibleSync, setVisibleSync] = useState(false)
    // 会社名/氏名確認候補のリクエスト
    const [openNameConfirmMarket, setOpenNameConfirmMarket] = useState<ClientRealEstatePublishRequestResponseDto | undefined>(
        undefined,
    )
    // 会社名/氏名確認API
    const [marketOpenAPI] = useClientRealEstateMarketPutMarketOpenMutation()
    // 開示リクエスト閲覧API
    const [requestCheckAPI] = useClientRealEstateMarketPutRequestCheckMutation()
    // 開示リクエストを見たことを送信
    useEffect(() => {
        requestCheckAPI({ uuid: realEstate.uuid })
    }, [realEstate.uuid])

    // 検索フィルタ
    const [filter, setFilter] = useState({
        order: 'requestedAt',
        sort: 'DESC' as 'ASC' | 'DESC',
    })
    const onChange = (v: typeof filter.order) => {
        setFilter((oldValue) => ({
            ...oldValue,
            order: v,
        }))
    }
    const onChangeSort = () => {
        setFilter((oldValue) => ({
            ...oldValue,
            sort: oldValue.sort == 'ASC' ? 'DESC' : 'ASC',
        }))
    }

    // ソート後の開示リクエスト
    const marketRequests = requests?.list
        ? [...requests.list].sort((a, b) => {
              // 情報開示依頼日
              if (filter.order === 'requestedAt') return dayjs(a.requestedAt).diff(dayjs(b.requestedAt), 'second')
              return 0
          })
        : undefined
    if (filter.sort === 'DESC') marketRequests?.reverse()

    // 会社名/氏名の確認可能数
    const availableOpenCount = requests ? requests.openLimit - requests.openedSum : 0

    // 「会社名/氏名を確認」ボタンを表示するか
    const canDisplayOpenButton = (market: ClientRealEstatePublishRequestResponseDto) => {
        // 会社名/氏名確認済みの場合は会社名/氏名確認ボタンを押せない
        // 顧客として登録済みの場合も会社名/氏名はオープン状態でサーバから返ってくるため、このif文で事足りる
        if (market.requestedName || market.requestedCompanyName) return false

        return true
    }

    // 「会社名/氏名を確認」ボタンを押下可能状態にするか
    const canClickOpenButton = () => {
        // 紹介権限がない場合はボタンを押下できない
        if (!user?.roles.intermediary || user?.roles.intermediary <= 1) return false

        // オープン件数上限に達している場合は押下できない
        if (availableOpenCount <= 0) return false
        return true
    }

    // 会社名/氏名を確認ボタンハンドラ
    const openNameButtonHandler = (market: ClientRealEstatePublishRequestResponseDto) => {
        setVisibleSync(true)
        setOpenNameConfirmMarket(market)
    }

    // 会社名/氏名確認処理
    const openName = async () => {
        try {
            setErrors([])
            if (!openNameConfirmMarket || !openNameConfirmMarket.uuid) {
                throw new ApplicationError(
                    t('CRealestateDetailMarket.会社名/氏名を確認する開示リクエストデータが選択されていません。'),
                )
            }
            await marketOpenAPI({ uuid: openNameConfirmMarket.uuid! }).unwrap()
            toast.success(t('CRealestateDetailMarket.リクエスト元の会社名/氏名を表示しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleSync(false)
            setOpenNameConfirmMarket(undefined)
        }
    }

    // 物件紹介ボタンを押せるか
    const canClickPublishButton = (market: ClientRealEstatePublishRequestResponseDto) => {
        // 会社名/氏名未確認の場合は物件紹介ボタンを押せない
        if (!(market.requestedName || market.requestedCompanyName)) return false

        // 開示依頼者が削除済みメンバーの場合、物件紹介ボタンではなく退会済みボタンとなるので、押せなくする
        if (market.isCancelledMember) return false

        // 紹介権限がない場合は物件紹介ボタンを押せない
        if (!user?.roles.intermediary || user?.roles.intermediary <= 1) return false

        return true
    }

    const buttonDom = (market: ClientRealEstatePublishRequestResponseDto) => {
        if (canDisplayOpenButton(market)) {
            return (
                <CButton
                    className={clsx('c-button-primary', 'whitespace-nowrap')}
                    disabled={!isMarketContract || !canClickOpenButton()}
                    onClick={() => {
                        openNameButtonHandler(market)
                    }}>
                    {t('CRealestateDetailMarket.会社名/氏名を確認')}
                </CButton>
            )
        } else if (!market.publishedAt) {
            // 会社名/氏名はopen状態だが情報開示をまだしていない時のみボタンを出す
            return (
                <CButton
                    className={clsx('c-button-primary', 'whitespace-nowrap')}
                    disabled={!isMarketContract || !canClickPublishButton(market)}
                    onClick={() => {
                        navigate({
                            pathname: `/realestate/${realEstate.uuid}/intermediary`,
                            search: createSearchParams({
                                markets: [market.uuid ?? ''],
                            }).toString(),
                        })
                    }}>
                    {market.isCancelledMember ? t('CRealestateDetailMarket.退会済み') : t('CRealestateDetailMarket.物件紹介')}
                </CButton>
            )
        } else if (market.publishedAt) {
            return (
                <CButton className={clsx('c-button-primary', 'whitespace-nowrap')} disabled>
                    {market.isCancelledMember ? t('CRealestateDetailMarket.退会済み') : t('CRealestateDetailMarket.紹介済み')}
                </CButton>
            )
        }
    }

    const description = () => {
        if (realEstate.markets?.length && !isLoadingRequests) {
            if (requests?.count) {
                if (isMarketContract) {
                    // 現在もマーケット契約がある場合
                    return (
                        <div className={clsx('text-center')}>
                            <div>
                                {t(
                                    'CRealestateDetailMarket.あなたの物件に興味を持っている新規のお客様から情報開示依頼がcount件届いています。',
                                    { count: requests?.count },
                                )}
                            </div>
                            <div>{t('CRealestateDetailMarket.会社名/氏名を確認して物件を紹介してみましょう。')}</div>
                        </div>
                    )
                } else {
                    // 現在はマーケット契約がなくなっている場合
                    return (
                        <div className={clsx('text-center')}>
                            <div>
                                {t(
                                    'CRealestateDetailMarket.あなたの物件に興味を持っている新規のお客様から情報開示依頼がcount件届いています。',
                                    { count: requests?.count },
                                )}
                            </div>
                        </div>
                    )
                }
            } else {
                // 掲載しているが開示依頼0件の場合
                if (realEstate.markets) {
                    return (
                        <div className={clsx('text-center')}>
                            <div>
                                {t(
                                    'CRealestateDetailMarket.あなたの物件に興味を持ちそうな新規のお客様はまだ見つかっていません。',
                                )}
                            </div>
                            <div>
                                {t(
                                    'CRealestateDetailMarket.物件概要で未入力の項目を入力することで情報開示依頼が増えることがあります。',
                                )}
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    return (
        <div>
            {/* 冒頭文言 */}
            {description()}
            <CError errors={errors} />
            {!isMarketContract ? (
                // マーケット契約なし
                <div className={clsx('text-center', 'bg-white', 'm-4')}>
                    <CInquiryButton>
                        <p>{t('CRealestateDetailMarket.ご契約のプランではマーケットはお使いいただけません')}</p>
                    </CInquiryButton>
                </div>
            ) : realEstate.markets?.length === 0 ? (
                // マーケットへの掲載なし
                <div className={clsx('mt-4')}>
                    <CMessage warning>
                        <div className={clsx('flex', 'justify-between', 'items-center')}>
                            <div>{t('CRealestateDetailMarket.この物件はマーケットに掲載されていません')}</div>
                            <div>
                                <CButton
                                    disabled={user?.roles.realestate !== 2}
                                    className={clsx('text-sm', 'c-button-primary')}
                                    onClick={() => {
                                        setOpenEditModal(true)
                                    }}>
                                    {t('CRealestateDetailMarket.マーケット利用の設定')}
                                </CButton>
                            </div>
                        </div>
                    </CMessage>
                </div>
            ) : isLoadingRequests ? (
                // 読み込み中
                <div className={clsx('mt-4')}>
                    <CMessage info>{t('CRealestateDetailMarket.情報開示依頼を読込中です')}</CMessage>
                </div>
            ) : (
                requests?.count === 0 && (
                    // 開示依頼0件
                    <div className={clsx('mt-4')}>
                        <CMessage info>{t('CRealestateDetailMarket.情報開示依頼はありません')}</CMessage>
                    </div>
                )
            )}
            {/* 開示依頼あり */}
            {/* マーケット契約が今なくなっていても開示依頼が存在するなら表示する */}
            {!isLoadingRequests && requests && requests.count > 0 && (
                <div>
                    {/* 残件数表示 */}
                    <div className={clsx('text-center', 'border', 'border-kimar-primary', 'rounded', 'p-2', 'bg-white', 'm-2')}>
                        <div className={clsx('text-sm')}>
                            {availableOpenCount ? (
                                <Trans
                                    i18nKey="CRealestateDetailMarket.あとavailableOpenCount件会社名/氏名を表示できます"
                                    availableOpenCount={availableOpenCount}>
                                    あと
                                    <span className={clsx('text-2xl')}>
                                        <>{{ availableOpenCount }}</>
                                    </span>
                                    件<span className={clsx('text-kimar-primary', 'font-bold')}>会社名/氏名を表示</span>
                                    できます
                                </Trans>
                            ) : (
                                t('CRealestateDetailMarket.会社名/氏名の確認可能件数が上限に達しました')
                            )}
                        </div>
                        <div className={clsx('text-xs', 'text-gray-700')}>
                            {t(
                                'CRealestateDetailMarket.あと request件情報開示リクエストが届くと、会社名/氏名確認可能件数が open件に増えます。',
                                { request: requests?.remainingRequestCount, open: availableOpenCount + 1 },
                            )}
                        </div>
                    </div>
                    {/* ソートボタン */}
                    <div className={clsx('flex', 'items-center')}>
                        <div className={clsx('flex')}>
                            <div className={clsx('p-2')}>
                                <CSortButton
                                    className={clsx('border-gray-300')}
                                    items={[
                                        {
                                            label: t('CRealestateDetailMarket.開示依頼日時'),
                                            value: 'requestedAt',
                                        },
                                    ]}
                                    sort={filter.sort}
                                    onChange={(v) => {
                                        onChange(v as 'requestedAt')
                                    }}
                                    onClickSort={onChangeSort}
                                />
                            </div>
                        </div>
                    </div>
                    <ul>
                        {marketRequests?.map((item, index) => (
                            <li key={index} className={clsx('m-2', 'border-t')}>
                                {/* 顧客名・タグ・ボタン */}
                                <div className={clsx('flex', 'justify-between', 'items-center', 'py-2')}>
                                    {/* 顧客名・タグ */}
                                    <div className={clsx('flex')}>
                                        {item.requestedName && item.requestedCompanyName ? (
                                            // 開示済み、または顧客登録済の場合の表示
                                            <div className={clsx('leading-5', 'break-all')}>
                                                {item.requestedName} ({item.requestedCompanyName})
                                                {item.isRegisteredCustomer && (
                                                    <CTag
                                                        className={clsx(
                                                            'rounded-md',
                                                            'bg-[#EA580C]',
                                                            'mx-1',
                                                            'px-2',
                                                            'text-white',
                                                            'text-xs',
                                                        )}>
                                                        {t('CRealestateDetailMarket.顧客登録済')}
                                                    </CTag>
                                                )}
                                                {!item.checkedAt && (
                                                    <CTag
                                                        className={clsx(
                                                            'rounded-full',
                                                            'bg-green-500',
                                                            'px-2',
                                                            'text-white',
                                                            'text-xs',
                                                        )}>
                                                        {t('CRealestateDetailMarket.NEW')}
                                                    </CTag>
                                                )}
                                            </div>
                                        ) : (
                                            // 未開示の場合の表示
                                            <div className={clsx('leading-5', 'break-all')}>
                                                <div className={clsx('inline-flex', 'whitespace-nowrap')}>
                                                    <img
                                                        src="/src/assets/image/page/market/hide_member_name.png"
                                                        alt="ダミー会社名/氏名"
                                                        className={clsx('w-1/2')}
                                                    />
                                                    <div className={clsx('align-middle')}>
                                                        <i
                                                            className={clsx(
                                                                'material-icons',
                                                                'inline-flex',
                                                                'align-middle',
                                                                'text-kimar-accent',
                                                            )}>
                                                            lock
                                                        </i>
                                                        {t('CRealestateDetailMarket.会社名/氏名 非表示中')}
                                                    </div>
                                                </div>
                                                {!item.checkedAt && (
                                                    <CTag
                                                        className={clsx(
                                                            'rounded-full',
                                                            'bg-green-500',
                                                            'px-2',
                                                            'text-white',
                                                            'text-xs',
                                                        )}>
                                                        {t('CRealestateDetailMarket.NEW')}
                                                    </CTag>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {/* ボタン */}
                                    <div>{buttonDom(item)}</div>
                                </div>
                                {/* 日時表示 */}
                                <div>
                                    <div>
                                        {t('CRealestateDetailMarket.開示依頼日時')}:{' '}
                                        {formatDateTime(item.requestedAt, 'dateTime')}
                                    </div>
                                    <div>
                                        {t('CRealestateDetailMarket.開示日時')}:{' '}
                                        {item.publishedAt
                                            ? formatDateTime(item.publishedAt, 'dateTime')
                                            : t('CRealestateDetailMarket.未開示')}
                                    </div>
                                </div>
                                {/* 購入条件表 */}
                                <div className={clsx('grid', 'grid-cols-2', 'sm:grid-cols-4', 'gap-y-1', 'text-sm', 'p-1')}>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailMarket.種別')}</div>
                                    <div className={clsx('sm:col-span-3', 'border-b')}>
                                        <span>
                                            {/* TODO: 種別表示 */}
                                            {item.purchaseCondition?.useType
                                                .map(
                                                    (u) =>
                                                        (useTypeListCount?.list as RealEstateUseTypeEntities[] | undefined)?.find(
                                                            (t) => t.uuid === u,
                                                        )?.name,
                                                )
                                                .sort()
                                                .join('、')}
                                        </span>
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailMarket.エリア')}</div>
                                    <div className={clsx('sm:col-span-3', 'border-b')}>
                                        <span>
                                            {item.purchaseCondition?.area
                                                .map((typeNumber) => areaTypeFormat(typeNumber))
                                                .join('、')}
                                        </span>
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailMarket.金額')}</div>
                                    <div className={clsx('border-b')}>
                                        {item.purchaseCondition?.prices
                                            ?.map((typeNumber) => customerPriceTypeFormat(typeNumber))
                                            .join('、')}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailMarket.築年数')}</div>
                                    <div className={clsx('border-b')}>
                                        {customerBuildingAgeTypeFormat(item.purchaseCondition?.buildingAgeType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailMarket.表面利回り')}</div>
                                    <div className={clsx('border-b')}>
                                        {customerGrossRateTypeFormat(item.purchaseCondition?.grossRateType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailMarket.実質利回り')}</div>
                                    <div className={clsx('border-b')}>
                                        {customerNetRateTypeFormat(item.purchaseCondition?.netRateType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailMarket.購入時期')}</div>
                                    <div className={clsx('border-b')}>
                                        {saleTimeTypeFormat(item.purchaseCondition?.purchaseTimeType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailMarket.耐震基準')}</div>
                                    <div className={clsx('border-b')}>
                                        {customerSeismicStandardTypeFormat(item.purchaseCondition?.seismicStandardType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailMarket.遵法性')}</div>
                                    <div className={clsx('border-b')}>
                                        {customerLegalComplianceTypeFormat(item.purchaseCondition?.legalComplianceType)}
                                    </div>
                                    <div className={clsx('font-bold', 'border-b')}>{t('CRealestateDetailMarket.コメント')}</div>
                                    <div className={clsx('border-b')}>{item.comment ?? '-'}</div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <CConfirmModal
                visible={visibleSync}
                onRequestClose={() => setVisibleSync(false)}
                onRequestConfirm={() => openName()}
                confirmLabel={t('Button.送信')}>
                <p>{t('CRealestateDetailMarket.会社名/氏名を表示します。表示には残り回数を使用します。よろしいですか？')}</p>
                <p>{t('CRealestateDetailMarket.(会社名/氏名 確認可能件数 残りcount件)', { count: availableOpenCount })}</p>
            </CConfirmModal>
        </div>
    )
}

export default CRealestateDetailMarket
