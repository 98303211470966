import clsx from 'clsx'
import { t } from 'i18next'
import { last, sortBy, uniqBy } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import {
    CustomerEntities,
    CustomerLabelEntities,
    RealEstateEntities,
    useClientCustomerFilterFindCustomersByFiltersAndRealEstateQuery,
    useClientCustomerLabelTeamUuidQuery,
    useClientCustomerUuidQuery,
} from '~/app/api'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CCheckBoxList } from '~/components/common/cCheckBoxList/CCheckBoxList'
import { CGuideTips } from '~/components/common/cGuideTips/CGuideTips'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import CMessage from '~/components/common/cMessage/CMessage'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { CTagList } from '~/components/common/cTagList/CTagList'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { taxType } from '~/types/enum/enum'
import { formatDateTime } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, selectLoginUserTeam } from '~/util/store/userSlice'

import { priceFormat } from '../../../../util/filter/format'
import { CButton } from '../../../common/cButton/CButton'
import CCustomerDetailInfo from '../../customer/detail/CCustomerDetailInfo'

type Props = {
    realEstate: RealEstateEntities
}

const CRealestateDetailFilteredCustomer = ({ realEstate }: Props) => {
    const user = useAppSelector(selectLoginUser)
    const userTeam = useAppSelector(selectLoginUserTeam)
    const navigate = useNavigate()

    const { data: customerList, isLoading: isLoadingCustomerList } =
        useClientCustomerFilterFindCustomersByFiltersAndRealEstateQuery({
            uuid: realEstate.uuid,
        })

    const { data: labelListCount } = useClientCustomerLabelTeamUuidQuery({ uuid: userTeam?.uuid ?? '' }, { skip: !userTeam })

    // 選択中のレコメンドリスト
    const [checkedCustomerList, setCheckedCustomerList] = useState<Array<CustomerEntities>>([])
    // 顧客詳細モーダル
    const [visibleCustomerDetailSync, setVisibleCustomerDetailSync] = useState(false)
    // 顧客詳細モーダルで表示する顧客
    const [clickedCustomer, setClickedCustomer] = useState<CustomerEntities | undefined>(undefined)

    const { data: selectedCustomer } = useClientCustomerUuidQuery(
        {
            uuid: clickedCustomer?.uuid ?? '',
        },
        { skip: !clickedCustomer },
    )

    // 資料の合計閲覧数を返す
    const getDocumentViewsCount = (item: CustomerEntities) => {
        let count: number = 0
        const intermediaryTo = last(sortBy(item.intermediaryToList, (s) => s.createdAt))
        if (!intermediaryTo) return 0
        intermediaryTo.intermediaryToDocuments?.forEach((document) => {
            count += document.previewCount + document.downloadCount
        })

        return count
    }

    // 検索フィルタ
    const [filterLabels, setFilterLabels] = useState<string[]>([])
    const [filterAndOr, setFilterAndOr] = useState<'AND' | 'OR'>('AND')
    const onChangeDetailAndOrToggle = (val: 'AND' | 'OR') => {
        setFilterAndOr(val)
    }
    const onChangeFilterCheckBox = (val: string[], target: string[]) => {
        const newFilter = filterLabels.filter((f) => !target.includes(f)).concat(val.filter((f) => target.includes(f)))
        setFilterLabels(newFilter)
    }

    const filteredCustomerList = useMemo(() => {
        return customerList?.filter((customer) => {
            // ラベルフィルタ
            if (filterAndOr === 'AND') {
                if (filterLabels.length > 0) {
                    if (filterLabels.includes('noLabels')) {
                        if ((customer.labels?.length ?? 0) > 0) return false
                    } else if (!filterLabels.every((label) => customer.labels?.find((l) => l.uuid === label))) return false
                }
            } else if (filterAndOr === 'OR') {
                if (filterLabels.length > 0) {
                    if (filterLabels.includes('noLabels')) {
                        if ((customer.labels?.length ?? 0) > 0) return false
                    } else if (!filterLabels.some((label) => customer.labels?.find((l) => l.uuid === label))) return false
                }
            }

            return true
        })
    }, [customerList, filterLabels, filterAndOr])

    const onChangeCheckBox = (customer: CustomerEntities) => {
        if (checkedCustomerList.find((c) => c.uuid === customer.uuid))
            setCheckedCustomerList((oldList) => oldList.filter((c) => c.uuid !== customer.uuid))
        else setCheckedCustomerList((oldList) => [...oldList, customer])
    }

    // 顧客名を押下したときのハンドラ
    const onClickCustomerName = (item: CustomerEntities) => {
        setClickedCustomer(item)
        setVisibleCustomerDetailSync(true)
    }

    const [isOpenFilter, setIsOpenFilter] = useState(false)
    const closeAll = () => {
        setIsOpenFilter((v) => (v ? false : v))
    }
    const filterRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!filterRef) return
        const handleClickOutside = (e: MouseEvent) => {
            if (!(filterRef.current as Node)?.contains(e.target as Node)) closeAll()
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [filterRef])

    const getLatestIntermediaryTo = (customer: CustomerEntities) => {
        return last(sortBy(customer.intermediaryToList, (s) => s.createdAt))
    }
    const allSelected = useMemo(() => {
        const enableList = filteredCustomerList ?? []
        return enableList.every((e) => checkedCustomerList.find((i) => i.uuid === e.uuid))
    }, [checkedCustomerList, filteredCustomerList])
    const checkAllCustomer = () => {
        if (allSelected) setCheckedCustomerList([])
        else setCheckedCustomerList(uniqBy([...checkedCustomerList, ...(filteredCustomerList ?? [])], (i) => i.uuid))
    }

    if (isLoadingCustomerList) {
        return (
            // 読み込み中
            <div className={clsx('mt-4')}>
                <CMessage info>{t('CRealestateDetailFilteredCustomer.読込中です')}</CMessage>
            </div>
        )
    }

    return (
        <div>
            <div>
                <div className={clsx('flex', 'flex-col', 'md:flex-row', 'justify-between', 'items-center', 'gap-2', 'my-2')}>
                    {/* ラベルフィルタ */}
                    <div ref={filterRef} className={clsx('my-2')}>
                        <div className={clsx('flex', 'justify-between', 'flex-col', 'md:flex-row', 'gap-2')}>
                            <CButton
                                className={clsx('c-button-secondary', 'text-sm')}
                                onClick={() => setIsOpenFilter(!isOpenFilter)}>
                                <i className={clsx('material-icons-outlined', 'text-sm')}>filter_alt</i>
                                <div>{t('CRealestateDetailFile.絞り込み')}</div>
                            </CButton>
                            <div
                                className={clsx(
                                    'absolute',
                                    'bg-white',
                                    'p-2',
                                    'mt-7',
                                    'shadow',
                                    'z-30',
                                    'rounded',
                                    !isOpenFilter && 'hidden',
                                    'min-w-[200px]',
                                    'border',
                                )}>
                                <CLabeledItem
                                    labelNode={
                                        <div className={clsx('w-full', 'flex', 'justify-between')}>
                                            <div>{t('CRealestateDetailFilteredCustomer.顧客ラベル')}</div>
                                            <div className={clsx('flex', 'gap-1')}>
                                                <CToggleSwitch
                                                    trueLabel={t('CRealestateDetailFilteredCustomer.AND')}
                                                    falseLabel={t('CRealestateDetailFilteredCustomer.OR')}
                                                    toggleChanged={(val) => {
                                                        onChangeDetailAndOrToggle(val ? 'AND' : 'OR')
                                                    }}
                                                    value={filterAndOr === 'AND'}
                                                />
                                                <CGuideTips tooltipDirection="bottom-left">
                                                    <b>{t('CRealestateDetailFilteredCustomer.AND')}</b>
                                                    <br />
                                                    {t('CRealestateDetailFilteredCustomer.すべての条件に一致')}
                                                    <br />
                                                    <b>{t('CRealestateDetailFilteredCustomer.OR')}</b>
                                                    <br />
                                                    {t('CRealestateDetailFilteredCustomer.いずれかの条件に一致')}
                                                </CGuideTips>
                                            </div>
                                        </div>
                                    }>
                                    <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                        <CCheckBoxList
                                            items={[
                                                {
                                                    label: t('CRealestateDetailFilteredCustomer.ラベルなし'),
                                                    value: 'noLabels',
                                                },
                                            ]}
                                            checked={filterLabels}
                                            onChange={(val) => {
                                                onChangeFilterCheckBox(val as string[], ['noLabels'])
                                            }}
                                        />
                                        <hr />
                                        <CCheckBoxList
                                            items={sortBy(
                                                labelListCount?.list as CustomerLabelEntities[] | undefined,
                                                (s) => s.sort,
                                            ).map((m) => ({
                                                label: m.name,
                                                value: m.uuid,
                                            }))}
                                            checked={filterLabels}
                                            onChange={(val) => {
                                                onChangeFilterCheckBox(
                                                    val as string[],
                                                    (labelListCount?.list as CustomerLabelEntities[] | undefined)?.map(
                                                        (m) => m.uuid,
                                                    ) ?? [],
                                                )
                                            }}
                                        />
                                    </div>
                                </CLabeledItem>
                                <div className={clsx('py-2')}>
                                    <CButton
                                        className={clsx('c-button-secondary', 'w-full', 'mb-1')}
                                        onClick={() => {
                                            setFilterLabels([])
                                        }}>
                                        {t('CRealestateDetailFile.絞り込みをリセット')}
                                    </CButton>
                                    <CButton
                                        className={clsx('c-button-primary', 'w-full')}
                                        onClick={() => {
                                            setIsOpenFilter(false)
                                        }}>
                                        {t('CRealestateDetailFile.絞り込みを閉じる')}
                                    </CButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 通常ボタン */}
                    <div className={clsx('flex', 'gap-2')}>
                        {user?.roles.intermediary === 2 && (
                            <CButton
                                className={clsx('c-button-primary', 'whitespace-nowrap')}
                                disabled={checkedCustomerList.length === 0}
                                onClick={() => {
                                    navigate({
                                        pathname: `/realestate/${realEstate.uuid}/intermediary`,
                                        search: createSearchParams({
                                            customers: checkedCustomerList.map((item) => item.uuid),
                                        }).toString(),
                                    })
                                }}>
                                {t('CRealestateDetailFilteredCustomer.物件を紹介')}
                            </CButton>
                        )}
                    </div>
                </div>

                {!!filteredCustomerList?.length && (
                    <>
                        <div className={clsx('flex', 'bg-kimar-primary')}>
                            {user?.roles.intermediary === 2 && (
                                <div className={clsx('px-1')}>
                                    <CCheckBox
                                        className={clsx('rounded', 'border', 'border-white')}
                                        onChange={checkAllCustomer}
                                        label=""
                                        checked={allSelected}
                                    />
                                </div>
                            )}
                            <div className={clsx('text-white')}>{t('CRealestateDetailFilteredCustomer.顧客')}</div>
                        </div>
                        <ul>
                            {filteredCustomerList?.map((item, index) => (
                                <li key={index} className={clsx('border-t', 'hover:bg-kimar-primary-light')}>
                                    {item.intermediaryToList?.length ? (
                                        // 紹介データがある場合
                                        <div className={clsx('flex', 'flex-row', 'gap-x-2')}>
                                            {user?.roles.intermediary === 2 && (
                                                <div className={clsx('flex', 'items-center')}>
                                                    <CCheckBox
                                                        className={clsx('p-1', 'rounded', 'flex-1')}
                                                        checked={
                                                            !!checkedCustomerList.find(
                                                                (recommend) => recommend.uuid === item.uuid,
                                                            )
                                                        }
                                                        value={
                                                            checkedCustomerList.find((recommend) => recommend.uuid === item.uuid)
                                                                ? 'checked'
                                                                : undefined
                                                        }
                                                        onChange={() => {
                                                            onChangeCheckBox(item)
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div className={clsx('w-full')}>
                                                {/* 顧客名・タグ */}
                                                <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-1')}>
                                                    <div
                                                        className={clsx(
                                                            'text-lg',
                                                            'text-kimar-primary',
                                                            'underline',
                                                            'cursor-pointer',
                                                        )}
                                                        onClick={() => {
                                                            onClickCustomerName(item)
                                                        }}>
                                                        {item.name} ({item.companyName})
                                                    </div>
                                                    <div className={clsx('flex', 'flex-col', 'justify-center', 'gap-1')}>
                                                        <div className={clsx('flex', 'items-center')}>
                                                            <CTagList
                                                                horizontal
                                                                items={
                                                                    sortBy(item?.labels, (s) => s.sort).map((label) => ({
                                                                        className: clsx('text-xs'),
                                                                        style: {
                                                                            backgroundColor: label.bgColor,
                                                                            color: label.letterColor,
                                                                        },
                                                                        children: <>{label.name}</>,
                                                                    })) ?? []
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={clsx(
                                                        'grid',
                                                        'grid-cols-2',
                                                        'sm:grid-cols-4',
                                                        'gap-y-1',
                                                        'text-sm',
                                                        'p-1',
                                                    )}>
                                                    <div className={clsx('font-bold', 'border-b', 'px-1')}>
                                                        {t('CRealestateDetailFilteredCustomer.物件紹介日時')}
                                                    </div>
                                                    <div className={clsx('px-1', 'border-b')}>
                                                        {formatDateTime(getLatestIntermediaryTo(item)?.createdAt, 'dateTime')}
                                                    </div>
                                                    <div className={clsx('font-bold', 'border-b', 'px-1')}>
                                                        {t('CRealestateDetailFilteredCustomer.最終アクセス日時')}
                                                    </div>
                                                    {item.intermediaryToList[0]?.lastAccessAt ? (
                                                        <div className={clsx('border-b', 'px-1')}>
                                                            {formatDateTime(
                                                                getLatestIntermediaryTo(item)?.lastAccessAt,
                                                                'dateTime',
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className={clsx('border-b', 'px-1')}>
                                                            {t('CRealestateDetailFilteredCustomer.未開封')}
                                                        </div>
                                                    )}
                                                    <div className={clsx('font-bold', 'border-b', 'px-1')}>
                                                        {t('CRealestateDetailFilteredCustomer.提示金額')}
                                                    </div>
                                                    <div className={clsx('px-1', 'border-b')}>
                                                        {priceFormat(item.intermediaryToList[0]?.intermediary.price)}
                                                        {item.intermediaryToList[0]?.intermediary.taxType
                                                            ? ' (' +
                                                              taxType.find(
                                                                  (t) =>
                                                                      t.value ===
                                                                      item.intermediaryToList![0]?.intermediary.taxType,
                                                              )?.label +
                                                              ')'
                                                            : ''}
                                                    </div>
                                                    <div className={clsx('font-bold', 'border-b', 'px-1')}>
                                                        {t('CRealestateDetailFilteredCustomer.資料の閲覧回数')}
                                                    </div>
                                                    <div className={clsx('px-1', 'border-b')}>
                                                        {getDocumentViewsCount(item)}
                                                        {t('Unit.回')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // 紹介データがない場合
                                        <div className={clsx('flex', 'flex-row', 'gap-x-2')}>
                                            {user?.roles.intermediary === 2 && (
                                                <div className={clsx('flex', 'items-center')}>
                                                    <CCheckBox
                                                        className={clsx('p-1', 'rounded', 'flex-1')}
                                                        checked={
                                                            !!checkedCustomerList.find(
                                                                (recommend) => recommend.uuid === item.uuid,
                                                            )
                                                        }
                                                        value={
                                                            checkedCustomerList.find((recommend) => recommend.uuid === item.uuid)
                                                                ? 'checked'
                                                                : undefined
                                                        }
                                                        onChange={() => {
                                                            onChangeCheckBox(item)
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-1')}>
                                                <div
                                                    className={clsx(
                                                        'text-lg',
                                                        'text-kimar-primary',
                                                        'underline',
                                                        'cursor-pointer',
                                                    )}
                                                    onClick={() => {
                                                        onClickCustomerName(item)
                                                    }}>
                                                    {item?.name} ({item?.companyName})
                                                </div>
                                                <div className={clsx('flex', 'flex-col', 'justify-center', 'gap-1')}>
                                                    <div className={clsx('flex', 'items-center')}>
                                                        <CTagList
                                                            items={[
                                                                {
                                                                    className: clsx('text-xs', 'bg-gray-500', 'text-white'),
                                                                    children: (
                                                                        <>{t('CRealestateDetailFilteredCustomer.未紹介')}</>
                                                                    ),
                                                                },
                                                            ]}
                                                            horizontal
                                                        />
                                                    </div>
                                                    <div className={clsx('flex', 'items-center')}>
                                                        <CTagList
                                                            horizontal
                                                            items={
                                                                sortBy(item?.labels, (s) => s.sort).map((label) => ({
                                                                    className: clsx('text-xs'),
                                                                    style: {
                                                                        backgroundColor: label.bgColor,
                                                                        color: label.letterColor,
                                                                    },
                                                                    children: <>{label.name}</>,
                                                                })) ?? []
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </>
                )}

                {filteredCustomerList?.length === 0 ? (
                    // レコメンド0件
                    <div className={clsx('mt-4')}>
                        <CMessage info>{t('CRealestateDetailFilteredCustomer.絞り込み条件に合致する顧客はいません')}</CMessage>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {/* 顧客名を押下した時の顧客詳細モーダル */}
            <CFooterModal
                footer={
                    <>
                        <CButton
                            className={clsx('c-button-secondary')}
                            onClick={() => {
                                setClickedCustomer(undefined)
                                setVisibleCustomerDetailSync(false)
                            }}>
                            {t('Button.閉じる')}
                        </CButton>
                    </>
                }
                visible={visibleCustomerDetailSync}
                onRequestClose={() => {
                    setClickedCustomer(undefined)
                    setVisibleCustomerDetailSync(false)
                }}>
                <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>
                    {t('CRealestateDetailFilteredCustomer.顧客情報')}
                </div>
                <div className={clsx('border', 'rounded', 'm-6', 'pt-2')}>
                    {selectedCustomer && <CCustomerDetailInfo customer={selectedCustomer} refetch={() => {}} />}
                </div>
            </CFooterModal>
        </div>
    )
}

export default CRealestateDetailFilteredCustomer
