import { isNil, round } from 'lodash'

import { RealEstateEntities, TeamMemberEntities } from '~/app/api'
import {
    certificateOfCompletionOfConfirmationItems,
    certificateOfFinalInspectionItems,
    cityPlanningAreaItems,
    firePreventionDistrictsItems,
    modeOfTransactionItems,
    realestateLegalComplianceTypeItems,
    realestateSeismicStandardTypeItems,
    salesDestinationTypeItems,
    saleTimeTypeItems,
    structureTypeItems,
    surveyDrawingItems,
    zoningTypeItems,
} from '~/types/enum/enum'

import { calcYearsBuilt, dateFormatOfJc, formatDateTime } from '../common/common'
import { priceFormat } from '../filter/format'

export const RealEstateKeyLabels = [
    { key: 'latitude', label: '緯度', type: 'number', suffix: undefined },
    { key: 'longitude', label: '経度', type: 'number', suffix: undefined },
    { key: 'station', label: '最寄り駅', type: 'string' },
    { key: 'informationSource', label: '情報取得元', type: 'string' },
    { key: 'acquiredAt', label: '情報取得日', type: 'day' },
    { key: 'seller', label: '売り主', type: 'string' },
    { key: 'builder', label: '建築主', type: 'string' },
    { key: 'buildingName', label: '建物名', type: 'string' },
    { key: 'totalFloorSize', label: '延床面積', type: 'number', suffix: '㎡' },
    { key: 'totalFloorSizeTsubo', label: '延床面積(坪)', type: 'number', suffix: '坪' },
    { key: 'rentableAreaSize', label: '賃貸可能面積', type: 'number', suffix: '㎡' },
    { key: 'rentableAreaSizeTsubo', label: '賃貸可能面積(坪)', type: 'number', suffix: '坪' },
    { key: 'buildAt', label: '竣工日', type: 'buildAt' },
    { key: 'structureType', label: '構造', type: 'structureType' },
    { key: 'floor', label: '地上階数', type: 'number', suffix: undefined },
    { key: 'undergroundFloor', label: '地下階数', type: 'number', suffix: undefined },
    { key: 'zoningType', label: '用途地域1', type: 'zoningType' },
    { key: 'zoningType2', label: '用途地域2', type: 'zoningType' },
    { key: 'floorAreaRatio', label: '容積率1', type: 'number', suffix: '%' },
    { key: 'floorAreaRatio2', label: '容積率2', type: 'number', suffix: '%' },
    { key: 'buildingRate', label: '建ぺい率1', type: 'number', suffix: '%' },
    { key: 'buildingRate2', label: '建ぺい率2', type: 'number', suffix: '%' },
    { key: 'grossAndNetRate', label: '利回り', type: 'grossAndNetRate' },
    { key: 'saleTimeType', label: '売却時期', type: 'saleTimeType' },
    { key: 'seismicStandardType', label: '耐震基準', type: 'seismicStandardType' },
    { key: 'legalComplianceType', label: '遵法性', type: 'legalComplianceType' },
    { key: 'other', label: 'その他', type: 'string' },
    { key: 'assumeIncome', label: '想定収入', type: 'price' },
    { key: 'presentIncome', label: '現況収入', type: 'price' },
    { key: 'buildingNumber', label: '家屋番号', type: 'string' },
    { key: 'buildingRight', label: '建物の権利', type: 'string' },
    { key: 'certificateOfCompletionOfConfirmation', label: '確認済証', type: 'certificateOfCompletionOfConfirmation' },
    { key: 'certificateOfFinalInspection', label: '検査済証', type: 'certificateOfFinalInspection' },
    { key: 'cityPlanningArea', label: '都市計画', type: 'cityPlanningArea' },
    { key: 'deliveryAt', label: '引渡日', type: 'deliveryAt' },
    { key: 'facility', label: '設備', type: 'string' },
    { key: 'firePreventionDistricts', label: '防火地域', type: 'firePreventionDistricts' },
    { key: 'floorSize', label: '床面積', type: 'number', suffix: '㎡' },
    { key: 'floorSizeTsubo', label: '床面積（坪）', type: 'number', suffix: '坪' },
    { key: 'frontalRoad', label: '前面道路情報', type: 'string' },
    { key: 'heightControl', label: '高度規制', type: 'string' },
    { key: 'landCategory', label: '地目', type: 'string' },
    { key: 'landRight', label: '土地の権利', type: 'string' },
    { key: 'landShape', label: '敷地形状', type: 'string' },
    { key: 'modeOfTransaction', label: '取引態様', type: 'modeOfTransaction' },
    { key: 'salesDestinationType', label: '先数', type: 'salesDestinationType' },
    { key: 'otherControl', label: 'その他規制', type: 'string' },
    { key: 'parcelNumber', label: '地番', type: 'string' },
    { key: 'surveyDrawing', label: '測量図', type: 'surveyDrawing' },
    { key: 'surveyedLandSize', label: '実測面積', type: 'number', suffix: '㎡' },
    { key: 'surveyedLandSizeTsubo', label: '実測面積（坪）', type: 'number', suffix: '坪' },
    { key: 'totalUnits', label: '総戸数', type: 'number', suffix: undefined },
    { key: 'registeredLandSize', label: '公簿面積', type: 'number', suffix: '㎡' },
    { key: 'registeredLandSizeTsubo', label: '公簿面積（坪）', type: 'number', suffix: '坪' },
    { key: 'createdAt', label: '登録日', type: 'createdAt' },
    { key: 'updatedAt', label: '最終更新日', type: 'updatedAt' },
    { key: 'assignees', label: '担当者', type: 'assignees' },
] as const

export const DefaultRealEstateKeys: (typeof RealEstateKeyLabels)[number]['key'][] = [
    'buildAt',
    'createdAt',
    'grossAndNetRate',
    'updatedAt',
    'salesDestinationType',
    'informationSource',
]

export const getRealEstatePropertyString = (realEstate: RealEstateEntities, key: (typeof RealEstateKeyLabels)[number]['key']) => {
    const kv = RealEstateKeyLabels.find((kv) => kv.key === key)
    try {
        switch (kv?.type) {
            case 'number':
                return `${(realEstate[key as keyof RealEstateEntities] as number | null) ?? ''}${
                    realEstate[key as keyof RealEstateEntities] && kv.suffix ? kv.suffix : ''
                }`
            case 'string':
                return (realEstate[key as keyof RealEstateEntities] as string | null) ?? ''
            case 'price':
                return priceFormat(realEstate[key as keyof RealEstateEntities])
            case 'day':
                // 日付の場合の処理
                return dateFormatOfJc(realEstate[key as keyof RealEstateEntities] as unknown as Date, kv.type)
            case 'buildAt':
                // 竣工日の場合の処理
                return (
                    dateFormatOfJc(
                        realEstate.buildAt,
                        realEstate.buildAtDateType === 2 ? 'year' : realEstate.buildAtDateType === 1 ? 'month' : 'day',
                    ) + (calcYearsBuilt(realEstate.buildAt) ?? '')
                )
            case 'structureType':
                // 構造の場合の処理
                return structureTypeItems.find((i) => i.value === realEstate.structureType)?.label
            case 'zoningType':
                // 用途地域の場合の処理
                return zoningTypeItems.find((i) => i.value === realEstate[key as keyof RealEstateEntities])?.label
            case 'grossAndNetRate': {
                // 利回りの場合
                const rate: string[] = []
                if (!isNil(realEstate.grossRate)) rate.push(`表面${(round(realEstate.grossRate, 3) as number | null) ?? ''}%`)
                if (!isNil(realEstate.netRate)) rate.push(`実質${(round(realEstate.netRate, 3) as number | null) ?? ''}%`)
                return rate.join(' / ')
            }
            case 'saleTimeType':
                // 売却時期の場合の処理
                return saleTimeTypeItems.find((i) => i.value === realEstate.saleTimeType)?.label
            case 'seismicStandardType':
                // 耐震基準の場合の処理
                return realestateSeismicStandardTypeItems.find((i) => i.value === realEstate.seismicStandardType)?.label
            case 'legalComplianceType':
                // 遵法性の場合の処理
                return realestateLegalComplianceTypeItems.find((i) => i.value === realEstate.legalComplianceType)?.label
            case 'certificateOfCompletionOfConfirmation':
                // 確認済証の場合の処理
                return certificateOfCompletionOfConfirmationItems.find(
                    (i) => i.value === realEstate.certificateOfCompletionOfConfirmation,
                )?.label
            case 'certificateOfFinalInspection':
                // 検査済証の場合の処理
                return certificateOfFinalInspectionItems.find((i) => i.value === realEstate.certificateOfFinalInspection)?.label
            case 'cityPlanningArea':
                // 都市計画の場合の処理
                return cityPlanningAreaItems.find((i) => i.value === realEstate.cityPlanningArea)?.label
            case 'deliveryAt':
                // 引渡日の場合の処理
                return dateFormatOfJc(
                    realEstate.deliveryAt,
                    realEstate.deliveryAtDateType === 2 ? 'year' : realEstate.deliveryAtDateType === 1 ? 'month' : 'day',
                )
            case 'firePreventionDistricts':
                // 防火地域の場合の処理
                return firePreventionDistrictsItems.find((i) => i.value === realEstate.firePreventionDistricts)?.label
            case 'modeOfTransaction':
                // 取引態様の場合の処理
                return modeOfTransactionItems.find((i) => i.value === realEstate.modeOfTransaction)?.label
            case 'salesDestinationType':
                // 先数の場合の処理
                return salesDestinationTypeItems.find((i) => i.value === realEstate.salesDestinationType)?.label
            case 'surveyDrawing':
                // 測量図の場合の処理
                return surveyDrawingItems.find((i) => i.value === realEstate.surveyDrawing)?.label
            case 'assignees':
                // 担当者の場合の処理
                return realEstate.assignees
                    ?.map((assignee) => {
                        if (assignee.isPrimary) return assignee.assignedTo?.name + '(主)'
                        return assignee.assignedTo?.name
                    })
                    .join(', ')
            case 'createdAt':
                // 登録日の場合の処理
                return `${formatDateTime(realEstate.createdAt, 'date')} (${
                    (realEstate.createdBy as TeamMemberEntities | undefined)?.name
                })`
            case 'updatedAt':
                // 最終更新日の場合の処理
                return `${formatDateTime(realEstate.updatedAt, 'date')} (${
                    (realEstate.updatedBy as TeamMemberEntities | undefined)?.name
                })`
            default:
                // 上記以外の場合の処理
                return ''
        }
    } catch (e) {
        console.error(e)
    }
}
