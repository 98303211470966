import clsx from 'clsx'
import { motion } from 'framer-motion'
import { CSSProperties, FC, ReactNode, useEffect } from 'react'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

export type CModalViewProps = {} & CModalProps

export const CModalView: FC<CModalViewProps> = ({
    children,
    visible,
    content,
    onAfterOpen,
    onRequestClose,
    closeOnOverlayClick = true,
}) => {
    return (
        <ReactModal
            isOpen={visible}
            shouldCloseOnOverlayClick={closeOnOverlayClick}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    padding: '0',
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    background: 'white',
                    position: 'absolute',
                    overflow: 'visible', // モーダル全体はvisible
                    ...content,
                },
                overlay: {
                    background: 'rgba(62, 62, 62, 0.75)',
                    zIndex: '100',
                },
            }}>
            <div
                style={{
                    maxHeight: '90vh',
                    overflow: 'auto', // 子要素内でスクロールを許可
                }}>
                {children}
            </div>
        </ReactModal>
    )
}

export type CFooterModalViewProps = {} & CModalViewProps & CFooterModalProps
export const CFooterModalView: FC<CFooterModalViewProps> = ({ ...props }) => {
    return (
        <CModalView {...props}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                }}
                className={clsx('flex', 'flex-col', 'h-full')}>
                <div className={clsx('flex-grow')}>{props.children}</div>
                <div
                    className={clsx(
                        'sticky',
                        'left-0',
                        'bottom-0',
                        'flex',
                        'justify-center',
                        'z-10',
                        'w-full',
                        'bg-gray-100',
                        'p-4',
                        'gap-x-2',
                    )}>
                    {props.footer}
                </div>
            </motion.div>
        </CModalView>
    )
}

export type CModalProps = {
    visible: boolean
    closeOnOverlayClick?: boolean
    children?: ReactNode
    content?: CSSProperties
    onAfterOpen?: () => void
    onRequestClose: () => void
}

export const CModal: FC<CModalProps> = ({ ...props }) => {
    // 背景スクロール抑制
    const stopScrollingBackContent = () => {
        if (props.visible) document.body.style.overflow = 'hidden'
        else document.body.style.overflow = 'auto'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }

    useEffect(stopScrollingBackContent, [props.visible])

    return <CModalView {...props} />
}

export type CFooterModalProps = {
    footer: ReactNode
} & CModalProps

export const CFooterModal: FC<CFooterModalProps> = ({ ...props }) => {
    // 背景スクロール抑制
    const stopScrollingBackContent = () => {
        if (props.visible) document.body.style.overflow = 'hidden'
        else document.body.style.overflow = 'auto'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }

    useEffect(stopScrollingBackContent, [props.visible])
    return <CFooterModalView {...props} />
}
