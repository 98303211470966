import clsx from 'clsx'
import { t } from 'i18next'
import { isNil, maxBy } from 'lodash'
import { useEffect, useState } from 'react'
import { createSearchParams } from 'react-router-dom'

import { ClientRealEstateActivityDto, CustomerEntities, IntermediaryToEntities } from '~/app/api'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import { taxType } from '~/types/enum/enum'
import { calcYearsBuilt, dateFormatOfJc, fullCurrencyFormat } from '~/util/common/common'
import { getSignedUrl } from '~/util/model/FileEntities'
import { useAppSelector } from '~/util/store/hooks'
import { selectIsPlanTrial } from '~/util/store/userSlice'

import { CButton } from '../../../common/cButton/CButton'
import { CRealestateTrialModal } from '../../realestate/CRealestateTrialModal'

type Props = {
    customer: CustomerEntities
    intermediaryTo: IntermediaryToEntities
    showChatButton: boolean
    showDocumentButton: boolean
    activityDto?: ClientRealEstateActivityDto
}

const CCustomerDetailIntermediaryListItem = ({ intermediaryTo, showChatButton, showDocumentButton, activityDto }: Props) => {
    const detailLink = `/realestate/${
        intermediaryTo.relatedRealEstate.sort((a, b) => {
            if (a.createdAt > b.createdAt) return -1
            if (a.createdAt < b.createdAt) return 1
            return 0
        })[0].uuid
    }`
    const sourceRealEstate = intermediaryTo.relatedRealEstate.sort((a, b) => {
        if (a.createdAt > b.createdAt) return -1
        if (a.createdAt < b.createdAt) return 1
        return 0
    })[0]

    // 無料会員
    const isPlanTrial = useAppSelector(selectIsPlanTrial)

    const [realEstateImage, setRealEstateImage] = useState<string>()
    useEffect(() => {
        const getLogo = async () => {
            const coverImg = maxBy(
                sourceRealEstate.documents?.filter((d) => !!d.isCover),
                'createdAt',
            )?.file
            if (coverImg) setRealEstateImage(getSignedUrl(coverImg) ?? '')
            else setRealEstateImage(`/src/assets/image/noimage.svg`)
        }
        getLogo()
    }, [sourceRealEstate])

    const openChat = (intermediaryTo: IntermediaryToEntities) => {
        const url = new URL(window.location.origin + '/chat')
        url.search = createSearchParams({
            intermediaryTo: intermediaryTo.uuid,
            isSentTeam: 'true',
        }).toString()

        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        window.open(
            url,
            '_blank',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )
    }

    const openDocument = (intermediaryTo: IntermediaryToEntities) => {
        const url = new URL(window.location.origin + '/intermediary/' + intermediaryTo.uuid + '/document')

        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        window.open(
            url,
            '_blank',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )
    }

    const hasNewChat = (intermediaryTo: IntermediaryToEntities) => {
        if (!activityDto) return false
        return intermediaryTo.relatedRealEstate?.some((estate) => activityDto.receiveNewChat.includes(estate.uuid))
    }
    const [openTrialModal, setOpenTrialModal] = useState(false)

    return (
        <>
            {!isPlanTrial ? (
                <div className={clsx('p-2', 'border', 'rounded')}>
                    <div className={clsx('flex', 'justify-between')}>
                        <div className={clsx('flex-1', 'overflow-x-hidden')}>
                            <div
                                className={clsx(
                                    'text-xl',
                                    'text-gray-700',
                                    'font-bold',
                                    'w-[20em]',
                                    'text-ellipsis',
                                    'whitespace-nowrap',
                                    'overflow-hidden',
                                )}>
                                {sourceRealEstate.name}
                            </div>
                        </div>
                        <div className={clsx('flex', 'items-baseline')}>
                            <div className={clsx('flex', 'items-baseline')}>
                                <div className={clsx('text-sm', 'text-kimar-primary')}>
                                    {t('CCustomerDetailIntermediaryListItem.提示価格')}
                                </div>
                                <div className={clsx('text-lg', 'text-kimar-primary', 'font-bold')}>
                                    {fullCurrencyFormat(intermediaryTo.intermediary.price)}
                                    {intermediaryTo.intermediary.taxType
                                        ? ' (' + taxType.find((t) => t.value === intermediaryTo.intermediary.taxType)?.label + ')'
                                        : ''}
                                </div>
                                <div className={clsx('text-sm', 'text-kimar-primary')}>{t('Unit.円')}</div>
                            </div>
                        </div>
                    </div>
                    <div className={clsx('flex', 'items-center')}>
                        <div className={clsx('text-xs', 'text-white', 'bg-gray-500', 'py-0.5', 'px-1', 'mr-1')}>
                            {sourceRealEstate.useType.name}
                        </div>
                        <div className={clsx('text-sm', 'text-gray-700')}>{sourceRealEstate.address}</div>
                    </div>
                    <div className={clsx('flex', 'my-2')}>
                        <div className={clsx('h-[80px]', 'w-[80px]', 'flex')}>
                            <img
                                src={realEstateImage}
                                alt=""
                                loading="lazy"
                                className={clsx('h-[80px]', 'w-[80px]', 'rounded', 'border', 'border-gray-200', 'object-cover')}
                                crossOrigin="anonymous"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null
                                    currentTarget.src = '/src/assets/image/notfound.svg'
                                }}
                            />
                        </div>
                        <div className={clsx('flex-1', 'my-2')}>
                            <div className={clsx('mx-2')}>
                                <div className={clsx('text-xs')}>
                                    <div className={clsx('flex', 'border-b')}>
                                        <div className={clsx('w-24', 'font-bold')}>
                                            {t('CCustomerDetailIntermediaryListItem.竣工日')}
                                        </div>
                                        <div className="data">
                                            <span>
                                                {dateFormatOfJc(
                                                    sourceRealEstate.buildAt,
                                                    sourceRealEstate.buildAtDateType === 2
                                                        ? 'year'
                                                        : sourceRealEstate.buildAtDateType === 1
                                                        ? 'month'
                                                        : 'day',
                                                )}
                                            </span>
                                            <span className={clsx('ml-2')}>{calcYearsBuilt(sourceRealEstate.buildAt)}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b')}>
                                        <div className={clsx('w-24', 'font-bold')}>
                                            {t('CCustomerDetailIntermediaryListItem.利回り')}
                                        </div>
                                        <div className="data">
                                            {!isNil(sourceRealEstate.grossRate) && (
                                                <>
                                                    <span>{t('CCustomerDetailIntermediaryListItem.表面')}</span>
                                                    <span>{sourceRealEstate.grossRate}</span>
                                                    <span>%</span>
                                                </>
                                            )}
                                            {!isNil(sourceRealEstate.grossRate) && !isNil(sourceRealEstate.netRate) && (
                                                <span>{' / '}</span>
                                            )}
                                            {!isNil(sourceRealEstate.netRate) && (
                                                <>
                                                    <span>{t('CCustomerDetailIntermediaryListItem.実質')}</span>
                                                    <span>{sourceRealEstate.netRate}</span>
                                                    <span>%</span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b')}>
                                        <div className={clsx('w-24', 'font-bold')}>
                                            {t('CCustomerDetailIntermediaryListItem.担当者')}
                                        </div>
                                        <div className="data">
                                            <span>
                                                {sourceRealEstate.assignees
                                                    ?.map((assignee) => {
                                                        if (assignee.isPrimary) return assignee.assignedTo?.name + '(主)'
                                                        return assignee.assignedTo?.name
                                                    })
                                                    .join(', ')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'border-b')}>
                                        <div className={clsx('w-24', 'font-bold')}>
                                            {t('CCustomerDetailIntermediaryListItem.登録者/紹介元')}
                                        </div>
                                        <div className="data">
                                            <span>{intermediaryTo.intermediary?.sentBy?.name}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={clsx('flex', 'gap-2')}>
                        {showChatButton && (
                            <CButton
                                className={clsx(
                                    'border',
                                    'rounded',
                                    'border-kimar-primary',
                                    'w-8',
                                    'h-8',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                    'relative',
                                )}
                                onClick={() => openChat(intermediaryTo)}>
                                <i className={clsx('material-icons', 'text-kimar-primary')}>chat</i>
                                {hasNewChat(intermediaryTo) && (
                                    <div
                                        className={clsx(
                                            'absolute',
                                            'top-0',
                                            'right-0',
                                            'w-2',
                                            'h-2',
                                            'rounded-full',
                                            'bg-red-700',
                                        )}
                                    />
                                )}
                            </CButton>
                        )}
                        {showDocumentButton && (
                            <CButton
                                className={clsx(
                                    'border',
                                    'rounded',
                                    'border-kimar-primary',
                                    'w-8',
                                    'h-8',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}
                                onClick={() => openDocument(intermediaryTo)}>
                                <i className={clsx('material-icons', 'text-kimar-primary')}>description</i>
                            </CButton>
                        )}
                        <CLinkButton className={clsx('c-button-primary', 'flex-grow')} to={detailLink} blank>
                            {t('CCustomerDetailIntermediaryListItem.物件詳細へ')}
                        </CLinkButton>
                    </div>
                </div>
            ) : (
                <>
                    {/* 無料会員の超過物件 */}
                    <div className={clsx('m-2', 'relative', 'cursor-pointer')} onClick={() => setOpenTrialModal(true)}>
                        <div
                            className={clsx(
                                'absolute',
                                'top-1/2',
                                'left-1/2',
                                '-translate-y-1/2',
                                '-translate-x-1/2',
                                'p-2',
                                'flex',
                                'flex-col',
                                'md:flex-col',
                                'bg-white',
                                'gap-2',
                                'rounded',
                            )}>
                            <p className={clsx('flex', 'items-center', 'gap-2')}>
                                <i className={clsx('material-icons', 'inline-flex', 'align-middle', 'text-kimar-accent')}>lock</i>
                                <span className={clsx('font-bold')}>{t('CCustomerDetailIntermediaryListItem.表示制御物件')}</span>
                            </p>
                            <span className={clsx('text-sm')}>
                                {t('CCustomerDetailIntermediaryListItem.有料プランに契約すると表示できます')}
                            </span>
                        </div>
                        <img
                            src="/src/assets/image/page/realestate/secret_realestate/secret_realestate_intermediary.png"
                            alt="超過物件"
                            className={clsx('w-full')}
                        />
                    </div>
                    <CRealestateTrialModal visible={openTrialModal} onClose={() => setOpenTrialModal(false)} />
                </>
            )}
        </>
    )
}

export default CCustomerDetailIntermediaryListItem
