import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useMemo, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import {
    AdminCompanyContractUpdateDto,
    CompanyContractEntities,
    PlanEntities,
    useAdminCompanyContractPutUuidMutation,
    useAdminPlanPlanQuery,
} from '~/app/api'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CError } from '~/components/common/cError/CError'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CRadioButton } from '~/components/common/cRadioButton/CRadioButton'
import CTable from '~/components/common/cTable/CTable'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { ApplicationError, IApplicationError } from '~/types/error'
import { getUnitString } from '~/util/common/common'

export type UpdateContractModalViewProps = {
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (key: string, value: unknown) => void
    setPlan: (plan: PlanEntities) => void
    dto: AdminCompanyContractUpdateDto
    disableSubmit: boolean
    errors: IApplicationError[]
    plans: PlanEntities[]
    checkConnectionFeatureCondition: (val: boolean) => void
    checkMarketFeatureCondition: (val: boolean) => void
    setConnectionFeatureCondition: (key: 'openLimit', value: number) => void
    setMarketFeatureCondition: (key: 'openLimit' | 'requestLimit', value: number) => void
} & UpdateContractModalProps

export const UpdateContractModalView: VFC<UpdateContractModalViewProps> = ({
    isShow,
    onRequestConfirm,
    onRequestClose,
    setProps,
    setPlan,
    dto,
    disableSubmit,
    errors,
    plans,
    checkConnectionFeatureCondition,
    checkMarketFeatureCondition,
    setConnectionFeatureCondition,
    setMarketFeatureCondition,
}) => {
    return (
        <CConfirmModal
            title={t('UpdateContractModal.契約を編集')}
            visible={!!isShow}
            content={{ width: '80%', maxHeight: '80%' }}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            disableSubmit={disableSubmit}>
            <CError errors={errors} />
            <div className={clsx('flex', 'flex-col', 'space-y-2')}>
                <CTable
                    tbody={
                        <>
                            <tr>
                                <td className={clsx('bg-kimar-primary', 'text-white', 'text-center')}>
                                    {t('UpdateContractModal.契約開始日')}
                                </td>
                                <td>
                                    <CTextInput
                                        className={clsx('w-20', 'c-text-input-base')}
                                        type={'number'}
                                        text={String(dayjs(dto.startAt).year())}
                                        placeholder={'----'}
                                        onChange={(val) =>
                                            setProps(
                                                'startAt',
                                                dayjs(dto.startAt).year(Number(val)).isValid()
                                                    ? dayjs(dto.startAt).year(Number(val)).toISOString()
                                                    : dayjs(dto.startAt).toISOString(),
                                            )
                                        }
                                    />
                                    {t('UpdateContractModal.年')}
                                    <CTextInput
                                        className={clsx('w-16', 'c-text-input-base')}
                                        type={'number'}
                                        text={String(dayjs(dto.startAt).month() + 1)}
                                        placeholder={'--'}
                                        onChange={(val) =>
                                            setProps(
                                                'startAt',
                                                dayjs(dto.startAt)
                                                    .month(Number(val) - 1)
                                                    .isValid()
                                                    ? dayjs(dto.startAt)
                                                          .month(Number(val) - 1)
                                                          .toISOString()
                                                    : dayjs(dto.startAt).toISOString(),
                                            )
                                        }
                                    />
                                    {t('UpdateContractModal.月')}
                                    <CTextInput
                                        className={clsx('w-16', 'c-text-input-base')}
                                        type={'number'}
                                        text={String(dayjs(dto.startAt).date())}
                                        placeholder={'--'}
                                        onChange={(val) =>
                                            setProps(
                                                'startAt',
                                                dayjs(dto.startAt).date(Number(val)).isValid()
                                                    ? dayjs(dto.startAt).date(Number(val)).toISOString()
                                                    : dayjs(dto.startAt).toISOString(),
                                            )
                                        }
                                    />
                                    {t('UpdateContractModal.日')}
                                </td>
                            </tr>
                            {dto.endAt && (
                                <tr>
                                    <td className={clsx('bg-kimar-primary', 'text-white', 'text-center')}>
                                        {t('UpdateContractModal.契約終了日')}
                                    </td>
                                    <td>
                                        <CTextInput
                                            className={clsx('w-20', 'c-text-input-base')}
                                            type={'number'}
                                            text={String(dayjs(dto.endAt).year())}
                                            placeholder={'----'}
                                            onChange={(val) =>
                                                setProps(
                                                    'endAt',
                                                    dayjs(dto.endAt).year(Number(val)).isValid()
                                                        ? dayjs(dto.endAt).year(Number(val)).toISOString()
                                                        : dayjs(dto.endAt).toISOString(),
                                                )
                                            }
                                        />
                                        {t('UpdateContractModal.年')}
                                        <CTextInput
                                            className={clsx('w-16', 'c-text-input-base')}
                                            type={'number'}
                                            text={String(dayjs(dto.endAt).month() + 1)}
                                            placeholder={'--'}
                                            onChange={(val) =>
                                                setProps(
                                                    'endAt',
                                                    dayjs(dto.endAt)
                                                        .month(Number(val) - 1)
                                                        .isValid()
                                                        ? dayjs(dto.endAt)
                                                              .month(Number(val) - 1)
                                                              .toISOString()
                                                        : dayjs(dto.endAt).toISOString(),
                                                )
                                            }
                                        />
                                        {t('UpdateContractModal.月')}
                                        <CTextInput
                                            className={clsx('w-16', 'c-text-input-base')}
                                            type={'number'}
                                            text={String(dayjs(dto.endAt).date())}
                                            placeholder={'--'}
                                            onChange={(val) =>
                                                setProps(
                                                    'endAt',
                                                    dayjs(dto.endAt).date(Number(val)).isValid()
                                                        ? dayjs(dto.endAt).date(Number(val)).toISOString()
                                                        : dayjs(dto.endAt).toISOString(),
                                                )
                                            }
                                        />
                                        {t('UpdateContractModal.日')}
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <td className={clsx('bg-kimar-primary', 'text-white', 'text-center')}>
                                    {t('UpdateContractModal.契約プラン')}
                                </td>
                                <td>
                                    <CRadioButton
                                        horizontal
                                        items={plans}
                                        dataLabel={'name'}
                                        dataValue={'uuid'}
                                        nowValue={dto.plan.uuid}
                                        onChange={(val) => setPlan(plans.find((p) => p.uuid === val)!)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className={clsx('bg-kimar-primary', 'text-white', 'text-center')}>
                                    {t('UpdateContractModal.マーケット契約')}
                                </td>
                                <td>
                                    <CCheckBox
                                        readonly
                                        label={t('UpdateContractModal.レコメンド')}
                                        checked={!!dto.recommendFeatureCondition}
                                    />
                                    <CCheckBox
                                        label={t('UpdateContractModal.コネクション')}
                                        checked={!!dto.connectionFeatureCondition}
                                        onChange={(e) => checkConnectionFeatureCondition(e.target.checked)}
                                    />
                                    {!!dto.connectionFeatureCondition && (
                                        <div className={clsx('ml-4')}>
                                            <CLabeledItem label={t('UpdateContractModal.会社名／氏名の確認上限数')} horizontal>
                                                <CTextInput
                                                    className={clsx('w-full', 'c-text-input-base')}
                                                    type={'number'}
                                                    placeholder={'0'}
                                                    text={String(dto.connectionFeatureCondition.openLimit?.value)}
                                                    onChange={(val) => setConnectionFeatureCondition('openLimit', Number(val))}
                                                />
                                            </CLabeledItem>
                                        </div>
                                    )}
                                    <CCheckBox
                                        label={t('UpdateContractModal.マーケット')}
                                        checked={!!dto.marketFeatureCondition}
                                        onChange={(e) => checkMarketFeatureCondition(e.target.checked)}
                                    />
                                    {!!dto.marketFeatureCondition && (
                                        <div className={clsx('ml-4')}>
                                            <CLabeledItem label={t('UpdateContractModal.会社名／氏名の確認上限数')} horizontal>
                                                <CTextInput
                                                    className={clsx('w-full', 'c-text-input-base')}
                                                    type={'number'}
                                                    placeholder={'0'}
                                                    text={String(dto.marketFeatureCondition.openLimit?.value)}
                                                    onChange={(val) => setMarketFeatureCondition('openLimit', Number(val))}
                                                />
                                            </CLabeledItem>
                                            <CLabeledItem label={t('UpdateContractModal.開示リクエスト回数')} horizontal>
                                                <div>
                                                    <CTextInput
                                                        className={clsx('c-text-input-base')}
                                                        type={'number'}
                                                        placeholder={'0'}
                                                        text={String(dto.marketFeatureCondition.requestLimit?.value)}
                                                        onChange={(val) => setMarketFeatureCondition('requestLimit', Number(val))}
                                                    />
                                                    <span>{getUnitString(dto.marketFeatureCondition.requestLimit?.unit)}</span>
                                                </div>
                                            </CLabeledItem>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </>
                    }
                />
            </div>
        </CConfirmModal>
    )
}

export type UpdateContractModalProps = {
    isShow: boolean
    contract: CompanyContractEntities
    onClose: () => void
    onSubmit: (contract: CompanyContractEntities) => void
}

export const UpdateContractModal: VFC<UpdateContractModalProps> = ({ ...props }) => {
    const { data: plans } = useAdminPlanPlanQuery()

    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const onRequestClose = () => {
        props.onClose()
    }

    const [updateContractQuery] = useAdminCompanyContractPutUuidMutation()
    const onRequestConfirm = async () => {
        if (!dto) return
        try {
            const team = await updateContractQuery({ uuid: props.contract.uuid, adminCompanyContractUpdateDto: dto }).unwrap()
            props.onSubmit(team)
            toast.success(t('UpdateContractModal.契約を編集しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    useEffect(() => {
        setDto({
            ...props.contract,
            endAt: props.contract.EndAt,
        })
    }, [props.contract])

    const [dto, setDto] = useState<AdminCompanyContractUpdateDto>()

    const setProps = (key: string, value: unknown) => {
        if (!dto) return
        setDto({
            ...dto,
            [key]: value,
        })
    }

    const planValueSet = {
        無料会員: {
            teamFeatureCondition: {
                limit: 1,
                nestedLimit: 1,
                memberLimit: 1,
                enableLimitIpAddress: false,
                enableTrackingUser: false,
            },
            realEstateFeatureCondition: {
                limit: 1,
                assignedMemberLimit: 1,
                enableMapPinColor: false,
            },
            customerFeatureCondition: {
                assignedMemberLimit: 0,
                enableExportCsv: false,
                limit: 0,
            },
            intermediaryFeatureCondition: {
                enableVote: false,
                limit: 1,
            },
            recommendFeatureCondition: {
                matchingLimit: {
                    unit: 'week' as 'week' | 'twoWeek',
                    value: 0,
                },
            },
            connectionFeatureCondition: undefined,
            marketFeatureCondition: {
                openLimit: { value: 1, unit: 'week' as 'week' | 'twoWeek' },
                requestLimit: {
                    value: 1,
                    unit: 'twoWeek' as 'week' | 'twoWeek',
                },
            },
        },
        ミニマムプラン: {
            teamFeatureCondition: {
                limit: 1,
                nestedLimit: 1,
                memberLimit: 1,
                enableLimitIpAddress: false,
                enableTrackingUser: false,
            },
            realEstateFeatureCondition: {
                limit: 0,
                assignedMemberLimit: 1,
                enableMapPinColor: false,
            },
            customerFeatureCondition: {
                assignedMemberLimit: 0,
                enableExportCsv: false,
                limit: 0,
            },
            intermediaryFeatureCondition: {
                enableVote: false,
                limit: 0,
            },
            recommendFeatureCondition: {
                matchingLimit: {
                    unit: 'week' as 'week' | 'twoWeek',
                    value: 0,
                },
            },
            connectionFeatureCondition: undefined,
            marketFeatureCondition: {
                openLimit: { value: 1, unit: 'week' as 'week' | 'twoWeek' },
                requestLimit: {
                    value: 3,
                    unit: 'week' as 'week' | 'twoWeek',
                },
            },
        },
        ライトプラン: {
            teamFeatureCondition: {
                limit: 0,
                nestedLimit: 0,
                memberLimit: 5,
                enableLimitIpAddress: false,
                enableTrackingUser: false,
            },
            realEstateFeatureCondition: {
                limit: 0,
                assignedMemberLimit: 1,
                enableMapPinColor: false,
            },
            customerFeatureCondition: {
                assignedMemberLimit: 0,
                enableExportCsv: false,
                limit: 0,
            },
            intermediaryFeatureCondition: {
                enableVote: false,
                limit: 0,
            },
            recommendFeatureCondition: {
                matchingLimit: {
                    unit: 'week' as 'week' | 'twoWeek',
                    value: 0,
                },
            },
            connectionFeatureCondition: {
                openLimit: {
                    unit: 'week' as 'week' | 'twoWeek',
                    value: 1,
                },
            },
            marketFeatureCondition: {
                openLimit: { value: 2, unit: 'week' as 'week' | 'twoWeek' },
                requestLimit: {
                    value: 5,
                    unit: 'week' as 'week' | 'twoWeek',
                },
            },
        },
        スタンダードプラン: {
            teamFeatureCondition: {
                limit: 0,
                nestedLimit: 0,
                memberLimit: 10,
                enableLimitIpAddress: false,
                enableTrackingUser: false,
            },
            realEstateFeatureCondition: {
                limit: 0,
                assignedMemberLimit: 0,
                enableMapPinColor: true,
            },
            customerFeatureCondition: {
                assignedMemberLimit: 0,
                enableExportCsv: true,
                limit: 0,
            },
            intermediaryFeatureCondition: {
                enableVote: true,
                limit: 0,
            },
            recommendFeatureCondition: {
                matchingLimit: {
                    unit: 'week' as 'week' | 'twoWeek',
                    value: 0,
                },
            },
            connectionFeatureCondition: {
                openLimit: {
                    unit: 'week' as 'week' | 'twoWeek',
                    value: 2,
                },
            },
            marketFeatureCondition: {
                openLimit: { value: 3, unit: 'week' as 'week' | 'twoWeek' },
                requestLimit: {
                    value: 10,
                    unit: 'week' as 'week' | 'twoWeek',
                },
            },
        },
        プレミアムプラン: {
            teamFeatureCondition: {
                limit: 0,
                nestedLimit: 0,
                memberLimit: 0,
                enableLimitIpAddress: false,
                enableTrackingUser: true,
            },
            realEstateFeatureCondition: {
                limit: 0,
                assignedMemberLimit: 0,
                enableMapPinColor: true,
            },
            customerFeatureCondition: {
                assignedMemberLimit: 0,
                enableExportCsv: true,
                limit: 0,
            },
            intermediaryFeatureCondition: {
                enableVote: true,
                limit: 0,
            },
            recommendFeatureCondition: {
                matchingLimit: {
                    unit: 'week' as 'week' | 'twoWeek',
                    value: 0,
                },
            },
            connectionFeatureCondition: {
                openLimit: {
                    unit: 'week' as 'week' | 'twoWeek',
                    value: 5,
                },
            },
            marketFeatureCondition: {
                openLimit: { value: 5, unit: 'week' as 'week' | 'twoWeek' },
                requestLimit: {
                    value: 15,
                    unit: 'week' as 'week' | 'twoWeek',
                },
            },
        },
    }
    type PlanNameType = keyof typeof planValueSet

    const setPlan = (plan: PlanEntities) => {
        if (!dto) return
        const planContractTemplate = planValueSet[plan.name as PlanNameType]
        const newDto = {
            ...dto,
            plan,
            ...planContractTemplate,
            recommendFeatureCondition: planContractTemplate.recommendFeatureCondition
                ? { ...planContractTemplate.recommendFeatureCondition }
                : undefined,
            connectionFeatureCondition: planContractTemplate.connectionFeatureCondition
                ? { ...planContractTemplate.connectionFeatureCondition }
                : undefined,
            marketFeatureCondition: planContractTemplate.marketFeatureCondition
                ? { ...planContractTemplate.marketFeatureCondition }
                : undefined,
        }
        setDto(newDto)
    }

    const disableSubmit = useMemo(() => {
        return !dto
    }, [dto])

    const checkConnectionFeatureCondition = (val: boolean) => {
        if (!dto?.plan) return
        if (val) {
            setDto({
                ...dto,
                connectionFeatureCondition: planValueSet[dto.plan.name as PlanNameType].connectionFeatureCondition,
                recommendFeatureCondition: dto.marketFeatureCondition
                    ? planValueSet[dto.plan.name as PlanNameType].recommendFeatureCondition
                    : undefined,
            })
        } else {
            setDto({
                ...dto,
                connectionFeatureCondition: undefined,
                recommendFeatureCondition: dto.marketFeatureCondition ? dto.recommendFeatureCondition : undefined,
            })
        }
    }

    const setConnectionFeatureCondition = (key: 'openLimit', val: number) => {
        if (!dto?.plan) return
        const newDto = { ...dto }
        if (!newDto?.plan || !newDto.connectionFeatureCondition || !newDto.connectionFeatureCondition[key]) return
        newDto.connectionFeatureCondition = {
            ...newDto.connectionFeatureCondition,
            [key]: {
                unit: newDto.connectionFeatureCondition[key]?.unit ?? 'week',
                value: val,
            },
        }
        setDto(newDto)
    }

    const checkMarketFeatureCondition = (val: boolean) => {
        if (!dto?.plan) return
        if (val) {
            setDto({
                ...dto,
                marketFeatureCondition: planValueSet[dto.plan.name as PlanNameType].marketFeatureCondition,
                recommendFeatureCondition: dto.connectionFeatureCondition
                    ? planValueSet[dto.plan.name as PlanNameType].recommendFeatureCondition
                    : undefined,
            })
        } else {
            setDto({
                ...dto,
                marketFeatureCondition: undefined,
                recommendFeatureCondition: dto.connectionFeatureCondition ? dto.recommendFeatureCondition : undefined,
            })
        }
    }

    const setMarketFeatureCondition = (key: 'openLimit' | 'requestLimit', val: number) => {
        if (!dto?.plan) return
        const newDto = { ...dto }
        if (!newDto?.plan || !newDto.marketFeatureCondition || !newDto.marketFeatureCondition[key]) return
        newDto.marketFeatureCondition = {
            ...newDto.marketFeatureCondition,
            [key]: {
                unit: newDto.marketFeatureCondition[key]?.unit,
                value: val,
            },
        }
        setDto(newDto)
    }

    if (!dto || !plans) return <></>
    return (
        <UpdateContractModalView
            {...props}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            dto={dto}
            plans={plans.list as PlanEntities[]}
            setProps={setProps}
            setPlan={setPlan}
            disableSubmit={disableSubmit}
            errors={errors}
            checkConnectionFeatureCondition={checkConnectionFeatureCondition}
            checkMarketFeatureCondition={checkMarketFeatureCondition}
            setConnectionFeatureCondition={setConnectionFeatureCondition}
            setMarketFeatureCondition={setMarketFeatureCondition}
        />
    )
}
