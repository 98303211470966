import clsx from 'clsx'
import { CSSProperties, ReactNode, VFC } from 'react'

export type CTagViewProps = {} & CTagProps

export const CTagView: VFC<CTagViewProps> = ({ children, className, style }) => {
    return (
        <div className={clsx(className, 'inline-block')} style={style}>
            <span>{children}</span>
        </div>
    )
}

export type CTagProps = {
    children?: ReactNode
    className?: string
    style?: CSSProperties
}

export const CTag: VFC<CTagProps> = (props) => {
    return <CTagView {...props} />
}
