import clsx from 'clsx'
import { t } from 'i18next'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { useClientUserPostReminderMutation } from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { pageTitleTemplate } from '~/util/common/common'

import { CButton } from '../../components/common/cButton/CButton'
import { CError } from '../../components/common/cError/CError'
import { CLabeledItem } from '../../components/common/cLabeledItem/CLabeledItem'
import { CTextInput } from '../../components/common/cTextInput/CTextInput'
import { IApplicationError } from '../../types/error'

const Reminder = () => {
    const errors: IApplicationError[] = []
    const [isSent, setIsSent] = useState(false)
    // ログインフォームの情報をstateとして持つ
    const [formState, setFormState] = useState({
        email: '',
    })

    const [reminder] = useClientUserPostReminderMutation()
    const clickHandler = async () => {
        await reminder({ userReminderDto: { email: formState.email } }).unwrap()
        setIsSent(true)
    }

    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Reminder.パスワード再設定')}</title>
            </Helmet>
            <CHeader label={t('Reminder.パスワード再設定')} />
            {!isSent ? (
                <div className={clsx('max-w-lg', 'mx-auto', 'p-4', 'flex', 'flex-col', 'gap-4', 'bg-white', 'rounded')}>
                    <CError errors={errors} />
                    <CMessage warning>
                        {t('Reminder.パスワード再設定手続きを行います。')}
                        <br />
                        {t('Reminder.登録いただいたメールアドレスを入力して、「再発行手続きをする」ボタンを押下してください。')}
                        <br />
                        {t('Reminder.その後届いたメールの内容に従って、パスワードの再設定を行ってください。')}
                    </CMessage>
                    <div className={clsx('w-full')}>
                        <CLabeledItem label={t('Reminder.メールアドレス')} required>
                            <CTextInput
                                text={formState.email}
                                onChange={(value) => setFormState((prev) => ({ ...prev, email: value }))}
                                type={'text'}
                                className={clsx('w-full', 'c-text-input-base')}
                            />
                        </CLabeledItem>
                    </div>
                    <div className={clsx('w-full', 'flex', 'justify-center')}>
                        <CButton onClick={clickHandler} disabled={isSent} className={clsx('c-button-primary', 'w-full')}>
                            {t('Reminder.再設定手続きをする')}
                        </CButton>
                    </div>
                </div>
            ) : (
                <CMessage info>
                    {t('Reminder.パスワード再設定メールを送信しました。')}
                    <br />
                    {t('Reminder.メールが届かない場合はメールアドレスが間違っているか、会員登録されていない可能性があります。')}
                    <br />
                    {t('Reminder.入力内容をお確かめの上、再度手続きをお願いいたします。')}
                </CMessage>
            )}
        </>
    )
}

export default Reminder
