import clsx from 'clsx'
import { t } from 'i18next'
import { useState } from 'react'

import { DownloadExecuteDto } from '~/app/api'

import { CButton } from '../../common/cButton/CButton'
import { CDropDown } from '../../common/cDropdown/CDropdown'
import { CLabeledItem } from '../../common/cLabeledItem/CLabeledItem'
import { CTextInput } from '../../common/cTextInput/CTextInput'

type Props = {
    initialForm: DownloadExecuteDto
    submitHandlerProp: (form: DownloadExecuteDto) => void
}

const CDownloadForm = ({ initialForm, submitHandlerProp }: Props) => {
    const [form, setForm] = useState(initialForm)

    const issues = [
        { label: t('CDownloadForm.-選択してください-'), value: '' },
        { label: t('CDownloadForm.新規人脈・新規物件情報獲得'), value: '新規人脈・新規物件情報獲得' },
        { label: t('CDownloadForm.業務効率化'), value: '業務効率化' },
        { label: t('CDownloadForm.情報の一元管理'), value: '情報の一元管理' },
        { label: t('CDownloadForm.業務の見える化'), value: '業務の見える化' },
        { label: t('CDownloadForm.その他'), value: 'その他' },
    ]

    const formTextChanged = (value: string, target: keyof DownloadExecuteDto) => {
        setForm((oldValue) => ({ ...oldValue, [target]: value }))
    }

    const submitHandler = () => {
        // formを親に返しつつ親のハンドラ実行
        submitHandlerProp(form)
    }

    return (
        <div className={clsx('w-4/5', 'mx-auto', 'rounded-xl', 'p-9', 'bg-[#DFF5F4]')}>
            <div className={clsx('w-full', 'my-4')}>
                <CLabeledItem label={t('CDownloadForm.会社名')} required>
                    <CTextInput
                        className={clsx('w-full', 'c-text-input-base')}
                        type="text"
                        text={form.companyName}
                        onChange={(value) => formTextChanged(value, 'companyName')}
                        placeholder={t('CDownloadForm.リマールエステート株式会社')}
                    />
                </CLabeledItem>
            </div>
            <div className={clsx('w-full', 'my-4')}>
                <CLabeledItem label={t('CDownloadForm.お名前')} required>
                    <CTextInput
                        className={clsx('w-full', 'c-text-input-base')}
                        type="text"
                        text={form.name}
                        onChange={(value) => formTextChanged(value, 'name')}
                        placeholder={t('CDownloadForm.キマール 太郎')}
                    />
                </CLabeledItem>
            </div>
            <div className={clsx('w-full', 'my-4')}>
                <CLabeledItem label={t('CDownloadForm.職位')}>
                    <CTextInput
                        className={clsx('w-full', 'c-text-input-base')}
                        type="text"
                        text={form.position}
                        onChange={(value) => formTextChanged(value, 'position')}
                        placeholder={t('CDownloadForm.部長')}
                    />
                </CLabeledItem>
            </div>
            <div className={clsx('w-full', 'my-4')}>
                <CLabeledItem label={t('CDownloadForm.メールアドレス')} required>
                    <CTextInput
                        className={clsx('w-full', 'c-text-input-base')}
                        type="text"
                        text={form.email}
                        onChange={(value) => formTextChanged(value, 'email')}
                        placeholder="mail@example.com"
                    />
                </CLabeledItem>
            </div>
            <div className={clsx('w-full', 'my-4')}>
                <CLabeledItem label={t('CDownloadForm.電話番号')} required>
                    <CTextInput
                        className={clsx('w-full', 'c-text-input-base')}
                        type="text"
                        text={form.phone}
                        onChange={(value) => formTextChanged(value, 'phone')}
                        placeholder="090-0000-0000"
                    />
                </CLabeledItem>
            </div>
            <div className={clsx('w-full', 'my-4')}>
                <CLabeledItem label={t('CDownloadForm.改善したい業務課題はございますか？')}>
                    <CDropDown
                        className={clsx('w-full', 'c-text-input-base')}
                        onChange={(selectedData) => formTextChanged(selectedData, 'issue')}
                        items={issues}
                    />
                </CLabeledItem>
            </div>
            <CButton
                className={clsx(
                    'w-full',
                    'rounded-full',
                    'bg-kimar-accent',
                    'font-bold',
                    'text-xl',
                    'py-3',
                    'mt-8',
                    'outline',
                    'outline-kimar-accent',
                    'hover:bg-white',
                    'hover:outline-2',
                    'hover:outline-kimar-accent',
                    'transition',
                )}
                onClick={submitHandler}>
                {t('CDownloadForm.資料ダウンロード')}
            </CButton>
        </div>
    )
}

export default CDownloadForm
