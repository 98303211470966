import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// 言語jsonファイルのimport
import translation_en from './en.json'
import translation_ja from './ja.json'

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false
    }
}

const resources = {
    ja: {
        translation: translation_ja,
    },
    en: {
        translation: translation_en,
    },
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        // lng: 'en',
        fallbackLng: 'ja',
        returnEmptyString: false,
        supportedLngs: ['en', 'ja'],
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        detection: {
            order: ['navigator'],
            caches: [],
        },
        returnNull: false,
    })

export default i18n
