import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { isNil } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Trans } from 'react-i18next'
import { TailSpin } from 'react-loader-spinner'
import { Link } from 'react-router-dom'

import {
    TeamActivityClass,
    useClientDashboardAnalyticsDashboardAnalyticsQuery,
    useClientTeamActivityPutKeyStatusMutation,
    useClientTeamActivityPutTeamMemberUuidStatusMutation,
    useClientTeamActivityTeamActivityQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CHeader } from '~/components/common/cHeader/CHeader'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { deltaDateTime, formatDateTime, pageTitleTemplate } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, selectLoginUserCompany } from '~/util/store/userSlice'

const customerActivityTemplateKeys: string[] = [
    'customer_assignee',
    'customer_create',
    'customer_csv_create',
    'customer_csv_sync',
    'customer_delete',
    'customer_memo_create',
    'customer_memo_delete',
    'customer_memo_update',
    'customer_purchase_condition_multi_request_from',
    'customer_purchase_condition_request_from',
    'customer_purchase_condition_request_to',
    'customer_update',
]

const realestateActivityTemplateKeys: string[] = [
    'external_recommend_intermediary',
    'external_recommend_intermediary_from',
    'intermediary',
    'intermediaries_bulk_ended_consideration_from',
    'intermediaries_bulk_ended_from',
    'intermediary_chat_receive',
    'intermediary_chat_send',
    'intermediary_ended_consideration_from',
    'intermediary_ended_consideration_to',
    'intermediary_ended_from',
    'intermediary_ended_to',
    'intermediary_file_preview',
    'intermediary_from',
    'intermediary_multi_ended_from',
    'multi_customer_assignee',
    'real_estate_publish',
    'real_estate_publish_from',
    'realestate_assignee',
    'realestate_create',
    'realestate_delete',
    'realestate_file_delete',
    'realestate_file_upload',
    'realestate_memo_create',
    'realestate_memo_delete',
    'realestate_memo_update',
    'realestate_update',
    'intermediary_vote',
    'market_real_estate_request',
    'end_real_estate',
]

const teamActivityTemplateKeys: string[] = [
    'signup',
    'team_member_delete',
    'team_member_password_update',
    'team_member_update',
    'team_update',
]

const DashBoard = () => {
    const user = useAppSelector(selectLoginUser)
    // 取得時間
    const [currentDatetime, setCurrentDatetime] = useState<Date>()

    const [page, setPage] = useState(1)
    const [teamMemberActivityList, setTeamMemberActivityList] = useState<TeamActivityClass[]>()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const company = useAppSelector(selectLoginUserCompany)
    const { data: dashboardStatus, isLoading: isLoadingStatus } = useClientDashboardAnalyticsDashboardAnalyticsQuery()
    const { data: teamActivity } = useClientTeamActivityTeamActivityQuery({ page })

    useEffect(() => {
        setTeamMemberActivityList((oldList) => {
            if (!teamActivity) return oldList
            let newList = [...(oldList ?? [])]
            // eslint-disable-next-line no-restricted-syntax
            for (const t of teamActivity.result) {
                if (newList.find((n) => n.id === t.id)) newList = [...newList.filter((n) => n.id != t.id), t]
                else newList = [...newList, t]
            }
            return newList
        })
        setCurrentDatetime(new Date())
    }, [teamActivity])

    useEffect(() => {
        if (!teamMemberActivityList?.length) setPage(1)
    }, [teamMemberActivityList])

    const contractPlanName = useMemo(() => company?.contracts.find(() => true)?.plan.name ?? '', [company])
    const intermediaryCount = useMemo(() => dashboardStatus?.intermediaryCount ?? 0, [dashboardStatus])
    const customerCount = useMemo(() => dashboardStatus?.customerCount ?? 0, [dashboardStatus])
    const realEstateCount = useMemo(() => dashboardStatus?.realEstateCount ?? 0, [dashboardStatus])
    const newestPublishRequest = useMemo(() => dashboardStatus?.newestPublishRequest, [dashboardStatus])

    const targetActivityList = useMemo(() => {
        return teamMemberActivityList
    }, [teamMemberActivityList])

    const categoryLabel = (name: string) => {
        if (realestateActivityTemplateKeys.includes(name)) return t('DashBoard.物件')
        if (customerActivityTemplateKeys.includes(name)) return t('DashBoard.顧客')
        if (teamActivityTemplateKeys.includes(name)) return t('DashBoard.チーム')
        return t('DashBoard.その他')
    }

    const [setReadQuery] = useClientTeamActivityPutKeyStatusMutation()
    const handleClick = async (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>, notify: TeamActivityClass) => {
        // チャット受信はチャットを開くまで既読としない
        if (event.target instanceof HTMLAnchorElement && notify.name != 'intermediary_chat_receive') await setRead(notify)
    }
    const isNoRead = (notify: TeamActivityClass) => {
        return notify.status !== 'read'
    }
    const setRead = async (notify: TeamActivityClass) => {
        await setReadQuery({
            key: notify.id,
        }).unwrap()
    }

    const [setReadAllQuery] = useClientTeamActivityPutTeamMemberUuidStatusMutation()
    const setReadAll = async () => {
        if (!user) return
        await setReadAllQuery({
            uuid: user.uuid,
        }).unwrap()
        setTeamMemberActivityList([])
    }

    const infoList = [
        {
            type: {
                info: true,
            },
            isShow: () => {
                return user && isNil(user.lastChangePasswordAt)
            },
            component: (
                <>
                    <Trans i18nKey="DashBoard.【キマールより】セキュリティ向上のため、パスワードの変更をお願いします。">
                        <Link className={clsx('underline')} to="/user/mypage?type=profile&content=changePassword">
                            {t('DashBoard.パスワードの変更')}
                        </Link>
                    </Trans>
                </>
            ),
        },
        {
            type: {
                success: true,
            },
            isShow: () => {
                return !isNil(newestPublishRequest)
            },
            component: (
                <div className={clsx('flex', 'items-center')}>
                    {t('DashBoard.掲載した物件の情報開示リクエストが届いています。')}
                    <CLinkButton className={clsx('c-button-primary')} to={`/realestate/${newestPublishRequest?.realEstate.uuid}`}>
                        物件詳細を確認する
                    </CLinkButton>
                </div>
            ),
        },
    ]
    const infoBoxList = useMemo(() => {
        return (
            <>
                {infoList
                    .filter((i) => i.isShow())
                    .map((i, index) => (
                        <div className={clsx('mb-2')}>
                            <CMessage key={index} {...i.type}>
                                {i.component}
                            </CMessage>
                        </div>
                    ))}
            </>
        )
    }, [user, newestPublishRequest])

    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('DashBoard.ダッシュボード')}</title>
            </Helmet>
            <div className={clsx('px-4')}>{infoBoxList}</div>
            <div className={clsx('grid', 'md:grid-cols-2', 'gap-2')}>
                <div className={clsx('p-2')}>
                    <CHeader label={t('DashBoard.統計情報')} bgClass="bg-kimar-accent" />
                    <div className={clsx('bg-white', 'rounded', 'my-4', 'p-4')}>
                        <div className={clsx('text-center', 'text-xl', 'font-bold')}>{contractPlanName}</div>
                        <div className={clsx('flex', 'flex-col', 'gap-x-2', 'gap-y-8', 'my-5')}>
                            <div className={clsx('grid', 'grid-cols-2', 'gap-4')}>
                                <div className={clsx('flex', 'justify-end', 'items-center', 'text-gray-500')}>
                                    {t('DashBoard.紹介している物件数')}
                                </div>
                                <div>
                                    {isLoadingStatus ? (
                                        <TailSpin height="40" width="40" color="#45a8a1" />
                                    ) : (
                                        <Trans i18nKey="DashBoard.intermediaryCount物件" intermediaryCount={intermediaryCount}>
                                            <span className={clsx('text-6xl', 'font-bold')}>
                                                <>{{ intermediaryCount }}</>
                                            </span>
                                            <span>物件</span>
                                        </Trans>
                                    )}
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-2', 'gap-4')}>
                                <div className={clsx('flex', 'justify-end', 'items-center', 'text-gray-500')}>
                                    {t('DashBoard.全物件数')}
                                </div>
                                <div>
                                    {isLoadingStatus ? (
                                        <TailSpin height="40" width="40" color="#45a8a1" />
                                    ) : (
                                        <Trans i18nKey="DashBoard.realEstateCount物件" realEstateCount={realEstateCount}>
                                            <span className={clsx('text-6xl', 'font-bold')}>
                                                <>{{ realEstateCount }}</>
                                            </span>
                                            <span>物件</span>
                                        </Trans>
                                    )}
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-2', 'gap-4')}>
                                <div className={clsx('flex', 'justify-end', 'items-center', 'text-gray-500')}>
                                    {t('DashBoard.全顧客数')}
                                </div>
                                <div>
                                    {isLoadingStatus ? (
                                        <TailSpin height="40" width="40" color="#45a8a1" />
                                    ) : (
                                        <Trans i18nKey="DashBoard.customerCount名" customerCount={customerCount}>
                                            <span className={clsx('text-6xl', 'font-bold')}>
                                                <>{{ customerCount }}</>
                                            </span>
                                            <span>名</span>
                                        </Trans>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 活動履歴 */}
                <div className={clsx('p-2')}>
                    <CHeader label={t('DashBoard.活動履歴')} bgClass="bg-kimar-accent" />
                    <div className={clsx('bg-white', 'rounded', 'my-4', 'p-4')}>
                        {/* TODO: 活動履歴の細分化 */}
                        <div className={clsx('flex', 'justify-end', 'items-center')}>
                            <CButton onClick={() => setReadAll()} className={clsx('c-button-secondary', 'text-xs')}>
                                {t('DashBoard.すべて既読にする')}
                            </CButton>
                        </div>
                        <div
                            className={clsx(
                                'flex',
                                'flex-col',
                                'overflow-y-auto',
                                'min-h-[222px]',
                                'max-h-[calc(100vh-104px-64px-210px)]',
                                'm-2',
                            )}>
                            {(targetActivityList?.length ?? 0) === 0 ? (
                                <div>
                                    <span>
                                        {formatDateTime(currentDatetime, 'dateTime')} {t('DashBoard.現在')}
                                    </span>
                                    <span>{t('DashBoard.お知らせはありません。')}</span>
                                </div>
                            ) : (
                                <>
                                    {targetActivityList?.map((notify) => (
                                        <div
                                            className={clsx(
                                                'text-sm',
                                                'border-b',
                                                'flex',
                                                'flex-col',
                                                'p-1',
                                                notify.status === 'read' && 'bg-gray-50',
                                            )}
                                            key={notify.id}>
                                            <div className={clsx('flex', 'gap-1', 'items-center')}>
                                                <span
                                                    className={clsx(
                                                        'text-xs',
                                                        'rounded-sm',
                                                        'text-white',
                                                        'bg-gray-500',
                                                        'py-0.5',
                                                        'px-1',
                                                        'mr-1',
                                                        'whitespace-nowrap',
                                                    )}>
                                                    {categoryLabel(notify.name)}
                                                </span>
                                                <p
                                                    className={clsx('inner-html')}
                                                    dangerouslySetInnerHTML={{ __html: notify.content }}
                                                    onClick={(event) => handleClick(event, notify)}
                                                />
                                            </div>
                                            <div
                                                className={clsx('flex', 'justify-end', 'text-gray-500', 'items-center', 'gap-2')}>
                                                <div>
                                                    {formatDateTime(notify.createdAt, 'dateTime') +
                                                        ' (' +
                                                        deltaDateTime(dayjs(notify.createdAt).toDate()) +
                                                        ')'}
                                                </div>
                                                {isNoRead(notify) ? (
                                                    <CButton
                                                        onClick={() => setRead(notify)}
                                                        className={clsx('c-button-secondary', 'text-xs', 'py-0.5', 'px-1')}>
                                                        {t('DashBoard.既読にする')}
                                                    </CButton>
                                                ) : (
                                                    <div
                                                        className={clsx(
                                                            'rounded',
                                                            'bg-gray-300',
                                                            'text-gray-700',
                                                            'mx-1',
                                                            'px-1',
                                                        )}>
                                                        {t('DashBoard.既読')}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    {
                                        <div>
                                            <CButton
                                                className={clsx('c-button-primary', 'text-sm', 'w-full')}
                                                onClick={() => setPage((p) => p + 1)}>
                                                <span>{t('DashBoard.続きを見る')}</span>
                                            </CButton>
                                        </div>
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DashBoard
