import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useState } from 'react'
import toast from 'react-hot-toast/headless'
import { Link } from 'react-router-dom'

import {
    AdminReportTemplateInsertDto,
    FileEntities,
    useAdminReportTemplateGetReportTemplateQuery,
    useAdminReportTemplatePostReportTemplateMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import CMessage from '~/components/common/cMessage/CMessage'
import { CModal } from '~/components/common/cModal/CModal'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { ApplicationError, IApplicationError } from '~/types/error'
import { useAdminDownloadFileMutation } from '~/util/api/downloadApi'

import CReportTemplateEdit from './CReportTemplateEdit'

const CReportTemplate = () => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const { data: templates, isLoading: isLoadingTemplates } = useAdminReportTemplateGetReportTemplateQuery({})
    const [postAPI] = useAdminReportTemplatePostReportTemplateMutation()
    const [dlAPI] = useAdminDownloadFileMutation()
    // 検索フォーム
    const [form, setForm] = useState({
        categoryJapanese: '',
        displayName: '',
        fileName: '',
    })
    // カテゴリの日本語名
    const categoryJapanese: { [key: string]: string } = { realestate_overview_document: t('CReportTemplate.物件概要書') }

    // { category: 「出力されるファイル名」のprefix }
    const displayNamePrefix: { [key: string]: string } = { realestate_overview_document: t('CReportTemplate.物件名_') }
    // テンプレート初期状態
    const emptyTemplate: AdminReportTemplateInsertDto = {
        category: '',
        displayName: '',
        file: undefined,
    }

    // 新規作成モーダルvisible
    const [visibleChangeModal, setVisibleChangeModal] = useState(false)
    // 新規作成モーダルのデータ
    const [template, setTemplate] = useState<AdminReportTemplateInsertDto>(emptyTemplate)

    // 検索フォーム入力内容でフィルタリング
    const keys = Object.keys(form) as (keyof typeof form)[]
    // テンプレートリストを表示・検索用リストに変換
    const filteredTemplates = templates?.list
        .map((template) => {
            const convertedTemplate = {
                ...template,
                categoryJapanese: categoryJapanese[template.category] ?? template.category,
                fileName: template.file.filename,
            }
            return convertedTemplate
        })
        .filter((template) => keys.every((key) => form[key] === '' || (key in template && template[key].includes(form[key]))))
    // 検索フォームイベントハンドラ
    const formTextChanged = (value: string, target: keyof typeof form) => {
        setForm((oldValue) => ({ ...oldValue, [target]: value }))
    }

    // 新規追加ボタンハンドラ
    const createHandler = () => {
        setVisibleChangeModal(true)
        setTemplate(emptyTemplate)
    }
    // 新規追加の保存ボタンハンドラ
    const confirmButtonHandler = async (changedTemplate: AdminReportTemplateInsertDto) => {
        setTemplate(changedTemplate)
        try {
            await postAPI({ adminReportTemplateInsertDto: changedTemplate }).unwrap()
            toast.success(t('CReportTemplate.帳票テンプレートを登録しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleChangeModal(false)
        }
    }

    const getFile = async (file: FileEntities) => {
        const replacedDocName = file.filename.replace(/\/|:|\?|"|<|>|\||¥|\*|\\/g, '-')

        try {
            await dlAPI({ fileUuid: file.uuid, fileName: replacedDocName }).unwrap()
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    return (
        <div className={clsx('w-full')}>
            <CError errors={errors} />
            <div className={clsx('flex', 'justify-between')}>
                <div className={clsx('flex', 'flex-row')}>
                    <div className={clsx('pl-4', 'whitespace-nowrap', 'self-center')}>{t('CReportTemplate.絞り込み')}</div>
                    <div className={clsx('p-4', 'grid', 'grid-cols-3', 'gap-1')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            text={form.categoryJapanese}
                            placeholder={t('CReportTemplate.カテゴリ')}
                            onChange={(value) => formTextChanged(value, 'categoryJapanese')}
                        />
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            text={form.displayName}
                            placeholder={t('CReportTemplate.出力されるファイル名')}
                            onChange={(value) => formTextChanged(value, 'displayName')}
                        />
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            text={form.fileName}
                            placeholder={t('CReportTemplate.登録したファイル名')}
                            onChange={(value) => formTextChanged(value, 'fileName')}
                        />
                    </div>
                </div>
                <div className={clsx('flex', 'justify-end', 'self-center')}>
                    <CButton className={clsx('c-button-primary', 'text-sm')} onClick={createHandler}>
                        <i className={clsx('material-icons')}>add</i>
                        <div>{t('CReportTemplate.新規帳票追加')}</div>
                    </CButton>
                </div>
            </div>

            <div className={clsx('w-full', 'p-2')}>
                {isLoadingTemplates && <CMessage info>{t('Message.読み込み中です…')}</CMessage>}
                <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                    <thead>
                        <tr className={clsx('bg-kimar-primary', 'text-white', 'text-left')}>
                            <th className={clsx('p-1')}>{t('CReportTemplate.カテゴリ')}</th>
                            <th className={clsx('p-1')}>{t('CReportTemplate.出力されるファイル名')}</th>
                            <th className={clsx('p-1')}>{t('CReportTemplate.登録したファイル名')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTemplates?.map((template) => (
                            <tr key={template.uuid} className={clsx('border', 'hover:bg-kimar-primary-light')}>
                                <td className={clsx('p-1')}>
                                    <Link to={`report/${template.uuid}`} className={clsx('text-kimar-primary')}>
                                        {template.categoryJapanese}
                                    </Link>
                                </td>
                                <td className={clsx('p-1')}>
                                    {displayNamePrefix[template.category] && <span>{displayNamePrefix[template.category]}</span>}
                                    <span>{template.displayName}</span>
                                    <span>.xlsx</span>
                                </td>
                                <td
                                    className={clsx('p-1', 'text-kimar-primary', 'underline', 'cursor-pointer')}
                                    onClick={() => getFile(template.file)}>
                                    {template.file.filename}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* 新規作成モーダル */}
            <CModal
                content={{ width: '1000px' }}
                visible={visibleChangeModal}
                onRequestClose={() => setVisibleChangeModal(false)}>
                <CReportTemplateEdit
                    template={template}
                    confirmButtonHandler={(changedTemplate) =>
                        confirmButtonHandler(changedTemplate as AdminReportTemplateInsertDto)
                    }
                    cancelButtonHandler={() => {
                        setVisibleChangeModal(false)
                    }}
                />
            </CModal>
        </div>
    )
}

export default CReportTemplate
