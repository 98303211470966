import clsx from 'clsx'
import { Outlet } from 'react-router-dom'

const SubWindow = () => (
    <div className={clsx('h-screen')}>
        <Outlet />
    </div>
)

export default SubWindow
