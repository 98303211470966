import clsx from 'clsx'
import { isString } from 'lodash'
import { ReactNode, VFC } from 'react'

export type CIconTipsViewProps = {} & CIconTipsProps

export const CIconTipsView: VFC<CIconTipsViewProps> = ({ children, color, icon, tooltipDirection }) => {
    return (
        <div className={clsx('h-full', 'flex', 'items-center', 'relative')}>
            {isString(icon) ? (
                <i
                    className={clsx(
                        'material-icons',
                        'w-6',
                        'h-6',
                        'text-[24px]',
                        'peer',
                        'cursor-pointer',
                        color ? color : 'text-gray-500',
                        'align-middle',
                    )}>
                    {icon}
                </i>
            ) : (
                icon
            )}
            <div
                className={clsx(
                    'opacity-0',
                    'peer-hover:opacity-100',
                    'pointer-events-none',
                    'absolute',
                    'text-gray-700',
                    'z-10',
                    'p-2',
                    'rounded',
                    'bg-white',
                    'bg-opacity-90',
                    'transition',
                    'text-sm',
                    'w-[200px]',
                    tooltipDirection === 'top-left' && ['bottom-6', 'right-0'],
                    tooltipDirection === 'top-right' && ['bottom-6', 'left-0'],
                    tooltipDirection === 'bottom-left' && ['top-6', 'right-0'],
                    tooltipDirection === 'bottom-right' && ['top-6', 'left-0'],
                    tooltipDirection === 'left' && ['-top-1', 'right-8'],
                    tooltipDirection === 'right' && ['-top-1', 'left-6'],
                )}>
                {children}
            </div>
        </div>
    )
}

export type CIconTipsProps = {
    icon: string | ReactNode
    color?: string
    children?: ReactNode
    tooltipDirection: 'left' | 'right' | 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right'
}
export const CIconTips: VFC<CIconTipsProps> = ({ ...props }) => {
    return <CIconTipsView {...props} color={props.color} />
}

export type CGuideTipsProps = {
    children?: ReactNode
    color?: string
    tooltipDirection: 'left' | 'right' | 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right'
}
export const CGuideTips: VFC<CGuideTipsProps> = ({ ...props }) => {
    return <CIconTipsView {...props} color={props.color} icon="help" />
}
