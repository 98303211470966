import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CompanyEntities, TeamEntities, TeamMemberEntities } from '~/app/api'

import type { RootState } from './store'

type OptOutKey = 'intermediaryVote'

const slice = createSlice({
    name: 'user',
    initialState: { user: undefined, company: undefined, team: undefined } as {
        user: undefined | TeamMemberEntities
        team: undefined | TeamEntities
        company: undefined | CompanyEntities
        optOut: undefined | { [key in OptOutKey]?: boolean }
    },
    reducers: {
        // user/me結果のuserを保存
        setLoginUser: (state, { payload: user }: PayloadAction<undefined | TeamMemberEntities>) => ({
            ...state,
            user, // user: user,
            team: user?.team,
            company: user?.team.company,
        }),
        // userのteam
        setLoginUserTeam: (state, { payload: team }: PayloadAction<undefined | TeamEntities>) => ({
            ...state,
            team,
        }),
        // userのcompany
        setLoginUserCompany: (state, { payload: company }: PayloadAction<undefined | CompanyEntities>) => ({
            ...state,
            company,
        }),
        setOptOut: (state, { payload }: PayloadAction<undefined | [OptOutKey, boolean | undefined]>) => ({
            ...state,
            optOut: payload
                ? {
                      ...state.optOut,
                      [payload[0]]: payload[1],
                  }
                : undefined,
        }),
    },
})

// sliceからactionを切り出す
export const { setLoginUser, setLoginUserTeam, setLoginUserCompany, setOptOut } = slice.actions

// ログインユーザー
export const selectLoginUser = (state: RootState) => state.persistedReducer.user.user
// ログインユーザーのチーム
export const selectLoginUserTeam = (state: RootState) => state.persistedReducer.user.team
// ログインユーザーの会社
export const selectLoginUserCompany = (state: RootState) => state.persistedReducer.user.company
// ログインユーザーの会社ロゴ
export const selectLoginUserCompanyLogo = (state: RootState) => state.persistedReducer.user.company?.logoFile
// ログインユーザーの会社の契約
export const selectLoginUserCompanyContract = (state: RootState) =>
    state.persistedReducer.user.company?.contracts.find((c) => !c.EndAt)
// 契約が無料会員か
export const selectIsPlanTrial = (state: RootState) => {
    return selectLoginUserCompanyContract(state)?.plan.name === '無料会員'
}
// 契約がスタンダード以上か
export const selectIsPlanStandardOrOver = (state: RootState) => {
    return ['スタンダードプラン', 'プレミアムプラン', '運用管理'].some((planName) =>
        selectLoginUserCompanyContract(state)?.plan.name.includes(planName),
    )
}
// ログインユーザーのレコメンド契約
export const selectLoginUserHaveRecommendContract = (state: RootState) =>
    !!selectLoginUserCompanyContract(state)?.recommendFeatureCondition
// ログインユーザーのコネクション契約
export const selectLoginUserHaveConnectionContract = (state: RootState) =>
    !!selectLoginUserCompanyContract(state)?.connectionFeatureCondition
// ログインユーザーのマーケット契約
export const selectLoginUserHaveMarketContract = (state: RootState) =>
    !!selectLoginUserCompanyContract(state)?.marketFeatureCondition
// ゲストかどうか
export const selectIsGuest = (state: RootState) => state.persistedReducer.user.user === null

export const selectOptOutIntermediaryVote = (state: RootState) => state.persistedReducer.user.optOut?.intermediaryVote

// sliceからreducerを切り出す
export default slice.reducer
