import clsx from 'clsx'
import { ReactNode, VFC } from 'react'
import { Link } from 'react-router-dom'

export type CLinkButtonViewProps = {} & CLinkButtonProps

export const CLinkButtonView: VFC<CLinkButtonViewProps> = ({ to, blank, children, className }) => {
    return (
        <Link
            to={to}
            className={clsx(className, 'cursor-pointer')}
            target={blank ? '_blank' : undefined}
            rel={blank ? 'noopener noreferrer' : undefined}>
            {children}
        </Link>
    )
}

export type CLinkButtonProps = {
    to: string
    blank?: boolean
    children?: ReactNode
    className?: string
}

export const CLinkButton: VFC<CLinkButtonProps> = (props) => {
    return <CLinkButtonView {...props} />
}
