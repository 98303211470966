import { GoogleMap, Marker } from '@react-google-maps/api'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { isArray, isNil, round } from 'lodash'
import { KeyboardEvent, Ref, useEffect, useRef, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import {
    ClientRealEstateInsertDto,
    ClientRealEstateUpdateDto,
    RealEstateEntities,
    RealEstateUseTypeEntities,
    useClientRealEstatePostRealEstateMutation,
    useClientRealEstatePutUuidMutation,
    useClientRealEstateUseTypeRealEstateUseTypeQuery,
} from '~/app/api'
import { CError } from '~/components/common/cError/CError'
import { CGuideTips } from '~/components/common/cGuideTips/CGuideTips'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { CNumberInput } from '~/components/common/cNumberInput/CNumberInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { CYearMonthDay } from '~/components/common/cYearMonthDay/CYearMonthDay'
import {
    areaTypeWithOutWordsItems,
    certificateOfCompletionOfConfirmationItems,
    certificateOfFinalInspectionItems,
    cityPlanningAreaItems,
    firePreventionDistrictsItems,
    MAX_PRICE_INTEGER,
    modeOfTransactionItems,
    realestateLegalComplianceTypeItems,
    realestateSeismicStandardTypeItems,
    salesDestinationTypeItems,
    saleTimeTypeItems,
    structureTypeItems,
    surveyDrawingItems,
    taxType,
    zoningTypeItems,
} from '~/types/enum/enum'
import { ApplicationError, BadRequest, IApplicationError } from '~/types/error'
import { calcYearsBuilt, fullCurrencyFormat, sizeToTsubo, tsuboToSize } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUserHaveConnectionContract, selectLoginUserHaveMarketContract } from '~/util/store/userSlice'

import { CButton } from '../../common/cButton/CButton'
import { CDropDown } from '../../common/cDropdown/CDropdown'
import { CTextInput } from '../../common/cTextInput/CTextInput'
import { CRealestateMarketPreview } from '../realestate/CRealestateMarketPreview'

export type CRealestateEditViewProps = {
    useTypeList: RealEstateUseTypeEntities[]
    form: ClientRealEstateInsertDto
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (key: string | string[], value: unknown | unknown[]) => void
    errors: IApplicationError[]
    putMarker: (lat: number, lng: number) => void
    isConnectionFeature: boolean
    isMarketFeature: boolean
    scrollRef: Ref<HTMLDivElement>
    marker?: { position: google.maps.LatLngLiteral }
    putMarkerFromAddress: () => void
    setIsShowFinishPublishMarket: (val: boolean) => void
    calcGrossRate: () => void
    onKeyDownAddressTextBox: (e: KeyboardEvent<HTMLInputElement>) => void
} & CRealestateEditProps

export const CRealestateEditView: VFC<CRealestateEditViewProps> = ({
    visible,
    useTypeList,
    form,
    onRequestClose,
    onRequestConfirm,
    setProps,
    errors,
    putMarker,
    marker,
    isConnectionFeature,
    isMarketFeature,
    scrollRef,
    realEstate,
    setIsShowFinishPublishMarket,
    calcGrossRate,
    onKeyDownAddressTextBox,
}) => {
    const [autoConvert, setAutoConvert] = useState(false)
    const RequiredLabel = (
        <>
            <div className={clsx('bg-red-700', 'text-white', 'text-xs', 'rounded', 'px-2', 'py-0.5')}>
                {t('CRealestateEdit.必須')}
            </div>
        </>
    )
    const HideIcon = (
        <div className={clsx('text-xs', 'bg-kimar-accent', 'text-gray-700', 'rounded', 'px-2', 'py-0.5')}>
            <p>{t('CRealestateEdit.紹介先非開示')}</p>
        </div>
    )

    const footer = (
        <>
            <CButton className={clsx('c-button-secondary')} onClick={() => onRequestClose()}>
                {t('Button.キャンセル')}
            </CButton>
            <CButton className={clsx('c-button-primary')} onClick={() => onRequestConfirm()}>
                {realEstate ? t('Button.編集確定') : t('Button.登録する')}
            </CButton>
        </>
    )

    return (
        <CFooterModal
            footer={footer}
            visible={visible}
            content={{ width: '95%', height: '95%' }}
            closeOnOverlayClick={false}
            onRequestClose={() => onRequestClose()}>
            <div className={clsx('w-full', 'h-full', 'text-sm')}>
                <div className={clsx('px-8', 'py-2', 'flex', 'flex-col', 'space-y-4', 'overflow-x-hidden')}>
                    <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>
                        {realEstate ? t('CRealestateEdit.物件を編集') : t('CRealestateEdit.物件を登録')}
                    </div>
                    <div
                        className={clsx(
                            'p-4',
                            'flex',
                            'flex-col',
                            'w-full',
                            'mx-auto',
                            'space-y-4',
                            'rounded',
                            !isMarketFeature || !isConnectionFeature ? 'bg-gray-50' : 'bg-white',
                        )}>
                        <div className={clsx('flex', 'items-end')}>
                            <div className={clsx('font-bold', 'mr-4', 'text-base')}>
                                {t('CRealestateEdit.マーケット掲載設定')}
                            </div>
                            <a
                                href="https://drive.google.com/file/d/1USiJCjJwpHpn9OXDliHlPezWzqUQDjP_/view?usp=sharing"
                                target={'_blank'}
                                className={clsx('underline', 'text-kimar-primary', 'text-sm')}>
                                {t('CRealestateEdit.詳細はこちら')}
                            </a>
                        </div>
                        <div className={clsx('border-l', 'my-2')}>
                            <div>
                                <div className={clsx('flex', 'items-end', 'justify-between', 'mb-4', 'pl-4', 'max-w-md')}>
                                    <div className={clsx('mr-4')}>
                                        {t('CRealestateEdit.レコメンド／コネクション対象物件にしますか？')}
                                    </div>
                                    <CToggleSwitch
                                        width="lg"
                                        value={form.isRecommendTarget}
                                        disabled={!isMarketFeature || !isConnectionFeature || !!realEstate?.ended}
                                        trueLabel={'はい'}
                                        falseLabel={'いいえ'}
                                        toggleChanged={(value) => {
                                            setProps('isRecommendTarget', value)
                                        }}
                                    />
                                </div>
                                <div className={clsx('flex', 'items-end', 'justify-between', 'mb-4', 'pl-4', 'max-w-md')}>
                                    <div className={clsx('mr-4')}>{t('CRealestateEdit.キマールマーケットに掲載しますか？')}</div>
                                    <CToggleSwitch
                                        width="lg"
                                        value={form.isMarketTarget}
                                        disabled={!isMarketFeature || !isConnectionFeature || !!realEstate?.ended}
                                        trueLabel={'はい'}
                                        falseLabel={'いいえ'}
                                        toggleChanged={(value) => {
                                            if (realEstate?.markets?.length && value === false) setIsShowFinishPublishMarket(true)
                                            else setIsShowFinishPublishMarket(false)
                                            setProps('isMarketTarget', value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {!!realEstate?.ended && (
                            <CMessage warning>
                                <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-2', 'justify-between')}>
                                    <p>
                                        {t(
                                            'CRealestateEdit.物件終了済のため、レコメンド／コネクション／マーケット機能は利用できません。',
                                        )}
                                    </p>
                                </div>
                            </CMessage>
                        )}
                        {(!isMarketFeature || !isConnectionFeature) && (
                            <CMessage warning>
                                <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-2', 'justify-between')}>
                                    <p>
                                        {t(
                                            'CRealestateEdit.ご契約のプランではマーケット機能はお使いいただけません。マーケット機能を有効にして、新規物件と新規人脈を手に入れましょう。',
                                        )}
                                    </p>
                                    <CLinkButton
                                        to="/market"
                                        blank
                                        className={clsx('c-button-market', 'w-full', 'md:w-auto', 'whitespace-nowrap')}>
                                        {t('CRealestateEdit.マーケットとは？')}
                                    </CLinkButton>
                                </div>
                            </CMessage>
                        )}
                        {isMarketFeature && form.isMarketTarget && (
                            <div className={clsx('bg-gray-100', 'p-2', 'flex', 'flex-col', 'gap-y-2')}>
                                <p>マーケット掲載時のプレビュー</p>
                                <div className={clsx('max-w-2xl', 'w-full', 'mx-auto')}>
                                    <CRealestateMarketPreview item={form} />
                                </div>
                            </div>
                        )}
                    </div>
                    <div
                        ref={scrollRef}
                        className={clsx('bg-gray-100', 'border', 'flex', 'justify-center', 'items-center', 'space-x-1', 'p-4')}>
                        <div>{RequiredLabel} </div>
                        <div>{t('CRealestateEdit.入力が必須の項目')}</div>
                        <div className={clsx('w-4')} />
                        <div>{HideIcon}</div>
                        <div>{t('CRealestateEdit.紹介先には表示されない項目')}</div>
                    </div>
                    <CError errors={errors} />
                    <div>
                        <div className={clsx('min-w-[600px]', 'w-full')}>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-2',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'space-x-2',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    <div>{t('CRealestateEdit.物件名')}</div>
                                    {RequiredLabel}
                                </div>
                                <div className={clsx('col-span-5', 'p-1')}>
                                    <CTextInput
                                        type={'text'}
                                        placeholder={t('CRealestateEdit.入力してください')}
                                        text={form.name}
                                        className={clsx('w-full', 'c-text-input-base')}
                                        onChange={(value) => setProps('name', value)}
                                    />
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.所在地')}
                                </div>
                                <div className={clsx('col-span-6')}>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'space-x-2',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            <div>{t('CRealestateEdit.住所')}</div>
                                            {RequiredLabel}
                                        </div>
                                        <div className={clsx('col-span-5', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                placeholder={t('CRealestateEdit.東京都千代田区丸の内１丁目２−３')}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.address}
                                                onChange={(value) => setProps('address', value)}
                                                onKeyDown={(e) => onKeyDownAddressTextBox(e)}
                                            />
                                            <div className={clsx('text-gray-500')}>
                                                {t(
                                                    'CRealestateEdit.住所入力後にエンターキーを押すと、入力した住所に地図ピンを移動できます。',
                                                )}
                                            </div>
                                            <GoogleMap
                                                mapContainerStyle={{
                                                    height: '300px',
                                                    width: '100%',
                                                }}
                                                center={
                                                    marker
                                                        ? marker.position
                                                        : { lat: form.latitude ?? 0, lng: form.longitude ?? 0 }
                                                }
                                                zoom={17}
                                                onClick={(e) => putMarker(e.latLng?.lat() ?? 0, e.latLng?.lng() ?? 0)}>
                                                {!!marker && <Marker position={marker.position} />}
                                            </GoogleMap>
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.地番')}
                                        </div>
                                        <div className={clsx('col-span-5', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                placeholder={t('CRealestateEdit.東京都千代田区丸の内１２３番４')}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.parcelNumber}
                                                onChange={(value) => setProps('parcelNumber', value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-2',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'space-x-2',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    <div>{t('CRealestateEdit.種別')}</div>
                                    {isMarketFeature && (form.isMarketTarget || form.isRecommendTarget) && RequiredLabel}
                                </div>
                                <div className={clsx('col-span-2', 'p-1')}>
                                    <CDropDown
                                        items={useTypeList}
                                        dataLabel={'name'}
                                        dataValue={'uuid'}
                                        nowValue={form.useType.uuid}
                                        onChange={(value) =>
                                            setProps(
                                                'useType',
                                                useTypeList.find((u) => u.uuid === value),
                                            )
                                        }
                                        className={clsx('w-full', 'c-dropdown-base')}
                                    />
                                </div>
                                <div
                                    className={clsx(
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'space-x-2',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    <div>{t('CRealestateEdit.売却時期')}</div>
                                    {isMarketFeature && (form.isMarketTarget || form.isRecommendTarget) && RequiredLabel}
                                </div>
                                <div className={clsx('col-span-2', 'p-1')}>
                                    <CDropDown
                                        items={saleTimeTypeItems}
                                        allowUnselected
                                        nowValue={String(form.saleTimeType)}
                                        onChange={(value) => setProps('saleTimeType', Number(value))}
                                        className={clsx('w-full', 'c-dropdown-base')}
                                    />
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-2',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'flex-col',
                                        'gap-1',
                                        'justify-center',
                                        'items-center',
                                        'p-1',
                                    )}>
                                    <div className={clsx('flex', 'gap-2')}>
                                        <div>{t('CRealestateEdit.物件価格')}</div>
                                        <div>
                                            {isMarketFeature && (form.isMarketTarget || form.isRecommendTarget) && RequiredLabel}
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'gap-2')}>{HideIcon}</div>
                                </div>
                                <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                                    <CNumberInput
                                        className={clsx('c-text-input-base')}
                                        value={form.price}
                                        decimalScale={0}
                                        onChange={(value) => setProps('price', value)}
                                    />
                                    <span className={clsx('ml-2')}>{t('Unit.円')}</span>
                                    <span className={clsx('ml-2')}>
                                        ({fullCurrencyFormat(form.price)}
                                        {t('Unit.円')})
                                    </span>
                                    <span>
                                        <CDropDown
                                            items={taxType}
                                            allowUnselected
                                            unselectedLabel={'--'}
                                            nowValue={String(form.taxType)}
                                            onChange={(value) => setProps('taxType', Number(value))}
                                            className={clsx('w-full', 'c-dropdown-base')}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-2',
                                        'relative',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'space-x-2',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    <div>{t('CRealestateEdit.竣工日')}</div>
                                    {isMarketFeature &&
                                        (form.isMarketTarget || form.isRecommendTarget) &&
                                        // 竣工日の必須ラベルは種別が土地・その他でない場合に付与する
                                        form.useType.name !== '土地' &&
                                        form.useType.name !== 'その他' &&
                                        RequiredLabel}
                                    <CGuideTips tooltipDirection="right" color="text-gray-100">
                                        {t('CRealestateEdit.「年」や「年月」だけでも登録できます')}
                                    </CGuideTips>
                                </div>
                                <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                                    <CYearMonthDay
                                        forceReturnValue
                                        value={dayjs(form.buildAt).toDate()}
                                        type={!form.buildAtDateType ? 'date' : form.buildAtDateType === 1 ? 'month' : 'year'}
                                        jc
                                        onChange={(val, type) => {
                                            setProps(
                                                ['buildAt', 'buildAtDateType'],
                                                [
                                                    val ? dayjs(val).toISOString() : undefined,
                                                    type === 'date' ? 0 : type === 'month' ? 1 : 2,
                                                ],
                                            )
                                        }}
                                    />
                                    <span className={clsx('ml-2')}>{calcYearsBuilt(form.buildAt)}</span>
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-2',
                                        'relative',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'flex-col',
                                        'justify-center',
                                        'items-center',
                                        'p-1',
                                    )}>
                                    <div className={clsx('flex', 'items-center')}>
                                        <div>{t('CRealestateEdit.マーケット掲載コメント')}</div>
                                        <CGuideTips tooltipDirection="right" color="text-gray-100">
                                            {t('CRealestateEdit.25文字以内で入力してください')}
                                        </CGuideTips>
                                    </div>
                                    <div className={clsx('flex', 'items-center')}>{HideIcon}</div>
                                </div>
                                <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                                    <CTextInput
                                        placeholder={
                                            isMarketFeature && form.isMarketTarget
                                                ? t('CRealestateEdit.駅まで徒歩数分で、通勤、通学、お買い物に便利な立地')
                                                : t('CRealestateEdit.マーケット機能を有効にすると入力できます')
                                        }
                                        className={clsx('w-full', 'c-text-input-base')}
                                        text={form.marketComment}
                                        disabled={!isMarketFeature || !form.isMarketTarget}
                                        onChange={(value) => setProps('marketComment', value)}
                                        type={'text'}
                                    />
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-2',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.交通')}
                                </div>
                                <div className={clsx('col-span-5', 'p-1')}>
                                    <CTextInput
                                        placeholder={t('CRealestateEdit.JR山手線 東京駅 徒歩1分')}
                                        type={'multiline'}
                                        className={clsx('w-full', 'c-text-input-base')}
                                        text={form.station}
                                        onChange={(value) => setProps('station', value)}
                                    />
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.土地')}
                                </div>
                                <div className={clsx('col-span-6')}>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.地目')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.landCategory}
                                                onChange={(value) => setProps('landCategory', value)}
                                            />
                                        </div>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.敷地形状')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.landShape}
                                                onChange={(value) => setProps('landShape', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.権利')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CTextInput
                                                placeholder={t('CRealestateEdit.所有権、借地権など')}
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.landRight}
                                                onChange={(value) => setProps('landRight', value)}
                                            />
                                        </div>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.測量図')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={surveyDrawingItems}
                                                allowUnselected
                                                nowValue={form.surveyDrawing}
                                                onChange={(value) => setProps('surveyDrawing', value)}
                                                className={clsx('w-full', 'c-dropdown-base')}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.公簿面積')}
                                        </div>
                                        <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                                            <div className={clsx('flex', 'items-center', 'p-1')}>
                                                <CNumberInput
                                                    className={clsx('c-text-input-base', 'w-40')}
                                                    value={form.registeredLandSize}
                                                    placeholder="0.00"
                                                    decimalScale={2}
                                                    onChange={(value) => {
                                                        setAutoConvert(true)
                                                        setProps('registeredLandSize', value)
                                                    }}
                                                    onBlur={() => {
                                                        if (autoConvert) {
                                                            setProps(
                                                                'registeredLandSizeTsubo',
                                                                sizeToTsubo(
                                                                    isNaN(Number(form.registeredLandSize))
                                                                        ? 0
                                                                        : Number(form.registeredLandSize),
                                                                ) || undefined,
                                                            )
                                                        }
                                                        setAutoConvert(false)
                                                    }}
                                                />
                                                <span className={clsx('ml-1')}>{t('Unit.㎡')}</span>
                                            </div>
                                            <div className={clsx('mx-2', 'flex', 'flex-col', 'items-center')}>
                                                <div className={clsx('text-xs')}>{t('CRealestateEdit.自動変換')}</div>
                                                <img src="/src/assets/image/page/realestate/left-right-arrow.svg" alt="arrow" />
                                            </div>
                                            <div className={clsx('flex', 'items-center', 'p-1')}>
                                                <CNumberInput
                                                    className={clsx('c-text-input-base', 'w-40')}
                                                    value={form.registeredLandSizeTsubo}
                                                    placeholder="0.00"
                                                    decimalScale={2}
                                                    onChange={(value) => {
                                                        setAutoConvert(true)
                                                        setProps('registeredLandSizeTsubo', value)
                                                    }}
                                                    onBlur={() => {
                                                        if (autoConvert) {
                                                            setProps(
                                                                'registeredLandSize',
                                                                tsuboToSize(
                                                                    isNaN(Number(form.registeredLandSizeTsubo))
                                                                        ? 0
                                                                        : Number(form.registeredLandSizeTsubo),
                                                                ) || undefined,
                                                            )
                                                        }
                                                        setAutoConvert(false)
                                                    }}
                                                />
                                                <span className={clsx('ml-1')}>{t('Unit.坪')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.実測面積')}
                                        </div>
                                        <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                                            <div className={clsx('flex', 'items-center', 'p-1')}>
                                                <CNumberInput
                                                    className={clsx('c-text-input-base', 'w-40')}
                                                    value={form.surveyedLandSize}
                                                    placeholder="0.00"
                                                    decimalScale={2}
                                                    onChange={(value) => {
                                                        setAutoConvert(true)
                                                        setProps('surveyedLandSize', value)
                                                    }}
                                                    onBlur={() => {
                                                        if (autoConvert) {
                                                            setProps(
                                                                'surveyedLandSizeTsubo',
                                                                sizeToTsubo(
                                                                    isNaN(Number(form.surveyedLandSize))
                                                                        ? 0
                                                                        : Number(form.surveyedLandSize),
                                                                ) || undefined,
                                                            )
                                                        }
                                                        setAutoConvert(false)
                                                    }}
                                                />
                                                <span className={clsx('ml-1')}>{t('Unit.㎡')}</span>
                                            </div>
                                            <div className={clsx('mx-2', 'flex', 'flex-col', 'items-center')}>
                                                <div className={clsx('text-xs')}>{t('CRealestateEdit.自動変換')}</div>
                                                <img src="/src/assets/image/page/realestate/left-right-arrow.svg" alt="arrow" />
                                            </div>
                                            <div className={clsx('flex', 'items-center', 'p-1')}>
                                                <CNumberInput
                                                    className={clsx('c-text-input-base', 'w-40')}
                                                    value={form.surveyedLandSizeTsubo}
                                                    placeholder="0.00"
                                                    decimalScale={2}
                                                    onChange={(value) => {
                                                        setAutoConvert(true)
                                                        setProps('surveyedLandSizeTsubo', value)
                                                    }}
                                                    onBlur={() => {
                                                        if (autoConvert) {
                                                            setProps(
                                                                'surveyedLandSize',
                                                                tsuboToSize(
                                                                    isNaN(Number(form.surveyedLandSizeTsubo))
                                                                        ? 0
                                                                        : Number(form.surveyedLandSizeTsubo),
                                                                ) || undefined,
                                                            )
                                                        }
                                                        setAutoConvert(false)
                                                    }}
                                                />
                                                <span className={clsx('ml-1')}>{t('Unit.坪')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.前面道路情報')}
                                        </div>
                                        <div className={clsx('col-span-5', 'p-1')}>
                                            <CTextInput
                                                type={'multiline'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.frontalRoad}
                                                onChange={(value) => setProps('frontalRoad', value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.建物')}
                                </div>
                                <div className={clsx('col-span-6')}>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.権利')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.buildingRight}
                                                onChange={(value) => setProps('buildingRight', value)}
                                            />
                                        </div>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.家屋番号')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.buildingNumber}
                                                onChange={(value) => setProps('buildingNumber', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.耐震基準')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={realestateSeismicStandardTypeItems}
                                                allowUnselected
                                                className={clsx('w-full', 'c-dropdown-base')}
                                                nowValue={form.seismicStandardType}
                                                onChange={(value) => setProps('seismicStandardType', Number(value))}
                                            />
                                        </div>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.遵法性')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={realestateLegalComplianceTypeItems}
                                                allowUnselected
                                                className={clsx('w-full', 'c-dropdown-base')}
                                                nowValue={form.legalComplianceType}
                                                onChange={(value) => setProps('legalComplianceType', Number(value))}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.建物名')}
                                        </div>
                                        <div className={clsx('col-span-5', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.buildingName}
                                                onChange={(value) => setProps('buildingName', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.建築主')}
                                        </div>
                                        <div className={clsx('col-span-5', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.builder}
                                                onChange={(value) => setProps('builder', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.総戸数')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CNumberInput
                                                className={clsx('w-full', 'c-text-input-base')}
                                                value={form.totalUnits}
                                                decimalScale={0}
                                                onChange={(value) => setProps('totalUnits', value)}
                                            />
                                        </div>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.構造')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={structureTypeItems}
                                                allowUnselected
                                                className={clsx('w-full', 'c-dropdown-base')}
                                                nowValue={form.structureType}
                                                onChange={(value) => setProps('structureType', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.延床面積')}
                                        </div>
                                        <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                                            <div className={clsx('flex', 'items-center', 'p-1')}>
                                                <CNumberInput
                                                    className={clsx('c-text-input-base', 'w-40')}
                                                    value={form.totalFloorSize}
                                                    placeholder="0.00"
                                                    decimalScale={2}
                                                    onChange={(value) => {
                                                        setAutoConvert(true)
                                                        setProps('totalFloorSize', value)
                                                    }}
                                                    onBlur={() => {
                                                        if (autoConvert) {
                                                            setProps(
                                                                'totalFloorSizeTsubo',
                                                                sizeToTsubo(
                                                                    isNaN(Number(form.totalFloorSize))
                                                                        ? 0
                                                                        : Number(form.totalFloorSize),
                                                                ) || undefined,
                                                            )
                                                        }
                                                        setAutoConvert(false)
                                                    }}
                                                />
                                                <span className={clsx('ml-1')}>{t('Unit.㎡')}</span>
                                            </div>
                                            <div className={clsx('mx-2', 'flex', 'flex-col', 'items-center')}>
                                                <div className={clsx('text-xs')}>{t('CRealestateEdit.自動変換')}</div>
                                                <img src="/src/assets/image/page/realestate/left-right-arrow.svg" alt="arrow" />
                                            </div>
                                            <div className={clsx('flex', 'items-center', 'p-1')}>
                                                <CNumberInput
                                                    className={clsx('c-text-input-base', 'w-40')}
                                                    value={form.totalFloorSizeTsubo}
                                                    placeholder="0.00"
                                                    onChange={(value) => {
                                                        setAutoConvert(true)
                                                        setProps('totalFloorSizeTsubo', value)
                                                    }}
                                                    onBlur={() => {
                                                        if (autoConvert) {
                                                            setProps(
                                                                'totalFloorSize',
                                                                tsuboToSize(
                                                                    isNaN(Number(form.totalFloorSizeTsubo))
                                                                        ? 0
                                                                        : Number(form.totalFloorSizeTsubo),
                                                                ) || undefined,
                                                            )
                                                        }
                                                        setAutoConvert(false)
                                                    }}
                                                />
                                                <span className={clsx('ml-1')}>{t('Unit.坪')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.賃貸可能面積')}
                                        </div>
                                        <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                                            <div className={clsx('flex', 'items-center', 'p-1')}>
                                                <CNumberInput
                                                    className={clsx('c-text-input-base', 'w-40')}
                                                    value={form.rentableAreaSize}
                                                    placeholder="0.00"
                                                    decimalScale={2}
                                                    onChange={(value) => {
                                                        setAutoConvert(true)
                                                        setProps('rentableAreaSize', value)
                                                    }}
                                                    onBlur={() => {
                                                        if (autoConvert) {
                                                            setProps(
                                                                'rentableAreaSizeTsubo',
                                                                sizeToTsubo(
                                                                    isNaN(Number(form.rentableAreaSize))
                                                                        ? 0
                                                                        : Number(form.rentableAreaSize),
                                                                ) || undefined,
                                                            )
                                                        }
                                                        setAutoConvert(false)
                                                    }}
                                                />
                                                <span className={clsx('ml-1')}>{t('Unit.㎡')}</span>
                                            </div>
                                            <div className={clsx('mx-2', 'flex', 'flex-col', 'items-center')}>
                                                <div className={clsx('text-xs')}>{t('CRealestateEdit.自動変換')}</div>
                                                <img src="/src/assets/image/page/realestate/left-right-arrow.svg" alt="arrow" />
                                            </div>
                                            <div className={clsx('flex', 'items-center', 'p-1')}>
                                                <CNumberInput
                                                    className={clsx('c-text-input-base', 'w-40')}
                                                    value={form.rentableAreaSizeTsubo}
                                                    placeholder="0.00"
                                                    decimalScale={2}
                                                    onChange={(value) => {
                                                        setAutoConvert(true)
                                                        setProps('rentableAreaSizeTsubo', value)
                                                    }}
                                                    onBlur={() => {
                                                        if (autoConvert) {
                                                            setProps(
                                                                'rentableAreaSize',
                                                                tsuboToSize(
                                                                    Number(
                                                                        isNaN(Number(form.rentableAreaSizeTsubo))
                                                                            ? 0
                                                                            : form.rentableAreaSizeTsubo,
                                                                    ),
                                                                ) || undefined,
                                                            )
                                                        }
                                                        setAutoConvert(false)
                                                    }}
                                                />
                                                <span className={clsx('ml-1')}>{t('Unit.坪')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.地上階数')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CNumberInput
                                                className={clsx('w-full', 'c-text-input-base')}
                                                value={form.floor}
                                                decimalScale={0}
                                                onChange={(value) => setProps('floor', value)}
                                            />
                                        </div>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.地下階数')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CNumberInput
                                                className={clsx('w-full', 'c-text-input-base')}
                                                value={form.undergroundFloor}
                                                decimalScale={0}
                                                onChange={(value) => setProps('undergroundFloor', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.設備')}
                                        </div>
                                        <div className={clsx('col-span-5', 'p-1')}>
                                            <CTextInput
                                                placeholder={t('CRealestateEdit.駐車場、駐輪場など')}
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.facility}
                                                onChange={(value) => setProps('facility', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.検査済証')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={certificateOfFinalInspectionItems}
                                                allowUnselected
                                                nowValue={form.certificateOfFinalInspection}
                                                onChange={(value) => setProps('certificateOfFinalInspection', value)}
                                                className={clsx('w-full', 'c-dropdown-base')}
                                            />
                                        </div>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.確認済証')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={certificateOfCompletionOfConfirmationItems}
                                                allowUnselected
                                                nowValue={form.certificateOfCompletionOfConfirmation}
                                                onChange={(value) => setProps('certificateOfCompletionOfConfirmation', value)}
                                                className={clsx('w-full', 'c-dropdown-base')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.公法規則')}
                                </div>
                                <div className={clsx('col-span-6')}>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.都市計画')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={cityPlanningAreaItems}
                                                allowUnselected
                                                nowValue={form.cityPlanningArea}
                                                onChange={(value) => setProps('cityPlanningArea', value)}
                                                className={clsx('w-full', 'c-dropdown-base')}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.用途地域１')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={zoningTypeItems}
                                                allowUnselected
                                                nowValue={form.zoningType}
                                                onChange={(value) => setProps('zoningType', value)}
                                                className={clsx('w-full', 'c-dropdown-base')}
                                            />
                                        </div>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.用途地域２')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={zoningTypeItems}
                                                allowUnselected
                                                nowValue={form.zoningType2}
                                                onChange={(value) => setProps('zoningType2', value)}
                                                className={clsx('w-full', 'c-dropdown-base')}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.建ぺい率１')}
                                        </div>
                                        <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                            <CNumberInput
                                                className={clsx('w-full', 'c-text-input-base')}
                                                value={form.buildingRate}
                                                decimalScale={2}
                                                onChange={(value) => setProps('buildingRate', value)}
                                            />
                                            <span className={clsx('ml-1')}>{t('Unit.％')}</span>
                                        </div>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.建ぺい率２')}
                                        </div>
                                        <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                            <CNumberInput
                                                className={clsx('w-full', 'c-text-input-base')}
                                                value={form.buildingRate2}
                                                decimalScale={2}
                                                onChange={(value) => setProps('buildingRate2', value)}
                                            />
                                            <span className={clsx('ml-1')}>{t('Unit.％')}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.容積率１')}
                                        </div>
                                        <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                            <CNumberInput
                                                className={clsx('w-full', 'c-text-input-base')}
                                                value={form.floorAreaRatio}
                                                decimalScale={2}
                                                onChange={(value) => setProps('floorAreaRatio', value)}
                                            />
                                            <span className={clsx('ml-1')}>{t('Unit.％')}</span>
                                        </div>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.容積率２')}
                                        </div>
                                        <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                            <CNumberInput
                                                className={clsx('w-full', 'c-text-input-base')}
                                                value={form.floorAreaRatio2}
                                                decimalScale={2}
                                                onChange={(value) => setProps('floorAreaRatio2', value)}
                                            />
                                            <span className={clsx('ml-1')}>{t('Unit.％')}</span>
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.防火地域')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={firePreventionDistrictsItems}
                                                allowUnselected
                                                nowValue={form.firePreventionDistricts}
                                                onChange={(value) => setProps('firePreventionDistricts', value)}
                                                className={clsx('w-full', 'c-dropdown-base')}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.高度規制')}
                                        </div>
                                        <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.heightControl}
                                                onChange={(value) => setProps('heightControl', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.その他規制')}
                                        </div>
                                        <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.otherControl}
                                                onChange={(value) => setProps('otherControl', value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.収支')}
                                </div>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.想定収入／年')}
                                </div>
                                <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                    <CNumberInput
                                        className={clsx('w-full', 'c-text-input-base')}
                                        value={form.assumeIncome}
                                        decimalScale={0}
                                        onChange={(value) => setProps('assumeIncome', value)}
                                    />
                                    <span className={clsx('ml-1')}>{t('Unit.円')}</span>
                                </div>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.現況収入／年')}
                                </div>
                                <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                    <CNumberInput
                                        className={clsx('w-full', 'c-text-input-base')}
                                        value={form.presentIncome}
                                        decimalScale={0}
                                        onChange={(value) => setProps('presentIncome', value)}
                                    />
                                    <span className={clsx('ml-1')}>{t('Unit.円')}</span>
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.利回り')}
                                </div>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.表面利回り')}
                                </div>
                                <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                    <CNumberInput
                                        className={clsx('w-full', 'c-text-input-base')}
                                        value={form.grossRate}
                                        decimalScale={2}
                                        onChange={(value) => setProps('grossRate', value)}
                                    />
                                    <span className={clsx('ml-1')}>{t('Unit.％')}</span>
                                    <CButton
                                        className={clsx('c-button-secondary', 'whitespace-nowrap')}
                                        disabled={!form?.assumeIncome || !form?.price}
                                        onClick={() => calcGrossRate()}>
                                        {t('CRealestateEdit.自動計算')}
                                    </CButton>
                                </div>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.実質利回り')}
                                </div>
                                <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                    <CNumberInput
                                        className={clsx('w-full', 'c-text-input-base')}
                                        value={form.netRate}
                                        decimalScale={2}
                                        onChange={(value) => setProps('netRate', value)}
                                    />
                                    <span className={clsx('ml-1')}>{t('Unit.％')}</span>
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-7', 'mb-0.5')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateEdit.その他')}
                                </div>
                                <div className={clsx('col-span-6')}>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.引渡日')}
                                        </div>
                                        <div className={clsx('col-span-5')}>
                                            <CYearMonthDay
                                                forceReturnValue
                                                value={dayjs(form.deliveryAt).toDate()}
                                                type={
                                                    !form.deliveryAtDateType
                                                        ? 'date'
                                                        : form.deliveryAtDateType === 1
                                                        ? 'month'
                                                        : 'year'
                                                }
                                                jc
                                                onChange={(val, type) => {
                                                    setProps(
                                                        ['deliveryAt', 'deliveryAtDateType'],
                                                        [
                                                            val ? dayjs(val).toISOString() : undefined,
                                                            type === 'date' ? 0 : type === 'month' ? 1 : 2,
                                                        ],
                                                    )
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.取引態様')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={modeOfTransactionItems}
                                                allowUnselected
                                                nowValue={String(form.modeOfTransaction)}
                                                onChange={(value) => setProps('modeOfTransaction', Number(value))}
                                                className={clsx('w-full', 'c-dropdown-base')}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'flex-col',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.情報取得元')}
                                            {HideIcon}
                                        </div>
                                        <div className={clsx('col-span-5', 'p-1')}>
                                            <CTextInput
                                                type={'multiline'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.informationSource}
                                                onChange={(value) => setProps('informationSource', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.先数')}
                                        </div>
                                        <div className={clsx('col-span-2', 'p-1')}>
                                            <CDropDown
                                                items={salesDestinationTypeItems}
                                                allowUnselected
                                                nowValue={String(form.salesDestinationType)}
                                                onChange={(value) => setProps('salesDestinationType', Number(value))}
                                                className={clsx('w-full', 'c-dropdown-base')}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'flex-col',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.情報取得日')}
                                            {HideIcon}
                                        </div>
                                        <div className={clsx('col-span-5', 'flex', 'space-x-2')}>
                                            <CYearMonthDay
                                                value={dayjs(form.acquiredAt).toDate()}
                                                jc
                                                onChange={(val) =>
                                                    setProps('acquiredAt', val ? dayjs(val).toISOString() : undefined)
                                                }
                                            />
                                            <CButton
                                                className={clsx('c-button-secondary')}
                                                onClick={() => setProps('acquiredAt', dayjs().toISOString())}>
                                                {t('CRealestateEdit.今日に設定')}
                                            </CButton>
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6', 'mb-0.5')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'flex-col',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.売主')}
                                            {HideIcon}
                                        </div>
                                        <div className={clsx('col-span-5', 'p-1')}>
                                            <CTextInput
                                                type={'text'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.seller}
                                                onChange={(value) => setProps('seller', value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-6')}>
                                        <div
                                            className={clsx(
                                                'col-span-1',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'flex',
                                                'justify-center',
                                                'items-center',
                                            )}>
                                            {t('CRealestateEdit.備考')}
                                        </div>
                                        <div className={clsx('col-span-5', 'p-1')}>
                                            <CTextInput
                                                type={'multiline'}
                                                className={clsx('w-full', 'c-text-input-base')}
                                                text={form.other}
                                                onChange={(value) => setProps('other', value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CFooterModal>
    )
}

export type CRealestateEditProps = {
    visible: boolean
    realEstate?: RealEstateEntities
    onClose: () => void
    onSubmit: (realEstate: RealEstateEntities, isFinishMarket: boolean) => void
}

export const CRealestateEdit: VFC<CRealestateEditProps> = ({ ...props }) => {
    const { data: useTypeListCount } = useClientRealEstateUseTypeRealEstateUseTypeQuery({})
    const [visible, setVisible] = useState(props.visible)
    const [form, setForm] = useState<ClientRealEstateInsertDto | ClientRealEstateUpdateDto>()
    useEffect(() => {
        if (!useTypeListCount) return
        setErrors([])
        setForm({
            isRecommendTarget: props.realEstate?.ended
                ? false
                : isMarketFeature
                ? props.realEstate?.isRecommendTarget ?? true
                : false,
            isMarketTarget: props.realEstate?.ended
                ? false
                : isMarketFeature
                ? !!props.realEstate?.markets?.length || false
                : false,
            name: props.realEstate?.name ?? '',
            address: props.realEstate?.address ?? '',
            latitude: props.realEstate?.latitude ?? 35.681236,
            longitude: props.realEstate?.longitude ?? 139.767125,
            parcelNumber: props.realEstate?.parcelNumber ?? '',
            useType: props.realEstate?.useType ?? (useTypeListCount.list[0] as RealEstateUseTypeEntities),
            saleTimeType: props.realEstate?.saleTimeType ?? 0,
            price: props.realEstate?.price ?? undefined,
            taxType: props.realEstate?.taxType ?? undefined,
            buildAt: props.realEstate?.buildAt ?? '',
            buildAtDateType: props.realEstate?.buildAtDateType ?? 0,
            station: props.realEstate?.station ?? '',
            landCategory: props.realEstate?.landCategory ?? '',
            landShape: props.realEstate?.landShape ?? '',
            landRight: props.realEstate?.landRight ?? '',
            surveyDrawing: props.realEstate?.surveyDrawing ?? undefined,
            registeredLandSize: props.realEstate?.registeredLandSize ?? undefined,
            registeredLandSizeTsubo: props.realEstate?.registeredLandSizeTsubo ?? undefined,
            surveyedLandSize: props.realEstate?.surveyedLandSize ?? undefined,
            surveyedLandSizeTsubo: props.realEstate?.surveyedLandSizeTsubo ?? undefined,
            frontalRoad: props.realEstate?.frontalRoad ?? '',
            buildingRight: props.realEstate?.buildingRight ?? '',
            buildingNumber: props.realEstate?.buildingNumber ?? '',
            seismicStandardType: props.realEstate?.seismicStandardType ?? 0,
            legalComplianceType: props.realEstate?.legalComplianceType ?? 0,
            buildingName: props.realEstate?.buildingName ?? '',
            builder: props.realEstate?.builder ?? '',
            totalUnits: props.realEstate?.totalUnits ?? undefined,
            structureType: props.realEstate?.structureType ?? 0,
            floorSize: props.realEstate?.floorSize ?? undefined,
            floorSizeTsubo: props.realEstate?.floorSizeTsubo ?? undefined,
            totalFloorSize: props.realEstate?.totalFloorSize ?? undefined,
            totalFloorSizeTsubo: props.realEstate?.totalFloorSizeTsubo ?? undefined,
            rentableAreaSize: props.realEstate?.rentableAreaSize ?? undefined,
            rentableAreaSizeTsubo: props.realEstate?.rentableAreaSizeTsubo ?? undefined,
            floor: props.realEstate?.floor ?? undefined,
            undergroundFloor: props.realEstate?.undergroundFloor ?? undefined,
            facility: props.realEstate?.facility ?? '',
            certificateOfCompletionOfConfirmation: props.realEstate?.certificateOfCompletionOfConfirmation ?? undefined,
            certificateOfFinalInspection: props.realEstate?.certificateOfFinalInspection ?? undefined,
            cityPlanningArea: props.realEstate?.cityPlanningArea ?? undefined,
            zoningType: props.realEstate?.zoningType ?? 0,
            zoningType2: props.realEstate?.zoningType2 ?? undefined,
            buildingRate: props.realEstate?.buildingRate ?? undefined,
            buildingRate2: props.realEstate?.buildingRate2 ?? undefined,
            floorAreaRatio: props.realEstate?.floorAreaRatio ?? undefined,
            floorAreaRatio2: props.realEstate?.floorAreaRatio2 ?? undefined,
            firePreventionDistricts: props.realEstate?.firePreventionDistricts ?? undefined,
            heightControl: props.realEstate?.heightControl ?? '',
            otherControl: props.realEstate?.otherControl ?? '',
            assumeIncome: !isNil(props.realEstate?.assumeIncome) ? props.realEstate?.assumeIncome : undefined,
            presentIncome: !isNil(props.realEstate?.presentIncome) ? props.realEstate?.presentIncome : undefined,
            grossRate: !isNil(props.realEstate?.grossRate) ? props.realEstate?.grossRate : undefined,
            netRate: !isNil(props.realEstate?.netRate) ? props.realEstate?.netRate : undefined,
            deliveryAt: props.realEstate?.deliveryAt ?? '',
            deliveryAtDateType: props.realEstate?.deliveryAtDateType ?? 0,
            modeOfTransaction: props.realEstate?.modeOfTransaction ?? 0,
            informationSource: props.realEstate?.informationSource ?? '',
            salesDestinationType: props.realEstate?.salesDestinationType ?? 0,
            acquiredAt: props.realEstate?.acquiredAt ?? '',
            seller: props.realEstate?.seller ?? '',
            marketComment: props.realEstate?.marketComment ?? '',
            other: props.realEstate?.other ?? '',
        })
        setMarker({
            position: {
                lat: props.realEstate?.latitude ?? 35.681236,
                lng: props.realEstate?.longitude ?? 139.767125,
            },
        })
        setVisible(props.visible)
    }, [props.visible])

    // コネクション契約があるか
    const isConnectionFeature = useAppSelector(selectLoginUserHaveConnectionContract)
    const isMarketFeature = useAppSelector(selectLoginUserHaveMarketContract)

    const setProps = (key: string | string[], value: unknown | unknown[]) => {
        if (!form) return
        if (isArray(key) && isArray(value)) {
            let newForm = {
                ...form,
            }
            for (let i = 0; i < key.length; i++) {
                newForm = {
                    ...newForm,
                    [key[i] as string]: value[i] as unknown,
                }
            }
            setForm(newForm)
        } else {
            setForm({
                ...form,
                [key as string]: value as unknown,
            })
        }
    }

    const onRequestClose = () => {
        props.onClose()
    }

    const [insertRealEstateQuery] = useClientRealEstatePostRealEstateMutation()
    const [updateRealEstateQuery] = useClientRealEstatePutUuidMutation()
    const [errors, setErrors] = useState<Array<IApplicationError>>([])

    // バリデーション
    const validate = () => {
        if (!form) return false
        const errorList: Array<ApplicationError> = []
        if (!form.name) errorList.push(new BadRequest(t('CRealestateEdit.物件名が入力されていません')))
        if (!form.address) errorList.push(new BadRequest(t('CRealestateEdit.住所が入力されていません')))
        if (!areaTypeWithOutWordsItems.some((item) => form.address.startsWith(item.label)))
            errorList.push(new BadRequest(t('CRealestateEdit.住所は都道府県名を先頭に記載してください')))
        if (isMarketFeature && (form.isMarketTarget || form.isRecommendTarget)) {
            if (form.useType.name === '未設定') errorList.push(new BadRequest(t('CRealestateEdit.種別が入力されていません')))
            if (!form.saleTimeType) errorList.push(new BadRequest(t('CRealestateEdit.売却時期が入力されていません')))
            if (!form.price) errorList.push(new BadRequest(t('CRealestateEdit.物件価格が入力されていません')))
            if (form.useType.name !== '土地' && form.useType.name !== 'その他' && !form.buildAt)
                errorList.push(new BadRequest(t('CRealestateEdit.竣工日が選択されていません')))
            if (form.marketComment && form.marketComment.length > 25)
                errorList.push(new BadRequest(t('CRealestateEdit.マーケット掲載コメントは25文字以下で入力してください')))
        }
        if (form.price && (form.price >= MAX_PRICE_INTEGER || form.price < 0))
            errorList.push(new BadRequest(t('CRealestateEdit.物件価格に正しい価格を入力してください')))
        if (errorList.length > 0) {
            setErrors(errorList)
            scrollTop()
            return false
        }
        return true
    }

    const scrollRef = useRef<HTMLDivElement>(null)
    const scrollTop = () => {
        scrollRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const [isShowFinishPublishMarket, setIsShowFinishPublishMarket] = useState(false)
    const onRequestConfirm = async () => {
        if (!form) return
        try {
            setErrors([])
            if (!validate()) return
            if (!props.realEstate) {
                const realEstate = await insertRealEstateQuery({ clientRealEstateInsertDto: form }).unwrap()
                props.onSubmit(realEstate, isShowFinishPublishMarket)
                toast.success(t('CRealestateEdit.物件を登録しました'))
            } else {
                const realEstate = await updateRealEstateQuery({
                    uuid: props.realEstate.uuid,
                    clientRealEstateUpdateDto: form,
                }).unwrap()
                props.onSubmit(realEstate, isShowFinishPublishMarket)
                toast.success(t('CRealestateEdit.物件を編集しました'))
            }
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const [marker, setMarker] = useState<{
        position: {
            lat: number
            lng: number
        }
    }>()
    const putMarker = (lat: number, lng: number) => {
        if (!form) return
        setMarker({
            position: {
                lat,
                lng,
            },
        })
        setForm({
            ...form,
            latitude: lat,
            longitude: lng,
        })
    }

    const putMarkerFromAddress = async () => {
        if (!form || !marker || !form.address) return
        const geocoder = new google.maps.Geocoder()
        const result = await geocoder.geocode({ address: form.address })
        if (result.results.length) {
            const [lat, lng] = [result.results[0].geometry.location.lat(), result.results[0].geometry.location.lng()]
            setMarker({
                position: {
                    lat,
                    lng,
                },
            })
            setForm({
                ...form,
                latitude: lat,
                longitude: lng,
            })
        }
    }

    const calcGrossRate = () => {
        if (!form?.assumeIncome || !form?.price) return
        const value = round(form.assumeIncome / form.price, 4) * 100
        setProps('grossRate', round(value, 3))
    }

    const onKeyDownAddressTextBox = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) putMarkerFromAddress()
    }

    if (!useTypeListCount || !form) return null
    return (
        <CRealestateEditView
            {...props}
            visible={visible}
            form={form}
            useTypeList={useTypeListCount.list as RealEstateUseTypeEntities[]}
            onRequestConfirm={onRequestConfirm}
            onRequestClose={onRequestClose}
            setProps={setProps}
            errors={errors}
            putMarker={putMarker}
            marker={marker}
            scrollRef={scrollRef}
            isConnectionFeature={isConnectionFeature}
            isMarketFeature={isMarketFeature}
            putMarkerFromAddress={putMarkerFromAddress}
            setIsShowFinishPublishMarket={setIsShowFinishPublishMarket}
            calcGrossRate={calcGrossRate}
            onKeyDownAddressTextBox={onKeyDownAddressTextBox}
        />
    )
}
