import { ReactNode, useEffect, VFC } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { useClientAuthClientMeQuery } from '~/app/api'
import apiBase from '~/app/apiBase'
import { selectCredential, setCredentials } from '~/util/store/authSlice'
import { useAppDispatch, useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, setLoginUser } from '~/util/store/userSlice'

export type RouteAdminAuthGuardProps = {
    children?: ReactNode
}
export const RouteAdminAuthGuard: VFC<RouteAdminAuthGuardProps> = ({ ...props }: RouteAdminAuthGuardProps) => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const user = useAppSelector(selectLoginUser)
    const cred = useAppSelector(selectCredential)

    const { data: teamMember, isLoading, isError } = useClientAuthClientMeQuery(undefined, { skip: !!user && !!cred })
    useEffect(() => {
        if (!isLoading && teamMember) dispatch(setLoginUser(teamMember))
    }, [isLoading, teamMember])

    useEffect(() => {
        if (isError) {
            dispatch(apiBase.util.resetApiState()) // RTK Queryのキャッシュクリア
            if (user || cred) {
                dispatch(setCredentials({ credential: undefined }))
                dispatch(setLoginUser(undefined))
            }
            navigate(teamMember ? '/dashboard' : '/', { state: { from: location } })
        }
    }, [isError])

    /** render */
    const adminJsx = <>{props.children}</>
    const noAdminJsx = <Navigate to={teamMember ? '/dashboard' : '/'} state={{ from: location }} replace />
    if (user && cred && user.roles.system) return adminJsx
    if (isLoading) return <></>
    if (teamMember?.roles.system) return adminJsx
    return noAdminJsx
}
