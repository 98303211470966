import clsx from 'clsx'
import { isNil } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { IntermediaryToEntities, useClientIntermediaryVotePostIntermediaryVoteMutation } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CError } from '~/components/common/cError/CError'
import { interestList, otherRouteList } from '~/types/enum/enum'
import { IApplicationError } from '~/types/error'
import { useAppDispatch, useAppSelector } from '~/util/store/hooks'
import { selectLoginUser, selectOptOutIntermediaryVote, setOptOut } from '~/util/store/userSlice'

type Props = {
    intermediaryTo?: IntermediaryToEntities
    isVoteAvailable?: boolean
}

const CIntermediaryVote = ({ intermediaryTo, isVoteAvailable }: Props) => {
    const dispatch = useAppDispatch()
    const optOut = useAppSelector(selectOptOutIntermediaryVote)
    const user = useAppSelector(selectLoginUser)
    const [errors, setErrors] = useState<Array<IApplicationError> | undefined>(undefined)

    const voteAvailable = useMemo(() => {
        if (optOut) return false
        if (!intermediaryTo) return false
        if (!isVoteAvailable) return false
        if (intermediaryTo.intermediaryToVote?.length) return false
        if (user) return intermediaryTo.destinationTo?.relatedMember?.uuid === user.uuid

        return true
    }, [])

    const [isShow, setIsShow] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setIsShow(true)
        }, 2000)
    }, [])
    const [checked, setChecked] = useState(false)
    const neverShowComponent = () => {
        setChecked(!checked)
    }

    const [selectedOtherRouteList, setSelectedOtherRouteList] = useState<number>()
    const [selectedInterestList, setSelectedInterestList] = useState<number>()

    const [postVoteQuery] = useClientIntermediaryVotePostIntermediaryVoteMutation()

    useEffect(() => {
        setErrors(undefined)
        async function sending() {
            if (!intermediaryTo) return
            // 回答済みなら送信
            if (!isNil(selectedOtherRouteList) && !isNil(selectedInterestList)) {
                await postVoteQuery({
                    clientIntermediaryToVoteInsertDto: {
                        intermediaryUuid: intermediaryTo.uuid,
                        isAcquiredOtherRoute: !!selectedOtherRouteList,
                        isInterested: !!selectedInterestList,
                    },
                })
                setIsShow(false)
                if (checked) dispatch(setOptOut(['intermediaryVote', true]))
            }
        }
        sending()
    }, [selectedOtherRouteList, selectedInterestList])

    const close = async () => {
        setIsShow(false)
    }

    if (!voteAvailable || !isShow) return null

    return (
        <div className={clsx('fixed', 'bottom-0', 'right-0', 'shadow', 'bg-white', 'rounded', 'z-20', 'p-4')}>
            <div className={clsx('text-kimar-primary', 'flex', 'flex-col', 'items-center')}>
                <div className={clsx('font-bold')}>物件情報に関するアンケート</div>
                <div className={clsx('text-xs')}>※回答は紹介元に開示されます</div>
            </div>
            <div className={clsx('py-2')}>
                <div className={clsx('text-sm')}>物件情報の取得状況</div>
                <div className={clsx('grid', 'grid-cols-2', 'gap-2')}>
                    {otherRouteList.map((o) => {
                        return (
                            <div
                                key={o.value}
                                className={clsx(
                                    selectedOtherRouteList === o.value ? 'c-button-primary' : 'c-button-secondary',
                                    'cursor-pointer',
                                )}
                                onClick={() => setSelectedOtherRouteList(o.value)}>
                                {o.label}
                            </div>
                        )
                    })}
                </div>
                <div className={clsx('text-sm')}>興味度合い</div>
                <div className={clsx('grid', 'grid-cols-2', 'gap-2')}>
                    {interestList.map((i) => {
                        return (
                            <div
                                key={i.value}
                                className={clsx(
                                    selectedInterestList === i.value ? 'c-button-primary' : 'c-button-secondary',
                                    'cursor-pointer',
                                )}
                                onClick={() => setSelectedInterestList(i.value)}>
                                {i.label}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={clsx('flex', 'flex-col', 'items-center')}>
                <CCheckBox
                    checked={checked}
                    label={'この物件のアンケートは今後表示しない'}
                    onChange={() => neverShowComponent()}
                />
                <div className={clsx('py-2')}>
                    <CButton className={clsx('text-kimar-primary', 'underline')} onClick={() => close()}>
                        アンケートを閉じる
                    </CButton>
                </div>
            </div>
            {errors?.length && <CError errors={errors} />}
        </div>
    )
}

export default CIntermediaryVote
