import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { Link } from 'react-router-dom'

import { AdminFaqUpdateDto, useAdminFaqGetFaqListQuery, useAdminFaqPostFaqMutation, useAdminFaqPutUuidMutation } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CError } from '~/components/common/cError/CError'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CModal } from '~/components/common/cModal/CModal'
import CFaqContentEdit from '~/components/unique/admin/faq/CFaqContentEdit'
import { ApplicationError, IApplicationError } from '~/types/error'
import { pageTitleTemplate } from '~/util/common/common'

const AdminFaq = () => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    // FAQ初期状態
    const emptyFaq: AdminFaqUpdateDto = {
        uuid: '',
        question: '',
        answer: '',
        isDisplayHome: false,
        sort: 0,
    }
    // 選択したFAQ
    const [selectedFaq, setSelectedFaq] = useState<AdminFaqUpdateDto>(emptyFaq)
    // FAQ一覧を取得
    const { data: faqList, isLoading: isLoadingFaqList } = useAdminFaqGetFaqListQuery({})

    // 新規作成・編集モーダルvisible
    const [visibleChangeModal, setVisibleChangeModal] = useState(false)
    // 新規作成API
    const [postAPI] = useAdminFaqPostFaqMutation()
    // 編集API
    const [putAPI] = useAdminFaqPutUuidMutation()

    // 新規作成ボタンハンドラ
    const createHandler = () => {
        setVisibleChangeModal(true)
        setSelectedFaq(emptyFaq)
    }
    // 新規作成・編集実行
    const changeFaq = async (changedFaq: AdminFaqUpdateDto) => {
        setErrors([])
        setSelectedFaq(changedFaq)
        try {
            if (!changedFaq) throw new ApplicationError('操作をしたいFAQが選択されていません')
            // uuidがあれば編集、なければ新規作成
            if (changedFaq.uuid) {
                await putAPI({ uuid: changedFaq.uuid, adminFaqUpdateDto: changedFaq }).unwrap()
                toast.success('よくある質問を編集しました')
            } else {
                await postAPI({ adminFaqUpdateDto: changedFaq }).unwrap()
                toast.success('よくある質問を追加しました')
            }
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleChangeModal(false)
        }
    }

    return (
        <>
            <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3')}>
                <Helmet titleTemplate={pageTitleTemplate(true)}>
                    <title>よくある質問管理</title>
                </Helmet>
                <CHeader label="よくある質問管理" />
                <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3')}>
                    <CError errors={errors} />
                    <div className="page-content">
                        <div className={clsx('flex', 'justify-end')}>
                            <CButton className={clsx('c-button-primary', 'text-sm')} onClick={createHandler}>
                                <i className={clsx('material-icons')}>add</i>
                                <div>新規質問追加</div>
                            </CButton>
                        </div>
                        <div className={clsx('w-full', 'p-2')}>
                            {isLoadingFaqList && <CMessage info>読み込み中です...</CMessage>}
                            <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                                <thead>
                                    <tr className={clsx('bg-kimar-primary', 'text-white')}>
                                        <th className={clsx('w-72')}>質問</th>
                                        <th>回答</th>
                                        <th className={clsx('w-32')}>LPに表示（順番）</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {faqList?.list.map((faq) => (
                                        <tr key={faq.uuid} className={clsx('border', 'hover:bg-kimar-primary-light')}>
                                            <td className={clsx('p-1')}>
                                                <Link to={faq.uuid} className={clsx('text-kimar-primary')}>
                                                    {faq.question}
                                                </Link>
                                            </td>
                                            <td className={clsx('p-1')}>
                                                {faq.answer.length > 50 ? faq.answer.substring(0, 50) + '...' : faq.answer}
                                            </td>
                                            <td className={clsx('p-1')}>
                                                {faq.isDisplayHome && (
                                                    <CCheckBox
                                                        className={clsx('p-1', 'rounded', 'flex-1')}
                                                        readonly
                                                        label={`${faq.sort}番目`}
                                                        checked
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* 新規作成モーダル */}
            <CModal content={{ width: '500px' }} visible={visibleChangeModal} onRequestClose={() => setVisibleChangeModal(false)}>
                <CFaqContentEdit
                    faqProp={selectedFaq}
                    confirmButtonHandler={(changedFaq) => changeFaq(changedFaq)}
                    cancelButtonHandler={() => setVisibleChangeModal(false)}
                />
            </CModal>
        </>
    )
}
export default AdminFaq
