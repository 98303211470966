/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from 'i18next'
import { isNil } from 'lodash'

import { DownloadEntities } from '~/app/api'

import {
    ageRanges,
    areaTypeItems,
    customerBuildingAgeTypeItems,
    customerGrossRateTypeItems,
    customerLegalComplianceTypeItems,
    customerNetRateTypeItems,
    customerOwnershipTypeItems,
    customerPriceRanges,
    customerRegisteredTypeItems,
    customerRoles,
    customerSeismicStandardTypeItems,
    downloadTriggerItems,
    inquiryStatusItems,
    interestList,
    intermediaryRoles,
    otherRouteList,
    realestateBuildingAgeTypeItems,
    realestateGrossRateTypeItems,
    realestateLegalComplianceTypeItems,
    realestateNetRateTypeItems,
    realEstateOwnershipTypeItems,
    realEstatePriceRanges,
    realestatePriceRanges,
    realestateRoles,
    realestateSalesDestinationTypeItems,
    realestateSeismicStandardTypeItems,
    saleTimeTypeItems,
    structureTypeItems,
    teamRoles,
    zoningTypeItems,
} from '../../types/enum/enum'

// 数値系フィルタ
export const numberFormat = (value: any) => {
    if (value) {
        return Number(value).toLocaleString(undefined, {
            maximumFractionDigits: 20,
        })
    }

    return '0'
}

// 金額系フィルタ
export const priceFormat = (value: any) => {
    if (value) return t('format.price円', { price: Number(value).toLocaleString() })

    return t('format.price円', { price: 0 })
}

// エリア変換フィルタ
export const areaTypeFormat = (value: number | null) => {
    if (value) {
        const item = areaTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 物件用の金額変換フィルタ
export const realestatePriceTypeFormat = (value: number | null) => {
    if (value) {
        const item = realestatePriceRanges.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 顧客用の金額変換フィルタ
export const customerPriceTypeFormat = (value: number | undefined) => {
    if (value) {
        const item = customerPriceRanges.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 金額変換フィルタ
export const realEstatePriceTypeFormat = (value: number | undefined) => {
    if (!isNil(value)) {
        const item = realEstatePriceRanges.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 物件用の築年数変換フィルタ
export const realestateBuildingAgeTypeFormat = (value: number | null) => {
    if (value) {
        const item = realestateBuildingAgeTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 顧客用の築年数変換フィルタ
export const customerBuildingAgeTypeFormat = (value: number | undefined) => {
    if (value) {
        const item = customerBuildingAgeTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

export const ageRangesFormat = (value: number | undefined) => {
    if (!isNil(value)) {
        const item = ageRanges.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 物件用の表面利回り変換フィルタ
export const realestateGrossRateTypeFormat = (value: number | null) => {
    if (!isNil(value)) {
        const item = realestateGrossRateTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 顧客用の表面利回り変換フィルタ
export const customerGrossRateTypeFormat = (value: number | undefined) => {
    if (value) {
        const item = customerGrossRateTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 物件用の実質利回り変換フィルタ
export const realestateNetRateTypeFormat = (value: number | null) => {
    if (value) {
        const item = realestateNetRateTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 顧客用の実質利回り変換フィルタ
export const customerNetRateTypeFormat = (value: number | undefined) => {
    if (value) {
        const item = customerNetRateTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 遵法性変換フィルタ
export const legalComplianceTypeFormat = (value: number | undefined) => {
    if (value) {
        const item = realestateLegalComplianceTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 売却時期変換フィルタ
export const saleTimeTypeFormat = (value: number | undefined) => {
    if (value) {
        const item = saleTimeTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 物件用の所有権の形態変換フィルタ
export const realestateOwnershipTypeFormat = (value: number | null) => {
    if (value) {
        const item = realEstateOwnershipTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 顧客用の所有権の形態変換フィルタ
export const customerOwnershipTypeFormat = (value: number | undefined) => {
    if (value) {
        const item = customerOwnershipTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 物件用の耐震基準変換フィルタ
export const realestateSeismicStandardTypeFormat = (value: number | null | undefined) => {
    if (value) {
        const item = realestateSeismicStandardTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 物件用の先数変換フィルタ
export const realestateSalesDestinationTypeFormat = (value: number | null | undefined) => {
    if (value) {
        const item = realestateSalesDestinationTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 顧客用の耐震基準変換フィルタ
export const customerSeismicStandardTypeFormat = (value: number | undefined) => {
    if (value) {
        const item = customerSeismicStandardTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 物件用の遵法性変換フィルタ
export const realestateLegalComplianceTypeFormat = (value: number | null) => {
    if (value) {
        const item = realestateLegalComplianceTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// 顧客用の遵法性変換フィルタ
export const customerLegalComplianceTypeFormat = (value: number | undefined) => {
    if (value) {
        const item = customerLegalComplianceTypeItems.find((it) => it.value === value)
        if (item) return item.label
    }
    return '-'
}

// // 購入条件、所有権の形態等{label, value}で表されるarrayをソートするフィルタ
// Vue.filter('sortArray', (sortTargetItems: Array<IIntermediaryFilterContent>, definedItems: Array<IIntermediaryFilterContent>) => {
//     function sortRule(a: IIntermediaryFilterContent, b: IIntermediaryFilterContent) {
//         // itemsの定義順に並べる
//         const aIndex = definedItems.findIndex((i) => i.value === a.value)
//         const bIndex = definedItems.findIndex((i) => i.value === b.value)
//         if (aIndex < bIndex) return -1
//         else if (aIndex > bIndex) return 1
//         else return 0
//     }
//     if (sortTargetItems === null) {
//         return sortTargetItems
//     }
//     const sortedArray = _.cloneDeep(sortTargetItems)
//     sortedArray.sort(sortRule)
//     return sortedArray
// })

// 顧客獲得経路ラベル名フィルタ
export const customerRegisteredTypeFormat = (value: number) => {
    const item = customerRegisteredTypeItems.find((it) => it.value === value)
    if (item) return item.label

    return '-'
}

export const structureTypeFormat = (type: number | null) => {
    if (type) {
        const item = structureTypeItems.find((it) => it.value === type)
        if (item) return item.label
    }
    return '-'
}
export const zoningTypeFormat = (type: number | null) => {
    if (type) {
        const item = zoningTypeItems.find((it) => it.value === type)
        if (item) return item.label
    }
    return '-'
}

export const voteRouteFormat = (value: boolean) => {
    let item:
        | {
              label: string
              value: number
          }
        | undefined = undefined
    if (value) {
        // valueが0でない場合は「取得済み」
        item = otherRouteList.find((it) => it.value === 1)
    } else {
        // 「初取得」
        item = otherRouteList.find((it) => it.value === 0)
    }
    if (item) return item.label

    return '-'
}

// TODO: この関数は上記関数で置き換えるので、参照が無くなったら消す
export const otherRouteFormat = (value: number) => {
    const item = otherRouteList.find((it) => it.value === value)
    if (item) return item.label

    return '-'
}

export const voteInterestFormat = (value: boolean) => {
    let item:
        | {
              label: string
              value: number
          }
        | undefined = undefined
    if (value) {
        // valueが0でない場合は「興味あり」
        item = interestList.find((it) => it.value === 1)
    } else {
        // 「興味なし」
        item = interestList.find((it) => it.value === 0)
    }
    if (item) return item.label

    return '-'
}

// TODO: この関数は上記関数で置き換えるので、参照が無くなったら消す
export const interestFormat = (value: number) => {
    const item = interestList.find((it) => it.value === value)
    if (item) return item.label

    return '-'
}

// お問い合わせに対する回答状態
export const inquiryStatusFormat = (value: number | undefined) => {
    // undefinedの場合は未対応とする
    if (!value) return t('format.未対応')
    const item = inquiryStatusItems.find((it) => it.value === value)
    if (item) return item.label

    return '-'
}

// 資料ダウンロードきっかけ
export const downloadTriggerFormat = (download: DownloadEntities) => {
    const item = downloadTriggerItems.find((it) => it.value === download.trigger)
    // その他を選択時は自由入力内容を返す
    if (item?.label === t('format.その他')) return download.trigger_content
    return item ? item.label : '-'
}

// Role系

export const teamRoleFormat = (type: number) => {
    const item = teamRoles.find((it) => it.value === type)
    if (item) return item.label

    return '-'
}
export const realestateRoleFormat = (type: number) => {
    const item = realestateRoles.find((it) => it.value === type)
    if (item) return item.label

    return '-'
}
export const customerRoleFormat = (type: number) => {
    const item = customerRoles.find((it) => it.value === type)
    if (item) return item.label

    return '-'
}
export const intermediaryRoleFormat = (type: number) => {
    const item = intermediaryRoles.find((it) => it.value === type)
    if (item) return item.label

    return '-'
}
