import clsx from 'clsx'
import { motion } from 'framer-motion'
import { Outlet } from 'react-router-dom'

import CLoader from '~/components/common/cLoader/CLoader'
import CNotification from '~/components/common/cNotification/CNotification'

import SiteHeader from '../components/global/gHeader/SiteHeader'

// フッターなし構造
const NoFooter = () => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
            duration: 0.2,
            ease: 'easeInOut',
        }}
        className={clsx('h-screen', 'overflow-auto', 'flex', 'flex-col', 'text-gray-700', 'bg-gray-50')}>
        <SiteHeader />
        <Outlet /> {/* 通知吹き出し(トースト) */}
        <CNotification />
        <CLoader />
    </motion.div>
)

export default NoFooter
