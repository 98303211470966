import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useState, VFC } from 'react'

import {
    RealEstateEntities,
    useClientCustomerIntermediaryPostFinishMutation,
    useClientIntermediaryRealEstateUuidQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'

export type CRealEstateDetailFinishAllIntermediaryViewProps = {
    visible: boolean
    onRequestClose: () => void
    onRequestConfirm: (isSendNotification: boolean) => void
    finishMessage: string
    setFinishMessage: (val: string) => void
} & CRealEstateDetailFinishAllIntermediaryProps

export const CRealEstateDetailFinishAllIntermediaryView: VFC<CRealEstateDetailFinishAllIntermediaryViewProps> = ({
    visible,
    onRequestClose,
    onRequestConfirm,
    finishMessage,
    setFinishMessage,
}) => {
    const title = t('CRealEstateDetailFinishAllIntermediary.物件のマーケット掲載を終了しました')?.toString()

    const footer = (
        <>
            <CButton className={clsx('c-button-secondary')} onClick={() => onRequestClose()}>
                {t('Button.キャンセル')}
            </CButton>
            <CButton className={clsx('c-button-primary')} onClick={() => onRequestConfirm(false)}>
                {t('CIntermediaryFinish.通知を送らず紹介終了')}
            </CButton>
            <CButton className={clsx('c-button-primary')} onClick={() => onRequestConfirm(true)}>
                {t('CIntermediaryFinish.通知を送って紹介終了')}
            </CButton>
        </>
    )

    return (
        <CFooterModal footer={footer} visible={visible} content={{ width: '80%' }} onRequestClose={() => onRequestClose()}>
            <div className={clsx('flex', 'flex-col', 'gap-8', 'p-4', 'md:p-8')}>
                <div className={clsx('text-kimar-primary', 'font-bold', 'text-center')}>{title}</div>
                <div>
                    <div className={clsx('mb-4')}>
                        {t('CRealEstateDetailFinishAllIntermediary.物件のマーケット掲載を終了しました。紹介も全て終了しますか？')}
                    </div>
                    <CLabeledItem label={t('CRealEstateDetailFinishAllIntermediary.紹介先へのメッセージ')}>
                        <CTextInput
                            text={finishMessage}
                            onChange={(value) => setFinishMessage(value)}
                            className={clsx('w-full', 'c-text-input-base')}
                            placeholder={t(
                                'CRealEstateDetailFinishAllIntermediary.物件紹介の終了にあたり紹介先に対してメッセージを送ることが出来ます。記入がない場合は紹介終了のお知らせのみ紹介先に送信されます。',
                            )}
                            type="multiline"
                        />
                    </CLabeledItem>
                </div>
            </div>
        </CFooterModal>
    )
}

export type CRealEstateDetailFinishAllIntermediaryProps = {
    visible: boolean
    realEstate?: RealEstateEntities
    onSubmit: () => void
    onClose: () => void
}

export const CRealEstateDetailFinishAllIntermediary: VFC<CRealEstateDetailFinishAllIntermediaryProps> = ({ ...props }) => {
    const [visible, setVisible] = useState(props.visible)
    const [finishMessage, setFinishMessage] = useState('')
    const {
        data: intermediaries,
        isLoading,
        isSuccess,
    } = useClientIntermediaryRealEstateUuidQuery({ uuid: props.realEstate?.uuid ?? '' }, { skip: !props.realEstate })

    useEffect(() => {
        setVisible(props.visible)
    }, [props.visible])

    const onRequestClose = () => {
        props.onClose()
    }

    useEffect(() => {
        if (isSuccess) {
            const intermediaryUuidList = (intermediaries?.flatMap((i) => i.intermediaryToList ?? []) ?? [])
                .filter((i) => !i.intermediaryFinishedAt && !i.reviewFinishedAt && !i.destinationTo?.deletedAt)
                .map((i) => i.uuid)

            if (!intermediaryUuidList.length) onRequestClose()
        }
    }, [isSuccess, intermediaries])

    const [finishIntermediaryQuery] = useClientCustomerIntermediaryPostFinishMutation()
    const onRequestConfirm = async (isSendNotification: boolean) => {
        if (!props.realEstate) return
        const intermediaryUuidList = (intermediaries?.flatMap((i) => i.intermediaryToList ?? []) ?? [])
            .filter((i) => !i.intermediaryFinishedAt && !i.reviewFinishedAt && !i.destinationTo?.deletedAt)
            .map((i) => i.uuid)

        await finishIntermediaryQuery({
            clientIntermediaryFinishDto: {
                intermediaryUuidList: intermediaryUuidList,
                isSendNotification,
                message: finishMessage,
            },
        }).unwrap()

        onRequestClose()
    }

    if (isLoading) return null

    return (
        <CRealEstateDetailFinishAllIntermediaryView
            {...props}
            visible={visible}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            finishMessage={finishMessage}
            setFinishMessage={setFinishMessage}
        />
    )
}
