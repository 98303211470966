import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { useNavigate, useParams } from 'react-router-dom'

import {
    ConditionRequestUpdateDto,
    useClientConditionRequestGetConditionRequestQuery,
    useClientConditionRequestPutUuidMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import { CHeader } from '~/components/common/cHeader/CHeader'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import CConditionForm from '~/components/unique/form/CConditionForm'
import { ApplicationError, IApplicationError } from '~/types/error'
import { pageTitleTemplate } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser } from '~/util/store/userSlice'

const PurchaseConditionEdit = () => {
    const params = useParams()
    const navigate = useNavigate()
    const user = useAppSelector(selectLoginUser)
    const invitationCode = 'KIMAR62'
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const [copyGuide, setCopyGuide] = useState(t('Edit.（タップするとコピーできます）'))
    const { data: conditionRequest, isSuccess: isConditionRequestGetSuccess } = useClientConditionRequestGetConditionRequestQuery(
        {
            uuid: params.uuid ? params.uuid : '',
        },
    )

    const [conditionPutAPI, { isSuccess: isConditionPutAPISuccess }] = useClientConditionRequestPutUuidMutation()
    const [updateConditionDto, setUpdateConditionDto] = useState<ConditionRequestUpdateDto>({
        area: [],
        buildingAgeType: 0,
        legalComplianceType: 0,
        grossRateType: undefined,
        netRateType: undefined,
        prices: [],
        purchaseTimeType: 0,
        seismicStandardType: 0,
        useType: [],
    })

    const validate = () => {
        const errorList: Array<ApplicationError> = []
        if (updateConditionDto.useType.length === 0) errorList.push(new ApplicationError(t('Edit.種別が入力されていません')))
        if (updateConditionDto.area.length === 0) errorList.push(new ApplicationError(t('Edit.エリアが入力されていません')))
        if (updateConditionDto.prices.length === 0) errorList.push(new ApplicationError(t('Edit.金額が入力されていません')))
        if (updateConditionDto.buildingAgeType === 0) errorList.push(new ApplicationError(t('Edit.築年数が入力されていません')))
        if (updateConditionDto.purchaseTimeType === 0)
            errorList.push(new ApplicationError(t('Edit.購入時期が入力されていません')))

        if (errorList.length > 0) {
            setErrors(errorList)
            return false
        }
        return true
    }

    const onSubmit = async () => {
        setErrors([])
        if (!validate()) return
        try {
            await conditionPutAPI({
                cuuid: conditionRequest?.customer?.uuid ?? '',
                ruuid: params.uuid ? params.uuid : '',
                conditionRequestUpdateDto: updateConditionDto,
            }).unwrap()
            toast.success(t('Edit.購入条件を登録しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const setConditionProps = (key: keyof ConditionRequestUpdateDto, value: unknown) => {
        setUpdateConditionDto({
            ...updateConditionDto,
            [key]: value,
        })
    }

    // クリップボードにコピー
    const copyToClipboard = async () => {
        await global.navigator.clipboard.writeText(invitationCode)
        setCopyGuide(t('Edit.コピーしました'))
    }

    if (!isConditionRequestGetSuccess) return <CMessage info>{t('Message.読み込み中です…')}</CMessage>
    // 購入条件リクエストを開いたユーザーがログイン済みで、かつリクエストを受け取った顧客とemailが異なる場合、リダイレクトする
    if (isConditionRequestGetSuccess && user && user.email != conditionRequest.customer.email) navigate('/customer')

    return (
        <div className={clsx('flex', 'flex-col', 'items-center', 'gap-4', 'p-6', 'font-body', 'bg-gray-50')}>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Edit.購入条件入力フォーム')}</title>
            </Helmet>
            <CHeader label={t('Edit.購入条件入力フォーム')} />

            <div className={clsx('p-4', 'bg-white', 'm-2', 'w-full', 'max-w-[768px]')}>
                <CError errors={errors} />
                {/* 回答済みまたは回答PUTが完了したときは完了画面 */}
                {conditionRequest?.answeredAt || isConditionPutAPISuccess ? (
                    <>
                        <div className={clsx('p-8', 'text-sm')}>
                            <div className={clsx('flex', 'flex-col', 'gap-4')}>
                                <CMessage info>
                                    <div>{t('Edit.購入条件を登録しました。')}</div>
                                    <div>{t('Edit.ご入力ありがとうございます。')}</div>
                                </CMessage>
                                {!conditionRequest?.customer.relatedMember && (
                                    <>
                                        <div className={clsx('flex', 'flex-col', 'gap-4')}>
                                            <p>
                                                {t(
                                                    'Edit.キマールの無料会員になると、キマールおよびキマールマーケットの機能（制限あり）を利用することができます。',
                                                )}
                                                <br />
                                                {t(
                                                    'Edit.この機会にキマールの無料会員登録をして、不動産テックを体験してみましょう！',
                                                )}
                                            </p>
                                            <p>
                                                {t(
                                                    'Edit.無料会員登録を希望される場合は、以下のボタンからユーザー情報と招待コードを入力して送信してください。',
                                                )}
                                            </p>
                                        </div>
                                        <div className={clsx('flex', 'flex-col', 'gap-4')}>
                                            <div className={clsx('flex', 'flex-col', 'gap-2')}>
                                                <div className={clsx('text-base', 'font-bold')}>{t('Edit.招待コード')}</div>
                                                <div
                                                    className={clsx('flex', 'flex-row', 'flex-wrap', 'items-center', 'gap-1')}
                                                    onClick={copyToClipboard}>
                                                    <CTextInput
                                                        type="text"
                                                        text={invitationCode}
                                                        readonly
                                                        className={clsx('c-text-input-base')}
                                                    />
                                                    <div>{copyGuide}</div>
                                                </div>
                                            </div>
                                            <CLinkButton
                                                className={clsx('c-button-primary', 'w-full')}
                                                to={'https://forms.gle/x7v66kvrwAFvnrP18'}
                                                blank>
                                                {t('Edit.無料会員登録する')}
                                            </CLinkButton>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={clsx('p-4', 'text-sm')}>
                            <p>
                                {conditionRequest?.customer?.name} {t('Edit.様')}
                                <br />
                                {conditionRequest?.sentBy?.team?.company?.name} {conditionRequest?.sentBy?.name}
                                {t('Edit.様から、購入条件の入力リクエストが届いています。')}
                                <br />
                                {t('Edit.購入条件を記入すると、より条件にマッチする物件の紹介が受けられるようになります。')}
                                <br />
                                {t('Edit.以下のフォームに現在の物件購入条件を入力してください。')}
                            </p>
                        </div>
                        <CConditionForm
                            formDto={updateConditionDto}
                            changeHandler={(key, value) => setConditionProps(key as keyof ConditionRequestUpdateDto, value)}
                        />
                        <div className={clsx('flex', 'justify-center', 'my-8')}>
                            <CButton className={clsx('c-button-primary', 'w-full')} onClick={onSubmit}>
                                {t('Button.保存')}
                            </CButton>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default PurchaseConditionEdit
