import clsx from 'clsx'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { t } from 'i18next'
import { isNil, maxBy, sortBy } from 'lodash'
import { useState, VFC } from 'react'
import { createSearchParams, Link } from 'react-router-dom'

import { ClientRealEstateListDto, RealEstateEntities } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import { CTagList } from '~/components/common/cTagList/CTagList'
import ReactRating from '~/components/common/reactRating/ReactRating'
import { RealEstateOptions, taxType } from '~/types/enum/enum'
import { fullCurrencyFormat } from '~/util/common/common'
import { getSignedUrl } from '~/util/model/FileEntities'
import { DefaultRealEstateKeys, getRealEstatePropertyString, RealEstateKeyLabels } from '~/util/model/RealEstateEntities'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser } from '~/util/store/userSlice'

import { CRealestateEdit } from './CRealestateEdit'
import { CRealestateTrialModal } from './CRealestateTrialModal'
import { CRealEstateDetailFinishAllIntermediary } from './detail/CRealEstateDetailFinishAllIntermediary'

dayjs.extend(isSameOrAfter)

export type CRealestateListItemViewProps = {
    showFinishPublishMarket: RealEstateEntities | undefined
    setShowFinishPublishMarket: (val: RealEstateEntities | undefined) => void
} & CRealestateListItemProps

export const CRealestateListItemView: VFC<CRealestateListItemViewProps> = ({
    item,
    isReceiveNewChat,
    isSendNewChat,
    showFinishPublishMarket,
    setShowFinishPublishMarket,
    isHideMap,
    progress,
    details,
}) => {
    const user = useAppSelector(selectLoginUser)
    const getRealEstateImage = (item: ClientRealEstateListDto) => {
        let coverImg = maxBy(
            item.documents?.filter((d) => !!d.isCover),
            'createdAt',
        )?.coverFile
        if (coverImg) return getSignedUrl(coverImg)
        coverImg = maxBy(
            item.documents?.filter((d) => !!d.isCover),
            'createdAt',
        )?.file
        if (coverImg) return getSignedUrl(coverImg)
        return `/src/assets/image/noimage.svg`
    }

    const getRealEstateCompanyImage = (item: ClientRealEstateListDto) => {
        if (item.intermediaryFrom) {
            const url = item.intermediaryFrom.intermediary?.sentBy?.team?.company?.logoFile
                ? getSignedUrl(item.intermediaryFrom.intermediary?.sentBy?.team?.company?.logoFile)
                : undefined
            return url
        }
        const url = item.team?.company?.logoFile ? getSignedUrl(item.team?.company?.logoFile) : undefined
        return url
    }
    const getRealEstateCompanyName = (item: ClientRealEstateListDto) => {
        if (item.intermediaryFrom) return item.intermediaryFrom.intermediary?.sentBy?.team?.company?.name
        else return item.team?.company?.name
    }

    const getMapImage = (item: ClientRealEstateListDto) => {
        const coverImg = maxBy(
            item.documents?.filter((d) => !!d.isMap),
            'createdAt',
        )?.file
        if (coverImg) return getSignedUrl(coverImg)
        else {
            return (
                'https://maps.googleapis.com/maps/api/staticmap?' +
                'center=' +
                item.latitude +
                ',' +
                item.longitude +
                '&' +
                'zoom=15&' +
                'scale=1&' +
                'size=' +
                300 +
                'x' +
                300 +
                '&' +
                'maptype=roadmap&' +
                'format=png&' +
                'visual_refresh=true&' +
                'markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C' +
                item.latitude +
                ',' +
                item.longitude +
                '&' +
                'key=' +
                import.meta.env.VITE_GOOGLE_MAPS_API_KEY
            )
        }
    }

    const isSending = (item: ClientRealEstateListDto) => {
        // 紹介している
        return item.sendStatus === 1
    }

    const getTagArray = () => {
        return [
            ...(sortBy(item?.labels, (s) => s.sort).map((label) => ({
                className: clsx('text-sm', 'px-2'),
                style: {
                    backgroundColor: label.bgColor,
                    color: label.letterColor,
                },
                children: <>{label.name}</>,
            })) ?? []),
        ]
    }

    const onClickChatButton = () => {
        if (!item.intermediaryFrom) return
        const url = new URL(window.location.origin + '/chat')
        url.search = createSearchParams({
            intermediaryTo: item.intermediaryFrom.uuid,
        }).toString()

        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        window.open(
            url,
            '_blank',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )
    }

    const [openEditModal, setOpenEditModal] = useState(false)

    const [openTrialModal, setOpenTrialModal] = useState(false)

    return item.isSecret ? (
        <>
            <div className={clsx('relative', 'cursor-pointer')} onClick={() => setOpenTrialModal(true)}>
                {/* 無料会員の超過物件 */}
                <div
                    className={clsx(
                        'absolute',
                        'top-1/2',
                        'left-1/2',
                        '-translate-y-1/2',
                        '-translate-x-1/2',
                        'p-2',
                        'flex',
                        'flex-col',
                        'md:flex-col',
                        'bg-white',
                        'gap-2',
                        'rounded',
                    )}>
                    <p className={clsx('flex', 'items-center', 'gap-2')}>
                        <i className={clsx('material-icons', 'inline-flex', 'align-middle', 'text-kimar-accent')}>lock</i>
                        <span className={clsx('font-bold')}>{t('CRealestateListItem.表示制御物件')}</span>
                    </p>
                    <span className={clsx('text-sm')}>{t('CRealestateListItem.有料プランに契約すると表示できます')}</span>
                </div>
                <img
                    src="/src/assets/image/page/realestate/secret_realestate/secret_realestate_pc.png"
                    alt="超過物件"
                    className={clsx('hidden', 'md:block', 'w-full')}
                />
                <img
                    src="/src/assets/image/page/realestate/secret_realestate/secret_realestate_sp.png"
                    alt="超過物件"
                    className={clsx('block', 'md:hidden', 'w-full')}
                />
            </div>
            <CRealestateTrialModal visible={openTrialModal} onClose={() => setOpenTrialModal(false)} />
        </>
    ) : (
        <>
            <Link to={`/realestate/${item.uuid}`} rel="noopener noreferrer" target="_blank">
                <div className={clsx('flex', 'p-2', 'flex-col', 'space-y-2', 'bg-white', 'rounded-lg')}>
                    <div className={clsx('flex', 'w-full', 'flex-col', 'md:flex-row', 'gap-2', 'min-w-0')}>
                        <div className={clsx('hidden', 'md:block', 'h-full', 'flex-shrink-0', 'w-[130px]', 'relative')}>
                            <img
                                src={getRealEstateImage(item)}
                                alt={item.name}
                                loading="lazy"
                                className={clsx('h-[130px]', 'w-[130px]', 'rounded', 'border', 'border-gray-200', 'object-cover')}
                                crossOrigin="anonymous"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null
                                    currentTarget.src = '/src/assets/image/notfound.svg'
                                }}
                            />
                            {(() => {
                                const url = getRealEstateCompanyImage(item)
                                if (url) {
                                    return (
                                        <img
                                            src={url}
                                            alt={'RealEstate Logo'}
                                            loading="lazy"
                                            crossOrigin="anonymous"
                                            className={clsx(
                                                'absolute',
                                                'max-w-[80px]',
                                                'top-0',
                                                'left-auto',
                                                'bg-white',
                                                'rounded-sm',
                                                'm-1',
                                                'opacity-80',
                                            )}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null
                                                currentTarget.src = '/src/assets/image/notfound.svg'
                                            }}
                                        />
                                    )
                                } else {
                                    const name = getRealEstateCompanyName(item)
                                    return name ? (
                                        <div
                                            className={clsx(
                                                'absolute',
                                                'max-w-[80px]',
                                                'top-0',
                                                'bg-white',
                                                'rounded-sm',
                                                'text-xs',
                                                'm-1',
                                                'p-1',
                                                'bg-opacity-60',
                                                'whitespace-nowrap',
                                                'text-ellipsis',
                                                'overflow-hidden',
                                                'hover:text-clip',
                                                'hover:whitespace-pre-wrap',
                                            )}>
                                            {name}
                                        </div>
                                    ) : null
                                }
                            })()}
                        </div>
                        <div className={clsx('flex-grow', 'flex', 'flex-col', 'min-w-0')}>
                            <div className={clsx('flex', 'w-full')}>
                                <div className={clsx('flex', 'flex-col', 'min-w-0', 'md:w-full', 'flex-shrink')}>
                                    <div className={clsx('items-baseline')}>
                                        <div
                                            className={clsx(
                                                'text-xl',
                                                'text-gray-700',
                                                'font-bold',
                                                'overflow-hidden',
                                                'whitespace-nowrap',
                                                'text-ellipsis',
                                            )}>
                                            {item.name}
                                        </div>
                                        <div
                                            className={clsx(
                                                'text-xs',
                                                'text-gray-700',
                                                'overflow-hidden',
                                                'whitespace-nowrap',
                                                'text-ellipsis',
                                            )}>
                                            {item.address}
                                        </div>
                                    </div>
                                    <div className={clsx('flex', 'items-center')}>
                                        <div
                                            className={clsx(
                                                'text-xs',
                                                'text-white',
                                                'bg-gray-500',
                                                'py-0.5',
                                                'px-1',
                                                'mr-1',
                                                'whitespace-nowrap',
                                            )}>
                                            {item.useType.name}
                                        </div>
                                        {item.ended && (
                                            <div
                                                className={clsx(
                                                    'text-xs',
                                                    'text-gray-700',
                                                    'bg-gray-300',
                                                    'py-0.5',
                                                    'px-1',
                                                    'mr-1',
                                                    'whitespace-nowrap',
                                                )}>
                                                {t('CRealestateListItem.物件終了')}
                                            </div>
                                        )}
                                        {item.option === RealEstateOptions.IS_DUPLICATE && (
                                            <div
                                                className={clsx(
                                                    'text-xs',
                                                    'text-white',
                                                    'bg-red-500',
                                                    'py-0.5',
                                                    'px-1',
                                                    'mr-1',
                                                    'whitespace-nowrap',
                                                )}>
                                                {t('CRealestateListItem.重複')}
                                            </div>
                                        )}
                                        <div className={clsx('flex', 'items-center', 'overflow-hidden', 'flex-grow-0')}>
                                            <CTagList horizontal noWrap items={getTagArray()} />
                                        </div>
                                    </div>
                                </div>
                                <div className={clsx('hidden', 'md:flex', 'md:flex-shrink-0', 'gap-2')}>
                                    <div
                                        className={clsx(
                                            'flex-grow',
                                            'overflow-hidden',
                                            'flex',
                                            'flex-col',
                                            'items-end',
                                            'md:w-min',
                                        )}>
                                        <div
                                            className={clsx(
                                                'whitespace-nowrap',
                                                'flex',
                                                'md:flex-col',
                                                'items-start',
                                                'flex-shrink-0',
                                                'overflow-auto',
                                                'flex-wrap',
                                            )}>
                                            {!isNil(item.price) && (
                                                <div className={clsx('flex', 'items-baseline', 'justify-end')}>
                                                    <div className={clsx('text-sm', 'text-kimar-primary')}>
                                                        {t('CRealestateListItem.物件価格')}
                                                    </div>
                                                    <div className={clsx('text-lg', 'text-kimar-primary', 'font-bold')}>
                                                        {fullCurrencyFormat(item.price)}
                                                    </div>
                                                    <div className={clsx('text-sm', 'text-kimar-primary')}>{t('Unit.円')}</div>
                                                    {!isNil(item.taxType) && (
                                                        <div className={clsx('text-sm', 'text-kimar-primary')}>{`(${
                                                            taxType.find((t) => t.value === item.taxType)?.label
                                                        })`}</div>
                                                    )}
                                                </div>
                                            )}
                                            {!isNil(item.intermediaryFrom?.intermediary?.price) && (
                                                <div className={clsx('flex', 'items-baseline', 'justify-end')}>
                                                    <div className={clsx('text-xs', 'text-kimar-primary')}>
                                                        {'(' + t('CRealestateListItem.提示価格')}
                                                    </div>
                                                    <div className={clsx('text-xs', 'text-kimar-primary', 'font-bold')}>
                                                        {fullCurrencyFormat(item.intermediaryFrom?.intermediary?.price)}
                                                    </div>
                                                    <div className={clsx('text-xs', 'text-kimar-primary')}>
                                                        {t('Unit.円') + ')'}
                                                    </div>
                                                    {!isNil(item.intermediaryFrom?.intermediary?.taxType) && (
                                                        <div className={clsx('text-sm', 'text-kimar-primary')}>{`(${
                                                            taxType.find(
                                                                (t) => t.value === item.intermediaryFrom?.intermediary.taxType,
                                                            )?.label
                                                        })`}</div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={clsx('hidden', 'md:block', 'flex-shrink-0')}>
                                        <div className={clsx('flex', 'flex-col', 'gap-1')}>
                                            {!!item.markets?.length && (
                                                <>
                                                    <CCheckBox
                                                        size="xs"
                                                        readonly
                                                        color="market"
                                                        checked
                                                        label={t('CRealestateListItem.マーケット掲載中')}
                                                    />
                                                </>
                                            )}
                                            {!item.markets?.length && (
                                                <>
                                                    <CCheckBox
                                                        size="xs"
                                                        readonly
                                                        color="market"
                                                        checked={false}
                                                        label={t('CRealestateListItem.マーケット非掲載')}
                                                    />
                                                </>
                                            )}
                                            {item.publishRequests?.find((p) => !p.checkedAt) ? (
                                                <CCheckBox
                                                    size="xs"
                                                    readonly
                                                    color="accent"
                                                    checked
                                                    label={t('CRealestateListItem.リクエストあり')}
                                                />
                                            ) : (
                                                <CCheckBox
                                                    size="xs"
                                                    readonly
                                                    color="accent"
                                                    label={t('CRealestateListItem.リクエストなし')}
                                                />
                                            )}
                                            {isSending(item) ? (
                                                <>
                                                    <div className={clsx('flex', 'items-center', 'gap-1')}>
                                                        <CCheckBox
                                                            size="xs"
                                                            readonly
                                                            checked
                                                            label={t('CRealestateListItem.紹介済')}
                                                        />
                                                        {isSendNewChat && (
                                                            <div
                                                                className={clsx(
                                                                    'w-2',
                                                                    'h-2',
                                                                    'rounded-full',
                                                                    'bg-red-700',
                                                                    '-translate-y-1.5',
                                                                )}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={clsx('flex', 'items-center', 'gap-1')}>
                                                        <CCheckBox size="xs" readonly label={t('CRealestateListItem.未紹介')} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={clsx('flex', 'md:hidden')}>
                                <div className={clsx('flex-grow', 'overflow-hidden', 'flex', 'flex-col')}>
                                    <div
                                        className={clsx(
                                            'whitespace-nowrap',
                                            'flex',
                                            'md:flex-col',
                                            'items-baseline',
                                            'flex-shrink-0',
                                            'overflow-auto',
                                            'flex-wrap',
                                        )}>
                                        {!isNil(item.price) && (
                                            <div className={clsx('flex', 'items-baseline', 'justify-end')}>
                                                <div className={clsx('text-sm', 'text-kimar-primary')}>
                                                    {t('CRealestateListItem.物件価格')}
                                                </div>
                                                <div className={clsx('text-lg', 'text-kimar-primary', 'font-bold')}>
                                                    {fullCurrencyFormat(item.price)}
                                                </div>
                                                <div className={clsx('text-sm', 'text-kimar-primary')}>{t('Unit.円')}</div>
                                                {!isNil(item.taxType) && (
                                                    <div className={clsx('text-sm', 'text-kimar-primary')}>{`(${
                                                        taxType.find((t) => t.value === item.taxType)?.label
                                                    })`}</div>
                                                )}
                                            </div>
                                        )}
                                        {!isNil(item.intermediaryFrom?.intermediary?.price) && (
                                            <div className={clsx('flex', 'items-baseline', 'justify-end')}>
                                                <div className={clsx('text-xs', 'text-kimar-primary')}>
                                                    {'(' + t('CRealestateListItem.提示価格')}
                                                </div>
                                                <div className={clsx('text-xs', 'text-kimar-primary', 'font-bold')}>
                                                    {fullCurrencyFormat(item.intermediaryFrom?.intermediary?.price)}
                                                </div>
                                                <div className={clsx('text-xs', 'text-kimar-primary')}>{t('Unit.円') + ')'}</div>
                                                {!isNil(item.intermediaryFrom?.intermediary?.taxType) && (
                                                    <div className={clsx('text-sm', 'text-kimar-primary')}>{`(${
                                                        taxType.find(
                                                            (t) => t.value === item.intermediaryFrom?.intermediary.taxType,
                                                        )?.label
                                                    })`}</div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={clsx('flex', 'md:block', 'gap-2')}>
                                <div className={clsx('block', 'md:hidden')}>
                                    <div className={clsx('flex-shrink-0', 'h-20', 'w-20', 'relative')}>
                                        <img
                                            src={getRealEstateImage(item)}
                                            alt={item.name}
                                            loading="lazy"
                                            className={clsx(
                                                'h-20',
                                                'w-20',
                                                'rounded',
                                                'border',
                                                'border-gray-200',
                                                'object-cover',
                                            )}
                                            crossOrigin="anonymous"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null
                                                currentTarget.src = '/src/assets/image/notfound.svg'
                                            }}
                                        />
                                        {(() => {
                                            const url = getRealEstateCompanyImage(item)
                                            if (url) {
                                                return (
                                                    <img
                                                        src={url}
                                                        alt={'RealEstate Logo'}
                                                        loading="lazy"
                                                        crossOrigin="anonymous"
                                                        className={clsx(
                                                            'absolute',
                                                            'max-w-[60px]',
                                                            'top-0',
                                                            'left-auto',
                                                            'bg-white',
                                                            'rounded-sm',
                                                            'm-1',
                                                            'opacity-80',
                                                        )}
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null
                                                            currentTarget.src = '/src/assets/image/notfound.svg'
                                                        }}
                                                    />
                                                )
                                            } else {
                                                const name = getRealEstateCompanyName(item)
                                                return name ? (
                                                    <div
                                                        className={clsx(
                                                            'absolute',
                                                            'max-w-[60px]',
                                                            'top-0',
                                                            'bg-white',
                                                            'rounded-sm',
                                                            'text-xs',
                                                            'm-1',
                                                            'p-1',
                                                            'bg-opacity-60',
                                                            'whitespace-nowrap',
                                                            'text-ellipsis',
                                                            'overflow-hidden',
                                                            'hover:text-clip',
                                                            'hover:whitespace-pre-wrap',
                                                        )}>
                                                        {name}
                                                    </div>
                                                ) : null
                                            }
                                        })()}
                                    </div>
                                    <div className={clsx('flex-shrink-0')}>
                                        <div className={clsx('flex', 'flex-col', 'gap-1')}>
                                            {!!item.markets?.length && (
                                                <>
                                                    <CCheckBox
                                                        size="xs"
                                                        readonly
                                                        color="market"
                                                        checked
                                                        label={t('CRealestateListItem.マーケット掲載中')}
                                                    />
                                                </>
                                            )}
                                            {!item.markets?.length && (
                                                <>
                                                    <CCheckBox
                                                        size="xs"
                                                        readonly
                                                        color="market"
                                                        checked={false}
                                                        label={t('CRealestateListItem.マーケット非掲載')}
                                                    />
                                                </>
                                            )}
                                            {item.publishRequests?.find((p) => !p.checkedAt) ? (
                                                <CCheckBox
                                                    size="xs"
                                                    readonly
                                                    color="accent"
                                                    checked
                                                    label={t('CRealestateListItem.リクエストあり')}
                                                />
                                            ) : (
                                                <CCheckBox
                                                    size="xs"
                                                    readonly
                                                    color="accent"
                                                    label={t('CRealestateListItem.リクエストなし')}
                                                />
                                            )}
                                            {isSending(item) ? (
                                                <>
                                                    <div className={clsx('flex', 'items-center', 'gap-1')}>
                                                        <CCheckBox
                                                            size="xs"
                                                            readonly
                                                            checked
                                                            label={t('CRealestateListItem.紹介済')}
                                                        />
                                                        {isSendNewChat && (
                                                            <div
                                                                className={clsx(
                                                                    'w-2',
                                                                    'h-2',
                                                                    'rounded-full',
                                                                    'bg-red-700',
                                                                    '-translate-y-1.5',
                                                                )}
                                                            />
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={clsx('flex', 'items-center', 'gap-1')}>
                                                        <CCheckBox size="xs" readonly label={t('CRealestateListItem.未紹介')} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={clsx('grid', 'lg:grid-cols-2', 'text-xs', 'w-full', 'leading-5', 'break-all')}>
                                    {(details ? details : DefaultRealEstateKeys).map((key) => {
                                        const kv = RealEstateKeyLabels.find((v) => v.key === key)
                                        if (!kv) return null
                                        return (
                                            <div className={clsx('flex', 'border-b')}>
                                                <div className={clsx('w-24', 'font-bold', 'flex-shrink-0')}>{kv.label}</div>
                                                <div>{getRealEstatePropertyString(item, kv.key)}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        {!isHideMap && (
                            <div className={clsx('hidden', 'flex-shrink-0', 'md:block', 'h-[130px]', 'w-[130px]')}>
                                <Link
                                    to={`/map?lat=${item.latitude}&lng=${item.longitude}&uuid=${item.uuid}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <img
                                        src={getMapImage(item)}
                                        alt=""
                                        loading="lazy"
                                        className={clsx(
                                            'h-[130px]',
                                            'w-[130px]',
                                            'rounded',
                                            'border',
                                            'border-gray-200',
                                            'object-cover',
                                        )}
                                        crossOrigin="anonymous"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null
                                            currentTarget.src = '/src/assets/image/notfound.svg'
                                        }}
                                    />
                                </Link>
                            </div>
                        )}

                        <div
                            className={clsx(
                                'flex-shrink-0',
                                'flex',
                                'md:flex-col',
                                'flex-row',
                                'flex-wrap',
                                'justify-center',
                                'w-full',
                                'md:w-32',
                                'gap-1',
                            )}>
                            <div className={clsx('flex', 'justify-center', 'items-center', 'flex-col')}>
                                <div className={clsx('text-xs')}>進捗</div>
                                <CLinkButton to={`/realestate/${item.uuid}?intermediaryTo=${progress.uuid}`}>
                                    <ReactRating
                                        initialRating={progress.progress}
                                        emptySymbol={<i className={clsx('material-icons-outlined')}>play_arrow</i>}
                                        fullSymbol={<i className={clsx('material-icons')}>play_arrow</i>}
                                        readonly={true}
                                    />
                                </CLinkButton>
                            </div>
                            {user?.roles.intermediary === 2 && !item.ended ? (
                                <CLinkButton
                                    className={clsx(
                                        'c-button-primary',
                                        'text-sm',
                                        'flex-1',
                                        'md:flex-none',
                                        'md:row-start-4',
                                        'md:w-full',
                                    )}
                                    to={`/realestate/${item.uuid}/intermediary`}>
                                    {t('CRealestateListItem.紹介')}
                                </CLinkButton>
                            ) : (
                                <CButton
                                    className={clsx(
                                        'c-button-primary',
                                        'text-sm',
                                        'flex-1',
                                        'md:flex-none',
                                        'md:row-start-3',
                                        'md:w-full',
                                    )}
                                    disabled
                                    preventDefault>
                                    {t('CRealestateListItem.紹介')}
                                </CButton>
                            )}
                            <CButton
                                className={clsx(
                                    'c-button-primary',
                                    'text-sm',
                                    'flex-1',
                                    'md:flex-none',
                                    'md:row-start-3',
                                    'md:w-full',
                                )}
                                onClick={() => setOpenEditModal(true)}
                                disabled={user?.roles.realestate !== 2}
                                preventDefault>
                                {t('CRealestateListItem.編集')}
                            </CButton>
                            <CButton
                                className={clsx(
                                    'c-button-primary',
                                    'text-sm',
                                    'flex-1',
                                    'md:flex-none',
                                    'md:row-start-1',
                                    'flex',
                                    'md:w-full',
                                )}
                                onClick={() => onClickChatButton()}
                                disabled={
                                    !item.intermediaryFrom ||
                                    !!item.intermediaryFrom.intermediaryFinishedAt ||
                                    !!item.intermediaryFrom.reviewFinishedAt
                                }
                                preventDefault>
                                {t('CRealestateListItem.チャット')}
                                {isReceiveNewChat && (
                                    <div className={clsx('w-2', 'h-2', 'rounded-full', 'bg-red-700', '-translate-y-1.5')} />
                                )}
                            </CButton>
                        </div>
                    </div>
                </div>
            </Link>
            <CRealestateEdit
                visible={openEditModal}
                realEstate={item}
                onSubmit={(realEstate, isShowFinishPublishMarket) => {
                    setOpenEditModal(false)
                    if (isShowFinishPublishMarket) setShowFinishPublishMarket(realEstate)
                }}
                onClose={() => {
                    setOpenEditModal(false)
                }}
            />
            <CRealEstateDetailFinishAllIntermediary
                visible={!!showFinishPublishMarket}
                realEstate={showFinishPublishMarket}
                onSubmit={() => setShowFinishPublishMarket(undefined)}
                onClose={() => setShowFinishPublishMarket(undefined)}
            />
        </>
    )
}

export type CRealestateListItemProps = {
    item: ClientRealEstateListDto
    isReceiveNewChat: boolean
    isSendNewChat: boolean
    isNewRecommend: boolean
    progress: { uuid: string; progress: number }
    isHideMap?: boolean
    details?: (typeof RealEstateKeyLabels)[number]['key'][]
}

export const CRealestateListItem: VFC<CRealestateListItemProps> = (props) => {
    const [showFinishPublishMarket, setShowFinishPublishMarket] = useState<RealEstateEntities | undefined>(undefined)

    return (
        <CRealestateListItemView
            {...props}
            showFinishPublishMarket={showFinishPublishMarket}
            setShowFinishPublishMarket={setShowFinishPublishMarket}
        />
    )
}
