import clsx from 'clsx'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import { useAdminDownloadGetDownloadListQuery } from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { formatDateTime, pageTitleTemplate } from '~/util/common/common'
import { downloadTriggerFormat } from '~/util/filter/format'

const AdminDownload = () => {
    const navigate = useNavigate()
    // 資料DL一覧を取得
    const { data: downloads, isLoading: isLoadingDownloads } = useAdminDownloadGetDownloadListQuery({})

    return (
        <>
            <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3')}>
                <Helmet titleTemplate={pageTitleTemplate(true)}>
                    <title>資料ダウンロード管理</title>
                </Helmet>
                <CHeader label="資料ダウンロード管理" />
                <div className="page-content">
                    <div className={clsx('w-full', 'p-2')}>
                        {isLoadingDownloads && <CMessage info>読み込み中です...</CMessage>}
                        <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                            <thead>
                                <tr className={clsx('bg-kimar-primary', 'text-white')}>
                                    <th>資料請求日</th>
                                    <th>会社名</th>
                                    <th>ルート</th>
                                    <th>きっかけ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {downloads?.list.map((download) => (
                                    <tr
                                        key={download.uuid}
                                        onClick={() => navigate(download.uuid)}
                                        className={clsx('border', 'hover:bg-kimar-primary-light', 'cursor-pointer')}>
                                        <td className={clsx('p-1')}>{formatDateTime(download.createdAt, 'dateTime')}</td>
                                        <td className={clsx('p-1', 'text-kimar-primary')}>
                                            {download.companyName}／{download.name}様
                                        </td>
                                        <td className={clsx('p-1')}>
                                            {!download.intermediaryFrom ? 'キマールHP' : 'ご案内メール'}
                                        </td>
                                        <td className={clsx('p-1')}>{downloadTriggerFormat(download)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminDownload
