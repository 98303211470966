import clsx from 'clsx'
import { isNil } from 'lodash'
import { ReactNode, VFC } from 'react'

export type CSegmentedControlViewProps = {
    tabs: {
        label: string
        value: unknown
        checked?: boolean
    }[]
    onClick: (val: unknown) => void
} & CSegmentedControlProps

export const CSegmentedControlView: VFC<CSegmentedControlViewProps> = ({ tabs, selectedTabValue, children, onClick }) => {
    return (
        <div className={clsx('w-full', 'flex', 'flex-col', 'items-center')}>
            {!isNil(selectedTabValue) && (
                <>
                    <div className={clsx('w-full', 'text-sm')}>
                        <div className={clsx('flex', 'border', 'border-kimar-primary', 'py-1', 'rounded-full', 'divide-x')}>
                            {tabs.map((tab) => (
                                <div key={tab.label} className={clsx('flex-1', 'px-1')}>
                                    <button
                                        className={clsx(
                                            'whitespace-nowrap',
                                            'rounded-full',
                                            'w-full',
                                            'text-center',
                                            tab.value === selectedTabValue && [
                                                'bg-kimar-primary',
                                                'hover:brightness-110',
                                                'text-white',
                                            ],
                                            tab.value != selectedTabValue && ['hover:bg-kimar-primary-light', 'text-gray-700'],
                                            'md:p-1',
                                            'p-0',
                                            'cursor-pointer',
                                            'transition',
                                        )}
                                        onClick={() => onClick(tab.value)}>
                                        <span className={clsx('text-xs', 'md:text-base')}>{tab.label}</span>
                                        {tab.checked && (
                                            <i
                                                className={clsx(
                                                    'material-icons',
                                                    'text-red-700',
                                                    'text-xs',
                                                    'absolute',
                                                    'ml-0.5',
                                                )}>
                                                circle
                                            </i>
                                        )}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={clsx('flex-1', 'w-full')}>{children}</div>
                </>
            )}
        </div>
    )
}

export type CSegmentedControlProps = {
    children?: ReactNode
    selectedTabValue: unknown
    items: Array<{ [key: string]: unknown } & { checked?: boolean }>
    dataLabel?: string
    dataValue?: string
    onClick: (val: unknown) => void
}

export const CSegmentedControl: VFC<CSegmentedControlProps> = (props) => {
    const { items, dataLabel, dataValue } = { ...props }
    const labelValues = items.map((data) => {
        const label = (dataLabel ? data[dataLabel] : data.label ? data.label : data.value) as string
        const value = (dataValue ? data[dataValue] : data.value) as unknown
        const checked = data.checked
        return { label, value, checked }
    })

    return <CSegmentedControlView {...props} tabs={labelValues} />
}
