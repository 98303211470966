import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { CompanyEntities } from '~/app/api'
import { getSignedUrl } from '~/util/model/FileEntities'
import { selectCredential } from '~/util/store/authSlice'
import { selectLoginUserCompanyLogo } from '~/util/store/userSlice'

import { useAppSelector } from '../../../util/store/hooks'

export const CompanyLogo = ({ company }: { company?: CompanyEntities }) => {
    // ログインしたユーザーの会社 or 指定された会社のロゴ
    const userCompanyLogo = useAppSelector(selectLoginUserCompanyLogo)
    const credential = useAppSelector(selectCredential)
    const [logoUrl, setLogoUrl] = useState<string>()
    useEffect(() => {
        const getLogo = async () => {
            if (!credential) return
            if (company?.logoFile) setLogoUrl(getSignedUrl(company.logoFile))
            else if (userCompanyLogo) setLogoUrl(getSignedUrl(userCompanyLogo))
            else setLogoUrl(`/src/assets/image/logo.svg`)
        }
        getLogo()
    }, [userCompanyLogo, company])

    if (logoUrl === undefined) return null

    return (
        <img
            className={clsx('h-9', 'md:h-12', 'w-36', 'object-contain')}
            src={logoUrl}
            alt="キマール"
            crossOrigin="anonymous"
            onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = '/src/assets/image/notfound.svg'
            }}
        />
    )
}
