import clsx from 'clsx'
import { motion } from 'framer-motion'
import { CSSProperties, FC, ReactNode, useEffect } from 'react'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

export type CModalV2ViewProps = {} & CModalV2Props

export const CModalV2View: FC<CModalV2ViewProps> = ({
    children,
    visible,
    content,
    onAfterOpen,
    onRequestClose,
    closeOnOverlayClick = true,
}) => {
    return (
        <ReactModal
            isOpen={visible}
            shouldCloseOnOverlayClick={closeOnOverlayClick}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    border: 'none',
                    padding: '0',
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    background: 'white',
                    position: 'absolute',
                    ...content,
                },
                overlay: {
                    background: 'rgba(62, 62, 62, 0.75)',
                    zIndex: '100',
                },
            }}>
            {children}
        </ReactModal>
    )
}

export type CFooterModalV2ViewProps = {} & CModalV2ViewProps & CFooterModalV2Props
export const CFooterModalV2View: FC<CFooterModalV2ViewProps> = ({ ...props }) => {
    return (
        <CModalV2View {...props}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    duration: 0.3,
                    ease: 'easeInOut',
                }}
                className={clsx('h-full')}>
                <div className={clsx('flex', 'flex-col', 'h-full')}>
                    <div className={clsx('flex-1', 'overflow-hidden')}>{props.children}</div>
                    <div className={clsx('flex', 'justify-center', 'w-full', 'bg-gray-100', 'p-4', 'gap-x-2')}>
                        {props.footer}
                    </div>
                </div>
            </motion.div>
        </CModalV2View>
    )
}

export type CModalV2Props = {
    visible: boolean
    closeOnOverlayClick?: boolean
    children?: ReactNode
    content?: CSSProperties
    onAfterOpen?: () => void
    onRequestClose: () => void
}

export const CModalV2: FC<CModalV2Props> = ({ ...props }) => {
    // 背景スクロール抑制
    const stopScrollingBackContent = () => {
        if (props.visible) document.body.style.overflow = 'hidden'
        else document.body.style.overflow = 'auto'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }

    useEffect(stopScrollingBackContent, [props.visible])

    return <CModalV2View {...props} />
}

export type CFooterModalV2Props = {
    footer: ReactNode
} & CModalV2Props

export const CFooterModalV2: FC<CFooterModalV2Props> = ({ ...props }) => {
    // 背景スクロール抑制
    const stopScrollingBackContent = () => {
        if (props.visible) document.body.style.overflow = 'hidden'
        else document.body.style.overflow = 'auto'

        return () => {
            document.body.style.overflow = 'auto'
        }
    }

    useEffect(stopScrollingBackContent, [props.visible])
    return <CFooterModalV2View {...props} />
}
