import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { ClientTeamMemberConditionUpdateDto, useClientUserConditionQuery } from '~/app/api'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'

import CConditionForm from '../form/CConditionForm'

type CUserPurchaseConditionContentProps = {
    isModal?: boolean
    // 購入条件が未設定かどうかを伝えるメソッド
    isConditionNull?: (isConditionNull: boolean) => void
    changeHandler: (updateConditionDto: ClientTeamMemberConditionUpdateDto) => void
}

const CUserPurchaseConditionContent = ({
    isModal = false,
    isConditionNull,
    changeHandler,
}: CUserPurchaseConditionContentProps) => {
    const { data: conditionData, isSuccess: conditionLoadSuccess } = useClientUserConditionQuery()
    const [updateConditionDto, setUpdateConditionDto] = useState<ClientTeamMemberConditionUpdateDto>()
    useEffect(() => {
        if (!conditionLoadSuccess) return
        let currentConditionDto: ClientTeamMemberConditionUpdateDto = {
            isShareCondition: false,
            isRecommendTarget: false,
            area: [],
            buildingAgeType: 1,
            grossRateType: 40,
            legalComplianceType: 1,
            netRateType: 30,
            prices: [],
            purchaseTimeType: 1,
            seismicStandardType: 1,
            useType: [],
        }
        if (!conditionData) isConditionNull && isConditionNull(true)
        else {
            currentConditionDto = {
                isShareCondition: conditionData.isShareCondition,
                isRecommendTarget: conditionData.teamMember.isRecommendTarget,
                area: conditionData.area,
                buildingAgeType: conditionData.buildingAgeType,
                grossRateType: conditionData.grossRateType,
                legalComplianceType: conditionData.legalComplianceType,
                netRateType: conditionData.netRateType,
                prices: conditionData.prices,
                purchaseTimeType: conditionData.purchaseTimeType,
                seismicStandardType: conditionData.seismicStandardType,
                useType: conditionData.useType,
            }
            isConditionNull && isConditionNull(false)
        }
        setUpdateConditionDto(currentConditionDto)
        // GET直後の値の状態を親コンポーネントにも伝える
        changeHandler(currentConditionDto)
    }, [conditionLoadSuccess])

    const setConditionProps = (key: string, value: unknown) => {
        if (!updateConditionDto) return
        const newDto = {
            ...updateConditionDto,
            [key]: value,
        }
        setUpdateConditionDto(newDto)
        changeHandler(newDto)
    }

    const linkToHelp = () => {
        window.open(
            'https://drive.google.com/file/d/1USiJCjJwpHpn9OXDliHlPezWzqUQDjP_/view?usp=sharing',
            '_blank',
            'noopener,noreferrer',
        )
    }

    if (!updateConditionDto) return null

    return (
        <>
            {!isModal && (
                <div className={clsx('mb-2', 'flex', 'flex-col', 'space-y-4')}>
                    <div className={clsx('flex', 'justify-start', 'items-end')}>
                        <div className={clsx('font-bold', 'text-lg')}>
                            {t('CUserPurchaseConditionContent.マーケットの利用設定')}
                        </div>
                        <div
                            className={clsx(
                                'ml-2',
                                'text-sm',
                                'border-b',
                                'border-kimar-primary',
                                'text-kimar-primary',
                                'cursor-pointer',
                            )}
                            onClick={linkToHelp}>
                            {t('CUserPurchaseConditionContent.詳細はこちら')}
                        </div>
                    </div>
                    <div className={clsx('border-l-2', 'pl-4', 'py-2')}>
                        <div className={clsx('flex', 'text-sm', 'items-center', 'justify-between', 'mb-2', 'hover:bg-gray-100')}>
                            <div>{t('CUserPurchaseConditionContent.自分を顧客とする会社に購入条件を開示する')}</div>
                            <CToggleSwitch
                                width="lg"
                                value={updateConditionDto.isShareCondition}
                                trueLabel={t('CUserPurchaseConditionContent.YES')}
                                falseLabel={t('CUserPurchaseConditionContent.NO')}
                                toggleChanged={(value) => setConditionProps('isShareCondition', value)}
                            />
                        </div>
                        <div className={clsx('flex', 'text-sm', 'items-center', 'justify-between', 'hover:bg-gray-100')}>
                            <div>{t('CUserPurchaseConditionContent.社外に存在する新規のお客様から物件提案を受ける')}</div>
                            <CToggleSwitch
                                width="lg"
                                value={updateConditionDto.isRecommendTarget}
                                trueLabel={t('CUserPurchaseConditionContent.YES')}
                                falseLabel={t('CUserPurchaseConditionContent.NO')}
                                toggleChanged={(value) => setConditionProps('isRecommendTarget', value)}
                            />
                        </div>
                    </div>
                </div>
            )}
            <CConditionForm formDto={updateConditionDto} changeHandler={(key, value) => setConditionProps(key, value)} />
        </>
    )
}

export default CUserPurchaseConditionContent
