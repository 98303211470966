import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { isNil, sortBy } from 'lodash'
import { useEffect, useRef, useState, VFC } from 'react'

import {
    RealEstateLabelEntities,
    RealEstateUseTypeEntities,
    TeamMemberEntities,
    useClientDashboardAnalyticsDashboardAnalyticsQuery,
    useClientRealEstateLabelTeamUuidQuery,
    useClientRealEstateUseTypeRealEstateUseTypeQuery,
    useClientTeamMemberTeamUuidQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBoxItemInterface, CCheckBoxList } from '~/components/common/cCheckBoxList/CCheckBoxList'
import { CGuideTips } from '~/components/common/cGuideTips/CGuideTips'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CTag } from '~/components/common/cTag/CTag'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { CYearMonthDay } from '~/components/common/cYearMonthDay/CYearMonthDay'
import {
    ageRanges,
    progressItemsSearch,
    realestateGrossRateTypeItems,
    realestateSalesDestinationTypeItems,
    saleTimeTypeItems,
    searchPriceRanges,
} from '~/types/enum/enum'
import { formatDateTime } from '~/util/common/common'
import { useAppSelector } from '~/util/store/hooks'
import {
    selectLoginUserHaveConnectionContract,
    selectLoginUserHaveMarketContract,
    selectLoginUserHaveRecommendContract,
    selectLoginUserTeam,
} from '~/util/store/userSlice'

export type CRealestateSearchViewProps = {
    isRecommendFeature: boolean
    isConnectionFeature: boolean
    isMarketFeature: boolean
    isOpenDetail: boolean
    onClickDetail: () => void
    isOpenFilter: boolean
    onClickFilter: () => void
    onChangeText: (val: string) => void
    hasMarketPublishRequest: boolean
    form: RealEstateSearchFormValue
    name: string
    onChangeDetail: (key: keyof RealEstateSearchFormValue, val?: string | number) => void
    resetFilterCheckBox: () => void
    onChangeFilterCheckBox: (val: RealEstateSearchFilterArrayType, target: RealEstateSearchFilterArrayType) => void
    onChangeDetailCheckBox: (key: keyof RealEstateSearchFormValue, val: string[] | number[]) => void
    onChangeDetailAndOrToggle: (key: keyof RealEstateSearchFormValue, val: 'AND' | 'OR') => void
    resetDetail: () => void
    teamMembers: TeamMemberEntities[]
    useTypes: RealEstateUseTypeEntities[]
    labels: RealEstateLabelEntities[]
    closeAll: () => void
} & CRealestateSearchProps

export const CRealestateSearchView: VFC<CRealestateSearchViewProps> = ({
    isRecommendFeature,
    isConnectionFeature,
    isMarketFeature,
    isOpenDetail,
    onClickDetail,
    isOpenFilter,
    onClickFilter,
    onChangeText,
    hasMarketPublishRequest,
    form,
    name,
    onChangeDetail,
    resetFilterCheckBox,
    onChangeFilterCheckBox,
    onChangeDetailCheckBox,
    onChangeDetailAndOrToggle,
    resetDetail,
    teamMembers,
    useTypes,
    labels,
    closeAll,
}) => {
    // 選択中の絞り込みと詳細検索を表示する
    const filterTags = () => {
        let elements: JSX.Element[] = []
        elements = elements.concat(
            form.filter.map((v) => {
                return (
                    <CTag className={clsx('rounded', 'px-2', 'text-gray-700', 'bg-gray-300', 'text-sm', 'items-center')}>
                        <div className={clsx('flex', 'justify-center')}>
                            <div className={clsx('whitespace-nowrap')}>
                                {RealEstateSearchFilterLangString.find((s) => s.value === v)?.label}
                            </div>
                            <div>
                                <CButton
                                    onClick={() => {
                                        onChangeFilterCheckBox(
                                            form.filter.filter((f) => f != v) as RealEstateSearchFilterArrayType,
                                            Array.from(RealEstateSearchFilterStrings),
                                        )
                                    }}>
                                    <i className={clsx('material-symbols-outlined', 'text-sm')}>close</i>
                                </CButton>
                            </div>
                        </div>
                    </CTag>
                )
            }),
        )

        elements = elements.concat(
            [
                {
                    prop: 'useTypes' as const,
                    label: (v: unknown) => `${t('CRealestateSearch.種別')}:${useTypes.find((s) => s.uuid === v)?.name}`,
                    onClick: (v: unknown) => onChangeDetailCheckBox('useTypes', form.useTypes.filter((f) => f != v) as string[]),
                },
                {
                    prop: 'prices' as const,
                    label: (v: unknown) =>
                        `${t('CRealestateSearch.物件価格')}:${searchPriceRanges.find((s) => s.value === v)?.label}`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox('prices', form.prices.filter((f) => f !== v) as unknown as number[]),
                },
                {
                    prop: 'buildingAges' as const,
                    label: (v: unknown) => `${t('CRealestateSearch.築年数')}:${ageRanges.find((s) => s.value === v)?.label}`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox('buildingAges', form.buildingAges.filter((f) => f !== v) as unknown as number[]),
                },
                {
                    prop: 'saleTimes' as const,
                    label: (v: unknown) =>
                        `${t('CRealestateSearch.売却時期')}:${saleTimeTypeItems.find((s) => s.value === v)?.label}`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox('saleTimes', form.saleTimes.filter((f) => f !== v) as unknown as number[]),
                },
                {
                    prop: 'assignedToUuidList' as const,
                    label: (v: unknown) =>
                        `${t('CRealestateSearch.担当者')}:${
                            teamMembers.map((m) => ({ label: m.name, value: m.uuid })).find((s) => s.value === v)?.label
                        }`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox(
                            'assignedToUuidList',
                            form.assignedToUuidList.filter((f) => f !== v) as unknown as number[],
                        ),
                },
                {
                    prop: 'labelUuidList' as const,
                    label: (v: unknown) =>
                        `${t('CRealestateSearch.物件ラベル')}:${
                            sortBy(labels, (s) => s.sort)
                                .map((m) => ({
                                    label: m.name,
                                    value: m.uuid,
                                }))
                                .find((s) => s.value === v)?.label
                        }`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox('labelUuidList', form.labelUuidList.filter((f) => f !== v) as unknown as number[]),
                },
                {
                    prop: 'progress' as const,
                    label: (v: unknown) =>
                        `${t('CRealestateSearch.進捗')}:${progressItemsSearch.find((s) => s.value === v)?.label}`,
                    onClick: (v: unknown) =>
                        onChangeDetailCheckBox('progress', form.progress.filter((f) => f !== v) as unknown as number[]),
                },
            ].flatMap((p) => {
                return form[p.prop].map((v) => {
                    return (
                        <CTag className={clsx('rounded', 'px-2', 'text-gray-700', 'bg-gray-300', 'text-sm', 'items-center')}>
                            <div className={clsx('flex', 'justify-center')}>
                                <div className={clsx('whitespace-nowrap')}>{p.label(v)}</div>
                                <div>
                                    <CButton
                                        onClick={() => {
                                            p.onClick(v)
                                        }}>
                                        <i className={clsx('material-symbols-outlined', 'text-sm')}>close</i>
                                    </CButton>
                                </div>
                            </div>
                        </CTag>
                    )
                })
            }),
        )

        elements = elements.concat(
            [
                {
                    prop: 'acquiredAtFrom' as const,
                    label: () => `${formatDateTime(form.acquiredAtFrom, 'date')}${t('CRealestateSearch.から')}`,
                    onClick: () => onChangeDetail('acquiredAtFrom', undefined),
                },
                {
                    prop: 'acquiredAtTo' as const,
                    label: () => `${formatDateTime(form.acquiredAtTo, 'date')}${t('CRealestateSearch.まで')}`,
                    onClick: () => onChangeDetail('acquiredAtTo', undefined),
                },
            ]
                .map((p) => {
                    if (isNil(form[p.prop])) return undefined
                    return (
                        <CTag className={clsx('rounded', 'px-2', 'text-gray-700', 'bg-gray-300', 'text-sm', 'items-center')}>
                            <div className={clsx('flex', 'justify-center')}>
                                <div className={clsx('whitespace-nowrap')}>{p.label()}</div>
                                <div>
                                    <CButton
                                        onClick={() => {
                                            p.onClick()
                                        }}>
                                        <i className={clsx('material-symbols-outlined', 'text-sm')}>close</i>
                                    </CButton>
                                </div>
                            </div>
                        </CTag>
                    )
                })
                .filter((t): t is Exclude<typeof t, undefined> => t !== undefined),
        )

        if (!elements.length) return null

        return (
            <div
                className={clsx(
                    'flex',
                    'gap-1',
                    'flex-wrap',
                    'py-2',
                    'px-1',
                    'border-x',
                    'border-kimar-primary',
                    'md:border-none',
                    'bg-white',
                    'md:bg-inherit',
                )}>
                {elements}
            </div>
        )
    }

    const cRealEstateSearchRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!cRealEstateSearchRef) return
        const handleClickOutside = (e: MouseEvent) => {
            if (!(cRealEstateSearchRef.current as Node)?.contains(e.target as Node)) closeAll()
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [cRealEstateSearchRef])

    return (
        <div ref={cRealEstateSearchRef} className={clsx('w-full')}>
            <div className={clsx('w-full', 'flex', 'flex-col', 'md:flex-row', 'font-body')}>
                <div
                    className={clsx(
                        'flex-1',
                        isOpenDetail ? ['border', 'rounded-tl-xl'] : ['border', 'rounded-tl-xl', 'md:rounded-l-xl'],
                        'rounded-tr-xl',
                        'md:rounded-tr-none',
                        'border-kimar-primary',
                        'bg-white',
                    )}>
                    <div className={clsx('flex')}>
                        <div className={clsx('flex-1', 'flex', 'items-center', 'pl-2', 'relative')}>
                            <i className={clsx('material-icons', 'text-gray-300')}>search</i>
                            <div className={clsx('hidden', 'md:block', 'max-w-[33%]')}>{filterTags()}</div>
                            <CTextInput
                                className={clsx(
                                    'w-full',
                                    'bg-inherit',
                                    'border-none',
                                    'focus:ring-0',
                                    'pl-0',
                                    'placeholder:text-gray-300',
                                )}
                                type={'text'}
                                placeholder={t('CRealestateSearch.検索キーワードを入力')}
                                text={name}
                                onChange={(val) => onChangeText(val)}
                            />
                            <div className={clsx('mr-2', 'z-30')}>
                                <CGuideTips tooltipDirection="bottom-left">
                                    <b>{t('CRealestateSearch.＜検索キーワード＞')}</b>
                                    <br />
                                    {t('CRealestateSearch.・物件名')}
                                    <br />
                                    {t('CRealestateSearch.・住所')}
                                    <br />
                                    {t('CRealestateSearch.・登録者名/登録チーム名')}
                                    <br />
                                    {t('CRealestateSearch.・紹介者名/紹介元会社名')}
                                    <br />
                                    {t('CRealestateSearch.・物件担当者ユーザー名')}
                                    <br />
                                    {t('CRealestateSearch.・最寄り駅')}
                                    <br />
                                    {t('CRealestateSearch.・情報取得元')}
                                    <br />
                                    {t('CRealestateSearch.・売主')}
                                    <br />
                                    {t('CRealestateSearch.・その他')}
                                </CGuideTips>
                            </div>
                        </div>
                        <div className={clsx('relative')}>
                            <CButton
                                className={clsx(
                                    'c-button-secondary',
                                    'w-full',
                                    'min-w-[120px]',
                                    'h-full',
                                    'border-r-0',
                                    'border-t-0',
                                    'border-b-0',
                                    'border-kimar-primary',
                                    'rounded-none',
                                    'rounded-tr-xl',
                                    'md:rounded-tr-none',
                                )}
                                onClick={() => onClickFilter()}>
                                <i className={clsx('material-icons-outlined', 'text-kimar-primary')}>filter_alt</i>
                                <div>{t('CRealestateSearch.絞り込み')}</div>
                                {hasMarketPublishRequest && (
                                    <div
                                        className={clsx(
                                            'w-2',
                                            'h-2',
                                            'rounded-full',
                                            'bg-red-700',
                                            '-translate-y-1',
                                            '-translate-x-0.5',
                                        )}
                                    />
                                )}
                            </CButton>
                            <div
                                className={clsx(
                                    'absolute',
                                    'bg-white',
                                    'p-2',
                                    'top-[42px]',
                                    'right-0',
                                    'md:-right-0',
                                    'z-20',
                                    'shadow',
                                    'rounded',
                                    !isOpenFilter && 'hidden',
                                    'min-w-[250px]',
                                )}>
                                <CCheckBoxList
                                    items={[
                                        { label: t('CRealestateSearch.登録した'), value: 'owned' },
                                        { label: t('CRealestateSearch.紹介された'), value: 'introduced' },
                                    ]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as RealEstateSearchFilterArrayType, ['owned', 'introduced'])
                                    }}
                                />
                                <hr />
                                <CCheckBoxList
                                    items={[
                                        { label: t('CRealestateSearch.紹介している'), value: 'sending' },
                                        { label: t('CRealestateSearch.紹介していない'), value: 'notSending' },
                                    ]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as RealEstateSearchFilterArrayType, ['sending', 'notSending'])
                                    }}
                                />
                                <hr />
                                <CCheckBoxList
                                    items={[
                                        { label: t('CRealestateSearch.紹介されている'), value: 'receiving' },
                                        { label: t('CRealestateSearch.紹介されていた'), value: 'received' },
                                    ]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as RealEstateSearchFilterArrayType, ['receiving', 'received'])
                                    }}
                                />
                                <hr />
                                <CCheckBoxList
                                    items={[
                                        { label: t('CRealestateSearch.自分が担当'), value: 'assigned' },
                                        { label: t('CRealestateSearch.終了物件を含む'), value: 'includeEnded' },
                                        { label: t('CRealestateSearch.終了物件のみ'), value: 'endedRealEstate' },
                                        { label: t('CRealestateSearch.重複している'), value: 'duplicate' },
                                        { label: t('CRealestateSearch.重複していない'), value: 'notDuplicate' },
                                    ]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as RealEstateSearchFilterArrayType, [
                                            'assigned',
                                            'includeEnded',
                                            'endedRealEstate',
                                            'duplicate',
                                            'notDuplicate',
                                        ])
                                    }}
                                />
                                {(isRecommendFeature || isConnectionFeature) && (
                                    <>
                                        <hr />
                                        <CCheckBoxList
                                            items={
                                                isRecommendFeature && isConnectionFeature
                                                    ? [
                                                          {
                                                              label: t('CRealestateSearch.レコメンド/コネクション対象外'),
                                                              value: 'notRecommendTarget',
                                                          },
                                                          {
                                                              label: t('CRealestateSearch.レコメンド/コネクション対象'),
                                                              value: 'recommendTarget',
                                                          },
                                                          {
                                                              label: t('CRealestateSearch.レコメンドあり'),
                                                              value: 'recommendExists',
                                                          },
                                                          {
                                                              label: t('CRealestateSearch.コネクションあり'),
                                                              value: 'connectionExists',
                                                          },
                                                      ]
                                                    : isRecommendFeature
                                                    ? [
                                                          {
                                                              label: t('CRealestateSearch.レコメンド対象外'),
                                                              value: 'notRecommendTarget',
                                                          },
                                                          {
                                                              label: t('CRealestateSearch.レコメンド対象'),
                                                              value: 'recommendTarget',
                                                          },
                                                          {
                                                              label: t('CRealestateSearch.レコメンドあり'),
                                                              value: 'recommendExists',
                                                          },
                                                      ]
                                                    : isConnectionFeature
                                                    ? [
                                                          {
                                                              label: t('CRealestateSearch.コネクション対象外'),
                                                              value: 'notRecommendTarget',
                                                          },
                                                          {
                                                              label: t('CRealestateSearch.コネクション対象'),
                                                              value: 'recommendTarget',
                                                          },
                                                          {
                                                              label: t('CRealestateSearch.コネクションあり'),
                                                              value: 'connectionExists',
                                                          },
                                                      ]
                                                    : [
                                                          // nothing
                                                      ]
                                            }
                                            checked={form.filter}
                                            onChange={(val) => {
                                                onChangeFilterCheckBox(
                                                    val as RealEstateSearchFilterArrayType,
                                                    isRecommendFeature && isConnectionFeature
                                                        ? [
                                                              'notRecommendTarget',
                                                              'recommendTarget',
                                                              'recommendExists',
                                                              'connectionExists',
                                                          ]
                                                        : isRecommendFeature
                                                        ? ['notRecommendTarget', 'recommendTarget', 'recommendExists']
                                                        : isConnectionFeature
                                                        ? ['notRecommendTarget', 'recommendTarget', 'connectionExists']
                                                        : [
                                                              // nothing
                                                          ],
                                                )
                                            }}
                                        />
                                    </>
                                )}
                                {isMarketFeature && (
                                    <>
                                        <hr />
                                        <CCheckBoxList
                                            items={[
                                                { label: t('CRealestateSearch.マーケット未掲載'), value: 'notRegisteringMarket' },
                                                { label: t('CRealestateSearch.マーケット掲載中'), value: 'registeringMarket' },
                                                {
                                                    label: t('CRealestateSearch.情報開示リクエストあり'),
                                                    value: 'requestExists',
                                                    attention: hasMarketPublishRequest,
                                                },
                                            ]}
                                            checked={form.filter}
                                            onChange={(val) => {
                                                onChangeFilterCheckBox(val as RealEstateSearchFilterArrayType, [
                                                    'notRegisteringMarket',
                                                    'registeringMarket',
                                                    'requestExists',
                                                ])
                                            }}
                                        />
                                    </>
                                )}
                                <CButton
                                    className={clsx('c-button-secondary', 'w-full', 'mb-1')}
                                    onClick={() => resetFilterCheckBox()}>
                                    {t('CRealestateSearch.絞り込みをリセット')}
                                </CButton>
                                <CButton className={clsx('c-button-primary', 'w-full')} onClick={() => onClickFilter()}>
                                    {t('CRealestateSearch.絞り込みを閉じる')}
                                </CButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('md:hidden', 'block', 'max-w-full')}>{filterTags()}</div>
                <CButton
                    className={clsx(
                        'c-button-primary',
                        'rounded-none',
                        isOpenDetail ? 'md:rounded-tr-xl' : ['rounded-b-xl', 'md:rounded-bl-none', 'md:rounded-r-xl'],
                    )}
                    onClick={() => onClickDetail()}>
                    <div
                        className={clsx(
                            'w-3',
                            'h-3',
                            isOpenDetail
                                ? ['border-r-2', 'border-t-2', 'translate-y-1']
                                : ['border-l-2', 'border-b-2', '-translate-y-1'],
                            'border-white',
                            '-rotate-45',
                            'm-2',
                            'transition',
                        )}
                    />
                    <div>{t('CCustomerSearch.詳細検索')}</div>
                </CButton>
            </div>
            <div
                className={clsx(
                    'w-full',
                    'p-2',
                    'font-body',
                    'border-b',
                    'border-l',
                    'border-r',
                    'border-kimar-primary',
                    'rounded-b-2xl',
                    'bg-white',
                    'bg-opacity-70',
                    'backdrop-blur',
                    !isOpenDetail && 'hidden',
                )}>
                <div className={clsx('max-h-40', 'md:max-h-none', 'overflow-y-scroll', 'overflow-x-hidden')}>
                    <div className={clsx('grid', 'grid-cols-2', 'md:grid-cols-5', 'gap-1', 'md:gap-4')}>
                        <CLabeledItem label={t('CRealestateSearch.種別')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-16', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={useTypes}
                                    dataLabel={'name'}
                                    dataValue={'uuid'}
                                    checked={form.useTypes}
                                    onChange={(val) => onChangeDetailCheckBox('useTypes', val as string[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CRealestateSearch.物件価格')} className={clsx('')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-16', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={searchPriceRanges as unknown as CCheckBoxItemInterface[]}
                                    checked={form.prices}
                                    onChange={(val) => onChangeDetailCheckBox('prices', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CRealestateSearch.築年数')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-16', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={ageRanges as unknown as CCheckBoxItemInterface[]}
                                    checked={form.buildingAges}
                                    onChange={(val) => onChangeDetailCheckBox('buildingAges', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CRealestateSearch.表面利回り')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-16', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={realestateGrossRateTypeItems}
                                    checked={form.grossRate}
                                    onChange={(val) => onChangeDetailCheckBox('grossRate', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CRealestateSearch.先数')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-16', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={realestateSalesDestinationTypeItems}
                                    checked={form.salesDestination}
                                    onChange={(val) => onChangeDetailCheckBox('salesDestination', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CRealestateSearch.売却時期')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-16', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={saleTimeTypeItems}
                                    checked={form.saleTimes}
                                    onChange={(val) => onChangeDetailCheckBox('saleTimes', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem
                            labelNode={
                                <div className={clsx('w-full', 'flex', 'justify-between')}>
                                    <div>{t('CRealestateSearch.担当者')}</div>
                                    <div className={clsx('flex', 'gap-1')}>
                                        <CToggleSwitch
                                            trueLabel={t('CRealestateSearch.AND')}
                                            falseLabel={t('CRealestateSearch.OR')}
                                            toggleChanged={(val) =>
                                                onChangeDetailAndOrToggle('assignedToType', val ? 'AND' : 'OR')
                                            }
                                            value={form.assignedToType === 'AND'}
                                        />
                                        <CGuideTips tooltipDirection="bottom-left">
                                            <b>{t('CRealestateSearch.AND')}</b>
                                            <br />
                                            {t('CRealestateSearch.すべての条件に一致')}
                                            <br />
                                            <b>{t('CRealestateSearch.OR')}</b>
                                            <br />
                                            {t('CRealestateSearch.いずれかの条件に一致')}
                                        </CGuideTips>
                                    </div>
                                </div>
                            }>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-16', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={[{ label: t('CRealestateSearch.担当者なし'), value: 'noAssigned' }]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as RealEstateSearchFilterArrayType, ['noAssigned'])
                                    }}
                                />
                                <hr />
                                <CCheckBoxList
                                    items={teamMembers.map((m) => ({ label: m.name, value: m.uuid }))}
                                    checked={form.assignedToUuidList}
                                    onChange={(val) => onChangeDetailCheckBox('assignedToUuidList', val as string[])}
                                />
                            </div>
                        </CLabeledItem>

                        <CLabeledItem
                            labelNode={
                                <div className={clsx('w-full', 'flex', 'justify-between')}>
                                    <div className={clsx('whitespace-nowrap')}>{t('CRealestateSearch.物件ラベル')}</div>
                                    <div className={clsx('flex', 'gap-1')}>
                                        <CToggleSwitch
                                            trueLabel={t('CRealestateSearch.AND')}
                                            falseLabel={t('CRealestateSearch.OR')}
                                            toggleChanged={(val) => onChangeDetailAndOrToggle('labelType', val ? 'AND' : 'OR')}
                                            value={form.labelType === 'AND'}
                                        />
                                        <CGuideTips tooltipDirection="bottom-right">
                                            <b>{t('CRealestateSearch.AND')}</b>
                                            <br />
                                            {t('CRealestateSearch.すべての条件に一致')}
                                            <br />
                                            <b>{t('CRealestateSearch.OR')}</b>
                                            <br />
                                            {t('CRealestateSearch.いずれかの条件に一致')}
                                        </CGuideTips>
                                    </div>
                                </div>
                            }>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-16', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={[{ label: t('CRealestateSearch.ラベルなし'), value: 'noLabels' }]}
                                    checked={form.filter}
                                    onChange={(val) => {
                                        onChangeFilterCheckBox(val as RealEstateSearchFilterArrayType, ['noLabels'])
                                    }}
                                />
                                <hr />
                                <CCheckBoxList
                                    items={sortBy(labels, (s) => s.sort).map((m) => ({
                                        label: m.name,
                                        value: m.uuid,
                                    }))}
                                    checked={form.labelUuidList}
                                    onChange={(val) => onChangeDetailCheckBox('labelUuidList', val as string[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CRealestateSearch.進捗')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-16', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={progressItemsSearch}
                                    checked={form.progress}
                                    onChange={(val) => onChangeDetailCheckBox('progress', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem
                            className={clsx('col-span-2')}
                            labelNode={
                                <div className={clsx('w-full', 'flex', 'justify-between')}>
                                    <div>{t('CRealestateSearch.情報取得日')}</div>
                                </div>
                            }>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-1', 'h-16', 'overflow-y-auto')}>
                                <div className={clsx('flex', 'items-center')}>
                                    <CYearMonthDay
                                        tiny
                                        yearMargin={0}
                                        value={form.acquiredAtFrom ? dayjs(form.acquiredAtFrom).toDate() : undefined}
                                        type={'date'}
                                        onChange={(val) => {
                                            if (val) onChangeDetail('acquiredAtFrom', dayjs(val).toISOString())
                                            else onChangeDetail('acquiredAtFrom', undefined)
                                        }}
                                    />
                                    <div>{t('CRealestateSearch.から')}</div>
                                </div>
                                <div className={clsx('flex', 'items-center')}>
                                    <CYearMonthDay
                                        tiny
                                        yearMargin={0}
                                        value={form.acquiredAtTo ? dayjs(form.acquiredAtTo).toDate() : undefined}
                                        type={'date'}
                                        onChange={(val) => {
                                            if (val) onChangeDetail('acquiredAtTo', dayjs(val).toISOString())
                                            else onChangeDetail('acquiredAtTo', undefined)
                                        }}
                                    />
                                    <div>{t('CRealestateSearch.まで')}</div>
                                </div>
                            </div>
                        </CLabeledItem>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-1', 'sm:grid-cols-2', 'gap-2', 'my-2')}>
                    <CButton className={clsx('c-button-secondary', 'w-full')} onClick={() => resetDetail()}>
                        {t('CRealestateSearch.詳細検索をリセット')}
                    </CButton>
                    <CButton className={clsx('c-button-primary', 'w-full')} onClick={() => onClickDetail()}>
                        {t('CRealestateSearch.詳細検索を閉じる')}
                    </CButton>
                </div>
            </div>
        </div>
    )
}

export type RealEstateSearchFormValue = {
    name: string
    filter: RealEstateSearchFilterArrayType
    useTypes: string[]
    prices: string[]
    buildingAges: string[]
    saleTimes: string[]
    grossRate: string[]
    salesDestination: string[]
    assignedToType: 'AND' | 'OR'
    assignedToUuidList: string[]
    labelType: 'AND' | 'OR'
    labelUuidList: string[]
    progress: string[]
    acquiredAtFrom?: string
    acquiredAtTo?: string
}

const RealEstateSearchFilterStrings = [
    'owned',
    'introduced',
    'sending',
    'notSending',
    'receiving',
    'received',
    'assigned',
    'noLabels',
    'notRecommendTarget',
    'recommendTarget',
    'recommendExists',
    'connectionExists',
    'notRegisteringMarket',
    'registeringMarket',
    'requestExists',
    'noAssigned',
    'noLabels',
    'includeEnded',
    'endedRealEstate',
    'duplicate',
    'notDuplicate',
] as const
const RealEstateSearchFilterLangString = [
    { label: t('CRealestateSearch.登録した'), value: 'owned' },
    { label: t('CRealestateSearch.紹介された'), value: 'introduced' },
    { label: t('CRealestateSearch.紹介している'), value: 'sending' },
    { label: t('CRealestateSearch.紹介していない'), value: 'notSending' },
    { label: t('CRealestateSearch.紹介されている'), value: 'receiving' },
    { label: t('CRealestateSearch.紹介されていた'), value: 'received' },
    { label: t('CRealestateSearch.自分が担当'), value: 'assigned' },
    {
        label: t('CRealestateSearch.レコメンド/コネクション対象外'),
        value: 'notRecommendTarget',
    },
    {
        label: t('CRealestateSearch.レコメンド/コネクション対象'),
        value: 'recommendTarget',
    },
    {
        label: t('CRealestateSearch.レコメンドあり'),
        value: 'recommendExists',
    },
    {
        label: t('CRealestateSearch.コネクションあり'),
        value: 'connectionExists',
    },
    { label: t('CRealestateSearch.マーケット未掲載'), value: 'notRegisteringMarket' },
    { label: t('CRealestateSearch.マーケット掲載中'), value: 'registeringMarket' },
    {
        label: t('CRealestateSearch.情報開示リクエストあり'),
        value: 'requestExists',
    },
    { label: t('CRealestateSearch.担当者なし'), value: 'noAssigned' },
    { label: t('CRealestateSearch.ラベルなし'), value: 'noLabels' },
    {
        label: t('CRealestateSearch.終了物件を含む'),
        value: 'includeEnded',
    },
    {
        label: t('CRealestateSearch.終了物件のみ'),
        value: 'endedRealEstate',
    },
    {
        label: t('CRealestateSearch.重複している'),
        value: 'duplicate',
    },
    {
        label: t('CRealestateSearch.重複していない'),
        value: 'notDuplicate',
    },
]
export type RealEstateSearchFilter = (typeof RealEstateSearchFilterStrings)[number]
export type RealEstateSearchFilterArrayType = RealEstateSearchFilter[]

export type CRealestateSearchProps = {
    onChange: (value: RealEstateSearchFormValue) => void
}

export const CRealestateSearch: VFC<CRealestateSearchProps> = ({ ...props }) => {
    const isRecommendFeature = useAppSelector(selectLoginUserHaveRecommendContract)
    const isConnectionFeature = useAppSelector(selectLoginUserHaveConnectionContract)
    const isMarketFeature = useAppSelector(selectLoginUserHaveMarketContract)
    const userTeam = useAppSelector(selectLoginUserTeam)
    const { data: teamMemberListCount } = useClientTeamMemberTeamUuidQuery(
        { teamUuid: userTeam?.uuid ?? '' },
        { skip: !userTeam },
    )
    const { data: useTypeListCount } = useClientRealEstateUseTypeRealEstateUseTypeQuery({})
    const { data: dashboardStatus } = useClientDashboardAnalyticsDashboardAnalyticsQuery()
    const { data: labelListCount } = useClientRealEstateLabelTeamUuidQuery({ uuid: userTeam?.uuid ?? '' }, { skip: !userTeam })

    const [isOpenDetail, setIsOpenDetail] = useState(false)
    const [isOpenFilter, setIsOpenFilter] = useState(false)

    const onClickDetail = () => {
        setIsOpenDetail(!isOpenDetail)
        setIsOpenFilter(false)
    }
    const onClickFilter = () => {
        setIsOpenFilter(!isOpenFilter)
        setIsOpenDetail(false)
    }

    const closeAll = () => {
        setIsOpenDetail((v) => (v ? false : v))
        setIsOpenFilter((v) => (v ? false : v))
    }

    // 検索フォーム
    const [form, setForm] = useState<RealEstateSearchFormValue>({
        name: '',
        filter: [],
        useTypes: [],
        prices: [],
        buildingAges: [],
        saleTimes: [],
        grossRate: [],
        salesDestination: [],
        assignedToType: 'OR',
        assignedToUuidList: [],
        labelType: 'OR',
        labelUuidList: [],
        progress: [],
    })

    const [name, setName] = useState('')
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    const onChangeText = (val: string) => {
        setName(val)
        if (timer) clearTimeout(timer)
        const newTimer = setTimeout(() => {
            const newValue = {
                ...form,
                name: val,
            }
            setForm(newValue)
            props.onChange(newValue)
        }, 500)
        setTimer(newTimer)
    }

    const onChangeFilterCheckBox = (val: RealEstateSearchFilterArrayType, target: RealEstateSearchFilterArrayType) => {
        const newFilter = form.filter.filter((f) => !target.includes(f)).concat(val.filter((f) => target.includes(f)))
        const newValue = {
            ...form,
            filter: newFilter,
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    const resetFilterCheckBox = () => {
        const newValue = {
            ...form,
            filter: [],
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    const onChangeDetail = (key: keyof RealEstateSearchFormValue, val?: string | number) => {
        const newValue = {
            ...form,
            [key]: val,
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    const onChangeDetailCheckBox = (key: keyof RealEstateSearchFormValue, val: string[] | number[]) => {
        const newValue = {
            ...form,
            [key]: val,
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    const onChangeDetailAndOrToggle = (key: keyof RealEstateSearchFormValue, val: 'AND' | 'OR') => {
        const newValue = {
            ...form,
            [key]: val,
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    const resetDetail = () => {
        const newValue = {
            name: '',
            useTypes: [],
            prices: [],
            buildingAges: [],
            saleTimes: [],
            grossRate: [],
            salesDestination: [],
            assignedToType: 'OR' as const,
            assignedToUuidList: [],
            labelType: 'OR' as const,
            labelUuidList: [],
            progress: [],
            filter: form.filter.filter((v) => v !== 'noAssigned' && v !== 'noLabels'),
        }
        setForm(newValue)
        props.onChange(newValue)
    }

    if (!teamMemberListCount || !useTypeListCount || !labelListCount) return null

    return (
        <CRealestateSearchView
            {...props}
            isRecommendFeature={isRecommendFeature}
            isConnectionFeature={isConnectionFeature}
            isMarketFeature={isMarketFeature}
            isOpenDetail={isOpenDetail}
            onClickDetail={onClickDetail}
            isOpenFilter={isOpenFilter}
            onClickFilter={onClickFilter}
            onChangeText={onChangeText}
            hasMarketPublishRequest={!!dashboardStatus?.newestPublishRequest}
            form={form}
            name={name}
            onChangeDetail={onChangeDetail}
            resetFilterCheckBox={resetFilterCheckBox}
            onChangeFilterCheckBox={onChangeFilterCheckBox}
            onChangeDetailCheckBox={onChangeDetailCheckBox}
            onChangeDetailAndOrToggle={onChangeDetailAndOrToggle}
            resetDetail={resetDetail}
            useTypes={useTypeListCount.list as RealEstateUseTypeEntities[]}
            labels={labelListCount.list as RealEstateLabelEntities[]}
            teamMembers={teamMemberListCount.list as TeamMemberEntities[]}
            closeAll={closeAll}
        />
    )
}
