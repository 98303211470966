import clsx from 'clsx'
import { t } from 'i18next'
import { useState } from 'react'

import { ClientRealEstateActivityDto, RealEstateEntities } from '~/app/api'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'
import { useAppSelector } from '~/util/store/hooks'
import {
    selectLoginUserHaveConnectionContract,
    selectLoginUserHaveMarketContract,
    selectLoginUserHaveRecommendContract,
} from '~/util/store/userSlice'

import CRealestateDetailConnection from './CRealestateDetailConnection'
import CRealestateDetailFilteredCustomer from './CRealestateDetailFilteredCustomer'
import CRealestateDetailMarket from './CRealestateDetailMarket'
import CRealestateDetailRecommend from './CRealestateDetailRecommend'

type Props = {
    realEstate: RealEstateEntities
    activity?: ClientRealEstateActivityDto
    refetch?: () => void
    setOpenEditModal: (value: boolean) => void
}
const CRealestateDetailMatching = ({ realEstate, activity, setOpenEditModal }: Props) => {
    // レコメンド契約
    const haveRecommendContract = useAppSelector(selectLoginUserHaveRecommendContract)
    // コネクション契約
    const haveConnectionContract = useAppSelector(selectLoginUserHaveConnectionContract)
    // マーケット契約
    const haveMarketContract = useAppSelector(selectLoginUserHaveMarketContract)

    // タブ表示
    const [selectedTab, setSelectedTab] = useState('recommend')
    const tabs: Array<{ label: string; value: string; checked?: boolean }> = [
        {
            label: t('CRealestateDetailMatching.絞り込み'),
            value: 'filteredCustomer',
        },
        {
            label: t('CRealestateDetailMatching.おすすめ顧客'),
            value: 'recommend',
            checked: haveRecommendContract && !!activity?.newRecommend.length,
        },
        {
            label: t('CRealestateDetailMatching.新規人脈'),
            value: 'connection',
            checked: haveConnectionContract && !!activity?.newConnection.length,
        },
        {
            label: t('CRealestateDetailMatching.開示リクエスト'),
            value: 'market',
            checked: haveMarketContract && !!activity?.newPublishRequest.length,
        },
    ]

    return (
        <>
            <div className={clsx('max-w-md', 'm-auto')}>
                <CSegmentedControl items={tabs} onClick={(val) => setSelectedTab(val as string)} selectedTabValue={selectedTab} />
            </div>
            <div className={clsx('p-4')}>
                {selectedTab === 'filteredCustomer' && <CRealestateDetailFilteredCustomer realEstate={realEstate} />}
                {selectedTab === 'recommend' && (
                    <CRealestateDetailRecommend realEstate={realEstate} setOpenEditModal={setOpenEditModal} />
                )}
                {selectedTab === 'connection' && (
                    <CRealestateDetailConnection realEstate={realEstate} setOpenEditModal={setOpenEditModal} />
                )}
                {selectedTab === 'market' && (
                    <CRealestateDetailMarket realEstate={realEstate} setOpenEditModal={setOpenEditModal} />
                )}
            </div>
        </>
    )
}

export default CRealestateDetailMatching
