import clsx from 'clsx'
import { motion } from 'framer-motion'
import { TailSpin } from 'react-loader-spinner'

import { useAppSelector } from '~/util/store/hooks'
import { isLoading } from '~/util/store/UISlice'

type Props = {
    className?: string[]
}

const CLoader = ({ className = [] }: Props) => {
    const isShow = useAppSelector(isLoading)
    if (!isShow) return null

    // loading時多重クリック抑制
    const onClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
    }
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.2,
                ease: 'easeInOut',
            }}
            id="loader"
            onClick={onClickHandler}
            className={clsx('fixed', 'z-[9999]', 'inset-0', 'flex', 'items-center', 'justify-center', 'bg-white/50')}>
            <TailSpin
                height="80"
                width="80"
                color="#45a8a1"
                ariaLabel="tail-spin-loading"
                wrapperClass={clsx('w-full', 'h-20', 'flex', 'items-center', 'justify-center', className)}
            />
        </motion.div>
    )
}

export default CLoader
