import clsx from 'clsx'
import { t } from 'i18next'
import { groupBy, maxBy, sortBy, sum, uniqBy } from 'lodash'
import { CSSProperties, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { createSearchParams, useLocation } from 'react-router-dom'

import {
    CustomerEntities,
    IntermediaryToEntities,
    RealEstateEntities,
    useClientCustomerIntermediaryPostFinishMutation,
    useClientCustomerUuidQuery,
    useClientIntermediaryRealEstateUuidQuery,
    useClientIntermediaryToUpdateProgressMutation,
    useClientRealEstateGetNoReadChatActivityQuery,
} from '~/app/api'
import CMessage from '~/components/common/cMessage/CMessage'
import CTable from '~/components/common/cTable/CTable'
import { CTagList } from '~/components/common/cTagList/CTagList'
import ReactRating from '~/components/common/reactRating/ReactRating'
import { openIntermediaries, taxType, voteInterests, voteOtherRoutes } from '~/types/enum/enum'
import { formatDateTime } from '~/util/common/common'
import { interestFormat, otherRouteFormat, priceFormat } from '~/util/filter/format'
import { useAppSelector } from '~/util/store/hooks'
import { selectIsPlanStandardOrOver, selectLoginUser } from '~/util/store/userSlice'

import { CButton } from '../../../common/cButton/CButton'
import { CCheckBox } from '../../../common/cCheckBox/CCheckBox'
import { CCheckBoxList } from '../../../common/cCheckBoxList/CCheckBoxList'
import { CLabeledItem } from '../../../common/cLabeledItem/CLabeledItem'
import { CSortButton } from '../../../common/cSortButton/CSortButton'
import CIntermediaryCustomerDetail from '../../intermediary/CIntermediaryCustomerDetail'
import CIntermediaryFinish from '../../intermediary/CIntermediaryFinish'
import { CRealEstateDetailFinishMarket } from './CRealEstateDetailFinishMarket'

type Props = {
    realEstate: RealEstateEntities
    realestateDetailIntermediaryChanged: () => void
}

const CRealestateDetailIntermediary = ({ realEstate, realestateDetailIntermediaryChanged }: Props) => {
    const user = useAppSelector(selectLoginUser)
    const { data: intermediaries, isLoading, refetch } = useClientIntermediaryRealEstateUuidQuery({ uuid: realEstate.uuid })
    const isPlanStandardOrOver = useAppSelector(selectIsPlanStandardOrOver)

    const query = new URLSearchParams(useLocation().search)
    const queryParam = {
        intermediaryTo: query.get('intermediaryTo'),
    }

    const [selectedIntermediaries, setSelectedIntermediaries] = useState<IntermediaryToEntities[]>([])
    const [finishType, setFinishType] = useState<IntermediaryToEntities | 'all' | undefined>(undefined)
    const [order, setOrder] = useState<{ order: 'access' | 'totalCount' | 'createdAt' | 'progress'; sort: 'ASC' | 'DESC' }>({
        order: queryParam.intermediaryTo ? 'progress' : 'access',
        sort: 'DESC',
    })
    const onChangeSort = (order: 'access' | 'totalCount' | 'createdAt' | 'progress', sort: 'ASC' | 'DESC') => {
        setOrder({
            order,
            sort,
        })
    }

    const [showCustomer, setShowCustomer] = useState<CustomerEntities>()

    const [filter, setFilter] = useState<FilterValue>({
        open: [],
        status: [],
        condition: [],
    })

    type FilterValue = {
        open: number[]
        status: number[]
        condition: number[]
    }
    const onChangeFilter = (key: keyof FilterValue, val: number[]) => {
        const newValue = {
            ...filter,
            [key]: val,
        }
        setFilter(newValue)
    }

    const resetFilterCheckBox = () => {
        const newValue = {
            open: [],
            status: [],
            condition: [],
        }
        setFilter(newValue)
    }

    const sortedIntermediaryToList = useMemo(() => {
        // filter
        const intermediaryFilter = (list: IntermediaryToEntities[]) =>
            list.filter((t) => {
                let target: boolean = true
                if (filter.open.length > 0) {
                    const _target: boolean[] = []
                    if (filter.open.includes(1)) _target.push(!t.openedAt)
                    if (filter.open.includes(0)) _target.push(!!t.openedAt)
                    target = target && _target.includes(true)
                }

                if (filter.status.length > 0) {
                    const _target: boolean[] = []
                    const isAcquiredOtherRoute = t.intermediaryToVote?.find((v) => v.isAcquiredOtherRoute)?.isAcquiredOtherRoute
                    if (filter.status.includes(0)) _target.push(isAcquiredOtherRoute === false)
                    if (filter.status.includes(1)) _target.push(isAcquiredOtherRoute === true)
                    if (filter.status.includes(10)) _target.push(isAcquiredOtherRoute === undefined)
                    target = target && _target.includes(true)
                }

                if (filter.condition.length > 0) {
                    const _target: boolean[] = []
                    const isInterested = t.intermediaryToVote?.find((v) => v.isInterested)?.isInterested
                    if (filter.condition.includes(0)) _target.push(isInterested === false)
                    if (filter.condition.includes(1)) _target.push(isInterested === true)
                    if (filter.condition.includes(10)) _target.push(isInterested === undefined)
                    target = target && _target.includes(true)
                }

                return target
            })
        const groupedList = groupBy(
            intermediaries?.flatMap((i) => i.intermediaryToList ?? []) ?? [],
            (i) => i.destinationTo?.uuid,
        )
        let resultList: { newest: IntermediaryToEntities; oldList: IntermediaryToEntities[] }[] = []
        for (const uuid of Object.keys(groupedList)) {
            const intermediaries = intermediaryFilter(groupedList[uuid])
            const newest = maxBy(intermediaries, (i) => i.createdAt)
            if (newest) {
                const oldList = sortBy(
                    intermediaries.filter((i) => i.uuid !== newest.uuid),
                    (i) => i.createdAt,
                ).reverse()
                resultList.push({
                    newest,
                    oldList,
                })
            }
        }

        // order
        resultList = sortBy(resultList, (i) => {
            if (order.order === 'access') return i.newest.lastAccessAt
            else if (order.order === 'totalCount') return sum(i.newest.intermediaryToDocuments?.map((d) => d.previewCount))
            else if (order.order === 'createdAt') return i.newest.createdAt
            else if (order.order === 'progress') return i.newest.progress
            else return i.newest.createdAt
        })

        if (order.sort === 'DESC') resultList = resultList.reverse()

        return resultList
    }, [intermediaries, order, filter])

    const [isOpenFilter, setIsOpenFilter] = useState(false)
    const onClickFilter = () => {
        setIsOpenFilter(!isOpenFilter)
    }

    const openCustomer = (customer?: CustomerEntities) => {
        if (customer) setShowCustomer(customer)
    }

    const { data: _selectedCustomer, isFetching: isCustomerFetching } = useClientCustomerUuidQuery(
        {
            uuid: showCustomer?.uuid ?? '',
        },
        { skip: !showCustomer },
    )
    const selectedCustomer = useMemo(() => {
        console.log(showCustomer && !isCustomerFetching ? _selectedCustomer : undefined)
        return showCustomer && !isCustomerFetching ? _selectedCustomer : undefined
    }, [_selectedCustomer, showCustomer])

    const getTagItem = (intermediaryTo: IntermediaryToEntities) => {
        const items: {
            children: ReactNode
            className?: string | undefined
            style?: CSSProperties | undefined
        }[] = []

        if (intermediaryTo.destinationTo?.deletedAt) {
            items.push({
                className: clsx('text-xs', 'bg-gray-700', 'text-gray-300'),
                children: <>{t('CRealestateDetailIntermediary.削除された顧客')}</>,
            })
        }

        if (intermediaryTo.intermediaryFinishedAt) {
            items.push({
                className: clsx('text-xs', 'bg-gray-700', 'text-gray-300'),
                children: <>{t('CRealestateDetailIntermediary.紹介終了')}</>,
            })
        }

        if (intermediaryTo.reviewFinishedAt) {
            items.push({
                className: clsx('text-xs', 'bg-gray-700', 'text-gray-300'),
                children: <>{t('CRealestateDetailIntermediary.検討終了')}</>,
            })
        }

        return items
    }

    const confirmedModal = (message: string, isSendNotification: boolean) => {
        if (!finishType) return
        if (finishType === 'all') finishAllIntermediary(isSendNotification, message)
        else onClickFinish(finishType, isSendNotification, message)
    }
    const [finishIntermediaryQuery] = useClientCustomerIntermediaryPostFinishMutation()
    const onClickFinish = async (intermediaryTo: IntermediaryToEntities, isSendNotification: boolean, message?: string) => {
        await finishIntermediaryQuery({
            clientIntermediaryFinishDto: { intermediaryUuidList: [intermediaryTo.uuid], isSendNotification, message },
        }).unwrap()
        setFinishType(undefined)
        setSelectedIntermediaries([])
        if (realEstate.markets?.length) setShowRemovePublishMarket(realEstate)
        refetch()
        realestateDetailIntermediaryChanged()
    }
    const finishAllIntermediary = async (isSendNotification: boolean, message?: string) => {
        await finishIntermediaryQuery({
            clientIntermediaryFinishDto: {
                intermediaryUuidList: selectedIntermediaries
                    .filter((i) => !i.intermediaryFinishedAt && !i.destinationTo?.deletedAt)
                    .map((i) => i.uuid),
                isSendNotification,
                message,
            },
        }).unwrap()
        setFinishType(undefined)
        setSelectedIntermediaries([])
        if (realEstate.markets?.length) setShowRemovePublishMarket(realEstate)
        refetch()
        realestateDetailIntermediaryChanged()
    }
    const checkAllIntermediary = () => {
        if (allSelected) setSelectedIntermediaries([])
        else {
            setSelectedIntermediaries(
                uniqBy(
                    [
                        ...selectedIntermediaries,
                        ...sortedIntermediaryToList
                            .flatMap((i) => i.newest)
                            .filter((i) => !i.intermediaryFinishedAt && !i.reviewFinishedAt && !i.destinationTo?.deletedAt),
                    ],
                    (i) => i.uuid,
                ),
            )
        }
    }
    const checkIntermediary = (intermediaryTo: IntermediaryToEntities) => {
        const target = selectedIntermediaries.find((i) => i.uuid === intermediaryTo.uuid)
        if (!target) setSelectedIntermediaries([...selectedIntermediaries, intermediaryTo])
        else setSelectedIntermediaries(selectedIntermediaries.filter((i) => i.uuid !== intermediaryTo.uuid))
    }
    const allSelected = useMemo(() => {
        const enableList = sortedIntermediaryToList
            .flatMap((i) => i.newest)
            .filter((i) => !i.intermediaryFinishedAt && !i.reviewFinishedAt && !i.destinationTo?.deletedAt)
        return enableList.every((e) => selectedIntermediaries.find((i) => i.uuid === e.uuid))
    }, [selectedIntermediaries, sortedIntermediaryToList])

    const openChat = (intermediaryTo: IntermediaryToEntities) => {
        const url = new URL(window.location.origin + '/chat')
        url.search = createSearchParams({
            intermediaryTo: intermediaryTo.uuid,
            isSentTeam: 'true',
        }).toString()

        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        window.open(
            url,
            '_blank',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )
    }

    const openDocument = (intermediaryTo: IntermediaryToEntities) => {
        const url = new URL(window.location.origin + '/intermediary/' + intermediaryTo.uuid + '/document')

        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        window.open(
            url,
            '_blank',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )
    }

    const { data: activityDto } = useClientRealEstateGetNoReadChatActivityQuery(
        {
            realEstateUuids: [realEstate.uuid],
        },
        { skip: !realEstate },
    )
    const hasNewChat = (intermediaryTo: IntermediaryToEntities) => {
        if (!activityDto) return false
        console.log(intermediaryTo, activityDto)
        return activityDto.sendNewChat.find((ta) => {
            const from_name = (ta.params as { from_name: string })['from_name']
            return (intermediaryTo.destinationTo?.name ?? '').includes(from_name)
        })
    }

    const [showRemovePublishMarket, setShowRemovePublishMarket] = useState<RealEstateEntities | undefined>(undefined)

    const closeAll = () => {
        setIsOpenFilter((v) => (v ? false : v))
    }
    const filterRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!filterRef) return
        const handleClickOutside = (e: MouseEvent) => {
            if (!(filterRef.current as Node)?.contains(e.target as Node)) closeAll()
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [filterRef])

    const [expandIntermediaries, setExpandIntermediaries] = useState<string[]>([])
    useEffect(() => {
        setExpandIntermediaries([])
    }, [intermediaries])

    const [updateProgressQuery] = useClientIntermediaryToUpdateProgressMutation()
    const updateProgress = async (uuid: string, progress: number) => {
        await updateProgressQuery({
            clientIntermediaryToProgressUpdateDto: {
                uuid,
                progress,
            },
        })
        refetch()
        realestateDetailIntermediaryChanged()
    }

    if (isLoading || !intermediaries) return null

    return (
        <div>
            {intermediaries.length > 0 ? (
                <>
                    <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-2', 'justify-between', 'py-2')}>
                        <div className={clsx('flex', 'gap-2', 'flex-wrap')}>
                            <CSortButton
                                className={clsx('border-gray-300')}
                                items={[
                                    {
                                        label: t('CRealestateDetailIntermediary.最終アクセス日'),
                                        value: 'access',
                                    },
                                    {
                                        label: t('CRealestateDetailIntermediary.資料の累計閲覧回数'),
                                        value: 'totalCount',
                                    },
                                    {
                                        label: t('CRealestateDetailIntermediary.送信日時'),
                                        value: 'createdAt',
                                    },
                                    {
                                        label: t('CRealestateDetailIntermediary.進捗'),
                                        value: 'progress',
                                    },
                                ]}
                                nowValue={order.order}
                                sort={order.sort}
                                onChange={(val) => onChangeSort(val as 'access' | 'totalCount', order.sort)}
                                onClickSort={() => onChangeSort(order.order, order.sort == 'ASC' ? 'DESC' : 'ASC')}
                            />
                            <div ref={filterRef} className={clsx('flex')}>
                                <div
                                    className={clsx(
                                        'bg-inherit',
                                        'items-center',
                                        'px-3',
                                        'md:px-6',
                                        'py-1',
                                        'text-center',
                                        'border',
                                        'border-kimar-primary',
                                        'text-kimar-primary',
                                        'cursor-pointer',
                                        'flex',
                                        'rounded',
                                    )}
                                    onClick={() => onClickFilter()}>
                                    <i className={clsx('material-icons-outlined', 'text-kimar-primary')}>filter_alt</i>
                                    <div className={clsx('whitespace-nowrap')}>{t('CRealestateDetailIntermediary.絞り込み')}</div>
                                </div>
                                <div
                                    className={clsx(
                                        'absolute',
                                        '-ml-56',
                                        'my-12',
                                        'bg-white',
                                        'p-2',
                                        'shadow',
                                        'rounded',
                                        !isOpenFilter && 'hidden',
                                        'min-w-[200px]',
                                        'z-50',
                                    )}>
                                    <div className={clsx('bg-gray-50', 'flex', 'p-8')}>
                                        <div className={clsx('mx-2', 'border-r')}>
                                            <CLabeledItem label={t('CRealestateDetailIntermediary.開封状態')}>
                                                <CCheckBoxList
                                                    items={openIntermediaries}
                                                    checked={filter.open}
                                                    onChange={(val) => onChangeFilter('open', val as number[])}
                                                    horizontal
                                                />
                                            </CLabeledItem>
                                        </div>
                                        {isPlanStandardOrOver && (
                                            <div className={clsx('mx-2', 'border-r')}>
                                                <CLabeledItem label={t('CRealestateDetailIntermediary.取得状況')}>
                                                    <CCheckBoxList
                                                        items={voteOtherRoutes}
                                                        checked={filter.status}
                                                        onChange={(val) => onChangeFilter('status', val as number[])}
                                                        horizontal
                                                    />
                                                </CLabeledItem>
                                            </div>
                                        )}
                                        <div className={clsx('mx-2')}>
                                            <CLabeledItem label={t('CRealestateDetailIntermediary.興味度合い')}>
                                                <CCheckBoxList
                                                    items={voteInterests}
                                                    checked={filter.condition}
                                                    onChange={(val) => onChangeFilter('condition', val as number[])}
                                                    horizontal
                                                />
                                            </CLabeledItem>
                                        </div>
                                    </div>
                                    <div className={clsx('grid', 'grid-cols-1', 'sm:grid-cols-2', 'gap-2', 'my-2')}>
                                        <CButton
                                            className={clsx('c-button-secondary', 'w-full', 'mb-1')}
                                            onClick={() => resetFilterCheckBox()}>
                                            {t('CRealestateDetailIntermediary.絞り込みをリセット')}
                                        </CButton>
                                        <CButton className={clsx('c-button-primary', 'w-full')} onClick={() => onClickFilter()}>
                                            {t('CRealestateDetailIntermediary.絞り込みを閉じる')}
                                        </CButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {user?.roles.intermediary === 2 && (
                            <div className={clsx('flex', 'justify-end', 'items-center')}>
                                <CButton
                                    className={clsx('c-button-danger', 'text-sm')}
                                    disabled={selectedIntermediaries.length === 0}
                                    onClick={() => setFinishType('all')}>
                                    {t('CRealestateDetailIntermediary.選択した紹介を紹介終了にする')}
                                </CButton>
                            </div>
                        )}
                    </div>
                    <div className={clsx('flex', 'bg-kimar-primary', 'px-2')}>
                        {user?.roles.intermediary === 2 && (
                            <div className={clsx('px-2')}>
                                <CCheckBox
                                    className={clsx('rounded', 'border', 'border-white')}
                                    onChange={checkAllIntermediary}
                                    label=""
                                    checked={allSelected}
                                />
                            </div>
                        )}
                        <div className={clsx('text-white')}>{t('CRealestateDetailIntermediary.紹介先顧客')}</div>
                    </div>
                    <ul className={clsx('p-2', 'divide-y')}>
                        {sortedIntermediaryToList.map((item) => (
                            <li key={item.newest.uuid} className={clsx('py-2')}>
                                <div>
                                    <div className={clsx('flex', 'justify-between', 'items-center')}>
                                        <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-1')}>
                                            <div
                                                className={clsx('text-lg', 'text-kimar-primary', 'underline', 'cursor-pointer')}
                                                onClick={() => openCustomer(item.newest.destinationTo)}>
                                                {item.newest.destinationTo?.name} ({item.newest.destinationTo?.companyName})
                                            </div>
                                            <div className={clsx('flex', 'flex-col', 'justify-center', 'gap-1')}>
                                                <div className={clsx('flex', 'items-center')}>
                                                    <CTagList items={getTagItem(item.newest)} horizontal />
                                                </div>
                                                <div className={clsx('flex', 'items-center')}>
                                                    <CTagList
                                                        horizontal
                                                        items={
                                                            sortBy(item.newest.destinationTo?.labels, (s) => s.sort).map(
                                                                (label) => ({
                                                                    className: clsx('text-xs'),
                                                                    style: {
                                                                        backgroundColor: label.bgColor,
                                                                        color: label.letterColor,
                                                                    },
                                                                    children: <>{label.name}</>,
                                                                }),
                                                            ) ?? []
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={clsx('overflow-x-auto')}>
                                        <CTable
                                            thead={
                                                <>
                                                    <tr className={clsx('text-sm')}>
                                                        <th className={clsx('text-center')}>
                                                            {t('CRealestateDetailIntermediary.選択')}
                                                        </th>
                                                        <th className={clsx('text-center', 'w-[88px]')}>
                                                            {t('CRealestateDetailIntermediary.送信日時')}
                                                        </th>
                                                        <th className={clsx('text-center', 'md:whitespace-pre-wrap', 'w-[88px]')}>
                                                            {t('CRealestateDetailIntermediary.最終アクセス日時')}
                                                        </th>
                                                        <th className={clsx('text-center', 'w-[100px]')}>
                                                            {t('CRealestateDetailIntermediary.提示金額')}
                                                        </th>
                                                        <th className={clsx('text-center', 'md:whitespace-pre-wrap', 'w-[80px]')}>
                                                            {t('CRealestateDetailIntermediary.資料の累計閲覧回数')}
                                                        </th>
                                                        <th className={clsx('text-center', 'w-[80px]')}>
                                                            {t('CRealestateDetailIntermediary.取得状況')}
                                                        </th>
                                                        <th className={clsx('text-center', 'w-[80px]')}>
                                                            {t('CRealestateDetailIntermediary.興味度合い')}
                                                        </th>
                                                        <th className={clsx('text-center')}>
                                                            {t('CRealestateDetailIntermediary.紹介元')}
                                                        </th>
                                                        <th className={clsx('text-center')}>
                                                            {t('CRealestateDetailIntermediary.操作')}
                                                        </th>
                                                    </tr>
                                                </>
                                            }
                                            tbody={
                                                <>
                                                    {/* 顧客リストの単体 */}
                                                    {[item.newest, ...item.oldList].map((i) =>
                                                        item.newest.uuid === i.uuid ||
                                                        expandIntermediaries.includes(item.newest.uuid) ? (
                                                            <tr
                                                                key={i.uuid}
                                                                className={clsx(
                                                                    'text-xs',
                                                                    (!!i.intermediaryFinishedAt || !!i.reviewFinishedAt) &&
                                                                        'bg-gray-200',
                                                                )}>
                                                                <td className={clsx('px-2', 'text-center')}>
                                                                    {i.uuid === item.newest.uuid && (
                                                                        <div className={clsx('flex', 'justify-center')}>
                                                                            <CCheckBox
                                                                                disabled={
                                                                                    !!i.intermediaryFinishedAt ||
                                                                                    !!i.reviewFinishedAt
                                                                                }
                                                                                className={clsx(
                                                                                    (!!i.intermediaryFinishedAt ||
                                                                                        !!i.reviewFinishedAt) && ['opacity-0'],
                                                                                )}
                                                                                onChange={() => checkIntermediary(i)}
                                                                                label=""
                                                                                checked={
                                                                                    !!selectedIntermediaries.find(
                                                                                        (_i) => _i.uuid === i.uuid,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </td>
                                                                <td className={clsx('px-2', 'text-center')}>
                                                                    {formatDateTime(i.createdAt, 'dateTime')}
                                                                </td>
                                                                <td className={clsx('px-2', 'text-center', 'w-[80px]')}>
                                                                    {i.lastAccessAt ? (
                                                                        <div>{formatDateTime(i.lastAccessAt, 'dateTime')}</div>
                                                                    ) : (
                                                                        <div>{t('CRealestateDetailIntermediary.未開封')}</div>
                                                                    )}
                                                                </td>
                                                                <td className={clsx('px-2', 'text-center')}>
                                                                    {priceFormat(i.intermediary.price)}
                                                                    {i.intermediary.taxType
                                                                        ? ' (' +
                                                                          taxType.find((t) => t.value === i.intermediary.taxType)
                                                                              ?.label +
                                                                          ')'
                                                                        : ''}
                                                                </td>
                                                                <td className={clsx('px-2', 'text-center')}>
                                                                    {sum(
                                                                        i.intermediaryToDocuments?.map(
                                                                            (d) => d.previewCount + d.downloadCount,
                                                                        ),
                                                                    ) ?? 0}
                                                                    {t('CRealestateDetailIntermediary.回')}
                                                                </td>
                                                                <td className={clsx('px-2', 'text-center')}>
                                                                    {!i.intermediaryToVote ||
                                                                    i.intermediaryToVote.length === 0 ? (
                                                                        <div>{t('CRealestateDetailIntermediary.未回答')}</div>
                                                                    ) : (
                                                                        // 初取得の場合は文字が赤色
                                                                        <div>
                                                                            {otherRouteFormat(
                                                                                i.intermediaryToVote?.find(
                                                                                    (v) => v.isAcquiredOtherRoute,
                                                                                )
                                                                                    ? 1
                                                                                    : 0,
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </td>
                                                                <td className={clsx('px-2', 'text-center')}>
                                                                    {!i.intermediaryToVote ||
                                                                    i.intermediaryToVote.length === 0 ? (
                                                                        <div>{t('CRealestateDetailIntermediary.未回答')}</div>
                                                                    ) : (
                                                                        // 興味ありの場合は文字が赤色
                                                                        <div>
                                                                            {interestFormat(
                                                                                i.intermediaryToVote?.find((v) => v.isInterested)
                                                                                    ? 1
                                                                                    : 0,
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </td>
                                                                <td className={clsx('px-2', 'text-center')}>
                                                                    {i.intermediary.sentBy?.name}(
                                                                    {i.intermediary.sentBy?.team.name})
                                                                </td>
                                                                <td className={clsx('px-2', 'text-center')}>
                                                                    <div
                                                                        className={clsx(
                                                                            'text-xs',
                                                                            'flex',
                                                                            'justify-center',
                                                                            'items-center',
                                                                            'flex-col',
                                                                            'gap-1',
                                                                            'whitespace-nowrap',
                                                                        )}>
                                                                        {i.uuid === item.newest.uuid && (
                                                                            <div className={clsx('border-b', 'pb-1', 'mb-1')}>
                                                                                <div
                                                                                    className={clsx(
                                                                                        'flex',
                                                                                        'justify-between',
                                                                                        'items-center',
                                                                                    )}>
                                                                                    {t('CRealestateDetailIntermediary.紹介進捗')}
                                                                                    <CButton
                                                                                        className={clsx(
                                                                                            'c-button-secondary',
                                                                                            'text-xs',
                                                                                            'py-0',
                                                                                            'px-1',
                                                                                        )}
                                                                                        onClick={() => {
                                                                                            updateProgress(i.uuid, 0)
                                                                                        }}>
                                                                                        {t(
                                                                                            'CRealestateDetailIntermediary.リセット',
                                                                                        )}
                                                                                    </CButton>
                                                                                </div>
                                                                                <div className={clsx('flex')}>
                                                                                    <ReactRating
                                                                                        initialRating={i.progress ?? 0}
                                                                                        emptySymbol={
                                                                                            <i
                                                                                                className={clsx(
                                                                                                    'material-icons-outlined',
                                                                                                )}>
                                                                                                play_arrow
                                                                                            </i>
                                                                                        }
                                                                                        fullSymbol={
                                                                                            <i className={clsx('material-icons')}>
                                                                                                play_arrow
                                                                                            </i>
                                                                                        }
                                                                                        onChange={(v) => {
                                                                                            updateProgress(i.uuid, v)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        <div className={clsx('w-full')}>
                                                                            <div className={clsx('relative')}>
                                                                                <CButton
                                                                                    className={clsx(
                                                                                        'c-button-secondary',
                                                                                        'w-full',
                                                                                        'relative',
                                                                                        'peer',
                                                                                    )}
                                                                                    disabled={
                                                                                        !!i.intermediaryFinishedAt ||
                                                                                        !!i.reviewFinishedAt
                                                                                    }
                                                                                    onClick={() => {
                                                                                        openChat(i)
                                                                                    }}>
                                                                                    <i
                                                                                        className={clsx(
                                                                                            'material-icons',
                                                                                            'text-sm',
                                                                                        )}>
                                                                                        chat
                                                                                    </i>
                                                                                    <div>
                                                                                        {t(
                                                                                            'CRealestateDetailIntermediary.チャット',
                                                                                        )}
                                                                                    </div>
                                                                                    {hasNewChat(i) && (
                                                                                        <div
                                                                                            className={clsx(
                                                                                                'absolute',
                                                                                                'top-1',
                                                                                                'right-1',
                                                                                                'w-2',
                                                                                                'h-2',
                                                                                                'rounded-full',
                                                                                                'bg-red-700',
                                                                                            )}
                                                                                        />
                                                                                    )}
                                                                                </CButton>
                                                                                <div
                                                                                    className={clsx(
                                                                                        'opacity-0',
                                                                                        'peer-hover:opacity-100',
                                                                                        'pointer-events-none',
                                                                                        'absolute',
                                                                                        'text-gray-700',
                                                                                        'z-10',
                                                                                        'p-2',
                                                                                        'rounded',
                                                                                        'bg-white',
                                                                                        'bg-opacity-90',
                                                                                        'transition',
                                                                                        'text-sm',
                                                                                        'w-[120px]',
                                                                                        'whitespace-pre-wrap',
                                                                                        ['top-6', 'right-0'],
                                                                                    )}>
                                                                                    {t(
                                                                                        'CRealestateDetailIntermediary.チャット・紹介追加メッセージが確認できます',
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <CButton
                                                                            className={clsx('c-button-secondary', 'w-full')}
                                                                            onClick={() => openDocument(i)}>
                                                                            <i className={clsx('material-icons', 'text-sm')}>
                                                                                description
                                                                            </i>
                                                                            <div>
                                                                                {t('CRealestateDetailIntermediary.送付資料')}
                                                                            </div>
                                                                        </CButton>

                                                                        {i.uuid === item.newest.uuid &&
                                                                            user?.roles.intermediary === 2 && (
                                                                                <CButton
                                                                                    className={clsx('c-button-danger', 'w-full')}
                                                                                    disabled={
                                                                                        !!i.intermediaryFinishedAt ||
                                                                                        !!i.reviewFinishedAt
                                                                                    }
                                                                                    onClick={() => setFinishType(i)}>
                                                                                    {t('CRealestateDetailIntermediary.紹介終了')}
                                                                                </CButton>
                                                                            )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            <></>
                                                        ),
                                                    )}
                                                    {item.oldList.length > 0 && (
                                                        <tr>
                                                            <td colSpan={9} className={clsx('bg-kimar-primary')}>
                                                                <div
                                                                    className={clsx(
                                                                        'flex',
                                                                        'items-center',
                                                                        'justify-center',
                                                                        'cursor-pointer',
                                                                    )}
                                                                    onClick={() => {
                                                                        setExpandIntermediaries((old) => {
                                                                            const returnList = [...old]
                                                                            if (returnList.includes(item.newest.uuid)) {
                                                                                return returnList.filter(
                                                                                    (_i) => _i !== item.newest.uuid,
                                                                                )
                                                                            } else {
                                                                                returnList.push(item.newest.uuid)
                                                                                return returnList
                                                                            }
                                                                        })
                                                                    }}>
                                                                    {expandIntermediaries.includes(item.newest.uuid) ? (
                                                                        <i
                                                                            className={clsx(
                                                                                'material-icons',
                                                                                'text-white',
                                                                                'text-sm',
                                                                            )}>
                                                                            unfold_less
                                                                        </i>
                                                                    ) : (
                                                                        <i
                                                                            className={clsx(
                                                                                'material-icons',
                                                                                'text-white',
                                                                                'text-sm',
                                                                            )}>
                                                                            unfold_more
                                                                        </i>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            }
                                        />
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <CMessage info>{t('CRealestateDetailIntermediary.紹介先がありません')}</CMessage>
            )}
            <CIntermediaryFinish visible={!!finishType} confirmed={confirmedModal} cancel={() => setFinishType(undefined)} />
            <CIntermediaryCustomerDetail customer={selectedCustomer} cancel={() => setShowCustomer(undefined)} />
            <CRealEstateDetailFinishMarket
                visible={!!showRemovePublishMarket}
                realEstate={showRemovePublishMarket}
                onSubmit={() => {
                    setShowRemovePublishMarket(undefined)
                    refetch()
                    realestateDetailIntermediaryChanged()
                }}
                onClose={() => setShowRemovePublishMarket(undefined)}
            />
        </div>
    )
}

export default CRealestateDetailIntermediary
