import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { CButton } from '~/components/common/cButton/CButton'
import { CHeader } from '~/components/common/cHeader/CHeader'

import { CLabeledItem } from '../../common/cLabeledItem/CLabeledItem'
import { CFooterModal } from '../../common/cModal/CModal'
import { CTextInput } from '../../common/cTextInput/CTextInput'

type Props = {
    visible: boolean
    confirmed: (message: string, isSendNotification: boolean) => void
    cancel: () => void
}

const CIntermediaryFinish = ({ visible, confirmed, cancel }: Props) => {
    const title = t('CIntermediaryFinish.紹介終了')?.toString()
    const [modalVisible, setModalVisible] = useState(visible)
    const [finishMessage, setFinishMessage] = useState('')
    useEffect(() => {
        setModalVisible(visible)
    }, [visible])

    const confirmHandler = (isSendNotification: boolean) => {
        confirmed(finishMessage, isSendNotification)
    }
    const cancelHandler = () => {
        cancel()
    }

    const footer = (
        <>
            <CButton className={clsx('c-button-secondary')} onClick={() => cancelHandler()}>
                {t('Button.キャンセル')}
            </CButton>
            <CButton className={clsx('c-button-primary')} onClick={() => confirmHandler(false)}>
                {t('CIntermediaryFinish.通知を送らず紹介終了')}
            </CButton>
            <CButton className={clsx('c-button-primary')} onClick={() => confirmHandler(true)}>
                {t('CIntermediaryFinish.通知を送って紹介終了')}
            </CButton>
        </>
    )

    return (
        <CFooterModal footer={footer} visible={modalVisible} content={{ width: '60%' }} onRequestClose={() => cancelHandler()}>
            <div className={clsx('p-2')}>
                <CHeader label={title} />
            </div>
            <div>
                <div className={clsx('p-2')}>
                    <CLabeledItem label={t('CIntermediaryFinish.紹介先へのメッセージ')}>
                        <CTextInput
                            onChange={(value) => setFinishMessage(value)}
                            className={clsx('w-full', 'c-text-input-base')}
                            placeholder={t(
                                'CIntermediaryFinish.物件紹介の終了にあたり紹介先に対してメッセージを送ることが出来ます。記入がない場合は紹介終了のお知らせのみ紹介先に送信されます。',
                            )}
                            type="multiline"
                        />
                    </CLabeledItem>
                </div>
            </div>
        </CFooterModal>
    )
}

export default CIntermediaryFinish
