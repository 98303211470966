import {
    Active,
    ClientRect,
    closestCorners,
    CollisionDetection,
    DndContext,
    DragEndEvent,
    DraggableAttributes,
    DroppableContainer,
    KeyboardSensor as LibKeyboardSensor,
    MouseSensor as LibMouseSensor,
    pointerWithin,
    TouchSensor as LibTouchSensor,
    useDroppable,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { RectMap } from '@dnd-kit/core/dist/store'
import { Coordinates } from '@dnd-kit/core/dist/types'
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { clsx } from 'clsx'
import { isFunction } from 'lodash'
import { FC, KeyboardEvent, MouseEvent, ReactNode, TouchEvent, useEffect, useState } from 'react'

export type CDragRowTableProps = {
    data: CDragRowTableType
    onChange?: (data: CDragRowTableType) => void
    fixed?: boolean
}

export type CDragRowTableRowType = {
    id: string
    tr: ReactNode | ((attributes: DraggableAttributes, listeners: SyntheticListenerMap | undefined) => ReactNode)
}

export type CDragRowTableType = {
    id: string
    thead: ReactNode
    tbody: CDragRowTableRowType[]
}

function shouldHandleEvent(element: HTMLElement | null) {
    let cur = element
    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) return false
        cur = cur.parentElement
    }
    return true
}

export class MouseSensor extends LibMouseSensor {
    static activators = [
        {
            eventName: 'onMouseDown' as const,
            handler: ({ nativeEvent: event }: MouseEvent) => {
                return shouldHandleEvent(event.target as HTMLElement)
            },
        },
    ]
}

export class KeyboardSensor extends LibKeyboardSensor {
    static activators = [
        {
            eventName: 'onKeyDown' as const,
            handler: ({ nativeEvent: event }: KeyboardEvent<Element>) => {
                return shouldHandleEvent(event.target as HTMLElement)
            },
        },
    ]
}

export class TouchSensor extends LibTouchSensor {
    static activators = [
        {
            eventName: 'onTouchStart' as const,
            handler: ({ nativeEvent: event }: TouchEvent<Element>) => {
                return shouldHandleEvent(event.target as HTMLElement)
            },
        },
    ]
}

export const CDragRowTable: FC<CDragRowTableProps> = ({ data, onChange, fixed }) => {
    const Tr: FC<CDragRowTableRowType> = ({ id, tr: element }) => {
        const { attributes, listeners, setNodeRef, transform } = useSortable({
            id: id,
        })

        const style = {
            transform: CSS.Transform.toString(transform),
            cursor: 'unset',
        }

        const functionElement = isFunction(element)
        return (
            <tr
                id={id}
                className={clsx('md:border-b')}
                ref={setNodeRef}
                {...(functionElement ? {} : attributes)}
                {...(functionElement ? {} : listeners)}
                style={style}>
                {functionElement ? element(attributes, listeners) : element}
            </tr>
        )
    }

    const Table: FC<CDragRowTableType> = ({ id, tbody: cards, thead }) => {
        const { setNodeRef } = useDroppable({ id: id })
        return (
            <SortableContext id={id} items={cards} strategy={rectSortingStrategy}>
                <div>
                    <table className={clsx('w-full', 'text-sm', fixed && 'table-fixed')}>
                        <thead className={clsx()}>{thead}</thead>
                        <tbody ref={setNodeRef}>
                            {cards.map((card) => (
                                <Tr key={card.id} id={card.id} tr={card.tr} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </SortableContext>
        )
    }

    const handleDragOver = () => {
        return null
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event
        const activeId = String(active.id)
        const overId = over ? String(over.id) : null

        const activeIndex = columns.tbody.findIndex((i) => i.id === activeId)
        const overIndex = columns.tbody.findIndex((i) => i.id === overId)
        if (activeIndex !== overIndex) {
            let c: CDragRowTableType | undefined = undefined
            setColumns((prevState) => {
                const returnVal = {
                    ...prevState,
                }
                returnVal.tbody = arrayMove(returnVal.tbody, activeIndex, overIndex)
                c = returnVal
                return returnVal
            })
            onChange && !!c && onChange(c)
        }
    }

    const pointerAndClosestCorners: CollisionDetection = (collisionArgs: {
        active: Active
        collisionRect: ClientRect
        droppableRects: RectMap
        droppableContainers: DroppableContainer[]
        pointerCoordinates: Coordinates | null
    }) => {
        const pointerCollisions = pointerWithin(collisionArgs)
        if (pointerCollisions.length > 0) return pointerCollisions
        return closestCorners(collisionArgs)
    }

    const [columns, setColumns] = useState<CDragRowTableType>(data)
    useEffect(() => setColumns(data), [data])
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={pointerAndClosestCorners}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}>
            <div className={clsx('flex', 'flex-row', 'gap-2')}>
                <div key={columns.id} className={clsx('flex-1', 'border', 'border-gray-200')}>
                    <Table key={columns.id} id={columns.id} tbody={columns.tbody} thead={columns.thead} />
                </div>
            </div>
        </DndContext>
    )
}
