import clsx from 'clsx'
import { t } from 'i18next'

import { CustomerEntities } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'

import { CFooterModal } from '../../common/cModal/CModal'
import { CCustomerDetail } from '../customer/detail/CCustomerDetail'

type Props = {
    customer?: CustomerEntities
    cancel: () => void
}

const CIntermediaryCustomerDetail = ({ customer, cancel }: Props) => {
    const cancelHandler = () => {
        cancel()
    }

    const footer = (
        <>
            <CButton className={clsx('c-button-secondary')} onClick={() => cancelHandler()}>
                {t('Button.閉じる')}
            </CButton>
        </>
    )
    return (
        <CFooterModal
            footer={footer}
            visible={!!customer}
            content={{ width: '60%', height: '80%' }}
            onRequestClose={() => cancelHandler()}>
            {customer && (
                <>
                    <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>{customer.name}</div>
                    <CCustomerDetail customer={customer} refetch={() => {}} />
                </>
            )}
        </CFooterModal>
    )
}

export default CIntermediaryCustomerDetail
