import apiBase from '~/app/apiBase'

export type DlArgs = {
    fileUuid: string
    fileName: string
}
const downloadApi = apiBase.injectEndpoints({
    endpoints: (builder) => ({
        adminDownloadFile: builder.mutation<unknown, DlArgs>({
            queryFn: async ({ fileUuid, fileName }, api, extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: `/api/admin/media/${fileUuid}?filename=${encodeURIComponent(fileName)}`,
                    responseHandler: (response) => response.blob(),
                })
                const hiddenElement = document.createElement('a')
                const url = window.URL || window.webkitURL
                const blob = url.createObjectURL(result.data as Blob)
                hiddenElement.href = blob
                hiddenElement.target = '_blank'
                hiddenElement.style.display = 'none'
                hiddenElement.download = fileName
                hiddenElement.click()
                URL.revokeObjectURL(hiddenElement.href)
                hiddenElement.remove()
                return { data: null }
            },
        }),
    }),
    overrideExisting: false,
})

export const { useAdminDownloadFileMutation } = downloadApi
