import clsx from 'clsx'
import { VFC } from 'react'
import { v4 } from 'uuid'

const gridClass = {
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
    8: 'grid-cols-8',
    10: 'grid-cols-10',
    12: 'grid-cols-12',
}

export type CRadioButtonViewProps = {} & CRadioButtonProps

export const CRadioButtonView: VFC<CRadioButtonViewProps> = ({
    disabled,
    horizontal,
    items = [],
    dataLabel = 'label',
    dataValue = null,
    onChange,
    className,
    nowValue,
    grid,
}) => {
    const name = v4()
    const radioButtonList = items.map((data, index) => {
        const label = (dataLabel ? data[dataLabel] : data.label ? data.label : data.value) as string
        const value = (dataValue ? data[dataValue] : data.value) as string | number | readonly string[] | undefined
        const checked = nowValue == value
        return (
            <div
                key={index}
                className={clsx(
                    'my-0.5',
                    'py-0.5',
                    'p-1',
                    'rounded',
                    horizontal && ['mr-4'],
                    checked && !disabled && 'bg-kimar-primary-light',
                )}>
                <label className={clsx(className, 'flex', 'items-center')}>
                    <input
                        name={name}
                        className={clsx(
                            disabled
                                ? ['text-gray-300', 'border-gray-300']
                                : ['text-kimar-primary', 'focus:ring-kimar-primary', 'border-kimar-primary'],
                        )}
                        type="radio"
                        value={value}
                        checked={checked}
                        disabled={disabled}
                        onChange={() => onChange(value)}
                    />
                    {label && <span className={clsx('text-sm', 'ml-2')}>{label}</span>}
                </label>
            </div>
        )
    })

    return grid ? (
        <div className={clsx(['grid', gridClass[grid], 'gap-x-3'])}>{radioButtonList}</div>
    ) : (
        <div className={clsx(horizontal && ['flex', 'flex-wrap'])}>{radioButtonList}</div>
    )
}

export type CRadioButtonProps = {
    className?: string
    // 無効状態か否か
    disabled?: boolean
    // 横配置
    horizontal?: boolean
    // 整列配置
    grid?: keyof typeof gridClass
    // データソース
    items: Array<{ [key: string]: unknown }>
    // ラベルキー
    dataLabel?: string
    // 値キー
    dataValue?: string | null
    nowValue?: string | number | readonly string[] | readonly number[]
    onChange: (e: string | number | readonly string[] | readonly number[] | undefined) => void
}

export const CRadioButton: VFC<CRadioButtonProps> = (props) => {
    return <CRadioButtonView {...props} />
}
