import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { useNavigate, useParams } from 'react-router-dom'

import {
    InquiryEntities,
    useAdminInquiryDeleteUuidMutation,
    useAdminInquiryPutUuidMutation,
    useAdminInquiryUuidQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CModal } from '~/components/common/cModal/CModal'
import { CTag } from '~/components/common/cTag/CTag'
import { inquiryStatusItems } from '~/types/enum/enum'
import { ApplicationError, IApplicationError } from '~/types/error'
import { formatDateTime, pageTitleTemplate } from '~/util/common/common'
import { inquiryStatusFormat } from '~/util/filter/format'

const AdminInquiryDetail = () => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const navigate = useNavigate()
    const params = useParams()
    const { inquiryUuid } = params

    // 変更するinquiry
    const [inquiry, setInquiry] = useState<InquiryEntities>()

    // 対応済み変更確認モーダルvisible
    const [visibleChangeModal, setVisibleChangeModal] = useState(false)
    // 変更API
    const [changeAPI] = useAdminInquiryPutUuidMutation()

    // 削除確認モーダルvisible
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
    // 削除API
    const [deleteAPI] = useAdminInquiryDeleteUuidMutation()

    // 問い合わせGET
    if (!inquiryUuid) return <CMessage>問い合わせを特定する情報が見つかりませんでした</CMessage>
    const { data: originalInquiry, isLoading: isLoadingOriginalInquiry } = useAdminInquiryUuidQuery(
        { uuid: inquiryUuid },
        { skip: visibleDeleteModal },
    )
    useMemo(() => {
        if (originalInquiry) setInquiry(originalInquiry)
        else return <CMessage>問い合わせが見つかりませんでした</CMessage>
    }, [originalInquiry])

    // 対応済みにするボタンハンドラ
    const changeHandler = () => {
        setVisibleChangeModal(true)
        if (inquiry) {
            setInquiry({
                ...inquiry,
                status: inquiryStatusItems.find((item) => item.label === '対応済み')?.value ?? inquiry.status,
            })
        }
    }

    // 対応済みに変更実行
    const changeInquiry = async () => {
        try {
            if (!inquiry) throw new ApplicationError('問い合わせの取得に失敗しました')
            await changeAPI({ uuid: inquiry.uuid, inquiryEntities: inquiry }).unwrap()
            toast.success('問い合わせを対応済みにしました')
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleChangeModal(false)
        }
    }
    // 削除実行
    const deleteInquiry = async () => {
        try {
            if (!inquiry) throw new ApplicationError('問い合わせの取得に失敗しました')
            await deleteAPI({ uuid: inquiry.uuid }).unwrap()
            toast.success('問い合わせを削除しました')
            // 問い合わせ一覧に遷移
            navigate('/admin/inquiry')
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleDeleteModal(false)
        }
    }

    return (
        <>
            <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3')}>
                <Helmet titleTemplate={pageTitleTemplate(true)}>
                    <title>問い合わせ詳細</title>
                </Helmet>
                <CError errors={errors} />
                <CHeader label="問い合わせ詳細" />
                <div>
                    <div className={clsx('flex', 'justify-end', 'my-2', 'space-x-2')}>
                        <CButton
                            className={clsx('c-button-secondary')}
                            onClick={changeHandler}
                            disabled={originalInquiry?.status === 10}>
                            対応済みにする
                        </CButton>
                        <CButton className={clsx('c-button-danger')} onClick={() => setVisibleDeleteModal(true)}>
                            問い合わせを削除
                        </CButton>
                    </div>
                    <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3', 'bg-white')}>
                        <CHeader label="問い合わせ元" bgClass="bg-kimar-accent" />
                        {isLoadingOriginalInquiry ? (
                            <CMessage info>読み込み中です...</CMessage>
                        ) : (
                            <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                                <tbody>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            会社名
                                        </th>
                                        <td className={clsx('p-1')}>{originalInquiry?.companyName}</td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            氏名
                                        </th>
                                        <td className={clsx('p-1')}>{originalInquiry?.name}</td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            電話番号
                                        </th>
                                        <td className={clsx('p-1')}>{originalInquiry?.phone}</td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            メールアドレス
                                        </th>
                                        <td className={clsx('p-1')}>{originalInquiry?.email}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                    <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3', 'bg-white')}>
                        <CHeader label="問い合わせ内容" bgClass="bg-kimar-accent" />
                        {isLoadingOriginalInquiry ? (
                            <CMessage info>読み込み中です...</CMessage>
                        ) : (
                            <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                                <tbody>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            状態
                                        </th>
                                        <td className={clsx('p-1')}>
                                            <CTag
                                                className={clsx(
                                                    'rounded-md',
                                                    !originalInquiry?.status ? 'bg-red-500' : 'bg-kimar-primary',
                                                    'px-2',
                                                    'text-white',
                                                )}>
                                                {inquiryStatusFormat(originalInquiry?.status)}
                                            </CTag>
                                        </td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            問い合わせ日
                                        </th>
                                        <td className={clsx('p-1')}>
                                            {formatDateTime(originalInquiry?.createdAt, 'dateTimeWithoutSecond')}
                                        </td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            件名
                                        </th>
                                        <td className={clsx('p-1')}>{originalInquiry?.subject}</td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            内容
                                        </th>
                                        <td className={clsx('p-1')}>{originalInquiry?.content}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            <CModal visible={visibleChangeModal} onRequestClose={() => setVisibleChangeModal(false)}>
                <div className={clsx('p-2')}>
                    <div>対応済みにします。よろしいですか？</div>
                    <div className={clsx('flex', 'm-4', 'justify-center')}>
                        <CButton className={clsx('c-button-secondary')} onClick={() => setVisibleChangeModal(false)}>
                            キャンセル
                        </CButton>
                        <CButton className={clsx('c-button-primary')} onClick={changeInquiry}>
                            確定
                        </CButton>
                    </div>
                </div>
            </CModal>
            <CModal visible={visibleDeleteModal} onRequestClose={() => setVisibleDeleteModal(false)}>
                <div className={clsx('p-2')}>
                    <div>問い合わせを削除します。よろしいですか？</div>
                    <div className={clsx('flex', 'm-4', 'justify-center')}>
                        <CButton className={clsx('c-button-secondary')} onClick={() => setVisibleDeleteModal(false)}>
                            キャンセル
                        </CButton>
                        <CButton className={clsx('c-button-danger')} onClick={deleteInquiry}>
                            削除
                        </CButton>
                    </div>
                </div>
            </CModal>
        </>
    )
}
export default AdminInquiryDetail
