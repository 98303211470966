import { FileEntities, useClientMediaGetSignedUrlMutation } from '~/app/api'

export const getSignedUrl = (file: FileEntities) => {
    return `${process.env.BASE_URL}/api/client/media/signed/${file.uuid}`
}

export const getSignedUrlString = async (file: FileEntities, mutation: ReturnType<typeof useClientMediaGetSignedUrlMutation>) => {
    const [method] = mutation
    const result = await method({ valueDto: { value: file.uuid } }).unwrap()
    return result.value
}

export const isPdfFile = (file: FileEntities) => {
    if (file.mimeType.match(/pdf/)) return true
    return false
}
