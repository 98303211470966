import clsx from 'clsx'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { useAdminDownloadUuidQuery } from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { formatDateTime, pageTitleTemplate } from '~/util/common/common'
import { downloadTriggerFormat } from '~/util/filter/format'

const AdminDownloadDetail = () => {
    const params = useParams()
    const { downloadUuid } = params
    if (!downloadUuid) return <CMessage>資料ダウンロードを特定する情報が見つかりませんでした</CMessage>

    const { data: download, isLoading: isLoadingDownload } = useAdminDownloadUuidQuery({ uuid: downloadUuid })
    if (!download) return <CMessage>資料ダウンロード情報が見つかりませんでした</CMessage>

    return (
        <>
            <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3')}>
                <Helmet titleTemplate={pageTitleTemplate(true)}>
                    <title>資料ダウンロード詳細</title>
                </Helmet>
                <CHeader label="資料ダウンロード詳細" />
                <div>
                    <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3')}>
                        <CHeader label="請求元" bgClass="bg-kimar-accent" />
                        {isLoadingDownload ? (
                            <CMessage info>読み込み中です...</CMessage>
                        ) : (
                            <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                                <tbody>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            会社名
                                        </th>
                                        <td className={clsx('p-1')}>{download.companyName}</td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            部署
                                        </th>
                                        <td className={clsx('p-1')}>{download.departmentName}</td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            役職
                                        </th>
                                        <td className={clsx('p-1')}>{download.position}</td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            氏名
                                        </th>
                                        <td className={clsx('p-1')}>{download.name}</td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            電話番号
                                        </th>
                                        <td className={clsx('p-1')}>{download.phone}</td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            メールアドレス
                                        </th>
                                        <td className={clsx('p-1')}>{download.email}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                    <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3')}>
                        <CHeader label="ルート・きっかけ" bgClass="bg-kimar-accent" />
                        {isLoadingDownload ? (
                            <CMessage info>読み込み中です...</CMessage>
                        ) : (
                            <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                                <tbody>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            資料請求日
                                        </th>
                                        <td className={clsx('p-1')}>
                                            {formatDateTime(download.createdAt, 'dateTimeWithoutSecond')}
                                        </td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            ルート
                                        </th>
                                        <td className={clsx('p-1')}>
                                            {!download.intermediaryFrom ? 'キマールHP' : 'ご案内メール'}
                                        </td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            きっかけ
                                        </th>
                                        <td className={clsx('p-1')}>{downloadTriggerFormat(download)}</td>
                                    </tr>
                                    <tr className={clsx('border')}>
                                        <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                            業務課題
                                        </th>
                                        <td className={clsx('p-1')}>{download.issue}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminDownloadDetail
