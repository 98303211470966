import clsx from 'clsx'
import { t } from 'i18next'
import { useMediaQuery } from 'react-responsive'

import {
    ClientTeamMemberConditionUpdateDto,
    ConditionRequestUpdateDto,
    RealEstateUseTypeEntities,
    useClientRealEstateUseTypeRealEstateUseTypeQuery,
} from '~/app/api'
import { CCheckBoxList } from '~/components/common/cCheckBoxList/CCheckBoxList'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CRadioButton } from '~/components/common/cRadioButton/CRadioButton'
import {
    areaTypeItems,
    customerBuildingAgeTypeItems,
    customerGrossRateTypeItems,
    customerLegalComplianceTypeItems,
    customerNetRateTypeItems,
    customerPriceRanges,
    customerSeismicStandardTypeItems,
    saleTimeTypeItems,
} from '~/types/enum/enum'

const gridClass = {
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
    8: 'grid-cols-8',
    10: 'grid-cols-10',
    12: 'grid-cols-12',
}

type CConditionFormProp = {
    grid?: keyof typeof gridClass
    requiredContent?: string[]
    formDto: ClientTeamMemberConditionUpdateDto | ConditionRequestUpdateDto
    changeHandler: (key: keyof ClientTeamMemberConditionUpdateDto | keyof ConditionRequestUpdateDto, value: unknown) => void
}

const CConditionForm = ({
    grid = 5,
    formDto,
    changeHandler,
    requiredContent = ['useType', 'area', 'prices', 'buildingAgeType', 'purchaseTimeType'],
}: CConditionFormProp) => {
    const { data: useTypeListCount } = useClientRealEstateUseTypeRealEstateUseTypeQuery({ isNotSetOption: true })
    const isMobile = useMediaQuery({ maxWidth: '768px' })
    return (
        <div className={clsx('flex', 'flex-col', 'gap-y-4')}>
            <CLabeledItem required={requiredContent.includes('useType')} label={t('CConditionForm.種別(複数選択可)')}>
                <div className={clsx('border', 'rounded', 'p-2')}>
                    <CCheckBoxList
                        grid={isMobile ? 3 : grid}
                        items={useTypeListCount?.list as RealEstateUseTypeEntities[]}
                        dataLabel={'name'}
                        dataValue={'uuid'}
                        checked={formDto.useType}
                        onChange={(value) => changeHandler('useType', value)}
                    />
                </div>
            </CLabeledItem>
            <CLabeledItem required={requiredContent.includes('area')} label={t('CConditionForm.エリア(複数選択可)')}>
                <div className={clsx('border', 'rounded', 'p-2')}>
                    <CCheckBoxList
                        grid={isMobile ? 3 : grid}
                        items={areaTypeItems as unknown as { [key: string]: unknown }[]}
                        checked={formDto.area}
                        onChange={(value) => changeHandler('area', value)}
                    />
                </div>
            </CLabeledItem>
            <CLabeledItem required={requiredContent.includes('prices')} label={t('CConditionForm.金額(複数選択可)')}>
                <div className={clsx('border', 'rounded', 'p-2')}>
                    <CCheckBoxList
                        grid={isMobile ? 3 : grid}
                        items={customerPriceRanges as unknown as { [key: string]: unknown }[]}
                        checked={formDto.prices}
                        onChange={(value) => changeHandler('prices', value)}
                    />
                </div>
            </CLabeledItem>
            <CLabeledItem required={requiredContent.includes('buildingAgeType')} label={t('CConditionForm.築年数')}>
                <div className={clsx('border', 'rounded', 'p-2')}>
                    <CRadioButton
                        grid={isMobile ? 3 : grid}
                        items={customerBuildingAgeTypeItems}
                        nowValue={formDto.buildingAgeType}
                        onChange={(value) => changeHandler('buildingAgeType', value)}
                    />
                </div>
            </CLabeledItem>
            <CLabeledItem required={requiredContent.includes('purchaseTimeType')} label={t('CConditionForm.購入時期')}>
                <div className={clsx('border', 'rounded', 'p-2')}>
                    <CRadioButton
                        grid={isMobile ? 3 : grid}
                        items={saleTimeTypeItems}
                        nowValue={formDto.purchaseTimeType}
                        onChange={(value) => changeHandler('purchaseTimeType', value)}
                    />
                </div>
            </CLabeledItem>
            <CLabeledItem required={requiredContent.includes('grossRateType')} label={t('CConditionForm.表面利回り')}>
                <div className={clsx('border', 'rounded', 'p-2')}>
                    <CRadioButton
                        grid={isMobile ? 3 : grid}
                        items={customerGrossRateTypeItems}
                        nowValue={formDto.grossRateType}
                        onChange={(value) => changeHandler('grossRateType', value)}
                    />
                </div>
            </CLabeledItem>
            <CLabeledItem required={requiredContent.includes('netRateType')} label={t('CConditionForm.実質利回り')}>
                <div className={clsx('border', 'rounded', 'p-2')}>
                    <CRadioButton
                        grid={isMobile ? 3 : grid}
                        items={customerNetRateTypeItems}
                        nowValue={formDto.netRateType}
                        onChange={(value) => changeHandler('netRateType', value)}
                    />
                </div>
            </CLabeledItem>
            <CLabeledItem required={requiredContent.includes('seismicStandardType')} label={t('CConditionForm.耐震基準')}>
                <div className={clsx('border', 'rounded', 'p-2')}>
                    <CRadioButton
                        grid={isMobile ? 3 : grid}
                        items={customerSeismicStandardTypeItems}
                        nowValue={formDto.seismicStandardType}
                        onChange={(value) => changeHandler('seismicStandardType', value)}
                    />
                </div>
            </CLabeledItem>
            <CLabeledItem required={requiredContent.includes('legalComplianceType')} label={t('CConditionForm.遵法性')}>
                <div className={clsx('border', 'rounded', 'p-2')}>
                    <CRadioButton
                        grid={isMobile ? 3 : grid}
                        items={customerLegalComplianceTypeItems}
                        nowValue={formDto.legalComplianceType}
                        onChange={(value) => changeHandler('legalComplianceType', value)}
                    />
                </div>
            </CLabeledItem>
        </div>
    )
}

export default CConditionForm
