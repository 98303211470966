import clsx from 'clsx'
import { t } from 'i18next'
import { sortBy } from 'lodash'
import { useMemo, useState } from 'react'

import {
    ClientCustomerIntermediaryResponseDto,
    CustomerEntities,
    IntermediaryToEntities,
    useClientCustomerIntermediaryCustomerUuidQuery,
    useClientRealEstateHasNoReadTeamActivityQuery,
} from '~/app/api'
import CMessage from '~/components/common/cMessage/CMessage'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'

import CCustomerDetailIntermediaryListItem from './CCustomerDetailIntermediaryListItem'
import CCustomerDetailRealEstateListItem from './CCustomerDetailRealEstateListItem'

type Props = {
    customer: CustomerEntities
}

const CCustomerDetailRealEstate = ({ customer }: Props) => {
    const [selectedTab, setSelectedTab] = useState<keyof ClientCustomerIntermediaryResponseDto>('sending')
    const { data: intermediaries } = useClientCustomerIntermediaryCustomerUuidQuery({ uuid: customer.uuid })

    // 各タブの物件のuuids
    // 紹介している・過去紹介した / 紹介されている・過去紹介された で物件のデータ位置が違うので条件分岐
    const rUuidsInSelectedTab = intermediaries
        ? selectedTab === 'sending' || selectedTab === 'sended'
            ? intermediaries[selectedTab].flatMap((i) => i.intermediary.sourceRealEstateRevision.realEstate?.uuid)
            : intermediaries[selectedTab].flatMap((i) => i.relatedRealEstate.flatMap((r) => r.uuid))
        : []

    const { data: activityDto } = useClientRealEstateHasNoReadTeamActivityQuery(
        {
            realEstateUuids: rUuidsInSelectedTab,
        },
        { skip: rUuidsInSelectedTab.length <= 0 },
    )

    const tabs = useMemo(
        () => [
            { label: t('CCustomerDetailRealEstate.紹介している'), value: 'sending' },
            { label: t('CCustomerDetailRealEstate.過去紹介した'), value: 'sended' },
            { label: t('CCustomerDetailRealEstate.紹介されている'), value: 'receiving' },
            { label: t('CCustomerDetailRealEstate.過去紹介された'), value: 'received' },
        ],
        [],
    )

    // 紹介されているタブの物件
    const receivingRealEstates = intermediaries?.receiving.filter((it) => it.relatedRealEstate.length > 0)
    // 過去紹介されたタブの物件
    const receivedRealEstates = intermediaries?.received.filter((it) => it.relatedRealEstate.length > 0)

    const sortedIntermediaryTo = (list: IntermediaryToEntities[]) => {
        return sortBy(list, 'createdAt').reverse()
    }

    const tabDom = () => {
        if (selectedTab === 'sending') {
            if (intermediaries?.sending && intermediaries?.sending.length > 0) {
                return sortedIntermediaryTo(intermediaries?.sending || []).map((intermediaryTo: IntermediaryToEntities) => (
                    <CCustomerDetailRealEstateListItem
                        // activityDto={activityDto}
                        key={intermediaryTo.uuid}
                        customer={customer}
                        intermediaryTo={intermediaryTo}
                        showChatButton={true}
                        showDocumentButton={true}
                    />
                ))
            }
        } else if (selectedTab === 'sended') {
            if (intermediaries?.sended && intermediaries?.sended.length > 0) {
                return sortedIntermediaryTo(intermediaries?.sended || []).map((intermediaryTo: IntermediaryToEntities) => (
                    <CCustomerDetailRealEstateListItem
                        // activityDto={activityDto}
                        key={intermediaryTo.uuid}
                        customer={customer}
                        intermediaryTo={intermediaryTo}
                        showChatButton={false}
                        showDocumentButton={true}
                    />
                ))
            }
        } else if (selectedTab === 'receiving') {
            if (receivingRealEstates && receivingRealEstates.length > 0) {
                return sortedIntermediaryTo(receivingRealEstates).map((intermediaryTo: IntermediaryToEntities) => (
                    <CCustomerDetailIntermediaryListItem
                        activityDto={activityDto}
                        key={intermediaryTo.uuid}
                        customer={customer}
                        intermediaryTo={intermediaryTo}
                        showChatButton={true}
                        showDocumentButton={true}
                    />
                ))
            }
        } else if (selectedTab === 'received') {
            if (receivedRealEstates && receivedRealEstates.length > 0) {
                return sortedIntermediaryTo(receivedRealEstates).map((intermediaryTo: IntermediaryToEntities) => (
                    <CCustomerDetailIntermediaryListItem
                        activityDto={activityDto}
                        key={intermediaryTo.uuid}
                        customer={customer}
                        intermediaryTo={intermediaryTo}
                        showChatButton={false}
                        showDocumentButton={true}
                    />
                ))
            }
        }
        return (
            <CMessage info>
                <p>{t('CCustomerDetailRealEstate.紹介はありません。')}</p>
            </CMessage>
        )
    }

    return (
        <div className={clsx('overflow-y-scroll', 'max-h-[63vh]', 'font-body', 'mt-2')}>
            <CSegmentedControl
                items={tabs}
                onClick={(val) => setSelectedTab(val as keyof ClientCustomerIntermediaryResponseDto)}
                selectedTabValue={selectedTab}
            />
            <div className={clsx('border', 'rounded', 'mt-2', 'p-2')}>{tabDom()}</div>
        </div>
    )
}

export default CCustomerDetailRealEstate
