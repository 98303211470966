import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IntermediaryToEntities } from '~/app/api'

import type { RootState } from './store'

const slice = createSlice({
    name: 'intermediary',
    initialState: { intermediaryTo: undefined } as {
        intermediaryTo: undefined | IntermediaryToEntities
    },
    reducers: {
        setIntermediaryTo: (state, { payload: intermediaryTo }: PayloadAction<undefined | IntermediaryToEntities>) => ({
            ...state,
            intermediary: intermediaryTo,
        }),
    },
})

// sliceからactionを切り出す
export const { setIntermediaryTo } = slice.actions

// ログインユーザー
export const selectIntermediaryTo = (state: RootState) => state.persistedReducer.intermediary.intermediaryTo

// sliceからreducerを切り出す
export default slice.reducer
