import clsx from 'clsx'
import { t } from 'i18next'
import { CSSProperties, FC, ReactNode } from 'react'

import { CButton } from '../cButton/CButton'
import { CFooterModal } from '../cModal/CModal'

export type CConfirmModalViewProps = {} & CConfirmModalProps

export const CConfirmModalView: FC<CConfirmModalViewProps> = ({
    children,
    visible,
    content,
    onRequestClose,
    onRequestConfirm,
    title,
    cancelLabel,
    confirmLabel,
    disableSubmit,
    confirmClass,
}) => {
    const footer = (
        <>
            <CButton className={clsx('c-button-secondary')} onClick={() => onRequestClose()}>
                {cancelLabel ?? t('Button.キャンセル')}
            </CButton>
            <CButton
                disabled={disableSubmit}
                className={clsx('c-button-primary', confirmClass)}
                onClick={() => onRequestConfirm()}>
                {confirmLabel ?? t('Button.確定')}
            </CButton>
        </>
    )

    return (
        <CFooterModal content={content} visible={visible} onRequestClose={() => onRequestClose()} footer={footer}>
            <div className={clsx('flex', 'flex-col', 'gap-8', 'p-4', 'md:p-8')}>
                <div className={clsx('text-kimar-primary', 'font-bold', 'text-center')}>
                    {title ?? t('Dialog.確認メッセージ')}
                </div>
                <div>{children}</div>
            </div>
        </CFooterModal>
    )
}

export type CConfirmModalProps = {
    children: ReactNode
    visible: boolean
    onRequestConfirm: () => void
    onRequestClose: () => void
    content?: CSSProperties
    title?: string
    confirmLabel?: string
    cancelLabel?: string
    disableSubmit?: boolean
    confirmClass?: string
}

export const CConfirmModal: FC<CConfirmModalProps> = ({ ...props }) => {
    return <CConfirmModalView {...props} />
}
