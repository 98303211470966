import clsx from 'clsx'
import { t } from 'i18next'
import { CSSProperties, ReactNode, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import { AdminNotificationTemplateInsertDto, FileEntities } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { ApplicationError, IApplicationError } from '~/types/error'

type CNotificationTemplateEditProps = {
    visible?: boolean
    template: AdminNotificationTemplateInsertDto
    children?: ReactNode
    content?: CSSProperties
    confirmButtonHandler: (changedTemplate: AdminNotificationTemplateInsertDto) => void
    cancelButtonHandler?: () => void
}

const CNotificationTemplateEdit: VFC<CNotificationTemplateEditProps> = ({ ...props }) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const [template, setTemplate] = useState(props.template)

    const changeHandler = (value: string | FileEntities, target: keyof AdminNotificationTemplateInsertDto) => {
        setTemplate((old) => ({ ...old, [target]: value }))
    }

    const validate = () => {
        const errorList: Array<ApplicationError> = []
        // インジェクション対策のサニタイジング
        if (template.key.trim().length === 0)
            errorList.push(new ApplicationError(t('CNotificationTemplateEdit.テンプレートキーを入力してください')))
        if (errorList.length > 0) {
            setErrors(errorList)
            return false
        }
        return true
    }

    const confirm = () => {
        setErrors([])
        if (!validate()) return
        props.confirmButtonHandler(template)
        toast.success(t('CNotificationTemplateEdit.通知テンプレートを追加しました'))
    }

    return (
        <>
            <div className={clsx('w-full', 'p-4', 'flex', 'flex-col', 'gap-y-3', 'bg-white')}>
                <div className={clsx('flex', 'flex-col', 'gap-y-4')}>
                    <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>
                        {t('CNotificationTemplateEdit.通知テンプレートを追加する')}
                    </div>
                    <CError errors={errors} />
                    <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3', 'overflow-auto')}>
                        <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                            <tbody>
                                <tr className={clsx('border')}>
                                    <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                        <CLabeledItem
                                            className={clsx('pb-0.5')}
                                            label={t('CNotificationTemplateEdit.テンプレートキー')}
                                            required
                                        />
                                    </th>
                                    <td className={clsx('p-1')}>
                                        <CTextInput
                                            type={'text'}
                                            placeholder={'intermediary'}
                                            text={template.key}
                                            className={clsx('w-full', 'c-text-input-base')}
                                            onChange={(value) => changeHandler(value, 'key')}
                                        />
                                    </td>
                                </tr>
                                <tr className={clsx('border')}>
                                    <th scope="row" className={clsx('bg-kimar-primary', 'text-white', 'p-1', 'w-1/5')}>
                                        {t('CNotificationTemplateEdit.テンプレート使用場面')}
                                    </th>
                                    <td className={clsx('p-1')}>
                                        <CTextInput
                                            type="text"
                                            placeholder={t('CNotificationTemplateEdit.物件の紹介(紹介先への通知)')}
                                            text={template?.name}
                                            className={clsx('w-full', 'c-text-input-base')}
                                            onChange={(value) => changeHandler(value, 'name')}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div
                className={clsx(
                    props.cancelButtonHandler && 'bg-gray-100',
                    'w-full',
                    'p-4',
                    'flex',
                    'justify-center',
                    'space-x-2',
                )}>
                {props.cancelButtonHandler && (
                    <CButton className={clsx('c-button-secondary')} onClick={props.cancelButtonHandler}>
                        {t('Button.キャンセル')}
                    </CButton>
                )}
                <CButton className={clsx('c-button-primary')} onClick={confirm}>
                    {t('Button.送信')}
                </CButton>
            </div>
        </>
    )
}

export default CNotificationTemplateEdit
