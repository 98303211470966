import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'

import {
    ClientRealEstateListDto,
    useClientRealEstateGetMaxProgressQuery,
    useClientRealEstateHasNoReadTeamActivityQuery,
    useClientRealEstateRealEstateQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { CPager } from '~/components/common/cPager/CPager'
import { CSortButton } from '~/components/common/cSortButton/CSortButton'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { CRealestateEdit } from '~/components/unique/realestate/CRealestateEdit'
import { CRealestateListItem } from '~/components/unique/realestate/CRealestateListItem'
import { CRealestateTrialModal } from '~/components/unique/realestate/CRealestateTrialModal'
import { pageTitleTemplate } from '~/util/common/common'
import { DefaultRealEstateKeys, RealEstateKeyLabels } from '~/util/model/RealEstateEntities'
import { useAppDispatch, useAppSelector } from '~/util/store/hooks'
import { selectIsShowMapOnRealEstateList, setIsShowMapOnRealEstateList } from '~/util/store/mapSlice'
import { selectLoginUser, selectLoginUserCompany } from '~/util/store/userSlice'

import {
    CRealestateSearch,
    RealEstateSearchFilterArrayType,
    RealEstateSearchFormValue,
} from '../../components/unique/realestate/CRealestateSearch'

/** 物件 */
const Realestate = () => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectLoginUser)
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const navigate = useNavigate()

    const company = useAppSelector(selectLoginUserCompany)
    const realEstateKeys =
        (company?.realEstateListKeys as (typeof RealEstateKeyLabels)[number]['key'][] | undefined) ?? DefaultRealEstateKeys

    const [openAddModal, setOpenAddModal] = useState(false)
    const [openTrialModal, setOpenTrialModal] = useState(!!query.get('trial'))
    const [openTrialIntermediaryModal, setOpenTrialIntermediaryModal] = useState(!!query.get('trialIntermediary'))

    // 検索フォーム
    const [form, setForm] = useState<{
        name: string
        order: string
        filter: RealEstateSearchFilterArrayType
        sort: 'ASC' | 'DESC'
        useTypes: string[]
        prices: string[]
        buildingAges: string[]
        saleTimes: string[]
        assignedToType: 'AND' | 'OR'
        assignedToUuidList: string[]
        labelType: 'AND' | 'OR'
        labelUuidList: string[]
        progress: string[]
        grossRate: string[]
        salesDestination: string[]
    }>({
        name: '',
        order: 'createdAt',
        filter: [],
        sort: 'DESC',
        useTypes: [],
        prices: [],
        buildingAges: [],
        saleTimes: [],
        assignedToType: 'OR',
        assignedToUuidList: [],
        labelType: 'OR',
        labelUuidList: [],
        progress: [],
        grossRate: [],
        salesDestination: [],
    })

    const [page, setPage] = useState(1)

    const pageSize = 100

    const { data: realEstateList, isLoading: isLoadingRealEstateList } = useClientRealEstateRealEstateQuery({
        page,
        pageSize,
        ...form,
    })

    const onChange = (
        v: 'createdAt' | 'updatedAt' | 'acquiredAt' | 'internalPriceAsc' | 'externalPriceAsc' | 'latestIntermediaryAt',
    ) => {
        setPage(1)
        setForm({
            ...form,
            order: v,
        })
    }

    const onChangeSort = () => {
        setPage(1)
        setForm({
            ...form,
            sort: form.sort == 'ASC' ? 'DESC' : 'ASC',
        })
    }

    const onPageChanged = (_page: number) => {
        setPage(_page)
    }

    const onChangeSearchVal = (val: RealEstateSearchFormValue) => {
        setPage(1)
        setForm({
            ...form,
            ...val,
        })
    }

    useEffect(() => {
        scroll.scrollToTop()
    }, [page])

    const closeTrialModal = () => {
        setOpenTrialModal(false)
        navigate('/realestate')
    }

    const closeTrialIntermediaryModal = () => {
        setOpenTrialIntermediaryModal(false)
        navigate('/realestate')
    }

    const { data: activityDto } = useClientRealEstateHasNoReadTeamActivityQuery(
        {
            realEstateUuids:
                (realEstateList?.list as ClientRealEstateListDto[] | undefined)?.map((realEstate) => realEstate.uuid) || [],
        },
        { skip: !realEstateList?.list?.length },
    )

    const { data: progressDto } = useClientRealEstateGetMaxProgressQuery(
        {
            realEstateUuids:
                (realEstateList?.list as ClientRealEstateListDto[] | undefined)?.map((realEstate) => realEstate.uuid) || [],
        },
        { skip: !realEstateList?.list?.length },
    )

    const isShowMap = useAppSelector(selectIsShowMapOnRealEstateList)

    if (isLoadingRealEstateList) return <CMessage info>{t('Message.読み込み中です…')}</CMessage>
    if (!realEstateList) return <></>

    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('Realestate.物件')}</title>
            </Helmet>
            <div className={clsx('sticky', 'top-20', 'w-full', 'w-full', 'max-w-7xl', 'mx-auto', 'z-10')}>
                <CRealestateSearch onChange={onChangeSearchVal} />
            </div>
            <div className={clsx('w-full', 'w-full', 'max-w-7xl', 'mx-auto', 'flex', 'flex-col', 'space-y-2')}>
                <div className={clsx('flex', 'flex-col', 'md:flex-row', 'justify-between', 'flex-wrap')}>
                    <div className={clsx('flex')}>
                        <CLabeledItem label={t('Realestate.並び順')} horizontal className={clsx('mx-1', 'text-sm')}>
                            <CSortButton
                                className={clsx('border-gray-300')}
                                items={[
                                    {
                                        label: t('Realestate.追加日'),
                                        value: 'createdAt',
                                    },
                                    {
                                        label: t('Realestate.更新日'),
                                        value: 'updatedAt',
                                    },
                                    {
                                        label: t('Realestate.情報取得日'),
                                        value: 'acquiredAt',
                                    },
                                    {
                                        label: t('Realestate.物件価格'),
                                        value: 'internalPriceAsc',
                                    },
                                    {
                                        label: t('Realestate.提示金額'),
                                        value: 'externalPriceAsc',
                                    },
                                    {
                                        label: t('Realestate.最終紹介日'),
                                        value: 'latestIntermediaryAt',
                                    },
                                ]}
                                sort={form.sort}
                                onChange={(v) => {
                                    onChange(
                                        v as
                                            | 'createdAt'
                                            | 'updatedAt'
                                            | 'acquiredAt'
                                            | 'internalPriceAsc'
                                            | 'externalPriceAsc'
                                            | 'latestIntermediaryAt',
                                    )
                                }}
                                onClickSort={onChangeSort}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('Realestate.地図表示')} horizontal className={clsx('mx-1', 'text-sm')}>
                            <CToggleSwitch
                                value={isShowMap}
                                trueLabel={t('Realestate.ON')}
                                falseLabel={t('Realestate.OFF')}
                                toggleChanged={(value) => {
                                    dispatch(setIsShowMapOnRealEstateList(value))
                                }}
                            />
                        </CLabeledItem>
                    </div>
                    <div className={clsx('flex', 'md:items-center', 'mx-2', 'flex-col', 'md:flex-row', 'items-end')}>
                        {realEstateList.list.length !== 0 && (
                            <CPager page={page} pageSize={pageSize} total={realEstateList.count} onPageChanged={onPageChanged} />
                        )}
                        {user?.roles.realestate === 2 && (
                            <div>
                                <CButton className={clsx('c-button-primary')} onClick={() => setOpenAddModal(true)}>
                                    <i className={clsx('material-icons')}>add</i>
                                    <div>{t('Realestate.新規物件登録')}</div>
                                </CButton>
                            </div>
                        )}
                    </div>
                </div>

                <div className={clsx('w-full', 'flex', 'flex-col', 'space-y-2')}>
                    {realEstateList.list.length === 0 ? (
                        <CMessage info>{t('Realestate.物件が見つかりませんでした')}</CMessage>
                    ) : (
                        <>
                            {realEstateList.list.map((item, i) => (
                                <div key={i} className={clsx('w-full')}>
                                    <CRealestateListItem
                                        isReceiveNewChat={
                                            !!activityDto?.receiveNewChat.find(
                                                (c) => c === (item as ClientRealEstateListDto).uuid,
                                            )
                                        }
                                        isSendNewChat={
                                            !!activityDto?.sendNewChat.find((c) => c === (item as ClientRealEstateListDto).uuid)
                                        }
                                        isNewRecommend={
                                            !!activityDto?.newRecommend.find(
                                                (c) => c === (item as ClientRealEstateListDto).uuid,
                                            ) ||
                                            !!activityDto?.newConnection.find((c) => c === (item as ClientRealEstateListDto).uuid)
                                        }
                                        item={item as ClientRealEstateListDto}
                                        progress={
                                            progressDto?.items.find((c) => c.uuid === (item as ClientRealEstateListDto).uuid) ?? {
                                                uuid: '',
                                                progress: 0,
                                            }
                                        }
                                        isHideMap={!isShowMap}
                                        details={realEstateKeys}
                                    />
                                </div>
                            ))}
                            <CPager page={page} pageSize={pageSize} total={realEstateList.count} onPageChanged={onPageChanged} />
                        </>
                    )}
                </div>
            </div>
            {/* 無料会員の表示物件数警告 */}
            <CRealestateTrialModal visible={openTrialModal} onClose={closeTrialModal} />
            {/* 無料会員の紹介数警告 */}
            <CFooterModal
                footer={
                    <CButton className={clsx('c-button-secondary')} onClick={closeTrialIntermediaryModal}>
                        {t('Button.閉じる')}
                    </CButton>
                }
                visible={!!openTrialIntermediaryModal}
                content={{ width: '450px' }}
                onRequestClose={closeTrialIntermediaryModal}>
                <div className={clsx('px-8', 'py-4', 'flex', 'flex-col', 'gap-4')}>
                    <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>
                        {t('Realestate.無料会員様へのお知らせ')}
                    </div>
                    <p>{t('Realestate.無料会員が同時に物件の紹介を行えるのは1物件のみです。')}</p>
                    <p>{t('Realestate.紹介可能な物件数を無制限とするには、以下のボタンからお問い合わせください。')}</p>
                    <CLinkButton className={clsx('c-button-primary', 'text-sm', 'md:row-start-4')} to={`/inquiry`}>
                        {t('Realestate.お問い合わせ')}
                    </CLinkButton>
                </div>
            </CFooterModal>
            <CRealestateEdit
                visible={openAddModal}
                onSubmit={() => {
                    setOpenAddModal(false)
                }}
                onClose={() => {
                    setOpenAddModal(false)
                }}
            />
        </>
    )
}
export default Realestate
