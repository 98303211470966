import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast/headless'
import { Link } from 'react-router-dom'

import {
    AdminNotificationTemplateInsertDto,
    useAdminNotificationTemplateGetNotificationTemplateQuery,
    useAdminNotificationTemplatePostNotificationTemplateMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CError } from '~/components/common/cError/CError'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import CMessage from '~/components/common/cMessage/CMessage'
import { CModal } from '~/components/common/cModal/CModal'
import { CPager } from '~/components/common/cPager/CPager'
import { CSortButton } from '~/components/common/cSortButton/CSortButton'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { ApplicationError, IApplicationError } from '~/types/error'

import CNotificationTemplateEdit from './CNotificationTemplateEdit'

const CNotificationTemplate = () => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const [page, setPage] = useState(1)
    const [timer, setTimer] = useState<NodeJS.Timeout>()
    // 検索フォーム
    const [form, setForm] = useState<{
        key: string
        name: string
        mailSubject: string
        sort: 'ASC' | 'DESC'
        order: 'key' | 'name' | 'mailSubject'
    }>({
        key: '',
        name: '',
        mailSubject: '',
        sort: 'ASC',
        order: 'key',
    })
    const [skip, setSkip] = useState(false)

    const { data: templates, isLoading: isLoadingTemplates } = useAdminNotificationTemplateGetNotificationTemplateQuery(
        { ...form, page },
        { skip },
    )
    const [postAPI] = useAdminNotificationTemplatePostNotificationTemplateMutation()
    // テンプレート初期状態
    const emptyTemplate: AdminNotificationTemplateInsertDto = {
        key: '',
        name: '',
    }
    // 新規作成モーダルvisible
    const [visibleChangeModal, setVisibleChangeModal] = useState(false)
    // 新規作成モーダルのデータ
    const [template, setTemplate] = useState<AdminNotificationTemplateInsertDto>(emptyTemplate)

    // 検索フォームイベントハンドラ
    const formTextChanged = (value: string, target: 'key' | 'name' | 'mailSubject') => {
        setSkip(true)
        setForm((oldValue) => ({ ...oldValue, [target]: value }))

        if (timer) clearTimeout(timer)
        const newTimer = setTimeout(() => {
            setSkip(false)
        }, 500)
        setTimer(newTimer)
    }
    const onChangeSort = () => {
        setPage(1)
        setForm((oldValue) => ({
            ...oldValue,
            sort: form.sort == 'ASC' ? 'DESC' : 'ASC',
        }))
    }
    const onChange = (v: 'key' | 'name' | 'mailSubject') => {
        setPage(1)
        setForm((oldValue) => ({
            ...oldValue,
            order: v,
        }))
    }

    // 新規追加ボタンハンドラ
    const createHandler = () => {
        setVisibleChangeModal(true)
        setTemplate(emptyTemplate)
    }
    // 新規追加の保存ボタンハンドラ
    const confirmButtonHandler = async (changedTemplate: AdminNotificationTemplateInsertDto) => {
        setErrors([])
        setTemplate(changedTemplate)
        try {
            await postAPI({ adminNotificationTemplateInsertDto: changedTemplate }).unwrap()
            toast.success(t('CNotificationTemplate.通知テンプレートの作成が完了しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleChangeModal(false)
        }
    }
    const tableRef = useRef<HTMLDivElement>(null)
    const callbackTableRef = useCallback(() => {
        if (tableRef.current !== null) tableRef.current.scrollTop = 0
    }, [templates])
    useEffect(() => callbackTableRef(), [callbackTableRef])

    const onPageChanged = (_page: number) => {
        setPage(_page)
    }

    return (
        <div className={clsx('w-full')}>
            <CError errors={errors} />
            <div className={clsx('flex', 'mx-2', 'justify-between')}>
                <div className={clsx('flex', 'flex-row', 'gap-x-4')}>
                    <CLabeledItem label="並び順" horizontal>
                        <CSortButton
                            className={clsx('border-gray-300')}
                            items={[
                                {
                                    label: t('CNotificationTemplate.テンプレートキー'),
                                    value: 'key',
                                },
                                {
                                    label: t('CNotificationTemplate.テンプレート名'),
                                    value: 'name',
                                },
                                {
                                    label: t('CNotificationTemplate.メール件名'),
                                    value: 'mailSubject',
                                },
                            ]}
                            sort={form.sort}
                            onChange={(v) => {
                                onChange(v as 'key' | 'name' | 'mailSubject')
                            }}
                            onClickSort={onChangeSort}
                        />
                    </CLabeledItem>
                    <CLabeledItem label="絞り込み" horizontal>
                        <div className={clsx('grid', 'grid-cols-3', 'gap-1')}>
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                text={form.key}
                                placeholder={t('CNotificationTemplate.テンプレートキー')}
                                onChange={(value) => formTextChanged(value, 'key')}
                            />
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                text={form.name}
                                placeholder={t('CNotificationTemplate.テンプレート名')}
                                onChange={(value) => formTextChanged(value, 'name')}
                            />
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                text={form.mailSubject}
                                placeholder={t('CNotificationTemplate.メール件名')}
                                onChange={(value) => formTextChanged(value, 'mailSubject')}
                            />
                        </div>
                    </CLabeledItem>
                </div>
                <div className={clsx('flex', 'justify-end', 'self-center')}>
                    <CButton className={clsx('c-button-primary', 'text-sm')} onClick={createHandler}>
                        <i className={clsx('material-icons')}>add</i>
                        <div>{t('Button.新規追加')}</div>
                    </CButton>
                </div>
            </div>

            <div className={clsx('w-full', 'p-2')} ref={tableRef}>
                {isLoadingTemplates && <CMessage info>{t('Message.読み込み中です…')}</CMessage>}
                <table className={clsx('w-full', 'text-sm', 'bg-white')}>
                    <thead>
                        <tr className={clsx('bg-kimar-primary', 'text-white', 'text-left')}>
                            <th className={clsx('p-1')}>{t('CNotificationTemplate.テンプレートキー')}</th>
                            <th className={clsx('p-1')}>{t('CNotificationTemplate.テンプレート名')}</th>
                            <th className={clsx('p-1')}>{t('CNotificationTemplate.メール件名')}</th>
                            <th className={clsx('p-1', 'w-20')}>{t('CNotificationTemplate.通知')}</th>
                            <th className={clsx('p-1', 'w-20')}>{t('CNotificationTemplate.メール')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {templates?.list?.map((template) => (
                            <tr key={template.uuid} className={clsx('border', 'hover:bg-kimar-primary-light')}>
                                <td className={clsx('p-1')}>
                                    <Link to={`notification/${template.uuid}`} className={clsx('text-kimar-primary')}>
                                        {template.key}
                                    </Link>
                                </td>
                                <td className={clsx('p-1')}>{template.name}</td>
                                <td className={clsx('p-1')}>{template.mailSubject}</td>
                                <td className={clsx('p-1', 'hidden', 'lg:table-cell')}>
                                    <div className={clsx('flex')}>
                                        <CCheckBox
                                            className={clsx('p-1', 'rounded', 'flex-1')}
                                            readonly
                                            checked={!!template.body}
                                            label={
                                                template.body ? t('CNotificationTemplate.有効') : t('CNotificationTemplate.無効')
                                            }
                                        />
                                    </div>
                                </td>
                                <td className={clsx('p-1', 'hidden', 'lg:table-cell')}>
                                    <div className={clsx('flex')}>
                                        <CCheckBox
                                            className={clsx('p-1', 'rounded', 'flex-1')}
                                            readonly
                                            checked={!!template.mailSubject}
                                            label={
                                                template.mailSubject
                                                    ? t('CNotificationTemplate.有効')
                                                    : t('CNotificationTemplate.無効')
                                            }
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <CPager page={page} pageSize={50} total={templates?.count ?? 0} onPageChanged={onPageChanged} />
            </div>
            {/* 新規作成モーダル */}
            <CModal content={{ width: '900px' }} visible={visibleChangeModal} onRequestClose={() => setVisibleChangeModal(false)}>
                <CNotificationTemplateEdit
                    template={template}
                    confirmButtonHandler={(changedTemplate) => confirmButtonHandler(changedTemplate)}
                    cancelButtonHandler={() => {
                        setVisibleChangeModal(false)
                    }}
                />
            </CModal>
        </div>
    )
}

export default CNotificationTemplate
