import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { last } from 'lodash'
import { useEffect, useMemo, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'
import { Trans } from 'react-i18next'

import {
    CustomerAssigneeEntities,
    CustomerEntities,
    CustomerInvestmentConditionEntities,
    CustomerLabelEntities,
    FileEntities,
    RealEstateUseTypeEntities,
    TeamMemberEntities,
    useClientCustomerImportMutation,
    useClientCustomerLabelTeamUuidQuery,
    useClientCustomerPreImportMutation,
    useClientRealEstateUseTypeRealEstateUseTypeQuery,
    useClientTeamMemberTeamUuidQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import CMessage from '~/components/common/cMessage/CMessage'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { CTagList } from '~/components/common/cTagList/CTagList'
import {
    areaTypeItems,
    customerBuildingAgeTypeItems,
    customerGrossRateTypeItems,
    customerLegalComplianceTypeItems,
    customerNetRateTypeItems,
    customerPriceRanges,
    customerSeismicStandardTypeItems,
    saleTimeTypeItems,
} from '~/types/enum/enum'
import { ApplicationError, IApplicationError } from '~/types/error'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUserTeam } from '~/util/store/userSlice'

type CustomerType =
    | Pick<
          CustomerEntities,
          | 'companyName'
          | 'companyNameSound'
          | 'departmentName'
          | 'position'
          | 'name'
          | 'nameSound'
          | 'intermediaryName'
          | 'companyAddress'
          | 'phone'
          | 'phoneExtension'
          | 'companyPhone'
          | 'companyFax'
          | 'mobile'
          | 'email'
          | 'labels'
          | 'assignees'
          | 'investmentConditions'
      > & { isDuplicate: boolean }

export type CCustomerInfoCsvEditViewProps = {
    step: number
    next: () => void
    prev: () => void
    downloadTemplateCsv: () => void
    setFile: (file: FileEntities) => void
    targetCustomers: CustomerType[]
    hasDuplicateRecord: boolean
    addCustomers: () => void
    replaceCustomers: () => void
    errors: IApplicationError[]
    useTypes: RealEstateUseTypeEntities[]
} & CCustomerInfoCsvEditProps

export const CCustomerInfoCsvEditView: VFC<CCustomerInfoCsvEditViewProps> = ({
    isShow,
    step,
    onRequestClose,
    setFile,
    downloadTemplateCsv,
    next,
    prev,
    targetCustomers,
    hasDuplicateRecord,
    addCustomers,
    replaceCustomers,
    errors,
    useTypes,
}) => {
    const buttons = useMemo(() => {
        if (step === 1) {
            return (
                <div className={clsx('flex', 'space-x-2')}>
                    <CButton className={clsx('c-button-secondary')} onClick={() => onRequestClose()}>
                        {t('Button.キャンセル')}
                    </CButton>
                    <CButton className={clsx('c-button-primary')} onClick={() => next()}>
                        {t('Button.次へ')}
                    </CButton>
                </div>
            )
        } else if (step === 2) {
            return (
                <>
                    <CButton className={clsx('c-button-secondary')} onClick={() => prev()}>
                        {t('Button.戻る')}
                    </CButton>
                </>
            )
        } else if (step === 3) {
            return (
                <CButton className={clsx('c-button-secondary')} onClick={() => prev()}>
                    {t('Button.戻る')}
                </CButton>
            )
        }
    }, [step])

    const stepper = useMemo(() => {
        const checkNode = (
            <div className={clsx('rounded-full', 'flex', 'justify-center')}>
                <i className={clsx('material-icons', 'bg-white', 'text-kimar-primary', 'text-[28px]')}>check_circle</i>
            </div>
        )
        const numberNode = (num: number) => {
            return (
                <div
                    className={clsx(
                        'rounded-full',
                        'text-white',
                        step >= num ? 'bg-kimar-primary' : 'bg-gray-300',
                        'w-6',
                        'flex',
                        'justify-center',
                    )}>
                    {num}
                </div>
            )
        }
        const padding = <div className={clsx('h-0.5', 'bg-gray-300', 'flex-1', 'mx-4')} />
        return (
            <div
                className={clsx(
                    'rounded',
                    'flex',
                    'w-full',
                    'items-center',
                    'border',
                    'border-gray-300',
                    'bg-gray-50',
                    'py-4',
                    'px-8',
                )}>
                <div className={clsx('flex', 'items-center')}>
                    {step > 1 ? checkNode : numberNode(1)}
                    <div className={clsx('ml-1', step >= 1 ? 'text-kimar-primary' : 'text-gray-300')}>
                        {t('CCustomerInfoCsvEdit.準備')}
                    </div>
                </div>
                {padding}
                <div className={clsx('flex', 'items-center')}>
                    {step > 2 ? checkNode : numberNode(2)}
                    <div className={clsx('ml-1', step >= 2 ? 'text-kimar-primary' : 'text-gray-300')}>
                        {t('CCustomerInfoCsvEdit.アップロード')}
                    </div>
                </div>
                {padding}
                <div className={clsx('flex', 'items-center')}>
                    {numberNode(3)}
                    <div className={clsx('ml-1', step >= 3 ? 'text-kimar-primary' : 'text-gray-300')}>
                        {t('CCustomerInfoCsvEdit.確認')}
                    </div>
                </div>
            </div>
        )
    }, [step])

    const stepNode = useMemo(() => {
        if (step === 1) {
            return (
                <>
                    <div className={clsx('text-gray-700', 'w-full')}>
                        <p>{t('CCustomerInfoCsvEdit.この画面では、顧客を一括で追加または上書きすることができます。')}</p>
                        <p>{t('CCustomerInfoCsvEdit.はじめに、一括登録用のテンプレートファイルをダウンロードしてください。')}</p>
                    </div>
                    <div className={clsx('flex', 'justify-center', 'my-4')}>
                        <CButton className={clsx('c-button-primary')} onClick={() => downloadTemplateCsv()}>
                            <i className={clsx('material-symbols-outlined')}>Download</i>
                            <div>{t('CCustomerInfoCsvEdit.テンプレートをダウンロード')} (CSV/0.1KB)</div>
                        </CButton>
                    </div>
                </>
            )
        } else if (step === 2) {
            return (
                <>
                    <div className={clsx('flex', 'flex-col', 'gap-2')}>
                        <div className={clsx('text-gray-700', 'w-full')}>
                            <p>
                                {t(
                                    'CCustomerInfoCsvEdit.次に、テンプレートファイルに追加したい顧客の情報を入力して保存し、この画面からアップロードしてください。',
                                )}
                            </p>
                        </div>
                        <div className={clsx('text-gray-700', 'w-full', 'flex', 'text-xs')}>
                            <div className={clsx('px-1', 'text-white', 'bg-red-700', 'rounded', 'mr-2')}>
                                {t('CCustomerInfoCsvEdit.必須項目')}
                            </div>
                            <div>{t('CCustomerInfoCsvEdit.会社名、氏名、メールアドレス')}</div>
                        </div>
                        <CMessage info>
                            <p>{t('CCustomerInfoCsvEdit.1回の操作で一括登録できる顧客件数は3000件までです。')}</p>
                            <p>
                                {t('CCustomerInfoCsvEdit.3000件を超える顧客を登録する場合は、複数回に分けて登録してください。')}
                            </p>
                        </CMessage>
                        <div>
                            <CFileUpload
                                accept=".csv"
                                fileUploaded={(files) => {
                                    files.length && setFile(files[0])
                                }}
                            />
                        </div>
                    </div>
                    <CError errors={errors} />
                </>
            )
        } else if (step === 3) {
            const createTables = () => {
                const units = [
                    {
                        title: t('CCustomerInfoCsvEdit.アップロード準備ができた顧客リスト'),
                        items: targetCustomers.filter((target) => !target.isDuplicate),
                        bgClass: 'bg-white',
                    },
                    {
                        title: t('CCustomerInfoCsvEdit.すでに登録されている顧客リスト'),
                        items: targetCustomers.filter((target) => target.isDuplicate),
                        bgClass: 'bg-red-200',
                    },
                ]
                return units.map(({ title, items, bgClass }) => {
                    return items.length ? (
                        <>
                            <div className={clsx('text-gray-700', 'w-full', 'flex', 'text-sm', 'mt-4')}>
                                <p>{title}</p>
                            </div>
                            <div className={clsx('overflow-x-auto', 'max-h-[250px]', 'border', 'rounded')}>
                                <table className={clsx('text-sm')}>
                                    <thead>
                                        <tr className={clsx('bg-kimar-primary', 'text-white', 'text-left', 'sticky', 'top-0')}>
                                            <th className={clsx('pl-2', 'min-w-[10rem]')}>{t('CCustomerInfoCsvEdit.会社名')}</th>
                                            <th className={clsx('pl-2', 'min-w-[10rem]')}>
                                                {t('CCustomerInfoCsvEdit.会社名（かな）')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[6rem]')}>{t('CCustomerInfoCsvEdit.部署')}</th>
                                            <th className={clsx('pl-2', 'min-w-[10rem]')}>{t('CCustomerInfoCsvEdit.役職')}</th>
                                            <th className={clsx('pl-2', 'min-w-[8rem]')}>{t('CCustomerInfoCsvEdit.名前')}</th>
                                            <th className={clsx('pl-2', 'min-w-[8rem]')}>
                                                {t('CCustomerInfoCsvEdit.名前（かな）')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[8rem]')}>
                                                {t('CCustomerInfoCsvEdit.紹介時宛名')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[16rem]')}>{t('CCustomerInfoCsvEdit.住所')}</th>
                                            <th className={clsx('pl-2', 'min-w-[8rem]')}>{t('CCustomerInfoCsvEdit.電話番号')}</th>
                                            <th className={clsx('pl-2', 'min-w-[8rem]')}>{t('CCustomerInfoCsvEdit.内線番号')}</th>
                                            <th className={clsx('pl-2', 'min-w-[8rem]')}>
                                                {t('CCustomerInfoCsvEdit.代表電話番号')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[8rem]')}>
                                                {t('CCustomerInfoCsvEdit.代表FAX番号')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.携帯電話')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.メールアドレス')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.顧客ラベル')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.顧客担当者')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.[購入条件]用途')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.[購入条件]エリア')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.[購入条件]金額')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.[購入条件]築年数')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.[購入条件]購入時期')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.[購入条件]表面利回り')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.[購入条件]実質利回り')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.[購入条件]耐震基準')}
                                            </th>
                                            <th className={clsx('pl-2', 'min-w-[12rem]')}>
                                                {t('CCustomerInfoCsvEdit.[購入条件]遵法性')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((customer, index) => {
                                            const condition = last(customer.investmentConditions)
                                            return (
                                                <tr key={index} className={clsx('border-b', 'break-words', 'divide-x', bgClass)}>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.companyName}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.companyNameSound}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.departmentName}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.position}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.name}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.nameSound}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.intermediaryName}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.companyAddress}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.phone}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.phoneExtension}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.companyPhone}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.companyFax}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.mobile}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.email}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div className={clsx('flex', 'items-center', 'justify-start')}>
                                                            <div>
                                                                <CTagList
                                                                    horizontal
                                                                    items={
                                                                        customer.labels?.map((label) => ({
                                                                            className: clsx('text-xs'),
                                                                            style: {
                                                                                backgroundColor: label.bgColor,
                                                                                color: label.letterColor,
                                                                            },
                                                                            children: <>{label.name}</>,
                                                                        })) ?? []
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>{customer.assignees?.map((a) => a.assignedTo.name).join(',')}</div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>
                                                            {(
                                                                condition?.useType
                                                                    ?.map(
                                                                        (u) =>
                                                                            useTypes.find((useType) => useType.uuid === u)?.name,
                                                                    )
                                                                    .filter(
                                                                        (i): i is Exclude<typeof i, undefined> => i !== undefined,
                                                                    ) || []
                                                            ).join(',')}
                                                        </div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>
                                                            {(
                                                                condition?.area
                                                                    ?.map(
                                                                        (l) =>
                                                                            areaTypeItems.find((a) => a.value === l)?.label ??
                                                                            undefined,
                                                                    )
                                                                    .filter(
                                                                        (i): i is Exclude<typeof i, undefined> => i !== undefined,
                                                                    ) || []
                                                            ).join(',')}
                                                        </div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>
                                                            {(
                                                                condition?.prices
                                                                    ?.map((l) => {
                                                                        return (
                                                                            customerPriceRanges.find((v) => v.value === l)
                                                                                ?.label ?? undefined
                                                                        )
                                                                    })
                                                                    .filter(
                                                                        (i): i is Exclude<typeof i, undefined> => i !== undefined,
                                                                    ) || []
                                                            ).join(',')}
                                                        </div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>
                                                            {customerBuildingAgeTypeItems.find(
                                                                (v) => v.value === condition?.buildingAgeType,
                                                            )?.label ?? ''}
                                                        </div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>
                                                            {saleTimeTypeItems.find(
                                                                (v) => v.value === condition?.purchaseTimeType,
                                                            )?.label ?? ''}
                                                        </div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>
                                                            {customerGrossRateTypeItems.find(
                                                                (v) => v.value === condition?.grossRateType,
                                                            )?.label ?? ''}
                                                        </div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>
                                                            {customerNetRateTypeItems.find(
                                                                (v) => v.value === condition?.netRateType,
                                                            )?.label ?? ''}
                                                        </div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>
                                                            {customerSeismicStandardTypeItems.find(
                                                                (v) => v.value === condition?.seismicStandardType,
                                                            )?.label ?? ''}
                                                        </div>
                                                    </td>
                                                    <td className={clsx('pl-2')}>
                                                        <div>
                                                            {customerLegalComplianceTypeItems.find(
                                                                (v) => v.value === condition?.legalComplianceType,
                                                            )?.label ?? ''}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : null
                })
            }

            return (
                <>
                    <div className={clsx('text-gray-700', 'w-full')}>
                        <p>{t('CCustomerInfoCsvEdit.まだアップロードは完了していません。')}</p>
                        <p>{t('CCustomerInfoCsvEdit.アップロード準備ができた顧客リストを確認し、操作を選択してください。')}</p>
                    </div>
                    {createTables()}
                    <div className={clsx('flex', 'w-full', 'my-4')}>
                        <div className={clsx('flex-1', 'px-4')}>
                            <div className={clsx('w-full', 'flex', 'justify-center')}>
                                <CButton
                                    className={clsx('c-button-primary')}
                                    disabled={hasDuplicateRecord}
                                    onClick={() => addCustomers()}>
                                    <div>{t('CCustomerInfoCsvEdit.顧客リストを顧客に追加')}</div>
                                </CButton>
                            </div>
                            {!hasDuplicateRecord ? (
                                <div className={clsx('text-sm', 'my-2')}>
                                    <p>{t('CCustomerInfoCsvEdit.上記の顧客リストを顧客に追加します。 ')}</p>
                                    <p>{t('CCustomerInfoCsvEdit.すでに登録されている顧客は影響を受けません。')}</p>
                                </div>
                            ) : (
                                <div className={clsx('text-sm', 'my-2')}>
                                    <CMessage danger>
                                        <p>
                                            {t(
                                                'CCustomerInfoCsvEdit.すでに登録されている顧客がリストに含まれるため「顧客リストを顧客に追加」機能は使用できません。',
                                            )}
                                        </p>
                                    </CMessage>
                                </div>
                            )}
                        </div>
                        <div className={clsx('w-0.5', 'bg-gray-200')} />
                        <div className={clsx('flex-1', 'px-4')}>
                            <div className={clsx('w-full', 'flex', 'justify-center')}>
                                <CButton className={clsx('c-button-primary')} onClick={() => replaceCustomers()}>
                                    <div>{t('CCustomerInfoCsvEdit.顧客リストを顧客に上書き')}</div>
                                </CButton>
                            </div>
                            <div className={clsx('text-sm', 'my-2')}>
                                <p>{t('CCustomerInfoCsvEdit.上記の顧客リストを顧客に上書きします。')}</p>
                            </div>
                            <CMessage danger>
                                <p className={clsx('text-center', 'mb-1')}>{t('CCustomerInfoCsvEdit.！注意・警告！')}</p>
                                <p className={clsx('text-sm')}>
                                    {t(
                                        'CCustomerInfoCsvEdit.「顧客リストを顧客に上書き」を選択した場合、上記顧客リストが新たな顧客リストに差し替わります。',
                                    )}
                                </p>
                                <p className={clsx('text-sm')}>
                                    <Trans i18nKey="CCustomerInfoCsvEdit.上記顧客リストにない顧客は削除され、リストにある顧客もCSVの内容に上書きされてしまいますのでご注意ください。">
                                        上記顧客リストにない顧客は
                                        <span className={clsx('font-bold')}>削除</span>
                                        され、リストにある顧客もCSVの内容に
                                        <span className={clsx('font-bold')}>上書き</span>
                                        されてしまいますのでご注意ください。
                                    </Trans>
                                </p>
                                <p className={clsx('text-sm')}>
                                    {t(
                                        'CCustomerInfoCsvEdit.この操作は取り消しができないため、慎重にご判断のうえ実行してください。',
                                    )}
                                </p>
                                <p className={clsx('text-sm')}>
                                    {t('CCustomerInfoCsvEdit.なお、顧客が同一であるか否かは、メールアドレスで判別されています。')}
                                </p>
                            </CMessage>
                        </div>
                    </div>
                    <CError errors={errors} />
                </>
            )
        }
    }, [step, errors])

    return (
        <CFooterModal footer={buttons} visible={isShow} content={{ width: '900px' }} onRequestClose={() => onRequestClose()}>
            <div>
                <div className={clsx('pt-6', 'flex', 'justify-center')}>
                    <div className={clsx('text-kimar-primary', 'font-bold')}>
                        {t('CCustomerInfoCsvEdit.新しい顧客を一括登録')}
                    </div>
                </div>
                <div className={clsx('px-12', 'py-4')}>{stepper}</div>
                <div className={clsx('px-8', 'my-2')}>{stepNode}</div>
            </div>
        </CFooterModal>
    )
}

export type CCustomerInfoCsvEditProps = {
    isShow: boolean
    onRequestClose: () => void
}

export const CCustomerInfoCsvEdit: VFC<CCustomerInfoCsvEditProps> = ({ ...props }) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const userTeam = useAppSelector(selectLoginUserTeam)
    const { data: useTypeListCount } = useClientRealEstateUseTypeRealEstateUseTypeQuery({})

    const { data: labelListCount, isSuccess: isClientCustomerLabelTeamSuccess } = useClientCustomerLabelTeamUuidQuery(
        { uuid: userTeam?.uuid ?? '' },
        { skip: !userTeam },
    )
    const { data: teamMemberListCount } = useClientTeamMemberTeamUuidQuery(
        { teamUuid: userTeam?.uuid ?? '' },
        { skip: !userTeam },
    )

    const [step, setStep] = useState<1 | 2 | 3>(1)
    const [prev, next] = [
        () => setStep(step !== 1 ? ((step - 1) as 1 | 2) : step),
        () => setStep(step !== 3 ? ((step + 1) as 2 | 3) : step),
    ]
    const downloadTemplateCsv = () => {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style.display = 'none'
        a.href = '/src/assets/csv/address.csv'
        a.download = 'address.csv'
        a.click()
        URL.revokeObjectURL(a.href)
        a.remove()
    }

    const [targetCustomers, setTargetCustomers] = useState<CustomerType[]>([])
    const [targetFile, setTargetFile] = useState<FileEntities>()
    const [hasDuplicateRecord, setHasDuplicateRecord] = useState(false)
    const [preImportQuery] = useClientCustomerPreImportMutation()

    useEffect(() => {
        setErrors([])
    }, [step])

    useEffect(() => {
        setStep(1)
    }, [props.isShow])

    const setFile = async (file: FileEntities) => {
        setErrors([])
        try {
            const dto = await preImportQuery({ clientCustomerImportRequestDto: { fileUuid: file.uuid } }).unwrap()
            const setTarget = dto.map((c) => ({
                ...c,
                labels: c.labels
                    .split(',')
                    .filter((l) => l.trim() !== '')
                    .map((l) => (labelListCount?.list as CustomerLabelEntities[] | undefined)?.find((label) => label.name === l))
                    .filter((l) => !!l) as CustomerLabelEntities[],
                assignees: c.assignees
                    .split(',')
                    .filter((l) => l.trim() !== '')
                    .map((l) => {
                        const assignee: CustomerAssigneeEntities = {
                            uuid: '',
                            deletedAt: null,
                            createdAt: '',
                            updatedAt: '',
                            customer: {} as CustomerEntities,
                            assignedTo:
                                (teamMemberListCount?.list as TeamMemberEntities[] | undefined)?.find((tm) => tm.email === l) ||
                                ({} as TeamMemberEntities),
                            isPrimary: false,
                        }
                        return assignee
                    })
                    .filter((l) => !!l) as CustomerAssigneeEntities[],
                investmentConditions: (() => {
                    return [
                        {
                            useType:
                                c.conditionUseType
                                    ?.split(',')
                                    .filter((l) => l.trim() !== '')
                                    .map(
                                        (l) =>
                                            (useTypeListCount?.list as RealEstateUseTypeEntities[] | undefined)?.find(
                                                (a) => a.name === l,
                                            )?.uuid ?? undefined,
                                    )
                                    .filter((i): i is Exclude<typeof i, undefined> => i !== undefined) || [],
                            area:
                                c.conditionArea
                                    ?.split(',')
                                    .filter((l) => l.trim() !== '')
                                    .map((l) => areaTypeItems.find((a) => a.label === l)?.value ?? undefined)
                                    .filter((i): i is Exclude<typeof i, undefined> => i !== undefined) || [],
                            prices:
                                c.conditionPrices
                                    ?.split(',')
                                    .filter((l) => l.trim() !== '')
                                    .map((l) => {
                                        return customerPriceRanges.find((v) => v.label === l)?.value ?? undefined
                                    })
                                    .filter((i): i is Exclude<typeof i, undefined> => i !== undefined) || [],
                            buildingAgeType: (() => {
                                const val = c.conditionBuildingAgeType?.trim() ?? ''
                                return customerBuildingAgeTypeItems.find((v) => v.label === val)?.value ?? undefined
                            })(),
                            purchaseTimeType: (() => {
                                const val = c.conditionPurchaseTimeType?.trim() ?? ''
                                return saleTimeTypeItems.find((v) => v.label === val)?.value ?? undefined
                            })(),
                            grossRateType: (() => {
                                const val = c.conditionGrossRateType?.trim() ?? ''
                                return customerGrossRateTypeItems.find((v) => v.label === val)?.value ?? undefined
                            })(),
                            netRateType: (() => {
                                const val = c.conditionNetRateType?.trim() ?? ''
                                return customerNetRateTypeItems.find((v) => v.label === val)?.value ?? undefined
                            })(),
                            seismicStandardType: (() => {
                                const val = c.conditionSeismicStandardType?.trim() ?? ''
                                return customerSeismicStandardTypeItems.find((v) => v.label === val)?.value ?? undefined
                            })(),
                            legalComplianceType: (() => {
                                const val = c.conditionLegalComplianceType?.trim() ?? ''
                                return customerLegalComplianceTypeItems.find((v) => v.label === val)?.value ?? undefined
                            })(),
                        },
                    ] as CustomerInvestmentConditionEntities[]
                })(),
            }))
            setTargetCustomers(setTarget)
            setTargetFile(file)
            setHasDuplicateRecord(setTarget.some((c) => c.isDuplicate))
            next()
        } catch (error) {
            if (error instanceof ApplicationError)
                setErrors([new ApplicationError(t('CCustomerInfoCsvEdit.CSVファイルの形式を再度確認してください。'))])
            else setErrors([(error as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const [importQuery] = useClientCustomerImportMutation()
    const addCustomers = async () => {
        setErrors([])
        if (!targetFile) return
        try {
            await importQuery({ importType: 'insert', clientCustomerImportRequestDto: { fileUuid: targetFile.uuid } }).unwrap()
            setStep(1)
            toast.success(t('CCustomerInfoCsvEdit.CSVから顧客を追加しました'))
            props.onRequestClose()
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }
    const replaceCustomers = async () => {
        setErrors([])
        if (!targetFile) return
        try {
            await importQuery({ importType: 'update', clientCustomerImportRequestDto: { fileUuid: targetFile.uuid } }).unwrap()
            setStep(1)
            toast.success(t('CCustomerInfoCsvEdit.CSVから顧客を上書きしました'))
            props.onRequestClose()
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    if (!isClientCustomerLabelTeamSuccess || !useTypeListCount) return null

    return (
        <CCustomerInfoCsvEditView
            {...props}
            step={step}
            prev={prev}
            next={next}
            setFile={setFile}
            downloadTemplateCsv={downloadTemplateCsv}
            targetCustomers={targetCustomers}
            hasDuplicateRecord={hasDuplicateRecord}
            addCustomers={addCustomers}
            replaceCustomers={replaceCustomers}
            errors={errors}
            useTypes={useTypeListCount.list as RealEstateUseTypeEntities[]}
        />
    )
}
