export const MAX_PRICE_INTEGER = 1000000000000 // 1兆円

export const realestatePriceRanges = [
    { label: '〜1億', value: 1 },
    { label: '1億〜5億', value: 2 },
    { label: '5億〜10億', value: 3 },
    { label: '10億〜', value: 4 },
]

export const customerPriceRanges = [
    { label: '1億円未満', value: 1 },
    { label: '1億円以上5億円未満', value: 2 },
    { label: '5億円以上10億円未満', value: 3 },
    { label: '10億円以上50億円未満', value: 4 },
    { label: '50億円以上', value: 5 },
]

export const realestateGrossRateTypeItems = [
    { label: '〜2%', value: Number.MIN_SAFE_INTEGER, next: 2 },
    { label: '2%〜3%', value: 2, next: 3 },
    { label: '3%〜4%', value: 3, next: 4 },
    { label: '4%〜5%', value: 4, next: 5 },
    { label: '5%〜6%', value: 5, next: 6 },
    { label: '6%〜7%', value: 6, next: 7 },
    { label: '7%〜8%', value: 7, next: 8 },
    { label: '8%〜9%', value: 8, next: 9 },
    { label: '9%〜10%', value: 9, next: 10 },
    { label: '10%〜', value: 10, next: Number.MAX_SAFE_INTEGER },
]

export const customerGrossRateTypeItems = [
    { label: '4%以上', value: 40 },
    { label: '5%以上', value: 50 },
    { label: '6%以上', value: 60 },
    { label: '7%以上', value: 70 },
    { label: '問わない', value: 100 },
]

export const realestateNetRateTypeItems = [
    { label: '〜3%', value: 100 },
    { label: '3%〜4%', value: 30 },
    { label: '4%〜5%', value: 40 },
    { label: '5%〜6%', value: 50 },
    { label: '6%〜', value: 60 },
]
export const customerNetRateTypeItems = [
    { label: '3%以上', value: 30 },
    { label: '4%以上', value: 40 },
    { label: '5%以上', value: 50 },
    { label: '6%以上', value: 60 },
    { label: '問わない', value: 100 },
]

export const areaTypeItems = [
    { label: '東京23区', value: 101 },
    { label: '都心5区', value: 102 },
    { label: '北海道', value: 1 },
    { label: '青森県', value: 2 },
    { label: '岩手県', value: 3 },
    { label: '宮城県', value: 4 },
    { label: '秋田県', value: 5 },
    { label: '山形県', value: 6 },
    { label: '福島県', value: 7 },
    { label: '茨城県', value: 8 },
    { label: '栃木県', value: 9 },
    { label: '群馬県', value: 10 },
    { label: '埼玉県', value: 11 },
    { label: '千葉県', value: 12 },
    { label: '東京都', value: 13 },
    { label: '神奈川県', value: 14 },
    { label: '新潟県', value: 15 },
    { label: '富山県', value: 16 },
    { label: '石川県', value: 17 },
    { label: '福井県', value: 18 },
    { label: '山梨県', value: 19 },
    { label: '長野県', value: 20 },
    { label: '岐阜県', value: 21 },
    { label: '静岡県', value: 22 },
    { label: '愛知県', value: 23 },
    { label: '三重県', value: 24 },
    { label: '滋賀県', value: 25 },
    { label: '京都府', value: 26 },
    { label: '大阪府', value: 27 },
    { label: '兵庫県', value: 28 },
    { label: '奈良県', value: 29 },
    { label: '和歌山県', value: 30 },
    { label: '鳥取県', value: 31 },
    { label: '島根県', value: 32 },
    { label: '岡山県', value: 33 },
    { label: '広島県', value: 34 },
    { label: '山口県', value: 35 },
    { label: '徳島県', value: 36 },
    { label: '香川県', value: 37 },
    { label: '愛媛県', value: 38 },
    { label: '高知県', value: 39 },
    { label: '福岡県', value: 40 },
    { label: '佐賀県', value: 41 },
    { label: '長崎県', value: 42 },
    { label: '熊本県', value: 43 },
    { label: '大分県', value: 44 },
    { label: '宮崎県', value: 45 },
    { label: '鹿児島県', value: 46 },
    { label: '沖縄県', value: 47 },
]

export const areaTypeWithOutWordsItems = [
    { label: '北海道', value: 1 },
    { label: '青森県', value: 2 },
    { label: '岩手県', value: 3 },
    { label: '宮城県', value: 4 },
    { label: '秋田県', value: 5 },
    { label: '山形県', value: 6 },
    { label: '福島県', value: 7 },
    { label: '茨城県', value: 8 },
    { label: '栃木県', value: 9 },
    { label: '群馬県', value: 10 },
    { label: '埼玉県', value: 11 },
    { label: '千葉県', value: 12 },
    { label: '東京都', value: 13 },
    { label: '神奈川県', value: 14 },
    { label: '新潟県', value: 15 },
    { label: '富山県', value: 16 },
    { label: '石川県', value: 17 },
    { label: '福井県', value: 18 },
    { label: '山梨県', value: 19 },
    { label: '長野県', value: 20 },
    { label: '岐阜県', value: 21 },
    { label: '静岡県', value: 22 },
    { label: '愛知県', value: 23 },
    { label: '三重県', value: 24 },
    { label: '滋賀県', value: 25 },
    { label: '京都府', value: 26 },
    { label: '大阪府', value: 27 },
    { label: '兵庫県', value: 28 },
    { label: '奈良県', value: 29 },
    { label: '和歌山県', value: 30 },
    { label: '鳥取県', value: 31 },
    { label: '島根県', value: 32 },
    { label: '岡山県', value: 33 },
    { label: '広島県', value: 34 },
    { label: '山口県', value: 35 },
    { label: '徳島県', value: 36 },
    { label: '香川県', value: 37 },
    { label: '愛媛県', value: 38 },
    { label: '高知県', value: 39 },
    { label: '福岡県', value: 40 },
    { label: '佐賀県', value: 41 },
    { label: '長崎県', value: 42 },
    { label: '熊本県', value: 43 },
    { label: '大分県', value: 44 },
    { label: '宮崎県', value: 45 },
    { label: '鹿児島県', value: 46 },
    { label: '沖縄県', value: 47 },
]
export const tokyo23WardsAreaTypeItems = [
    { label: '千代田区', value: 1 },
    { label: '中央区', value: 2 },
    { label: '港区', value: 3 },
    { label: '新宿区', value: 4 },
    { label: '文京区', value: 5 },
    { label: '台東区', value: 6 },
    { label: '墨田区', value: 7 },
    { label: '江東区', value: 8 },
    { label: '品川区', value: 9 },
    { label: '目黒区', value: 10 },
    { label: '大田区', value: 11 },
    { label: '世田谷区', value: 12 },
    { label: '渋谷区', value: 13 },
    { label: '中野区', value: 14 },
    { label: '杉並区', value: 15 },
    { label: '豊島区', value: 16 },
    { label: '北区', value: 17 },
    { label: '荒川区', value: 18 },
    { label: '板橋区', value: 19 },
    { label: '練馬区', value: 20 },
    { label: '足立区', value: 21 },
    { label: '葛飾区', value: 22 },
    { label: '江戸川区', value: 23 },
]

export const tokyo5WardsAreaTypeItems = [
    { label: '千代田区', value: 1 },
    { label: '中央区', value: 2 },
    { label: '港区', value: 3 },
    { label: '新宿区', value: 4 },
    { label: '渋谷区', value: 13 },
]
export const tokyo23WithOut5WardsAreaTypeItems = [
    { label: '文京区', value: 5 },
    { label: '台東区', value: 6 },
    { label: '墨田区', value: 7 },
    { label: '江東区', value: 8 },
    { label: '品川区', value: 9 },
    { label: '目黒区', value: 10 },
    { label: '大田区', value: 11 },
    { label: '世田谷区', value: 12 },
    { label: '中野区', value: 14 },
    { label: '杉並区', value: 15 },
    { label: '豊島区', value: 16 },
    { label: '北区', value: 17 },
    { label: '荒川区', value: 18 },
    { label: '板橋区', value: 19 },
    { label: '練馬区', value: 20 },
    { label: '足立区', value: 21 },
    { label: '葛飾区', value: 22 },
    { label: '江戸川区', value: 23 },
]

export const saleTimeTypeItems = [
    { label: '極力早く', value: 1 },
    { label: '3ヶ月以内', value: 2 },
    { label: '半年以内', value: 3 },
    { label: '１年以内', value: 4 },
    { label: '条件が合えば', value: 10 },
]

export const realestateBuildingAgeTypeItems = [
    { label: '〜築5年', value: 1 },
    { label: '築6年〜10年', value: 2 },
    { label: '築11年〜20年', value: 3 },
    { label: '築21年〜', value: 10 },
]

export const customerBuildingAgeTypeItems = [
    { label: '5年以下', value: 1 },
    { label: '10年以下', value: 2 },
    { label: '20年以下', value: 3 },
    { label: '問わない', value: 10 },
]

export const realestateSeismicStandardTypeItems = [
    { label: '新耐震', value: 1 },
    { label: '旧耐震', value: 2 },
]

export const realestateSalesDestinationTypeItems = [
    { label: '売主', value: 1 },
    { label: '代理', value: 2 },
    { label: '元付', value: 3 },
    { label: '先1', value: 4 },
    { label: '先2', value: 5 },
    { label: 'その他', value: 100 },
]

export const customerSeismicStandardTypeItems = [
    { label: '新耐震', value: 1 },
    { label: '旧耐震', value: 2 },
    { label: '問わない', value: 10 },
]

export const realestateLegalComplianceTypeItems = [
    { label: '問題なし', value: 1 },
    { label: '問題あり', value: 2 },
]

export const customerLegalComplianceTypeItems = [
    { label: '問う', value: 1 },
    { label: '問わない', value: 10 },
]

export const voteOtherRoutes = [
    { label: '取得済み', value: 1 },
    { label: '初取得', value: 0 },
    { label: '未回答', value: 10 },
]

export const voteInterests = [
    { label: 'あり', value: 1 },
    { label: 'なし', value: 0 },
    { label: '未回答', value: 10 },
]

export const acquiredRoutes = [
    { label: '直接紹介', value: 0 },
    { label: 'DB紹介', value: 1 },
]

export const openIntermediaries = [
    { label: '開封済み', value: 0 },
    { label: '未開封', value: 1 },
]

export const structureTypeItems = [
    { label: '木造', value: 1 },
    { label: '軽量鉄骨造', value: 2 },
    { label: 'S(鉄骨造)', value: 3 },
    { label: 'RC(鉄筋コンクリート造)', value: 4 },
    { label: 'SRC(鉄骨鉄筋コンクリート造)', value: 5 },
]

export const zoningTypeItems = [
    { label: '第一種低層住居専用地域', value: 1 },
    { label: '第二種低層住居専用地域', value: 2 },
    { label: '第一種中高層住居専用地域', value: 3 },
    { label: '第二種中高層住居専用地域', value: 4 },
    { label: '第一種住居地域', value: 5 },
    { label: '第二種住居地域', value: 6 },
    { label: '田園住居地域', value: 14 },
    { label: '準住居地域', value: 7 },
    { label: '近隣商業地域', value: 8 },
    { label: '商業地域', value: 9 },
    { label: '準工業地域', value: 10 },
    { label: '工業地域', value: 11 },
    { label: '工業専用地域', value: 12 },
    { label: '指定のない区域', value: 13 },
]

export const searchPriceRanges = [
    { label: '5000万まで', value: 0, next: 50000000 },
    { label: '5000万〜1億', value: 50000000, next: 100000000 },
    { label: '1億〜5億', value: 100000000, next: 500000000 },
    { label: '5億〜10億', value: 500000000, next: 1000000000 },
    { label: '10億以上', value: 1000000000, next: Number.MAX_SAFE_INTEGER },
]

export const realEstatePriceRanges = [
    { label: '5000万まで', value: 0, next: 50000000 },
    { label: '5000万〜1億', value: 50000000, next: 100000000 },
    { label: '1億〜5億', value: 100000000, next: 500000000 },
    { label: '5億〜10億', value: 500000000, next: 1000000000 },
    { label: '10億〜50億', value: 1000000000, next: 5000000000 },
    { label: '50億以上', value: 5000000000, next: Number.MAX_SAFE_INTEGER },
]

export const ageRanges = [
    { label: '完成前', value: -9999, next: 0 },
    { label: '新築', value: 0, next: 1 },
    { label: '1〜10年', value: 1, next: 10 },
    { label: '11年〜20年', value: 10, next: 20 },
    { label: '21年〜30年', value: 20, next: 30 },
    { label: '31年〜40年', value: 30, next: 40 },
    { label: '41年〜50年', value: 40, next: 50 },
    { label: '50年以上', value: 50, next: 9999 },
]

export const progressItems = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
]

export const progressItemsSearch = [
    { label: '▷▷▷▷▷', value: 0 },
    { label: '▶︎▷▷▷▷', value: 1 },
    { label: '▶︎▶︎▷▷▷', value: 2 },
    { label: '▶︎▶︎▶︎▷▷', value: 3 },
    { label: '▶︎▶︎▶︎▶︎▷', value: 4 },
    { label: '▶︎▶︎▶︎▶︎▶︎', value: 5 },
]

// 測量図
export const surveyDrawingItems = [
    { label: '地積', value: 1 },
    { label: '現況', value: 2 },
    { label: '確定', value: 3 },
    { label: 'なし', value: 0 },
]

// 確認済証
export const certificateOfCompletionOfConfirmationItems = [
    { label: 'あり', value: 1 },
    { label: 'あり(台帳)', value: 2 },
    { label: 'なし', value: 0 },
]

// 検査済証
export const certificateOfFinalInspectionItems = [
    { label: 'あり', value: 1 },
    { label: 'あり(台帳)', value: 2 },
    { label: 'なし', value: 0 },
]

// 都市計画
export const cityPlanningAreaItems = [
    { label: '市街化区域', value: 1 },
    { label: '市街化調整区域', value: 2 },
    { label: 'その他', value: 100 },
]

// 防火地域
export const firePreventionDistrictsItems = [
    { label: '防火地域', value: 1 },
    { label: '準防火地域', value: 2 },
    { label: 'ー', value: 100 },
]

// 取引態様
export const modeOfTransactionItems = [
    { label: '売主', value: 1 },
    { label: '売主代理', value: 2 },
    { label: '専属専任', value: 3 },
    { label: '専任', value: 4 },
    { label: '媒介', value: 5 },
]

// 先数
export const salesDestinationTypeItems = [
    { label: '売主', value: 1 },
    { label: '代理', value: 2 },
    { label: '元付', value: 3 },
    { label: '先1', value: 4 },
    { label: '先2', value: 5 },
    { label: 'その他', value: 100 },
]

export const customerRegisteredTypeItems = [
    { label: '新規登録', value: 1 },
    // { label: 'CSV登録', value: 2 },
    // { label: '物件紹介経由', value: 3 },
    // { label: '買いニーズ紹介経由', value: 4 },
    { label: 'マーケット経由', value: 5 },
    { label: 'コネクション経由', value: 6 },
]

export const companyRoles = [
    { label: '閲覧不可', value: 0 },
    { label: '閲覧のみ', value: 1 },
    { label: 'すべて可', value: 2 },
]
export const teamRoles = [
    { label: '閲覧不可', value: 0 },
    { label: '閲覧のみ', value: 1 },
    { label: 'すべて可', value: 2 },
]
export const realestateRoles = [
    { label: '閲覧のみ', value: 1 },
    { label: 'すべて可', value: 2 },
]
export const customerRoles = [
    { label: '閲覧のみ', value: 1 },
    { label: 'すべて可', value: 2 },
]
export const intermediaryRoles = [
    { label: '紹介不可', value: 1 },
    { label: '紹介可', value: 2 },
    // { label: '１社のみ紹介可', value: 3 },
]

export const otherRouteList = [
    { label: '初取得', value: 0 },
    { label: '取得済み', value: 1 },
]
export const interestList = [
    { label: 'あり', value: 1 },
    { label: 'なし', value: 0 },
]

export const RealEstateSendStatus = [
    { label: '紹介したことがない', value: 0 },
    { label: '紹介している', value: 1 },
    { label: '紹介していた', value: 2 },
]

export const RealEstateReceiveStatus = [
    { label: '紹介されたことがない', value: 0 },
    { label: '紹介されている', value: 1 },
    { label: '紹介されていた', value: 2 },
]

export const realestateSortRules = [
    { label: '追加日', value: 'createdAtAsc' },
    { label: '更新日', value: 'updatedAtAsc' },
    { label: '情報取得日', value: 'acquiredAtAsc' },
    { label: '紹介先アクセス順', value: 'accessAtAsc' },
    { label: '物件価格順', value: 'internalPriceAsc' },
    { label: '提示金額', value: 'externalPriceAsc' },
]

export const marketRealestateSortRules = [
    { label: '掲載日', value: 'registeredAtAsc' },
    { label: '更新日', value: 'updatedAtAsc' },
]

export const customerSortRules = [
    { label: '氏名', value: 'nameAsc' },
    { label: '会社名', value: 'compunknownNameAsc' },
]

export const recommendSortRules = [
    { label: 'レコメンド日', value: 'executionAtAsc' },
    { label: 'マッチ度', value: 'orderScoreAsc' },
]

export const realEstateOwnershipTypeItems = [
    { label: '完全所有権', value: 1 },
    { label: '区分所有・共有', value: 2 },
]

export const customerOwnershipTypeItems = [
    { label: '完全所有権', value: 1 },
    { label: '区分所有・共有', value: 2 },
    { label: '問わない', value: 10 },
]

export const inquiryStatusItems = [
    { label: '未対応', value: 0 },
    { label: '保留中', value: 1 },
    { label: '対応済み', value: 10 },
    { label: '対応不要', value: 99 },
]

export const downloadTriggerItems = [
    { label: '', value: 0 },
    { label: '他社からの物件紹介', value: 1 },
    { label: 'インターネット記事', value: 2 },
    { label: 'インターネット広告', value: 3 },
    { label: '新聞・雑誌記事', value: 4 },
    { label: 'Web検索', value: 5 },
    { label: '展示会', value: 6 },
    { label: 'イベント・セミナー', value: 7 },
    { label: '社内紹介・引き継ぎ', value: 8 },
    { label: 'リマールエステート関係者紹介', value: 9 },
    { label: '友人・知人の紹介', value: 10 },
    { label: 'その他', value: 11 },
]

export const RealEstateOptions = {
    IS_DUPLICATE: 1 << 0, // 重複
} as const

export const taxType = [
    { label: '税込', value: 1 },
    { label: '税抜', value: 2 },
]
