import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { useNavigate, useParams } from 'react-router-dom'

import {
    AdminNotificationTemplateUpdateDto,
    useAdminNotificationTemplateDeleteUuidMutation,
    useAdminNotificationTemplateGetNotificationTemplateByUuidQuery,
    useAdminNotificationTemplatePutUuidMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CModal } from '~/components/common/cModal/CModal'
import CNotificationTemplateDetailForm from '~/components/unique/admin/template/CNotificationTemplateDetailForm'
import { ApplicationError, IApplicationError } from '~/types/error'
import { pageTitleTemplate } from '~/util/common/common'

const AdminNotificationTemplateDetail = () => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])

    const navigate = useNavigate()
    const params = useParams()
    const { notificationTemplateUuid } = params
    // 通知テンプレート初期状態
    const emptyTemplate: AdminNotificationTemplateUpdateDto = {
        uuid: '',
        key: '',
        name: '',
        mailSubject: '',
        mailHtmlBody: '',
        mailHtmlButtonTitle: '',
        mailHtmlAfterMessage: '',
        body: '',
    }
    // 表示しているテンプレート
    const [template, setTemplate] = useState<AdminNotificationTemplateUpdateDto>(emptyTemplate)
    // 編集API
    const [putAPI] = useAdminNotificationTemplatePutUuidMutation()
    // 編集成功時に立つフラグ
    const [successFlag, setSuccessFlag] = useState(false)
    // 削除確認モーダルvisible
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
    // 削除API
    const [deleteAPI] = useAdminNotificationTemplateDeleteUuidMutation()

    // 通知テンプレートの内容のGET
    if (!notificationTemplateUuid) return <CMessage>通知テンプレートが見つかりませんでした</CMessage>
    const { data: originalTemplate, isLoading: isLoadingOriginalTemplate } =
        useAdminNotificationTemplateGetNotificationTemplateByUuidQuery(
            { uuid: notificationTemplateUuid },
            { skip: visibleDeleteModal },
        )

    useMemo(() => {
        if (originalTemplate) {
            const templateUpdateDto: AdminNotificationTemplateUpdateDto = {
                uuid: originalTemplate.uuid,
                key: originalTemplate.key,
                name: originalTemplate.name,
                mailSubject: originalTemplate.mailSubject,
                mailHtmlBody: originalTemplate.mailHtmlBody,
                mailHtmlButtonTitle: originalTemplate.mailHtmlButtonTitle,
                mailHtmlAfterMessage: originalTemplate.mailHtmlAfterMessage,
                body: originalTemplate.body,
            }
            setTemplate(templateUpdateDto)
        }
    }, [originalTemplate])

    const confirmButtonHandler = async () => {
        try {
            if (!template) throw new ApplicationError('通知テンプレートの取得に失敗しました')
            await putAPI({ uuid: template.uuid, adminNotificationTemplateUpdateDto: template }).unwrap()
            setSuccessFlag(true)
            toast.success('通知テンプレートを編集しました')
            setTimeout(() => {
                setSuccessFlag(false)
            }, 3000)
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }
    const deleteButtonHandler = () => {
        setVisibleDeleteModal(true)
    }
    // 削除実行
    const deleteTemplate = async () => {
        try {
            await deleteAPI({ uuid: template.uuid }).unwrap()
            toast.success('通知テンプレートを削除しました')
            // テンプレート一覧に遷移
            navigate('/admin/template')
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleDeleteModal(false)
        }
    }

    return (
        <>
            <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3')}>
                <Helmet titleTemplate={pageTitleTemplate(true)}>
                    <title>通知テンプレート詳細</title>
                </Helmet>
                <CError errors={errors} />
                {successFlag && <CMessage info>保存に成功しました。</CMessage>}
                <CHeader label="通知テンプレート詳細" />
                <div>
                    <div className={clsx('flex', 'justify-end', 'my-2')}>
                        <CButton className={clsx('c-button-danger')} onClick={deleteButtonHandler}>
                            テンプレートを削除
                        </CButton>
                    </div>
                    <div className={clsx('w-full', 'p-4', 'flex', 'flex-col', 'gap-y-3', 'bg-white')}>
                        {isLoadingOriginalTemplate ? (
                            <CMessage info>読み込み中です...</CMessage>
                        ) : (
                            <CNotificationTemplateDetailForm
                                template={template}
                                changeHandler={(changedTemplate) => {
                                    setTemplate(changedTemplate)
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className={clsx('bg-gray-100', 'w-full', 'p-4', 'flex', 'justify-center')}>
                    <CButton className={clsx('c-button-primary')} onClick={confirmButtonHandler}>
                        保存
                    </CButton>
                </div>
            </div>
            {/* 削除確認モーダル */}
            <CModal visible={visibleDeleteModal} onRequestClose={() => setVisibleDeleteModal(false)}>
                <div className={clsx('p-2')}>
                    <div>テンプレートを削除します。よろしいですか？</div>
                    <div className={clsx('flex', 'm-4', 'justify-center')}>
                        <CButton className={clsx('c-button-secondary')} onClick={() => setVisibleDeleteModal(false)}>
                            キャンセル
                        </CButton>
                        <CButton className={clsx('c-button-danger')} onClick={deleteTemplate}>
                            削除
                        </CButton>
                    </div>
                </div>
            </CModal>
        </>
    )
}

export default AdminNotificationTemplateDetail
