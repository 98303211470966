import { GoogleMap } from '@react-google-maps/api'
import clsx from 'clsx'
import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast/headless'

import {
    RealEstateEntities,
    useClientFileGetPreSignedUrlMutation,
    useClientFileSetFileSizeMutation,
    useClientRealEstateDocumentPostRealEstateDocumentMutation,
} from '~/app/api'
import CMessage from '~/components/common/cMessage/CMessage'

import { CButton } from '../../common/cButton/CButton'
import { CFooterModal } from '../../common/cModal/CModal'

type Props = {
    visible: boolean
    realEstate: RealEstateEntities | undefined
    closed: () => void
}

const CStreetViewEdit = ({ ...props }: Props) => {
    const [visible, setVisible] = useState(props.visible)
    useEffect(() => {
        setVisible(props.visible)
    }, [props.visible])
    const [showPageIndex, setShowPageIndex] = useState(0)

    const setRelEstateImage = async () => {
        await getBlobToApi()
        closeModal()
        toast.success(t('CStreetViewEdit.ストリートビュー画像を物件資料に追加しました'))
    }

    const isTopImage = true

    const closeModal = () => {
        setShowPageIndex(0)
        props.closed()
    }

    const containerStyle = {
        height: '300px',
        width: '300px',
    }

    const [latLng, setLatLng] = useState<google.maps.LatLngLiteral>()

    useEffect(() => {
        setLatLng({
            lat: props.realEstate?.latitude ?? 35.681236,
            lng: props.realEstate?.longitude ?? 139.767125,
        })
    }, [props.realEstate])

    const panoramaRef = useCallback((element: HTMLDivElement | null) => {
        if (element != null) setPanoramaElement(element)
    }, [])

    const [panoramaElement, setPanoramaElement] = useState<HTMLDivElement>()

    useEffect(() => {
        if (latLng && panoramaElement) {
            setPanorama(
                new google.maps.StreetViewPanorama(panoramaElement, {
                    visible: true,
                    position: panorama?.getPosition() ?? latLng,
                    pov: panorama
                        ? panorama.getPov()
                        : {
                              heading: 270,
                              pitch: 0,
                          },
                    motionTracking: true,
                    zoomControl: true,
                    addressControlOptions: {
                        position: google.maps.ControlPosition.TOP_LEFT,
                    },
                }),
            )
        }
    }, [latLng, panoramaElement])

    const [panorama, setPanorama] = useState<google.maps.StreetViewPanorama>()

    const getBlobToApi = async () => {
        if (!panorama) return
        const position = panorama.getPosition()
        if (!position) return
        const fov = 180 / 2 ** panorama.getZoom()
        const src = `https://maps.googleapis.com/maps/api/streetview?size=300x300&heading=${
            panorama.getPov().heading
        }&fov=${fov}&pitch=${panorama.getPov().pitch}&location=${position.lat()},${position.lng()}&sensor=false&key=${
            import.meta.env.VITE_GOOGLE_MAPS_API_KEY
        }`
        const response = await fetch(src)
        const blob = await response.blob()
        if (!blob) return

        // スクリーンショットを資料としてアップロード
        await uploadScreenShot(blob, '物件画像.png')
    }

    const [fileUploadUrlQuery] = useClientFileGetPreSignedUrlMutation()
    const [setFileSizeQuery] = useClientFileSetFileSizeMutation()
    const [insertDocumentQuery] = useClientRealEstateDocumentPostRealEstateDocumentMutation()
    const uploadScreenShot = async (image: Blob, fileName: string) => {
        if (!props.realEstate) return
        try {
            const response = await fileUploadUrlQuery({
                mediaFileUploadDto: {
                    originalFilename: fileName,
                    mimetype: 'image/png',
                    tag: 'thumbnail=true',
                },
            }).unwrap()

            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/png',
                    'x-amz-tagging': 'thumbnail=true',
                },
                body: image,
            }
            //Perform the upload
            await fetch(response.url, requestOptions)

            await setFileSizeQuery({
                mediaFileUploadSetSizeDto: {
                    uuid: response.fileEntity.uuid,
                },
            }).unwrap()

            await insertDocumentQuery({
                clientRealEstateDocumentInsertDto: {
                    document: response.fileEntity,
                    realEstate: props.realEstate,
                    isCover: isTopImage,
                    isMap: false,
                },
            })
        } catch {
            toast.error(t('CFileUpload.ファイルアップロードに失敗しました'))
        }
    }

    if (!props.realEstate) return null

    const footer = (
        <>
            <CButton className={clsx('c-button-secondary')} onClick={() => closeModal()}>
                {t('Button.キャンセル')}
            </CButton>
            <CButton
                className={clsx('c-button-primary')}
                onClick={() => {
                    setRelEstateImage()
                }}>
                {t('CStreetViewEdit.画像確定')}
            </CButton>
        </>
    )

    return (
        <CFooterModal footer={footer} visible={visible} onRequestClose={closeModal} content={{ width: '70%' }}>
            <div>
                <div className={clsx('p-2', 'w-full', 'flex', 'justify-center')}>
                    <div className={clsx('text-kimar-primary', 'text-lg', 'font-bold')}>
                        {t('CStreetViewEdit.ストリートビューからトップ画像を作成')}
                    </div>
                </div>
                {showPageIndex === 0 && (
                    <>
                        <div className={clsx('m-2')}>
                            <CMessage info>
                                {t('CStreetViewEdit.左のマップ上で、人形のアイコンをドラッグし、青い線の上に置きます。')}
                                <br />
                                {t('CStreetViewEdit.右のストリートビューでトップ画像に設定する外観を調整します。')}
                            </CMessage>
                        </div>

                        <div className={clsx('grid', 'grid-cols-2', 'gap-2', 'm-4')}>
                            <div className={clsx('flex', 'justify-end')}>
                                {!!panorama && (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={{
                                            lat: props.realEstate?.latitude ?? 35.681236,
                                            lng: props.realEstate?.longitude ?? 139.767125,
                                        }}
                                        zoom={17}
                                        streetView={panorama}
                                    />
                                )}
                            </div>
                            <div className={clsx('flex', 'justify-start')}>
                                <div className={clsx('flex', 'flex-col', 'items-center')}>
                                    <div>
                                        <div ref={panoramaRef} className={clsx('w-[300px]', 'h-[300px]')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </CFooterModal>
    )
}

export default CStreetViewEdit
