import clsx from 'clsx'
import { t } from 'i18next'
import { min } from 'lodash'
import { useMemo, VFC } from 'react'
import ReactPaginate from 'react-paginate'

export type CPagerViewProps = {
    first: boolean
    last: boolean
    from: number
    to: number
    onPageChange: (page: number) => void
} & CPagerProps

export const CPagerView: VFC<CPagerViewProps> = ({ page, total, pageSize, from, to, first, last, onPageChange }) => {
    const containerClass = clsx('flex', 'justify-center', 'items-center')
    const activeClass = clsx('bg-kimar-primary', 'text-white')
    const inActiveClass = clsx('cursor-pointer', 'inline-flex', 'items-center', 'text-sm', 'font-medium', 'px-2')

    const firstLastClass = clsx('px-2', 'h-7', 'inline-flex', 'items-center', 'text-sm', 'font-medium')
    return (
        <div
            className={clsx(
                'p-2',
                'flex',
                'flex-row',
                'flex-wrap',
                'justify-center',
                'gap-2',
                'items-center',
                'whitespace-nowrap',
            )}>
            <div className={clsx('flex', 'items-center', 'text-sm', 'font-medium', 'text-gray-700', 'h-7')}>
                {t('CPager.from - to 件 / total 件中', { from, to, total })}
            </div>
            <div className={clsx('flex')}>
                <div
                    className={clsx(
                        firstLastClass,
                        first ? ['cursor-pointer', 'text-gray-700', 'hover:text-gray-900'] : ['text-gray-300', 'cursor-default'],
                    )}
                    onClick={() => onPageChange(0)}>
                    {t('CPager.先頭')}
                </div>
                <ReactPaginate
                    forcePage={page - 1}
                    pageCount={Math.ceil(total / pageSize)}
                    pageClassName={clsx('rounded', 'mx-0.5', 'h-7')}
                    activeClassName={clsx(activeClass)}
                    nextClassName={inActiveClass}
                    previousClassName={inActiveClass}
                    containerClassName={containerClass}
                    pageLinkClassName={clsx(inActiveClass)}
                    previousLabel={
                        <>
                            <i className={clsx('text-sm', 'material-icons')}>arrow_back_ios_new</i>
                        </>
                    }
                    nextLabel={
                        <>
                            <i className={clsx('text-sm', 'material-icons')}>arrow_forward_ios</i>
                        </>
                    }
                    disabledLinkClassName={clsx('text-gray-300', 'cursor-default')}
                    onPageChange={(v) => onPageChange(v.selected)}
                />
                <div
                    className={clsx(
                        firstLastClass,
                        last ? ['cursor-pointer', 'text-gray-700', 'hover:text-gray-900'] : ['text-gray-300', 'cursor-default'],
                    )}
                    onClick={() => onPageChange(Math.ceil(total / pageSize) - 1)}>
                    {t('CPager.最後')}
                </div>
            </div>
        </div>
    )
}

export type CPagerProps = {
    total: number
    pageSize: number
    page: number
    onPageChanged: (page: number) => void
}

export const CPager: VFC<CPagerProps> = ({ ...props }) => {
    const from = useMemo(() => (props.page ? props.pageSize * (props.page - 1) || 1 : 0), [props])
    const to = useMemo(() => min([props.page * props.pageSize, props.total]) || 0, [props])
    const first = useMemo(() => from != 1, [props, from])
    const last = useMemo(() => to != props.total, [props, to])
    const onPageChange = (page: number) => props.onPageChanged(page + 1)
    return <CPagerView {...props} first={first} last={last} from={from} to={to} onPageChange={onPageChange} />
}
