import clsx from 'clsx'
import { t } from 'i18next'
import { CSSProperties, ReactNode, useState, VFC } from 'react'

import { AdminFaqUpdateDto } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'

type CFaqContentEditProps = {
    visible?: boolean
    faqProp: AdminFaqUpdateDto
    children?: ReactNode
    content?: CSSProperties
    confirmButtonHandler: (changedFaq: AdminFaqUpdateDto) => void
    cancelButtonHandler: () => void
}

const CFaqContentEdit: VFC<CFaqContentEditProps> = ({ ...props }) => {
    const [faq, setFaq] = useState(props.faqProp)

    const changeHandler = (value: string | boolean | number, target: keyof typeof faq) => {
        setFaq((old) => ({ ...old, [target]: value }))
    }

    return (
        <div className={clsx('w-full', 'h-full')}>
            <div className={clsx('px-8', 'py-2')}>
                <div className={clsx('text-center', 'text-kimar-primary', 'font-bold', 'p-2')}>
                    {faq?.uuid ? t('CFaqContentEdit.よくある質問を編集') : t('CFaqContentEdit.よくある質問を登録')}
                </div>
                <div>
                    <div className={clsx('flex', 'flex-col', 'gap-3', 'm-3', 'overflow-auto')}>
                        <CLabeledItem label={t('CFaqContentEdit.質問')} className={clsx('mx-2')}>
                            <CTextInput
                                type={'text'}
                                placeholder={t('CFaqContentEdit.入力してください')}
                                text={faq.question}
                                className={clsx('w-full', 'c-text-input-base')}
                                onChange={(value) => changeHandler(value, 'question')}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('CFaqContentEdit.回答')} className={clsx('mx-2')}>
                            <CTextInput
                                type={'multiline'}
                                placeholder={t('CFaqContentEdit.入力してください')}
                                text={faq.answer}
                                className={clsx('w-full', 'c-text-input-base')}
                                onChange={(value) => changeHandler(value, 'answer')}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('CFaqContentEdit.LPに表示する')} className={clsx('mx-2')}>
                            <div className={clsx('w-fit')}>
                                <CToggleSwitch
                                    width="lg"
                                    value={faq.isDisplayHome}
                                    trueLabel={t('CFaqContentEdit.はい')}
                                    falseLabel={t('CFaqContentEdit.いいえ')}
                                    toggleChanged={(value) => changeHandler(value, 'isDisplayHome')}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CFaqContentEdit.LPでの表示順')} className={clsx('mx-2')}>
                            <CTextInput
                                type={'number'}
                                className={clsx('w-full', 'c-text-input-base')}
                                text={faq.sort.toString()}
                                onChange={(value) => changeHandler(value, 'sort')}
                            />
                        </CLabeledItem>
                    </div>
                </div>
            </div>
            <div className={clsx('bg-gray-100', 'w-full', 'p-4', 'flex', 'justify-center', 'space-x-2')}>
                <CButton className={clsx('c-button-secondary')} onClick={props.cancelButtonHandler}>
                    {t('Button.キャンセル')}
                </CButton>
                <CButton className={clsx('c-button-primary')} onClick={() => props.confirmButtonHandler(faq)}>
                    {t('Button.送信')}
                </CButton>
            </div>
        </div>
    )
}

export default CFaqContentEdit
