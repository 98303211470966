import clsx from 'clsx'
import { t } from 'i18next'
import { useLocation } from 'react-router-dom'

import { CPdfViewer } from '~/components/common/cPdfViewer/CPdfViewer'

const FilePreview = () => {
    const search = useLocation().search
    const query = new URLSearchParams(search)
    const param = {
        fileName: query.get('fileName'),
        src: query.get('src') || '',
        mineType: query.get('mineType') || '',
    }

    const baseUrl = 'https://view.officeapps.live.com/op/view.aspx?src='

    const isImageFile = () => {
        if (param.mineType.match(/^image\//)) return true

        return false
    }

    const isPdfFile = () => {
        if (param.mineType.match(/pdf/)) return true
        return false
    }

    const isDirectOpenFile = () => {
        if (isPdfFile()) return true

        if (isImageFile()) return true

        if (param.mineType.match(/^text\//)) return true

        return false
    }

    const fileSrc = () => {
        if (isImageFile()) return param.src
        if (isPdfFile()) return param.src

        if (isDirectOpenFile()) return param.src

        return `${baseUrl}${param.src}`
    }

    const frameComponent = () => {
        if (isPdfFile()) return <CPdfViewer src={fileSrc()} />
        else if (isImageFile())
            return <iframe title="PreviewFrame" src={fileSrc()} className={clsx('w-full', 'h-screen', 'pt-[45px]')} />

        return <iframe title="PreviewFrame" src={fileSrc()} className={clsx('w-full', 'h-screen', 'mt-[-56px]', 'pt-[45px]')} />
    }

    return (
        <div className={clsx('w-full', 'h-full', 'font-body', 'text-gray-700', 'bg-gray-100')}>
            <div
                className={clsx(
                    'fixed',
                    'top-0',
                    'left-0',
                    'w-full',
                    'h-[45px]',
                    'bg-white',
                    'flex',
                    'items-center',
                    'border-t',
                    'border-b',
                    'border-kimar-primary',
                    'z-20',
                )}>
                <div className={clsx('text-kimar-primary', 'text-lg', 'mx-4', 'font-bold')}>{param.fileName}</div>
            </div>
            {frameComponent()}
            <a className={clsx('m-1', 'text-sm', 'fixed', 'bottom-0', 'right-0', 'c-button-secondary', 'z-20')} href={fileSrc()}>
                {t('FilePreview.プレビュー表示されない場合はこちら')}
            </a>
        </div>
    )
}
export default FilePreview
