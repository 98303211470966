import { clsx } from 'clsx'
import { t } from 'i18next'
import { cloneDeep, sortBy, uniq } from 'lodash'
import { ReactNode, useState, VFC } from 'react'

import { CButton } from '../cButton/CButton'

export type CSelectFromItemSetViewProps = {
    itemSetSelected: Array<string | number>
    selectedListSelected: Array<string | number>
    onClickItemSetItem: (key: string | number) => void
    onClickSelectedItem: (key: string | number) => void
    add: () => void
    remove: () => void
} & CSelectFromItemSetProps

export const CSelectFromItemSetView: VFC<CSelectFromItemSetViewProps> = ({
    sourceItems,
    selectedItems,
    itemSetHeader,
    selectedListHeader,
    getRowNode,
    itemSetSelected,
    selectedListSelected,
    onClickItemSetItem,
    onClickSelectedItem,
    add,
    remove,
}) => {
    return (
        <div className={clsx('flex', 'space-x-2')}>
            <div className={clsx('flex-1', 'border', 'border-gray-200')}>
                <div className={clsx('bg-kimar-primary', 'text-white', 'pl-2')}>{itemSetHeader}</div>
                <div className={clsx('max-h-80', 'overflow-y-auto')}>
                    {sortBy(sourceItems, (s) => (s.item as { sort: number }).sort).map((i, index) => (
                        <div
                            key={index}
                            className={clsx(
                                itemSetSelected.includes(i.key) ? 'bg-kimar-primary-light' : 'bg-white',
                                'cursor-pointer',
                                'pl-1',
                            )}
                            onClick={() => onClickItemSetItem(i.key)}>
                            {getRowNode(i.item)}
                        </div>
                    ))}
                </div>
            </div>
            <div className={clsx('flex', 'flex-col', 'justify-center', 'space-y-1')}>
                <CButton disabled={false} onClick={() => add()} className={clsx('c-button-primary', 'text-sm')}>
                    <i className={clsx('border-2', 'rounded-full', 'border-white', 'material-icons', 'text-[16px]')}>
                        arrow_forward
                    </i>
                    <span>{t('CSelectFromItemSet.追加')}</span>
                </CButton>
                <CButton disabled={false} onClick={() => remove()} className={clsx('c-button-secondary', 'text-sm')}>
                    <i
                        className={clsx(
                            'border-2',
                            'rounded-full',
                            'border-kimar-primary',
                            'text-kimar-primary',
                            'material-icons',
                            'text-[16px]',
                        )}>
                        arrow_back
                    </i>
                    <span>{t('CSelectFromItemSet.解除')}</span>
                </CButton>
            </div>
            <div className={clsx('flex-1', 'border', 'border-gray-200')}>
                <div className={clsx('bg-kimar-primary', 'text-white', 'pl-2')}>{selectedListHeader}</div>
                <div className={clsx('max-h-80', 'overflow-y-auto')}>
                    {sortBy(sourceItems, (s) => (s.item as { sort: number }).sort)
                        .filter((item) => selectedItems.includes(item.key))
                        .map((i, index) => {
                            return (
                                <div
                                    key={index}
                                    className={clsx(
                                        selectedListSelected.includes(i.key) ? 'bg-kimar-primary-light' : 'bg-white',
                                        'cursor-pointer',
                                        'pl-1',
                                    )}
                                    onClick={() => onClickSelectedItem(i.key)}>
                                    {getRowNode(i.item)}
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export type CSelectFromItemSetProps = {
    // データソース(左側に表示)
    sourceItems: Array<{
        item: unknown
        key: string | number
    }>
    getRowNode: (item: unknown) => ReactNode
    selectedItems: Array<string | number>
    itemSetHeader: string
    selectedListHeader: string
    onChange: (newSelectedItems: Array<string | number>) => void
}

export const CSelectFromItemSet: VFC<CSelectFromItemSetProps> = ({ ...props }) => {
    const [itemSetSelected, setItemSetSelected] = useState<(string | number)[]>([])
    const [selectedListSelected, setSelectedListSelected] = useState<(string | number)[]>([])

    const add = () => {
        const newItems = uniq(cloneDeep(props.selectedItems).concat(itemSetSelected))
        props.onChange(newItems)
        setItemSetSelected([])
        setSelectedListSelected([])
    }
    const remove = () => {
        const newItems = cloneDeep(props.selectedItems).filter((item) => !selectedListSelected.includes(item))
        props.onChange(newItems)
        setItemSetSelected([])
        setSelectedListSelected([])
    }

    const onClickItemSetItem = (key: string | number) => {
        if (itemSetSelected.includes(key)) setItemSetSelected(itemSetSelected.filter((i) => i !== key))
        else setItemSetSelected([...itemSetSelected, key])
    }
    const onClickSelectedItem = (key: string | number) => {
        if (selectedListSelected.includes(key)) setSelectedListSelected(selectedListSelected.filter((i) => i !== key))
        else setSelectedListSelected([...selectedListSelected, key])
    }

    return (
        <CSelectFromItemSetView
            {...props}
            itemSetSelected={itemSetSelected}
            selectedListSelected={selectedListSelected}
            add={add}
            remove={remove}
            onClickItemSetItem={onClickItemSetItem}
            onClickSelectedItem={onClickSelectedItem}
        />
    )
}
