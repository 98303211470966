/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'

import { RealEstateUseTypeEntities, useClientRealEstateUseTypeRealEstateUseTypeQuery } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBoxList } from '~/components/common/cCheckBoxList/CCheckBoxList'
import { CGuideTips } from '~/components/common/cGuideTips/CGuideTips'
import {
    areaTypeItems,
    realestateBuildingAgeTypeItems,
    realestateGrossRateTypeItems,
    realestateLegalComplianceTypeItems,
    realestateNetRateTypeItems,
    realestatePriceRanges,
    realestateSalesDestinationTypeItems,
    realestateSeismicStandardTypeItems,
    saleTimeTypeItems,
} from '~/types/enum/enum'
import { selectIsGuest } from '~/util/store/userSlice'

import { useAppSelector } from '../../../util/store/hooks'
import { CLabeledItem } from '../../common/cLabeledItem/CLabeledItem'

type Filter = {
    use: Array<string>
    area: Array<string>
    price: Array<string>
    age: Array<string>
    saleTime: Array<string>
    grossRate: Array<string>
    netRate: Array<string>
    seismicStandard: Array<string>
    legalCompliance: Array<string>
    salesDestination: Array<string>
    filter: Array<string>
    order: 'updatedAt' | 'publishedAt' | 'publishRequestCount' | undefined
    sort: 'ASC' | 'DESC'
}

type Props = {
    filterProp: Filter
    marginBottom?: boolean
    filterUpdate: (filter: Filter) => void
}

const CMarketRealestateSearch = ({ filterProp, filterUpdate }: Props) => {
    // ゲストかどうか
    const isGuest = useAppSelector(selectIsGuest)
    const [filter, setFilter] = useState(filterProp)
    const [detailSearchVisible, setDetailSearchVisible] = useState(false)
    const [isOpenDetail, setIsOpenDetail] = useState(false)
    // 種別
    const { data: useTypeListCount } = useClientRealEstateUseTypeRealEstateUseTypeQuery({ isNotSetOption: true })

    const listSelectedHandler = (key: keyof Filter, val: string[] | number[]) => {
        // 選択状態のアイテムだけfilterに入れる
        const newValue = {
            ...filter,
            [key]: val,
        }
        setFilter(newValue)
        filterUpdate(newValue)
    }

    const resetFilterCheckBox = () => {
        const newValue = {
            ...filter,
            filter: [],
        }
        setFilter(newValue)
        filterUpdate(newValue)
    }

    const resetDetail = () => {
        const newValue = {
            use: [] as Array<string>,
            area: [] as Array<string>,
            price: [] as Array<string>,
            age: [] as Array<string>,
            saleTime: [] as Array<string>,
            grossRate: [] as Array<string>,
            netRate: [] as Array<string>,
            seismicStandard: [] as Array<string>,
            legalCompliance: [] as Array<string>,
            salesDestination: [] as Array<string>,
            filter: filter.filter,
            order: 'publishedAt' as 'updatedAt' | 'publishedAt' | 'publishRequestCount' | undefined,
            sort: 'DESC' as 'ASC' | 'DESC',
        }
        setFilter(newValue)
        filterUpdate(newValue)
    }

    const closeAll = () => {
        setIsOpenDetail((v) => (v ? false : v))
        setDetailSearchVisible((v) => (v ? false : v))
    }

    const cMarketRealEstateSearchRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!cMarketRealEstateSearchRef) return
        const handleClickOutside = (e: MouseEvent) => {
            if (!(cMarketRealEstateSearchRef.current as Node)?.contains(e.target as Node)) closeAll()
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [cMarketRealEstateSearchRef])

    if (isGuest) return null

    return (
        <div ref={cMarketRealEstateSearchRef} className={clsx('w-full')}>
            <div className={clsx('flex', 'flex-col', 'sm:flex-row', 'justify-between', 'items-center')}>
                <div className={clsx('flex', 'flex-row', 'items-center')}>
                    <div className={clsx('mr-2')}>
                        <CGuideTips tooltipDirection="bottom-right">
                            {t(
                                'CMarketRealestateSearch.物件の作成・編集時に、「マーケット掲載設定」の「キマールマーケットに掲載しますか？」でYESを選択し、必須項目を入力して登録すると掲載されます。',
                            )}
                        </CGuideTips>
                    </div>
                    <div>{t('CMarketRealestateSearch.マーケットに物件を掲載するには？')}</div>
                </div>
                <div className={clsx('flex', 'gap-2')}>
                    <div className={clsx('relative')}>
                        <CButton
                            className={clsx(
                                'c-button-secondary',
                                detailSearchVisible ? 'rounded-b-none' : 'rounded',
                                'whitespace-nowrap',
                                'h-full',
                            )}
                            onClick={() => setIsOpenDetail((oldValue) => !oldValue)}>
                            <i className={clsx('material-icons-outlined', 'text-kimar-primary')}>filter_alt</i>
                            <div>{t('CMarketRealestateSearch.絞り込み')}</div>
                        </CButton>
                        <div
                            className={clsx(
                                'absolute',
                                'bg-white',
                                'p-2',
                                'top-[42px]',
                                'left-0',
                                'md:left-auto',
                                'md:right-0',
                                'z-20',
                                'shadow',
                                'rounded',
                                !isOpenDetail && 'hidden',
                                'min-w-[250px]',
                            )}>
                            <CCheckBoxList
                                items={[{ label: t('CMarketRealestateSearch.開示実績あり'), value: 'hasOpenHistory' }]}
                                checked={filter.filter}
                                onChange={(val) => listSelectedHandler('filter', val as string[])}
                            />
                            <CButton
                                className={clsx('c-button-secondary', 'w-full', 'mb-1')}
                                onClick={() => resetFilterCheckBox()}>
                                {t('CMarketRealestateSearch.絞り込みをリセット')}
                            </CButton>
                            <CButton className={clsx('c-button-primary', 'w-full')} onClick={() => setIsOpenDetail(false)}>
                                {t('CMarketRealestateSearch.絞り込みを閉じる')}
                            </CButton>
                        </div>
                    </div>
                    <CButton
                        className={clsx(
                            'c-button-primary',
                            detailSearchVisible ? 'rounded-b-none' : 'rounded',
                            'whitespace-nowrap',
                        )}
                        onClick={() => setDetailSearchVisible((oldValue) => !oldValue)}>
                        <div
                            className={clsx(
                                'w-3',
                                'h-3',
                                detailSearchVisible
                                    ? ['border-r-2', 'border-t-2', 'translate-y-1']
                                    : ['border-l-2', 'border-b-2', '-translate-y-1'],
                                'border-white',
                                '-rotate-45',
                                'm-2',
                                'transition',
                            )}
                        />
                        <div>{t('CCustomerSearch.詳細検索')}</div>
                    </CButton>
                </div>
            </div>
            {detailSearchVisible && (
                <div
                    className={clsx(
                        'sticky',
                        'top-0',
                        'z-10',
                        'w-full',
                        'p-2',
                        'font-body',
                        'border',
                        'border-kimar-primary',
                        'rounded-b-2xl',
                        'rounded-tl-2xl',
                        'bg-white',
                        'bg-opacity-70',
                        'backdrop-blur',
                    )}>
                    <div className={clsx('grid', 'grid-cols-2', 'md:grid-cols-5', 'gap-4')}>
                        <CLabeledItem label={t('CMarketRealestateSearch.種別')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={(useTypeListCount?.list as RealEstateUseTypeEntities[]) ?? []}
                                    dataLabel={'name'}
                                    dataValue={'uuid'}
                                    checked={filter.use}
                                    onChange={(val) => listSelectedHandler('use', val as string[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CMarketRealestateSearch.エリア')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={areaTypeItems as unknown as { [key: string]: unknown }[]}
                                    checked={filter.area}
                                    onChange={(val) => listSelectedHandler('area', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CMarketRealestateSearch.金額')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={realestatePriceRanges}
                                    checked={filter.price}
                                    onChange={(val) => listSelectedHandler('price', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CMarketRealestateSearch.築年数')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={realestateBuildingAgeTypeItems}
                                    checked={filter.age}
                                    onChange={(val) => listSelectedHandler('age', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CMarketRealestateSearch.売却時期')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={saleTimeTypeItems}
                                    checked={filter.saleTime}
                                    onChange={(val) => listSelectedHandler('saleTime', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CMarketRealestateSearch.表面利回り')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={realestateGrossRateTypeItems}
                                    checked={filter.grossRate}
                                    onChange={(val) => listSelectedHandler('grossRate', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CMarketRealestateSearch.実質利回り')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={realestateNetRateTypeItems}
                                    checked={filter.netRate}
                                    onChange={(val) => listSelectedHandler('netRate', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CMarketRealestateSearch.耐震基準')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={realestateSeismicStandardTypeItems}
                                    checked={filter.seismicStandard}
                                    onChange={(val) => listSelectedHandler('seismicStandard', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CMarketRealestateSearch.遵法性')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={realestateLegalComplianceTypeItems}
                                    checked={filter.legalCompliance}
                                    onChange={(val) => listSelectedHandler('legalCompliance', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CMarketRealestateSearch.先数')}>
                            <div className={clsx('border', 'rounded', 'bg-white', 'p-2', 'h-28', 'overflow-y-auto')}>
                                <CCheckBoxList
                                    items={realestateSalesDestinationTypeItems}
                                    checked={filter.salesDestination}
                                    onChange={(val) => listSelectedHandler('salesDestination', val as number[])}
                                />
                            </div>
                        </CLabeledItem>
                    </div>
                    <div className={clsx('grid', 'grid-cols-1', 'sm:grid-cols-2', 'gap-2', 'my-2')}>
                        <CButton className={clsx('c-button-secondary', 'w-full')} onClick={() => resetDetail()}>
                            {t('CMarketRealestateSearch.詳細検索をリセット')}
                        </CButton>
                        <CButton
                            className={clsx('c-button-primary', 'w-full')}
                            onClick={() => setDetailSearchVisible((oldValue) => !oldValue)}>
                            {t('CMarketRealestateSearch.詳細検索を閉じる')}
                        </CButton>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CMarketRealestateSearch
