import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { useNavigate, useParams } from 'react-router-dom'

import {
    AdminReportTemplateUpdateDto,
    useAdminReportTemplateDeleteUuidMutation,
    useAdminReportTemplateGetReportTemplateByUuidQuery,
    useAdminReportTemplateGetReportTemplateQuery,
    useAdminReportTemplatePutUuidMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CError } from '~/components/common/cError/CError'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CModal } from '~/components/common/cModal/CModal'
import CReportTemplateEdit from '~/components/unique/admin/template/CReportTemplateEdit'
import { ApplicationError, IApplicationError } from '~/types/error'
import { pageTitleTemplate } from '~/util/common/common'

const AdminReportTemplateDetail = () => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])

    const navigate = useNavigate()
    const params = useParams()
    const { reportTemplateUuid } = params
    // 帳票テンプレート初期状態
    const emptyTemplate: AdminReportTemplateUpdateDto = {
        uuid: '',
        category: '',
        displayName: '',
        file: undefined,
    }
    // 表示しているテンプレート
    const [template, setTemplate] = useState<AdminReportTemplateUpdateDto>(emptyTemplate)
    // テンプレリスト
    const { data: templates } = useAdminReportTemplateGetReportTemplateQuery({})
    // 編集API
    const [putAPI] = useAdminReportTemplatePutUuidMutation()
    // 編集成功時に立つフラグ
    const [successFlag, setSuccessFlag] = useState(false)
    // 削除確認モーダルvisible
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
    // 削除API
    const [deleteAPI] = useAdminReportTemplateDeleteUuidMutation()

    // 帳票テンプレートの内容のGET
    if (!reportTemplateUuid) return <CMessage>帳票テンプレートが見つかりませんでした</CMessage>
    const { data: originalTemplate, isLoading: isLoadingOriginalTemplate } = useAdminReportTemplateGetReportTemplateByUuidQuery(
        { uuid: reportTemplateUuid },
        { skip: visibleDeleteModal },
    )

    useMemo(() => {
        if (originalTemplate) {
            const templateUpdateDto: AdminReportTemplateUpdateDto = {
                uuid: originalTemplate.uuid,
                category: originalTemplate.category,
                displayName: originalTemplate.displayName,
                file: originalTemplate.file,
            }
            setTemplate(templateUpdateDto)
        }
    }, [originalTemplate])

    const confirmButtonHandler = async (changedTemplate: AdminReportTemplateUpdateDto) => {
        setTemplate(changedTemplate)
        try {
            await putAPI({ uuid: changedTemplate.uuid, adminReportTemplateUpdateDto: changedTemplate }).unwrap()
            setSuccessFlag(true)
            toast.success('帳票テンプレートを編集しました')
            setTimeout(() => {
                setSuccessFlag(false)
            }, 3000)
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }
    const deleteButtonHandler = () => {
        if (templates?.list.filter((t) => t.category == template.category).length === 1) {
            alert('削除するとそのカテゴリのテンプレートが0件となるため、消すことができません。')
            return
        }
        setVisibleDeleteModal(true)
    }
    // 削除実行
    const deleteTemplate = async () => {
        try {
            await deleteAPI({ uuid: template.uuid }).unwrap()
            toast.success('帳票テンプレートを削除しました')
            // テンプレート一覧に遷移
            navigate('/admin/template')
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        } finally {
            setVisibleDeleteModal(false)
        }
    }

    return (
        <>
            <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3')}>
                <Helmet titleTemplate={pageTitleTemplate(true)}>
                    <title>帳票テンプレート詳細</title>
                </Helmet>
                <CError errors={errors} />
                {successFlag && <CMessage info>保存に成功しました。</CMessage>}
                <CHeader label="帳票テンプレート詳細" />
                <div>
                    <div className={clsx('flex', 'justify-end', 'my-2')}>
                        <CButton className={clsx('c-button-danger')} onClick={deleteButtonHandler}>
                            テンプレートを削除
                        </CButton>
                    </div>
                    <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-3', 'bg-white')}>
                        {isLoadingOriginalTemplate ? (
                            <CMessage info>読み込み中です...</CMessage>
                        ) : (
                            <CReportTemplateEdit
                                template={template}
                                confirmButtonHandler={(changedTemplate) =>
                                    confirmButtonHandler(changedTemplate as AdminReportTemplateUpdateDto)
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* 削除確認モーダル */}
            <CModal visible={visibleDeleteModal} onRequestClose={() => setVisibleDeleteModal(false)}>
                <div className={clsx('p-2')}>
                    <div>テンプレートを削除します。よろしいですか？</div>
                    <div className={clsx('flex', 'm-4', 'justify-center')}>
                        <CButton className={clsx('c-button-secondary')} onClick={() => setVisibleDeleteModal(false)}>
                            キャンセル
                        </CButton>
                        <CButton className={clsx('c-button-danger')} onClick={deleteTemplate}>
                            削除
                        </CButton>
                    </div>
                </div>
            </CModal>
        </>
    )
}

export default AdminReportTemplateDetail
