import { VFC } from 'react'
import { NumericFormat } from 'react-number-format'

export type CNumberInputViewProps = {} & CNumberInputProps

export const CNumberInputView: VFC<CNumberInputViewProps> = ({ ...props }) => {
    return (
        <NumericFormat
            value={props.value}
            placeholder={props.placeholder}
            readOnly={props.readonly}
            disabled={props.disabled}
            onValueChange={(values) => {
                !!props.onChange && props.onChange(values.floatValue)
            }}
            onKeyUp={() => !!props.onKeyUp && props.onKeyUp()}
            onBlur={() => !!props.onBlur && props.onBlur()}
            onFocus={() => !!props.onFocus && props.onFocus()}
            className={props.className}
            thousandsGroupStyle={'thousand'}
            thousandSeparator={','}
            decimalScale={props.decimalScale}
            fixedDecimalScale
        />
    )
}

export type CNumberInputProps = {
    value: string | number | null | undefined
    className?: string
    placeholder?: string
    readonly?: boolean
    disabled?: boolean
    decimalScale?: number
    onChange?: (value: number | undefined) => void
    onKeyUp?: () => void
    onFocus?: () => void
    onBlur?: () => void
}

export const CNumberInput: VFC<CNumberInputProps> = (props) => {
    return <CNumberInputView {...props} />
}
