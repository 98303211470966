import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import clsx from 'clsx'
import { t } from 'i18next'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.min.js?url'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Document, DocumentProps, Page, PageProps, pdfjs } from 'react-pdf'
import { ReactZoomPanPinchContentRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

import { CButton } from '../cButton/CButton'

// PDF.jsのバージョン合致が必須なので、react-pdfのアップデートの際は注意する
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

export type CPdfViewerViewProps = {
    currentPage: number
    totalPages: number
    isLoading: boolean
    onLoadSuccess: DocumentProps['onLoadSuccess']
    onPageLoadSuccess: PageProps['onLoadSuccess']
    previousPage: () => void
    nextPage: () => void
} & CPdfViewerProps

export const CPdfViewerView: FC<CPdfViewerViewProps> = ({
    src,
    currentPage,
    totalPages,
    isLoading,
    onLoadSuccess,
    onPageLoadSuccess,
    previousPage,
    nextPage,
}) => {
    const wRef = useRef<ReactZoomPanPinchContentRef | null>(null)
    const pageComponent = useMemo(() => {
        return (
            <TransformWrapper centerOnInit centerZoomedOut ref={wRef} minScale={0.5} doubleClick={{ disabled: true }}>
                <TransformComponent>
                    <Page pageNumber={currentPage} renderTextLayer={false} onLoadSuccess={onPageLoadSuccess} />
                </TransformComponent>
            </TransformWrapper>
        )
    }, [src, currentPage])

    useEffect(() => {
        wRef.current?.resetTransform()
    }, [src, currentPage, totalPages])

    const pager = useMemo(() => {
        if (totalPages <= 1) return null
        return (
            <div className={clsx('bg-white', 'flex', 'gap-2', 'p-1', 'items-center', 'fixed', 'top-[48px]', 'w-full', 'z-30')}>
                <p className={clsx('text-xs')}>
                    {currentPage || (totalPages ? 1 : '--')} / {totalPages || '--'} {t('CPdfViewer.ページ')}
                </p>
                <CButton
                    className={clsx('c-button-secondary', 'text-xs')}
                    disabled={isLoading || currentPage <= 1}
                    onClick={previousPage}>
                    {t('CPdfViewer.前ページへ')}
                </CButton>
                <CButton
                    className={clsx('c-button-primary', 'text-xs')}
                    disabled={isLoading || currentPage >= totalPages}
                    onClick={nextPage}>
                    {t('CPdfViewer.次ページへ')}
                </CButton>
            </div>
        )
    }, [src, currentPage, totalPages, isLoading])
    return (
        <div className={clsx(pager ? 'h-[calc(100vh-86px)]' : 'h-[calc(100vh-43px)]', 'min-w-[340px]')}>
            {pager}
            <Document
                className={clsx('w-full', 'h-full', pager ? 'mt-[86px]' : 'mt-[43px]', 'overflow-y-auto', 'mx-auto')}
                file={src}
                onLoadSuccess={onLoadSuccess}
                options={{
                    cMapUrl: 'https://unpkg.com/pdfjs-dist@3.6.172/cmaps/',
                    cMapPacked: true,
                }}>
                {pageComponent}
            </Document>
        </div>
    )
}

export type CPdfViewerProps = {
    src: string
}

export const CPdfViewer: FC<CPdfViewerProps> = ({ ...props }) => {
    const [totalPages, setTotalPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const onLoadSuccess = ({ numPages }: { numPages: number }) => {
        setTotalPages(numPages)
    }

    const onPageLoadSuccess = () => {
        setIsLoading(false)
    }

    function changePage(offset: number) {
        setIsLoading(true)
        setCurrentPage((prevPageNumber) => prevPageNumber + offset)
    }

    function previousPage() {
        changePage(-1)
    }

    function nextPage() {
        changePage(1)
    }

    return (
        <CPdfViewerView
            {...props}
            currentPage={currentPage}
            totalPages={totalPages}
            isLoading={isLoading}
            onLoadSuccess={onLoadSuccess}
            onPageLoadSuccess={onPageLoadSuccess}
            nextPage={nextPage}
            previousPage={previousPage}
        />
    )
}
