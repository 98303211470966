import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import React, { useMemo } from 'react'
import { createSearchParams } from 'react-router-dom'

import {
    IntermediaryToDocumentEntities,
    IntermediaryToEntities,
    useClientIntermediaryPostPreviewMutation,
    useClientMediaGetSignedUrlMutation,
} from '~/app/api'
import CMessage from '~/components/common/cMessage/CMessage'
import { getSignedUrl, getSignedUrlString, isPdfFile } from '~/util/model/FileEntities'

import { CButton } from '../../../common/cButton/CButton'
import { CError } from '../../../common/cError/CError'

type Props = {
    intermediaryTo: IntermediaryToEntities
}

const CRealestateDetailIntermediaryFile = ({ intermediaryTo }: Props) => {
    const errors = React.useMemo(() => [], [])

    // 資料一覧
    const filteredDocuments = useMemo(() => {
        return intermediaryTo?.intermediaryToDocuments?.filter((d) => !d.documentFrom?.isMap)
    }, [intermediaryTo])

    const [postPreview] = useClientIntermediaryPostPreviewMutation()

    // プレビューログ
    const sendPreviewLog = (documentUuid: string, method: 'preview' | 'download') => {
        postPreview({ clientIntermediaryDocumentPreviewDto: { documentUuid, method } }).catch((err) => {
            console.error(err)
        })
    }

    // 資料の一括ダウンロード
    const bulkDownload = async () => {
        await downloadFile(
            `${process.env.BASE_URL}/api/client/intermediary_to/export/${intermediaryTo.uuid}`,
            `${intermediaryTo?.intermediary.sourceRealEstateRevision?.name ?? ''}_${dayjs().format(
                'YYYYMMDDHHmmss',
            )}_intermediary_documents.zip`,
        )
    }

    // ダウンロード
    const downloadDocument = async (doc: IntermediaryToDocumentEntities) => {
        if (!doc.file) return
        sendPreviewLog(doc.uuid, 'download')
        const url = getSignedUrl(doc.file)
        await downloadFile(
            url,
            `${intermediaryTo?.intermediary.sourceRealEstateRevision?.name ?? ''}_${doc.file?.filename ?? ''}`,
        )
    }
    const downloadFile = async (path: string, name: string) => {
        try {
            const response = await fetch(path)
            const blob = response.body
            const streamSaver = (await import('streamsaver')).default
            const fileStream = streamSaver.createWriteStream(name)
            await blob?.pipeTo(fileStream)
        } catch {
            // ファイルDLがキャンセルor中断された
        }
    }

    // プレビュー
    const mutation = useClientMediaGetSignedUrlMutation()
    const previewDocument = async (doc: IntermediaryToDocumentEntities) => {
        if (!doc.file) return
        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        const win = window.open(
            undefined,
            'file_preview',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )

        sendPreviewLog(doc.uuid, 'preview')

        try {
            const fileUrl = isPdfFile(doc.file) ? await getSignedUrlString(doc.file, mutation) : getSignedUrl(doc.file)
            const url = new URL(window.location.origin + '/file_preview')
            url.search = createSearchParams({
                fileName: doc.file.filename,
                mineType: doc.file.mimeType,
                src: fileUrl,
            }).toString()

            if (win) win.location.href = url.toString()
        } catch (e) {
            // ファイルの取得に失敗
        }
    }

    return (
        <>
            <CError errors={errors} />
            <div className={clsx('my-2')}>
                <div className={clsx('flex', 'justify-end')}>
                    {(filteredDocuments?.length ?? 0) > 0 && (
                        <CButton className={clsx('c-button-primary', 'text-sm')} onClick={bulkDownload}>
                            <i className={clsx('material-icons', 'text-sm')}>download</i>
                            <div>{t('CRealestateDetailFile.資料を一括ダウンロード')}</div>
                        </CButton>
                    )}
                </div>
            </div>
            <div>
                {(filteredDocuments?.length ?? 0) > 0 ? (
                    <table className={clsx('w-full', 'text-sm')}>
                        <thead>
                            <tr className={clsx('text-left', 'text-kimar-primary', 'border-t', 'border-b')}>
                                <th>{t('CRealestateDetailIntermediaryFile.資料名')}</th>
                                <th style={{ width: '270px' }}>{t('CRealestateDetailIntermediaryFile.操作')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDocuments?.map((document) => (
                                <tr key={`second-${document.uuid}`} className={clsx('border-b')}>
                                    <td>
                                        <div className={clsx('flex', 'items-center')}>
                                            <div>{document.file.filename}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={clsx('flex', 'items-center', 'space-x-1')}>
                                            <CButton
                                                className={clsx('c-button-secondary', 'text-sm')}
                                                onClick={() => previewDocument(document)}>
                                                {t('CRealestateDetailFile.プレビュー')}
                                            </CButton>
                                            <CButton
                                                className={clsx('c-button-secondary', 'text-sm')}
                                                onClick={() => downloadDocument(document)}>
                                                <i className={clsx('material-icons', 'text-sm')}>download</i>
                                                <div>{t('CRealestateDetailFile.ダウンロード')}</div>
                                            </CButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <CMessage info>{t('CRealestateDetailIntermediaryFile.物件資料がありません')}</CMessage>
                )}
            </div>
        </>
    )
}

export default CRealestateDetailIntermediaryFile
