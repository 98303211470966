import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast/headless'

import {
    ClientNotificationTemplateUpdateDto,
    NotificationTemplateEntities,
    useClientNotificationTemplateDeleteUuidMutation,
    useClientNotificationTemplateNotificationTemplateMypageQuery,
    useClientNotificationTemplatePutUuidMutation,
} from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'
import CTable from '~/components/common/cTable/CTable'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser } from '~/util/store/userSlice'

import { ApplicationError, IApplicationError } from '../../../types/error'
import { CButton } from '../../common/cButton/CButton'
import { CError } from '../../common/cError/CError'
import CUserMypageTeamTemplateEdit from './CUserMypageTeamTemplateEdit'

const CUserMypageTeamTemplate = () => {
    const [errors, setErrors] = useState<Array<IApplicationError> | undefined>(undefined)
    const userRole = useAppSelector(selectLoginUser)?.roles.team
    // メールテンプレ取得
    const tabs = useMemo(
        () => [
            { label: t('CUserMypageTeamTemplate.会社'), value: 1 },
            { label: t('CUserMypageTeamTemplate.個人'), value: 2 },
        ],
        [],
    )
    const [selectedTab, setSelectedTab] = useState<1 | 2>(1)
    const { data: notificationTemplates } = useClientNotificationTemplateNotificationTemplateMypageQuery({
        type: selectedTab,
    })
    // 編集またはデフォルトに戻す対象のテンプレート
    const [templateEditModel, setTemplateEditModel] = useState<ClientNotificationTemplateUpdateDto | undefined>(undefined)

    // 編集ダイアログオープンflag
    const [templateEditVisible, setTemplateEditVisible] = useState(false)
    // PUT API
    const [templateSaveAPI] = useClientNotificationTemplatePutUuidMutation()

    // チームテンプレート削除確認ダイアログオープンflag
    const [templateDeleteVisible, setTemplateDeleteVisible] = useState(false)
    // DELETE API
    const [templateDeleteAPI] = useClientNotificationTemplateDeleteUuidMutation()

    // リクエスト成功時に立つフラグ 1:編集成功 2:デフォルトへの戻し成功 0:その他
    const [successFlag, setSuccessFlag] = useState(0)

    // 編集ボタンハンドラ
    const editTemplateHandler = (template: NotificationTemplateEntities) => {
        setTemplateEditModel({
            uuid: template.uuid,
            key: template.key,
            name: template.name,
            mailSubject: template.mailSubject,
            mailHtmlBody: template.mailHtmlBody,
            mailHtmlButtonTitle: template.mailHtmlButtonTitle,
            mailHtmlAfterMessage: template.mailHtmlAfterMessage,
            templateType: selectedTab,
        })
        setTemplateEditVisible(true)
    }

    // インジェクション対策
    const htmlEntities = (str: string) =>
        String(str).replace(/&/g, '＆').replace(/"/g, '”').replace(/</g, '＜').replace(/>/g, '＞')

    // 編集中のハンドラ
    const changeHandler = (changedTemplate: ClientNotificationTemplateUpdateDto) => {
        setTemplateEditModel({
            ...changedTemplate,
            // インジェクション対策のサニタイジング
            mailSubject: htmlEntities(changedTemplate.mailSubject ?? ''),
            mailHtmlBody: htmlEntities(changedTemplate.mailHtmlBody ?? ''),
            mailHtmlButtonTitle: htmlEntities(changedTemplate.mailHtmlButtonTitle ?? ''),
            mailHtmlAfterMessage: htmlEntities(changedTemplate.mailHtmlAfterMessage ?? ''),
        })
    }

    // 編集したテンプレートの保存ボタンハンドラ
    const editTemplateSaveHandler = async () => {
        try {
            setErrors(undefined)
            if (!templateEditModel)
                throw new ApplicationError(t('CUserMypageTeamTemplate.編集対象のテンプレートデータを取得できませんでした'))
            if (templateEditModel.name.length <= 0)
                throw new ApplicationError(t('CUserMypageTeamTemplate.テンプレート使用場面が入力されていません'))
            if (templateEditModel.mailSubject.length <= 0)
                throw new ApplicationError(t('CUserMypageTeamTemplate.メール件名が入力されていません'))
            if (templateEditModel.mailHtmlBody.length <= 0)
                throw new ApplicationError(t('CUserMypageTeamTemplate.メール本文が入力されていません'))
            await templateSaveAPI({
                uuid: templateEditModel.uuid,
                clientNotificationTemplateUpdateDto: templateEditModel,
            }).unwrap()
            setSuccessFlag(1)
            toast.success(t('CUserMypageTeamTemplate.通知テンプレートを編集しました'))
            setTimeout(() => {
                setSuccessFlag(0)
            }, 3000)
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else setErrors([{ ...((e as FetchBaseQueryError).data as IApplicationError), message: '編集に失敗しました。' }])
        } finally {
            setTemplateEditModel(undefined)
            setTemplateEditVisible(false)
        }
    }

    // デフォルトに戻すボタンハンドラ
    const deleteTemplateHandler = (template: NotificationTemplateEntities) => {
        if (template) {
            if (selectedTab === 1 && !template.team) return
            if (selectedTab === 2 && !template.member) return
        }
        setTemplateEditModel({
            uuid: template.uuid,
            key: template.key,
            name: template.name,
            mailSubject: template.mailSubject,
            mailHtmlBody: template.mailHtmlBody,
            mailHtmlButtonTitle: template.mailHtmlButtonTitle,
            mailHtmlAfterMessage: template.mailHtmlAfterMessage,
            templateType: selectedTab,
        })
        setTemplateDeleteVisible(true)
    }

    // デフォルトに戻すリクエスト送信
    const sendDelete = async () => {
        try {
            setErrors(undefined)
            if (!templateEditModel) {
                throw new ApplicationError(
                    t('CUserMypageTeamTemplate.デフォルトに戻す対象のテンプレートデータを取得できませんでした'),
                )
            }
            await templateDeleteAPI({
                uuid: templateEditModel.uuid,
            }).unwrap()
            setSuccessFlag(2)
            toast.success(t('CUserMypageTeamTemplate.通知テンプレートをデフォルトに戻しました'))
            setTimeout(() => {
                setSuccessFlag(0)
            }, 3000)
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            else {
                setErrors([
                    {
                        ...((e as FetchBaseQueryError).data as IApplicationError),
                        message: t('CUserMypageTeamTemplate.テンプレートをデフォルトに戻せませんでした。'),
                    },
                ])
            }
        } finally {
            setTemplateEditModel(undefined)
            setTemplateDeleteVisible(false)
        }
    }

    const getDefaultButtonString = (template: NotificationTemplateEntities) => {
        if (selectedTab == 1) {
            // 会社
            if (!template.team) return t('CUserMypageTeamTemplate.デフォルトテンプレートです')
        } else if (selectedTab == 2) {
            if (!template.member && !!template.team) return t('CUserMypageTeamTemplate.会社テンプレートです')
            else if (!template.member && !template.team) return t('CUserMypageTeamTemplate.デフォルトテンプレートです')
        }
        return undefined
    }

    return (
        <>
            <div className={clsx('w-full', 'md:max-w-lg')}>
                <CSegmentedControl items={tabs} onClick={(val) => setSelectedTab(val as 1 | 2)} selectedTabValue={selectedTab} />
            </div>
            <div className={clsx('flex', 'flex-col', 'gap-y-4', 'w-full')}>
                <CHeader label={t('CUserMypageTeamTemplate.紹介先へ送信されるメール設定')} />
                {successFlag !== 0 && (
                    <CMessage info>
                        {successFlag === 1
                            ? t('CUserMypageTeamTemplate.保存に成功しました。')
                            : t('CUserMypageTeamTemplate.デフォルトに戻しました。')}
                    </CMessage>
                )}
                {errors?.length && <CError errors={errors} />}
                <CTable
                    thead={
                        <tr className={clsx('bg-kimar-primary', 'text-white', 'text-left')}>
                            <th className={clsx('p-2', 'w-[250px]')}>{t('CUserMypageTeamTemplate.テンプレート使用場面')}</th>
                            <th className={clsx('p-2')}>{t('CUserMypageTeamTemplate.メール件名')}</th>
                            {userRole && userRole === 2 && <th className={clsx('p-2')}>{t('CUserMypageTeamTemplate.操作')}</th>}
                        </tr>
                    }
                    tbody={notificationTemplates?.map((template) => (
                        <tr key={template.uuid} className={clsx('border', 'hover:bg-kimar-primary-light')}>
                            <td className={clsx('p-2')}>{template.name}</td>
                            <td className={clsx('p-2')}>{template.mailSubject}</td>
                            {userRole && userRole === 2 && (
                                <td className={clsx('flex', 'flex-row', 'gap-1', 'text-sm')}>
                                    <CButton
                                        className={clsx(
                                            'whitespace-nowrap',
                                            !template.team ? 'c-button-primary' : 'c-button-secondary',
                                        )}
                                        disabledText={getDefaultButtonString(template)}
                                        disabled={(selectedTab == 1 && !template.team) || (selectedTab == 2 && !template.member)}
                                        onClick={() => deleteTemplateHandler(template)}>
                                        {t('CUserMypageTeamTemplate.デフォルトに戻す')}
                                    </CButton>
                                    <CButton
                                        onClick={() => editTemplateHandler(template)}
                                        className={clsx('whitespace-nowrap', 'c-button-primary')}>
                                        {t('Button.編集')}
                                    </CButton>
                                </td>
                            )}
                        </tr>
                    ))}
                />
            </div>
            {/* テンプレート編集 */}
            <CConfirmModal
                title={t('CUserMypageTeamTemplate.メールテンプレートを編集')}
                content={{ maxWidth: '1200px', maxHeight: '90%' }}
                visible={templateEditVisible}
                onRequestClose={() => {
                    setTemplateEditModel(undefined)
                    setTemplateEditVisible(false)
                }}
                onRequestConfirm={() => editTemplateSaveHandler()}
                confirmLabel={t('CUserMypageTeamTemplate.編集確定')}>
                {templateEditModel && (
                    <CUserMypageTeamTemplateEdit
                        template={templateEditModel}
                        changeHandler={(changedTemplate) => {
                            changeHandler(changedTemplate)
                        }}
                    />
                )}
            </CConfirmModal>
            {/* チームテンプレート削除 */}
            <CConfirmModal
                visible={templateDeleteVisible}
                onRequestConfirm={() => sendDelete()}
                onRequestClose={() => {
                    setTemplateEditModel(undefined)
                    setTemplateDeleteVisible(false)
                }}
                confirmLabel={t('CUserMypageTeamTemplate.デフォルトに戻す')}>
                <span>{t('CUserMypageTeamTemplate.テンプレートをデフォルトのものに戻します。')}</span>
                <br />
                <span>{t('CUserMypageTeamTemplate.今のテンプレートは失われます。よろしいですか？')}</span>
            </CConfirmModal>
        </>
    )
}

export default CUserMypageTeamTemplate
