import clsx from 'clsx'
import { t } from 'i18next'
import { Helmet } from 'react-helmet-async'

import { CHeader } from '~/components/common/cHeader/CHeader'
import { pageTitleTemplate } from '~/util/common/common'

const Eura = () => (
    <>
        <Helmet titleTemplate={pageTitleTemplate()}>
            <title>{t('Eura.利用規約')}</title>
        </Helmet>
        <CHeader label={t('Eura.利用規約')} />
        <div
            className={clsx(
                'bg-white',
                'flex',
                'flex-col',
                'gap-4',
                'p-4',
                'md:p-8',
                'text-sm',
                'w-full',
                'max-w-4xl',
                'mx-auto',
                'rounded',
            )}>
            <div>
                本利用規約（以下「本規約」といいます。）には、リマールエステート株式会社（以下「当社」といいます。）の提供するキマールサービスのご利用にあたり、登録ユーザーの皆様に遵守していただかなければならない事項及び当社と登録ユーザーの皆様との間の権利義務関係が定められております。当該サービスを登録ユーザーとしてご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。
            </div>
            <div>
                <ol className={clsx('list-decimal')} style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        適用
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                本規約は、本サービス（第2条に定義）の利用に関する当社と登録ユーザー（第2条に定義）との間の権利義務関係を定めることを目的とし、登録ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社が当社ウェブサイト（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。また、本規約の内容と、当社と登録ユーザーとの間で締結した個別契約の内容とが異なる場合は、個別契約の規定が優先して適用されるものとします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        定義
                    </li>
                    <ol className={clsx('pl-4')}>
                        本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
                        <ol className={clsx('list-decimal')} style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「社内マッチングサービス」とは、本サービスのうち、当社が提供するキマールマーケットという名称のAIデータ解析を利用した登録ユーザーの登録物件と登録顧客のレコメンドサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「社外マッチングサービス」とは、本サービスのうち、当社が提供するキマールマーケットという名称のAIデータ解析を利用した社外見込客のレコメンドサービス及び非公開物件への情報開示リクエストサービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「当社ウェブサイト」とは、そのドメインが「https://kimar.jp」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「登録希望者」とは、第3条において定義された「登録希望者」を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「登録顧客」とは、登録ユーザーが本サービスにおいて情報を入力した不動産購入希望者及び不動産売却希望者（社外マッチングサービスにおいては、登録ユーザーの登録顧客又は登録物件に関する情報の提供を受けた登録ユーザー以外の本サービス利用者を含みます。）を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「社外見込客」とは、登録ユーザー以外の本サービス利用者が本サービスにおいて情報を入力した不動産購入希望者及び不動産売却希望者を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「登録情報」とは、第3条において定義された「登録情報」を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「登録物件」とは、登録ユーザーが本サービスにおいて情報を入力した売却希望不動産を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「非公開物件」とは、登録ユーザー以外の本サービス利用者が本サービスにおいて情報を入力した売却希望不動産であって、当該情報が本サービス上で公開されていないものを意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「登録ユーザー」とは、第3条に基づき本サービスの利用者としての登録がなされた個人又は法人（利用者としての当該法人の役職員を含みます。以下同様です。）を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「本サービス」とは、当社が提供するキマールという名称の不動産売買プラットフォームを活用したサービス（物件管理、顧客管理、情報配信及び営業管理の業務支援サービス、並びに、社内マッチングサービス及び社外マッチングサービス等の機会創出サービスを含みます。但し、理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「投稿データ」とは、登録ユーザー（登録ユーザーの登録顧客を含みます。以下本号において同様です。）が本サービスを利用して投稿その他送信する物件情報、顧客情報、売却希望不動産情報、購入希望条件、売却希望条件その他のコンテンツ（資料、ファイル、文章、画像、動画その他のデータを含みますがこれらに限りません。）及び登録ユーザーが本サービスを利用することにより記録され又は登録ユーザーが当社ウェブサイトに入力する一切の情報を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「行動ログ」とは、登録顧客が本サービスを利用して登録物件、非公開物件、社外見込客等に関する情報又は資料を閲覧等した行動に係る記録及び情報（但し、登録物件を特定する情報及び登録顧客を特定する情報、並びに、登録顧客を登録した登録ユーザーを特定する情報は含まれないものとします。）を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「登録顧客入力データ」とは、登録顧客が入力した売却希望不動産情報、購入希望条件、売却希望条件を意味します。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                「利用契約」とは、第3条第4項に基づき当社と登録ユーザーの間で成立する、本規約の諸規定に従った本サービスの利用契約を意味します。
                            </li>
                        </ol>
                    </ol>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        登録
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                本サービスの利用を希望する者（以下「登録希望者」といいます。）は、既存の登録ユーザーからの招待を受けた場合又は当社が承認した場合には、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録の申請は必ず本サービスを利用する個人又は法人自身が行わなければならず、原則として代理人による登録申請は認められません。また、登録希望者は、登録の申請にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、第1項に基づき登録を申請した登録希望者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。
                                <ol className={clsx('list-decimal')} style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        本規約に違反するおそれがあると当社が判断した場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        過去に本サービスの利用の登録を取り消された者である場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同様です。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        過去当社との契約に違反した者又はその関係者であると当社が判断した場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        その他、当社が登録を適当でないと判断した場合
                                    </li>
                                </ol>
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、前項その他当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知します。かかる通知により登録希望者の登録ユーザーとしての登録は完了し、本規約の諸規定に従った本サービスの利用にかかる契約（以下「利用契約」といいます。）が登録ユーザーと当社の間に成立します。なお、当社は登録の可否の判断の内容及び理由について、登録希望者からの照会に応じる義務を負わないものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーが当社に提供した個人情報については、当社が別途定めるプライバシーポリシーの定めに従って取り扱います。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        本サービスの利用
                        <ol className={clsx('font-normal', 'text-sm', 'pl-4')}>
                            登録ユーザーは、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        料金及び支払方法
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、本サービス利用の対価として、その会員ステータス又は利用サービスの区分に従い、当社と別途合意した金額の利用料金を、当社が別途発行する請求書に基づき、これに係る消費税（地方消費税を含みます。）相当額を付加して支払うものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、前項に定める利用料金及びこれに係る消費税（地方消費税を含みます。）相当額を、当社が別途発行する請求書に記載の支払期日までに支払うものとします。なお、振込手数料その他の支払いに必要な費用は登録ユーザーの負担とします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、利用契約の解除その他いかなる場合であっても、登録ユーザーから支払いを受けた利用料金について、当該登録ユーザーに返金する義務を負わないものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーが利用料金の支払いを遅滞した場合、登録ユーザーは年14.60％の割合による遅延損害金を当社に支払うものとします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        パスワード及びユーザーIDの管理
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、自己の責任において、パスワード及びユーザーIDを管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーのパスワード又はユーザーIDが使用された場合、当社は当該登録ユーザー本人による使用とみなすことができ、これにより当該登録ユーザーに損害が発生した場合であっても、当社は一切の責任を負いません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録ユーザーが負うものとし、当社は一切の責任を負いません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、パスワード又はユーザーIDが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        禁止行為
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
                                <ol className={clsx('list-decimal')} style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        当社又は他の登録ユーザーその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        犯罪行為に関連する行為又は公序良俗に反する行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        反社会的勢力等への利益供与
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        猥褻な情報又は青少年に有害な情報を送信する行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        交際に関する情報を送信する行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        本サービスの目的に反する方法又は態様での面識のない者との出会いを目的とした行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        法令又は当社若しくは登録ユーザーが所属する業界団体の内部規則に違反する行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        本サービスに関して利用し又は利用しうる情報を改ざんする行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        本サービスのネットワーク又はシステム等に過度な負荷をかける行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        当社のネットワーク又はシステム等に不正にアクセスし又は不正なアクセスを試みる行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        当社による本サービスの運営を妨害し又は妨害するおそれのある行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        第三者に成りすます行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        本サービスの他の登録ユーザーの情報を収集する行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        当社又は第三者のサービス、財産、事業に対して損害を与え又は損害を与えるおそれのある行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        投稿データその他当社ウェブサイトに法令若しくは第三者との間の契約に違反又は抵触する内容を記録又は入力する行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        本サービスをリバースエンジニアリング、逆アセンブル、逆コンパイル、複製、修正、改変し、又はこれらを第三者に行わせる行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        本サービスと同一又は類似のサービスを行う行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        前各号の行為を直接又は間接に惹起し又は容易にする行為
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        その他、当社が不適切と判断する行為
                                    </li>
                                </ol>
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、本サービスにおける登録ユーザーによる情報の送信行為が前項各号のいずれかに該当し又は該当するおそれがあると当社が判断した場合には、登録ユーザーに事前に通知することなく、当該情報の全部又は一部の削除その他の必要な措置を講ずることができるものとします。当社は、本項に基づき当社が行った措置に関して登録ユーザーに生じた損害について一切の責任を負いません。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        本サービスの停止等
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
                                <ol className={clsx('list-decimal')} style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        本サービスのアップデートを行う場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        コンピューター、通信回線等が事故により停止した場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        その他、当社が停止又は中断を必要と判断した場合
                                    </li>
                                </ol>
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社は登録ユーザーに事前に通知するものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、本条に基づき当社が行った措置に関して登録ユーザーに生じた損害について一切の責任を負いません。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        設備の負担等
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、登録ユーザーの費用と責任において行うものとし、本サービスの利用に際して当該通信環境等の不具合等に起因して登録ユーザーに損害が発生した場合であっても、当社は当該損害について一切の責任を負いません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスへの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講ずるものとし、本サービスの利用に際して当該セキュリティ対策の不備等に起因して登録ユーザーに損害が発生した場合であっても、当社は当該損害について一切の責任を負いません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、登録ユーザーが送受信したメッセージその他の情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。なお、当社はかかる情報の削除に関連して登録ユーザーに生じた損害について一切の責任を負いません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイトからのダウンロードその他の方法によりソフトウェア等を登録ユーザーのコンピューター等にインストールする場合には、登録ユーザーが保有する情報の消滅若しくは改変又は機器の故障若しくは損傷等が生じないよう十分な注意を払うものとし、当社は登録ユーザーに発生したかかる損害について一切の責任を負わないものとします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        権利帰属
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社ウェブサイト及び本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。登録ユーザーは、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害する行為又は侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これらに限定されません。）をしないものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、投稿データについて、その内容が正確であること、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとし、投稿データに起因して当社が損害を被った場合には当該損害を補償する義務を負うものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、投稿データ（行動ログを含みます。）について、当社が、登録ユーザーを特定及び復元できないよう処理した上で、本サービスの利用状況の分析、改善又は機能の追加若しくは修正、その他新たなサービス等の検討及び開発等のために合理的に必要な範囲において、無償で利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することに同意するものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、投稿データ（行動ログ、登録顧客入力データを含みます。）について、当社が、本サービスの利用者のみがアクセスできるよう処理した上で、本サービス（社外マッチングサービスにおいては、登録ユーザーが社外マッチングサービスの利用に同意した場合に限ります。）の提供のために合理的に必要な範囲において、無償で利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することに同意するものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、当社及び当社から権利を承継し又は許諾された者に対して、投稿データに係る著作者人格権を行使しないことに同意するものとします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        登録取消等
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該登録ユーザーについて本サービスの利用を一時的に停止し又は登録ユーザーとしての登録を取り消すことができます。
                                <ol className={clsx('list-decimal')} style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        本規約のいずれかの条項に違反した場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        当社に提供された登録情報の全部又は一部につき虚偽の事実があることが判明した場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        当社又は他の登録ユーザーその他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用し又は利用しようとした場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        手段の如何を問わず、本サービスの運営を妨害した場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        自ら振出し、若しくは引受けた手形又は小切手につき、不渡りの処分を受けた場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        租税公課の滞納処分を受けた場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        第3条第3項各号に該当する場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        6ヶ月以上本サービスの利用がない場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        当社からの問い合わせその他の回答を求める連絡に対して15日間以上応答がない場合
                                    </li>
                                    <li
                                        style={{ counterIncrement: 'count 1' }}
                                        className={clsx('font-normal', 'before:content-["("counter(count)")_"]')}>
                                        その他、当社が登録ユーザーとしての登録の継続を適当でないと判断した場合
                                    </li>
                                </ol>
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払いを行わなければなりません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、本条に基づき当社が行った行為により登録ユーザーに生じた損害について一切の責任を負いません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                本条に基づき登録ユーザーの登録が取り消された場合、登録ユーザーは、当社の指示に従い、当社から提供を受けた本サービスに関連するソフトウェア、マニュアルその他の物につき、返還、廃棄その他の処分を行うものとします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        保証の否認及び免責
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーが当社から直接又は間接に、本サービス、当社ウェブサイト、本サービスの他の登録ユーザーその他の事項に関する何らかの情報を得た場合であっても、当社は登録ユーザーに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、本サービスを利用することが、登録ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用において調査するものとし、当社は、登録ユーザーによる本サービスの利用が、登録ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                本サービス又は当社ウェブサイトに関連して登録ユーザーと他の登録ユーザーその他の第三者との間において生じた取引、連絡、紛争等については、登録ユーザーの責任と費用において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、当社による本サービスの提供の中断、停止、終了、利用不能若しくは変更、登録ユーザーのメッセージ若しくは情報の削除又は消失､登録ユーザーによる誤操作、投稿データの誤り又は不十分性、登録ユーザーの登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して登録ユーザーが被った損害につき、賠償する責任を一切負わないものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合であっても、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して、理由の如何を問わず、一切の責任を負わないものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、本サービスに関連して登録ユーザーが被った損害について、一切賠償の責任を負いません。なお、消費者契約法の適用その他の理由により、本項その他の当社の損害賠償責任を免責する規定にかかわらず当社が登録ユーザーに対して損害賠償責任を負う場合、当社の賠償責任は、損害の事由が生じた時点から遡って過去6ヶ月に登録ユーザーから現実に受領した本サービスの利用料金の総額を上限とします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        ユーザーの賠償等の責任
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、本規約に違反することにより又は本サービスの利用に関連して当社に損害を与えた場合には、当社に対しその損害を賠償しなければなりません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、本サービスに関連して他の登録ユーザーその他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合、直ちにその内容を当社に通知するとともに、登録ユーザーの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に従い、その経過及び結果を当社に報告するものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーによる本サービスの利用に関連して、当社が、他の登録ユーザーその他の第三者から権利侵害その他の理由により何らかの請求を受けた場合、登録ユーザーは、当該請求に起因して当社が被った損害を賠償しなければなりません。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        秘密保持
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                本規約において「秘密情報」とは、利用契約又は本サービスに関連して、登録ユーザーが、当社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関する一切の情報を意味します。但し、(1)当社から提供若しくは開示がなされたとき又は知得したときに、既に公知となっていた、又は既に知得していたもの、(2)当社から提供若しくは開示を受け又は知得した後、自己の責めに帰さない事由により刊行物その他によって公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)当社から秘密保持の必要がない旨書面で確認されたものについては、秘密情報から除外するものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、秘密情報を本サービスの利用の目的にのみ利用するとともに、当社の書面による事前の承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                第2項の定めにかかわらず、登録ユーザーは、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を当社に通知しなければなりません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、秘密情報を記載した文書又は磁気記録媒体等を複製する場合には、事前に当社の書面による承諾を得ることとし、複製物の管理については第2項に準じて厳重に行うものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報及び秘密情報を記載又は包含した書面その他の記録媒体物並びにその全ての複製物を返却又は廃棄しなければなりません。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        会社名等の公表
                        <ol className={clsx('font-normal', 'text-sm', 'pl-4')}>
                            登録ユーザーは、当社が、本サービスの利用実績を紹介する目的の範囲内で、登録ユーザーの会社名を当社ウェブサイトその他当社が別途定める媒体において表示し、第三者に開示又は公表することを承諾します。また、登録ユーザーは、当社の申入れがあった場合には、当該表示に登録ユーザーのロゴ又は商標等を無償で使用することについて許諾するものとします。
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        有効期間
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                利用契約は、登録ユーザーについて第3条に基づく登録が完了した日に効力を生じ、当該登録ユーザーの登録が取り消された日又は本サービスの提供が終了した日のいずれか早い日まで、当社と登録ユーザーとの間で有効に存続するものとします。
                                また、契約期間満了時の1ヶ月前までに登録ユーザーからの更新拒絶の通知がない限り、利用契約は同一条件（契約期間を含む。）にて自動的に更新されるものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、契約期間中、利用契約を途中解約することはできないものとします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        本規約等の変更
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、本サービスの内容を自由に変更できるものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同様です。）を変更できるものとします。当社は、本規約を変更した場合には、登録ユーザーに当該変更内容を通知するものとし、当該変更内容の通知後、登録ユーザーが本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、登録ユーザーは、本規約の変更に同意したものとみなします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        連絡/通知
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                本サービスに関する問い合わせその他の登録ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他の当社から登録ユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、当社が、本サービスの提供における登録ユーザーの利便性向上の目的に限り、かつ、登録顧客による購入条件の入力等の本サービスの利用のサポートのために必要な範囲に限り、登録顧客に対して直接連絡又は通知を行うことに同意するものとします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        契約上の地位の譲渡等
                        <ol
                            className={clsx('list-decimal', 'text-sm', 'pl-4')}
                            style={{ counterReset: 'count 0', listStyleType: 'none' }}>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                登録ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
                            </li>
                            <li
                                style={{ counterIncrement: 'count 1' }}
                                className={clsx('font-normal', 'before:content-[""counter(count)"._"]')}>
                                当社が本サービスにかかる事業を他社に譲渡する場合、当社は当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録情報その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
                            </li>
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        完全合意
                        <ol className={clsx('font-normal', 'text-sm', 'pl-4')}>
                            本規約は、本規約に含まれる事項に関する当社と登録ユーザーとの完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社と登録ユーザーとの事前の合意、表明及び了解に優先します。
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        分離可能性
                        <ol className={clsx('font-normal', 'text-sm', 'pl-4')}>
                            本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び登録ユーザーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で規定を修正し、当該無効若しくは執行不能な条項又は部分の趣旨を維持し、また法律的及び経済的に同等の効果を確保できるように努めるものとします。
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        存続規定
                        <ol className={clsx('font-normal', 'text-sm', 'pl-4')}>
                            第5条（未払がある場合に限ります。）、第6条第2項、第3項、第7条第2項、第8条第3項、第9条、第10条、第11条第2項、第4項及び第5項、第12条から第15条まで、並びに第19条から第23条までの規定は利用契約の終了後も有効に存続するものとします。
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        準拠法及び管轄裁判所
                        <ol className={clsx('font-normal', 'text-sm', 'pl-4')}>
                            本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
                        </ol>
                    </li>
                    <li
                        className={clsx('font-semibold', 'text-lg', 'pt-4', 'before:content-["第"counter(count)"条_"]')}
                        style={{ counterIncrement: 'count 1' }}>
                        協議解決
                        <ol className={clsx('font-normal', 'text-sm', 'pl-4')}>
                            当社及び登録ユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
                        </ol>
                    </li>
                </ol>
            </div>
            <div className={clsx('w-40', 'ml-auto', 'mr-8', 'flex', 'flex-col', 'justify-end')}>
                <p className={clsx('flex', 'justify-between')}>
                    <span>2018年2月13日</span>
                    <span>制定</span>
                </p>
                <p className={clsx('flex', 'justify-between')}>
                    <span>2018年9月1日</span>
                    <span>改訂</span>
                </p>
                <p className={clsx('flex', 'justify-between')}>
                    <span>2018年12月1日</span>
                    <span>改訂</span>
                </p>
                <p className={clsx('flex', 'justify-between')}>
                    <span>2019年10月10日</span>
                    <span>改訂</span>
                </p>
                <p className={clsx('flex', 'justify-between')}>
                    <span>2019年12月10日</span>
                    <span>改訂</span>
                </p>
                <p className={clsx('flex', 'justify-between')}>
                    <span>2021年8月25日</span>
                    <span>改訂</span>
                </p>
                <p className={clsx('flex', 'justify-between')}>
                    <span>2021年10月1日</span>
                    <span>改訂</span>
                </p>
                <p className={clsx('flex', 'justify-between')}>
                    <span>2023年5月26日</span>
                    <span>改訂</span>
                </p>
            </div>
        </div>
    </>
)

export default Eura
