import clsx from 'clsx'
import { lowerCase } from 'lodash'
import { VFC } from 'react'

export type CSortButtonViewProps = {} & CSortButtonProps

export const CSortButtonView: VFC<CSortButtonViewProps> = ({
    className,
    items = [],
    sort,
    dataLabel = 'label',
    dataValue = 'value',
    nowValue,
    onChange,
    onClickSort,
}) => {
    const radioButtonList = items.map((data) => {
        const label = (data[dataLabel] ?? data[dataValue]) as string
        const value = data[dataValue] as string | number | readonly string[] | undefined
        return (
            <option key={label} value={value}>
                {label}
            </option>
        )
    })

    return (
        <div className={clsx('flex', 'items-center', 'border-gray-300', 'border', 'bg-white', 'rounded', className)}>
            <select
                className={clsx('bg-none', 'pr-4', 'bg-inherit', 'border-none', 'focus:ring-0')}
                value={nowValue}
                onChange={(event) => onChange(event.target.value)}>
                {radioButtonList}
            </select>
            <button className={clsx('block', 'flex', 'items-center', 'mr-2')}>
                <i
                    className={clsx(
                        'material-symbols-outlined',
                        'text-kimar-primary',
                        lowerCase(sort) === 'asc' && 'rotate-180',
                        'transition',
                    )}
                    onClick={() => onClickSort()}>
                    filter_list
                </i>
            </button>
        </div>
    )
}

export type CSortButtonProps = {
    className?: string
    items: Array<{ [key: string]: unknown }>
    sort: 'asc' | 'desc' | 'ASC' | 'DESC'
    dataLabel?: string
    dataValue?: string
    nowValue?: string
    onChange: (value: string) => void
    onClickSort: () => void
}

export const CSortButton: VFC<CSortButtonProps> = ({ ...props }) => {
    return <CSortButtonView {...props} />
}
