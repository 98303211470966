import 'rc-tree/assets/index.css'

import clsx from 'clsx'
import dayjs from 'dayjs'
import { sortBy } from 'lodash'
import Tree, { TreeNode } from 'rc-tree'
import { Key } from 'rc-tree/lib/interface'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { useParams } from 'react-router-dom'

import {
    AdminCompanyDetailUpdateDto,
    AdminCompanyUpdateDto,
    AdminTeamUpdateDto,
    CompanyContractEntities,
    FileEntities,
    TeamEntities,
    TeamMemberEntities,
    useAdminCompanyContractDeleteUuidMutation,
    useAdminCompanyContractPutUuidCancelMutation,
    useAdminCompanyPutUuidLogoMutation,
    useAdminCompanyPutUuidMutation,
    useAdminCompanyUuidQuery,
    useAdminTeamMemberDeleteUuidMutation,
    useAdminTeamMemberReplaceUuidMutation,
    useAdminTeamPutUuidMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CDropDown } from '~/components/common/cDropdown/CDropdown'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { CHeader } from '~/components/common/cHeader/CHeader'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import CTable from '~/components/common/cTable/CTable'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { CompanyLogo } from '~/components/unique/company/CompanyLogo'
import { InsertContractModal } from '~/components/unique/contract/InsertContractModal'
import { UpdateContractModal } from '~/components/unique/contract/UpdateContractModal'
import { InsertTeamMemberModal } from '~/components/unique/team/InsertTeamMemberModal'
import { InsertTeamModal } from '~/components/unique/team/InsertTeamModal'
import { UpdateTeamMemberModal } from '~/components/unique/team/UpdateTeamMemberModal'
import { companyRoles, customerRoles, intermediaryRoles, realestateRoles, teamRoles } from '~/types/enum/enum'
import { formatDateTime, pageTitleTemplate } from '~/util/common/common'
import { createTeamTree, TeamTreeNode } from '~/util/model/TeamEntities'

const AdminCompanyDetail = () => {
    const params = useParams()
    const { companyUuid } = params

    /** Query */
    const {
        data: company,
        isLoading,
        refetch: refetchCompany,
    } = useAdminCompanyUuidQuery({ uuid: companyUuid ?? '' }, { skip: !companyUuid })

    const [companyQuery, { isSuccess: isCompanySaveSuccess }] = useAdminCompanyPutUuidMutation()
    const [teamQuery, { isSuccess: isTeamSaveSuccess }] = useAdminTeamPutUuidMutation()
    const [deleteTeamMemberQuery] = useAdminTeamMemberDeleteUuidMutation()
    const [deleteContractQuery] = useAdminCompanyContractDeleteUuidMutation()
    const [cancelCompanyQuery] = useAdminCompanyContractPutUuidCancelMutation()

    const [replaceTeamMemberQuery] = useAdminTeamMemberReplaceUuidMutation()

    /** State */
    const [team, setTeam] = useState<TeamEntities>()
    const [companyForm, setCompanyForm] = useState<AdminCompanyDetailUpdateDto>({
        name: '',
        sound: '',
        address: '',
        phone: '',
        fax: '',
    })
    const [kpiForm, setKpiForm] = useState<AdminCompanyUpdateDto>({
        isKpiTarget: false,
    })
    const [teamUpdateDto, setTeamUpdateDto] = useState<AdminTeamUpdateDto>({
        name: '',
        overview: '',
        address: '',
        phone: '',
        fax: '',
    })
    const [showTeamInsertModal, setShowTeamInsertModal] = useState(false)
    const [showTeamMemberInsertModal, setShowTeamMemberInsertModal] = useState(false)
    const [teamMemberUpdateTarget, setTeamMemberUpdateTarget] = useState<TeamMemberEntities>()
    const [teamMemberDeleteTarget, setTeamMemberDeleteTarget] = useState<TeamMemberEntities>()
    const [showContractInsertModal, setShowContractInsertModal] = useState(false)
    const [contractUpdateTarget, setContractUpdateTarget] = useState<CompanyContractEntities>()
    const [contractDeleteTarget, setContractDeleteTarget] = useState<CompanyContractEntities>()
    const [contractCancelTarget, setContractCancelTarget] = useState<CompanyContractEntities>()

    const [replaceUuid, setReplaceUuid] = useState<string>()
    const [teamMemberReplaceTarget, setTeamMemberReplaceTarget] = useState<TeamMemberEntities>()

    /** Effect */
    useEffect(() => {
        if (!company) return
        setCompanyForm({
            name: company.name,
            sound: company.sound,
            address: company.address,
            phone: company.phone,
            fax: company.fax,
        })
        setKpiForm({
            isKpiTarget: company.isKpiTarget,
        })
    }, [company])
    useEffect(() => {
        if (!team) return
        setTeamUpdateDto({ name: team.name, overview: team.overview, address: team.address, phone: team.phone, fax: team.fax })
    }, [company, team])
    useEffect(() => {
        // 初回表示時はroot teamを選択する
        if (!team) setTeam(company?.teams.find((t) => !t.parentTeam))
        // refetchしたのを入れ直す
        else setTeam(company?.teams.find((t) => t.uuid === team.uuid))
    }, [company, team])

    /** Inner Component */

    const infoComponent = () => {
        /** Event */
        const onChangeCompanyFormText = (key: string, val: string) => {
            setCompanyForm({
                ...companyForm,
                [key]: val,
            })
        }
        const onChangeKpiTarget = async (val: boolean) => {
            setKpiForm({ isKpiTarget: val })
            if (!company) return
            await companyQuery({ uuid: company.uuid, body: { isKpiTarget: val } }).unwrap()
        }
        const confirmCompanyDetail = async () => {
            if (!company) return
            await companyQuery({ uuid: company.uuid, body: companyForm }).unwrap()
        }

        const columns = [
            { name: '会社名', key: 'name', placeholder: '株式会社XX' },
            { name: '会社名（かな）', key: 'sound', placeholder: 'かぶしきがいしゃXX' },
            { name: '住所', key: 'address', placeholder: '東京都千代田区...' },
            { name: '電話番号', key: 'phone', placeholder: '00-1111-2222' },
            { name: 'FAX', key: 'fax', placeholder: '00-1111-3333' },
        ] as {
            name: string
            key: keyof AdminCompanyDetailUpdateDto
            placeholder: string
        }[]

        const [companyLogoSave] = useAdminCompanyPutUuidLogoMutation()
        const logoFileUploaded = async (file?: FileEntities) => {
            if (!file || !company) return
            try {
                await companyLogoSave({ uuid: company.uuid, adminCompanyLogoUpdateDto: { logo: file } }).unwrap()
                refetchCompany()
                toast.success('会社ロゴを登録しました')
            } catch (e) {
                toast.error('会社ロゴの登録に失敗しました')
            }
        }

        return (
            <div className={clsx('flex', 'flex-col', 'gap-4', 'bg-white', 'rounded', 'p-4')}>
                <div className={clsx('flex', 'gap-4')}>
                    <div className={clsx('w-2/3', 'flex', 'flex-col', 'gap-4')}>
                        <CHeader label="会社情報" bgClass="bg-kimar-accent" />
                        {isCompanySaveSuccess && (
                            <div className={clsx('bg-green-50', 'text-green-900', 'p-4', 'rounded')}>
                                会社基本情報を変更しました
                            </div>
                        )}
                        <CTable
                            tbody={
                                <>
                                    {columns.map((c) => (
                                        <tr key={c.key}>
                                            <th className={clsx('bg-kimar-primary', 'text-white', 'w-32')}>{c.name}</th>
                                            <td className={clsx('px-2')}>
                                                <CTextInput
                                                    className={clsx('w-full', 'c-text-input-base')}
                                                    type="text"
                                                    text={companyForm[c.key]}
                                                    placeholder={c.placeholder}
                                                    onChange={(val) => {
                                                        onChangeCompanyFormText(c.key, val)
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            }
                        />
                        <div className={clsx('flex', 'justify-center')}>
                            <CButton className={clsx('c-button-primary')} onClick={() => confirmCompanyDetail()}>
                                保存
                            </CButton>
                        </div>
                        <CLabeledItem label="KPI集計対象" horizontal>
                            <td className={clsx('inline-block', 'px-2')}>
                                <CToggleSwitch
                                    value={kpiForm.isKpiTarget}
                                    trueLabel="対象"
                                    falseLabel="対象外"
                                    width="lg"
                                    toggleChanged={(value) => onChangeKpiTarget(value)}
                                />
                            </td>
                        </CLabeledItem>
                    </div>
                    <div className={clsx('w-1/3', 'flex', 'flex-col', 'gap-4')}>
                        <CHeader label="会社ロゴ" bgClass="bg-kimar-accent" />
                        <div className={clsx('p-4', 'flex', 'justify-center', 'items-center')}>
                            {company?.logoFile ? <CompanyLogo company={company} /> : <span>未設定</span>}
                        </div>
                        <CFileUpload
                            isCrop
                            accept="image/*"
                            uploadLabel="画像"
                            aspectRatio={3 / 1}
                            fileUploaded={(files) => logoFileUploaded(files.length ? files[0] : undefined)}
                        />
                        <p>推奨サイズ：横幅 150px、縦幅 50px（比率 3：1）</p>
                    </div>
                </div>
            </div>
        )
    }

    const contractComponent = () => {
        const getConditions = (contract: CompanyContractEntities) => [
            !!contract.recommendFeatureCondition,
            !!contract.connectionFeatureCondition,
            !!contract.marketFeatureCondition,
        ]

        const nowContract = company?.contracts.find((c) => !c.EndAt)

        return (
            <div className={clsx('m-4', 'bg-white', 'rounded', 'p-2')}>
                <CHeader label="契約履歴" bgClass="bg-kimar-accent" />
                <div className={clsx('p-2')}>
                    <div className={clsx('flex', 'justify-end', 'my-2')}>
                        <CButton
                            className={clsx('c-button-primary', 'text-sm')}
                            disabled={!!nowContract}
                            onClick={() => setShowContractInsertModal(true)}>
                            <i className={clsx('material-icons')}>add</i>
                            <div>新規契約登録</div>
                        </CButton>
                    </div>
                    <table className={clsx('w-full')}>
                        <thead className={clsx('sticky', 'top-[0px]', 'text-xs')}>
                            <tr className={clsx('bg-kimar-primary', 'text-white', 'text-left')}>
                                <th>期間</th>
                                <th>契約プラン</th>
                                <th className={clsx('w-20', 'hidden', 'lg:table-cell')}>レコメンド</th>
                                <th className={clsx('w-20', 'hidden', 'lg:table-cell')}>コネクション</th>
                                <th className={clsx('w-20', 'hidden', 'lg:table-cell')}>マーケット</th>
                                <th className={clsx('w-48')}>操作</th>
                            </tr>
                        </thead>
                        <tbody className={clsx('divide-y')}>
                            {company?.contracts.map((contract) => (
                                <tr key={contract.uuid}>
                                    <td>
                                        <div className={clsx('flex')}>
                                            <div>{formatDateTime(contract.startAt, 'date')}</div>
                                            <i className={clsx('material-icons', 'w-5', 'mx-4')}>horizontal_rule_icon</i>
                                            <div>
                                                {contract.EndAt
                                                    ? formatDateTime(contract.EndAt, 'date')
                                                    : dayjs(contract.startAt).isBefore(dayjs(), 'day')
                                                    ? '現在'
                                                    : ''}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{contract.plan.name}</td>
                                    {getConditions(contract).map((condition, index) => (
                                        <td key={index} className={clsx('hidden', 'lg:table-cell')}>
                                            <div className={clsx('flex')}>
                                                <CCheckBox
                                                    className={clsx('p-1', 'rounded', 'flex-1')}
                                                    readonly
                                                    checked={condition}
                                                    label={condition ? '有効' : '無効'}
                                                />
                                            </div>
                                        </td>
                                    ))}
                                    <td className={clsx('flex', 'space-x-1')}>
                                        {!contract.EndAt && (
                                            <CButton
                                                className={clsx('c-button-danger', 'text-sm')}
                                                onClick={() => setContractCancelTarget(contract)}>
                                                解約
                                            </CButton>
                                        )}
                                        <CButton
                                            className={clsx('c-button-primary', 'text-sm')}
                                            onClick={() => setContractUpdateTarget(contract)}>
                                            編集
                                        </CButton>
                                        <CButton
                                            className={clsx('c-button-danger', 'text-sm')}
                                            onClick={() => setContractDeleteTarget(contract)}>
                                            削除
                                        </CButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    const teamComponent = () => {
        /** Inner Component */
        const teamTreeComponent = () => {
            const rootTeam = company?.teams.find((t) => !t.parentTeam)
            if (!company?.teams.length || !rootTeam) return <></>

            /** Event */
            const onSelectNode = (
                selectedKeys: Key[],
                info: {
                    event: 'select'
                    selected: boolean
                    nativeEvent: MouseEvent
                },
            ) => {
                if (!info.selected) {
                    info.nativeEvent.preventDefault()
                    return
                }
                if (selectedKeys.length != 1) return
                setTeam(company?.teams.find((t) => t.uuid === selectedKeys[0]))
            }

            /** Inner Component */
            const treeNode = (teamTreeNode: TeamTreeNode) => {
                return (
                    <TreeNode title={teamTreeNode.model.name} key={teamTreeNode.model.uuid}>
                        {teamTreeNode.children.map((t) => treeNode(t))}
                    </TreeNode>
                )
            }

            return (
                <>
                    <Tree defaultExpandAll showIcon={false} selectedKeys={team ? [team.uuid] : undefined} onSelect={onSelectNode}>
                        {createTeamTree(company.teams).map((t) => treeNode(t))}
                    </Tree>
                </>
            )
        }

        const teamDetail = () => {
            if (!teamUpdateDto) return

            /** Event */
            const onChangeTeamFormText = (key: string, val: string) => {
                setTeamUpdateDto({
                    ...teamUpdateDto,
                    [key]: val,
                })
            }
            const confirmTeamDetail = async () => {
                if (!team) return
                await teamQuery({ uuid: team.uuid, adminTeamUpdateDto: teamUpdateDto }).unwrap()
                refetchCompany()
            }

            const columns = [
                { name: 'チーム名', key: 'name', placeholder: '株式会社XX', text: () => teamUpdateDto.name },
                { name: 'チーム説明', key: 'overview', placeholder: 'かぶしきがいしゃXX', text: () => teamUpdateDto.overview },
                { name: '住所', key: 'address', placeholder: '東京都千代田区...', text: () => teamUpdateDto.address },
                { name: '電話番号', key: 'phone', placeholder: '00-1111-2222', text: () => teamUpdateDto.phone },
                { name: 'FAX', key: 'fax', placeholder: '00-1111-3333', text: () => teamUpdateDto.fax },
            ] as {
                name: string
                key: keyof AdminTeamUpdateDto
                placeholder: string
                text: () => string
            }[]
            return (
                <div>
                    <table className={clsx('w-full')}>
                        <tbody>
                            {columns.map((c) => (
                                <>
                                    <tr>
                                        <td
                                            className={clsx(
                                                'text-center',
                                                'min-w-[150px]',
                                                'bg-kimar-primary',
                                                'text-white',
                                                'border',
                                            )}>
                                            {c.name}
                                        </td>
                                        <td className={clsx('w-full')}>
                                            <CTextInput
                                                className={clsx('w-full', 'c-text-input-base')}
                                                type="text"
                                                placeholder={c.placeholder}
                                                text={c.text() ?? ''}
                                                onChange={(val) => {
                                                    onChangeTeamFormText(c.key, val)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                    <div className={clsx('flex', 'justify-center')}>
                        <CButton className={clsx('c-button-primary')} onClick={() => confirmTeamDetail()}>
                            保存
                        </CButton>
                        {isTeamSaveSuccess && (
                            <div className={clsx('bg-green-50', 'text-green-900', 'p-4', 'rounded')}>
                                チーム基本情報を変更しました
                            </div>
                        )}
                    </div>
                </div>
            )
        }

        const teamMemberDetail = () => {
            if (!team) return
            return (
                <div className={clsx('m-4', 'bg-white', 'rounded', 'p-2')}>
                    <CHeader label="チームメンバー" bgClass="bg-kimar-accent" />
                    <div className={clsx('p-2')}>
                        <div className={clsx('flex', 'justify-end', 'my-2')}>
                            <CButton
                                className={clsx('c-button-primary', 'text-sm')}
                                onClick={() => setShowTeamMemberInsertModal(true)}>
                                <i className={clsx('material-icons')}>add</i>
                                <div>新規メンバー追加</div>
                            </CButton>
                        </div>
                        <table className={clsx('w-full')}>
                            <thead className={clsx('sticky', 'top-[0px]', 'text-xs')}>
                                <tr className={clsx('bg-kimar-primary', 'text-white')}>
                                    <th className={clsx('w-20')}>役職</th>
                                    <th>氏名</th>
                                    <th className={clsx('hidden', 'lg:table-cell')}>メールアドレス</th>
                                    <th className={clsx('hidden', 'lg:table-cell', 'w-18')}>最終ログイン</th>
                                    <th className={clsx('w-12', 'hidden', 'lg:table-cell')}>
                                        会社
                                        <br />
                                        権限
                                    </th>
                                    <th className={clsx('w-12', 'hidden', 'lg:table-cell')}>
                                        チーム
                                        <br />
                                        権限
                                    </th>
                                    <th className={clsx('w-12', 'hidden', 'lg:table-cell')}>
                                        物件
                                        <br />
                                        権限
                                    </th>
                                    <th className={clsx('w-12', 'hidden', 'lg:table-cell')}>
                                        顧客
                                        <br />
                                        権限
                                    </th>
                                    <th className={clsx('w-12', 'hidden', 'lg:table-cell')}>
                                        紹介
                                        <br />
                                        権限
                                    </th>
                                    <th className={clsx('w-48')}>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                {team?.teamMembers.map((teamMember) => (
                                    <>
                                        <tr>
                                            <td>{teamMember.position}</td>
                                            <td className={clsx('break-all', 'max-w-[96px]')}>
                                                {teamMember.name}
                                                {teamMember.deletedAt ? '（削除済）' : ''}
                                            </td>
                                            <td className={clsx('hidden', 'lg:table-cell', 'break-all', 'text-center')}>
                                                {teamMember.email}
                                            </td>
                                            <td
                                                className={clsx(
                                                    'hidden',
                                                    'lg:table-cell',
                                                    'whitespace-pre',
                                                    'text-xs',
                                                    'text-center',
                                                )}>
                                                {formatDateTime(
                                                    sortBy(teamMember.sessions ?? [], 'expire').reverse()[0]?.updatedAt,
                                                    'dateTime',
                                                ).replace(' ', '\n')}
                                            </td>
                                            <td className={clsx('hidden', 'lg:table-cell', 'text-xs')}>
                                                {companyRoles.find((role) => role.value === teamMember.roles.company)?.label}
                                            </td>
                                            <td className={clsx('hidden', 'lg:table-cell', 'text-xs')}>
                                                {teamRoles.find((role) => role.value === teamMember.roles.team)?.label}
                                            </td>
                                            <td className={clsx('hidden', 'lg:table-cell', 'text-xs')}>
                                                {
                                                    realestateRoles.find((role) => role.value === teamMember.roles.realestate)
                                                        ?.label
                                                }
                                            </td>
                                            <td className={clsx('hidden', 'lg:table-cell', 'text-xs')}>
                                                {customerRoles.find((role) => role.value === teamMember.roles.customer)?.label}
                                            </td>
                                            <td className={clsx('hidden', 'lg:table-cell', 'text-xs')}>
                                                {
                                                    intermediaryRoles.find((role) => role.value === teamMember.roles.intermediary)
                                                        ?.label
                                                }
                                            </td>
                                            <td className={clsx('flex', 'space-x-1', 'items-center')}>
                                                <CButton
                                                    className={clsx('c-button-secondary', 'text-sm')}
                                                    onClick={() => setTeamMemberReplaceTarget(teamMember)}>
                                                    移行
                                                </CButton>
                                                {!teamMember.deletedAt && (
                                                    <>
                                                        <CButton
                                                            className={clsx('c-button-primary', 'text-sm')}
                                                            onClick={() => setTeamMemberUpdateTarget(teamMember)}>
                                                            編集
                                                        </CButton>
                                                        <CButton
                                                            className={clsx('c-button-danger', 'text-sm')}
                                                            onClick={() => setTeamMemberDeleteTarget(teamMember)}>
                                                            削除
                                                        </CButton>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }

        return (
            <div className={clsx('m-4', 'bg-white', 'rounded', 'p-2')}>
                <CHeader label="チーム管理" bgClass="bg-kimar-accent" />
                <div className={clsx('p-2')}>
                    <div className={clsx('flex', 'justify-end', 'my-2')}>
                        <CButton className={clsx('c-button-primary')} onClick={() => setShowTeamInsertModal(true)}>
                            <i className={clsx('material-icons')}>add</i>
                            <div>新規チーム追加</div>
                        </CButton>
                    </div>
                    <div className={clsx('flex')}>
                        {!!company?.teams.length && (
                            <>
                                <div className={clsx('max-w-md', 'm-2')}>
                                    <div>
                                        <div className={clsx('text-white', 'bg-kimar-primary', 'text-sm', 'p-1')}>チーム選択</div>
                                        <div className={clsx('border-x', 'border-b', 'rounded-b', 'px-2')}>
                                            {teamTreeComponent()}
                                        </div>
                                    </div>
                                </div>
                                <div className={clsx('flex-auto', 'm-2', 'p-2', 'border', 'rounded')}>
                                    {teamDetail()}
                                    {teamMemberDetail()}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const teamInsertComponent = useMemo(() => {
        if (!company) return <></>

        const closeInsertTeamModal = () => {
            setShowTeamInsertModal(false)
        }

        const submitInsertTeam = () => {
            setShowTeamInsertModal(false)
            refetchCompany()
        }
        return (
            <InsertTeamModal
                company={company}
                parentTeam={team}
                isShow={showTeamInsertModal}
                onSubmit={submitInsertTeam}
                onClose={closeInsertTeamModal}
            />
        )
    }, [company, showTeamInsertModal])

    const teamMemberInsertComponent = useMemo(() => {
        if (!team) return <></>

        const closeInsertTeamMemberModal = () => {
            setShowTeamMemberInsertModal(false)
        }

        const submitInsertTeamMember = () => {
            setShowTeamMemberInsertModal(false)
            refetchCompany()
        }
        return (
            <InsertTeamMemberModal
                team={team}
                isShow={showTeamMemberInsertModal}
                onSubmit={submitInsertTeamMember}
                onClose={closeInsertTeamMemberModal}
            />
        )
    }, [company, team, showTeamMemberInsertModal])

    const teamMemberUpdateComponent = useMemo(() => {
        if (!teamMemberUpdateTarget) return <></>

        const closeUpdateTeamMemberModal = () => {
            setTeamMemberUpdateTarget(undefined)
        }

        const submitUpdateTeamMember = () => {
            setTeamMemberUpdateTarget(undefined)
            refetchCompany()
        }
        return (
            <UpdateTeamMemberModal
                teamMember={teamMemberUpdateTarget}
                isShow={!!teamMemberUpdateTarget}
                onSubmit={submitUpdateTeamMember}
                onClose={closeUpdateTeamMemberModal}
            />
        )
    }, [company, team, teamMemberUpdateTarget])

    const teamMemberDeleteComponent = useMemo(() => {
        const closeCancelModal = () => {
            setTeamMemberDeleteTarget(undefined)
        }
        const confirmCancelModal = async () => {
            if (!teamMemberDeleteTarget) return
            await deleteTeamMemberQuery({ uuid: teamMemberDeleteTarget.uuid }).unwrap()
            closeCancelModal()
            refetchCompany()
        }
        return (
            <CConfirmModal
                visible={!!teamMemberDeleteTarget}
                onRequestClose={closeCancelModal}
                onRequestConfirm={confirmCancelModal}
                confirmLabel={'削除'}>
                <div>チームメンバー {teamMemberDeleteTarget?.name} を削除します。よろしいですか？</div>
            </CConfirmModal>
        )
    }, [company, team, teamMemberDeleteTarget])

    const contractInsertComponent = useMemo(() => {
        if (!company) return <></>

        const closeInsertContractModal = () => {
            setShowContractInsertModal(false)
        }

        const submitInsertContract = () => {
            setShowContractInsertModal(false)
            refetchCompany()
        }
        return (
            <InsertContractModal
                company={company}
                isShow={showContractInsertModal}
                onSubmit={submitInsertContract}
                onClose={closeInsertContractModal}
            />
        )
    }, [company, showContractInsertModal])

    const contractUpdateComponent = useMemo(() => {
        if (!contractUpdateTarget) return <></>

        const closeUpdateContractModal = () => {
            setContractUpdateTarget(undefined)
        }

        const submitUpdateContract = () => {
            setContractUpdateTarget(undefined)
            refetchCompany()
        }
        return (
            <UpdateContractModal
                contract={contractUpdateTarget}
                isShow={!!contractUpdateTarget}
                onSubmit={submitUpdateContract}
                onClose={closeUpdateContractModal}
            />
        )
    }, [company, contractUpdateTarget])

    const contractDeleteComponent = useMemo(() => {
        if (!contractDeleteTarget) return <></>
        const closeCancelModal = () => {
            setContractDeleteTarget(undefined)
        }
        const confirmCancelModal = async () => {
            if (!contractDeleteTarget) return
            await deleteContractQuery({ uuid: contractDeleteTarget.uuid }).unwrap()
            closeCancelModal()
            refetchCompany()
        }
        return (
            <CConfirmModal
                visible={!!contractDeleteTarget}
                onRequestClose={closeCancelModal}
                onRequestConfirm={confirmCancelModal}
                confirmLabel={'削除'}>
                <div className={clsx('flex')}>
                    <div>{formatDateTime(contractDeleteTarget.startAt, 'date')}</div>
                    <i className={clsx('material-icons', 'w-5', 'mx-4')}>horizontal_rule_icon</i>
                    <div>{contractDeleteTarget.EndAt ? formatDateTime(contractDeleteTarget.EndAt, 'date') : '現在'}</div>
                </div>
                <div>上記の契約を削除します。よろしいですか？</div>
            </CConfirmModal>
        )
    }, [company, contractDeleteTarget])

    const contractCancelComponent = useMemo(() => {
        if (!contractCancelTarget) return <></>

        const closeCancelModal = () => {
            setContractCancelTarget(undefined)
        }
        const confirmCancelModal = async () => {
            if (!contractCancelTarget) return
            await cancelCompanyQuery({ uuid: contractCancelTarget.uuid }).unwrap()
            closeCancelModal()
            refetchCompany()
        }

        return (
            <CConfirmModal
                visible={!!contractCancelTarget}
                onRequestClose={closeCancelModal}
                onRequestConfirm={confirmCancelModal}
                confirmLabel={'契約解除'}>
                <div>現状の契約({formatDateTime(contractCancelTarget.startAt, 'date')} 〜)を契約解除します。よろしいですか？</div>
            </CConfirmModal>
        )
    }, [company, contractCancelTarget])

    useEffect(() => {
        setReplaceUuid(team?.teamMembers.filter((m) => !m.deletedAt)[0]?.uuid)
    }, [company, team, teamMemberReplaceTarget])
    const teamMemberReplaceComponent = useMemo(() => {
        const closeReplaceModal = () => {
            setTeamMemberReplaceTarget(undefined)
        }
        const confirmReplaceModal = async () => {
            if (!teamMemberReplaceTarget || !replaceUuid) return
            try {
                await replaceTeamMemberQuery({
                    adminTeamMemberReplaceDto: {
                        baseMemberUuid: teamMemberReplaceTarget.uuid,
                        targetMemberUuid: replaceUuid,
                    },
                }).unwrap()
                closeReplaceModal()
                refetchCompany()
                toast.success('移行が完了しました。')
            } catch (err) {
                toast.error('移行に失敗しました。')
                console.error(err)
            }
        }
        return (
            <CConfirmModal
                visible={!!teamMemberReplaceTarget}
                onRequestClose={closeReplaceModal}
                onRequestConfirm={confirmReplaceModal}
                confirmLabel={'移行'}>
                <div>チームメンバー {teamMemberReplaceTarget?.name} を下記のユーザーに移行します。よろしいですか？</div>
                <CDropDown
                    className={clsx('w-full', 'rounded', 'border-gray-300')}
                    items={team?.teamMembers.filter((m) => !m.deletedAt) ?? []}
                    dataLabel="name"
                    dataValue="uuid"
                    nowValue={replaceUuid}
                    onChange={(value) => setReplaceUuid(value)}
                />
            </CConfirmModal>
        )
    }, [company, team, teamMemberReplaceTarget, replaceUuid])

    if (!isLoading && !company) return <div>404 Error</div>
    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate(true)}>
                <title>会社情報</title>
            </Helmet>
            <div className={clsx('flex', 'flex-col', 'gap-4')}>
                <CHeader label="会社詳細" />
                {infoComponent()}
                {contractComponent()}
                {teamComponent()}
            </div>
            {teamInsertComponent}
            {teamMemberInsertComponent}
            {teamMemberUpdateComponent}
            {teamMemberDeleteComponent}
            {contractInsertComponent}
            {contractUpdateComponent}
            {contractDeleteComponent}
            {contractCancelComponent}
            {teamMemberReplaceComponent}
        </>
    )
}
export default AdminCompanyDetail
