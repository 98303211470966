import clsx from 'clsx'
import { t } from 'i18next'
import { isNil, maxBy, sortBy } from 'lodash'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'
import { Trans } from 'react-i18next'
import { createSearchParams, Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import {
    RealEstateEntities,
    RealEstateLabelEntities,
    useClientCustomerIntermediaryPostReviewFinishMutation,
    useClientRealEstateDeleteUuidMutation,
    useClientRealEstateDeleteUuidOptionMutation,
    useClientRealEstateEndedRealEstateRevertEndedRealEstateMutation,
    useClientRealEstateGetMaxProgressQuery,
    useClientRealEstateHasNoReadTeamActivityQuery,
    useClientRealEstateLabelTeamUuidQuery,
    useClientRealEstateUuidQuery,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CError } from '~/components/common/cError/CError'
import { CHeader } from '~/components/common/cHeader/CHeader'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CFooterModal } from '~/components/common/cModal/CModal'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'
import { CTagList } from '~/components/common/cTagList/CTagList'
import ReactRating from '~/components/common/reactRating/ReactRating'
import CIntermediaryVote from '~/components/unique/intermediary/CIntermediaryVote'
import { CRealestateEdit } from '~/components/unique/realestate/CRealestateEdit'
import { CRealestateOutput } from '~/components/unique/realestate/CRealestateOutput'
import CStreetViewEdit from '~/components/unique/realestate/CStreetViewEdit'
import { CEndedRealestate } from '~/components/unique/realestate/detail/CEndedRealestate'
import CRealestateDetailFile from '~/components/unique/realestate/detail/CRealestateDetailFile'
import { CRealEstateDetailFinishAllIntermediary } from '~/components/unique/realestate/detail/CRealEstateDetailFinishAllIntermediary'
import CRealestateDetailInfo from '~/components/unique/realestate/detail/CRealestateDetailInfo'
import CRealestateDetailIntermediary from '~/components/unique/realestate/detail/CRealestateDetailIntermediary'
import { CRealEstateDetailLabelEdit } from '~/components/unique/realestate/detail/CRealEstateDetailLabelEdit'
import CRealestateDetailManage from '~/components/unique/realestate/detail/CRealestateDetailManage'
import CRealestateDetailMatching from '~/components/unique/realestate/detail/CRealestateDetailMatching'
import { RealEstateOptions, taxType } from '~/types/enum/enum'
import { IApplicationError } from '~/types/error'
import { formatDateTime, fullCurrencyFormat, pageTitleTemplate } from '~/util/common/common'
import { getSignedUrl } from '~/util/model/FileEntities'
import { useAppSelector } from '~/util/store/hooks'
import {
    selectLoginUser,
    selectLoginUserHaveConnectionContract,
    selectLoginUserHaveMarketContract,
    selectLoginUserHaveRecommendContract,
    selectLoginUserTeam,
} from '~/util/store/userSlice'

const RealestateDetail = () => {
    const user = useAppSelector(selectLoginUser)
    const navigate = useNavigate()
    const params = useParams()
    const [isDeleted, setIsDeleted] = useState(false)
    const { realEstateUuid } = params

    const query = new URLSearchParams(useLocation().search)
    const queryParam = {
        intermediaryTo: query.get('intermediaryTo'),
    }

    // レコメンド契約
    const haveRecommendContract = useAppSelector(selectLoginUserHaveRecommendContract)
    // コネクション契約
    const haveConnectionContract = useAppSelector(selectLoginUserHaveConnectionContract)
    // マーケット契約
    const haveMarketContract = useAppSelector(selectLoginUserHaveMarketContract)
    const {
        data: realEstate,
        isLoading,
        isError,
        refetch: refetchRealEstate,
    } = useClientRealEstateUuidQuery(
        {
            uuid: realEstateUuid!,
        },
        { skip: !realEstateUuid || isDeleted },
    )

    if (realEstate?.isSecret) navigate('/realestate?trial=true')

    const userTeam = useAppSelector(selectLoginUserTeam)
    const { data: labelListCount } = useClientRealEstateLabelTeamUuidQuery({ uuid: userTeam?.uuid ?? '' }, { skip: !userTeam })

    const { data: activityDto } = useClientRealEstateHasNoReadTeamActivityQuery(
        {
            realEstateUuids: [realEstateUuid!],
        },
        { skip: !realEstateUuid },
    )

    const getRealEstateImage = (item: RealEstateEntities) => {
        let coverImg = maxBy(
            item.documents?.filter((d) => !!d.isCover),
            'createdAt',
        )?.coverFile
        if (coverImg) return getSignedUrl(coverImg)
        coverImg = maxBy(
            item.documents?.filter((d) => !!d.isCover),
            'createdAt',
        )?.file
        if (coverImg) return getSignedUrl(coverImg)
        return `/src/assets/image/noimage.svg`
    }
    const getRealEstateCompanyImage = (item: RealEstateEntities) => {
        if (item.intermediaryFrom) {
            const url = item.intermediaryFrom.intermediary?.sentBy?.team?.company?.logoFile
                ? getSignedUrl(item.intermediaryFrom.intermediary?.sentBy?.team?.company?.logoFile)
                : undefined
            return url
        }
        const url = item.team?.company?.logoFile ? getSignedUrl(item.team?.company?.logoFile) : undefined
        return url
    }
    const getRealEstateCompanyName = (item: RealEstateEntities) => {
        if (item.intermediaryFrom) return item.intermediaryFrom.intermediary?.sentBy?.team?.company?.name
        else return item.team?.company?.name
    }

    const { data: progressDto, refetch: refetchRealEstateProgress } = useClientRealEstateGetMaxProgressQuery(
        {
            realEstateUuids: [realEstateUuid!],
        },
        { skip: !realEstateUuid },
    )

    const realEstateImageElement = useMemo(() => {
        if (!realEstate) return null
        return (
            <div className={clsx('flex-shrink-0', 'h-[320px]', 'w-[320px]', 'relative')}>
                <img
                    src={getRealEstateImage(realEstate)}
                    alt={realEstate.name}
                    loading="lazy"
                    className={clsx('h-[320px]', 'w-[320px]', 'rounded', 'border', 'border-gray-200', 'object-cover')}
                    crossOrigin="anonymous"
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null
                        currentTarget.src = '/src/assets/image/notfound.svg'
                    }}
                />
                {(() => {
                    const url = getRealEstateCompanyImage(realEstate)
                    if (url) {
                        return (
                            <img
                                src={url}
                                alt={'RealEstate Logo'}
                                loading="lazy"
                                crossOrigin="anonymous"
                                className={clsx(
                                    'absolute',
                                    'max-w-[220px]',
                                    'top-0',
                                    'left-auto',
                                    'bg-white',
                                    'rounded-sm',
                                    'm-1',
                                    'opacity-80',
                                )}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null
                                    currentTarget.src = '/src/assets/image/notfound.svg'
                                }}
                            />
                        )
                    } else {
                        const name = getRealEstateCompanyName(realEstate)
                        return name ? (
                            <div
                                className={clsx(
                                    'absolute',
                                    'max-w-[220px]',
                                    'top-0',
                                    'bg-white',
                                    'rounded-sm',
                                    'text-xs',
                                    'm-1',
                                    'p-1',
                                    'bg-opacity-60',
                                )}>
                                {name}
                            </div>
                        ) : null
                    }
                })()}
            </div>
        )
    }, [realEstate])

    const mapImageElement = useMemo(() => {
        if (!realEstate) return null
        const file = maxBy(
            realEstate?.documents?.filter((d) => !!d.isMap),
            'createdAt',
        )?.file
        const url = file ? getSignedUrl(file) : '/src/assets/image/noimage.svg'
        return (
            <Link
                to={`/map?lat=${realEstate.latitude}&lng=${realEstate.longitude}&uuid=${realEstate.uuid}`}
                target="_blank"
                rel="noopener noreferrer">
                <img
                    src={url}
                    alt=""
                    loading="lazy"
                    className={clsx('h-[320px]', 'w-[320px]', 'rounded', 'border', 'border-gray-200', 'object-cover')}
                    crossOrigin="anonymous"
                    onError={({ currentTarget }) => {
                        console.log(currentTarget.src)
                        currentTarget.onerror = null
                        currentTarget.src = '/src/assets/image/notfound.svg'
                    }}
                />
            </Link>
        )
    }, [realEstate])

    const errors: IApplicationError[] = []
    // タブ表示
    const [selectedTab, setSelectedTab] = useState(queryParam.intermediaryTo ? 'intermediary' : 'file')
    // TODO: 無料会員向けtab処理
    const tabs: Array<{ label: string; value: string }> = useMemo(
        () => [
            { label: t('RealestateDetail.物件資料'), value: 'file' },
            { label: t('RealestateDetail.物件情報'), value: 'detail' },
            {
                label: t('RealestateDetail.マッチング'),
                value: 'market',
                checked: !!(
                    (haveRecommendContract && !!activityDto?.newRecommend.length) ||
                    (haveConnectionContract && !!activityDto?.newConnection.length) ||
                    (haveMarketContract && !!activityDto?.newPublishRequest.length)
                ),
            },
            { label: t('RealestateDetail.紹介先'), value: 'intermediary', checked: !!activityDto?.sendNewChat.length },
            { label: t('RealestateDetail.社内管理'), value: 'management' },
        ],
        [activityDto],
    )

    const [trialModal, setTrialModal] = useState(false)

    // トップ画像に設定
    const documentRefetch = () => {
        refetchRealEstate()
    }

    const managementRefetch = () => {
        refetchRealEstate()
    }

    const onClickChatButton = () => {
        if (!realEstate?.intermediaryFrom) return
        const url = new URL(window.location.origin + '/chat')
        url.search = createSearchParams({
            intermediaryTo: realEstate.intermediaryFrom.uuid,
        }).toString()

        const subWindowProp = {
            w: 700, // サブウインドウの横幅
            h: window.innerHeight * 0.8, // サブウインドウの高さ
            x: window.screenX + 10, // X座標
            y: window.screenY + 10, // Y座標
        }
        window.open(
            url,
            '_blank',
            `width=${subWindowProp.w}, height=${subWindowProp.h}, top=${subWindowProp.y} , left=${subWindowProp.x} `,
        )
    }

    const [openEditModal, setOpenEditModal] = useState(false)

    const isMarket = useMemo(() => {
        return !!realEstate?.markets?.find((m) => !m.deletedAt)
    }, [realEstate])

    const [openRealEstateOutputModal, setOpenRealEstateOutputModal] = useState(false)
    const [openLabelModal, setOpenLabelModal] = useState(false)

    const finishedIntermediary = useMemo(() => {
        if (!realEstate?.intermediaryFrom) return true
        return !!realEstate?.intermediaryFrom?.reviewFinishedAt || !!realEstate?.intermediaryFrom?.intermediaryFinishedAt
    }, [realEstate])

    const hasIntermediaryFrom = useMemo(() => {
        return !!realEstate?.intermediaryFrom
    }, [realEstate])

    const isSending = () => {
        // 紹介している
        return realEstate?.sendStatus === 1
    }

    const getTagArray = () => {
        return user?.roles.realestate === 2
            ? [
                  ...(sortBy(realEstate?.labels, (s) => s.sort).map((label) => ({
                      className: clsx('text-sm', 'px-2'),
                      style: {
                          backgroundColor: label.bgColor,
                          color: label.letterColor,
                      },
                      children: <>{label.name}</>,
                  })) ?? []),
              ]
            : [
                  ...(realEstate?.labels?.map((label) => ({
                      className: clsx('text-sm', 'px-2'),
                      style: {
                          backgroundColor: label.bgColor,
                          color: label.letterColor,
                      },
                      children: <>{label.name}</>,
                  })) ?? []),
              ]
    }

    const [showFinishIntermediaryModal, setShowFinishIntermediaryModal] = useState(false)
    const [finishIntermediaryQuery] = useClientCustomerIntermediaryPostReviewFinishMutation()
    const finishIntermediary = async (sendNotification: boolean) => {
        if (!realEstate?.intermediaryFrom) return
        await finishIntermediaryQuery({
            clientIntermediaryReviewFinishDto: { intermediaryToUuid: realEstate.intermediaryFrom.uuid, sendNotification },
        }).unwrap()
        setShowFinishIntermediaryModal(false)
        refetchRealEstate()
        toast.success(t('RealestateDetail.物件の検討を終了しました'))
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteQuery] = useClientRealEstateDeleteUuidMutation()
    const deleteRealEstate = async () => {
        if (!realEstate) return
        try {
            setIsDeleted(true)
            await deleteQuery({ uuid: realEstate.uuid }).unwrap()
            toast.success(t('RealestateDetail.物件を削除しました'))
            navigate('/realestate')
        } catch {
            setIsDeleted(false)
        }
    }

    const [showEndedModal, setShowEndedModal] = useState(false)

    const [showFinishPublishMarket, setShowFinishPublishMarket] = useState<RealEstateEntities | undefined>(undefined)

    /** ストリートビューから画像 */
    const [streetViewModalVisible, setStreetViewModalVisible] = useState(false)
    const streetViewOpenHandler = () => {
        // GoogleMapをスクリーンショットするときのため、トップにスクロールし、モーダル以外はスクロールをロック
        scrollTo(0, 0)
        // streetViewModalModel = _.cloneDeep(realEstate)
        setStreetViewModalVisible(true)
    }
    const streetViewEditFinished = () => {
        setStreetViewModalVisible(false)
        refetchRealEstate()
    }

    const [openNoDuplicateModal, setOpenNoDuplicateModal] = useState(false)
    const [deleteUuidOption] = useClientRealEstateDeleteUuidOptionMutation()
    const deleteDuplicateOption = async () => {
        if (!realEstate) return
        await deleteUuidOption({
            option: RealEstateOptions.IS_DUPLICATE,
            uuid: realEstate.uuid,
        }).unwrap()
        setOpenNoDuplicateModal(false)
        refetchRealEstate()
        toast.success(t('RealestateDetail.物件を重複物件ではないとしました。'))
    }

    const [showRevertEndedRealEstateModal, setShowRevertEndedRealEstateModal] = useState(false)
    const [revertEndedRealEstateQuery] = useClientRealEstateEndedRealEstateRevertEndedRealEstateMutation()
    const revertEndedRealEstate = async () => {
        if (!realEstate) return
        await revertEndedRealEstateQuery({
            realEstateUuid: realEstate.uuid,
        }).unwrap()
        setShowRevertEndedRealEstateModal(false)
        refetchRealEstate()
        toast.success(t('RealestateDetail.物件終了を解除しました。'))
    }

    if (isLoading) return <CMessage info>{t('Message.読み込み中です…')}</CMessage>
    if (isError) return <>{navigate('/realestate')}</>
    if (!realEstate) return null

    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>{t('RealestateDetail.物件詳細')}</title>
            </Helmet>
            <CError errors={errors} />
            {/* ヘッダ */}
            <div className={clsx('flex', 'flex-col', 'md:flex-row', 'gap-2', 'w-full', 'max-w-7xl', 'mx-auto')}>
                <div className={clsx('flex', 'p-2', 'flex-col', 'space-y-2', 'rounded-lg', 'w-full')}>
                    <div className={clsx('flex', 'w-full', 'flex-col', 'md:flex-row', 'gap-2', 'min-w-0')}>
                        <div className={clsx('flex-grow', 'flex', 'flex-col', 'gap-2', 'min-w-0')}>
                            <div className={clsx('flex', 'flex-col', 'md:flex-row')}>
                                <div className={clsx('flex', 'flex-col', 'min-w-0', 'w-full')}>
                                    <div className={clsx('flex-grow', 'text-2xl', 'text-gray-700', 'font-bold')}>
                                        {realEstate.name}
                                    </div>
                                    <div className={clsx('text-xs', 'text-gray-700')}>{realEstate.address}</div>
                                    <div className={clsx('flex', 'items-center')}>
                                        <div
                                            className={clsx(
                                                'text-xs',
                                                'text-white',
                                                'bg-gray-500',
                                                'py-0.5',
                                                'px-1',
                                                'mr-1',
                                                'whitespace-nowrap',
                                            )}>
                                            {realEstate.useType.name}
                                        </div>
                                        {realEstate.ended && (
                                            <div
                                                className={clsx(
                                                    'text-xs',
                                                    'text-gray-700',
                                                    'bg-gray-300',
                                                    'py-0.5',
                                                    'px-1',
                                                    'mr-1',
                                                    'whitespace-nowrap',
                                                )}>
                                                {t('RealestateDetail.物件終了')}
                                            </div>
                                        )}
                                        {realEstate.option === RealEstateOptions.IS_DUPLICATE && (
                                            <div
                                                className={clsx(
                                                    'text-xs',
                                                    'text-white',
                                                    'bg-red-500',
                                                    'py-0.5',
                                                    'px-1',
                                                    'mr-1',
                                                    'whitespace-nowrap',
                                                )}>
                                                {t('RealestateDetail.重複')}
                                            </div>
                                        )}
                                        <div className={clsx('flex', 'items-center')}>
                                            <CTagList horizontal items={getTagArray()} />
                                        </div>
                                    </div>
                                </div>
                                <div className={clsx('flex', 'md:flex-shrink-0', 'gap-2')}>
                                    <div
                                        className={clsx(
                                            'flex-grow',
                                            'overflow-hidden',
                                            'flex',
                                            'flex-col',
                                            'items-start',
                                            'md:items-end',
                                        )}>
                                        <div
                                            className={clsx(
                                                'whitespace-nowrap',
                                                'flex',
                                                'md:flex-col',
                                                'items-start',
                                                'flex-shrink-0',
                                                'overflow-auto',
                                                'flex-wrap',
                                            )}>
                                            {!isNil(realEstate.price) && (
                                                <div className={clsx('flex', 'items-baseline', 'justify-end')}>
                                                    <div className={clsx('text-sm', 'text-kimar-primary')}>
                                                        {t('RealestateDetail.物件価格')}
                                                    </div>
                                                    <div className={clsx('text-lg', 'text-kimar-primary', 'font-bold')}>
                                                        {fullCurrencyFormat(realEstate.price)}
                                                    </div>
                                                    <div className={clsx('text-sm', 'text-kimar-primary')}>{t('Unit.円')}</div>
                                                    {!isNil(realEstate.taxType) && (
                                                        <div className={clsx('text-sm', 'text-kimar-primary')}>{`(${
                                                            taxType.find((t) => t.value === realEstate.taxType)?.label
                                                        })`}</div>
                                                    )}
                                                </div>
                                            )}
                                            {!isNil(realEstate.intermediaryFrom?.intermediary?.price) && (
                                                <div className={clsx('flex', 'items-baseline', 'justify-end')}>
                                                    <div className={clsx('text-xs', 'text-kimar-primary')}>
                                                        {'(' + t('RealestateDetail.提示価格')}
                                                    </div>
                                                    <div className={clsx('text-xs', 'text-kimar-primary', 'font-bold')}>
                                                        {fullCurrencyFormat(realEstate.intermediaryFrom?.intermediary?.price)}
                                                    </div>
                                                    <div className={clsx('text-xs', 'text-kimar-primary')}>
                                                        {t('Unit.円') + ')'}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={clsx('hidden', 'md:block', 'flex-shrink-0')}>
                                        <div className={clsx('flex', 'flex-col', 'gap-1')}>
                                            {!!realEstate.markets?.length && (
                                                <>
                                                    <CCheckBox
                                                        size="xs"
                                                        readonly
                                                        color="market"
                                                        checked
                                                        label={t('RealestateDetail.マーケット掲載中')}
                                                    />
                                                </>
                                            )}
                                            {!realEstate.markets?.length && (
                                                <>
                                                    <CCheckBox
                                                        size="xs"
                                                        readonly
                                                        color="market"
                                                        checked={false}
                                                        label={t('RealestateDetail.マーケット非掲載')}
                                                    />
                                                </>
                                            )}
                                            {realEstate.publishRequests?.find((p) => !p.checkedAt) ? (
                                                <CCheckBox
                                                    size="xs"
                                                    readonly
                                                    color="accent"
                                                    checked
                                                    label={t('RealestateDetail.リクエストあり')}
                                                />
                                            ) : (
                                                <CCheckBox
                                                    size="xs"
                                                    readonly
                                                    color="accent"
                                                    label={t('RealestateDetail.リクエストなし')}
                                                />
                                            )}
                                            {isSending() ? (
                                                <>
                                                    <div className={clsx('flex', 'items-center', 'gap-1')}>
                                                        <CCheckBox
                                                            size="xs"
                                                            readonly
                                                            checked
                                                            label={t('RealestateDetail.紹介済')}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={clsx('flex', 'items-center', 'gap-1')}>
                                                        <CCheckBox size="xs" readonly label={t('RealestateDetail.未紹介')} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={clsx('flex', 'md:block', 'gap-2')}>
                                <div className={clsx('block', 'md:hidden')}>
                                    <div className={clsx('flex-shrink-0')}>
                                        <div className={clsx('flex', 'flex-row', 'gap-1')}>
                                            {!!realEstate.markets?.length && (
                                                <>
                                                    <CCheckBox
                                                        size="xs"
                                                        readonly
                                                        color="market"
                                                        checked
                                                        label={t('RealestateDetail.マーケット掲載中')}
                                                    />
                                                </>
                                            )}
                                            {!realEstate.markets?.length && (
                                                <>
                                                    <CCheckBox
                                                        size="xs"
                                                        readonly
                                                        color="market"
                                                        checked={false}
                                                        label={t('RealestateDetail.マーケット非掲載')}
                                                    />
                                                </>
                                            )}
                                            {realEstate.publishRequests?.find((p) => !p.checkedAt) ? (
                                                <CCheckBox
                                                    size="xs"
                                                    readonly
                                                    color="accent"
                                                    checked
                                                    label={t('RealestateDetail.リクエストあり')}
                                                />
                                            ) : (
                                                <CCheckBox
                                                    size="xs"
                                                    readonly
                                                    color="accent"
                                                    label={t('RealestateDetail.リクエストなし')}
                                                />
                                            )}
                                            {isSending() ? (
                                                <>
                                                    <div className={clsx('flex', 'items-center', 'gap-1')}>
                                                        <CCheckBox
                                                            size="xs"
                                                            readonly
                                                            checked
                                                            label={t('RealestateDetail.紹介済')}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className={clsx('flex', 'items-center', 'gap-1')}>
                                                        <CCheckBox size="xs" readonly label={t('RealestateDetail.未紹介')} />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className={clsx(
                                'flex-shrink-0',
                                'flex',
                                'md:flex-col',
                                'flex-row',
                                'flex-wrap',
                                'justify-center',
                                'w-full',
                                'md:w-32',
                                'gap-1',
                            )}>
                            <div className={clsx('flex', 'justify-center', 'items-center', 'flex-col')}>
                                <div className={clsx('text-xs')}>{t('RealestateDetail.進捗')}</div>
                                <ReactRating
                                    initialRating={progressDto?.items.length ? progressDto.items[0].progress : 0}
                                    emptySymbol={<i className={clsx('material-icons-outlined')}>play_arrow</i>}
                                    fullSymbol={<i className={clsx('material-icons')}>play_arrow</i>}
                                    readonly={true}
                                />
                            </div>
                            {user?.roles.intermediary === 2 && !realEstate.ended ? (
                                <CLinkButton
                                    className={clsx(
                                        'c-button-primary',
                                        'text-sm',
                                        'flex-1',
                                        'md:flex-none',
                                        'md:row-start-4',
                                        'md:w-full',
                                    )}
                                    to={`/realestate/${realEstate.uuid}/intermediary`}>
                                    {t('RealestateDetail.紹介')}
                                </CLinkButton>
                            ) : (
                                <CButton
                                    className={clsx(
                                        'c-button-primary',
                                        'text-sm',
                                        'flex-1',
                                        'md:flex-none',
                                        'md:row-start-3',
                                        'md:w-full',
                                    )}
                                    disabled
                                    preventDefault>
                                    {t('RealestateDetail.紹介')}
                                </CButton>
                            )}
                            <CButton
                                className={clsx(
                                    'c-button-primary',
                                    'text-sm',
                                    'flex-1',
                                    'md:flex-none',
                                    'md:row-start-3',
                                    'md:w-full',
                                )}
                                onClick={() => setOpenEditModal(true)}
                                disabled={user?.roles.realestate !== 2}
                                preventDefault>
                                {t('RealestateDetail.編集')}
                            </CButton>
                            <CButton
                                className={clsx(
                                    'c-button-primary',
                                    'text-sm',
                                    'flex-1',
                                    'md:flex-none',
                                    'md:row-start-1',
                                    'flex',
                                    'md:w-full',
                                )}
                                onClick={() => setOpenLabelModal(true)}
                                disabled={user?.roles.realestate !== 2}
                                preventDefault>
                                {t('RealestateDetail.ラベル編集')}
                            </CButton>
                            <CButton
                                className={clsx(
                                    'c-button-primary',
                                    'text-sm',
                                    'flex-1',
                                    'md:flex-none',
                                    'md:row-start-1',
                                    'flex',
                                    'md:w-full',
                                )}
                                onClick={() => onClickChatButton()}
                                disabled={
                                    !realEstate.intermediaryFrom ||
                                    !!realEstate.intermediaryFrom.intermediaryFinishedAt ||
                                    !!realEstate.intermediaryFrom.reviewFinishedAt
                                }
                                disabledText={
                                    !realEstate.intermediaryFrom
                                        ? t('RealestateDetail.紹介された物件ではありません')
                                        : realEstate.intermediaryFrom.intermediaryFinishedAt
                                        ? t('RealestateDetail.すでに紹介が終了されています')
                                        : realEstate.intermediaryFrom.reviewFinishedAt
                                        ? t('RealestateDetail.すでに検討終了済みです')
                                        : ''
                                }
                                preventDefault>
                                {t('RealestateDetail.チャット')}
                            </CButton>
                        </div>
                    </div>
                </div>
            </div>
            {realEstate && (
                <div className={clsx('w-full', 'max-w-7xl', 'mx-auto', 'flex', 'flex-col', 'md:flex-row', 'gap-2')}>
                    {/* 物件情報 */}
                    <div className={clsx('flex', 'flex-col', 'space-y-2', 'items-center')}>
                        {/* トップ画像 */}
                        {realEstateImageElement}
                        <div className={clsx('w-full')}>
                            {user?.roles.realestate === 2 && (
                                <CButton
                                    className={clsx('c-button-secondary', 'text-sm', 'w-full')}
                                    onClick={() => streetViewOpenHandler()}>
                                    <div>{t('CRealestateDetailFile.ストリートビューからトップ画像を作成する')}</div>
                                </CButton>
                            )}
                        </div>
                        {/* 物件地図 */}
                        <div className={clsx('h-[320px]', 'w-[320px]', 'flex')}>{mapImageElement}</div>
                        <div className={clsx('w-[320px]')}>
                            <table className={clsx('text-xs', 'w-full')}>
                                <tbody>
                                    <tr className={clsx('')}>
                                        <td className={clsx('p-0.5', 'whitespace-nowrap', 'text-gray-500', 'bg-gray-200')}>
                                            物件登録日
                                        </td>
                                        <td className={clsx('p-0.5', 'whitespace-nowrap')}>
                                            {formatDateTime(realEstate.createdAt, 'date')}
                                        </td>
                                        <td className={clsx('p-0.5', 'whitespace-nowrap', 'text-gray-500', 'bg-gray-200')}>
                                            物件登録者
                                        </td>
                                        <td className={clsx('p-0.5', 'break-all')}>{realEstate.createdBy?.name}</td>
                                    </tr>
                                    <tr className={clsx('')}>
                                        <td className={clsx('p-0.5', 'whitespace-nowrap', 'text-gray-500', 'bg-gray-200')}>
                                            情報取得日
                                        </td>
                                        <td className={clsx('p-0.5', 'whitespace-nowrap')}>
                                            {formatDateTime(realEstate.acquiredAt, 'date')}
                                        </td>
                                        <td className={clsx('p-0.5', 'whitespace-nowrap', 'text-gray-500', 'bg-gray-200')}>
                                            情報取得元
                                        </td>
                                        <td className={clsx('p-0.5', 'break-all')}>{realEstate.informationSource}</td>
                                    </tr>
                                    <tr className={clsx('')}>
                                        <td className={clsx('p-0.5', 'whitespace-nowrap', 'text-gray-500', 'bg-gray-200')}>
                                            最終更新日
                                        </td>
                                        <td className={clsx('p-0.5', 'whitespace-nowrap')}>
                                            {formatDateTime(realEstate.updatedAt, 'date')}
                                        </td>
                                        <td className={clsx('p-0.5', 'whitespace-nowrap', 'text-gray-500', 'bg-gray-200')}>
                                            最終更新者
                                        </td>
                                        <td className={clsx('p-0.5', 'break-all')}>{realEstate.updatedBy?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* 物件詳細 */}
                    <div className={clsx('max-w-full', 'rounded', 'flex-grow', 'flex', 'flex-col', 'gap-2', 'p-4', 'bg-white')}>
                        <div className={clsx('max-w-[900px]', 'w-full', 'mx-auto')}>
                            <CSegmentedControl
                                items={tabs}
                                onClick={(val) => setSelectedTab(val as string)}
                                selectedTabValue={selectedTab}
                            />
                        </div>
                        {selectedTab === 'detail' && (
                            <div id="detail">
                                <div className={clsx('flex', 'flex-col', 'md:flex-row', 'justify-end', 'gap-2', 'mb-4')}>
                                    {realEstate.option === RealEstateOptions.IS_DUPLICATE && (
                                        <CButton
                                            disabled={user?.roles.realestate !== 2 || !!realEstate.ended}
                                            className={clsx('text-sm', 'c-button-primary')}
                                            onClick={() => {
                                                setOpenNoDuplicateModal(true)
                                            }}>
                                            {t('RealestateDetail.重複物件ではないとする')}
                                        </CButton>
                                    )}
                                    {hasIntermediaryFrom && !finishedIntermediary && (
                                        <CButton
                                            disabled={user?.roles.realestate !== 2}
                                            className={clsx('text-sm', 'c-button-market')}
                                            onClick={() => {
                                                setShowFinishIntermediaryModal(true)
                                            }}>
                                            {t('RealestateDetail.検討終了')}
                                        </CButton>
                                    )}
                                    <CButton
                                        disabled={user?.roles.realestate !== 2}
                                        className={clsx('text-sm', 'c-button-primary')}
                                        onClick={() => {
                                            setOpenEditModal(true)
                                        }}>
                                        {t('RealestateDetail.物件編集')}
                                    </CButton>
                                    <CButton
                                        disabled={user?.roles.realestate !== 2}
                                        className={clsx('c-button-primary', 'text-sm')}
                                        onClick={() => setOpenRealEstateOutputModal(true)}>
                                        {t('RealestateDetail.物件概要書出力')}
                                    </CButton>
                                    {!realEstate.ended && (
                                        <CButton
                                            disabled={!(user?.roles.realestate === 2)}
                                            className={clsx('c-button-danger', 'text-sm')}
                                            onClick={() => {
                                                setShowEndedModal(true)
                                            }}>
                                            {t('RealestateDetail.物件終了')}
                                        </CButton>
                                    )}
                                    {!!realEstate.ended && (
                                        <CButton
                                            disabled={!(user?.roles.realestate === 2)}
                                            className={clsx('c-button-secondary', 'text-sm')}
                                            onClick={() => {
                                                setShowRevertEndedRealEstateModal(true)
                                            }}>
                                            {t('RealestateDetail.物件終了を解除')}
                                        </CButton>
                                    )}
                                    <CButton
                                        disabled={
                                            !(finishedIntermediary && realEstate.sendStatus !== 1 && user?.roles.realestate === 2)
                                        }
                                        disabledText={t('RealestateDetail.紹介中か物件編集権限がないため操作できません')}
                                        className={clsx('c-button-danger', 'text-sm')}
                                        onClick={() => {
                                            setShowDeleteModal(true)
                                        }}>
                                        {t('RealestateDetail.物件削除')}
                                    </CButton>
                                </div>
                                {/* 物件情報 */}
                                <CRealestateDetailInfo realEstate={realEstate} isMarket={isMarket} />
                            </div>
                        )}
                        {selectedTab === 'file' && (
                            <div id="file-upload">
                                <CRealestateDetailFile realEstate={realEstate} refetch={() => documentRefetch()} />
                            </div>
                        )}
                        {selectedTab === 'market' && (
                            <div id="market" className={clsx('overflow-y-auto', 'h-full')}>
                                <CRealestateDetailMatching
                                    activity={activityDto}
                                    realEstate={realEstate}
                                    setOpenEditModal={setOpenEditModal}
                                />
                            </div>
                        )}
                        {selectedTab === 'intermediary' && (
                            <div id="intermediary">
                                <CRealestateDetailIntermediary
                                    realEstate={realEstate}
                                    realestateDetailIntermediaryChanged={() => {
                                        refetchRealEstateProgress()
                                    }}
                                />
                            </div>
                        )}
                        {selectedTab === 'management' && (
                            <div id="management">
                                <CRealestateDetailManage realEstate={realEstate} refetch={managementRefetch} />
                            </div>
                        )}
                    </div>
                </div>
            )}
            {/* アンケート */}
            {realEstate && (
                <CIntermediaryVote intermediaryTo={realEstate.intermediaryFrom} isVoteAvailable={realEstate.voteAvailable} />
            )}
            <CFooterModal
                footer={
                    <>
                        <CButton
                            className={clsx('c-button-secondary')}
                            onClick={() => {
                                setTrialModal(false)
                            }}>
                            {t('Button.閉じる')}
                        </CButton>
                    </>
                }
                visible={trialModal}
                onRequestClose={() => {
                    setTrialModal(false)
                }}>
                <h3>
                    {t('RealestateDetail.無料会員は1件まで物件情報の紹介が可能です。')}
                    <br />
                    <Trans i18nKey="RealestateDetail.紹介可能な物件数を無制限とするには、こちらからお問い合わせください。">
                        紹介可能な物件数を無制限とするには、
                        <br />
                        <Link to="/inquiry">こちら</Link>からお問い合わせください。
                    </Trans>
                </h3>
            </CFooterModal>
            <CRealestateEdit
                visible={openEditModal}
                realEstate={realEstate}
                onSubmit={(realEstate, isShowFinishPublishMarket) => {
                    setOpenEditModal(false)
                    if (isShowFinishPublishMarket) setShowFinishPublishMarket(realEstate)
                }}
                onClose={() => {
                    setOpenEditModal(false)
                }}
            />
            <CRealestateOutput
                visible={openRealEstateOutputModal}
                realEstate={realEstate}
                onSubmit={() => {
                    setOpenRealEstateOutputModal(false)
                }}
                onClose={() => {
                    setOpenRealEstateOutputModal(false)
                }}
            />
            <CRealEstateDetailLabelEdit
                visible={openLabelModal}
                realEstate={realEstate}
                teamRealEstateLabels={(labelListCount?.list ?? []) as RealEstateLabelEntities[]}
                onSubmit={() => {
                    setOpenLabelModal(false)
                    refetchRealEstate()
                }}
                onClose={() => {
                    setOpenLabelModal(false)
                    refetchRealEstate()
                }}
            />
            <CFooterModal
                visible={showFinishIntermediaryModal}
                onRequestClose={() => setShowFinishIntermediaryModal(false)}
                footer={
                    <>
                        <CButton className={clsx('c-button-secondary')} onClick={() => setShowFinishIntermediaryModal(false)}>
                            {t('Button.キャンセル')}
                        </CButton>
                        <CButton className={clsx('c-button-primary')} onClick={() => finishIntermediary(false)}>
                            {t('RealestateDetail.通知を送らず検討終了')}
                        </CButton>
                        <CButton className={clsx('c-button-primary')} onClick={() => finishIntermediary(true)}>
                            {t('RealestateDetail.通知を送って検討終了')}
                        </CButton>
                    </>
                }>
                <div className={clsx('p-2')}>
                    <CHeader label={t('Dialog.確認メッセージ')} />
                    <div className={clsx('p-2')}>{t('RealestateDetail.本紹介物件の検討を終了します。よろしいですか？')}</div>
                </div>
            </CFooterModal>
            <CConfirmModal
                visible={showDeleteModal}
                onRequestClose={() => setShowDeleteModal(false)}
                onRequestConfirm={() => deleteRealEstate()}
                confirmLabel={t('RealestateDetail.削除確定')}>
                <div>{t('RealestateDetail.物件を削除します。よろしいですか？')}</div>
            </CConfirmModal>
            <CConfirmModal
                visible={openNoDuplicateModal}
                onRequestClose={() => setOpenNoDuplicateModal(false)}
                onRequestConfirm={() => deleteDuplicateOption()}
                confirmLabel={t('Button.確定')}>
                <div>{t('RealestateDetail.物件を重複物件ではないとします。よろしいですか？')}</div>
            </CConfirmModal>
            <CEndedRealestate
                visible={!!showEndedModal}
                realEstate={realEstate}
                onSubmit={() => {
                    setShowEndedModal(false)
                    refetchRealEstate()
                }}
                onClose={() => {
                    setShowEndedModal(false)
                }}
            />
            <CConfirmModal
                visible={showRevertEndedRealEstateModal}
                onRequestClose={() => setShowRevertEndedRealEstateModal(false)}
                onRequestConfirm={() => revertEndedRealEstate()}
                confirmLabel={t('Button.確定')}>
                <div>{t('RealestateDetail.物件終了を解除します。よろしいですか？')}</div>
            </CConfirmModal>
            <CRealEstateDetailFinishAllIntermediary
                visible={!!showFinishPublishMarket}
                realEstate={showFinishPublishMarket}
                onSubmit={() => {
                    setShowFinishPublishMarket(undefined)
                    refetchRealEstate()
                }}
                onClose={() => setShowFinishPublishMarket(undefined)}
            />
            <CStreetViewEdit visible={streetViewModalVisible} realEstate={realEstate} closed={() => streetViewEditFinished()} />
        </>
    )
}

export default RealestateDetail
