import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast/headless'
import { useLocation } from 'react-router-dom'

import {
    FileEntities,
    IntermediaryToChatEntities,
    useClientIntermediaryToDeleteChatMutation,
    useClientIntermediaryToPostChatMutation,
    useClientIntermediaryToUuidChatQuery,
    useClientIntermediaryToViewChatMutation,
} from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { CIconTips } from '~/components/common/cGuideTips/CGuideTips'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import CNotification from '~/components/common/cNotification/CNotification'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { formatDateTime } from '~/util/common/common'
import { getSignedUrl } from '~/util/model/FileEntities'
import { useAppSelector } from '~/util/store/hooks'
import { selectLoginUser } from '~/util/store/userSlice'

const Chat = () => {
    const user = useAppSelector(selectLoginUser)

    const search = useLocation().search
    const query = new URLSearchParams(search)
    const param = {
        intermediaryTo: query.get('intermediaryTo'),
        isSentTeam: Boolean(query.get('isSentTeam')),
    }

    const { data, refetch, isSuccess, isLoading } = useClientIntermediaryToUuidChatQuery(
        { uuid: param.intermediaryTo! },
        { skip: !param.intermediaryTo },
    )

    const scrollBottomRef = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        scrollBottomRef?.current?.scrollIntoView()
    }, [data])

    const [viewChatQuery] = useClientIntermediaryToViewChatMutation()
    useEffect(() => {
        if (isSuccess && data) viewChatQuery({ uuid: data.intermediaryTo.uuid })
    }, [isSuccess])

    const [content, setContent] = useState('')
    const [attachedFile, setAttachedFile] = useState<FileEntities>()
    const [insertChatQuery] = useClientIntermediaryToPostChatMutation()
    const insertButtonHandler = async () => {
        if (!data?.intermediaryTo || (content.length === 0 && !attachedFile)) return
        await insertChatQuery({
            uuid: data.intermediaryTo.uuid,
            clientIntermediaryToChatInsertDto: {
                content,
                attachmentUuid: attachedFile?.uuid,
            },
        })
        setContent('')
        setAttachedFile(undefined)
        refetch()
        toast.success(t('Chat.チャットを送信しました'))
    }
    const fileUploaded = async (file?: FileEntities) => {
        if (!data?.intermediaryTo) return
        setAttachedFile(file)
    }
    const isImageFile = (file?: FileEntities) => {
        if (file?.mimeType.match(/^image\//)) return true
        return false
    }
    const [deleteTarget, setDeleteTarget] = useState<IntermediaryToChatEntities>()
    const [deleteChat] = useClientIntermediaryToDeleteChatMutation()
    const closeCancelModal = () => {
        setDeleteTarget(undefined)
    }
    const confirmCancelModal = async () => {
        if (!data?.intermediaryTo || !deleteTarget) return
        await deleteChat({ uuid: data.intermediaryTo.uuid, chatUuid: deleteTarget.uuid }).unwrap()
        closeCancelModal()
        refetch()
        toast.success(t('Chat.チャットを削除しました'))
    }

    if (isLoading) return <CMessage info>{t('Message.読み込み中です…')}</CMessage>

    if (!user || !data?.intermediaryTo) return null

    return (
        <div className={clsx('w-full', 'h-full', 'font-body', 'text-gray-700', 'bg-gray-100')}>
            <CNotification />
            <div
                className={clsx(
                    'fixed',
                    'top-0',
                    'left-0',
                    'w-full',
                    'h-[45px]',
                    'bg-white',
                    'flex',
                    'items-center',
                    'border-t',
                    'border-b',
                    'z-10',
                )}>
                {data?.intermediaryTo && (
                    <div className={clsx('text-gray-700', 'text-lg', 'mx-4', 'font-bold')}>
                        {t(
                            !data?.intermediaryTo.destinationTo?.relatedMember
                                ? 'Chat.companyName customerNameへの紹介メッセージ'
                                : 'Chat.companyName customerNameとチャット',
                            data.intermediaryTo.destinationTo?.relatedMember?.team.uuid === user.team.uuid
                                ? {
                                      companyName: data.intermediaryTo.intermediary.sentBy?.team.company.name || '',
                                      customerName: data.intermediaryTo.intermediary.sentBy?.name || '',
                                  }
                                : {
                                      companyName: data.intermediaryTo.destinationTo?.companyName || '',
                                      customerName: data.intermediaryTo.destinationTo?.name || '',
                                  },
                        )}
                    </div>
                )}
            </div>
            <div className={clsx('w-full', 'h-full', 'pt-[45px]', 'pb-[126px]', 'overflow-auto')}>
                {!data?.intermediaryTo.destinationTo?.relatedMember ? (
                    <div className={clsx('m-4')}>
                        {data?.intermediaryTo.intermediary.message ? (
                            <CMessage color={'white'}>{data?.intermediaryTo.intermediary.message}</CMessage>
                        ) : (
                            <CMessage info>{t('Chat.紹介メッセージはありません')}</CMessage>
                        )}
                    </div>
                ) : (data?.chatList?.length ?? 0) > 0 ? (
                    <div className={clsx('py-2')}>
                        {data?.chatList?.map((intermediaryChat) => {
                            if (intermediaryChat.isSentTeam === param.isSentTeam) {
                                return (
                                    <div key={intermediaryChat.uuid} className={clsx('flex', 'gap-0.5', 'm-2', 'justify-end')}>
                                        <div
                                            className={clsx(
                                                'w-20',
                                                'flex-shrink-0',
                                                'h-auto',
                                                'flex',
                                                'flex-col',
                                                'items-end',
                                                'justify-end',
                                            )}>
                                            <div className={clsx('text-xs', 'text-gray-500', 'text-right')}>
                                                {formatDateTime(intermediaryChat.createdAt, 'date')}
                                                <br />
                                                {formatDateTime(intermediaryChat.createdAt, 'time')}
                                            </div>
                                            {intermediaryChat.sentBy?.uuid === user.uuid && user?.roles.realestate === 2 && (
                                                <CButton
                                                    className={clsx('rounded-md', 'text-red-700', 'text-xs')}
                                                    onClick={() => setDeleteTarget(intermediaryChat)}>
                                                    {t('Button.削除')}
                                                </CButton>
                                            )}
                                        </div>
                                        <div className={clsx('flex-shrink')}>
                                            <div
                                                className={clsx(
                                                    'bg-kimar-primary',
                                                    'rounded',
                                                    'text-white',
                                                    'px-3',
                                                    'py-1',
                                                    'whitespace-pre-wrap',
                                                )}>
                                                {intermediaryChat.content && <div>{intermediaryChat.content}</div>}
                                                {intermediaryChat.attachment && (
                                                    <a
                                                        className={clsx(
                                                            'underline',
                                                            'cursor-pointer',
                                                            'flex',
                                                            'flex-col',
                                                            'items-end',
                                                        )}
                                                        href={getSignedUrl(intermediaryChat.attachment)}
                                                        target="_blank">
                                                        {isImageFile(intermediaryChat.attachment) && (
                                                            <img
                                                                className={clsx('max-w-[200px]')}
                                                                src={getSignedUrl(intermediaryChat.attachment)}
                                                            />
                                                        )}
                                                        <span>{intermediaryChat.attachment.filename}</span>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={clsx(
                                                'w-3',
                                                'h-3',
                                                'bg-kimar-primary',
                                                '-ml-3',
                                                'mr-3',
                                                'mt-2.5',
                                                'rotate-45',
                                                'flex-shrink-0',
                                            )}
                                        />
                                        <CIconTips
                                            icon={
                                                <i
                                                    className={clsx(
                                                        'w-12',
                                                        'h-12',
                                                        'rounded-full',
                                                        'bg-gray-200',
                                                        'flex',
                                                        'peer',
                                                        'justify-center',
                                                        'items-center',
                                                    )}>
                                                    {intermediaryChat.sentBy?.name.charAt(0)}
                                                </i>
                                            }
                                            tooltipDirection="left">
                                            {intermediaryChat.sentBy?.name}
                                        </CIconTips>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={intermediaryChat.uuid} className={clsx('flex', 'gap-0.5', 'm-2')}>
                                        <CIconTips
                                            icon={
                                                <i
                                                    className={clsx(
                                                        'w-12',
                                                        'h-12',
                                                        'rounded-full',
                                                        'bg-gray-200',
                                                        'flex',
                                                        'peer',
                                                        'justify-center',
                                                        'items-center',
                                                    )}>
                                                    {intermediaryChat.sentBy ? intermediaryChat.sentBy.name.charAt(0) : '―'}
                                                </i>
                                            }
                                            tooltipDirection="right">
                                            {intermediaryChat.sentBy
                                                ? intermediaryChat.sentBy.name
                                                : t('Chat.削除されたユーザー')}
                                        </CIconTips>
                                        <div
                                            className={clsx(
                                                'w-3',
                                                'h-3',
                                                'bg-gray-200',
                                                '-mr-3',
                                                'ml-3',
                                                'mt-2.5',
                                                'rotate-45',
                                                'flex-shrink-0',
                                            )}
                                        />
                                        <div className={clsx('flex-shrink')}>
                                            <div
                                                className={clsx('bg-gray-200', 'rounded', 'px-3', 'py-1', 'whitespace-pre-wrap')}>
                                                {intermediaryChat.content && <div>{intermediaryChat.content}</div>}
                                                {intermediaryChat.attachment && (
                                                    <a
                                                        className={clsx(
                                                            'underline',
                                                            'cursor-pointer',
                                                            'flex',
                                                            'flex-col',
                                                            'items-start',
                                                        )}
                                                        href={getSignedUrl(intermediaryChat.attachment)}
                                                        target="_blank">
                                                        {isImageFile(intermediaryChat.attachment) && (
                                                            <img
                                                                className={clsx('max-w-[200px]')}
                                                                src={getSignedUrl(intermediaryChat.attachment)}
                                                            />
                                                        )}
                                                        <span>{intermediaryChat.attachment.filename}</span>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={clsx(
                                                'w-20',
                                                'flex-shrink-0',
                                                'h-auto',
                                                'flex',
                                                'flex-col',
                                                'items-start',
                                                'justify-end',
                                            )}>
                                            <div className={clsx('text-xs', 'text-gray-500', 'text-left')}>
                                                {formatDateTime(intermediaryChat.createdAt, 'date')}
                                                <br />
                                                {formatDateTime(intermediaryChat.createdAt, 'time')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                        <div ref={scrollBottomRef} />
                    </div>
                ) : (
                    <div className={clsx('m-4')}>
                        <CMessage info>{t('Chat.チャット履歴はありません')}</CMessage>
                    </div>
                )}
            </div>
            {!!data?.intermediaryTo.destinationTo?.relatedMember && user?.roles.realestate === 2 && (
                <div className={clsx('fixed', 'bottom-0', 'left-0', 'w-full', 'bg-white', 'p-1')}>
                    <div className={clsx('grid', 'grid-cols-2', 'gap-1', 'mb-1')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'multiline'}
                            text={content}
                            rows={(content?.match(/\n/g)?.length ?? 0) + 2}
                            onChange={(val) => setContent(val)}
                        />
                        {attachedFile ? (
                            <div>
                                <div className={clsx('rounded', 'bg-gray-100', 'p-1', 'mb-1')}>{attachedFile.filename}</div>
                                <CButton className={clsx('c-button-danger', 'w-full')} onClick={() => setAttachedFile(undefined)}>
                                    {t('Chat.添付ファイルを削除')}
                                </CButton>
                            </div>
                        ) : (
                            <CFileUpload fileUploaded={(files) => fileUploaded(files.length ? files[0] : undefined)} />
                        )}
                    </div>
                    <CButton
                        className={clsx('c-button-primary', 'w-full')}
                        disabled={content.length === 0 && !attachedFile}
                        onClick={insertButtonHandler}>
                        {t('Chat.チャットを送信')}
                    </CButton>
                </div>
            )}
            <CConfirmModal
                visible={!!deleteTarget}
                content={{ width: '80%', maxHeight: '80%' }}
                onRequestClose={closeCancelModal}
                onRequestConfirm={confirmCancelModal}
                confirmLabel={'削除'}>
                <CMessage>{deleteTarget?.content}</CMessage>
                <div>{t('Chat.上記のチャットを削除します。よろしいですか？')}</div>
            </CConfirmModal>
        </div>
    )
}
export default Chat
