import clsx from 'clsx'
import { t } from 'i18next'

import { IntermediaryToEntities } from '~/app/api'
import {
    certificateOfCompletionOfConfirmationItems,
    certificateOfFinalInspectionItems,
    cityPlanningAreaItems,
    firePreventionDistrictsItems,
    modeOfTransactionItems,
    realestateLegalComplianceTypeItems,
    realestateSeismicStandardTypeItems,
    salesDestinationTypeItems,
    saleTimeTypeItems,
    surveyDrawingItems,
    taxType,
    zoningTypeItems,
} from '~/types/enum/enum'
import { calcYearsBuilt, dateFormatOfJc, fullCurrencyFormat } from '~/util/common/common'
import { priceFormat } from '~/util/filter/format'

type Props = {
    intermediaryTo: IntermediaryToEntities
}

const CRealestateDetailIntermediaryInfo = ({ intermediaryTo }: Props) => {
    return (
        <div className={clsx('py-1', 'text-xs')}>
            <div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-2',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.物件名')}
                    </div>
                    <div className={clsx('col-span-5', 'p-1')}>{intermediaryTo.intermediary.sourceRealEstateRevision.name}</div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.所在地')}
                    </div>
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.住所')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.address}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.地番')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.parcelNumber}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-2',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.種別')}
                    </div>
                    <div className={clsx('col-span-2', 'p-1')}>
                        {intermediaryTo.intermediary.sourceRealEstateRevision.useType.name}
                    </div>
                    <div className={clsx('bg-kimar-primary', 'text-white', 'flex', 'justify-center', 'items-center')}>
                        {t('CRealestateDetailIntermediaryInfo.売却時期')}
                    </div>
                    <div className={clsx('col-span-2', 'p-1')}>
                        {
                            saleTimeTypeItems.find(
                                (i) => i.value === intermediaryTo.intermediary.sourceRealEstateRevision.saleTimeType,
                            )?.label
                        }
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-2',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.提示価格')}
                    </div>
                    <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                        {priceFormat(intermediaryTo.intermediary.price)}
                        <span className={clsx('ml-2')}>
                            ({fullCurrencyFormat(intermediaryTo.intermediary.price)}
                            {t('Unit.円')})
                        </span>
                        <span className={clsx('ml-2')}>
                            {intermediaryTo.intermediary.taxType
                                ? ' (' + taxType.find((t) => t.value === intermediaryTo.intermediary.taxType)?.label + ')'
                                : ''}
                        </span>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-2',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.竣工日')}
                    </div>
                    <div className={clsx('col-span-5', 'flex', 'p-1')}>
                        {dateFormatOfJc(
                            intermediaryTo.intermediary.sourceRealEstateRevision.buildAt,
                            intermediaryTo.intermediary.sourceRealEstateRevision.buildAtDateType === 2
                                ? 'year'
                                : intermediaryTo.intermediary.sourceRealEstateRevision.buildAtDateType === 1
                                ? 'month'
                                : 'day',
                        )}
                        <span className={clsx('ml-2')}>
                            {calcYearsBuilt(intermediaryTo.intermediary.sourceRealEstateRevision.buildAt)}
                        </span>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-2',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.交通')}
                    </div>
                    <div className={clsx('col-span-5', 'p-1', 'whitespace-pre-wrap')}>
                        {intermediaryTo.intermediary.sourceRealEstateRevision.station}
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.土地')}
                    </div>
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.地目')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.landCategory}
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.敷地形状')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.landShape}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.権利')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.landRight}
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.測量図')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    surveyDrawingItems.find(
                                        (i) => i.value === intermediaryTo.intermediary.sourceRealEstateRevision.surveyDrawing,
                                    )?.label
                                }
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.公簿面積')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>
                                <div className={clsx('flex', 'items-center', 'py-1')}>
                                    {intermediaryTo.intermediary.sourceRealEstateRevision.registeredLandSize}
                                    <span className={clsx('ml-1')}>{t('Unit.㎡')}</span>
                                </div>
                                <div className={clsx('mx-2', 'flex', 'flex-col', 'items-center')}>
                                    <div className={clsx('text-xs')}>,</div>
                                </div>
                                <div className={clsx('flex', 'items-center', 'py-1')}>
                                    {intermediaryTo.intermediary.sourceRealEstateRevision.registeredLandSizeTsubo}
                                    <span className={clsx('ml-1')}>{t('Unit.坪')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.実測面積')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>
                                <div className={clsx('flex', 'items-center', 'py-1')}>
                                    {intermediaryTo.intermediary.sourceRealEstateRevision.surveyedLandSize}
                                    <span className={clsx('ml-1')}>{t('Unit.㎡')}</span>
                                </div>
                                <div className={clsx('mx-2', 'flex', 'flex-col', 'items-center')}>
                                    <div className={clsx('text-xs')}>,</div>
                                </div>
                                <div className={clsx('flex', 'items-center', 'py-1')}>
                                    {intermediaryTo.intermediary.sourceRealEstateRevision.surveyedLandSizeTsubo}
                                    <span className={clsx('ml-1')}>{t('Unit.坪')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.前面道路情報')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1', 'whitespace-pre-wrap')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.frontalRoad}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.建物')}
                    </div>
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.権利')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.buildingRight}
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.家屋番号')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.buildingNumber}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.耐震基準')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    realestateSeismicStandardTypeItems.find(
                                        (i) =>
                                            i.value === intermediaryTo.intermediary.sourceRealEstateRevision.seismicStandardType,
                                    )?.label
                                }
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.遵法性')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    realestateLegalComplianceTypeItems.find(
                                        (i) =>
                                            i.value === intermediaryTo.intermediary.sourceRealEstateRevision.legalComplianceType,
                                    )?.label
                                }
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.建物名')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.buildingName}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.建築主')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.builder}
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.総戸数')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.totalUnits}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.延床面積')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>
                                <div className={clsx('flex', 'items-center', 'py-1')}>
                                    {intermediaryTo.intermediary.sourceRealEstateRevision.totalFloorSize}
                                    <span className={clsx('ml-1')}>{t('Unit.㎡')}</span>
                                </div>
                                <div className={clsx('mx-2', 'flex', 'flex-col', 'items-center')}>
                                    <div className={clsx('text-xs')}>,</div>
                                </div>
                                <div className={clsx('flex', 'items-center', 'py-1')}>
                                    {intermediaryTo.intermediary.sourceRealEstateRevision.totalFloorSizeTsubo}
                                    <span className={clsx('ml-1')}>{t('Unit.坪')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.賃貸可能面積')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>
                                <div className={clsx('flex', 'items-center', 'py-1')}>
                                    {intermediaryTo.intermediary.sourceRealEstateRevision.rentableAreaSize}
                                    <span className={clsx('ml-1')}>{t('Unit.㎡')}</span>
                                </div>
                                <div className={clsx('mx-2', 'flex', 'flex-col', 'items-center')}>
                                    <div className={clsx('text-xs')}>,</div>
                                </div>
                                <div className={clsx('flex', 'items-center', 'py-1')}>
                                    {intermediaryTo.intermediary.sourceRealEstateRevision.rentableAreaSizeTsubo}
                                    <span className={clsx('ml-1')}>{t('Unit.坪')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.地上階数')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.floor}
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.地下階数')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.undergroundFloor}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.設備')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.facility}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.検査済証')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    certificateOfFinalInspectionItems.find(
                                        (i) =>
                                            i.value ===
                                            intermediaryTo.intermediary.sourceRealEstateRevision.certificateOfFinalInspection,
                                    )?.label
                                }
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.確認済証')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    certificateOfCompletionOfConfirmationItems.find(
                                        (i) =>
                                            i.value ===
                                            intermediaryTo.intermediary.sourceRealEstateRevision
                                                .certificateOfCompletionOfConfirmation,
                                    )?.label
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.公法規則')}
                    </div>
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.都市計画')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    cityPlanningAreaItems.find(
                                        (i) => i.value === intermediaryTo.intermediary.sourceRealEstateRevision.cityPlanningArea,
                                    )?.label
                                }
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.用途地域１')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    zoningTypeItems.find(
                                        (i) => i.value === intermediaryTo.intermediary.sourceRealEstateRevision.zoningType,
                                    )?.label
                                }
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.用途地域２')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    zoningTypeItems.find(
                                        (i) => i.value === intermediaryTo.intermediary.sourceRealEstateRevision.zoningType2,
                                    )?.label
                                }
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.建ぺい率１')}
                            </div>
                            <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.buildingRate}
                                <span className={clsx('ml-1')}>％</span>
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.建ぺい率２')}
                            </div>
                            <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.buildingRate2}
                                <span className={clsx('ml-1')}>％</span>
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.容積率１')}
                            </div>
                            <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.floorAreaRatio}
                                <span className={clsx('ml-1')}>％</span>
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.容積率２')}
                            </div>
                            <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.floorAreaRatio2}
                                <span className={clsx('ml-1')}>％</span>
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.防火地域')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    firePreventionDistrictsItems.find(
                                        (i) =>
                                            i.value ===
                                            intermediaryTo.intermediary.sourceRealEstateRevision.firePreventionDistricts,
                                    )?.label
                                }
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.高度規制')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.heightControl}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.その他規制')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.otherControl}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.収支')}
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.想定収入／年')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        {priceFormat(intermediaryTo.intermediary.sourceRealEstateRevision.assumeIncome)}
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.現況収入／年')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        {priceFormat(intermediaryTo.intermediary.sourceRealEstateRevision.presentIncome)}
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.利回り')}
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.表面利回り')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        {intermediaryTo.intermediary.sourceRealEstateRevision.grossRate}
                        <span className={clsx('ml-1')}>％</span>
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.実質利回り')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        {intermediaryTo.intermediary.sourceRealEstateRevision.netRate}
                        <span className={clsx('ml-1')}>％</span>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailIntermediaryInfo.その他')}
                    </div>
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div className={clsx('bg-kimar-primary', 'text-white', 'flex', 'justify-center', 'items-center')}>
                                {t('CRealestateDetailIntermediaryInfo.引渡日')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'p-1')}>
                                {dateFormatOfJc(
                                    intermediaryTo.intermediary.sourceRealEstateRevision.deliveryAt,
                                    intermediaryTo.intermediary.sourceRealEstateRevision.deliveryAtDateType === 2
                                        ? 'year'
                                        : intermediaryTo.intermediary.sourceRealEstateRevision.deliveryAtDateType === 1
                                        ? 'month'
                                        : 'day',
                                )}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.取引態様')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    modeOfTransactionItems.find(
                                        (i) => i.value === intermediaryTo.intermediary.sourceRealEstateRevision.modeOfTransaction,
                                    )?.label
                                }
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.先数')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    salesDestinationTypeItems.find(
                                        (i) =>
                                            i.value === intermediaryTo.intermediary.sourceRealEstateRevision.salesDestinationType,
                                    )?.label
                                }
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailIntermediaryInfo.備考')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1', 'whitespace-pre-wrap')}>
                                {intermediaryTo.intermediary.sourceRealEstateRevision.other}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CRealestateDetailIntermediaryInfo
