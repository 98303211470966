import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from './store'

const slice = createSlice({
    name: 'map',
    initialState: { zoom: 14, latLng: { lat: 35.681236, lng: 139.767125 }, isShowMapOnRealEstateList: true } as {
        zoom: number
        latLng: { lat: number; lng: number } // 初期値は東京駅
        isShowMapOnRealEstateList: boolean // 物件一覧の地図表示
    },
    reducers: {
        setZoom: (state, { payload }: PayloadAction<number>) => ({
            ...state,
            zoom: payload,
        }),
        setLatLng: (state, { payload }: PayloadAction<{ lat: number; lng: number }>) => ({
            ...state,
            latLng: payload,
        }),
        setIsShowMapOnRealEstateList: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            isShowMapOnRealEstateList: payload,
        }),
    },
})

export const { setZoom, setLatLng, setIsShowMapOnRealEstateList } = slice.actions

export const selectZoom = (state: RootState) => state.persistedReducer.map.zoom
export const selectLatLng = (state: RootState) => state.persistedReducer.map.latLng
export const selectIsShowMapOnRealEstateList = (state: RootState) => state.persistedReducer.map.isShowMapOnRealEstateList

export default slice.reducer
