import clsx from 'clsx'
import { VFC } from 'react'

export type CToggleSwitchViewProps = {
    _toggleChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
} & CToggleSwitchProps

export const CToggleSwitchView: VFC<CToggleSwitchViewProps> = ({
    value,
    trueLabel = '',
    falseLabel = '',
    width = 'base',
    disabled = false,
    _toggleChanged,
}) => (
    <>
        <label className={clsx('flex', 'relative', 'items-center')}>
            <input
                type="checkbox"
                checked={value}
                onChange={_toggleChanged}
                disabled={disabled}
                className={clsx('peer', 'sr-only')}
            />
            <div
                className={clsx(
                    'border',
                    'block',
                    'h-[26px]',
                    'cursor-pointer',
                    'bg-white',
                    'rounded-full',
                    'px-0.5',
                    'py-px',
                    'after:block',
                    'after:h-[22px]',
                    'after:w-[22px]',
                    'after:rounded-full',
                    'after:transition',
                    'peer-checked:after:bg-white',
                    width === 'base' && ['w-[4em]', 'peer-checked:after:translate-x-[calc(4em-22px-5px)]'],
                    width === 'lg' && ['w-[5em]', 'peer-checked:after:translate-x-[calc(5em-22px-5px)]'],
                    disabled
                        ? ['border-gray-300', 'after:bg-gray-300', 'peer-checked:bg-gray-300']
                        : ['border-kimar-primary', 'after:bg-kimar-primary', 'peer-checked:bg-kimar-primary'],
                )}
                style={{
                    width: width,
                }}
            />
            <div
                className={clsx(
                    'h-6',
                    'absolute',
                    'flex',
                    'items-center',
                    'text-xs',
                    'right-2',
                    'peer-checked:left-2',
                    'peer-checked:text-white',
                    disabled ? 'text-gray-300' : 'text-kimar-primary',
                )}>
                <div>{value ? trueLabel : falseLabel}</div>
            </div>
        </label>
    </>
)

export type CToggleSwitchProps = {
    value: boolean
    trueLabel: string
    falseLabel: string
    absolute?: boolean
    disabled?: boolean
    width?: 'base' | 'lg'
    toggleChanged: (value: boolean) => void
}

export const CToggleSwitch: VFC<CToggleSwitchProps> = ({ ...props }) => {
    const _toggleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.toggleChanged(e.target.checked)
    }
    return <CToggleSwitchView {...props} _toggleChanged={_toggleChanged} />
}
