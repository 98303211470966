import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useMemo, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import { AdminCompanyInsertDto, CompanyEntities, useAdminCompanyPostCompanyMutation } from '~/app/api'
import { CError } from '~/components/common/cError/CError'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { ApplicationError, IApplicationError } from '~/types/error'

export type InsertCompanyModalViewProps = {
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (key: string, value: unknown) => void
    dto: AdminCompanyInsertDto
    disableSubmit: boolean
    errors: IApplicationError[]
} & InsertCompanyModalProps

export const InsertCompanyModalView: VFC<InsertCompanyModalViewProps> = ({
    isShow,
    company,
    onRequestConfirm,
    onRequestClose,
    setProps,
    dto,
    disableSubmit,
    errors,
}) => {
    return (
        <CConfirmModal
            title={company ? t('InsertCompanyModal.会社を編集') : t('InsertCompanyModal.会社を登録')}
            visible={!!isShow}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            disableSubmit={disableSubmit}>
            <div>
                <CError errors={errors} />
                <div className={clsx('flex', 'flex-col', 'space-y-2')}>
                    <CLabeledItem label={t('InsertCompanyModal.会社名')} required>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={t('InsertCompanyModal.会社名')}
                            text={dto.name}
                            onChange={(val) => setProps('name', val)}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('InsertCompanyModal.会社名(かな)')} required>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={t('InsertCompanyModal.会社名(かな)')}
                            text={dto.sound}
                            onChange={(val) => setProps('sound', val)}
                        />
                    </CLabeledItem>

                    <CLabeledItem label={t('InsertCompanyModal.KPI集計対象')} horizontal>
                        <CToggleSwitch
                            value={dto.isKpiTarget}
                            trueLabel={t('InsertCompanyModal.対象')}
                            falseLabel={t('InsertCompanyModal.対象外')}
                            toggleChanged={(value) => setProps('isKpiTarget', value)}
                        />
                    </CLabeledItem>
                </div>
            </div>
        </CConfirmModal>
    )
}

export type InsertCompanyModalProps = {
    isShow: boolean
    company?: CompanyEntities
    onClose: () => void
    onSubmit: (company: CompanyEntities) => void
}

export const InsertCompanyModal: VFC<InsertCompanyModalProps> = ({ ...props }) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const onRequestClose = () => {
        setDto(createNewDto())
        props.onClose()
    }

    const [insertCompanyQuery] = useAdminCompanyPostCompanyMutation()
    const onRequestConfirm = async () => {
        try {
            const company = await insertCompanyQuery({ adminCompanyInsertDto: dto }).unwrap()
            props.onSubmit(company)
            toast.success(t('InsertCompanyModal.会社を登録しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const createNewDto = () => ({
        name: '',
        sound: '',
        isKpiTarget: false,
    })

    const [dto, setDto] = useState<AdminCompanyInsertDto>(
        props.company
            ? {
                  ...props.company,
              }
            : createNewDto(),
    )
    useEffect(() => {
        setDto(
            props.company
                ? {
                      ...props.company,
                  }
                : createNewDto(),
        )
    }, [props.company])

    const setProps = (key: string, value: unknown) => {
        setDto({
            ...dto,
            [key]: value,
        })
    }

    const disableSubmit = useMemo(() => {
        return !dto.name || !dto.sound
    }, [dto])

    return (
        <InsertCompanyModalView
            {...props}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            dto={dto}
            setProps={setProps}
            disableSubmit={disableSubmit}
            errors={errors}
        />
    )
}
