import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useMemo, useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import {
    AdminTeamMemberUpdateDto,
    ClientTeamMemberUpdateDto,
    TeamMemberEntities,
    useAdminTeamMemberPutUuidMutation,
    useClientTeamMemberPutUuidMutation,
} from '~/app/api'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CDropDown } from '~/components/common/cDropdown/CDropdown'
import { CError } from '~/components/common/cError/CError'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { companyRoles, customerRoles, intermediaryRoles, realestateRoles, teamRoles } from '~/types/enum/enum'
import { ApplicationError, IApplicationError } from '~/types/error'

export type UpdateTeamMemberModalViewProps = {
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (key: string, value: unknown) => void
    setRolesProp: (key: string, value: unknown) => void
    dto: AdminTeamMemberUpdateDto
    disableSubmit: boolean
    errors: IApplicationError[]
} & UpdateTeamMemberModalProps

export const UpdateTeamMemberModalView: VFC<UpdateTeamMemberModalViewProps> = ({
    isShow,
    onRequestConfirm,
    onRequestClose,
    setProps,
    setRolesProp,
    dto,
    disableSubmit,
    errors,
}) => {
    return (
        <CConfirmModal
            title={t('UpdateTeamMemberModal.チームメンバーを編集')}
            visible={!!isShow}
            content={{ width: '700px' }}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            disableSubmit={disableSubmit}
            confirmLabel={t('UpdateTeamMemberModal.編集確定')}>
            <CError errors={errors} />
            <div className={clsx('flex', 'flex-col', 'space-y-2')}>
                <CLabeledItem label={t('UpdateTeamMemberModal.メールアドレス')} required className={clsx('w-full')}>
                    <CTextInput
                        className={clsx('w-full', 'c-text-input-base')}
                        type={'text'}
                        placeholder={'test@example.com'}
                        text={dto.email}
                        onChange={(val) => setProps('email', val)}
                    />
                </CLabeledItem>
                <div className={clsx('flex', 'space-x-2')}>
                    <CLabeledItem label={t('UpdateTeamMemberModal.氏名')} required className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={t('UpdateTeamMemberModal.山田 太郎')}
                            text={dto.name}
                            onChange={(val) => setProps('name', val)}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('UpdateTeamMemberModal.氏名(かな)')} required className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={t('UpdateTeamMemberModal.やまだ たろう')}
                            text={dto.nameSound}
                            onChange={(val) => setProps('nameSound', val)}
                        />
                    </CLabeledItem>
                </div>
                <div className={clsx('flex', 'space-x-2')}>
                    <CLabeledItem label={t('UpdateTeamMemberModal.部署名')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={''}
                            text={dto.departmentName}
                            onChange={(val) => setProps('departmentName', val)}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('UpdateTeamMemberModal.役職')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={''}
                            text={dto.position}
                            onChange={(val) => setProps('position', val)}
                        />
                    </CLabeledItem>
                </div>
                <div className={clsx('flex', 'space-x-2')}>
                    <CLabeledItem label={t('UpdateTeamMemberModal.電話番号')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={'00-1111-2222'}
                            text={dto.phone}
                            onChange={(val) => setProps('phone', val)}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('UpdateTeamMemberModal.内線番号')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={''}
                            text={dto.phoneExtension}
                            onChange={(val) => setProps('phoneExtension', val)}
                        />
                    </CLabeledItem>
                </div>
                <div className={clsx('flex', 'space-x-2')}>
                    <CLabeledItem label={t('UpdateTeamMemberModal.携帯電話番号')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={'090-1111-2222'}
                            text={dto.mobile}
                            onChange={(val) => setProps('mobile', val)}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('UpdateTeamMemberModal.並び順')} className={clsx('w-full')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'number'}
                            placeholder={'0'}
                            text={String(dto.sort)}
                            onChange={(val) => setProps('sort', Number(val))}
                        />
                    </CLabeledItem>
                </div>
                <div className={clsx('grid', 'grid-cols-2', 'md:grid-cols-3', 'gap-2')}>
                    <CLabeledItem label={t('UpdateTeamMemberModal.会社権限')}>
                        <CDropDown
                            className={clsx('w-full', 'c-text-input-base')}
                            items={companyRoles}
                            nowValue={String(dto.roles.company)}
                            onChange={(val) => setRolesProp('company', Number(val))}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('UpdateTeamMemberModal.チーム権限')}>
                        <CDropDown
                            className={clsx('w-full', 'c-text-input-base')}
                            items={teamRoles}
                            nowValue={String(dto.roles.team)}
                            onChange={(val) => setRolesProp('team', Number(val))}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('UpdateTeamMemberModal.物件権限')}>
                        <CDropDown
                            className={clsx('w-full', 'c-text-input-base')}
                            items={realestateRoles}
                            nowValue={String(dto.roles.realestate)}
                            onChange={(val) => setRolesProp('realestate', Number(val))}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('UpdateTeamMemberModal.顧客権限')}>
                        <CDropDown
                            className={clsx('w-full', 'c-text-input-base')}
                            items={customerRoles}
                            nowValue={String(dto.roles.customer)}
                            onChange={(val) => setRolesProp('customer', Number(val))}
                        />
                    </CLabeledItem>
                    <CLabeledItem label={t('UpdateTeamMemberModal.紹介権限')}>
                        <CDropDown
                            className={clsx('w-full', 'c-text-input-base')}
                            items={intermediaryRoles}
                            nowValue={String(dto.roles.intermediary)}
                            onChange={(val) => setRolesProp('intermediary', Number(val))}
                        />
                    </CLabeledItem>
                    {/* Limarアカウント及びいえらぶアカウントのみ運営管理権限付与可 */}
                    {(dto.email.endsWith('@limar.co.jp') || dto.email.endsWith('@ielove-group.jp')) && (
                        <CLabeledItem label={t('UpdateTeamMemberModal.運営管理権限')}>
                            <CCheckBox
                                className={clsx('w-full', 'c-text-input-base')}
                                label={t('UpdateTeamMemberModal.運営管理権限')}
                                checked={dto.roles.system}
                                onChange={() => setRolesProp('system', !dto.roles.system)}
                            />
                        </CLabeledItem>
                    )}
                </div>
            </div>
        </CConfirmModal>
    )
}

export type UpdateTeamMemberModalProps = {
    isShow: boolean
    teamMember: TeamMemberEntities
    onClose: () => void
    onSubmit: (teamMember: TeamMemberEntities) => void
}

export const UpdateTeamMemberModal: VFC<UpdateTeamMemberModalProps> = ({ ...props }) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const onRequestClose = () => {
        setDto(createNewDto())
        props.onClose()
    }

    const [updateTeamMemberQuery] = useAdminTeamMemberPutUuidMutation()
    const onRequestConfirm = async () => {
        try {
            const teamMember = await updateTeamMemberQuery({
                uuid: props.teamMember.uuid,
                adminTeamMemberUpdateDto: dto,
            }).unwrap()
            props.onSubmit(teamMember)
            toast.success(t('UpdateTeamMemberModal.チームメンバーを編集しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const createNewDto = () => ({
        name: props.teamMember.name,
        nameSound: props.teamMember.nameSound,
        email: props.teamMember.email,
        sort: props.teamMember.sort,
        roles: {
            system: props.teamMember.roles.system,
            team: props.teamMember.roles.team,
            realestate: props.teamMember.roles.realestate,
            customer: props.teamMember.roles.customer,
            intermediary: props.teamMember.roles.intermediary,
            company: props.teamMember.roles.company,
            approvalFlow: props.teamMember.roles.approvalFlow,
        },
        departmentName: props.teamMember.departmentName,
        position: props.teamMember.position,
        phone: props.teamMember.phone,
        phoneExtension: props.teamMember.phoneExtension,
        mobile: props.teamMember.mobile,
    })

    useEffect(() => {
        setDto(createNewDto())
    }, [props.teamMember])

    const [dto, setDto] = useState<AdminTeamMemberUpdateDto>(createNewDto())

    const setProps = (key: string, value: unknown) => {
        setDto({
            ...dto,
            [key]: value,
        })
    }

    const setRolesProp = (key: string, value: unknown) => {
        const roles = {
            ...dto.roles,
            [key]: value,
        }
        setDto({
            ...dto,
            roles,
        })
    }

    const disableSubmit = useMemo(() => {
        return !dto.name || !dto.nameSound || !dto.email
    }, [dto])

    return (
        <UpdateTeamMemberModalView
            {...props}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            dto={dto}
            setProps={setProps}
            setRolesProp={setRolesProp}
            disableSubmit={disableSubmit}
            errors={errors}
        />
    )
}

export type UpdateTeamMemberClientModalViewProps = {
    onRequestClose: () => void
    onRequestConfirm: () => void
    setProps: (key: string, value: unknown) => void
    setRolesProp: (key: string, value: unknown) => void
    dto: ClientTeamMemberUpdateDto
    disableSubmit: boolean
    errors: IApplicationError[]
} & UpdateTeamMemberModalProps

export const UpdateTeamMemberClientModalView: VFC<UpdateTeamMemberClientModalViewProps> = ({
    isShow,
    onRequestConfirm,
    onRequestClose,
    setProps,
    setRolesProp,
    dto,
    disableSubmit,
    errors,
}) => {
    return (
        <CConfirmModal
            title={t('UpdateTeamMemberModal.チームメンバーを編集')}
            visible={!!isShow}
            content={{ width: '700px' }}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            disableSubmit={disableSubmit}
            confirmLabel={t('UpdateTeamMemberModal.編集確定')}>
            <div className={clsx('px-4')}>
                <CError errors={errors} />
                <div className={clsx('flex', 'flex-col', 'space-y-2')}>
                    <CLabeledItem label={t('UpdateTeamMemberModal.メールアドレス')}>
                        <CTextInput
                            className={clsx('w-full', 'c-text-input-base')}
                            type={'text'}
                            placeholder={'test@example.com'}
                            readonly
                            disabled
                            text={dto.email}
                            onChange={(val) => setProps('email', val)}
                        />
                    </CLabeledItem>
                    <div className={clsx('flex', 'space-x-2')}>
                        <CLabeledItem label={t('UpdateTeamMemberModal.氏名')} required className={clsx('w-full')}>
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                placeholder={t('UpdateTeamMemberModal.山田 太郎')}
                                text={dto.name}
                                onChange={(val) => setProps('name', val)}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('UpdateTeamMemberModal.氏名(かな)')} required className={clsx('w-full')}>
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                placeholder={t('UpdateTeamMemberModal.やまだ たろう')}
                                text={dto.nameSound}
                                onChange={(val) => setProps('nameSound', val)}
                            />
                        </CLabeledItem>
                    </div>
                    <div className={clsx('flex', 'space-x-2')}>
                        <CLabeledItem label={t('UpdateTeamMemberModal.部署名')} className={clsx('w-full')}>
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                placeholder={''}
                                text={dto.departmentName}
                                onChange={(val) => setProps('departmentName', val)}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('UpdateTeamMemberModal.役職')} className={clsx('w-full')}>
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                placeholder={''}
                                text={dto.position}
                                onChange={(val) => setProps('position', val)}
                            />
                        </CLabeledItem>
                    </div>
                    <div className={clsx('flex', 'space-x-2')}>
                        <CLabeledItem label={t('UpdateTeamMemberModal.電話番号')} className={clsx('w-full')}>
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                text={dto.phone}
                                onChange={(val) => setProps('phone', val)}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('UpdateTeamMemberModal.内線番号')} className={clsx('w-full')}>
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                placeholder={''}
                                text={dto.phoneExtension}
                                onChange={(val) => setProps('phoneExtension', val)}
                            />
                        </CLabeledItem>
                    </div>
                    <div className={clsx('flex', 'space-x-2')}>
                        <CLabeledItem label={t('UpdateTeamMemberModal.携帯電話番号')} className={clsx('w-full')}>
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'text'}
                                text={dto.mobile}
                                onChange={(val) => setProps('mobile', val)}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('UpdateTeamMemberModal.並び順')} className={clsx('w-full')}>
                            <CTextInput
                                className={clsx('w-full', 'c-text-input-base')}
                                type={'number'}
                                placeholder={'0'}
                                text={String(dto.sort)}
                                onChange={(val) => setProps('sort', Number(val))}
                            />
                        </CLabeledItem>
                    </div>
                    <div className={clsx('grid', 'grid-cols-2', 'md:grid-cols-3', 'gap-2')}>
                        <CLabeledItem label={t('UpdateTeamMemberModal.会社権限')}>
                            <CDropDown
                                className={clsx('w-full', 'c-text-input-base')}
                                items={companyRoles}
                                nowValue={String(dto.roles.company)}
                                onChange={(val) => setRolesProp('company', Number(val))}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('UpdateTeamMemberModal.チーム権限')}>
                            <CDropDown
                                className={clsx('w-full', 'c-text-input-base')}
                                items={teamRoles}
                                nowValue={String(dto.roles.team)}
                                onChange={(val) => setRolesProp('team', Number(val))}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('UpdateTeamMemberModal.物件権限')}>
                            <CDropDown
                                className={clsx('w-full', 'c-text-input-base')}
                                items={realestateRoles}
                                nowValue={String(dto.roles.realestate)}
                                onChange={(val) => setRolesProp('realestate', Number(val))}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('UpdateTeamMemberModal.顧客権限')}>
                            <CDropDown
                                className={clsx('w-full', 'c-text-input-base')}
                                items={customerRoles}
                                nowValue={String(dto.roles.customer)}
                                onChange={(val) => setRolesProp('customer', Number(val))}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('UpdateTeamMemberModal.紹介権限')}>
                            <CDropDown
                                className={clsx('w-full', 'c-text-input-base')}
                                items={intermediaryRoles}
                                nowValue={String(dto.roles.intermediary)}
                                onChange={(val) => setRolesProp('intermediary', Number(val))}
                            />
                        </CLabeledItem>
                    </div>
                </div>
            </div>
        </CConfirmModal>
    )
}

export const UpdateTeamMemberClientModal: VFC<UpdateTeamMemberModalProps> = ({ ...props }) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const onRequestClose = () => {
        props.onClose()
    }

    const [updateTeamMemberQuery] = useClientTeamMemberPutUuidMutation()
    const onRequestConfirm = async () => {
        try {
            const teamMember = await updateTeamMemberQuery({
                uuid: props.teamMember.uuid,
                clientTeamMemberUpdateDto: dto,
            }).unwrap()
            props.onSubmit(teamMember)
            toast.success(t('UpdateTeamMemberModal.チームメンバーを編集しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setErrors([e])
            setErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    const createNewDto = () => ({
        name: props.teamMember.name,
        nameSound: props.teamMember.nameSound,
        email: props.teamMember.email,
        sort: props.teamMember.sort,
        departmentName: props.teamMember.departmentName,
        position: props.teamMember.position,
        phone: props.teamMember.phone,
        phoneExtension: props.teamMember.phoneExtension,
        mobile: props.teamMember.mobile,
        roles: props.teamMember.roles,
    })

    useEffect(() => {
        setDto(createNewDto())
    }, [props.teamMember])

    const [dto, setDto] = useState<ClientTeamMemberUpdateDto>(createNewDto())

    const setProps = (key: string, value: unknown) => {
        setDto({
            ...dto,
            [key]: value,
        })
    }

    const setRolesProp = (key: string, value: unknown) => {
        const roles = {
            ...dto.roles,
            [key]: value,
        }
        setDto({
            ...dto,
            roles,
        })
    }

    const disableSubmit = useMemo(() => {
        return !dto.name || !dto.nameSound || !dto.email
    }, [dto])

    return (
        <UpdateTeamMemberClientModalView
            {...props}
            onRequestClose={onRequestClose}
            onRequestConfirm={onRequestConfirm}
            dto={dto}
            setProps={setProps}
            setRolesProp={setRolesProp}
            disableSubmit={disableSubmit}
            errors={errors}
        />
    )
}
