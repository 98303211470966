import clsx from 'clsx'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast/headless'

import { CButton } from '~/components/common/cButton/CButton'
import { CCheckBox } from '~/components/common/cCheckBox/CCheckBox'
import { CCheckBoxList } from '~/components/common/cCheckBoxList/CCheckBoxList'
import { CDropDown } from '~/components/common/cDropdown/CDropdown'
import { CError } from '~/components/common/cError/CError'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { CGuideTips, CIconTips } from '~/components/common/cGuideTips/CGuideTips'
import { CHeader } from '~/components/common/cHeader/CHeader'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CLinkButton } from '~/components/common/cLinkButton/CLinkButton'
import CLoader from '~/components/common/cLoader/CLoader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CConfirmModal } from '~/components/common/cMessageModal/CMessageModal'
import { CModal } from '~/components/common/cModal/CModal'
import { CPager } from '~/components/common/cPager/CPager'
import { CRadioButton } from '~/components/common/cRadioButton/CRadioButton'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'
import { CSelectFromItemSet } from '~/components/common/cSelectFromItemSet/CSelectFromItemSet'
import { CSelectList } from '~/components/common/cSelectList/CSelectList'
import { CSortButton } from '~/components/common/cSortButton/CSortButton'
import CTable from '~/components/common/cTable/CTable'
import { CTag } from '~/components/common/cTag/CTag'
import { CTagList } from '~/components/common/cTagList/CTagList'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { CToggleSwitch } from '~/components/common/cToggleSwitch/CToggleSwitch'
import { CYearMonthDay } from '~/components/common/cYearMonthDay/CYearMonthDay'
import { pageTitleTemplate } from '~/util/common/common'

const Ui = () => {
    const [selectedTab, setSelectedTab] = useState(1)
    const [isModalShow, setIsModalShow] = useState(false)
    const [isCModalShow, setIsCModalShow] = useState(false)

    const lists = useMemo(() => ['アイテム1', 'アイテム2', 'アイテム3'], [])

    const [dateItem, setDateItem] = useState<Date | undefined>(dayjs().toDate())

    const items = useMemo(
        () => [
            { label: 'アイテム1', value: '1', backgroundColor: '#45a8a1', color: 'white' },
            { label: 'アイテム2', value: '2', backgroundColor: '#4d6aaa', color: 'white' },
            { label: 'アイテム3', value: '3', backgroundColor: '#f3cc71' },
        ],
        [],
    )

    const componentsList = useMemo(
        () => [
            'CCheckBox',
            'CSelectList',
            'CSelectFromItemSet',
            'CTextInput',
            'CYearMonthDay',
            'CFileUpload',
            'CCheckBoxList',
            'CRadioButton',
            'CToggleSwitch',
            'CDropDown',
            'CSortButton',
            'CSegmentedControl',
            'CButton',
            'CLinkButton',
            'CPager',
            'CIconTips',
            'CGuideTips',
            'CTagList',
            'CTag',
            'CError',
            'CMessage',
            'CNotification',
            'CTable',
            'CMessageModal',
            'CModal',
            'CLoader',
            'Toast',
        ],
        [],
    )

    const [switchFlg, setSwitchFlg] = useState(false)

    return (
        <>
            <Helmet titleTemplate={pageTitleTemplate()}>
                <title>Components</title>
            </Helmet>
            <div className={clsx('flex', 'flex-col', 'space-y-8', 'm-8')}>
                <CHeader label="Components" />
                <p>コンポーネントのprop別の表示などを一覧するページ。このページからコンポーネントのスタイルは変更しない。</p>
                <div className={clsx('bg-white', 'rounded', 'p-4', 'max-w-md', 'mx-auto')}>
                    <ul>
                        {componentsList.map((component, index) => (
                            <li key={index}>
                                <AnchorLink
                                    href={`#${component}`}
                                    offset="64"
                                    className={clsx('underline', 'text-kimar-primary')}>
                                    {component}
                                </AnchorLink>
                            </li>
                        ))}
                    </ul>
                </div>

                <hr />

                <h2>Form</h2>

                <h3 id="CCheckBox">CCheckBox</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CCheckBox label="Base" />
                        <CCheckBox label="Checked" checked />
                        <CCheckBox label="Disabled" disabled />
                    </div>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CCheckBox label="Readonly" readonly />
                        <CCheckBox label="Readonly" readonly checked />
                        <CCheckBox label="Readonly XS Size" readonly size="xs" />
                        <CCheckBox label="Readonly XS Size" readonly checked size="xs" />
                    </div>
                </div>

                <h3 id="CSelectList">CSelectList</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CLabeledItem label="Base">
                            <CSelectList items={items} onChange={() => false} />
                        </CLabeledItem>
                        <CLabeledItem label="Disabele">
                            <CSelectList items={items} disabled onChange={() => false} />
                        </CLabeledItem>
                    </div>
                </div>

                <h3 id="CSelectFromItemSet">CSelectFromItemSet</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <CSelectFromItemSet
                        sourceItems={items.map((i) => ({
                            item: i.label,
                            key: i.value,
                        }))}
                        getRowNode={(item) => <CTag>{item as string}</CTag>}
                        itemSetHeader="Passive"
                        selectedListHeader="Active"
                        selectedItems={['1']}
                        onChange={() => false}
                    />
                </div>

                <h3 id="CTextInput">CTextInput</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CLabeledItem label="Text">
                            <CTextInput type="text" placeholder="Placeholder" className={clsx('c-text-input-base')} />
                        </CLabeledItem>
                        <CLabeledItem label="Password">
                            <CTextInput type="password" className={clsx('c-text-input-base')} />
                        </CLabeledItem>
                        <CLabeledItem label="Number">
                            <CTextInput type="number" className={clsx('c-text-input-base')} />
                        </CLabeledItem>
                    </div>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CLabeledItem label="Multiline">
                            <CTextInput type="multiline" className={clsx('c-text-input-base')} />
                        </CLabeledItem>
                        <CLabeledItem label="Read Only">
                            <CTextInput readonly type="text" text="Text" className={clsx('c-text-input-base')} />
                        </CLabeledItem>
                        <CLabeledItem label="Disabeled">
                            <CTextInput disabled type="text" text="Text" className={clsx('c-text-input-base')} />
                        </CLabeledItem>
                        <CLabeledItem label="Text" required>
                            <CTextInput type="text" placeholder="Placeholder" className={clsx('c-text-input-base')} />
                        </CLabeledItem>
                    </div>
                </div>

                <h3 id="CYearMonthDay">CYearMonthDay</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <CLabeledItem label="Default">
                        <CYearMonthDay value={dateItem} onChange={(date) => setDateItem(date)} />
                    </CLabeledItem>
                    <CLabeledItem label="年号表示">
                        <CYearMonthDay value={dateItem} jc onChange={(date) => setDateItem(date)} />
                    </CLabeledItem>
                </div>

                <h3 id="CFileUpload">CFileUpload</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <CFileUpload fileUploaded={() => {}} />
                </div>

                <h3 id="CCheckBoxList">CCheckBoxList</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CLabeledItem label="Base">
                            <CCheckBoxList items={items} checked={[2]} onChange={() => false} />
                        </CLabeledItem>
                        <CLabeledItem label="Disabele">
                            <CCheckBoxList items={items} disabled checked={[2]} onChange={() => false} />
                        </CLabeledItem>
                        <CLabeledItem label="Horizontal">
                            <CCheckBoxList items={items} horizontal checked={[2]} onChange={() => false} />
                        </CLabeledItem>
                        <CLabeledItem label="Grid(2)">
                            <CCheckBoxList items={items} grid={2} checked={[2]} onChange={() => false} />
                        </CLabeledItem>
                    </div>
                </div>

                <h3 id="CRadioButton">CRadioButton</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CLabeledItem label="Base">
                            <CRadioButton items={items} nowValue={2} onChange={() => false} />
                        </CLabeledItem>
                        <CLabeledItem label="Disabele">
                            <CRadioButton items={items} disabled nowValue={2} onChange={() => false} />
                        </CLabeledItem>
                        <CLabeledItem label="Horizontal">
                            <CRadioButton items={items} horizontal onChange={() => false} />
                        </CLabeledItem>
                        <CLabeledItem label="Grid(2)">
                            <CRadioButton items={items} grid={2} onChange={() => false} />
                        </CLabeledItem>
                    </div>
                </div>

                <h3 id="CToggleSwitch">CToggleSwitch</h3>
                <div className={clsx('flex', 'space-x-4', 'bg-white', 'rounded', 'p-4')}>
                    <CLabeledItem label="Base">
                        <div className={clsx('flex')}>
                            <CToggleSwitch
                                trueLabel="AND"
                                falseLabel="OR"
                                toggleChanged={() => setSwitchFlg(!switchFlg)}
                                value={switchFlg}
                            />
                        </div>
                    </CLabeledItem>
                    <CLabeledItem label="Large">
                        <div className={clsx('flex')}>
                            <CToggleSwitch
                                trueLabel="はい"
                                falseLabel="いいえ"
                                width="lg"
                                toggleChanged={() => setSwitchFlg(!switchFlg)}
                                value={!switchFlg}
                            />
                        </div>
                    </CLabeledItem>
                    <CLabeledItem label="Base(Disabled)">
                        <div className={clsx('flex')}>
                            <CToggleSwitch
                                trueLabel="AND"
                                falseLabel="OR"
                                disabled
                                toggleChanged={() => setSwitchFlg(!switchFlg)}
                                value={switchFlg}
                            />
                        </div>
                    </CLabeledItem>
                    <CLabeledItem label="Large(Disabled)">
                        <div className={clsx('flex')}>
                            <CToggleSwitch
                                trueLabel="はい"
                                falseLabel="いいえ"
                                width="lg"
                                disabled
                                toggleChanged={() => setSwitchFlg(!switchFlg)}
                                value={!switchFlg}
                            />
                        </div>
                    </CLabeledItem>
                </div>

                <h3 id="CDropDown">CDropDown</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CLabeledItem label="Base">
                            <CDropDown items={items} className={clsx('c-dropdown-base')} nowValue={2} onChange={() => false} />
                        </CLabeledItem>
                        <CLabeledItem label="Allow Unselected">
                            <CDropDown
                                items={items}
                                className={clsx('c-dropdown-base')}
                                allowUnselected
                                nowValue={[]}
                                onChange={() => false}
                            />
                        </CLabeledItem>
                        <CLabeledItem label="Disabele">
                            <CDropDown
                                items={items}
                                className={clsx('c-dropdown-base')}
                                disabled
                                nowValue={2}
                                onChange={() => false}
                            />
                        </CLabeledItem>
                        <CLabeledItem label="Multiple">
                            <CDropDown
                                items={items}
                                className={clsx('c-dropdown-base')}
                                multiple
                                nowValue={['2']}
                                onChange={() => false}
                            />
                        </CLabeledItem>
                    </div>
                </div>

                <h3 id="CSortButton">CSortButton</h3>
                <div className={clsx('flex', 'bg-white', 'rounded', 'p-4')}>
                    <CSortButton items={items} nowValue={'1'} sort="ASC" onChange={() => false} onClickSort={() => false} />
                </div>

                <hr />

                <h2>Tab</h2>

                <h3 id="CSegmentedControl">CSegmentedControl</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('w-full', 'flex', 'justify-center')}>
                        <div className={clsx('w-full', 'md:w-3/4')}>
                            <CSegmentedControl
                                items={items}
                                onClick={(val) => setSelectedTab(val as number)}
                                selectedTabValue={selectedTab}
                            />
                        </div>
                    </div>
                </div>

                <hr />

                <h2>Button</h2>

                <h3 id="CButton">CButton</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <p>Base</p>
                    <div className={clsx('flex', 'space-x-2')}>
                        <CButton className={clsx('c-button-primary')}>.c-button-primary</CButton>
                        <CButton className={clsx('c-button-secondary')}>.c-button-secondary</CButton>
                        <CButton className={clsx('c-button-market')}>.c-button-market</CButton>
                        <CButton className={clsx('c-button-market-secondary')}>.c-button-market-secondary</CButton>
                    </div>
                    <div className={clsx('flex', 'space-x-2')}>
                        <CButton className={clsx('c-button-danger')}>.c-button-danger</CButton>
                        <CButton className={clsx('c-button-accent')}>.c-button-accent</CButton>
                        <CButton className={clsx('c-button-icon', 'w-6', 'h-6')}>
                            <i className={clsx('material-icons', 'text-[20px]')}>person</i>
                        </CButton>
                    </div>
                    <p>Disabled</p>
                    <div className={clsx('flex', 'space-x-2')}>
                        <CButton disabled className={clsx('c-button-primary')}>
                            .c-button-primary
                        </CButton>
                        <CButton disabled className={clsx('c-button-secondary')}>
                            .c-button-secondary
                        </CButton>
                        <CButton disabled className={clsx('c-button-market')}>
                            .c-button-market
                        </CButton>
                        <CButton disabled className={clsx('c-button-market-secondary')}>
                            .c-button-market-secondary
                        </CButton>
                    </div>
                    <div className={clsx('flex', 'space-x-2')}>
                        <CButton disabled className={clsx('c-button-danger')}>
                            .c-button-danger
                        </CButton>
                        <CButton disabled className={clsx('c-button-accent')}>
                            .c-button-accent
                        </CButton>
                        <CButton disabled className={clsx('c-button-icon', 'w-6', 'h-6')}>
                            <i className={clsx('material-icons', 'text-[20px]')}>person</i>
                        </CButton>
                    </div>
                </div>

                <h3 id="CLinkButton">CLinkButton</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CLinkButton to="/">Base</CLinkButton>
                        <CLinkButton to="/" blank>
                            Blank
                        </CLinkButton>
                    </div>
                </div>

                <hr />

                <h3 id="CPager">CPager</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CPager total={150} pageSize={5} page={3} onPageChanged={() => false} />
                    </div>
                </div>

                <h3 id="CIconTips">CIconTips</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <CIconTips
                        icon={
                            <i
                                className={clsx(
                                    'material-symbols-outlined',
                                    'w-6',
                                    'h-6',
                                    'p-0.5',
                                    'text-[20px]',
                                    'peer',
                                    'cursor-pointer',
                                    'text-white',
                                    'align-middle',
                                    'rounded-full',
                                    'bg-gray-500',
                                )}>
                                image
                            </i>
                        }
                        tooltipDirection="right">
                        Sample
                    </CIconTips>
                </div>

                <h3 id="CGuideTips">CGuideTips</h3>
                <div className={clsx('flex', 'gap-4', 'bg-white', 'rounded', 'p-4')}>
                    <CLabeledItem label="Top Left">
                        <CGuideTips tooltipDirection="top-left">Top Left</CGuideTips>
                    </CLabeledItem>
                    <CLabeledItem label="Top Right">
                        <CGuideTips tooltipDirection="top-right">Top Right</CGuideTips>
                    </CLabeledItem>
                    <CLabeledItem label="Bottom Left">
                        <CGuideTips tooltipDirection="bottom-left">Bottom Left</CGuideTips>
                    </CLabeledItem>
                    <CLabeledItem label="Bottom Right">
                        <CGuideTips tooltipDirection="bottom-right">Bottom Right</CGuideTips>
                    </CLabeledItem>
                    <CLabeledItem label="Left">
                        <CGuideTips tooltipDirection="left">Left</CGuideTips>
                    </CLabeledItem>
                    <CLabeledItem label="Right">
                        <CGuideTips tooltipDirection="right">Right</CGuideTips>
                    </CLabeledItem>
                </div>

                <h3 id="CTagList">CTagList</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CLabeledItem label="Base">
                            <CTagList
                                items={
                                    items.map((item) => ({
                                        children: <>{item.label}</>,
                                        style: { backgroundColor: item.backgroundColor, color: item.color },
                                    })) ?? []
                                }
                            />
                        </CLabeledItem>
                        <CLabeledItem label="Horizontal">
                            <CTagList
                                horizontal
                                items={
                                    items.map((item) => ({
                                        children: <>{item.label}</>,
                                        style: { backgroundColor: item.backgroundColor, color: item.color },
                                    })) ?? []
                                }
                            />
                        </CLabeledItem>
                    </div>
                </div>

                <h3 id="CTag">CTag</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        {items.map((item) => (
                            <CTag key={item.value} style={{ backgroundColor: item.backgroundColor, color: item.color }}>
                                Tag{item.value}
                            </CTag>
                        ))}
                    </div>
                </div>

                <h3 id="CError">CError</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'space-x-4')}>
                        <CError errors={[{ message: 'エラーメッセージが入ります', statusCode: 402 }]} />
                    </div>
                </div>

                <hr />
                <h2>Message</h2>

                <h3 id="CMessage">CMessage</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <div className={clsx('flex', 'flex-col', 'space-y-2')}>
                        <CMessage>Base</CMessage>
                        <CMessage centered>Centered</CMessage>
                        <CMessage messages={lists}>Multi Message</CMessage>
                        <CMessage info>Info</CMessage>
                        <CMessage warning>Warning</CMessage>
                        <CMessage danger>Danger</CMessage>
                        <CMessage success>Success</CMessage>
                    </div>
                </div>

                <h3 id="CNotification">CNotification</h3>
                {/* <CNotification /> */}
                <CMessage warning>未実装</CMessage>

                <h3 id="CTable">CTable</h3>
                <div className={clsx('flex', 'flex-col', 'space-y-4', 'bg-white', 'rounded', 'p-4')}>
                    <CTable
                        thead={
                            <tr>
                                {items.map((item, index) => (
                                    <th key={index}>ヘッダー{item.value}</th>
                                ))}
                            </tr>
                        }
                        tbody={
                            <>
                                <tr>
                                    {items.map((item, index) => (
                                        <td key={index}>アイテム{item.value}</td>
                                    ))}
                                </tr>
                                <tr>
                                    {items.map((item, index) => (
                                        <td key={index}>アイテム{item.value}</td>
                                    ))}
                                </tr>
                                <tr>
                                    {items.map((item, index) => (
                                        <td key={index}>アイテム{item.value}</td>
                                    ))}
                                </tr>
                            </>
                        }
                    />
                </div>

                <h3 id="CMessageModal">CConfirmModal</h3>
                <CButton className={clsx('c-button-primary')} onClick={() => setIsModalShow(true)}>
                    Open ConfirmModal
                </CButton>
                <CConfirmModal
                    title="Modal Title"
                    visible={!!isModalShow}
                    onRequestClose={() => setIsModalShow(false)}
                    onRequestConfirm={() => setIsModalShow(false)}>
                    Base
                </CConfirmModal>

                <h3 id="CModal">CModal</h3>
                <CButton className={clsx('c-button-primary')} onClick={() => setIsCModalShow(true)}>
                    Open CModal
                </CButton>
                <CModal visible={!!isCModalShow} onRequestClose={() => setIsCModalShow(false)}>
                    Base
                    <CButton className={clsx('c-button-secondary')} onClick={() => setIsCModalShow(false)}>
                        閉じる
                    </CButton>
                </CModal>

                <h3 id="CLoader">CLoader</h3>
                <CLoader />

                <h3 id="Toast">Toast</h3>
                <div className={clsx('flex', 'space-x-4', 'bg-white', 'rounded', 'p-4')}>
                    <CButton className={clsx('c-button-primary')} onClick={() => toast('blank')}>
                        blank
                    </CButton>
                    <CButton className={clsx('c-button-primary')} onClick={() => toast.success('success')}>
                        success
                    </CButton>
                    <CButton className={clsx('c-button-primary')} onClick={() => toast.error('error')}>
                        error
                    </CButton>
                    <CButton className={clsx('c-button-primary')} onClick={() => toast.loading('loading')}>
                        loading
                    </CButton>
                </div>
            </div>
        </>
    )
}

export default Ui
