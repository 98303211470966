import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast/headless'

import {
    ClientTeamMemberConditionUpdateDto,
    ClientTeamMemberUpdateMeDto,
    useClientUserPutConditionMutation,
    useClientUserPutPasswordMutation,
    useClientUserPutProfileMutation,
    useClientUserSignatureQuery,
} from '~/app/api'
import { CHeader } from '~/components/common/cHeader/CHeader'
import CMessage from '~/components/common/cMessage/CMessage'
import { CSegmentedControl } from '~/components/common/cSegmentedControl/CSegmentedControl'
import { selectLoginUser, setLoginUser } from '~/util/store/userSlice'

import { ApplicationError, IApplicationError } from '../../../types/error'
import { useAppDispatch, useAppSelector } from '../../../util/store/hooks'
import { CButton } from '../../common/cButton/CButton'
import { CError } from '../../common/cError/CError'
import { CLabeledItem } from '../../common/cLabeledItem/CLabeledItem'
import { CRadioButton } from '../../common/cRadioButton/CRadioButton'
import { CTextInput } from '../../common/cTextInput/CTextInput'
import CUserPurchaseConditionContent from './CUserPurchaseConditionContent'

type Props = {
    content?: string
}

const CUserMypageProfile = ({ content }: Props) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectLoginUser)
    // プロフィール変更
    const [updateMeQuery, { isSuccess: updateMeQuerySuccess }] = useClientUserPutProfileMutation()
    const { data: signatureData, isFetching: signatureLoading } = useClientUserSignatureQuery()
    const [updateMeDto, setUpdateMeDto] = useState<ClientTeamMemberUpdateMeDto>()
    useEffect(() => {
        if (signatureLoading) return
        setUpdateMeDto({
            name: user?.name ?? '',
            nameSound: user?.nameSound ?? '',
            departmentName: user?.departmentName,
            position: user?.position,
            phone: user?.phone,
            phoneExtension: user?.phoneExtension,
            mobile: user?.mobile,
            signature: signatureData?.length ? signatureData[0].signature : '',
            setting: !user?.settings ? { mail: 1, sendToString: 1 } : { ...{ mail: 1, sendToString: 1 }, ...user?.settings },
        })
    }, [user, signatureLoading])
    const setProfileProps = (key: string, value: unknown) => {
        if (!updateMeDto) return
        setUpdateMeDto({
            ...updateMeDto,
            [key]: value,
        })
    }
    const [profileErrors, setProfileErrors] = useState<Array<IApplicationError>>([])
    const disableSaveProfile = useMemo(() => {
        if (!updateMeDto) return true
        if (!updateMeDto.name) return t('CUserMypageProfile.氏名が入力されていません')
        if (!updateMeDto.nameSound) return t('CUserMypageProfile.氏名(かな)が入力されていません')

        return false
    }, [updateMeDto])
    const onSaveProfile = async () => {
        if (!updateMeDto) return
        try {
            const member = await updateMeQuery({
                clientTeamMemberUpdateMeDto: updateMeDto,
            }).unwrap()
            dispatch(setLoginUser(member))
            toast.success(t('CUserMypageProfile.プロフィールを編集しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setProfileErrors([e])
            setProfileErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    // パスワード変更
    const [updatePasswordQuery, { isSuccess: updatePasswordQuerySuccess }] = useClientUserPutPasswordMutation()
    const [updatePasswordDto, setUpdatePasswordDto] = useState({ password: '', confirmPassword: '' })
    const setNewPasswordProps = (key: string, value: unknown) => {
        if (!updatePasswordDto) return
        setUpdatePasswordDto({
            ...updatePasswordDto,
            [key]: value,
        })
    }
    const [passwordErrors, setPasswordErrors] = useState<Array<IApplicationError>>([])
    const disableSavePassword = useMemo(() => {
        if (!updatePasswordDto) return true
        if (updatePasswordDto.password.trim().length === 0) return true
        if (updatePasswordDto.password.trim().length > 0 && updatePasswordDto.password.trim().length < 8)
            return t('CUserMypageProfile.パスワードは8文字以上の文字列にしてください。')
        if (!updatePasswordDto.password.trim().match(/^(?=.*[-_%$#])(?=.*[0-9])(?=.*[a-zA-Z])/)) {
            return t(
                'CUserMypageProfile.パスワードは半角の英字と数字と記号（「-」「_」「%」「$」「#」のみ）をすべて含んでください。',
            )
        }
        if (updatePasswordDto.password !== updatePasswordDto.confirmPassword)
            return t('CUserMypageProfile.パスワードとパスワード(確認)が異なっています')

        return false
    }, [updatePasswordDto])
    const onSaveNewPassword = async () => {
        if (!updatePasswordDto) return
        try {
            await updatePasswordQuery({
                clientTeamMemberUpdatePasswordDto: { password: updatePasswordDto.password },
            }).unwrap()
            setUpdatePasswordDto({ password: '', confirmPassword: '' })
            dispatch(setLoginUser(undefined))
            toast.success(t('CUserMypageProfile.パスワードを変更しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setPasswordErrors([e])
            setPasswordErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    // 設定変更用モデル
    const settingMails: Array<{ label: string; value: number }> = [
        { label: t('CUserMypageProfile.都度'), value: 1 },
        { label: t('CUserMypageProfile.1時間毎'), value: 4 },
        { label: t('CUserMypageProfile.1日毎(AM8：00)'), value: 3 },
        { label: t('CUserMypageProfile.通知なし'), value: 2 },
    ]
    const sendToString: Array<{ label: string; value: number }> = [
        { label: t('CUserMypageProfile.個人名'), value: 1 },
        { label: t('CUserMypageProfile.個人名（会社名）'), value: 2 },
    ]

    const [selectedTab, setSelectedTab] = useState(content || 'profile')
    const tabs = useMemo(
        () => [
            { label: t('CUserMypageProfile.プロフィール設定'), value: 'profile' },
            { label: t('CUserMypageProfile.パスワード変更'), value: 'changePassword' },
            { label: t('CUserMypageProfile.購入条件'), value: 'condition' },
        ],
        [],
    )

    // condition
    const [updateConditionDto, setUpdateConditionDto] = useState<ClientTeamMemberConditionUpdateDto>({
        isShareCondition: false,
        isRecommendTarget: false,
        area: [],
        buildingAgeType: 1,
        grossRateType: 40,
        legalComplianceType: 1,
        netRateType: 30,
        prices: [],
        purchaseTimeType: 1,
        seismicStandardType: 1,
        useType: [],
    })
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState<
        boolean | '種別が選択されていません' | 'エリアが選択されていません'
    >(false)
    const conditionChangeHandler = (_updateConditionDto: ClientTeamMemberConditionUpdateDto) => {
        if (!_updateConditionDto?.useType.length) setIsDisabledSaveButton('種別が選択されていません')
        else if (!_updateConditionDto?.area.length) setIsDisabledSaveButton('エリアが選択されていません')
        else setIsDisabledSaveButton(false)
        setUpdateConditionDto(_updateConditionDto)
    }
    const [updateConditionQuery, { isSuccess: updateConditionQuerySuccess }] = useClientUserPutConditionMutation()
    const [conditionErrors, setConditionErrors] = useState<Array<IApplicationError>>([])
    const onSaveCondition = async () => {
        if (!updateConditionDto) return
        try {
            await updateConditionQuery({
                clientTeamMemberConditionUpdateDto: updateConditionDto,
            }).unwrap()
            toast.success(t('CUserMypageProfile.購入条件を編集しました'))
        } catch (e) {
            if (e instanceof ApplicationError) setConditionErrors([e])
            setConditionErrors([(e as FetchBaseQueryError).data as IApplicationError])
        }
    }

    return (
        <>
            <div className={clsx('w-full', 'md:max-w-lg')}>
                <CSegmentedControl items={tabs} onClick={(val) => setSelectedTab(val as string)} selectedTabValue={selectedTab} />
            </div>
            {selectedTab === 'profile' && updateMeDto && (
                <>
                    <div className={clsx('flex', 'flex-col', 'space-y-4', 'max-w-xl', 'w-full')}>
                        <CHeader label={t('CUserMypageProfile.個人設定')} />
                        <CError errors={profileErrors} />
                        {updateMeQuerySuccess && (
                            <div className={clsx('bg-green-50', 'text-green-900', 'p-4', 'rounded')}>
                                {t('CUserMypageProfile.プロフィールを変更しました')}
                            </div>
                        )}
                        <CLabeledItem label={t('CUserMypageProfile.メールアドレス')}>
                            <CTextInput
                                text={user?.email ?? ''}
                                type={'text'}
                                className={clsx('w-full', 'c-text-input-base', 'bg-gray-100', 'text-gray-700')}
                            />
                        </CLabeledItem>
                        <div className={clsx('grid', 'grid-cols-2', 'gap-2')}>
                            <CLabeledItem label={t('CUserMypageProfile.氏名')} required>
                                <CTextInput
                                    type={'text'}
                                    text={updateMeDto.name}
                                    onChange={(val) => setProfileProps('name', val)}
                                    className={clsx('w-full', 'c-text-input-base')}
                                />
                            </CLabeledItem>
                            <CLabeledItem label={t('CUserMypageProfile.氏名(かな)')} required>
                                <CTextInput
                                    type={'text'}
                                    text={updateMeDto.nameSound}
                                    onChange={(val) => setProfileProps('nameSound', val)}
                                    className={clsx('w-full', 'c-text-input-base')}
                                />
                            </CLabeledItem>
                        </div>
                        <div className={clsx('grid', 'grid-cols-2', 'gap-2')}>
                            <CLabeledItem label={t('CUserMypageProfile.部署名')}>
                                <CTextInput
                                    text={updateMeDto.departmentName ?? ''}
                                    onChange={(val) => setProfileProps('departmentName', val)}
                                    type={'text'}
                                    className={clsx('w-full', 'c-text-input-base')}
                                />
                            </CLabeledItem>

                            <CLabeledItem label={t('CUserMypageProfile.役職')}>
                                <CTextInput
                                    type={'text'}
                                    text={updateMeDto.position ?? ''}
                                    onChange={(val) => setProfileProps('position', val)}
                                    className={clsx('w-full', 'c-text-input-base')}
                                />
                            </CLabeledItem>
                        </div>
                        <div className={clsx('grid', 'grid-cols-2', 'gap-2')}>
                            <CLabeledItem label={t('CUserMypageProfile.電話番号')}>
                                <CTextInput
                                    type={'text'}
                                    text={updateMeDto.phone ?? ''}
                                    onChange={(val) => setProfileProps('phone', val)}
                                    className={clsx('w-full', 'c-text-input-base')}
                                />
                            </CLabeledItem>
                            <CLabeledItem label={t('CUserMypageProfile.内線番号')}>
                                <CTextInput
                                    type={'text'}
                                    text={updateMeDto.phoneExtension ?? ''}
                                    onChange={(val) => setProfileProps('phoneExtension', val)}
                                    className={clsx('w-full', 'c-text-input-base')}
                                />
                            </CLabeledItem>
                        </div>
                        <CLabeledItem label={t('CUserMypageProfile.携帯番号')}>
                            <CTextInput
                                type={'text'}
                                text={updateMeDto.mobile ?? ''}
                                onChange={(val) => setProfileProps('mobile', val)}
                                className={clsx('w-full', 'c-text-input-base')}
                            />
                        </CLabeledItem>
                    </div>
                    <div className={clsx('flex', 'flex-col', 'space-y-4', 'max-w-xl')}>
                        <CHeader label={t('CUserMypageProfile.メール設定')} />
                        <CMessage info>
                            {t(
                                'CUserMypageProfile.購入条件リクエストの受信通知とレコメンド、コネクション、情報開示リクエストの発生通知は設定に関わらず送信されます。',
                            )}
                        </CMessage>
                        <CLabeledItem label={t('CUserMypageProfile.メールの通知頻度')}>
                            <div className={clsx('rounded', 'border')}>
                                <CRadioButton
                                    horizontal
                                    items={settingMails}
                                    nowValue={updateMeDto.setting.mail}
                                    dataValue="value"
                                    onChange={(val) =>
                                        setProfileProps('setting', { ...updateMeDto.setting, mail: val as number })
                                    }
                                />
                            </div>
                        </CLabeledItem>
                        <CLabeledItem label={t('CUserMypageProfile.メールの署名')}>
                            <CTextInput
                                text={updateMeDto.signature ?? ''}
                                onChange={(val) => setProfileProps('signature', val)}
                                type={'multiline'}
                                className={clsx('w-full', 'c-text-input-base')}
                            />
                        </CLabeledItem>
                        <CLabeledItem label={t('CUserMypageProfile.メールの差出人表示')}>
                            <CRadioButton
                                horizontal
                                items={sendToString}
                                nowValue={updateMeDto.setting.sendToString}
                                dataValue="value"
                                onChange={(val) =>
                                    setProfileProps('setting', { ...updateMeDto.setting, sendToString: val as number })
                                }
                            />
                        </CLabeledItem>
                        {!!disableSaveProfile && (
                            <div className={clsx('text-red-500', 'w-full', 'text-center')}>
                                {disableSaveProfile !== true && disableSaveProfile}
                            </div>
                        )}
                        <CButton
                            className={clsx('c-button-primary', 'w-full')}
                            disabled={!!disableSaveProfile}
                            onClick={onSaveProfile}>
                            {t('CUserMypageProfile.設定確定')}
                        </CButton>
                    </div>
                </>
            )}
            {selectedTab === 'changePassword' && (
                <>
                    {updatePasswordDto && (
                        <div className={clsx('flex', 'flex-col', 'space-y-4', 'max-w-xl')}>
                            <CMessage info>
                                {t(
                                    'CUserMypageProfile.新しいパスワードは、半角の英字と数字と記号（「-」「_」「%」「$」「#」のみ）をすべて含む、8文字以上の文字列にしてください。',
                                )}
                            </CMessage>
                            {updatePasswordQuerySuccess && (
                                <div className={clsx('bg-green-50', 'text-green-900', 'p-4', 'rounded')}>
                                    {t('CUserMypageProfile.パスワードを変更しました')}
                                </div>
                            )}
                            <CError errors={passwordErrors} />
                            <CLabeledItem label={t('CUserMypageProfile.新しいパスワード')}>
                                <CTextInput
                                    text={updatePasswordDto.password}
                                    onChange={(value) => setNewPasswordProps('password', value)}
                                    type="password"
                                    className={clsx('w-full', 'c-text-input-base')}
                                />
                            </CLabeledItem>
                            <CLabeledItem label={t('CUserMypageProfile.新しいパスワード(確認)')}>
                                <CTextInput
                                    text={updatePasswordDto.confirmPassword}
                                    onChange={(value) => setNewPasswordProps('confirmPassword', value)}
                                    type="password"
                                    className={clsx('w-full', 'c-text-input-base')}
                                />
                            </CLabeledItem>
                            {!!disableSavePassword && (
                                <div className={clsx('text-red-500', 'w-full', 'text-center')}>
                                    {disableSavePassword !== true && disableSavePassword}
                                </div>
                            )}
                            <CButton
                                className={clsx('c-button-primary', 'w-full')}
                                disabled={!!disableSavePassword}
                                onClick={onSaveNewPassword}>
                                {t('CUserMypageProfile.パスワードを変更')}
                            </CButton>
                        </div>
                    )}
                </>
            )}
            {selectedTab === 'condition' && (
                <div className={clsx('flex', 'flex-col', 'space-y-4')}>
                    {user && (
                        <>
                            <CError errors={conditionErrors} />
                            {updateConditionQuerySuccess && (
                                <div className={clsx('bg-green-50', 'text-green-900', 'p-4', 'rounded')}>
                                    {t('CUserMypageProfile.購入条件を変更しました')}
                                </div>
                            )}
                            <CUserPurchaseConditionContent
                                changeHandler={(_updateConditionDto) => conditionChangeHandler(_updateConditionDto)}
                            />
                            {!!isDisabledSaveButton && (
                                <div className={clsx('text-red-500', 'w-full', 'text-center')}>{isDisabledSaveButton}</div>
                            )}
                            <CButton
                                className={clsx('c-button-primary', 'w-full')}
                                disabled={!!isDisabledSaveButton}
                                onClick={onSaveCondition}>
                                {t('CUserMypageProfile.設定確定')}
                            </CButton>
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default CUserMypageProfile
