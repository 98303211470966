import clsx from 'clsx'
import { t } from 'i18next'
import { isNil, round } from 'lodash'

import { RealEstateEntities } from '~/app/api'
import {
    certificateOfCompletionOfConfirmationItems,
    certificateOfFinalInspectionItems,
    cityPlanningAreaItems,
    firePreventionDistrictsItems,
    modeOfTransactionItems,
    realestateLegalComplianceTypeItems,
    realestateSeismicStandardTypeItems,
    salesDestinationTypeItems,
    saleTimeTypeItems,
    structureTypeItems,
    surveyDrawingItems,
    taxType,
    zoningTypeItems,
} from '~/types/enum/enum'
import { calcYearsBuilt, dateFormatOfJc, fullCurrencyFormat } from '~/util/common/common'
import { numberFormat, priceFormat } from '~/util/filter/format'

import { getEndedTypeLabel, getInvolvementLabel } from './CEndedRealestate'

type Props = {
    realEstate: RealEstateEntities
    isIntermediary?: boolean
    isMapMode?: boolean
    isMarket?: boolean
}

const CRealestateDetailInfo = ({ realEstate, isMarket }: Props) => {
    return (
        <div className={clsx('py-1', 'text-xs', 'md:text-sm')}>
            <div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-2',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.物件名')}
                    </div>
                    <div className={clsx('col-span-5', 'p-1')}>{realEstate.name}</div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.所在地')}
                    </div>
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.住所')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>{realEstate.address}</div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.地番')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>{realEstate.parcelNumber}</div>
                        </div>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-2',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.種別')}
                    </div>
                    <div className={clsx('col-span-2', 'p-1')}>{realEstate.useType.name}</div>
                    <div className={clsx('bg-kimar-primary', 'text-white', 'flex', 'justify-center', 'items-center')}>
                        {t('CRealestateDetailInfo.売却時期')}
                    </div>
                    <div className={clsx('col-span-2', 'p-1')}>
                        {saleTimeTypeItems.find((i) => i.value === realEstate.saleTimeType)?.label}
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-2',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.物件価格')}
                    </div>
                    <div className={clsx('col-span-5', 'flex', 'items-center', 'p-1')}>
                        {realEstate.price && (
                            <>
                                {priceFormat(realEstate.price)} ({fullCurrencyFormat(realEstate.price)}
                                {t('Unit.円')})
                                {!isNil(realEstate.taxType) && (
                                    <div className={clsx('text-sm')}>{`(${
                                        taxType.find((t) => t.value === realEstate.taxType)?.label
                                    })`}</div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-2',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.竣工日')}
                    </div>
                    <div className={clsx('col-span-5', 'flex', 'p-1')}>
                        {dateFormatOfJc(
                            realEstate.buildAt,
                            realEstate.buildAtDateType === 2 ? 'year' : realEstate.buildAtDateType === 1 ? 'month' : 'day',
                        )}
                        <span className={clsx('ml-2')}>{calcYearsBuilt(realEstate.buildAt)}</span>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-2',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.交通')}
                    </div>
                    <div className={clsx('col-span-5', 'p-1', 'whitespace-pre-wrap')}>{realEstate.station}</div>
                </div>
                {isMarket && (
                    <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                        <div
                            className={clsx(
                                'col-span-2',
                                'bg-kimar-primary',
                                'text-white',
                                'flex',
                                'justify-center',
                                'items-center',
                            )}>
                            {t('CRealestateDetailInfo.マーケット掲載コメント')}
                        </div>
                        <div className={clsx('col-span-5', 'p-1')}>{realEstate.marketComment}</div>
                    </div>
                )}
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.土地')}
                    </div>
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.地目')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>{realEstate.landCategory}</div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.敷地形状')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>{realEstate.landShape}</div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.権利')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>{realEstate.landRight}</div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.測量図')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {surveyDrawingItems.find((i) => i.value === realEstate.surveyDrawing)?.label}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.公簿面積')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>
                                {realEstate.registeredLandSize && (
                                    <>
                                        {realEstate.registeredLandSize}
                                        {t('Unit.㎡')}, {realEstate.registeredLandSizeTsubo}
                                        {t('Unit.坪')}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.実測面積')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>
                                {realEstate.surveyedLandSize && (
                                    <>
                                        {realEstate.surveyedLandSize}
                                        {t('Unit.㎡')}, {realEstate.surveyedLandSizeTsubo}
                                        {t('Unit.坪')}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.前面道路情報')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1', 'whitespace-pre-wrap')}>{realEstate.frontalRoad}</div>
                        </div>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.建物')}
                    </div>
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.権利')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>{realEstate.buildingRight}</div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.家屋番号')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>{realEstate.buildingNumber}</div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.耐震基準')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    realestateSeismicStandardTypeItems.find((i) => i.value === realEstate.seismicStandardType)
                                        ?.label
                                }
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.遵法性')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    realestateLegalComplianceTypeItems.find((i) => i.value === realEstate.legalComplianceType)
                                        ?.label
                                }
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.建物名')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>{realEstate.buildingName}</div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.建築主')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>{realEstate.builder}</div>
                        </div>

                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.総戸数')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>{realEstate.totalUnits}</div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.構造')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {structureTypeItems.find((i) => i.value === realEstate.structureType)?.label}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.延床面積')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>
                                {realEstate.totalFloorSize && (
                                    <>
                                        {realEstate.totalFloorSize}
                                        {t('Unit.㎡')}, {realEstate.totalFloorSizeTsubo}
                                        {t('Unit.坪')}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.賃貸可能面積')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>
                                {realEstate.rentableAreaSize && (
                                    <>
                                        {realEstate.rentableAreaSize}
                                        {t('Unit.㎡')}, {realEstate.rentableAreaSizeTsubo}
                                        {t('Unit.坪')}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.地上階数')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>{realEstate.floor}</div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.地下階数')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>{realEstate.undergroundFloor}</div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.設備')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>{realEstate.facility}</div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.検査済証')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    certificateOfFinalInspectionItems.find(
                                        (i) => i.value === realEstate.certificateOfFinalInspection,
                                    )?.label
                                }
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.確認済証')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {
                                    certificateOfCompletionOfConfirmationItems.find(
                                        (i) => i.value === realEstate.certificateOfCompletionOfConfirmation,
                                    )?.label
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.公法規則')}
                    </div>
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.都市計画')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {cityPlanningAreaItems.find((i) => i.value === realEstate.cityPlanningArea)?.label}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.用途地域１')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {zoningTypeItems.find((i) => i.value === realEstate.zoningType)?.label}
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.用途地域２')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {zoningTypeItems.find((i) => i.value === realEstate.zoningType2)?.label}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.建ぺい率１')}
                            </div>
                            <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                {realEstate.buildingRate && <>{numberFormat(realEstate.buildingRate)}%</>}
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.建ぺい率２')}
                            </div>
                            <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                {realEstate.buildingRate2 && <>{numberFormat(realEstate.buildingRate2)}%</>}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.容積率１')}
                            </div>
                            <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                {realEstate.floorAreaRatio && <>{numberFormat(realEstate.floorAreaRatio)}%</>}
                            </div>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.容積率２')}
                            </div>
                            <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                                {realEstate.floorAreaRatio2 && <>{numberFormat(realEstate.floorAreaRatio2)}%</>}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.防火地域')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {firePreventionDistrictsItems.find((i) => i.value === realEstate.firePreventionDistricts)?.label}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.高度規制')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>{realEstate.heightControl}</div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.その他規制')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'items-end', 'p-1')}>{realEstate.otherControl}</div>
                        </div>
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.収支')}
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.想定収入／年')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        {realEstate.assumeIncome && <>{priceFormat(realEstate.assumeIncome)}</>}
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.現況収入／年')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        {realEstate.presentIncome && <>{priceFormat(realEstate.presentIncome)}</>}
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.利回り')}
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.表面利回り')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        {realEstate.grossRate && <>{numberFormat(round(realEstate.grossRate, 3))}%</>}
                    </div>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.実質利回り')}
                    </div>
                    <div className={clsx('col-span-2', 'flex', 'items-end', 'p-1', 'items-center')}>
                        {realEstate.netRate && <>{numberFormat(round(realEstate.netRate, 3))}%</>}
                    </div>
                </div>
                <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                    <div
                        className={clsx(
                            'col-span-1',
                            'bg-kimar-primary',
                            'text-white',
                            'flex',
                            'justify-center',
                            'items-center',
                        )}>
                        {t('CRealestateDetailInfo.その他')}
                    </div>
                    <div className={clsx('col-span-6')}>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div className={clsx('bg-kimar-primary', 'text-white', 'flex', 'justify-center', 'items-center')}>
                                {t('CRealestateDetailInfo.引渡日')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'p-1')}>
                                {dateFormatOfJc(
                                    realEstate.deliveryAt,
                                    realEstate.deliveryAtDateType === 2
                                        ? 'year'
                                        : realEstate.deliveryAtDateType === 1
                                        ? 'month'
                                        : 'day',
                                )}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.取引態様')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {modeOfTransactionItems.find((i) => i.value === realEstate.modeOfTransaction)?.label}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.情報取得元')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1', 'whitespace-pre-wrap')}>{realEstate.informationSource}</div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.先数')}
                            </div>
                            <div className={clsx('col-span-2', 'p-1')}>
                                {salesDestinationTypeItems.find((i) => i.value === realEstate.salesDestinationType)?.label}
                            </div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div className={clsx('bg-kimar-primary', 'text-white', 'flex', 'justify-center', 'items-center')}>
                                {t('CRealestateDetailInfo.情報取得日')}
                            </div>
                            <div className={clsx('col-span-5', 'flex', 'p-1')}>{dateFormatOfJc(realEstate.acquiredAt)}</div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.売主')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1')}>{realEstate.seller}</div>
                        </div>
                        <div className={clsx('grid', 'grid-cols-6')}>
                            <div
                                className={clsx(
                                    'col-span-1',
                                    'bg-kimar-primary',
                                    'text-white',
                                    'flex',
                                    'justify-center',
                                    'items-center',
                                )}>
                                {t('CRealestateDetailInfo.備考')}
                            </div>
                            <div className={clsx('col-span-5', 'p-1', 'whitespace-pre-wrap')}>{realEstate.other}</div>
                        </div>
                    </div>
                </div>
                {!!realEstate.ended && (
                    <div className={clsx('grid', 'grid-cols-7', 'border-b')}>
                        <div
                            className={clsx(
                                'col-span-1',
                                'bg-kimar-primary',
                                'text-white',
                                'flex',
                                'justify-center',
                                'items-center',
                            )}>
                            {t('CRealestateDetailInfo.物件終了')}
                        </div>
                        <div className={clsx('col-span-6')}>
                            <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateDetailInfo.終了日時')}
                                </div>
                                <div className={clsx('col-span-2', 'p-1')}>
                                    {dateFormatOfJc(realEstate.ended.createdAt, 'day')}
                                </div>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateDetailInfo.売買金額')}
                                </div>
                                <div className={clsx('col-span-2', 'p-1')}>
                                    {!isNil(realEstate.ended.price) ? (
                                        <>
                                            {priceFormat(realEstate.ended.price)} ({fullCurrencyFormat(realEstate.ended.price)}
                                            {t('Unit.円')})
                                        </>
                                    ) : (
                                        <>{t('CRealestateDetailInfo.売買金額不明')}</>
                                    )}
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateDetailInfo.契約時期')}
                                </div>
                                <div className={clsx('col-span-2', 'p-1')}>
                                    {realEstate.ended.endedAt
                                        ? dateFormatOfJc(realEstate.ended.endedAt, 'month')
                                        : t('CRealestateDetailInfo.契約時期不明')}
                                </div>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateDetailInfo.決済時期')}
                                </div>
                                <div className={clsx('col-span-2', 'p-1')}>
                                    {realEstate.ended.paymentAt
                                        ? dateFormatOfJc(realEstate.ended.paymentAt, 'month')
                                        : t('CRealestateDetailInfo.決済時期不明')}
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-6', 'border-b')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateDetailInfo.売買への関与')}
                                </div>
                                <div className={clsx('col-span-2', 'p-1')}>{getEndedTypeLabel(realEstate.ended.endedType)}</div>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateDetailInfo.関与内容')}
                                </div>
                                <div className={clsx('col-span-2', 'p-1')}>
                                    {getInvolvementLabel(realEstate.ended.involvement)}
                                </div>
                            </div>
                            <div className={clsx('grid', 'grid-cols-6')}>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                    )}>
                                    {t('CRealestateDetailInfo.Kimarで紹介')}
                                </div>
                                <div className={clsx('col-span-2', 'p-1', 'flex', 'items-center')}>
                                    {isNil(realEstate.ended.isKimar) ? null : realEstate.ended.isKimar ? (
                                        <i className={clsx('material-icons-outlined')}>circle</i>
                                    ) : (
                                        <i className={clsx('material-icons')}>close</i>
                                    )}
                                </div>
                                <div
                                    className={clsx(
                                        'col-span-1',
                                        'bg-kimar-primary',
                                        'text-white',
                                        'flex',
                                        'justify-center',
                                        'items-center',
                                        'text-center',
                                    )}>
                                    {t('CRealestateDetailInfo.マーケット経由で情報取得')}
                                </div>
                                <div className={clsx('col-span-2', 'p-1', 'flex', 'items-center')}>
                                    {isNil(realEstate.ended.isMarket) ? null : realEstate.ended.isMarket ? (
                                        <i className={clsx('material-icons-outlined')}>circle</i>
                                    ) : (
                                        <i className={clsx('material-icons')}>close</i>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CRealestateDetailInfo
