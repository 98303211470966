import { VFC } from 'react'

export type CDropDownViewProps = {} & CDropDownProps

export const CDropDownView: VFC<CDropDownViewProps> = ({
    className,
    disabled,
    multiple,
    items,
    allowUnselected,
    unselectedLabel,
    dataLabel,
    dataValue,
    nowValue,
    onChange,
}) => {
    const dropDownList = items.map((data, index) => {
        const label = dataLabel ? data[dataLabel] : data.label ? data.label : data.value
        const value = (dataValue ? data[dataValue] : data.value) as string | number | readonly string[] | undefined
        return (
            <option key={index + 1} value={value}>
                {label as string}
            </option>
        )
    })

    if (allowUnselected) {
        dropDownList.unshift(
            <option key="0" value={undefined}>
                {unselectedLabel ?? ''}
            </option>,
        )
    }

    return (
        <>
            <select
                className={className}
                value={nowValue}
                onChange={(e) => onChange && onChange(e.target.value)}
                disabled={disabled}
                multiple={multiple}>
                {dropDownList}
            </select>
        </>
    )
}

export type CDropDownProps = {
    className?: string
    // 無効状態か否か
    disabled?: boolean
    // 複数選択
    multiple?: boolean
    items: Array<{ [key: string | number | symbol]: unknown }>
    // 未選択を許容するか
    allowUnselected?: boolean
    // 未選択のラベル
    unselectedLabel?: string
    // ラベル
    dataLabel?: string
    // 値
    dataValue?: string | null
    nowValue?: string | number | readonly string[] | undefined
    onChange?: (selectedData: string) => void
}

export const CDropDown: VFC<CDropDownProps> = (props) => {
    return <CDropDownView {...props} />
}
