import clsx from 'clsx'
import { t } from 'i18next'
import { useState, VFC } from 'react'
import toast from 'react-hot-toast/headless'

import { AdminReportTemplateInsertDto, AdminReportTemplateUpdateDto, FileEntities } from '~/app/api'
import { CButton } from '~/components/common/cButton/CButton'
import { CDropDown } from '~/components/common/cDropdown/CDropdown'
import { CError } from '~/components/common/cError/CError'
import { CFileUpload } from '~/components/common/cFileUpload/CFileUpload'
import { CLabeledItem } from '~/components/common/cLabeledItem/CLabeledItem'
import { CTextInput } from '~/components/common/cTextInput/CTextInput'
import { ApplicationError, IApplicationError } from '~/types/error'

type CReportTemplateEditProps = {
    template: AdminReportTemplateUpdateDto | AdminReportTemplateInsertDto
    confirmButtonHandler: (changedTemplate: AdminReportTemplateUpdateDto | AdminReportTemplateInsertDto) => void
    cancelButtonHandler?: () => void
}

const CReportTemplateEdit: VFC<CReportTemplateEditProps> = ({ ...props }) => {
    const [errors, setErrors] = useState<Array<IApplicationError>>([])
    const [template, setTemplate] = useState(props.template)
    // カテゴリ一覧
    const categories: { label: string; value: string }[] = [
        { label: '', value: '' },
        { label: t('CReportTemplateEdit.物件概要書'), value: 'realestate_overview_document' },
    ]
    // カテゴリの日本語名
    const categoryJapanese: { [key: string]: string } = { realestate_overview_document: t('CReportTemplateEdit.物件概要書') }
    // { category: 「出力されるファイル名」のprefix }
    const displayNamePrefix: { [key: string]: string } = { realestate_overview_document: t('CReportTemplateEdit.物件名_') }
    const labelList: Array<{ key: string; text: string }> = [
        { key: '_title_', text: '物件名' },
        { key: '_use_type_', text: '種別' },
        { key: '_address_', text: '住所' },
        { key: '_parcel_number_', text: '地番' },
        { key: '_station_', text: '交通' },
        { key: '_land_category_', text: '地目' },
        { key: '_land_shape_', text: '敷地形状' },
        { key: '_land_right_', text: '権利(土地)' },
        { key: '_survey_drawing_', text: '測量図' },
        { key: '_registered_land_size_', text: '公簿面積' },
        { key: '_registered_land_size_tubo_', text: '公簿面積(坪)' },
        { key: '_surveyed_land_size_', text: '実測面積' },
        { key: '_surveyed_land_size_tubo_', text: '実測面積(坪)' },
        { key: '_frontal_road_', text: '前面道路情報' },
        { key: '_building_right_', text: '権利(建物)' },
        { key: '_building_number_', text: '家屋番号' },
        { key: '_total_units_', text: '総戸数' },
        { key: '_structure_type_', text: '構造' },
        { key: '_floor_size_', text: '床面積' },
        { key: '_floor_size_tubo_', text: '床面積(坪)' },
        { key: '_total_floor_size_', text: '延床面積' },
        { key: '_total_floor_size_tubo_', text: '延床面積(坪)' },
        { key: '_build_at_', text: '竣工日' },
        { key: '_facility_', text: '設備' },
        { key: '_certificate_of_completion_of_confirmation_', text: '確認済証' },
        { key: '_certificate_of_final_inspection_', text: '検査済証' },
        { key: '_city_planning_area_', text: '都市計画' },
        { key: '_zoning_type_', text: '用途地域1および用途地域2' },
        { key: '_building_rate_', text: '建ぺい率1および建ぺい率2' },
        { key: '_floor_area_ratio_', text: '容積率1および容積率2' },
        { key: '_fire_prevention_districts_', text: '防火地域' },
        { key: '_height_control_', text: '高度規制' },
        { key: '_other_control_', text: 'その他規制' },
        { key: '_assume_income_', text: '想定収入／年' },
        { key: '_present_income_', text: '現況収入／年' },
        { key: '_gross_rate_', text: '表面利回り' },
        { key: '_net_rate_', text: '実質利回り' },
        { key: '_price_', text: '物件価格' },
        { key: '_delivery_at_', text: '引渡日' },
        { key: '_mode_of_transaction_', text: '取引態様' },
        { key: '_other_', text: '備考' },
    ]

    // インジェクション対策
    const htmlEntities = (str: string) => {
        return String(str).replace(/&/g, '＆').replace(/"/g, '”').replace(/</g, '＜').replace(/>/g, '＞')
    }

    const changeHandler = (
        value: string | FileEntities,
        target: keyof (AdminReportTemplateUpdateDto & AdminReportTemplateInsertDto),
    ) => {
        setTemplate((old) => ({ ...old, [target]: value }))
    }

    const validate = () => {
        const errorList: Array<ApplicationError> = []
        // インジェクション対策のサニタイジング
        if (template.displayName.trim().length === 0)
            setTemplate((old) => ({ ...old, displayName: htmlEntities(template.displayName) }))
        if (template.category.trim().length === 0) setTemplate((old) => ({ ...old, category: htmlEntities(template.category) }))

        if (template.displayName.trim().length === 0)
            errorList.push(new ApplicationError(t('CReportTemplateEdit.出力されるファイル名を入力してください')))
        if (template.category.trim().length === 0)
            errorList.push(new ApplicationError(t('CReportTemplateEdit.カテゴリを選択してください')))
        if (!template.file) errorList.push(new ApplicationError(t('CReportTemplateEdit.ファイルをアップロードしてください')))
        if (errorList.length > 0) {
            setErrors(errorList)
            return false
        }
        return true
    }

    const confirm = () => {
        setErrors([])
        if (!validate()) return
        props.confirmButtonHandler(template)
        toast.success(t('CReportTemplateEdit.帳票テンプレートを登録しました'))
    }

    return (
        <>
            <div className={clsx('w-full', 'p-4', 'flex', 'flex-col', 'gap-y-3', 'bg-white')}>
                <div className={clsx('flex', 'flex-col', 'gap-y-4')}>
                    <CError errors={errors} />
                    <div className={clsx('columns-2')}>
                        <CLabeledItem label={t('CReportTemplateEdit.物件概要書で使用できるテンプレートラベル')}>
                            <div className={clsx('w-full', 'p-2', 'h-96', 'gap-y-3', 'overflow-auto')}>
                                <table className={clsx('border')}>
                                    <tbody>
                                        {labelList.map((label, index) => (
                                            <tr key={index} className={clsx('border')}>
                                                <td className={clsx('border', 'p-1')}>{label.text}</td>
                                                <td className={clsx('border', 'p-1')}>{label.key}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CLabeledItem>
                        <div className={clsx('w-full', 'p-2', 'flex', 'flex-col', 'gap-y-4')}>
                            <CLabeledItem className={clsx('pb-0.5')} label={t('CReportTemplateEdit.カテゴリ')} required>
                                {'uuid' in template ? (
                                    // テンプレート登録済ならそのカテゴリを日本語で表示
                                    categoryJapanese[template.category] ?? <div>{template.category}</div>
                                ) : (
                                    // テンプレート未登録ならカテゴリリストから選択させる
                                    <CDropDown
                                        items={categories}
                                        onChange={(value) => {
                                            changeHandler(value, 'category')
                                        }}
                                    />
                                )}
                            </CLabeledItem>
                            <CLabeledItem
                                className={clsx('pb-0.5')}
                                label={t('CReportTemplateEdit.出力されるファイル名')}
                                required>
                                <div className={clsx('flex', 'flex-row', 'items-end', 'whitespace-nowrap')}>
                                    {displayNamePrefix[template.category] ?? <span>{displayNamePrefix[template.category]}</span>}
                                    <CTextInput
                                        type="text"
                                        placeholder={t('CReportTemplateEdit.物件概要書')}
                                        text={template?.displayName}
                                        className={clsx('w-full', 'c-text-input-base')}
                                        onChange={(value) => changeHandler(value, 'displayName')}
                                    />
                                    <span>.xlsx</span>
                                </div>
                            </CLabeledItem>
                            <CLabeledItem className={clsx('pb-0.5')} label={t('CReportTemplateEdit.登録したファイル名')}>
                                <CFileUpload
                                    accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    fileUploaded={(files) => {
                                        files.length && changeHandler(files[0], 'file')
                                    }}
                                />
                                <CTextInput
                                    className={clsx('w-full', 'mt-2', 'c-text-input-base')}
                                    type="text"
                                    text={template?.file ? template?.file.filename : ''}
                                    placeholder={
                                        template?.file
                                            ? undefined
                                            : t('CReportTemplateEdit.ファイルをアップロードすると自動で入力されます')
                                    }
                                    disabled
                                />
                            </CLabeledItem>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={clsx(
                    props.cancelButtonHandler && 'bg-gray-100',
                    'w-full',
                    'p-4',
                    'flex',
                    'justify-center',
                    'space-x-2',
                )}>
                {props.cancelButtonHandler && (
                    <CButton className={clsx('c-button-secondary')} onClick={props.cancelButtonHandler}>
                        {t('Button.キャンセル')}
                    </CButton>
                )}
                <CButton className={clsx('c-button-primary')} onClick={confirm}>
                    {t('Button.送信')}
                </CButton>
            </div>
        </>
    )
}

export default CReportTemplateEdit
