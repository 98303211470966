import clsx from 'clsx'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

// type Props = {
//     white?: boolean
// }

const SiteFooter = () => (
    <footer
        className={clsx('font-body', 'w-full', 'bg-gray-700', 'text-white', 'text-xs', 'py-6', 'px-4', 'sticky', 'top-[100vh]')}>
        <ul className={clsx('flex', 'justify-center', 'flex-wrap', 'space-x-2', 'leading-loose')}>
            <Link className={clsx('underline')} to="eura">
                {t('SiteFooter.利用規約')}
            </Link>
            <Link className={clsx('underline')} to="privacy">
                {t('SiteFooter.プライバシーポリシー')}
            </Link>
            <Link className={clsx('underline')} to="scta_notation">
                {t('SiteFooter.特定商取引法に基づく表記')}
            </Link>
            <Link className={clsx('underline')} to="inquiry">
                {t('SiteFooter.お問い合わせ')}
            </Link>
            <a href="https://limar.co.jp" target="_blank" rel="noopener noreferrer" className={clsx('underline')}>
                {t('SiteFooter.運営会社')}
            </a>
        </ul>
        <div className={clsx('text-center', 'mt-6', 'tracking-widest')}>COPYRIGHT &copy; LimarEstate LIMITED</div>
    </footer>
)

export default SiteFooter
