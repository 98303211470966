import TagManager from 'react-gtm-module'
import { Navigate, Route, Routes } from 'react-router-dom'

import { RouteAdminAuthGuard } from './components/global/guard/RouteAdminAuthGuard'
import { RouteClientAuthGuard } from './components/global/guard/RouteClientAuthGuard'
import Default from './layout/Default'
import NoFooter from './layout/NoFooter'
import SubWindow from './layout/SubWindow'
import AdminCompany from './pages/admin/company/Company'
import AdminCompanyDetail from './pages/admin/company/CompanyDetail'
import AdminDashboard from './pages/admin/dashboard/DashBoard'
import AdminDownloadDetail from './pages/admin/download/detail/DownloadDetail'
import AdminDownload from './pages/admin/download/Download'
import AdminFaqContentDetail from './pages/admin/faq/detail/FaqContentDetail'
import AdminFaq from './pages/admin/faq/Faq'
import AdminInquiryDetail from './pages/admin/inquiry/detail/InquiryDetail'
import AdminInquiry from './pages/admin/inquiry/Inquiry'
import AdminNotificationTemplateDetail from './pages/admin/template/detail/NotificationTemplateDetail'
import AdminReportTemplateDetail from './pages/admin/template/detail/ReportTemplateDetail'
import AdminTemplate from './pages/admin/template/Template'
import Chat from './pages/chat/Chat'
import Customer from './pages/customer/Customer'
import DashBoard from './pages/dashboard/DashBoard'
import Download from './pages/download/Download'
import Error404 from './pages/error/404'
import Eura from './pages/eura/Eura'
import Faq from './pages/faq/Faq'
import Inquiry from './pages/inquiry/Inquiry'
import { LP } from './pages/lp/LP'
import Map from './pages/map/Map'
import Market from './pages/market/Market'
import Mypage from './pages/mypage/Mypage'
import Privacy from './pages/privacy/Privacy'
import PurchaseConditionEdit from './pages/purchase_condition/Edit'
import RealestateDetailIntermediary from './pages/realestate/detail/Intermediary'
import RealestateDetail from './pages/realestate/detail/RealestateDetail'
import IntermediaryDocument from './pages/realestate/intermediary/Document'
import Intermediary from './pages/realestate/intermediary/Intermediary'
import Realestate from './pages/realestate/Realestate'
import SctaNotation from './pages/sctaNotation/SctaNotation'
import ActivatePassword from './pages/signin/ActivatePassword'
import Reminder from './pages/signin/Reminder'
import ResetPassword from './pages/signin/ResetPassword'
import SignIn from './pages/signin/SignIn'
import SignOut from './pages/signin/SignOut'
import Ui from './pages/ui/Ui'
import FilePreview from './pages/util/FilePreview'

const KimarApp = () => {
    const tagManagerArgs = {
        gtmId: process.env.GTM_ID ?? '',
    }
    TagManager.initialize(tagManagerArgs)

    const host = window.location.hostname
    return (
        <Routes>
            <Route path="/" element={<SubWindow />}>
                <Route path="file_preview" element={<FilePreview />} />
                <Route path="chat" element={<Chat />} />
                <Route path="intermediary/:intermediaryUuid" element={<RealestateDetailIntermediary />} />
                <Route path="intermediary/:intermediaryUuid/Document" element={<IntermediaryDocument />} />
            </Route>
            <Route path="/" element={<Default />}>
                <Route index element={<LP />} />
                <Route path="download" element={<Download />} />
                <Route path="user/signin" element={<SignIn />} />
                <Route path="user/reminder" element={<Reminder />} />
                <Route path="user/reset/:token" element={<ResetPassword />} />
                <Route path="user/activate/:token" element={<ActivatePassword />} />
                <Route path="purchase_condition/:uuid/edit" element={<PurchaseConditionEdit />} />
                <Route path="eura" element={<Eura />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="scta_notation" element={<SctaNotation />} />
                <Route path="faq" element={<Faq />} />
                <Route path="inquiry" element={<Inquiry />} />
                {host === 'localhost' || host.indexOf('192.168') === 0 ? <Route path="ui" element={<Ui />} /> : <></>}
            </Route>
            <Route path="/" element={<NoFooter />}>
                <Route path="signout" element={<SignOut />} />
            </Route>
            <Route
                path="/"
                element={
                    <RouteClientAuthGuard>
                        <NoFooter />
                    </RouteClientAuthGuard>
                }>
                <Route path="map" element={<Map />} />
            </Route>
            <Route
                path="/"
                element={
                    <RouteClientAuthGuard>
                        <Default />
                    </RouteClientAuthGuard>
                }>
                <Route path="dashboard" element={<DashBoard />} />
                <Route path="market" element={<Market />} />

                <Route path="realestate" element={<Realestate />} />
                <Route path="realestate/:realEstateUuid" element={<RealestateDetail />} />

                <Route path="customer/:customerUuid" element={<Customer />} />

                <Route path="customer" element={<Customer />} />
                <Route path="user/mypage" element={<Mypage />} />
            </Route>
            <Route
                path="/"
                element={
                    <RouteClientAuthGuard>
                        <NoFooter />
                    </RouteClientAuthGuard>
                }>
                <Route path="realestate/:realEstateUuid/intermediary" element={<Intermediary />} />
            </Route>
            {/* Admin */}
            <Route
                path="/admin"
                element={
                    <RouteAdminAuthGuard>
                        <Default />
                    </RouteAdminAuthGuard>
                }>
                <Route path="" element={<Navigate to={'/dashboard'} replace />} />
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="company" element={<AdminCompany />} />
                <Route path="company/:companyUuid" element={<AdminCompanyDetail />} />
                <Route path="template" element={<AdminTemplate />} />
                <Route path="template/notification/:notificationTemplateUuid" element={<AdminNotificationTemplateDetail />} />
                <Route path="template/report/:reportTemplateUuid" element={<AdminReportTemplateDetail />} />
                <Route path="faq" element={<AdminFaq />} />
                <Route path="faq/:faqContentUuid" element={<AdminFaqContentDetail />} />
                <Route path="inquiry" element={<AdminInquiry />} />
                <Route path="inquiry/:inquiryUuid" element={<AdminInquiryDetail />} />
                <Route path="download" element={<AdminDownload />} />
                <Route path="download/:downloadUuid" element={<AdminDownloadDetail />} />
                <Route path="*" element={<Navigate to={'/dashboard'} replace />} />
            </Route>
            <Route path="*" element={<Error404 />} />
        </Routes>
    )
}

export default KimarApp
