import clsx from 'clsx'
import { t } from 'i18next'
import { ReactNode, VFC } from 'react'

export type CLabeledItemViewProps = {} & CLabeledItemProps

export const CLabeledItemView: VFC<CLabeledItemViewProps> = ({ ...props }) => {
    return (
        <div className={clsx(props.className, props.horizontal && ['flex', 'items-center'], 'space-y-1')}>
            <div
                className={clsx(
                    props.className,
                    props.horizontal ? ['flex', 'items-center', 'mr-2', 'space-y-1'] : ['flex', 'space-x-1'],
                )}>
                {props.labelNode ?? (
                    <>
                        <span className={clsx(props.horizontal && ['block'])}>{props.label}</span>
                        <span className={clsx(props.horizontal && ['block'])}>
                            {props.required && (
                                <span className={clsx('text-white', 'bg-red-700', 'text-xs', 'px-1', 'py-0.5', 'rounded')}>
                                    {t('CLabeledItemView.必須')}
                                </span>
                            )}
                        </span>
                    </>
                )}
            </div>
            <div>{props.children}</div>
        </div>
    )
}

export type CLabeledItemProps = {
    required?: boolean
    children?: ReactNode
    label?: string
    className?: string
    horizontal?: boolean
    labelNode?: ReactNode
}

export const CLabeledItem: VFC<CLabeledItemProps> = (props) => {
    return <CLabeledItemView {...props} />
}
