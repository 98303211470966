import clsx from 'clsx'

import CUserMypageCustomerLabel from './CUserMypageCustomerLabel'
import CUserMypageRealEstateDocumentLabel from './CUserMypageRealEstateDocumentLabel'
import CUserMypageRealEstateLabel from './CUserMypageRealEstateLabel'

const CUserMypageLabel = () => {
    return (
        <div className={clsx('flex', 'md:flex-row', 'flex-col', 'justify-center', 'w-full', 'gap-4')}>
            <CUserMypageRealEstateLabel />
            <CUserMypageRealEstateDocumentLabel />
            <CUserMypageCustomerLabel />
        </div>
    )
}

export default CUserMypageLabel
